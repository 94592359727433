exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".order-editor-extra{display:flex;flex-wrap:wrap;background-image:linear-gradient(270deg,#78aadd 1px,transparent 0),linear-gradient(0deg,#78aadd 1px,transparent 0);background-size:100% 35px}.order-editor-extra>div,.order-editor-extra>div.combine>div{flex:1 0 188px;display:flex;align-items:center;height:35px;padding:0 6px;border-right:1px solid #78aadd;font-size:14px;position:relative}.order-editor-extra>div.combine>div>.fn-label,.order-editor-extra>div>.fn-label{flex-shrink:0}.order-editor-extra>div .ant-calendar-picker,.order-editor-extra>div.combine>div .ant-calendar-picker,.order-editor-extra>div.combine>div>.fn-address,.order-editor-extra>div.combine>div>.fn-input-pure-wrap,.order-editor-extra>div>.fn-address,.order-editor-extra>div>.fn-input-pure-wrap{flex-grow:1}.order-editor-extra>div.combine>div .fn-input-pure,.order-editor-extra>div .fn-input-pure{text-align:center}.order-editor-extra>div.combine>div .fn-checkbox-label,.order-editor-extra>div .fn-checkbox-label{min-width:36px}.order-editor-extra>div:empty{height:0}.order-editor-extra>div.x2{flex:2 0 376px}.order-editor-extra>div.x2.order-remark{border-right:0}.order-editor-extra>div.x2.order-remark input{text-align:left}.order-editor-extra>div.combine{padding:0;border:0}.order-editor-extra>div.combine>div{width:50%}.order-editor-extra>div.combine>div:empty{border:0}.order-editor-extra>div:not(:empty):nth-of-type(5)+div:empty,.order-editor-extra>div:not(:empty):nth-of-type(5)+div:empty~div:empty{display:none}", ""]);

// exports
exports.locals = {
	"prefixCls": "order-editor-extra",
	"order-editor-extra": "order-editor-extra",
	"combine": "combine",
	"fn-label": "fn-label",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"fn-address": "fn-address",
	"ant-calendar-picker": "ant-calendar-picker",
	"fn-input-pure": "fn-input-pure",
	"fn-checkbox-label": "fn-checkbox-label",
	"x2": "x2",
	"order-remark": "order-remark"
};