/**
 * Created by wangnaihe on 2018/5/5.
 */
import { fetchApi, ot } from 'utils';

export const formatVipSug = data =>
  data.map(item => ({
    ...item,
    telephone: item.account_holder_phone,
    name: item.account_holder,
    bank_card: item.bank_card_num,
  }));
export const convertSugToMemberData = data => ({
  co_delivery_mode: data.payment_mode,
  card_holder: data.account_holder,
  contact_phone: data.account_holder_phone,
  open_bank: data.bank_name, // 开户行
  bank_card_num: data.bank_card, // 开户电话
  id: data.member_code, // 会员号
  member_code: data.member_code,
});

export const validateDelivery = async state => {
  const { hasArrStation, arrInfo, coDelivery } = state;
  const address = arrInfo && (arrInfo.addr ? arrInfo.addr : arrInfo);
  if (!coDelivery || !hasArrStation || !address || !address.show_val) return '';
  const search = {
    adcode: address.adcode,
    arr_city: address.city,
    arr_county: address.district,
    group_id: window.group_id,
    arr_province: address.province,
    arr_station: address.show_val,
    arr_street: address.street,
  };
  const req = { search_key: search, fields: ['up_delivery', 'arr_station'] };
  const res = await fetchApi('Table/Search/arrmanagesel', { method: 'POST', body: { req } });
  const match = res.res.data && res.res.data[0];
  const max = match ? +match.up_delivery : Infinity;
  return +coDelivery > max ? `您设置了到站“${match.arr_station}”的${ot('代收货款')}不能超过${max}元！` : '';
};

export const isNoService = (point, service) =>
  !!(point && point.company_no_service && point.company_no_service.includes(service));
