exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".order-editor-traffic{display:flex;flex-wrap:wrap;background-image:linear-gradient(270deg,#78aadd 1px,transparent 0),linear-gradient(0deg,#78aadd 1px,transparent 0);background-size:100% 35px}.order-editor-traffic>div,.order-editor-traffic>div.combine>div{flex:1 0 200px;display:flex;align-items:center;height:35px;padding:0 6px;border-right:1px solid #78aadd}.order-editor-traffic>div.combine>div>.fn-label,.order-editor-traffic>div>.fn-label{flex-shrink:0}.order-editor-traffic>div .ant-calendar-picker,.order-editor-traffic>div.combine>div .ant-calendar-picker,.order-editor-traffic>div.combine>div>.fn-address,.order-editor-traffic>div.combine>div>.fn-input-pure-wrap,.order-editor-traffic>div>.fn-address,.order-editor-traffic>div>.fn-input-pure-wrap{flex-grow:1}.order-editor-traffic>div.combine>div .fn-input-pure.hide-value,.order-editor-traffic>div .fn-input-pure.hide-value{background-position:0}.order-editor-traffic>div:empty{height:0}.order-editor-traffic>div.x2{flex:2 0 376px}.order-editor-traffic>div.lock-width{flex-grow:0;flex-shrink:0;width:188px}.order-editor-traffic>div.lock-width.x2{width:376px}.order-editor-traffic>div.combine{padding:0;border:0}.order-editor-traffic>div.combine>div{width:50%}.order-editor-traffic>div.combine>div:empty{border:0}.order-editor-traffic>div:not(:empty):nth-of-type(5)+div:empty,.order-editor-traffic>div:not(:empty):nth-of-type(5)+div:empty~div:empty{display:none}.order-editor-traffic .fn-select.order-trans-mode{margin-left:5px;flex:0 0 52px}.order-editor-traffic .fn-select.order-trans-mode .fn-input-pure{padding-left:14px}.order-editor-traffic .fn-select.order-trans-mode .input-tip{left:0;margin-left:-5px;right:auto!important}.order-editor-traffic .date-time-picker .ant-calendar-range-picker-separator{width:12px}.arr-info-show-val{max-width:240px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.arr-info-show-val .text-gray{padding-left:12px}", ""]);

// exports
exports.locals = {
	"prefixCls": "order-editor-traffic",
	"order-editor-traffic": "order-editor-traffic",
	"combine": "combine",
	"fn-label": "fn-label",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"fn-address": "fn-address",
	"ant-calendar-picker": "ant-calendar-picker",
	"fn-input-pure": "fn-input-pure",
	"hide-value": "hide-value",
	"x2": "x2",
	"lock-width": "lock-width",
	"fn-select": "fn-select",
	"order-trans-mode": "order-trans-mode",
	"input-tip": "input-tip",
	"date-time-picker": "date-time-picker",
	"ant-calendar-range-picker-separator": "ant-calendar-range-picker-separator",
	"arr-info-show-val": "arr-info-show-val",
	"text-gray": "text-gray"
};