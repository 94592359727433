exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"prefixCls": "order-editor-consignor"
};