import { SET_ORDER_DATA, FETCH_ORDER_DATA, MERGE_DATA } from '../constant';
import { calcSubTotal } from './tool';

const goodsMiddleware = page => store => {
  const onChange = (next, key, val) => {
    const state = store.getState();
    switch (key) {
      case 'frequentGoods': {
        if (state.isCreate && !page.wrap.querySelector('.goods-list [data-is-edited]')) {
          const { weightUnit } = state;
          const corGoods = { ...val, num: state.goods[0].num || 0 };
          const unitPUnit = corGoods.unit_p_unit;
          const unitP = +corGoods.unit_p || 0;

          if (unitPUnit === 'per_w_kg' || unitPUnit === 'per_w_t') {
            corGoods.unit_p_unit = 'per_w';
            if (weightUnit === '吨' && unitPUnit === 'per_w_kg') {
              corGoods.unit_p = +(unitP / 1000).toFixed(6);
            }
            if (weightUnit === 'kg' && unitPUnit === 'per_w_t') {
              corGoods.unit_p = +(unitP * 1000).toFixed(3);
            }
          }
          if (weightUnit === '吨' && corGoods.weight_per_num) {
            corGoods.weight_per_num = +((+corGoods.weight_per_num || 0) / 1000).toFixed(6);
            corGoods.weight = +(corGoods.weight_per_num * (+corGoods.num || 0)).toFixed(6);
          }
          const subTotal = calcSubTotal(corGoods, 'unit_p_unit');
          subTotal.subtotal_price
            ? (corGoods.subtotal_price = subTotal.subtotal_price)
            : delete corGoods.subtotal_price;
          page.setTable('goods', 0, corGoods);
        }
        break;
      }
      default:
        break;
    }

    // 价格计算结果可能会返回新的计算参数，这里尝试重新计算小计，否则小计结果会不正确
    // 比如用户填写了更大的重量，导致了单价进入另一个价格梯队
    // if (Array.isArray(state.goods)) {
    //   let calcSuccessCount = 0;
    //   state.goods.forEach((item, index) => {
    //     const isUserChanged = page.edited(`subtotal_price_${index + 1}`);
    //     // 若该行是用户手动填写的，则不进行重新计算
    //     if (isUserChanged) {
    //       return;
    //     }
    //     try {
    //       const newSubTotalResult = calcSubTotal(item, 'unit_p');
    //       if (newSubTotalResult && !isNaN(newSubTotalResult.subtotal_price)) {
    //         item.subtotal_price = newSubTotalResult.subtotal_price;
    //         calcSuccessCount++;
    //       }
    //     } catch (err) {
    //       console.log('[运费小计计算 error]', err);
    //     }
    //   });
    //   if (calcSuccessCount > 0) {
    //     page.merge('goods', state.goods);
    //   }
    // }
  };

  return next => action => {
    switch (action.type) {
      case SET_ORDER_DATA:
      case MERGE_DATA:
        onChange(next, action.payload.key, action.payload.val, action.index, action.changes);
        break;
      case FETCH_ORDER_DATA: {
        setTimeout(() => {
          const state = store.getState();
          if (state.isCreate && state.ext.frequent_goods) {
            page.set('frequentGoods', state.ext.frequent_goods);
          }
        });
        break;
      }
      default:
        break;
    }
    next(action);
  };
};

export default goodsMiddleware;
