exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".coinfomap_popup{width:320px}.coinfomap_popup .fn-show_info__icon{float:left}.coinfomap_popup .fn-icon{color:#0097cf}.point_map_info{overflow-y:hidden;height:calc(100% - 309px);border:1px solid #ccc;border-top:0 none}.point_map_info h2{height:32px;background:#f5f5f5;border-bottom:1px solid #ccc;padding:0 10px;font-weight:700;font-size:14px;color:#333;line-height:32px}.point_map_info h2 em{color:#378cbe}.point_map_info div{padding:10px;max-height:calc(100% - 32px);overflow-y:auto}.point_map_info div h5{font-size:14px;line-height:24px;color:#378cbe}.point_map_info div p{line-height:24px;position:relative;width:100%;font-size:14px}.point_map_info div .single{display:flex;padding-left:24px}.point_map_info div .single .fn-icon{color:#378cbe;font-size:14px;width:14px;height:14px;position:absolute;top:5px;left:1px}.point_map_info div .single em{letter-spacing:2px;color:#378cbe;flex:0 0 auto}.point_map_info div .fn-icon+span{width:calc(100% - 24px);height:24px;overflow:hidden;white-space:normal;display:inline-block;text-overflow:ellipsis;line-height:24px;font-size:14px}", ""]);

// exports
exports.locals = {
	"coinfomap_popup": "coinfomap_popup",
	"fn-show_info__icon": "fn-show_info__icon",
	"fn-icon": "fn-icon",
	"point_map_info": "point_map_info",
	"single": "single"
};