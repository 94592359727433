/**
 * Created by wangnaihe on 2017/6/19.
 */
import React from 'react';
import Map from './map';
import { CEE_ARR_MATCHED } from '../constant';
// import './reducer'

function renderMap(page) {
  function onMatch(result) {
    const { store } = page;
    store.dispatch({ type: CEE_ARR_MATCHED, payload: result });
  }
  return function () {
    const { store } = page;
    const state = store.getState();
    const { companyId, showMap, ceeMatchArr, showArrPoint, ceeAddrInfo, arrPoint, isCreate } = state;

    // if ((this.props.isWhere !== 'oinfo' && !this.state.odBasicId) &&
    if (showMap) {
      return (
        <Map
          showArrPoint={showArrPoint}
          ceeMatchArr={ceeMatchArr && isCreate}
          arrPoint={arrPoint}
          ceeAddr={ceeAddrInfo}
          onMatch={onMatch}
          companyId={companyId}
        />
      );
    }
    return null;
  };
}

export default renderMap;
