import { fetchApi as fetch, typeIs } from 'utils';
import { convertWeight } from '../tool';
import { goodsKeys, needTrimKeys, alwaysSaveKeys, distributeKeys } from '../constant';

const saveOrder = (order, btn, mergeReq, saveHiddenFields) => {
  const { weightUnit, reservationNum, camelKey, keyMap, getMap, route } = order;
  const keys = Object.keys(camelKey).filter(key => saveHiddenFields || order[`${key}Show`]);
  const showGoodsKeys = goodsKeys.filter(key => order[`${keyMap[key]}Show`]);
  const normalKeys = keys.filter(key => !goodsKeys.includes(keyMap[key]));
  const goods = weightUnit === '吨' ? convertWeight(order.goods, true) : order.goods;
  const req = { ...mergeReq };
  normalKeys.forEach(key => {
    if (key === 'goods') {
      req.goods = [];
      goods.forEach((item, index) =>
        showGoodsKeys.forEach(k => {
          const val = item[k];
          const isEmpty = val === '' || val === null || val === undefined;
          req.goods[index] = req.goods[index] || {};
          req.goods[index][k] = isEmpty ? '' : needTrimKeys[k] ? val.trim() : val;
          req.goods[index].subtotal_price = item.subtotal_price || '';
        }),
      );
      return;
    }
    const val = order[key];
    const isEmpty = val === '' || val === null || val === undefined;
    const reqKey = keyMap[key];
    req[reqKey] = isEmpty ? '' : val;
    getMap[key] && typeIs(val, 'object') && getMap[key] in val && (req[reqKey] = val[getMap[key]]);
    needTrimKeys[reqKey] && (req[reqKey] = typeIs(val, 'string') ? val.trim() : val);
  });

  req.sys_order_num = order.origin.order_data.order_num.value;
  req.reservation_num = reservationNum;
  alwaysSaveKeys.forEach(key => (req[key] = order[keyMap[key]]));
  Object.keys(distributeKeys).forEach(key => delete req[key]);
  Object.assign(req, route);
  if (req.product_type) {
    req.product_key = req.product_type.key;
    req.product_type = req.product_type.name;
  }

  if (req.product_line) {
    req.product_line = [req.product_line];
  }

  if (req.remark && typeIs(req.remark, 'array')) {
    req.remark = req.remark.join(',');
  }

  if (req.rcv_stn && typeof req.rcv_stn === 'object') {
    req.rcv_stn_name = req.rcv_stn.short_name;
    req.rcv_stn = req.rcv_stn.id;
  }

  if (req.receipt_require && typeIs(req.receipt_require, 'array')) {
    req.receipt_require = req.receipt_require.join(',');
  }
  if (!order.hasCashier) {
    if (!order.payBillingPaidShow && req.pay_billing) {
      req.pay_billing_paid = true;
    }
    if (!order.cashreturnPaidShow && req.cashreturn) {
      req.cashreturn_paid = true;
    }

    if (!order.rebatePaidShow && req.rebate) {
      req.rebate_paid = true;
    }

    if (!order.coPayAdvPaidShow && req.co_pay_adv) {
      req.co_pay_adv_paid = true;
    }
  }
  const url = `/Order/Order/coHandle?btn_type=${btn}&co_logid=${order.logid}`;
  const conf = { method: 'POST', body: { req, from: 'pc' } };
  return fetch(url, conf);
};

export default saveOrder;
