exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".point-goods-modal .fn-dialog__title{background:#eaeaea 100%;color:#000}.point-goods-modal .fn-input-pure-wrap{width:100%}.point-goods-modal table{table-layout:fixed}.point-goods-modal thead{background:#ecf0f5;text-align:center}.point-goods-modal thead td{height:30px;line-height:30px}.point-goods-modal tbody td{padding:1px}", ""]);

// exports
exports.locals = {
	"prefixCls": "point-goods-modal",
	"point-goods-modal": "point-goods-modal",
	"fn-dialog__title": "fn-dialog__title",
	"fn-input-pure-wrap": "fn-input-pure-wrap"
};