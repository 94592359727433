import { saveAs } from 'file-saver';
import { post } from 'utils';

import XLSX from 'xlsx-style';
// import XLSX from 'xlsx-style-jszip3'
export function tooArrayBufferView(str) {
  const _str = str;
  const buf = new ArrayBuffer(_str.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== _str.length; ++i) {
    view[i] = _str.charCodeAt(i) & 0xff; // eslint-disable-line
  }
  return buf;
}

export function cellStyle(rowIndex, colIndex, data, batchInfoLen, multiTableParams) {
  const { isMultiTable, multiHeadersCount, isInsertAvg } = multiTableParams ?? {};
  let style = {};
  const optionData = batchInfoLen || 0;
  style = {
    font: {
      sz: 10,
      name: 'Microsoft YaHei',
    },
    border: {
      top: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
    },
  };
  if (batchInfoLen && rowIndex === 0) {
    style.font.sz = 20;
    style.font.bold = true;
  } else if (batchInfoLen && rowIndex < batchInfoLen) {
    style.font.sz = 10;
    style.font.bold = true;
    style.alignment.horizontal = 'left';
  } else if (
    rowIndex === optionData ||
    rowIndex === data.length - 1 ||
    (isMultiTable && rowIndex <= multiHeadersCount) ||
    (isInsertAvg && rowIndex === data.length - 2)
  ) {
    // style.fill = { fgColor: { rgb: 'f2f2f2' } }
    style.font.bold = true;
    style.font.sz = 11;
    style.fill = { fgColor: { rgb: 'e5e5e5' } };
  } else if (rowIndex % 2 === 0 && rowIndex > optionData - 1) {
    style.fill = { fgColor: { rgb: 'f2f2f2' } };
  }
  return style;
}

const isSafari = Number.isNaN(Date.parse('2012-01-01 00:00:00'));

export function dateFormt(v, date1904, type) {
  let _v = v;
  const isMutilDate = v && typeof v === 'string' && /,|;|；|、/.test(v);
  if (isMutilDate) return v;
  if (v && typeof v === 'string' && isSafari) {
    _v = v.replace(/-/g, '/');
  }
  if (date1904) {
    _v += 1462;
  }
  const epoch = Date.parse(_v) + 8 * 60 * 60 * 1000;
  /* 42590
  if (type > 1) {
    epoch = Date.parse(_v)
  }
  */
  return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}

export const SPECIALKEY = ['运单号', '货号'];

export function trim(str) {
  return str.replace(/^\s+|\s+$/g, '');
}

export function sheetChangeData(sheetData, excelDataType, columnWidths, batchInfoLen, multiTableParams) {
  const { isMultiTable = false, multiHeaders = [], isInsertAvg = false } = multiTableParams ?? {};
  const sheetFormatData = {};
  const wscols = [];
  let widthPt = 1;
  const batchInfoLength = batchInfoLen || 0;
  const range = {
    s: {
      c: 10000000,
      r: 10000000,
    },
    e: {
      c: 0,
      r: 0,
    },
  };
  let newData = [...sheetData];
  let merges = [];
  if (isMultiTable) {
    newData.shift();
    newData = [...multiHeaders, ...newData];
    merges = doMerges([...multiHeaders, []]);
  }
  newData.forEach((item, R) => {
    // 循环行
    excelDataType.forEach((item2, C) => {
      // 循环列
      if (range.s.r > R) range.s.r = R;
      if (range.s.c > C) range.s.c = C;
      if (range.e.r < R) range.e.r = R;
      if (range.e.c < C) range.e.c = C;
      const cell = {
        v: newData[R][C] === null || newData[R][C] === undefined ? '' : newData[R][C],
      };
      const cellCoordinate = XLSX.utils.encode_cell({
        c: C,
        r: R,
      });

      if (R > batchInfoLength && cell.v !== undefined && cell.v !== '') {
        if (excelDataType[C] === 'Text') {
          cell.t = 's';
          // 其实是纯数字，并且不以0开头; 并且不是 科学技术法 数字，如：24E069032
          cell.v &&
            SPECIALKEY.includes(sheetData[batchInfoLength][C]) &&
            !Number.isNaN(Number(cell.v)) &&
            cell.v.indexOf('e') === -1 &&
            cell.v.indexOf('E') === -1 &&
            cell.v.indexOf('0') !== 0 &&
            cell.v.length < 11 &&
            (cell.v = Number(cell.v)) &&
            (cell.t = 'n');
          // @see https://issues.chinawayltd.com/browse/CYTRD-13338 屏蔽Excel工具（如：WPS）将"900383454,900383453" 形式识别成「货币数字」
          // @see https://github.com/markatil/xlsx-style/blob/master/dist/xlsx.js#L1386 escapexml
          // 在 xlsx 底层，也是将数组转换成逗号分隔的字符串，这里在提前进行转换：逗号加空格 分割
          Array.isArray(cell.v) && (cell.v = cell.v.join(', '));
        } else if (excelDataType[C] === 'Number') {
          cell.t = 'n';
          // 字符串数字转为数字 并且去掉,号分隔符
          cell.v &&
            (!Number.isNaN(Number(cell.v)) || !Number.isNaN(Number(cell.v.toString().replace(/,/g, '')))) &&
            (cell.v = Number(cell.v.toString().replace(/,/g, '')));
          // 类型为数字，但是是字符串
          cell.v && Number.isNaN(Number(cell.v)) && (cell.t = 's');
        } else if (excelDataType[C] === 'DateText') {
          cell.v = dateFormt(cell.v, undefined, 1);
          if (typeof cell.v === 'string') {
            cell.t = 's';
          } else {
            cell.t = 'n';
            cell.z = 'YYYY-MM-DD HH:mm:ss';
          }
        } else if (excelDataType[C] === 'DateYmdText') {
          cell.v = dateFormt(cell.v, undefined, 2);
          if (typeof cell.v === 'string') {
            cell.t = 's';
          } else {
            cell.t = 'n';
            cell.z = 'YYYY-MM-DD';
          }
        } else if (excelDataType[C] === 'DateYmText') {
          cell.v = dateFormt(trim(cell.v), undefined, 3);
          if (typeof cell.v === 'string') {
            cell.t = 's';
          } else {
            cell.t = 'n';
            cell.z = 'YYYY-MM';
          }
        }
      } else if (cell.v === '' && excelDataType[C] === 'Number') {
        // 类型是数字 但是是空值
        cell.t = 'n';
      } else {
        cell.v === undefined && (cell.v = '');
        cell.t = 's';
      }

      cell.s = cellStyle(R, C, newData, batchInfoLen, {
        isMultiTable,
        multiHeadersCount: multiHeaders?.length - 1,
        isInsertAvg,
      });
      sheetFormatData[cellCoordinate] = cell;
      R === 0 && (widthPt = Math.round(columnWidths[C] * 0.91)) && wscols.push({ wpx: widthPt }); // eslint-disable-line

      if (R < batchInfoLen && !isMultiTable) {
        if (C === 0) {
          merges[R] = {};
          merges[R].s = { c: C, r: R };
        }
        if (C === excelDataType.length - 1) {
          merges[R].e = { c: C, r: R };
        }
      }
    });
  });
  sheetFormatData['!merges'] = merges;
  sheetFormatData['!cols'] = wscols;
  sheetFormatData['!ref'] = XLSX.utils.encode_range(range);
  return sheetFormatData;
}
export async function exportSafeManagerNotice(params) {
  try {
    const res = await post(`/Basic/ShortMessage/exportSafeManagerNotice?timestamp=${new Date().valueOf()}`, {
      ...params,
    });
    if (res?.errno !== 0) {
      console.log(res.error);
    }
  } catch (error) {
    console.log(error);
  }
}
export default function ExportJsonExcelFn(option) {
  const { datas, fileName, noticeParams = null } = option;
  const _this = {};
  _this.fileName = fileName || 'download';
  _this.datas = datas;
  _this.workbook = {
    SheetNames: [],
    Sheets: {},
  };

  const { workbook } = _this;

  _this.datas.forEach((data, index) => {
    const { sheetData } = data;
    const { batchInfoLen } = data;
    const sheetName = data.sheetName || `sheet${index + 1})`;
    const { excelDataType, columnWidths, isMultiTable = false, multiHeaders = [], isInsertAvg = false } = data;

    const sheetFormatData = sheetChangeData(sheetData, excelDataType, columnWidths, batchInfoLen, {
      isMultiTable,
      multiHeaders,
      isInsertAvg,
    });
    // sheetFormatData['!merges'] = []
    // workbook.SheetNames.push(sheetName)
    // for test not enought data
    workbook.SheetNames.push(sheetName);
    workbook.Sheets[sheetName] = sheetFormatData;
  });

  const content = XLSX.write(workbook, {
    bookType: 'xlsx',
    // bookType: 'xls',
    bookSST: false,
    type: 'binary',
    // type: 'buffer',
    compression: false,
  });
  const blobData = new Blob([tooArrayBufferView(content)], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const file = saveAs(blobData, `${_this.fileName}.xlsx`);
  if (noticeParams) {
    exportSafeManagerNotice({
      ...noticeParams,
    });
  }
  window.$app.showBottomTips({
    type: 'hideExportTip',
    data: '文件导出中，请勿重复操作导出！',
  });
  console.log(file);
  // file.readyState == 0
}
/**
 * 合并头部单元格
 * */
function doMerges(arr) {
  // 要么横向合并 要么纵向合并
  const deep = arr.length;
  const merges = [];
  for (let y = 0; y < deep; y++) {
    // 先处理横向合并
    const row = arr[y];
    let colSpan = 0;
    for (let x = 0; x < row.length; x++) {
      if (row[x] === '!$COL_SPAN_PLACEHOLDER') {
        row[x] = undefined;
        if (x + 1 === row.length) {
          merges.push({ s: { r: y, c: x - colSpan - 1 }, e: { r: y, c: x } });
        }
        colSpan++;
      } else if (colSpan > 0 && x > colSpan) {
        merges.push({ s: { r: y, c: x - colSpan - 1 }, e: { r: y, c: x - 1 } });
        colSpan = 0;
      } else {
        colSpan = 0;
      }
    }
  }
  // 再处理纵向合并
  const colLength = arr[0].length;
  for (let x = 0; x < colLength; x++) {
    let rowSpan = 0;
    for (let y = 0; y < deep; y++) {
      if (arr[y][x] === '!$ROW_SPAN_PLACEHOLDER') {
        arr[y][x] = undefined;
        if (y + 1 === deep) {
          merges.push({ s: { r: y - rowSpan, c: x }, e: { r: y, c: x } });
        }
        rowSpan++;
      } else if (rowSpan > 0 && y > rowSpan) {
        merges.push({ s: { r: y - rowSpan - 1, c: x }, e: { r: y - 1, c: x } });
        rowSpan = 0;
      } else {
        rowSpan = 0;
      }
    }
  }
  return merges;
}
