/**
 * Created by JANY on 2017/1/5.
 */
import { SET_ORDER_DATA, SELECT_COR_SUG } from '../../constant';
import calcCommission from '../../public/calcCommission';
import { isNoService } from './tool';

const effectCoDeliveryFeeKeys = {
  coDelivery: 1,
  coDeliveryRatio: 1,
  arrPoint: 1,
  route: 1,
  coDeliveryReleaseDays: 1,
  transMode: 1,
};

const onChange = (page, store, next, key, val) => {
  const state = store.getState();
  const { commissionSetting } = state;

  // 代收手续费非手动输时关联计算
  if (
    effectCoDeliveryFeeKeys[key] &&
    commissionSetting.checked !== 'manual' &&
    commissionSetting.checked !== 'price' &&
    !page.edited('co_delivery_fee')
  ) {
    const {
      coDelivery,
      coDeliveryRatio,
      arrPoint,
      transMode,
      route,
      coDeliveryReleaseDays,
      freeDelivery,
      maxCoDeliveryFee,
    } = state;
    const effectValues = { coDelivery, coDeliveryRatio, arrPoint, transMode, route, coDeliveryReleaseDays, [key]: val };
    const coDeliveryFee = calcCommission(effectValues, commissionSetting, freeDelivery, maxCoDeliveryFee);
    const oldCoDeliveryFee = +state.coDeliveryFee;
    // !!!!!coDeliveryFee是代收手续费!!!!!
    coDeliveryFee !== oldCoDeliveryFee && page.set('coDeliveryFee', coDeliveryFee ? +coDeliveryFee.toFixed(2) : '');
  }

  switch (key) {
    case 'coDelivery': {
      const { keyMap, setting, memberCode } = state;
      // 代收货款变化时 代收手续费的值清空
      if (!+val > 0) {
        page.set('coDeliveryFee', '');
      }
      if (memberCode && memberCode.co_delivery_mode === '银行卡') {
        setting.co_delivery_required.value.forEach(k =>
          page.merge(`${keyMap[k]}Required`, +val > 0 ? true : state[`${keyMap[k]}SettingRequired`]),
        );
      }
      break;
    }
    case 'memberCode': {
      const { keyMap, setting, coDelivery } = state;
      if (+coDelivery > 0) {
        const required = val && val.co_delivery_mode === '银行卡';
        setting.co_delivery_required.value.forEach(k =>
          page.merge(`${keyMap[k]}Required`, required ? true : state[`${keyMap[k]}SettingRequired`]),
        );
      }
      break;
    }
    case 'arrPoint': {
      state.coDelivery && isNoService(val, 'co_delivery') && page.set('coDelivery', '');
      state.coDeliveryFreight && isNoService(val, 'co_delivery_freight') && page.set('coDeliveryFreight', '');
      break;
    }
    default:
      break;
  }
};

const collectMiddleware = page => store => next => action => {
  // const state = store.getState()
  switch (action.type) {
    case SET_ORDER_DATA:
      onChange(page, store, next, action.payload.key, action.payload.val);
      break;
    case SELECT_COR_SUG: {
      const { data } = action.payload;
      // 发货人带出 代收手续费费率
      page.set('coDeliveryRatio', data.co_delivery_ratio || 0);

      // if (state.memberInfoUponCor && data.telephone) {
      //   // 修改会员编号
      //   const url = '/Order/Order/getMemberInfo/'
      //   const req = { cor_mobile: data.telephone, query: { contact_phone: data.telephone } }
      //   const conf = { method: 'POST', body: { req } }
      //   fetch(url, conf).then(res => store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'memberCode', val: res.res.data } }))
      // }
      break;
    }
    default:
      break;
  }
  next(action);
};

export default collectMiddleware;
