module.exports = {
  printList: {
    Settle: {
      ids: 'bill_id',
    },
    Loss: {
      ids: 'Order|od_link_id',
    },
    TruckRecord: {
      ids: 'id',
    },
  },
  printHead: {
    Finance_dailyIOSummary: {
      // status: 'audit_status_disp',
      amount: 'balance_amount',
    },
    Finance_dailyIODetail: {
      // status: 'audit_status_disp',
      amount: 'balance_amount',
    },
    Finance_cliRelList: {
      // status: 'pa_re_audit',
      amount: 'ac_amount',
    },
    Finance_paymentReceivedBillList: {
      // status: 'pa_re_audit',
      amount: 'receipt_payment_amount',
    },
    Finance_payBillList: {
      // status: 'audit_status_disp',
      amount: 'em_account_amount',
    },
    Finance_branchRecList: {
      // status: 'pa_re_audit',
      amount: 'ac_amount',
    },
    Finance_settleLog: {
      // status: 'settle_audit',
      amount: 'amount',
    },
    Finance_settleFeeLog: {
      // status: 'settle_audit',
      amount: 'amount_sum',
    },
    Finance_loanDriver: {
      amount: 'amount',
    },
    taskFinance_cliRelListTask: {
      amount: 'ac_amount',
    },
  },
  printConfig: {
    TruckRecord_truck_record_v1_print_truckrecord: {
      title: '车辆记录',
      ids: 'id',
      company_id: 'company_id',
      printHead: {
        tr_num: { title: '车牌号', type: 'Text', display: 'show', width: 45, align: 'center', flexGrow: 1 },
        use_record_type: {
          title: '使用记录类型',
          type: 'Text',
          display: 'show',
          width: 45,
          align: 'center',
          flexGrow: 1,
        },
      },
    },
  },
};
