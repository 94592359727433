import React from 'react';
import { PopUp, PopTip, ModalDialog } from 'components'; // eslint-disable-line
import getBatchDetail from 'components/commoncomponents/BatchDetail';
import {
  postData as gpostData,
  getPageKey,
  fromJS,
  filterListColumnByKeyList,
  createTip,
  showInfo,
  viewImage,
  flatV1,
  fetchJAVA,
  hasPermission,
} from 'utils';
import { ERROR, BATCH_TYPE, CHECK } from 'constants';
import { directToTaskDetail } from 'utils/business/task/direct';
import mockData from 'components/commoncomponents/BatchDetail/mockData';
import SimilarFee from 'components/commoncomponents/similarFee';
import Reload from 'pages/TruckController/components/Reload';
import actions from 'actions';
import { preArrivalCheck } from './orderOp';
import truckBatchConfig from './truckBatchOpConfig';
import { roundTripDetail } from 'operation/listOperation/reportsOp.js';
import { ImageTag } from 'components/commoncomponents/listOperation/truckOp/batchTaskAdd';
import _ from 'lodash';
/*
 #########此文件维护(车辆、送货、提货、短驳、中转)批次详情以及批次详情修改操作##################
 */
function checkOp(idIndex) {
  if (idIndex.length <= 0) {
    createTip('请选择操作批次', ERROR).show();
    return false;
  }
  if (idIndex.length > 1) {
    createTip('不能操作多个批次', ERROR).show();
    return false;
  }
  return true;
}
// 提取数据中的参数
function getParameter(dataList, buttonKey, key) {
  // 此处太坑
  const configInfo = truckBatchConfig.buttonKeys[buttonKey];
  const batchNo =
    (configInfo && configInfo.batchNo && dataList[0][configInfo.batchNo]) || dataList[0].car_batch || dataList[0][key];
  const batchId =
    (configInfo && configInfo.batchId && dataList[0][configInfo.batchId]) ||
    dataList[0].b_link_id ||
    dataList[0].b_pickup_batch_id ||
    dataList[0]['Batch|b_link_id'];
  const basicId = (configInfo && configInfo.basicId && dataList[0][configInfo.basicId]) || dataList[0].b_basic_id;
  const comId =
    (configInfo && configInfo.comId && dataList[0][configInfo.comId]) ||
    dataList[0].b_company_id ||
    dataList[0].company_id;
  const id = (configInfo && configInfo.id && dataList[0][configInfo.id]) || dataList[0].id;
  const type = (configInfo && configInfo.operateType) || 'add';
  const hasTab = (configInfo && configInfo.hasTab) || '';
  const isEdit = (configInfo && configInfo.isEdit) || '';
  const transtaskFlag = dataList[0].transtask_flag;
  const receiptAuditSt = dataList[0].receipt_audit_st;
  const batchSt = dataList[0].batch_st;
  return {
    batchNo,
    batchId,
    basicId,
    comId,
    type,
    hasTab,
    isEdit,
    id,
    transtaskFlag,
    receiptAuditSt,
    batchSt,
  };
}
// 根据设置打开侧拉或者新的页面
function openBatchdetail(param) {
  console.log(param, 'paramparamparamparam');
  if (window.company_setting.op_page_type && window.company_setting.op_page_type.value === 'new_page') {
    const urlData = {
      controller: 'Truck',
      action: 'detail',
      query: {
        tabName: param.batchNo,
        batchId: param.batchId,
      },
    };
    actions.api.direct(urlData, 'directInfo', param);
    return;
  }
  new PopUp(getBatchDetail(), param).show();
}

const customValidity = val => {
  if (val && val.black_set === '1') {
    // showInfo(WARN, '当前司机已被拉黑')
    return { type: 'warn', msg: '当前司机已被拉黑' };
  } else if (val && val.black_set === '2') {
    return '当前司机已被拉黑';
  }
  return '';
};
// 批次修改-车辆配载／送货／提货／短驳
export const batchModify = function (that, buttonKey) {
  const { category, tab } = that.tableInfo;
  const dataList = that.vTable.getStateSelecteRows();
  if (!checkOp(dataList)) return;
  // 侧拉详情的编辑状态 是没有日志跟踪的
  const pageKey = getPageKey().pageConfig.key;
  if (!checkOp(dataList)) return;
  const data = dataList[0] || {};
  console.log('blk_status', data.blk_status);
  console.log('transtask_flag', data.transtask_flag);
  const tabMap = ['all_pay_mgr', 'tr_pay_mgr', 'pickup_pay_mgr', 'delivery_pay_mgr', 'shuttle_pay_mgr'];
  if (category === 'Batch' && tabMap.includes(tab) && +data.blk_status === 2 && +data.transtask_flag !== 0) {
    directToTaskDetail({
      from: 'taskList',
      batchID: data.b_basic_id || data['Batch|b_basic_id'],
      carBatch: data.car_batch || data['Batch|car_batch'],
      comID: data.company_id || data['Batch|company_id'],
    });
    return;
  }
  const typeMap = BATCH_TYPE;
  const type = typeMap[data.type] || data.batch_log_type;
  const pageInfo = that.getTableInfo();
  const parmInfo = getParameter(dataList, buttonKey);
  const pageTab = fromJS(pageInfo).getIn(['pageConfig', 'tableInfo', 'tab']);
  const pack = {
    type: parmInfo.type,
    b_link_id: parmInfo.batchId,
    company_id: parmInfo.comId,
    id: parmInfo.id,
    batchSt: parmInfo.batchSt,
  };
  const pageUrl = (mockData[type || pageTab] || {}).pageUrl || '/Truck/Truck/onLoading';
  // 是否为预装车
  if (type === 'pre_tr_up') {
    pack.isPreLoad = true;
  }
  gpostData(pageUrl || '/Truck/Truck/onLoading', pack, res => {
    if (res.data.errno !== 0) {
      createTip(res.data.errmsg, ERROR).show();
      return;
    }
    const dr_id = _.get(res.data.res, 'batch_info.b_dr_id');
    const dr_name = _.get(res.data.res, 'batchTitleInfo.cardInfo.dr_name');
    const dr_phone = _.get(res.data.res, 'batchTitleInfo.cardInfo.dr_phone');
    const dr_assistant_name = _.get(res.data.res, 'batchTitleInfo.cardInfo.dr_assistant_name');
    const dr_assistant_phone = _.get(res.data.res, 'batchTitleInfo.cardInfo.dr_assistant_phone');

    if (dr_name) {
      res.data.res.batchTitleInfo.cardInfo.dr_name.customValidity = customValidity;
    }
    if (dr_phone) {
      res.data.res.batchTitleInfo.cardInfo.dr_phone.customValidity = customValidity;
    }
    if (dr_assistant_name) {
      res.data.res.batchTitleInfo.cardInfo.dr_assistant_name.customValidity = customValidity;
    }
    if (dr_assistant_phone) {
      res.data.res.batchTitleInfo.cardInfo.dr_assistant_phone.customValidity = customValidity;
    }
    const param = Object.assign(
      {
        popName: `operatSlideDrag_${pageKey}`,
        pageTab,
        pageType: 'add',
        closeCallback: that.handleRefreshData,
        pickOrderInfo: fromJS(res.data.res),
        listPage: that,
        realTab: type || pageTab,
        dr_id,
      },
      parmInfo,
    );
    openBatchdetail(param);
  });
};

// link批次预览-车辆配载／送货／提货／短驳, linkItem 有调用未传递情况
export const batchView = function (that, index, buttonKey, data, linkItem, tableInfoObj) {
  console.log(linkItem, tableInfoObj);
  console.log('batchView =', index, buttonKey);
  let tableInfo = tableInfoObj || _.cloneDeep(that.tableInfo) || {};
  tableInfo.tab = flatV1(tableInfo.tab);
  if (tableInfo.category === 'Reservation' && buttonKey === 'b_pickup_batch') {
    tableInfo = {
      body: { fetch_mode: 'all' },
      category: 'Batch',
      tab: 'pickup_reservation_batch',
      url: 'Table/Search/batchList',
    };
  }
  const toScheduleTask = [
    'transport_task_batch',
    'transport_task_dispatch_driver',
    'transport_task_batch_not_accept',
    'transport_task_batch_accepted',
    'transport_task_batch_done',
    'ledger_batch_onway',
    'payment_batch_onway',
    'relate_task_list',
    'settle_batch_task',
    'payment_batch_task',
    'project_batch_detail',
    'project_batch_bill_detail',
    'tt_report',
    'ledger_batch_task',
    'doc_batch_task_detail',
    'bm_list',
    'list_customer_assess',
    'list_customer_mycreate',
    'list_customer_foraudit',
    'list_delivery_assess',
    'list_delivery_mycreate',
    'list_delivery_foraudit',
    'task_delivery_assess',
    'task_customer_assess',
    'b_fee_list_popup',
    'b_fee_list',
    'b_fee_list_self_create',
    'b_fee_list_self_audit',
    'payment_batch_onway_bill_fee_detail', // 在途费用付款单详情
  ];
  // 跳转到任务详情页   transtask_flag是一个标记位   如: 资金流水明细表
  const transTaskFlagKeys = ['Batch|transtask_flag', 'transtask_flag', 'b_transtask_flag'];
  const toJumpTranstask = transTaskFlagKeys.some(key => data[key] && +data[key] !== 0);

  const isPlan = tableInfo.tab === 'plan_transport_task';
  // 支付管理-任务直接进入新页面
  const tabMaps = ['all_pay_mgr', 'tr_pay_mgr', 'pickup_pay_mgr', 'delivery_pay_mgr', 'shuttle_pay_mgr'];
  const directNewPage =
    tabMaps.includes(tableInfo.tab) &&
    tableInfo.category === 'Batch' &&
    +data.blk_status === 2 &&
    +data.transtask_flag !== 0;
  // 增加结算账户页面点击的批次号为任务时直接跳转详情页 task_flag: 1为任务 0为批次
  const tradeRecordDirect =
    tableInfo.tab === 'settle_account_tr' &&
    tableInfo.category === 'TradeRecord' &&
    _.get(data, 'tr_fare.task_flag') === 1;
  if (
    tableInfo.tab &&
    (toScheduleTask.includes(tableInfo.tab) ||
      data.abn_category === '4' ||
      +data.apply_type === 7 ||
      toJumpTranstask ||
      directNewPage ||
      tradeRecordDirect)
  ) {
    directToTaskDetail({
      from: isPlan ? 'planTaskList' : 'taskList',
      batchID: data.b_basic_id || data['Batch|b_basic_id'] || _.get(data, 'tr_fare.b_basic_id'),
      carBatch: data.car_batch || data['Batch|car_batch'] || data.batch_num,
      comID: data.b_company_id || data.company_id || data['Batch|company_id'] || _.get(data, 'tr_fare.company_id'),
      uuid: data.uuid,
      isPlan,
    });
    return;
  }
  const realData = data;
  const pageKey = getPageKey().pageConfig.key;
  const dataList = [realData]; // 获取的是选中行数据中的某些指定key
  const typeMap = BATCH_TYPE;
  let type = typeMap[realData.type || realData.b_type] || realData.batch_log_type;
  const abnLink = {
    1: 'tr_up',
    2: 'tr_down',
    3: 'shuttle_up',
    4: 'shuttle_down',
    5: 'delivery_batch',
    6: 'pickup_batch',
  };
  if (!checkOp(dataList)) return;
  const pageTab = tableInfo.tab;
  const pageCategory = tableInfo.category;
  const parmInfo = getParameter(dataList, `${pageCategory}_${buttonKey}`, buttonKey);
  // 新增配载 => 发车清单批处理
  if (+dataList[0].blk_next_id && +dataList[0].next_company_id) {
    parmInfo.tr_up_op = {};
    parmInfo.tr_up_op.batchId = dataList[0].blk_next_id;
    parmInfo.tr_up_op.comId = dataList[0].next_company_id;
  }
  if (pageTab === 'platform_task') {
    // 平台任务
    parmInfo.hasTab = false;
  }
  if (pageTab === 'business_detail') {
    // 平台任务，点对账单号进详情，再点批次号时
    type = 'platform_task';
  }
  if (pageTab === 'abnormal') {
    type = abnLink[dataList[0]['Batch|type']];
  }
  if (
    pageTab === 'sr_ledger_batch' &&
    (buttonKey === 'Order|b_tr_car_batch' || buttonKey === 'Order|b_tr_up_car_batch')
  ) {
    const newTypeMap = { 10: 'tr_up', 20: 'tr_down' };
    type = newTypeMap[realData.sr_site_type];
  }
  const trDownPrintType = pageTab === 'point_batch_settle_detail' && pageCategory === 'BatchAccount';
  const goodsInfo = filterListColumnByKeyList(dataList, ['b_tr_load_n_s', 'b_tr_load_w_s', 'b_tr_load_v_s']);
  const pack = {
    type: ['tr_up', 'tr_up_r'].includes(pageTab) ? 'tr_up' : type || pageTab,
    b_link_id: parmInfo.batchId,
    company_id: parmInfo.comId,
    read_only: 1,
  };
  const defaultUrl = '/Truck/Truck/onLoading';
  const deliveryUrl = '/Truck/Delivery/onLoading';
  const packTab = type === 'pack_default';
  const pack_referer = packTab && pageTab !== 'pack_default' ? '' : type;
  pack.pack_referer = pack_referer;
  let pageUrl = (mockData[type || pageTab] || {}).pageUrl || defaultUrl;
  // 打包管理可点击打包单详情，发车、送货批次
  if (packTab && buttonKey === 'shuttle_batch') {
    pack.type = 'tr_up';
    pack.b_link_id = dataList[0].b_tr_link_id;
    pageUrl = defaultUrl;
    parmInfo.batchId = dataList[0].b_tr_link_id;
    parmInfo.basicId = dataList[0].b_tr_basic_id;
  }
  if (packTab && buttonKey === 'delivery_batch') {
    pack.type = 'delivery_batch';
    pack.b_link_id = dataList[0].b_delivery_batch_id;
    pageUrl = deliveryUrl;
    parmInfo.batchId = dataList[0].b_delivery_batch_id;
    parmInfo.basicId = dataList[0].b_delivery_basic_id;
  }
  console.log(dataList, 'dataListdataList');
  if (
    (realData.ol_ext_info && realData.ol_ext_info.is_reservation_pickup === 1) ||
    tableInfo.category === 'Reservation'
  ) {
    pageUrl = mockData.pickup_reservation.pageUrl;
  }
  gpostData(pageUrl || '/Truck/Truck/onLoading', pack, res => {
    if (res.data.errno !== 0) {
      createTip(res.data.errmsg, ERROR).show();
      return;
    }
    if (!parmInfo.basicId) {
      parmInfo.basicId = res.data.res.batch_info.b_basic_id;
    }
    const param = Object.assign(parmInfo, {
      popName: `BatchDetailSlider_${pageKey}`,
      pageTab,
      type: tableInfo.tab,
      trDownPrintType,
      goodsInfo: goodsInfo[0],
      realTab: pageTab === 'tr_up' ? 'tr_up' : type,
      pageType: 'show',
      listPage: that,
      batchLogType: realData.batch_log_type,
      closeCallback: that.handleRefreshData || (that.tableInfo || {}).handleRefreshData,
      pickOrderInfo: fromJS(res.data.res),
      buttonKey,
      batchSt: dataList[0].batch_st,
    });
    openBatchdetail(param);
  });
};
const removeCategoryFromCurrRowKeyList = currRowData =>
  Object.keys(currRowData).reduce((dist, item) => {
    const result = item.match(/[a-zA-Z_]+$/);
    return { ...dist, [result[0]]: currRowData[item] };
  }, {});
const removeCateGoryFromSingleKey = key => {
  const result = key.match(/[a-zA-Z_]+$/);
  return [result[0]] || '';
};
const batchViewProxy =
  (isProxy = false) =>
  (...listPageContext) => {
    const _listPageContext = listPageContext;
    if (isProxy) {
      const [, , btnKey, data] = listPageContext;
      const dataAfterRemoveCategory = removeCategoryFromCurrRowKeyList(data);
      const btnKeyAfterRemoveCategory = removeCateGoryFromSingleKey(btnKey);
      _listPageContext.splice(2, 2, btnKeyAfterRemoveCategory, dataAfterRemoveCategory);
    }
    batchView(..._listPageContext);
  };

export const batchViewWrap = function (type, that, index, buttonKey, data, linkItem, tableInfoObj) {
  batchView(that, index, buttonKey, { ...data, type }, linkItem, tableInfoObj);
};

// 批次信息在列表返回数据中，又包了一层
export const batchViewDeep = (that, index, buttonKey, data, linkItem, tableInfoObj) => {
  const newBatchData = {
    ...data,
    car_batch: data.conn_info.car_batch,
    b_link_id: data.conn_info.b_link_id,
    company_id: data.dst_point,
  };
  batchView(that, index, buttonKey, newBatchData, linkItem, tableInfoObj);
};

// 中转-内部中转转入／内部中转转出／外部中转转出／外部中转转入
export const batchBInnerTransBatch = function (that, index, buttonKey) {
  const pageKey = getPageKey().pageConfig.key;
  const dataList = that.getTableList([index]);
  if (dataList.length === 0) return false;

  const parmInfo = getParameter(dataList, buttonKey);
  const pageInfo = getPageKey();
  const tableInfo = pageInfo.pageConfig.tableInfo || {};
  const type = tableInfo.tab;
  const pack = {
    type,
    b_link_id: parmInfo.batchId,
    company_id: parmInfo.comId,
  };
  const pageUrl = (mockData[tableInfo.tab] || {}).pageUrl || '/Truck/Truck/onLoading';
  gpostData(pageUrl || '/Truck/Truck/onLoading', pack, res => {
    if (res.data.errno !== 0) {
      createTip(res.data.errmsg, ERROR).show();
      return;
    }
    const param = Object.assign(parmInfo, {
      popName: `operatSlideDrag_${pageKey}`,
      pageType: 'show',
      type: tableInfo.tab,
      hasTab: true,
      isEdit: false,
      listPage: that,
      closeCallback: that.handleRefreshData,
      pickOrderInfo: fromJS(res.data.res),
    });
    openBatchdetail(param);
  });
};
const odTrReload = async that => {
  let comIds;
  let odLinkIds;
  try {
    ({ com_id: comIds, od_link_id: odLinkIds } = await preArrivalCheck(that, '补装车', ['com_id', 'od_link_id']));
  } catch (error) {
    return;
  }
  comIds = [...new Set(comIds)];
  // const comIds = [...new Set(that.getSelectesKey('com_id'))]
  if (comIds.length === 0) {
    return showInfo(ERROR, '请选择要操作的数据！');
  }
  if (comIds.length !== 1) {
    return showInfo(ERROR, '一次只能操作一个网点的数据！');
  }
  const tableInfo = that.getReq();
  const fromDetail = tableInfo.category === 'Order' && tableInfo.tab.length === 0;
  let comName = that.getEnumItem('com_id', comIds[0]);
  comName = comName && (comName[0].short_name || comName[0].name);
  const jumpDetail = (dataList, cb) => {
    cb();
    const parmInfo = getParameter(dataList, 'modify_tr_batch');
    const pack = {
      type: parmInfo.type,
      b_link_id: parmInfo.batchId,
      company_id: parmInfo.comId,
      reload_od_link_ids: odLinkIds,
    };
    gpostData('/Truck/Truck/onLoading', pack, res => {
      const resData = res.data.res;
      resData.is_reload = odLinkIds;
      if (res.data.errno !== 0) {
        createTip(res.data.errmsg, ERROR).show();
        return;
      }
      if (!parmInfo.basicId) {
        parmInfo.basicId = resData.batch_info.b_basic_id;
      }
      if (!that.tableInfo) {
        that.tableInfo = {
          // eslint-disable-line
          tab: 'tr_up',
        };
      }
      const param = Object.assign(
        {
          popName: 'operatSlideDrag_reload',
          pageTab: 'tr_up',
          pageType: 'add',
          closeCallback: that.handleRefreshData,
          pickOrderInfo: fromJS(resData),
          listPage: that,
          realTab: 'tr_up',
        },
        parmInfo,
      );
      openBatchdetail(param);
    });
  };
  const popup = new PopUp(Reload, {
    classname: 'reload',
    isShow: true,
    autoDestroy: true,
    comName,
    comId: comIds[0],
    onConfirm: jumpDetail,
    fromDetail,
  });
  popup.show();
};
const viewTrUp = (that, index, buttonKey, data, linkItem) => {
  let curTab = 'tr_up';
  const { category, tab } = that.tableInfo || {};
  if (category === 'Settle' && tab === 'balance_detail') curTab = +data.batch_type === 1 ? 'tr_up' : 'tr_down';
  batchView(that, index, buttonKey, data, linkItem, {
    tab: curTab,
    category: that.tableInfo.category,
  });
};

// CMTX-8953 订单列表显示运单号、运单列表显示订单号 -徐文斌
// 在(1.0)订单列表页中支持查看提货、干线、送货30条(1.0)运单数据，
const orderWaybillView = (type, that, index, buttonKey, data, linkItem) => {
  // 根据原linkItem值查询到索引，然后对应服务端参数数组取值
  const bPickupBatchList = data?.[buttonKey] || [];
  const linkIndex = bPickupBatchList.indexOf(linkItem);
  // CYTRD-9286财运通2.0，支出费用-已结、未结费用统计优化 2.0不允许跳转 -周现忠
  if (!hasPermission('viewAllReservationV1')) {
    return; // 判断非1.0提前结束
  }
  const linkData = data?.[`${type}_jump_para_v1`]?.[linkIndex] || {};
  const { b_basic_id: batchID, car_batch: carBatch, company_id: comID, uuid } = linkData;

  directToTaskDetail({
    from: 'taskList',
    batchID,
    carBatch,
    comID,
    uuid,
    isPlan: false,
  });
};

// 删除运单回单照片
const deletaBatchReceiptImg = async imgInfo => {
  const { log_id, name, path, type, node_audit_id } = imgInfo;
  const req = {
    log_id,
    node_audit_id,
    receipt_imgs: [
      {
        name,
        path,
        type,
      },
    ],
  };
  try {
    const res = await fetchJAVA('/cmm-batch/batch/delReceiptImg', { method: 'POST', body: { req } });
    if (res.errno !== 0) {
      res.errmsg && createTip(res.errmsg, ERROR, null, 2000, null, true).show();
      return false;
    }

    new PopUp(PopTip, {
      type: CHECK,
      content: '回单删除成功！',
      isModal: true,
    }).show();
    return true;
  } catch {
    createTip('服务器异常', ERROR, null, 2000, null, true).show();
  }
};

const viewSimilarFee = (that, index, buttonKey, data) => {
  new PopUp(ModalDialog, {
    title: (
      <React.Fragment>
        <i className="fn-icon fn-icon-warn-o" />
        <div>相似费用</div>
      </React.Fragment>
    ),
    content: <SimilarFee id={data?.id} />,
    popName: buttonKey,
    isDrag: false,
    isShow: true,
    style: { width: 900 },
  }).show();
};

export function init(emt) {
  // 车辆配载 修改／查看
  emt.on('abnormalCarBatch', batchView);
  emt.on('abnormalTaskBatch', batchView);
  emt.on('modifyTrBatch', batchModify);
  emt.on('batchCarBatch', batchView);
  emt.on('batchAuditCarBatch', batchView);
  emt.on('loginkCarBatch', batchView);
  emt.on('batchModifyCarBatch', batchView);
  emt.on('settleCarBatch', viewTrUp);
  emt.on('batchAccountCarBatch', batchView);
  emt.on('etcTradeRecordCarBatch', batchView);
  emt.on('batchFeeApprovalCarBatch', batchView);
  emt.on('batchFeeApprovalHasSuspDupes', viewSimilarFee);
  emt.on('orderCarBatch', batchView);
  emt.on('settleRecordOrder|bTrCarBatch', batchView);
  emt.on('settleRecordOrder|bTrUpCarBatch', batchView);
  emt.on('odTrReload', odTrReload);
  // 我的保单 批次详情跳转
  emt.on('insuranceCarBatch', batchView);

  // 短驳 修改／查看
  emt.on('modifyShuttleBatch', batchModify);
  emt.on('batchShuttleBatch', batchView);

  // 提货 修改／查看
  emt.on('modifyPickupBatch', batchModify);
  emt.on('modifyPickupReservationBatch', batchModify);
  emt.on('batchPickupBatch', batchView);
  emt.on('reservationBPickupBatch', (...args) => batchViewWrap('9', ...args));
  emt.on('orderBPickupBatch', (...args) => batchViewWrap('7', ...args));

  // 1.0订单查看运单：提、干、送运单
  emt.on('orderPickupCarBatchV1', (...args) => orderWaybillView('pickup', ...args));
  emt.on('orderTrCarBatchV1', (...args) => orderWaybillView('tr', ...args));
  emt.on('orderDeliveryCarBatchV1', (...args) => orderWaybillView('delivery', ...args));

  // 送货 修改／查看
  emt.on('modifyDeliveryBatch', batchModify);
  emt.on('batchDeliveryBatch', batchView);

  emt.on('batchAssessCarBatch', batchView);

  emt.on('modifyTrPreBatch', batchViewProxy(true));
  // 资金流水明细里发车批次LINK
  emt.on('settleBatch|carBatch', batchViewProxy(true));
  emt.on('settleBatch|deliveryBatch', batchViewProxy(true));
  emt.on('settleBatch|pickupBatch', batchViewProxy(true));
  emt.on('settleBatch|shuttleBatch', batchViewProxy(true));
  emt.on('acApplyBatchBatch|carBatch', batchViewProxy(true));

  // 内部中转转出 中转批次详情
  emt.on('batchBInnerTransBatch', batchBInnerTransBatch);
  // 内部中转转入 批次列表详情
  emt.on('batchBTransBatch', batchBInnerTransBatch);
  // 计算服务结算账户 批次列表详情
  emt.on('tradeRecordTrFare.carBatch', batchViewDeep);
  emt.on('editPack', batchModify);
  emt.on('batchOnwayPic', (that, index, buttonKey, data, img) => viewImage(img));
  emt.on('batchFeeApprovalPic', (that, index, buttonKey, data, img) => {
    viewImage(img, data.pic || []);
  });
  emt.on('batchPic', (that, index, buttonKey, data, img) => {
    viewImage(img, data.pic || []);
  });
  emt.on('batchReceiptImg', (that, index, buttonKey, data, img) => {
    // 上传电子回单权限
    const UploadReceiptImg = 'batchUploadReceiptImg';
    const imgTagMap = {
      1: '装货回单',
      2: '卸货回单',
    };
    let divRef = null;
    const options = {
      deleteUrl: '',
      deleteImageName: '点击后将删除当前图片',
      withDelete: !!hasPermission(UploadReceiptImg),
      customDeleteFun: deletaBatchReceiptImg,
      // 删除成功关闭弹窗后回调，执行刷新页面
      onDeleteSucceed: () => that.handleRefreshData?.(),
      galleryProps: {
        onSlide: (i, list) => {
          const { receipt_type } = list?.[i] || data?.receipt_img?.[i] || {};
          const tag = imgTagMap[receipt_type];
          divRef?.handleChangeValue(tag);
        },
      },
      renderTopSlot: () => <ImageTag tag={imgTagMap[img?.receipt_type]} ref={ref => (divRef = ref)} />,
    };
    viewImage(img, data.receipt_img || [], options);
  });
  emt.on('batchAuditReceiptImg', (that, index, buttonKey, data, img) => {
    const { node_audit_id } = data || {};
    // 上传电子回单权限
    const UploadReceiptImg = 'batchUploadReceiptImg';
    const options = {
      deleteUrl: '',
      deleteImageName: '点击后将删除当前图片',
      withDelete: !!hasPermission(UploadReceiptImg),
      customDeleteFun: imgInfo => deletaBatchReceiptImg({ ...imgInfo, node_audit_id }),
      // 删除成功关闭弹窗后回调，执行刷新页面
      onDeleteSucceed: () => that.handleRefreshData?.(),
    };
    viewImage(img, data.receipt_img || [], options);
  });
  emt.on('orderReceiptImg', (that, index, buttonKey, data, img) => {
    viewImage(img, data.receipt_img || []);
  });
  emt.on('batchLoadImgs', (that, index, buttonKey, data, img) => {
    viewImage(img, data.load_imgs || []);
  });
  emt.on('batchPickupImgs', (that, index, buttonKey, data, img) => {
    viewImage(img, data.pickup_imgs || []);
  });
  emt.on('batchUnloadImgs', (that, index, buttonKey, data, img) => {
    viewImage(img, data.unload_imgs || []);
  });
  emt.on('batchReturnNum', roundTripDetail);
  emt.on('batchFeeApprovalReturnNum', roundTripDetail);
  emt.on('batchDepartOilQuantityImgs', (that, index, buttonKey, data, img) => {
    viewImage(img, data.depart_oil_quantity_imgs || []);
  });
  emt.on('batchArriveOilQuantityImgs', (that, index, buttonKey, data, img) => {
    viewImage(img, data.arrive_oil_quantity_imgs || []);
  });
}
