exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".inner-remark-edit-modal .fields>div{display:flex;margin-bottom:10px}.inner-remark-edit-modal .fields>div>label{flex:0 0 80px;text-align:right;padding-right:5px;color:#f12222}.inner-remark-edit-modal .fields>div .fn-input-pure-wrap{flex:1 1 100%;height:100px}", ""]);

// exports
exports.locals = {
	"prefixCls": "inner-remark-edit-modal",
	"inner-remark-edit-modal": "inner-remark-edit-modal",
	"fields": "fields",
	"fn-input-pure-wrap": "fn-input-pure-wrap"
};