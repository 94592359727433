/**
 * Created by wangnaihe on 2018/5/31.
 */
import React from 'react';
import { alert } from 'utils';
import { ERROR } from 'constants';

const ledgerErrorTip = (title, ledgerFieldsChanges) => {
  const detail = (
    <div className="dialog_detail">
      <div className="checkinfo-detail">
        <p className="notice_title">原因说明：</p>
        <div className="detail-content">
          <p className="notice_list">
            <em>●</em>您设置了修改路由和修改费用项自动生成改单凭证，以下费用项已挂账，不能和路由一起修改!
          </p>
          <p className="checkinfo-list">{ledgerFieldsChanges.map(item => item.text).join('; ')}</p>
        </div>
      </div>
    </div>
  );
  return alert(ERROR, title, { cancel: '关闭' }, detail);
};

export default ledgerErrorTip;
