exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".order-editor-freight{flex:1 50%}.order-editor-freight .total-price{color:#f12222;margin-right:15px}.order-editor-freight .total-price.hidden-value{font-size:0;width:30px;height:32px;display:inline-block;vertical-align:middle}.order-editor-freight .total-price.hidden-value.hidden-value--asterisk{background-image:url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='25px' height='14px' viewBox='0 0 25 14'><text font-size='14' y='12' x='0' fill='red'>***</text></svg>\");background-repeat:no-repeat;background-position:50%}.order-editor-freight .tax-inc:after{display:none}.order-editor-freight .tax-inc .fn-checkbox-pure{vertical-align:-1px}.order-editor-freight .long-kun-insure{float:right;margin-top:8px;margin-right:6px}.order-editor-freight>div{width:25%;float:left}", ""]);

// exports
exports.locals = {
	"prefixCls": "order-editor-freight",
	"order-editor-freight": "order-editor-freight",
	"total-price": "total-price",
	"hidden-value": "hidden-value",
	"hidden-value--asterisk": "hidden-value--asterisk",
	"tax-inc": "tax-inc",
	"fn-checkbox-pure": "fn-checkbox-pure",
	"long-kun-insure": "long-kun-insure"
};