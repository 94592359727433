import { throttle } from 'utils';
import { matchProfitFormula, formatFormula } from '../tool';
import { SET_ORDER_DATA, FETCH_ORDER_DATA, MERGE_DATA, OUTER_TRANS, tableKeys, moveKeys } from '../constant';

const profitMiddleware = page => store => {
  const calcProfit = throttle(() => {
    const state = store.getState();
    if (state.profitShow) {
      const { effectProfitKeys } = state;
      const { effectProfitKeyMap } = state;
      const { ext } = state;
      const extProfit = ext.profit || {};
      const { keyMap } = state;
      const merge = {};

      const profit = Object.keys(effectProfitKeys)
        .reduce((total, key) => {
          const ratio = effectProfitKeys[key];
          let value = +state[key] || 0;
          if (!keyMap[key]) {
            const newKey = effectProfitKeyMap[key];
            if (extProfit[newKey]) {
              value = +extProfit[newKey] || 0;
            } else {
              tableKeys.forEach(tableKey => {
                const tableData = state[tableKey];
                tableData &&
                  tableData.forEach(row => {
                    const outerTransNotStartPoint =
                      row.od_link_id &&
                      (+row.od_link_id < +ext.ipt_head_od_link_id || +row.od_link_id > +ext.ipt_tail_od_link_id);
                    if (tableKey === 'transInfo' && (+row.trans_type !== OUTER_TRANS || outerTransNotStartPoint))
                      return;
                    if (row[newKey]) value = +row[newKey] || 0;
                  });
              });
            }
          }
          return total + ratio * value;
        }, 0)
        .toFixed(2);
      merge.profit = profit;
      const totalPrice = Object.keys(moveKeys).reduce((pre, k) => pre + (+extProfit[k] || 0), 0) + state.totalPrice;
      const profitRate = +totalPrice ? (+profit / totalPrice) * 100 : 0;
      merge.profitRate = profitRate.toFixed(2);

      store.dispatch({ type: MERGE_DATA, payload: merge });
    }
  }, 200);

  return next => action => {
    switch (action.type) {
      case SET_ORDER_DATA: {
        const state = store.getState();
        if (state.profitShow) {
          const { key } = action.payload;
          const { effectProfitKeys } = state;
          const { ext } = state;
          if (state.isOpenPayment && key === 'priceMode') {
            const priceMode = action.payload.val;
            // 单票毛利公式
            const profitFormula = matchProfitFormula(
              state.financeSetting.fn_formula,
              priceMode,
              state.isCoPointOrParent,
              state.isOpenPayment,
            );
            const { formulaKeys, formulaKeyMap } = formatFormula(profitFormula);
            page.merge('effectProfitKeys', formulaKeys);
            page.merge('effectProfitKeyMap', formulaKeyMap);
            page.merge('profitFormula', profitFormula);
            calcProfit();
          }
          if (effectProfitKeys[key]) {
            calcProfit();
          }
          if (action.changes) {
            const { index } = action;
            const { changes } = action;
            const keys = changes ? Object.keys(changes) : [];
            const { effectProfitKeyMap } = state;
            if (key === 'transInfo') {
              const row = action.payload.val[index];
              const transType = +row.trans_type;
              const outerTransNotStartPoint =
                row.od_link_id &&
                (+row.od_link_id < +ext.ipt_head_od_link_id || +row.od_link_id > +ext.ipt_tail_od_link_id);
              if (transType !== 1 || outerTransNotStartPoint) break;
            }
            keys.some(k => effectProfitKeys[effectProfitKeyMap[k]] && calcProfit());
          }

          // 删除中转的时候重新计算全部的
          if (key === 'transInfo' && 'index' in action && !action.changes) calcProfit();
        }
        break;
      }
      case FETCH_ORDER_DATA: {
        calcProfit();
        break;
      }
      default:
        break;
    }
    next(action);
  };
};

export default profitMiddleware;
