/**
 * Created by wangnaihe on 2017/10/9.
 */
import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import moment from 'moment';
import { fetchApi as fetch, showInfo, ot } from 'utils';
import { ModalDialog, Button, Load, DateTimePicker, PureTextarea } from 'components';
import { ERROR, CHECK } from 'constants';
import { prefixCls } from './index.scss';

class InnerRemarkEditModal extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    data: PropTypes.object,
    close: PropTypes.func,
    odBasicId: PropTypes.string,
    odLinkId: PropTypes.string,
    onSaveSuccess: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      title: props.data ? `修改${ot('内部备注')}` : `添加${ot('内部备注')}`,
      odBasicId: props.odBasicId,
      odLinkId: props.odLinkId,
      id: props.data && props.data.id,
      stateTime: props.data ? props.data.state_time : moment().format('YYYY-MM-DD HH:mm:ss'),
      remark: props.data ? props.data.record_content : '',
    };
  }

  set = (key, val) => this.setState({ [key]: val });

  save = async () => {
    const { stateTime, remark, odLinkId, odBasicId, id } = this.state;
    if (!stateTime) return showInfo(ERROR, '【备注时间】必填');
    if (!remark) return showInfo(ERROR, '【备注内容】必填');
    this.setState({ saving: true });
    const url = 'Order/OrderSelect/editRecord';
    const req = {
      consultant_mode: '',
      consultant_type: '',
      cs_visible: '0',
      type: 901,
      state_time: stateTime,
      is_visible: '0',
      record_content: remark,
      od_basic_id: odBasicId,
      od_link_id: odLinkId,
      is_inner_remark: true,
      id,
    };
    const res = await fetch(url, { method: 'POST', body: { req } });
    if (res.errno === 0) {
      this.modal.handleHide();
      showInfo(CHECK, '保存成功');
      setTimeout(() => this.props?.onSaveSuccess?.(), 1500);
    } else {
      showInfo(ERROR, res.errmsg);
      this.setState({ saving: false });
    }
  };

  render() {
    const { state } = this;
    const { title, loading, saving, stateTime, remark } = state;
    const content = (
      <Load spinning={loading} className={prefixCls}>
        <div className="fields" ref={r => (this.wrap = r)}>
          <div>
            <label>备注时间</label>
            <DateTimePicker showTime defaultValue={stateTime} onChange={val => this.set('stateTime', val)} />
          </div>
          <div>
            <label>备注内容</label>
            <PureTextarea
              value={remark}
              maxLength="200"
              placeholder="请输入备注内容，不超过200个字。"
              onChange={e => this.set('remark', e.target.value)}
            />
          </div>
        </div>
      </Load>
    );

    const bottom = (
      <Fragment>
        <Button type="primary" loading={saving} onClick={this.save}>
          确定
        </Button>
        <Button type="default" disabled={saving} onClick={() => this.modal.handleHide()}>
          取消
        </Button>
      </Fragment>
    );

    return (
      <ModalDialog
        ref={r => (this.modal = r)}
        content={content}
        bottom={bottom}
        isShow
        isModal
        close={this.props.close}
        title={title}
        contentStyle={{ width: '520px' }}
      />
    );
  }
}

export default InnerRemarkEditModal;
