/**
 * Created by wangnaihe on 2017/6/19.
 */
import React from 'react';
import {
  DataList,
  PureInput as Input,
  Address,
  Icon,
  Select,
  Tips,
  RangeTimePicker,
  DateTimePicker,
  UploadFile,
} from 'components';
import { readIDFun, showInfo, ot, buildTipMixin } from 'utils';
import { MOBILE, ERROR } from 'constants';
import { required, AutoWrapLabel } from '../../tool';
import { SELECT_COR_SUG } from '../../constant';
import { convertSugToMemberData, formatVipSug } from '../../fee/collect/tool';
import { formatSugData, sugCorCee, showHistory, renderMsgIcon } from '../tool';
import { prefixCls } from './index.scss';
import { dataAnalyticTrack } from '@/utils/dataAnalytic';

const projectCorTip = buildTipMixin(
  <div>
    提示：
    <br />
    项目客户的发货单位在开单时不允许修改！如需修改，请到客户管理里修改客户信息。！
  </div>,
  { type: 'info' },
);

function renderConsignorTo(page) {
  return function () {
    const { store } = page;
    const state = store.getState();
    const { origin, isDetail, corId, corLock, corHeaders, corSalesmen, corIsProject, corInsuranceRatio } = state;
    const { corIsMonth, corIsMonthShow } = state;
    const { corCustomerNo, corCustomerNoShow } = state;
    const { corIndustry, corIndustryShow, corIndustryRequired, corIndustryEditable, corIndustryEnum } = state;
    const { corCom, corComShow, corComRequired, corComEditable, corComEnum } = state;
    const { corName, corNameShow, corNameRequired, corNameEditable, corNameEnum, corHistoryShow } = state;
    const { corMobile, corMobileShow, corMobileRequired, corMobileEditable, corMobileEnum, validateCorMobile } = state;
    const { corPhone, corPhoneShow, corPhoneRequired, corPhoneEditable, corPhoneEnum } = state;
    const { corCate, corCateStr, corCateShow, corCateRequired, corCateEditable } = state;
    const { corIdNum, corIdNumShow, corIdNumRequired, corIdNumEditable, corIdNumEnum, readIDCard } = state;
    const {
      corAddrInfo,
      corAddrInfoShow,
      corAddrInfoRequired,
      corAddrInfoEditable,
      mapChoose,
      corAddressType,
      corAddressLock,
    } = state;
    const { corAddrRemark, corAddrRemarkShow, corAddrRemarkEditable, corAddrRemarkRequired } = state;
    const { corPickDist, corPickDistShow } = state;
    const { corRemark, corRemarkShow, corRemarkEditable, corRemarkRequired } = state;
    const {
      memberCodeShow,
      vipPos,
      memberCodeEnum,
      memberCodeEditable,
      memberCodeType,
      memberCodeRequired,
      memberCode,
      isCMBCOrLSMember,
    } = state;
    const { coDeliveryModeShow, coDeliveryModeRequired, coDeliveryModeEditable, coDeliveryModeEnum } = state;
    const { cardHolderShow, cardHolderRequired, cardHolderEditable } = state;
    const { contactPhoneShow, contactPhoneRequired, contactPhoneEditable } = state;
    const { openBankShow, openBankRequired, openBankEditable, openBankEnum } = state;
    const { bankCardNumShow, bankCardNumRequired, bankCardNumEditable } = state;
    const { cardHolderEnum, contactPhoneEnum, bankCardNumEnum } = state;
    const { coPickupDate, coPickupDateShow, coPickupDateRequired, coPickupDateEditable } = state;
    const { obPlanLoadT, obPlanLoadTShow, obPlanLoadTRequired, obPlanLoadTEditable } = state;
    const { obPlanTruckT, obPlanTruckTShow, obPlanTruckTRequired, obPlanTruckTEditable } = state;
    const { autoMatchGdAddr } = state;
    // 发货人附件
    const {
      corAttachment = [],
      corAttachmentShow = false,
      corAttachmentEditable,
      corAttachmentRequired = false,
      matchedCorAttachment = false,
    } = state;

    const { set } = page;
    const { merge } = page;

    // 如果从下拉里选中的发货人有会员号，则发放方式、开户行、银行卡号、开户人、开户电话都不允许编辑。
    const memberFieldsDisabled = memberCode && memberCode.member_code;
    // const vipCodeHead = (memberCode || {}).co_delivery_mode === '现金' ? cashHead : bankHead

    const sug = (key, type, keyword, select) => {
      sugCorCee(true, type, keyword, state, select.fetch).then(res => page.setSug(key, formatSugData(res.res)));
    };

    const clearHiddenFields = (name, mobile) => {
      const { basicSetting, todoDays, todoTime, alarmThreshold, lockValue, needAlarm, corCompanyId, corLockTime } =
        state;
      const corMaintainCalc = basicSetting.cor_maintain_calc || {};
      corId && set('corId', '');
      corCate !== null && set('corCate', null);
      merge('corCateStr', '');
      corSalesmen && set('corSalesmen', '');
      alarmThreshold && merge('alarmThreshold', '');
      lockValue && merge('lockValue', '');
      needAlarm !== 2 && merge('needAlarm', 2); // 锁机值开关
      corCompanyId && merge('corCompanyId', '');
      corLockTime && merge('corLockTime', '');
      todoDays && merge('todoDays', '');
      todoTime && merge('todoTime', '');
      set('corMaintain', name || mobile ? corMaintainCalc.maintain_fee : []);
      set('corMaintainByFormula', +corMaintainCalc.maintain_rule === 2);
      set('corMaintainRatio', name || mobile ? +corMaintainCalc.maintain_ratio || 0 : 0);
      corIsProject && set('corIsProject', false);
      corInsuranceRatio && set('corInsuranceRatio', '');
      !corIsMonthShow && corIsMonth && set('corIsMonth', false);
      !corNameShow && corName && set('corName', '');
      !corMobileShow && corMobile && set('corMobile', '');
      !corAddrInfoShow && corAddrInfo && set('corAddrInfo', '');
      !corComShow && corCom && set('corCom', '');
      !corPhoneShow && corPhone && set('corPhone', '');
      !corIdNumShow && corIdNum && set('corIdNum', '');
      !corCustomerNoShow && corCustomerNo && set('corCustomerNo', '');
      !corIndustryShow && corIndustry && set('corIndustry', '');
      !corAddrRemarkShow && corAddrRemark && set('corAddrRemark', '');
      !corRemarkShow && corRemark && set('corRemark', '');
      // 修改发货人清除会员信息(1000, 2078, 5656 集团不走此逻辑)
      if (![1000, 2078, 5656, 226].includes(+window.group_id) && memberCode) {
        const _memberCode = {
          ...memberCode,
        };
        const isMember = memberCode.member_code;
        const keys = [
          { delete: isMember || !openBankShow, key: 'open_bank' },
          { delete: isMember || !bankCardNumShow, key: 'bank_card_num' },
          { delete: isMember || !cardHolderShow, key: 'card_holder' },
          { delete: isMember || !contactPhoneShow, key: 'contact_phone' },
          { delete: false, key: 'co_delivery_mode' },
          { delete: true, key: 'id' },
          { delete: true, key: 'member_code' },
        ];
        keys.forEach(item => {
          if (item.delete) {
            delete _memberCode[item.key];
          }
        });
        set('memberCode', _memberCode);
      }
    };

    const selectSug = (key, data) => store.dispatch({ type: SELECT_COR_SUG, payload: { data, key } });

    const clearAttachment = () => {
      // 手动改收货人清空附件
      if (corAttachmentShow) {
        set('corAttachment', []);
        set('matchedCorAttachment', false);
      }
    };
    const setID = () => {
      const cardInfo = readIDFun();
      if (!cardInfo) return;
      set('corIdNum', cardInfo.id);
      corNameShow && set('corName', cardInfo.name);
      clearAttachment();
    };

    const setCorName = val => {
      clearHiddenFields(val, corMobile);
      set('corName', val);
      clearAttachment();
    };

    const setCorMobile = val => {
      clearHiddenFields(corName, val);
      set('corMobile', val);
    };

    const addressBlur = () => corAddrInfo && !corAddrInfo.poi && set('corAddrInfo', corAddrInfo);

    const sugVip = (key, urlKey, keyword, select) => {
      if (keyword.length < 6) return;
      const url = '/Table/Suggest/memberSug';
      const req = { type: state.basicSetting.member_code.checked === 'ls' ? 2 : 1, query: keyword };
      const conf = { method: 'POST', body: { req } };
      select.fetch(url, conf, 'json', { focus: `cor_${urlKey}` }).then(res => page.setSug(key, formatVipSug(res.res)));
    };

    const selectVip = (key, data) => (data ? selectSug(key, data) : set(key, convertSugToMemberData(data)));

    const setSingleCode = (key, val) =>
      set('memberCode', { ...(memberCode || convertSugToMemberData({})), [key]: val });
    const memberCodeCheck = () => {
      if (!(memberCode && memberCode.member_code)) {
        showInfo(ERROR, `请从下拉里选择${ot('会员号')}`);
      }
    };

    const onFileChange = files => {
      set('corAttachment', files);
      dataAnalyticTrack(['常发货物要求', '订单', '上传发货人附件']);
    };

    return (
      <div className={prefixCls}>
        <div>
          {corIsMonthShow && !!corIsMonth && <Icon iconType="icon-monthly-pass" tips="月结客户" />}
          <div>
            <Icon iconType="icon-avtor" />
            <p>发货方</p>
          </div>
        </div>
        <div>
          <div>
            {memberCodeShow && vipPos === 'top' && (
              <div className="vip-code">
                <label className={`fn-label${required(memberCodeRequired)}`}>{ot('会员号')}</label>
                <Select
                  data-path="member_code"
                  required={memberCodeRequired}
                  value={memberCode}
                  data={memberCodeEnum}
                  disabled={!memberCodeEditable}
                  header={corHeaders.member_code}
                  compare="id"
                  placeholder=""
                  format={memberCodeType}
                  map={false}
                  clear
                  onBlur={memberCodeCheck}
                  onChange={val => selectVip('memberCode', val)}
                  filter={(keyword, select) =>
                    (isCMBCOrLSMember ? sugVip : sug)('memberCode', 'member_code', keyword, select)
                  }
                />
              </div>
            )}
            {coDeliveryModeShow && (
              <div className="vip-delivery">
                <label className={`fn-label${required(coDeliveryModeRequired)}`}>{ot('发放方式')}</label>
                <Select
                  data-path="co_delivery_mode"
                  required={coDeliveryModeRequired}
                  value={memberCode ? memberCode.co_delivery_mode : ''}
                  data={coDeliveryModeEnum}
                  disabled={memberFieldsDisabled || !coDeliveryModeEditable}
                  map={false}
                  filter={false}
                  onChange={val => setSingleCode('co_delivery_mode', val.key)}
                />
              </div>
            )}
          </div>
          <div>
            {corComShow && (
              <div className="cor-company">
                <AutoWrapLabel className={`fn-label${required(corComRequired)}`} text={ot('发货单位')} />
                {!corLock && (
                  <DataList
                    data-path="cor_com"
                    required={corComRequired}
                    maxLength="30"
                    value={corCom}
                    data={corComEnum}
                    disabled={!corComEditable || corIsProject}
                    highlight="customer_cname"
                    header={corHeaders.customer_cname}
                    onChange={val => set('corCom', val)}
                    onSelect={data => selectSug('corCom', data)}
                    filter={(keyword, select) => sug('corCom', 'cor_com', keyword, select)}
                    {...(corComEditable && corIsProject ? projectCorTip : undefined)}
                  />
                )}
                {corLock && (
                  <Select
                    data-path="cor_com"
                    required={corComRequired}
                    value={corCom && { customer_cname: corCom, id: corId }}
                    data={corComEnum}
                    disabled={!corComEditable}
                    header={corHeaders.customer_cname}
                    map={false}
                    compare="id"
                    format="customer_cname"
                    onChange={val => selectSug('corCom', val)}
                    filter={(keyword, select) => sug('corCom', 'cor_com', keyword, select)}
                  />
                )}
              </div>
            )}
            {corNameShow && (
              <div className="cor-name">
                <label className={`fn-label${required(corNameRequired)}`}>{ot('发货人')}</label>
                {!corLock && (
                  <DataList
                    data-path="cor_name"
                    required={corNameRequired}
                    maxLength="20"
                    value={corName}
                    data={corNameEnum}
                    disabled={!corNameEditable}
                    highlight="name"
                    header={corHeaders.name}
                    onChange={setCorName}
                    onSelect={data => selectSug('corName', data)}
                    filter={(keyword, select) => sug('corName', 'cor_name', keyword, select)}
                  >
                    {corHistoryShow && (
                      <Tips className="input-tip" title="最近10次发货记录查询">
                        <i
                          className="fn-icon fn-icon-search"
                          data-path="cor_name_log"
                          onClick={() => showHistory('cor_name', store)}
                        />
                      </Tips>
                    )}
                  </DataList>
                )}
                {corLock && (
                  <Select
                    data-path="cor_name"
                    required={corNameRequired}
                    value={corName && { name: corName, id: corId }}
                    data={corNameEnum}
                    disabled={!corNameEditable}
                    header={corHeaders.name}
                    map={false}
                    compare="id"
                    format="name"
                    onChange={data => selectSug('corName', data)}
                    filter={(keyword, select) => sug('corName', 'cor_name', keyword, select)}
                  />
                )}
              </div>
            )}
          </div>
          <div>
            {corMobileShow && (
              <div className="cor-mobile">
                <label className={`fn-label${required(corMobileRequired)}`}>{ot('发货人手机', '手机号码')}</label>
                {isDetail && corMobile && renderMsgIcon(origin, { key: 'cor_msg_info' })}
                {!corLock && (
                  <DataList
                    data-path="cor_mobile"
                    required={corMobileRequired}
                    maxLength="128"
                    pattern={validateCorMobile ? MOBILE : undefined}
                    value={corMobile}
                    data={corMobileEnum}
                    disabled={!corMobileEditable}
                    highlight="telephone"
                    header={corHeaders.telephone}
                    onChange={setCorMobile}
                    onSelect={data => selectSug('corMobile', data)}
                    filter={(keyword, select) => sug('corMobile', 'cor_mobile', keyword, select)}
                  />
                )}
                {corLock && (
                  <Select
                    data-path="cor_mobile"
                    required={corMobileRequired}
                    value={corMobile && { telephone: corMobile, id: corId }}
                    data={corMobileEnum}
                    disabled={!corMobileEditable}
                    header={corHeaders.telephone}
                    map={false}
                    compare="id"
                    format="telephone"
                    onChange={val => selectSug('corMobile', val)}
                    filter={(keyword, select) => sug('corMobile', 'cor_mobile', keyword, select)}
                  />
                )}
              </div>
            )}
            {corPhoneShow && (
              <div className="cor-phone">
                <label className={`fn-label${required(corPhoneRequired)}`}>{ot('发货电话', '电话号码')}</label>
                {isDetail && !corMobile && corPhone && renderMsgIcon(origin, { key: 'cor_msg_info' })}
                {!corLock && (
                  <DataList
                    data-path="cor_phone"
                    required={corPhoneRequired}
                    maxLength="128"
                    value={corPhone}
                    data={corPhoneEnum}
                    disabled={!corPhoneEditable}
                    highlight="phone"
                    header={corHeaders.phone}
                    onChange={val => set('corPhone', val)}
                    onSelect={data => selectSug('corPhone', data)}
                    filter={(keyword, select) => sug('corPhone', 'cor_phone', keyword, select)}
                  />
                )}
                {corLock && (
                  <Select
                    data-path="cor_phone"
                    required={corPhoneRequired}
                    value={corPhone && { phone: corPhone, id: corId }}
                    data={corPhoneEnum}
                    disabled={!corPhoneEditable}
                    header={corHeaders.phone}
                    map={false}
                    compare="id"
                    format="phone"
                    onChange={data => selectSug('corName', data)}
                    filter={(keyword, select) => sug('corPhone', 'cor_phone', keyword, select)}
                  />
                )}
              </div>
            )}
          </div>
          <div>
            {corCateShow && (
              <div className="cor-cate">
                <label className={`fn-label${required(corCateRequired)}`}>{ot('发货客户类型', '客户类型')}</label>
                <Input
                  data-path="cor_cate"
                  required={corCateRequired}
                  value={corCateStr}
                  disabled={corLock || !corCateEditable}
                />
              </div>
            )}
            {corIndustryShow && (
              <div className="cor-industry">
                <label className={`fn-label${required(corIndustryRequired)}`}>{ot('发货客户行业', '客户行业')}</label>
                {!corLock && (
                  <DataList
                    data-path="cor_industry"
                    required={corIndustryRequired}
                    maxLength="30"
                    value={corIndustry}
                    data={corIndustryEnum}
                    disabled={!corIndustryEditable}
                    highlight="industry"
                    header={corHeaders.industry}
                    onChange={val => set('corIndustry', val)}
                    onSelect={data => selectSug('corIndustry', data)}
                    filter={(keyword, select) => sug('corIndustry', 'cor_industry', keyword, select)}
                  />
                )}
                {corLock && (
                  <Select
                    data-path="cor_industry"
                    required={corIndustryRequired}
                    value={{ industry: corIndustry, id: corId }}
                    data={corIndustryEnum}
                    disabled={!corIndustryEditable}
                    header={corHeaders.industry}
                    map={false}
                    compare="id"
                    format="industry"
                    onChange={data => selectSug('corIndustry', data)}
                    filter={(keyword, select) => sug('corIndustry', 'cor_industry', keyword, select)}
                  />
                )}
              </div>
            )}
          </div>
          <div>
            {openBankShow && (
              <div className="vip-bank">
                <label className={`fn-label${required(openBankRequired)}`}>{ot('开户行')}</label>
                <Select
                  data-path="open_bank"
                  required={openBankRequired}
                  value={memberCode ? memberCode.open_bank : ''}
                  data={openBankEnum}
                  disabled={memberFieldsDisabled || !openBankEditable}
                  map={false}
                  placeholder=""
                  onChange={val => setSingleCode('open_bank', val.key)}
                />
              </div>
            )}
            {bankCardNumShow && (
              <div className="vip-bank-num">
                <label className={`fn-label${required(bankCardNumRequired)}`}>{ot('银行卡号')}</label>
                <DataList
                  data-path="bank_card_num"
                  required={bankCardNumRequired}
                  value={memberCode ? memberCode.bank_card_num : ''}
                  data={bankCardNumEnum}
                  header={corHeaders.bank_card}
                  disabled={memberFieldsDisabled || !bankCardNumEditable}
                  highlight="bank_card_num"
                  map={false}
                  onChange={val => setSingleCode('bank_card_num', val)}
                  onSelect={data => selectSug('bankCardNum', data)}
                  filter={(keyword, select) => sug('bankCardNum', 'bank_card_num', keyword, select)}
                />
              </div>
            )}
          </div>
          <div>
            {cardHolderShow && (
              <div className="vip-holder">
                <label className={`fn-label${required(cardHolderRequired)}`}>{ot('开户人')}</label>
                <DataList
                  data-path="card_holder"
                  required={cardHolderRequired}
                  value={memberCode ? memberCode.card_holder : ''}
                  data={cardHolderEnum}
                  header={corHeaders.account_holder}
                  disabled={memberFieldsDisabled || !cardHolderEditable}
                  highlight="account_holder"
                  onChange={val => setSingleCode('card_holder', val)}
                  onSelect={data => selectSug('cardHolder', data)}
                  filter={(keyword, select) => sug('cardHolder', 'card_holder', keyword, select)}
                />
              </div>
            )}
            {contactPhoneShow && (
              <div className="contact-phone">
                <label className={`fn-label${required(contactPhoneRequired)}`}>{ot('开户电话')}</label>
                {isDetail && renderMsgIcon(origin, { key: 'member_msg_info' })}
                <DataList
                  data-path="contact_phone"
                  required={contactPhoneRequired}
                  value={memberCode ? memberCode.contact_phone : ''}
                  data={contactPhoneEnum}
                  header={corHeaders.account_holder_phone}
                  disabled={memberFieldsDisabled || !contactPhoneEditable}
                  highlight="account_holder_phone"
                  onChange={val => setSingleCode('contact_phone', val)}
                  onSelect={data => selectSug('contactPhone', data)}
                  filter={(keyword, select) => sug('contactPhone', 'contact_phone', keyword, select)}
                />
              </div>
            )}
          </div>
          <div>
            {/* {corCustomerNoShow &&
           <div className="cor-no">
           <label className={`fn-label${required(corCustomerNoRequired)}`}>{ot('客户编号')}</label>
           {!corLock && <DataList
           data-path="cor_customer_no"
           required={corCustomerNoRequired}
           maxLength="30"
           value={corCustomerNo}
           data={corCustomerNoEnum}
           disabled={!corCustomerNoEditable}
           highlight="customer_no"
           header={corHeader}
           onChange={val => set('corCustomerNo', val)}
           onSelect={data => selectSug('corCustomerNo', data)}
           filter={(keyword, select) => sug('corCustomerNo', 'cor_customer_no', keyword, select)}
           />}
           {corLock && <Select
           data-path="cor_customer_no"
           required={corCustomerNoRequired}
           value={{ customer_no: corCustomerNo, id: corId }}
           data={corCustomerNoEnum}
           disabled={!corCustomerNoEditable}
           header={corHeader}
           map={false}
           compare="id"
           format="customer_no"
           onChange={data => selectSug('corCustomerNo', data)}
           filter={(keyword, select) => sug('corCustomerNo', 'cor_customer_no', keyword, select)}
           />}
           </div>} */}
            {corIdNumShow && (
              <div>
                <label className={`fn-label${required(corIdNumRequired)}`}>{ot('发货人身份证', '身份证号')}</label>
                {!corLock && (
                  <DataList
                    data-path="cor_id_num"
                    required={corIdNumRequired}
                    maxLength="18"
                    value={corIdNum}
                    data={corIdNumEnum}
                    disabled={!corIdNumEditable}
                    header={corHeaders.id_num}
                    highlight="id_num"
                    onChange={val => set('corIdNum', val)}
                    onSelect={data => selectSug('corIdNum', data)}
                    filter={(keyword, select) => sug('corIdNum', 'cor_id_num', keyword, select)}
                  >
                    {corIdNumEditable && readIDCard && (
                      <Tips className="input-tip" title="点击读取身份证号">
                        <i className="fn-icon fn-icon-usid" onClick={setID} />
                      </Tips>
                    )}
                  </DataList>
                )}
                {corLock && (
                  <Select
                    data-path="cor_id_num"
                    required={corIdNumRequired}
                    value={{ payee_id_card: corIdNum, id: corId }}
                    data={corIdNumEnum}
                    disabled={!corIdNumEditable}
                    header={corHeaders.id_num}
                    map={false}
                    compare="id"
                    format="payee_id_card"
                    onChange={val => selectSug('corIdNum', val)}
                    filter={(keyword, select) => sug('corIdNum', 'cor_id_num', keyword, select)}
                  />
                )}
              </div>
            )}
          </div>

          {(corAddrInfoShow || corAddrRemarkShow || corPickDistShow) && (
            <div>
              {!corAddrInfoShow && corAddrRemarkShow && !corPickDistShow ? (
                <label className={`fn-label${required(corAddrRemarkRequired)}`}>{ot('发货地址备注', '地址备注')}</label>
              ) : (
                <label className={`fn-label${required(corAddrInfoRequired)}`}>{ot('发货地址')}</label>
              )}
              {(corAddrInfoShow || corPickDistShow) &&
                (autoMatchGdAddr ? (
                  <Input
                    value={corAddrInfo?.show_val}
                    placeholder="请输入地址"
                    maxLength="30"
                    required={corAddrInfoRequired}
                    disabled={!corAddrInfoEditable || corLock}
                    data-path="cor_addr_info"
                    onChange={e => set('corAddrInfo', { show_val: e.target.value, poi: '1,1' })}
                  />
                ) : (
                  <Address
                    g7Map
                    data-path="cor_addr_info"
                    showTitleTips
                    required={corAddrInfoRequired}
                    needSelect={corAddressLock}
                    mode={corAddressType}
                    mapIcon={mapChoose}
                    fullName
                    value={corAddrInfo}
                    disabled={!corAddrInfoEditable || corLock}
                    onBlur={addressBlur}
                    onChange={val => merge('corAddrInfo', val)}
                    onSelect={val => set('corAddrInfo', val)}
                  />
                ))}
              {corAddrRemarkShow && (
                <Input
                  data-path="cor_addr_remark"
                  showTitleTips
                  required={corAddrRemarkRequired}
                  maxLength="30"
                  className="address-remark"
                  placeholder={ot('发货地址备注', '地址备注')}
                  value={corAddrRemark || ''}
                  disabled={!corAddrRemarkEditable}
                  onChange={e => set('corAddrRemark', e.target.value)}
                />
              )}
              {corPickDistShow && (
                <Input
                  data-path="cor_pick_dist"
                  className="pick-dist"
                  value={corPickDist || ''}
                  disabled
                  onChange={e => set('corPickDist', e.target.value)}
                />
              )}
            </div>
          )}
          {coPickupDateShow && (
            <div>
              <label className={`fn-label${required(coPickupDateRequired)}`}>{ot('预约提货时间', '提货时间')}</label>
              <RangeTimePicker
                data-path="co_pickup_date"
                defaultValue={coPickupDate}
                required={coPickupDateRequired}
                disabled={!coPickupDateEditable}
                onChange={val => set('coPickupDate', val)}
                format="YY-MM-DD HH:mm:ss"
                showTime
                showExtFoot={false}
              />
            </div>
          )}
          <div>
            {obPlanLoadTShow && (
              <div>
                <AutoWrapLabel className={`fn-label${required(obPlanLoadTRequired)}`} text={ot('预计装货时间')} />
                <DateTimePicker
                  defaultValue={obPlanLoadT}
                  data-path="ob_plan_load_t"
                  showTime
                  multiLine
                  disabled={!obPlanLoadTEditable}
                  onChange={val => page.set('obPlanLoadT', val)}
                />
              </div>
            )}
            {obPlanTruckTShow && (
              <div>
                <AutoWrapLabel className={`fn-label${required(obPlanTruckTRequired)}`} text={ot('预计发车时间')} />
                <DateTimePicker
                  defaultValue={obPlanTruckT}
                  data-path="ob_plan_truck_t"
                  showTime
                  multiLine
                  disabled={!obPlanTruckTEditable}
                  onChange={val => page.set('obPlanTruckT', val)}
                />
              </div>
            )}
          </div>

          {corAttachmentShow && (
            <div>
              <label className={`fn-label${required(corAttachmentRequired)}`}>{ot('发货人附件')}</label>
              <UploadFile
                className="cor-attachment"
                type="customer_goods"
                showType="link"
                fileList={corAttachment}
                disabled={!corAttachmentEditable || corLock || matchedCorAttachment}
                onChange={fileList => onFileChange(fileList)}
                isOss
                displayName="hover"
                maxNum={10}
                sizeLimit={10}
              />
              <Icon
                iconType="icon-help"
                isHtmlTips
                tips="如果联系人维护了常发货物附件则选择货物后自动匹配带入附件 <br /> 如果联系人未维护常发货物附件信息，则支持人工上传"
              />
            </div>
          )}

          {corRemarkShow && (
            <div>
              <label className={`fn-label${required(corRemarkRequired)}`}>{ot('发货备注')}</label>
              <Input
                data-path="cor_remark"
                showTitleTips
                required={corRemarkRequired}
                title={corRemark}
                value={corRemark || ''}
                maxLength={500}
                disabled={!corRemarkEditable}
                onChange={e => set('corRemark', e.target.value)}
              />
            </div>
          )}
        </div>
      </div>
    );
  };
}
export default renderConsignorTo;
