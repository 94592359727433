/**
 * Created by wangnaihe on 2018/4/18.
 */
import { asyncComponent } from 'utils';

const dynamicImportOrderPreview = () => import(/* webpackChunkName: "Order-preview" */ './index');

export default dynamicImportOrderPreview;

export const AsyncOrderPreview = asyncComponent(dynamicImportOrderPreview);
