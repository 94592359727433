/**
 * Created by wangnaihe on 2017/10/9.
 */
import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { validateFieldsOf, ot } from 'utils';
import { ModalDialog, Button, PureInput as Input } from 'components';
import { FLOAT_P_3, FLOAT_P_6 } from 'constants';
import { prefixCls } from './index.scss';

class TransWeightVolumeEditModal extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    close: PropTypes.func,
  };

  state = {
    trans_v_detail: this.props.data.trans_v_detail || [],
    trans_w_detail: this.props.data.trans_w_detail || [],
  };

  set = (key, index, val) => {
    const arr = [...this.state[key]];
    arr[index] = val;
    this.setState({ [key]: arr });
  };

  confirm = async () => {
    if (!(await validateFieldsOf(this.wrap))) return false;
    this.modal.handleHide();
    this.props.onConfirm(this.state);
  };

  cancel = () => {
    this.modal.handleHide();
    this.props.onCancel();
  };

  render() {
    const { trans_w_detail: weight, trans_v_detail: volume } = this.state;
    const weightPattern = window.company_setting.weight_unit.value === 'KG' ? FLOAT_P_3 : FLOAT_P_6;
    const content = (
      <table ref={r => (this.wrap = r)}>
        <thead>
          <tr>
            <th>{ot('中转重量')}</th>
            <th>{ot('中转体积')}</th>
          </tr>
        </thead>
        <tbody>
          {weight.map((val, index) => (
            <tr key={index}>
              <td>
                <Input
                  value={val}
                  pattern={weightPattern}
                  onChange={e => this.set('trans_w_detail', index, e.target.value)}
                />
              </td>
              <td>
                <Input
                  value={volume[index]}
                  pattern={FLOAT_P_6}
                  onChange={e => this.set('trans_v_detail', index, e.target.value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );

    const bottom = (
      <Fragment>
        <Button type="primary" onClick={this.confirm}>
          确定
        </Button>
        <Button type="default" onClick={this.cancel}>
          取消
        </Button>
      </Fragment>
    );

    return (
      <ModalDialog
        ref={r => (this.modal = r)}
        classname={prefixCls}
        content={content}
        bottom={bottom}
        isShow
        isModal
        close={this.props.close}
        title="外部中转"
        contentStyle={{ width: '520px' }}
      />
    );
  }
}

export default TransWeightVolumeEditModal;
