// 司机管理 - 配置文件

const submitCertHeader = {
  name: { title: '姓名', display: 'show', align: 'center' },
  tel: { title: '手机号', display: 'show', align: 'center' },
  id_num: { title: '身份证号', display: 'show', width: 150, align: 'center' },
};

// 批量操作的配置
export const batchOpConfig = {
  // 提交认证
  driver_platform_cert: {
    title: '司机认证进度',
    url: '/cmm-basic/driver/commitAttestationOfPlatform',
    tips: '司机认证',
    header: submitCertHeader,
  },
};
