exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".driver-view-img .fn-dialog__content{max-width:100%}.driver-view-img .fn-dialog__content img{max-width:100%;max-height:100%}.fn-show_info__icon{vertical-align:top}.fn-show_info__text{overflow-x:inherit;overflow-y:inherit}.carry-set-check{margin-top:5px;margin-left:0}.carry-set-check .reason-red{color:red;font-size:14px;margin-top:15px}.carry-set-check .name{color:#e44cc3}.carry-set-check .reason-content{margin-left:-46px}.carry-set-check .marginBottom20{margin-bottom:20px}.carry-set-check .reason-name{margin-top:5px;color:gray}.carry-set-check .reason-span{margin:5px 6px 5px 0}.carry-set-check p{color:#ccc;max-width:400px;margin-top:10px;margin-left:30px}.driver-cert-scan{width:438px}.driver-cert-scan .fn-dialog__close.fn-icon-close{top:13px;right:13px;font-size:10px;background-color:#b2b2b2;width:22px;height:22px;border-radius:22px;color:#fff;display:flex;align-items:center;justify-content:center}.driver-cert-scan .fn-dialog__close.fn-icon-close:hover{top:13px;right:13px}.driver-cert-scan .fn-dialog__center{min-width:438px;padding:40px}.driver-cert-scan .fn-show_info__icon{display:none}.driver-cert-scan .fn-show_info__text.no_detail_1{width:100%}.driver-cert-scan .qr-pop-wrap{overflow:hidden}.driver-cert-scan .qr-pop-wrap .title{color:#131e27;font-size:18px;font-weight:700;line-height:30px;text-align:center}.driver-cert-scan .qr-img-wrap{text-align:center;margin:20px 0 15px}.driver-cert-scan .qr-foot{font-size:15px;color:#555}.driver-cert-scan .fn-dialog__title{height:0;overflow:hidden}.driver-cert-scan .fn-dialog__bottom{height:0;padding:0}.driver-cert-scan .down-qr{width:108px;height:36px;display:block;margin:26px auto 0}.driver-cert-scan .down-load-save{height:0;width:0;display:block}", ""]);

// exports
exports.locals = {
	"driver-view-img": "driver-view-img",
	"fn-dialog__content": "fn-dialog__content",
	"fn-show_info__icon": "fn-show_info__icon",
	"fn-show_info__text": "fn-show_info__text",
	"carry-set-check": "carry-set-check",
	"reason-red": "reason-red",
	"name": "name",
	"reason-content": "reason-content",
	"marginBottom20": "marginBottom20",
	"reason-name": "reason-name",
	"reason-span": "reason-span",
	"driver-cert-scan": "driver-cert-scan",
	"fn-dialog__close": "fn-dialog__close",
	"fn-icon-close": "fn-icon-close",
	"fn-dialog__center": "fn-dialog__center",
	"no_detail_1": "no_detail_1",
	"qr-pop-wrap": "qr-pop-wrap",
	"title": "title",
	"qr-img-wrap": "qr-img-wrap",
	"qr-foot": "qr-foot",
	"fn-dialog__title": "fn-dialog__title",
	"fn-dialog__bottom": "fn-dialog__bottom",
	"down-qr": "down-qr",
	"down-load-save": "down-load-save"
};