import { ERROR } from 'constants';
import { fetchApi as fetch, showInfo, throttle, ot } from 'utils';
import { SET_ORDER_DATA, SET_SUG_DATA, MERGE_DATA, FETCH_ORDER_DATA, PAY_ARRIVAL, payModeField } from '../../constant';

const needCheckPayDeliveryKeys = { payCoDelivery: 1, coDelivery: 1, coDeliveryFee: 1 };

const payMiddleware = page => store => {
  const defaultPayModeIsPayArrival = data => {
    const { payArrivalWhenCashDiscountRebate, cashreturn, discount, rebate, payArrivalWhenPayAdv, coPayAdv } = data;
    return (
      (payArrivalWhenCashDiscountRebate && (+cashreturn || +discount || +rebate)) || (payArrivalWhenPayAdv && +coPayAdv)
    );
  };

  const needChangePayModeToPayArrival = data =>
    defaultPayModeIsPayArrival(data) &&
    data.payMode !== PAY_ARRIVAL &&
    data.payModeEnum.find(item => item.key === PAY_ARRIVAL);

  const getPayMode = throttle(() => {
    const state = store.getState();
    const url = '/Order/Order/getPayMode';
    const { corName, corMobile, tpl } = state;
    const req = { name: corName, mobile: corMobile, order_tp_id: tpl, company_id: state.cid };
    const conf = { method: 'POST', body: { req } };
    fetch(url, conf).then(res => {
      const curState = store.getState();
      const { paymentUponLast } = curState;
      const resPayMode = res.res.pay_mode || {};
      const defaultPayMode = res.res.default_value.key;
      const payModeEnum = Object.entries(resPayMode).map(([key, name]) => ({ key, name }));
      const curPayMode = curState.payMode;
      if (
        (paymentUponLast && !(curPayMode === PAY_ARRIVAL && defaultPayModeIsPayArrival(curState))) ||
        !resPayMode[curPayMode]
      ) {
        store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'payMode', val: defaultPayMode } });
      }
      store.dispatch({ type: SET_SUG_DATA, payload: { key: 'payMode', data: payModeEnum } });
    });
  }, 500);

  const setDefaultPayMode = (state, key, val) => {
    const {
      payArrivalWhenCashDiscountRebate,
      cashreturn,
      discount,
      rebate,
      payArrivalWhenPayAdv,
      coPayAdv,
      payMode,
      payModeEnum,
    } = state;
    const data = {
      payArrivalWhenCashDiscountRebate,
      cashreturn,
      discount,
      rebate,
      payArrivalWhenPayAdv,
      coPayAdv,
      payMode,
      payModeEnum,
      [key]: val,
    };
    if (needChangePayModeToPayArrival(data)) {
      store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'payMode', val: PAY_ARRIVAL } });
    }
  };

  const checkPayDelivery = throttle(() => {
    const state = store.getState();
    const { payCoDelivery, coDelivery, coDeliveryFee } = state;
    if (payCoDelivery && payCoDelivery > (+coDelivery || 0) - (coDeliveryFee || 0)) {
      showInfo(ERROR, `${ot('货款扣')}金额不得超过${ot('代收货款')}-${ot('货款手续费')}！`);
    }
  }, 500);

  const onChange = (next, key, val) => {
    const state = store.getState();
    const { keyMap } = state;
    switch (key) {
      case 'corName': {
        getPayMode();
        break;
      }
      case 'corMobile': {
        getPayMode();
        break;
      }
      case 'cashreturn': {
        setDefaultPayMode(state, key, val);
        break;
      }
      case 'discount': {
        setDefaultPayMode(state, key, val);
        break;
      }
      case 'rebate': {
        setDefaultPayMode(state, key, val);
        break;
      }
      case 'coPayAdv': {
        setDefaultPayMode(state, key, val);
        break;
      }
      case 'totalPrice': {
        const { payMode } = state;
        if (payMode && payMode !== 'pay_free' && payMode !== 'pay_multi') {
          page.merge(keyMap[payMode], val);
        }
        break;
      }
      case 'payMode': {
        const userFor = page.props.usedFor;
        // 网点中转接收，多笔付时以下付款方式需要可编辑
        // 现付、回付、月结、货款扣、货到打卡、欠付
        if (userFor === 'pointTrans') {
          const editable = val === 'pay_multi';
          store.dispatch({
            type: MERGE_DATA,
            payload: {
              payBillingEditable: editable,
              payCreditEditable: editable,
              payMonthlyEditable: editable,
              payReceiptEditable: editable,
              payCoDeliveryEditable: editable,
              payOwedEditable: editable,
            },
          });
        }
        if (state.ext.is_point_trans === 1 && state.origin.order_data.pay_mode.value !== val && val === 'pay_arrival') {
          showInfo(ERROR, '此运单为网点中转运单，无法修改为到付，如有需要请先取消中转！');
          setTimeout(() => {
            store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'payMode', val: state.payMode } });
          });
          break;
        }
        // 清空原来的
        val !== 'pay_multi' &&
          payModeField.forEach(payMode => {
            state[payMode] && store.dispatch({ type: SET_ORDER_DATA, payload: { key: payMode, val: '' } });
          });
        // 设置新的
        if (val && val !== 'pay_free' && val !== 'pay_multi') {
          store.dispatch({ type: SET_ORDER_DATA, payload: { key: keyMap[val], val: state.totalPrice || '' } });
        }
        break;
      }
      case 'payBilling': {
        store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'payBillingPaid', val: !!+val } });
        break;
      }
      default:
        break;
    }

    // 货款扣 不能大于 代收货款 - 手续费
    needCheckPayDeliveryKeys[key] && checkPayDelivery();
  };
  return next => action => {
    // const state = store.getState()
    switch (action.type) {
      case SET_ORDER_DATA:
        onChange(next, action.payload.key, action.payload.val);
        break;
      case MERGE_DATA:
        if (action.payload.key === 'totalPrice') {
          onChange(next, action.payload.key, action.payload.val);
        }
        break;
      case SET_SUG_DATA: {
        const { key } = action.payload;
        // 更新付款方式下拉 同时要隐藏不包含在下拉内的付款方式输入框
        if (key === 'payMode' && page.props.usedFor !== 'pre' && page.props.usedFor !== 'tags') {
          const merge = {};
          const state = store.getState();
          const payModeEnum = action.payload.data;
          payModeField.forEach(k => (merge[`${k}Show`] = false));
          payModeEnum.forEach(item => (merge[`${state.keyMap[item.key]}Show`] = true));
          store.dispatch({ type: MERGE_DATA, payload: merge });
        }
        break;
      }
      case FETCH_ORDER_DATA: {
        setTimeout(() => {
          const state = store.getState();
          if (!state.isDetail) {
            page.setSug('payMode', state.payModeEnum);
          }
        });
        break;
      }
      default:
        break;
    }
    next(action);
  };
};

export default payMiddleware;
