import React from 'react';
import { fetchApi, throttle, highlightStr } from 'utils';

export const getPoint = (keyword, state, fetch = fetchApi) => {
  const {
    startPointId,
    cid,
    startArrNeedPoi,
    arrOnlyRoute,
    arrNoRelation,
    arrPointShow,
    routeShow,
    ext,
    route,
    arrInfo,
  } = state;
  const { isPointTrans, companyInfo } = state;
  const url = '/Basic/Nsug/pointSug/';
  const arr = arrInfo && arrInfo.addr ? arrInfo.addr : arrInfo;
  console.log(arr, 'mmmmmmm');
  const req = {
    search: keyword,
    start_pid: startPointId,
    newfun: true,
    group_id: window.group_id,
    company_id: isPointTrans ? companyInfo.company_id : cid, // 网点中转 上级可替下级操作
    department_id: window.user_info.department_id,
    user_id: window.user_id,
    property: isPointTrans ? companyInfo.property : window.company_info.property,
    show_dst: arrPointShow ? 1 : 0,
    show_route: routeShow ? 1 : 0,
    start_in_divi: startArrNeedPoi ? 1 : 0,
    arr_only_route: arrOnlyRoute ? 1 : 0,
    dest_drop_hide_upper_route: arrNoRelation ? 1 : 0,
    belong_dist: ext.belong_dist,
    dst_relate: window.company_setting.dst_relate ? window.company_setting.dst_relate.select : [],
    arr_point:
      arr.address !== undefined &&
      !arr.address &&
      route &&
      route.route &&
      ([...route.route].reverse().find(item => item.company_id > 0) || {}).company_id,
    arr: arr && (arr.id || arr.show_val) && !arr.address,
    arr_type: arr.id && !arr.address ? 'id' : 'kw',
  };
  const conf = { method: 'POST', body: { req } };
  return fetch(url, conf).then(res => res.res.map(item => ({ ...item, addr: item.arr_point_addr })));
};

export const getArrInfo = (keyword, state, fetch = fetchApi) => {
  const {
    startPointId,
    cid,
    startArrNeedPoi,
    arrOnlyRoute,
    arrNoRelation,
    arrPointShow,
    routeShow,
    ceeRelateArrPoint,
    ext,
  } = state;
  const { isPointTrans, companyInfo } = state;
  const url = '/Basic/Nsug/sug/';
  const req = {
    search: keyword,
    start_pid: startPointId,
    newfun: true,
    group_id: window.group_id,
    company_id: isPointTrans ? companyInfo.company_id : cid,
    department_id: window.user_info.department_id,
    user_id: window.user_id,
    property: isPointTrans ? companyInfo.property : window.company_info.property,
    // eslint-disable-next-line no-nested-ternary
    show_dst: ceeRelateArrPoint ? 0 : arrPointShow ? 1 : 0,
    // eslint-disable-next-line no-nested-ternary
    show_route: ceeRelateArrPoint ? 0 : routeShow ? 1 : 0,
    start_in_divi: startArrNeedPoi ? 1 : 0,
    arr_only_route: arrOnlyRoute ? 1 : 0,
    dest_drop_hide_upper_route: arrNoRelation ? 1 : 0,
    belong_dist: ext.belong_dist,
  };
  return fetch(url, { method: 'POST', body: { req } });
};

export const removeArrSug = (startPointId, option) => {
  const url = '/Basic/Nsug/deleteSug/';
  const req = { start_pid: startPointId, unique_key: option.unique_key };
  return fetchApi(url, { method: 'POST', body: { req } });
};

export const getLast10StartInfo = () => fetch('/Order/Order/last10StartInfo', { method: 'POST' });

export const getRcvStn = (keyword, fetch = fetchApi, state) => {
  const url = '/Basic/Org/orgSug';
  const req = { search: keyword, page_num: 10, org_types: [4], company_id: state.cid };
  return fetch(url, { method: 'POST', body: { req } });
};

export const getRoute = val => ({
  route_id: val.route_id || 0,
  route_name: val.route_name || '',
  route_nick: val.route_nick || '',
  route: val.route_nodes || [],
  route_time: val.route_time || 0,
  node2: val.route_second_node,
});

export const getProductLine = throttle((startInfo, arrInfo, productType, cid, resolve) => {
  let arrCity = '';
  let startCity = '';
  const arr = arrInfo && arrInfo.addr ? arrInfo.addr : arrInfo;
  if (arr) {
    if (arr.city) {
      arrCity = arr.city;
    } else if (arr.province) {
      arrCity = arr.province;
    }
  }
  if (startInfo) {
    if (startInfo.city) {
      startCity = startInfo.city;
    } else {
      startCity = startInfo.province;
    }
  }
  const arrVal = arr ? `${arrCity}${arr.district || ''}${arr.street || ''}` || arr.show_val || '' : '';
  const startVal = startInfo
    ? `${startCity}${startInfo.district || ''}${startInfo.street || ''}` || startInfo.show_val || ''
    : '';
  const arrShowVal = arr ? arr.show_val || '' : '';
  const startShowVal = startInfo ? startInfo.show_val || '' : '';
  const productVal = productType ? productType.name : '';
  const url = '/Order/Order/getProductLine';
  const req = {
    start_ext: startVal,
    arr_ext: arrVal,
    product_type: productVal,
    start: startShowVal,
    arr: arrShowVal,
    company_id: cid,
  };
  const conf = { method: 'POST', body: { req } };
  fetchApi(url, conf).then(res => {
    if (res.errno !== 0) {
      const productTypeEnum = res.res.product_type;
      const ret = { productLine: '', productTypeEnum };
      resolve(ret);
    } else {
      const productLine = (res.res.product_line && res.res.product_line[0]) || '';
      const productTypeEnum = res.res.product_type;
      const ret = { productLine, productTypeEnum };
      if (!productType && productTypeEnum.length === 1) {
        ret.productType = productTypeEnum[0];
      }
      resolve(ret);
    }
  });
}, 500);

export const hackProductType = data => {
  const enums = data.product_list || [];
  const key = data.order_data.product_key && data.order_data.product_key.value;
  const name = data.order_data.product_type && data.order_data.product_type.value;
  let value = { key, name };
  if (key) {
    const find = enums.find(item => item.key === key);
    if (find) {
      if (find.name !== name) {
        value = { key: -1, name };
        return { value, enums: [...enums, value] };
      }
      return { value, enums };
    }
    return { value, enums: [...enums, value] };
  }
  return { value: '', enums };
};

export const renderArrInfoAddressCol = (item, col, key, str, keyword) => (
  <div className="arr-info-show-val">
    {highlightStr(item.show_val, keyword)}
    <span className="text-gray" title={item.show_p_val}>
      {item.show_p_val}
    </span>
  </div>
);

export const checkEntrustNumExisted = (val, { isModify, isUpdatePre, origin, setting }) => {
  const conf = { method: 'POST', body: { req: { entrust_num: val } } };
  const url = '/Order/Order/checkEntrustNumExisted';
  if (!val || !setting.entrust_num_repeat || !setting.entrust_num_repeat.checked) return '';
  if ((isModify || isUpdatePre) && val === (origin.order_data.entrust_num || {}).value) return '';
  return fetchApi(url, conf).then(res => (res.errno === 330 ? res.errmsg : ''));
};

export const getDevice = (keyword, state, fetch) => {
  const url = '/Basic/Device/deviceSug';
  const req = { search: keyword, company_id: state.cid };
  return fetch(url, { method: 'POST', body: { req } });
};
export const getStockPointSug = (keyword, state, fetch = fetchApi) => {
  const url = '/Basic/Org/orgSug';
  const req = {
    search: keyword,
    need_lise: 1,
    org_types: [3, 4, 5, 7, 8, 9, 10],
    is_group_all: 1,
    company_id: state.cid,
  };
  return fetch(url, { method: 'POST', body: { req } });
};
