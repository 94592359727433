import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { post, opTips } from 'utils';
import actions from 'actions';

const isFromPickClip = listPage => listPage && listPage.getReq().tab === 'pick_clip'; // 是否是挑单夹事件

// 运单配载（极速版）
export const batchTaskAdd = function ({ that, loadType, title, controller, btnKey }) {
  const urls = {
    pickup: '/Truck/Pickup/onLoading',
    truck: '/Truck/Truck/onLoading',
    delivery: '/Truck/Delivery/onLoading',
  };
  const params = {
    loading_od_link_ids: that.getSelectesKey('od_link_id'),
    id: (that.getSelectesKey('id') || []).filter(item => !!item),
    orderNum: that.getSelectesKey('order_num'),
    is_task: 1,
  };
  const jump = query => {
    const urlData = {
      controller,
      action: 'taskLoad',
      query: {
        fromPickClip: isFromPickClip(that),
        showPack: false,
        isOrderPicker: true,
        loadType,
        title,
      },
    };
    actions.api.direct(urlData, 'directInfo', query);
  };
  btnKey && that?.disableMenuButton?.(btnKey);
  post(urls[loadType], params)
    .then(res => {
      opTips({
        resData: res,
        opType: '新增配载',
        orderType: '订单',
        showSucTips: false,
        sucCallback: () => {
          jump({ loadingOdLinkIds: that.getSelectesKey('od_link_id') });
        },
        continueCallback: (sids, cb) => {
          cb && cb();
          setTimeout(() => that.handleRefreshData(), 1500);
          jump({ loadingOdLinkIds: sids });
        },
      });
    })
    .finally(() => {
      btnKey && that?.enableMenuButton?.(btnKey);
    });
};
// 回单照片-图片标签
export const ImageTag = forwardRef((props, ref) => {
  const { tag } = props;
  const [text, setText] = useState(tag);

  const handleChangeValue = v => setText(v);

  useImperativeHandle(ref, () => ({
    handleChangeValue,
  }));

  return text ? (
    <div style={{ textAlign: 'center' }}>
      <div
        style={{
          display: 'inline-block',
          padding: '2px 4px',
          textAlign: 'center',
          border: '1px solid #59a89d',
          fontSize: '14px',
          color: '#59a89d',
          borderRadius: '3px',
          marginBottom: '10px',
        }}
      >
        {text}
      </div>
    </div>
  ) : null;
});
