exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cor-cee-history .fn-input-pure-wrap{height:28px;width:200px;margin-right:15px}.cor-cee-history .fn-table-wrap{margin-top:10px}.cor-cee-history table{table-layout:auto}.cor-cee-history table th{color:#222}.cor-cee-history table small{color:#555;font-weight:400}.cor-cee-history table td.text-red{color:#f12222}.cor-cee-history .no-data-tip{height:100px;line-height:100px;margin-top:20px;font-size:14px;text-align:center;color:#999}.cor-cee-history .no-data-tip i{color:#3fcb8e;font-size:20px;width:auto;margin-right:8px}.cor-cee-history .person-history-info{max-height:calc(100vh - 240px);overflow-y:auto}.cor-cee-history .history-card{border:1px solid #78aadd;border-top:none;padding-bottom:10px}.cor-cee-history .history-card.table{margin-bottom:10px}.cor-cee-history .history-card.hidden .fn-icon-carry-out{-webkit-transform:rotate(0);transform:rotate(0)}.cor-cee-history .history-card.hidden .remark-wrap,.cor-cee-history .history-card.hidden .table-wrap{height:0;overflow:hidden}.cor-cee-history .remark-wrap{padding:10px 10px 0}.cor-cee-history .remark-wrap .no-data-tip{margin-top:0}.cor-cee-history h3{min-width:98px;height:32px;line-height:32px;font-size:14px;background:#cae0f6;color:#333;border-top:1px solid #78aadd;border-bottom:1px solid #78aadd}.cor-cee-history h3:before{content:\"\";padding:0 1px;background:#ff8100;margin:0 6px}.cor-cee-history .fn-icon-carry-out{font-size:16px;width:16px;line-height:1;height:16px;margin-right:5px;margin-bottom:2px;-webkit-transform:rotate(180deg);transform:rotate(180deg)}.cor-cee-history .fn-icon-carry-out:hover{color:#0097cf}", ""]);

// exports
exports.locals = {
	"prefixCls": "cor-cee-history",
	"cor-cee-history": "cor-cee-history",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"fn-table-wrap": "fn-table-wrap",
	"text-red": "text-red",
	"no-data-tip": "no-data-tip",
	"person-history-info": "person-history-info",
	"history-card": "history-card",
	"table": "table",
	"hidden": "hidden",
	"fn-icon-carry-out": "fn-icon-carry-out",
	"table-wrap": "table-wrap",
	"remark-wrap": "remark-wrap"
};