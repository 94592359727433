/**
 * Created by wangnaihe on 2017/8/28.
 */
import _ from 'lodash';
import { switchWeight } from '../tool';
import { bigComputed } from 'utils';

export const filterGoods = (field, keyword, state, fetch) => {
  const {
    cid,
    corId,
    corName,
    corMobile,
    ceeName,
    ceeMobile,
    arrInfo,
    goodsRelate,
    goodsRelateCor,
    goodsRelateCee,
    goodsRelateArr,
    unitMap,
    weightUnit,
    isGoodsManager,
  } = state;
  const isGoodsManageSug = (keyword && (!goodsRelate || !goodsRelate.length)) || isGoodsManager;
  const url = isGoodsManageSug ? 'Basic/Goods/goodsSug' : '/Table/Search/orderList';
  const req = isGoodsManageSug
    ? {
        from: 'order',
        name: field === 'name' && keyword ? keyword : undefined,
        code: field === 'code' && keyword ? keyword : undefined,
        cor_id: goodsRelateCor ? corId : 0,
      }
    : {
        category: 'Order',
        tab: 'goods_name_sug_new',
        sort: {
          billing_date: 'desc',
        },
        page_num: 1,
        page_size: 10,
        fetch_mode: 'body',
        cid,
        company_id: cid,
        query: {
          _logic: 'and',
          com_id: cid,
          arr: goodsRelateArr && arrInfo && arrInfo.addr ? arrInfo && arrInfo.addr : undefined,
          'cor_name._exact_': goodsRelateCor && corName ? corName : undefined,
          'cor_mobile._exact_': goodsRelateCor && corMobile ? corMobile : undefined,
          'cee_name._exact_': goodsRelateCee && ceeName ? ceeName : undefined,
          'cee_mobile._exact_': goodsRelateCee && ceeMobile ? ceeMobile : undefined,
          g_name: field === 'name' && keyword ? keyword : undefined, // eslint-disable-line
          g_code: field === 'code' && keyword ? keyword : undefined,
        },
        filter: {},
        fields: [
          'order_num',
          'billing_date',
          'cor_name',
          'cee_name',
          'arr',
          'g_name',
          'g_code',
          'co_freight_f',
          'g_subtotal_price',
          'g_n',
          'g_weight_per_num',
          'g_weight',
          'g_volume',
          'g_unit_price_disp',
          'g_cat',
          'g_pkg',
          'g_pkg_service',
          'g_spec',
          'g_model',
          'g_special',
          'co_delivery_f',
          'co_pickup_f',
          'co_pkg_f',
          'declared_value',
          'co_insurance',
        ],
      };
  const conf = { method: 'POST', body: { req } };
  return fetch(url, conf).then(res =>
    res.errno !== 0
      ? []
      : (isGoodsManageSug ? res.res : res.res.data).map(item => {
          const unit = item.g_unit_price_disp && item.g_unit_price_disp[0] && item.g_unit_price_disp[0].unit_p_unit;
          const unitP = item.g_unit_price_disp && item.g_unit_price_disp[0] && item.g_unit_price_disp[0].unit_p;
          // eslint-disable-next-line no-nested-ternary
          const price = unitP ? (weightUnit === '吨' && unit === 'per_w' ? +(1000 * unitP).toFixed(6) : unitP) : '';
          const weight = item.g_weight && item.g_weight[0];
          const weightPerNum = item.g_weight_per_num && item.g_weight_per_num[0];
          return {
            ...item,
            g_num: item.g_n,
            g_weight_per_num: [weightPerNum && weightUnit === '吨' ? switchWeight(weightPerNum) : weightPerNum],
            g_weight: [weight && weightUnit === '吨' ? switchWeight(weight) : weight],
            g_unit_p: [price],
            g_unit_p_unit: [unit],
            unit_str: item.g_unit_price_disp && item.g_unit_price_disp[0] && `${price}${unitMap[unit]}`,
            pkg_service_str: item.g_pkg_service && (item.g_pkg_service[0] ? '是' : '否'),
            g_special_str:
              item.g_special &&
              item.g_special[0] &&
              (!Array.isArray(item.g_special[0]) ? item.g_special[0] : item.g_special[0].join(',')),
            g_special:
              item.g_special && item.g_special[0] && !Array.isArray(item.g_special[0])
                ? [[item.g_special[0]]]
                : item.g_special,
          };
        }),
  );
};

const freightUnitMap = { per_num: 'num', per_w: 'weight', per_v: 'volume', per_s: 'suit' };

export const whHeader = [
  { key: 'wh_name', title: '仓库' },
  { key: 'wh_area_name', title: '库区' },
  { key: 'wh_location_name', title: '货位' },
];

export const calcSubTotal = (item, key, page, i = 0) => {
  const unit = item.unit_p_unit || 'per_num';
  const columnKey = freightUnitMap[unit];
  const amount = item[columnKey];
  const unitPrice = item.unit_p;
  const subtotalPriceEdited = page?.edited?.(`subtotal_price_${i + 1}`) || false;
  if (
    ((key === columnKey && (unitPrice === '0' || +unitPrice)) ||
      ((key === 'unit_p' || key === 'unit_p_unit') && (amount === '0' || +amount))) &&
    !subtotalPriceEdited
  ) {
    const value = _.round(bigComputed([unitPrice || 0, amount || 0], 'times'), 2);
    const oldValue = +item.subtotal_price || 0;
    if (value !== oldValue) return { subtotal_price: value, subtotal_price_src: 'goods' };
  }
  return {};
};

// 处理特殊货物可能为字符串的情况
export const resolveSpecialVal = val => {
  if (!val) return '';
  if (Array.isArray(val)) {
    return val.join(',');
  }
  return val;
};
