import { throttle } from 'utils';
import { SET_ORDER_DATA } from '../constant';
import { getEnumText, calcField } from '../tool';

const extraMiddleware = page => store => {
  const updateRemark = (remark, replace, add, lock) => {
    // eslint-disable-next-line no-nested-ternary
    const remarkArr = lock ? remark : remark && remark.length ? remark.split(',') : [];
    const index = remarkArr.indexOf(replace);
    if (add && index === -1) {
      remarkArr.push(replace);
      store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'remark', val: lock ? remarkArr : remarkArr.join(',') } });
    }
    if (!add && index > -1) {
      remarkArr.splice(index, 1);
      store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'remark', val: lock ? remarkArr : remarkArr.join(',') } });
    }
  };
  const updateReceiptRequire = (remark, old, replace, lock) => {
    // eslint-disable-next-line no-nested-ternary
    const remarkArr = lock ? remark : remark && remark.length ? remark.split(',') : [];
    const index = remarkArr.indexOf(old);
    if (index === -1) {
      remarkArr.push(replace);
      store.dispatch({
        type: SET_ORDER_DATA,
        payload: { key: 'receiptRequire', val: lock ? remarkArr : remarkArr.join(',') },
      });
    }
    if (index > -1) {
      remarkArr[index] = replace;
      store.dispatch({
        type: SET_ORDER_DATA,
        payload: { key: 'receiptRequire', val: lock ? remarkArr : remarkArr.join(',') },
      });
    }
  };

  const calcShortfallWeight = throttle(() => {
    const state = store.getState();
    const { goods, actualWeight, weightUnit, groupId, odCurrency0001 } = state;
    // CMTX-8962 订单增加自定义字段1-4 -产品周贺龙
    // 临时86940客户写死: 订单的亏吨重量=自定义数字1-货物实际重量
    const is86940 = [86940, 364].includes(+groupId);
    const totalWeight = (is86940 ? odCurrency0001 : calcField(goods, 'weight')) || 0;
    const shortfallWeight = +(totalWeight - (+actualWeight || 0)).toFixed(weightUnit === 'kg' ? 3 : 6);
    page.set('shortfallWeight', shortfallWeight);
  }, 100);

  const calcShortfallVolume = throttle(() => {
    const state = store.getState();
    const { goods, actualVolume } = state;
    const totalVolume = calcField(goods, 'volume');
    const shortfallVolume = +(totalVolume - (+actualVolume || 0)).toFixed(6);
    page.set('shortfallVolume', shortfallVolume);
  }, 100);

  const onChange = (next, key, val, index, changes) => {
    const state = store.getState();
    switch (key) {
      case 'serviceType': {
        store.dispatch({
          type: SET_ORDER_DATA,
          payload: { key: 'pickup', val: val === 'door_door' || val === 'door_site' },
          src: 'serviceType',
        });
        break;
      }
      case 'payMode': {
        // 付款方式为[]时修改回单数
        const receiptNEle = page.getEle('receipt_n');
        if (state.receiptPayments.includes(val) && ((receiptNEle && !receiptNEle.dataset.isEdited) || !receiptNEle)) {
          store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'receiptN', val: state.defaultReturnPayNum } });
        }
        break;
      }
      case 'declaredValue': {
        // 声明价值超过n 显示备注
        const { remarkLock } = state;
        const declareRemarkMoney = +state.declareRemarkMoney;
        const { declareRemark } = state;
        const remark = state.remark || '';
        declareRemark && updateRemark(remark, declareRemark, val > declareRemarkMoney, remarkLock);
        break;
      }
      case 'coDelivery': {
        // 代收货款超过n显示备注
        const { remarkLock } = state;
        const deliveryRemarkMoney = +state.deliveryRemarkMoney;
        const { deliveryRemark } = state;
        const remark = state.remark || '';
        deliveryRemark && updateRemark(remark, deliveryRemark, val > deliveryRemarkMoney, remarkLock);
        break;
      }
      case 'receiptN': {
        // 有回单显示备注
        const { remarkLock } = state;
        const { receiptRemark } = state;
        const remark = state.remark || '';
        receiptRemark && updateRemark(remark, receiptRemark, +val > 0, remarkLock);
        if (state.groupId === 4586) {
          const { receiptN, receiptRequire, receiptCat, receiptCatEnum = [], receiptRequireLock } = state;
          const receiptCatText = getEnumText(receiptCat, receiptCatEnum, 'key', 'value') || receiptCat;
          const newReceiptRequire = val && receiptCat ? `${receiptCatText}${val}份` : '';
          updateReceiptRequire(receiptRequire, `${receiptCatText}${receiptN}份`, newReceiptRequire, receiptRequireLock);
        }
        break;
      }
      case 'receiptCat': {
        if (state.groupId === 4586) {
          const { receiptN, receiptRequire, receiptCat, receiptCatEnum, receiptRequireLock } = state;
          const receiptCatTextNew = getEnumText(val, receiptCatEnum, 'key', 'value') || val;
          const newReceiptRequire = val && receiptCat ? `${receiptCatTextNew}${receiptN}份` : '';
          const receiptCatTextOld = getEnumText(receiptCat, receiptCatEnum, 'key', 'value') || receiptCat;
          updateReceiptRequire(
            receiptRequire,
            `${receiptCatTextOld}${receiptN}份`,
            newReceiptRequire,
            receiptRequireLock,
          );
        }
        break;
      }
      case 'noticeDelivery': {
        // 等通知放货显示备注
        const { remarkLock } = state;
        const noticeDeliveryRemark = '等通知放货';
        const { noticeDeliveryAutoRemark } = state;
        const remark = state.remark || '';
        noticeDeliveryAutoRemark && updateRemark(remark, noticeDeliveryRemark, !!val, remarkLock);
        break;
      }
      case 'deliveryMode': {
        // 送货方式为xxx显示备注
        const { remarkLock } = state;
        const { deliveryModeRemark } = state;
        const { remarkWhenDeliveryModeIs } = state;
        const remark = state.remark || '';
        deliveryModeRemark && updateRemark(remark, deliveryModeRemark, !!remarkWhenDeliveryModeIs[val], remarkLock);
        break;
      }
      case 'needDispatch': {
        // 如果设置了 勾选需要配安的运单才可配安 ，改变需要配安的状态时需关联配安状态
        const { dispatch, needDispatch, pointCostInfo } = state;
        const canDispatch = (dispatch && needDispatch !== 1) || !dispatch;
        const dispatchChecked =
          pointCostInfo && !(+pointCostInfo[0].dispatch_st === 10 || !pointCostInfo[0].dispatch_st);
        if (!canDispatch && dispatchChecked) {
          page.setTable('pointCostInfo', 0, { dispatch_st: 10 });
        }
        break;
      }
      case 'goods': {
        if (!changes || changes.weight) {
          calcShortfallWeight();
        }
        if (!changes || changes.volume) {
          calcShortfallVolume();
        }
        if (!changes || changes.practical_w) {
          page.set('actualWeight', calcField(val, 'practical_w'));
        }
        if (!changes || changes.practical_v) {
          page.set('actualVolume', calcField(val, 'practical_v'));
        }
        break;
      }
      case 'odCurrency0001':
      case 'actualWeight': {
        calcShortfallWeight();
        break;
      }
      case 'actualVolume': {
        calcShortfallVolume();
        break;
      }

      default:
        break;
    }
  };

  return next => action => {
    switch (action.type) {
      case SET_ORDER_DATA:
        onChange(next, action.payload.key, action.payload.val, action.index, action.changes);
        break;
      default:
        break;
    }
    next(action);
  };
};

export default extraMiddleware;
