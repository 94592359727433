module.exports = {
  allKeys: ['b_link_id', 'car_batch', 'shuttle_batch', 'pickup_batch', 'delivery_batch', 'b_basic_id', 'company_id'],
  buttonKeys: {
    // 车辆批次修改
    modify_tr_batch: {
      hasTab: false,
    },
    // 短驳批次修改
    modify_shuttle_batch: {
      batchNo: 'shuttle_batch',
      hasTab: false,
    },
    // 送货批次修改
    modify_delivery_batch: {
      batchNo: 'delivery_batch',
      hasTab: false,
    },
    // 打包修改
    edit_pack: {
      batchNo: 'pack_batch',
      hasTab: false,
    },
    // 提货批次修改
    modify_pickup_batch: {
      batchNo: 'pickup_batch',
      hasTab: false,
    },
    // 订单提货批次修改
    modify_pickup_reservation_batch: {
      batchNo: 'pickup_batch',
      hasTab: false,
    },
    // 车辆批次link
    Batch_car_batch: {
      hasTab: true,
      isEdit: false,
      operateType: 'view',
    },
    Abnormal_car_batch: {
      hasTab: true,
      isEdit: false,
      operateType: 'view',
    },
    // 短驳批次link
    Batch_shuttle_batch: {
      batchNo: 'shuttle_batch',
      hasTab: true,
      isEdit: false,
      operateType: 'view',
    },
    // 短驳批次link
    Settle_shuttle_batch: {
      batchNo: 'shuttle_batch',
      hasTab: true,
      isEdit: false,
      operateType: 'view',
    },
    // 送货批次link
    Batch_delivery_batch: {
      batchNo: 'delivery_batch',
      hasTab: true,
      isEdit: false,
      operateType: 'view',
    },
    // 送货批次link
    Settle_delivery_batch: {
      batchNo: 'delivery_batch',
      hasTab: true,
      isEdit: false,
      operateType: 'view',
    },
    // 提货批次link
    Batch_pickup_batch: {
      batchNo: 'pickup_batch',
      hasTab: true,
      isEdit: false,
      operateType: 'view',
    },
    // 提货批次link
    Settle_pickup_batch: {
      batchNo: 'pickup_batch',
      hasTab: true,
      isEdit: false,
      operateType: 'view',
    },
    Order_b_pickup_batch: {
      batchNo: 'pickup_batch',
      hasTab: true,
      isEdit: false,
      operateType: 'view',
    },
    // 订单提货tab 订单提货批次
    Reservation_b_pickup_batch: {
      batchNo: 'pickup_batch',
      hasTab: true,
      isEdit: false,
      operateType: 'view',
    },
    'Settle_Batch|car_batch': {
      batchNo: 'Batch|car_batch',
      hasTab: true,
      isEdit: false,
      operateType: 'view',
    },
    // 内部中转
    b_inner_trans_batch: {
      batchNo: 'b_inner_trans_batch',
      hasTab: true,
      isEdit: false,
      operateType: 'view',
    },
    // 外部中转
    b_trans_batch: {
      batchNo: 'b_trans_batch',
      hasTab: true,
      isEdit: false,
      operateType: 'view',
    },
    // 网点按批次对账明细
    BatchAccount_car_batch: {
      batchId: 'link_id',
      batchNo: 'car_batch',
      hasTab: true,
      isEdit: false,
      operateType: 'view',
      basicId: 'basic_id',
      comId: 'com_id',
    },
    'TradeRecord_tr_fare.car_batch': {
      hasTab: true,
    },
    Order_order_num: {
      batchNo: 'b_pack_batch',
    },
    'SettleRecord_Order|b_tr_car_batch': {
      hasTab: true,
      operateType: 'view',
    },
    'SettleRecord_Order|b_tr_up_car_batch': {
      hasTab: true,
      operateType: 'view',
    },
  },
};
