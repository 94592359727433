import { PopUp, PopTip } from 'components';
import { ERROR, CHECK } from 'constants';
/*
 * 适用于列表页-【修改】操作
 * 判断选中几条元素
 * if length<1 提示 请选择
 * if length>1 提示 只能选择一条
 */
export function UserChooseFalseTip(arr) {
  const _l = arr.length;
  let _tips = '请选择！';
  if (_l > 1) {
    _tips = '只能选择一条！';
  }
  if (_l > 1 || _l < 1) {
    new PopUp(PopTip, {
      classname: 'pop_tip',
      type: ERROR,
      isShow: true,
      content: _tips,
      autoDestroy: true,
    }).show();
    return false;
  }
}
/*
 * 适用于列表页-批量操作
 * 判断选中几条元素
 * if length<1 提示 请选择
 * if length>1 提示 只能选择一条
 */
export function UserChooseArrFalseTip(arr) {
  const _l = arr.length;
  const _tips = '请选择！';
  if (_l < 1) {
    new PopUp(PopTip, {
      classname: 'pop_tip',
      type: ERROR,
      isShow: true,
      content: _tips,
      autoDestroy: true,
    }).show();
    return false;
  }
}
/*
 * 系统报错
 */
export function errmsgTip(errmsg) {
  new PopUp(PopTip, {
    classname: 'pop_tip',
    type: ERROR,
    isShow: true,
    content: errmsg,
    autoDestroy: true,
  }).show();
  return false;
}
/*
 * 成功提示
 */
export function successTip(msg) {
  new PopUp(PopTip, {
    classname: 'pop_tip',
    type: CHECK,
    isShow: true,
    content: msg,
    autoDestroy: true,
  }).show();
}
