exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fn-show_info__icon{vertical-align:top}.fn-show_info__text{overflow-x:inherit;overflow-y:inherit}.carry-set-check{margin-top:5px;margin-left:0}.carry-set-check .reason-red{color:red;font-size:14px;margin-top:15px}.carry-set-check .name{color:#e44cc3}.carry-set-check .reason-content{margin-left:-46px}.carry-set-check .marginBottom20{margin-bottom:20px}.carry-set-check .reason-name{margin-top:5px;color:gray}.carry-set-check .reason-span{margin:5px 6px 5px 0}.carry-set-check p{color:#ccc;max-width:400px;margin-top:10px;margin-left:30px}.truck-state-change .fn-show_info__text_con_notice{color:#999;margin-left:60px}", ""]);

// exports
exports.locals = {
	"fn-show_info__icon": "fn-show_info__icon",
	"fn-show_info__text": "fn-show_info__text",
	"carry-set-check": "carry-set-check",
	"reason-red": "reason-red",
	"name": "name",
	"reason-content": "reason-content",
	"marginBottom20": "marginBottom20",
	"reason-name": "reason-name",
	"reason-span": "reason-span",
	"truck-state-change": "truck-state-change",
	"fn-show_info__text_con_notice": "fn-show_info__text_con_notice"
};