/**
 * Created by wangnaihe on 2017/8/16.
 */
import React from 'react';
import { formatCamelCase, ot } from 'utils';
import { PRICE_KEYS } from 'constants/priceCalc';
import { renderPayeeCol, renderPayeeTitle } from 'utils/sug/truck';

const mgrHeader = [
  { key: 'company_name', title: '所属组织' },
  { key: 'name', title: '员工姓名' },
  { key: 'position_name', title: '职位' },
];

const trHeader = [
  { key: 'tr_num', title: '车牌号' },
  { key: 'dr_name', title: '姓名' },
  { key: 'dr_tel', title: '电话' },
];

const drHeader = [
  { key: 'name', title: '姓名' },
  { key: 'tel', title: '电话' },
];

const bDrHeader = [
  { key: 'name', title: '姓名' },
  { key: 'tel', title: '电话' },
  { key: 'p_c_state_display', title: '认证状态' },
  { key: 'dr_con_state_display', title: '签约状态' },
  { key: 'payee', title: '收款人', render: renderPayeeCol, titleRender: renderPayeeTitle },
];

const payeeHeader = [
  { key: 'tr_payee_name', title: '收款人' },
  { key: 'tr_payee_open_bank', title: '开户行' },
  { key: 'tr_payee_open_phone', title: '开户电话' },
  { key: 'tr_payee_bank_card_num', title: '银行卡号' },
  { key: 'tr_payee_id_card', title: '身份证号' },
  { key: 'cert_status_display', title: '认证状态' },
];

export const sugHeaderMap = {
  std_mgr_id: mgrHeader,
  std_cee_mgr_id: mgrHeader,
  b_tr_num: [
    { key: 'tr_num', title: '车牌号' },
    { key: 'dr_name', title: '姓名' },
    { key: 'dr_tel', title: '电话' },
    { key: 'cert_status_display', title: '认证状态' },
    { key: 'payee', title: '收款人', render: renderPayeeCol, titleRender: renderPayeeTitle },
  ],
  pickup_tr_num: trHeader,
  b_dr_name: bDrHeader,
  pickup_dr_name: drHeader,
  pickup_dr_phone: ['tel'],
  b_dr_phone: bDrHeader,
  point_trans_dn_com_id: [
    { key: 'short_name', title: '公司名称' },
    { key: 'telephone', title: '联系电话' },
  ],
  dn_com_id: [
    { key: 'company_name', title: '所属组织' },
    { key: 'carrier_name', title: '承运公司' },
    { key: 'phone2', title: '联系电话' },
  ],
  address: [
    { key: 'name', title: '到站' },
    { key: 'contract_name', title: '联系人' },
    { key: 'contract_phone', title: '联系电话' },
  ],
  dispatch_dr: [
    { key: 'short_name', title: '所属组织' },
    { key: 'name', title: '司机姓名' },
    { key: 'tel', title: '司机手机号' },
  ],
  trans_route: [
    { key: 'route_nick', title: ot('路由') },
    { key: 'trans_mode_name', title: ot('中转方式') },
    { key: 'mile', title: '里程' },
    { key: 'route_time', title: '时效' },
  ],
  b_tr_payee_name: payeeHeader,
  b_tr_payee_bank_card_num: payeeHeader,
};

export const sugHighlightMap = {
  b_tr_num: 'tr_num',
  pickup_tr_num: 'tr_num',
  b_dr_name: 'name',
  pickup_dr_name: 'name',
  pickup_dr_phone: 'tel',
  b_dr_phone: 'tel',
};

const trNumSugUrl = '/Basic/Truck/truckSug';
const drNameSugUrl = '/Basic/Driver/driverSug';

export const sugMap = {
  b_tr_num: { url: trNumSugUrl, query: { is_tr_header: 1 } },
  pickup_tr_num: { url: trNumSugUrl },
  b_dr_name: { url: drNameSugUrl },
  pickup_dr_name: { url: drNameSugUrl },
  pickup_dr_phone: { url: drNameSugUrl },
  b_dr_phone: { url: drNameSugUrl },
  dn_com_id: { url: '/Basic/Carrier/carrierSug', searchKey: 'search' },
  point_trans_dn_com_id: {
    url: '/Basic/Org/orgSug',
    searchKey: 'search',
    query: {
      need_list: 1,
      include: true,
      org_types: [3, 4, 5],
      is_group_all: 1,
      org_fields: ['telephone', 'service_phone', 'address', 'address_remark'],
    },
  },
  dn_mgr_id: { url: '/Basic/Org/userSug', searchKey: 'search' },
  std_mgr_id: { url: '/Basic/Org/userSug', searchKey: 'search', query: { user_type: [1, 2] } },
  std_cee_mgr_id: { url: '/Basic/Org/userSug', searchKey: 'search', query: { user_type: [1, 2] } },
  b_tr_payee_name: { url: '/Basic/Truck/truckPayeeSug' },
  b_tr_payee_bank_card_num: { url: '/Basic/Truck/truckPayeeSug' },
};

const a2o = arr => arr.reduce((pre, key) => ({ ...pre, [formatCamelCase(key)]: key }), {});

const commonPriceKeys = [
  'product_type',
  'delivery_mode',
  'trsp_mode',
  'service_type',
  'product_line',
  'billing_date',
  'insured',
  'co_freight_f',
  'route_mile',
  'g_pkg_service',
];

const feePriceKeys = PRICE_KEYS;

const trafficPriceKeys = ['start_info', 'arr_info', 'start_point', 'arr_point', 'trans_mode'];

const distPriceKeys = ['cor_pick_dist', 'cee_pick_dist'];

const corPriceKeys = ['cor_addr_info', 'cor_name', 'cor_com', 'cor_id'];

const ceePriceKeys = ['cee_addr_info', 'cee_name', 'cee_com', 'cee_id'];

const truckPriceKeys = ['truck_length', 'truck_type'];
export const actualPriceKeys = ['actual_weight', 'actual_volume'];
export const valueAddedPriceKeys = ['thermosphere', 'value_add_service', 'need_insured', 'urgency', 'insured'];
export const coPriceKeys = ['co_declare_f', 'co_receipt_f', 'co_build_f'];
export const customerPriceKeys = a2o([
  ...actualPriceKeys,
  ...coPriceKeys,
  ...commonPriceKeys,
  ...trafficPriceKeys,
  ...distPriceKeys,
  ...corPriceKeys,
  ...ceePriceKeys,
  ...truckPriceKeys,
  ...valueAddedPriceKeys,
  'cor_cate',
  'cee_cate',
  'declared_value',
  'pickup',
  'receipt_n',
  'co_delivery',
  'obCustomerId',
  'obProjectId',
]);

export const outerTransPriceKeys = a2o([
  ...actualPriceKeys,
  ...coPriceKeys,
  ...commonPriceKeys,
  ...feePriceKeys,
  ...truckPriceKeys,
  ...valueAddedPriceKeys,
  'cee_addr_info',
  'pickup',
]);

export const pointTransPriceKeys = a2o([
  ...actualPriceKeys,
  ...coPriceKeys,
  ...commonPriceKeys,
  ...feePriceKeys,
  ...truckPriceKeys,
  ...valueAddedPriceKeys,
  'cee_addr_info',
  'pickup',
  'arr_info',
  'start_point',
  'arr_point',
]);

export const pickupPriceKeys = a2o([
  ...actualPriceKeys,
  ...coPriceKeys,
  ...commonPriceKeys,
  ...corPriceKeys,
  'cee_name',
  'cee_com',
  'cee_id',
  ...feePriceKeys,
  ...valueAddedPriceKeys,
  'arr_info',
  'start_point',
  'arr_point',
]);

export const budgetPriceKeys = a2o([
  ...actualPriceKeys,
  ...coPriceKeys,
  ...commonPriceKeys,
  ...trafficPriceKeys,
  ...distPriceKeys,
  ...corPriceKeys,
  ...ceePriceKeys,
  ...feePriceKeys,
  ...valueAddedPriceKeys,
  'cor_cate',
  'cee_cate',
  'pickup',
]);

export const innerPriceKeys = a2o([
  ...commonPriceKeys,
  ...feePriceKeys,
  ...trafficPriceKeys,
  ...distPriceKeys,
  ...corPriceKeys,
  ...ceePriceKeys,
  'route_id',
  'route',
  'price_mode',
  'pay_mode',
  'notice_delivery',
  'receipt_n',
]);

// CMTX-8165 价格计算费用项补充
export const newlyKeys = a2o([
  'co_freight_f', // 干线费 = 运费
  'co_pickup_f', // 提货费
  'co_delivery_f', // 送货费
  'co_handling_f', // 装卸费
  'co_install_f', // 安装费
  'co_pay_adv', // 垫付费
  'co_delivery_fee', // 货款手续费
  'cashreturn', // 现返
  'discount', // 欠返
  'co_receipt_f', // 回单费
  'co_upstairs_f', // 上楼费
  'declared_value', // 订单货款 = 声明价值
  'co_insurance', // 保价费
  'co_trans_f', // 中转费
  'co_pkg_f', // 包装费
  'co_in_wh_f', // 进仓费
  'co_delivery_adv', // 垫付货款
  'co_storage_f', // 保管费
  'co_make_f', // 制单费
  'co_declare_f', // 报关费
  'co_build_f', // 港建费
  'co_misc_f', // 其他费
  'co_arr_service_f', // 到付服务费
]);

export const outerPriceKeys = {
  ...customerPriceKeys,
  ...outerTransPriceKeys,
  ...pointTransPriceKeys,
  ...budgetPriceKeys,
  ...newlyKeys,
};

export const costPriceKeys = ['tax', 'pickup_f', 'od_loc_tr_f', 'od_loc_misc_f'];

export const pointTransInfoPriceKeys = ['dn_com_id', 'trans_w_detail', 'trans_v_detail'];
export const transInfoPriceKeys = ['dn_com_id', 'address', 'trans_w_detail', 'trans_v_detail'];

export const initCostInfo = {
  cost_co_delivery_f: 0,
  cost_insurance_f: 0,
  cost_manage_f: 0,
  cost_message_f: 0,
  cost_operate_f: 0,
  cost_order_f: 0,
  cost_pay_arrival_fee: 0,
  cost_pay_co_delivery_fee: 0,
  cost_receipt_f: 0,
  cost_return_charge: 0,
  cost_tag_f: 0,
  cost_trans_f: 0,
  cost_control_goods_f: 0,
};

export const initStdCost = {
  std_delivery_f: 0,
  std_f: 0,
  std_inner_trans_f: 0,
  // std_mn_f: 0,
  std_outer_trans_f: 0,
  std_pickup_f: 0,
  std_trans_f: 0,
};

export const goodsRowParams = [
  'weight',
  'volume',
  'suit',
  'num',
  'name',
  'pkg',
  'cat',
  'special',
  'practical_w',
  'pkg_service',
  'unit_p', // 切换单价 触发价格计算
  'unit_p_unit', // 切换单价单位 触发价格计算
  'subtotal_price',
];

const batchReplace = (str, separtor) =>
  str
    .split(separtor)
    .map(t => ot(t))
    .join(separtor);

export const totalCostTip = (
  <span>
    支出合计=
    {batchReplace('现付运输费+现付油卡费+到付运输费+回付运输费+发站装车费+到站卸车费+整车保险费+税费+落地费', '+')}
    <br />+
    {batchReplace(
      '发站其他费+到站其他费+单票提货费+提货费+单票短驳费+单票送货费+送货费+发站单票装车费+发站单票其他费',
      '+',
    )}
    <br />+
    {batchReplace(
      '到站单票卸车费+到站单票其他费+发货客户维护费+收货客户维护费+异动减款+罚款+挂车使用费+中转费+整车落地费',
      '+',
    )}
    -{batchReplace('异动增款-信息费', '-')}
    <br />
    （收发货客户维护费只会计入开单网点的支出合计里）
  </span>
);

export const transInfoDisabledKeys = [
  'trans_n',
  'trans_w',
  'trans_v',
  'trans_std_freight_f',
  'trans_std_delivery_f',
  'trans_std_handling_f',
  'trans_std_pickup_f',
  'trans_std_f',
];

export const calcPriceFee = {
  co_delivery_f: 1,
  co_freight_f: 1,
  co_handling_f: 1,
  co_install_f: 1,
  co_pickup_f: 1,
  co_pay_adv: 1,
  co_in_wh_f: 1,
  co_pkg_f: 1,
  co_trans_f: 1,
  co_insurance: 1,
  declared_value: 1,
  co_receipt_f: 1,
  discount: 1,
  cashreturn: 1,
  co_storage_f: 1,
  co_make_f: 1,
  co_declare_f: 1,
  co_build_f: 1,
  co_arr_service_f: 1,
  co_misc_f: 1,
  back_f: 1,
};

export const outerTransCalcPriceFee = {
  trans_freight_f: 1,
  trans_pickup_f: 1,
  trans_delivery_f: 1,
  trans_handling_f: 1,
};

export const tableCalcPriceFee = {
  pickup_f: 1,
  budget_trans_f: 1,
  budget_delivery_f: 1,
  ...outerTransCalcPriceFee,
};
