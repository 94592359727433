/**
 * Created by wangnaihe on 2017/6/19.
 */

import { FETCH_ORDER_DATA, RESTORE_ORDER_DATA, SET_ORDER_DATA, SET_SUG_DATA, MERGE_DATA } from './constant';

import adaptor from './adaptor';

const reducers = {
  [FETCH_ORDER_DATA]: (state, payload) => adaptor(payload),
  [RESTORE_ORDER_DATA]: (state, payload) => payload,
  [SET_ORDER_DATA]: (state, payload) => ({ ...state, [payload.key]: payload.val, noCalPrice: payload.noCalPrice }),
  [MERGE_DATA]: (state, payload) => ({ ...state, ...payload }),
  [SET_SUG_DATA]: (state, payload) => ({ ...state, [`${payload.key}Enum`]: payload.data }),
};

const reducer = (state, action) => (reducers[action.type] ? reducers[action.type](state, action.payload) : state);

export default reducer;
