import React, { Fragment } from 'react';
import classnames from 'classnames';
import {
  Select,
  DataList,
  PureRadio as Radio,
  PureInput as Input,
  PureCheckbox as Checkbox,
  Icon,
  DateTimePicker,
} from 'components';
import { ot } from 'utils';
import { NUMBER, FLOAT } from 'constants';
import PriceSnapshotIcon from 'components/commoncomponents/PriceSnapshotIcon';
import { required, lockShow, validateReceiptN } from '../tool';
import { prefixCls } from './index.scss';

const normalHeader = ['value'];

// eslint-disable-next-line react/prop-types
const AutoWrapLabel = ({ text, className, children, ...rest }) => {
  if (text.length > 8) {
    const len = Math.ceil(text.length / 2);
    return (
      <label className={classnames({ [className]: className, 'label--auto-wrap': true })} {...rest}>
        {children}
        <span>
          <span>{text.slice(0, len)}</span>
          <span>{text.slice(len)}</span>
        </span>
      </label>
    );
  }
  return (
    <label className={className} {...rest}>
      {children}
      {text}
    </label>
  );
};

function renderExtra(page) {
  const onSelectMultiDataList = (key, value) => page.set(key, DataList.formatMultipleValue(value, 'value'));

  return function () {
    const { store } = page;
    const state = store.getState();
    const { valueAddedServiceHidden, isDetail, useJAVAPrice, priceSnapshot, priceSnapshotDelete, odLinkId } = state;
    const { pickup, pickupEditable, pickupShow } = state;
    const {
      deliveryMode,
      deliveryModeRequired,
      deliveryModeEditable,
      deliveryModeEnum,
      deliveryModeEnumNoSelfPick,
      deliveryShortcuts,
    } = state;
    const { serviceType, serviceTypeShow, serviceTypeRequired, serviceTypeEditable, serviceTypeEnum } = state;
    const { urgency, urgencyShow, urgencyRequired, urgencyEditable, urgencyEnum } = state;
    const { needInsured, needInsuredShow, needInsuredEditable } = state;
    const { declaredValue, declaredValueShow, declaredValueRequired, declaredValueEditable } = state;
    const { valueAddService, valueAddServiceEditable, valueAddServiceShow, valueAddServiceEnum } = state;
    const { noticeDelivery, noticeDeliveryEditable, noticeDeliveryShow } = state;
    const { needDispatch, needDispatchShow, needDispatchEditable } = state;
    const { hxCode, hxCodeShow, hxCodeEditable } = state;
    const { insured, insuredShow, insuredEditable, isLongKun } = state;
    const { receiptCat, receiptCatShow, receiptCatRequired, receiptCatEditable, receiptCatEnum } = state;
    const { receiptN, receiptNRequired, receiptNEditable } = state;
    const { receiptNum, receiptNumShow, receiptNumRequired, receiptNumEditable } = state;
    const { expectedReleaseTime, expectedReleaseTimeShow, expectedReleaseTimeRequired, expectedReleaseTimeEditable } =
      state;
    const {
      receiptRequire,
      receiptRequireShow,
      receiptRequireLock,
      receiptRequireRequired,
      receiptRequireEditable,
      receiptRequireEnum,
    } = state;

    const { ext } = state;
    const hiddenKeys = Object.keys(ext.perm_hide || {});

    const { set } = page;

    const isSelfPick = deliveryMode === 'self_pick';

    const onValueAddServiceCheck = (key, checked) => {
      const valueAddServiceNew = [...(valueAddService || [])];
      if (checked) {
        valueAddServiceNew.push(key);
      } else {
        const index = valueAddServiceNew.indexOf(key);
        index > -1 && valueAddServiceNew.splice(index, 1);
      }
      page.set('valueAddService', valueAddServiceNew);
    };

    return (
      <div className={`${prefixCls} order-card ${valueAddedServiceHidden ? ' hidden' : ''}`}>
        <h3>
          <Icon
            iconType="icon-carry-out"
            onClick={() => page.merge('valueAddedServiceHidden', !valueAddedServiceHidden)}
          />
          增值服务
        </h3>
        <div>
          <div className={`${prefixCls}__left`}>
            {pickupShow && (
              <label className="fn-checkbox-label">
                <Checkbox
                  data-path="pickup"
                  checked={pickup}
                  disabled={!pickupEditable}
                  onChange={e => set('pickup', e.target.checked)}
                />
                {ot('上门提货')}
              </label>
            )}
            <div className="delivery-mode">
              <label className="fn-radio-label self-pick">
                <Radio
                  checked={isSelfPick}
                  disabled={!deliveryModeEditable}
                  onClick={() => page.set('deliveryMode', 'self_pick')}
                />
                自提
              </label>
              <Radio
                checked={!isSelfPick}
                disabled={!deliveryModeEditable}
                onClick={() =>
                  isSelfPick &&
                  page.set('deliveryMode', deliveryModeEnumNoSelfPick[0] && deliveryModeEnumNoSelfPick[0].key)
                }
              />
              <Select
                data-path="delivery_mode"
                value={isSelfPick ? deliveryModeEnumNoSelfPick[0] && deliveryModeEnumNoSelfPick[0].key : deliveryMode}
                required={deliveryModeRequired}
                data={isDetail ? deliveryModeEnum : deliveryModeEnumNoSelfPick}
                disabled={!deliveryModeEditable || isSelfPick}
                filter="value"
                onChange={val => set('deliveryMode', val)}
                shortcuts={deliveryShortcuts}
              />
            </div>
            {serviceTypeShow && (
              <div className="flex-field">
                <label className={`fn-label${required(serviceTypeRequired)}`}>{ot('服务类型')}</label>
                <Select
                  data-path="service_type"
                  required={serviceTypeRequired}
                  value={serviceType}
                  data={serviceTypeEnum}
                  disabled={!serviceTypeEditable}
                  filter={false}
                  onChange={val => set('serviceType', val)}
                />
              </div>
            )}
          </div>
          <div className={`${prefixCls}__middle`}>
            {urgencyShow && (
              <div className="urgency">
                <label className={`fn-label${required(urgencyRequired)}`}>{ot('紧急度')}</label>
                {urgencyEnum.map(item => (
                  <label className="fn-radio-label">
                    <Radio
                      checked={urgency === item.key}
                      disabled={!urgencyEditable}
                      onClick={() => page.set('urgency', item.key)}
                    />
                    {item.name}
                  </label>
                ))}
              </div>
            )}

            {(needInsuredShow || declaredValueShow) && (
              <div className="declared-value">
                {needInsuredShow && (
                  <label className="fn-checkbox-label">
                    <Checkbox
                      data-path="need_insured"
                      checked={+needInsured === 1}
                      disabled={!needInsuredEditable}
                      onChange={e => set('needInsured', e.target.checked ? 1 : 2)}
                    />
                    {ot('需要保价')} :
                  </label>
                )}
                {declaredValueShow && (
                  <Input
                    data-path="declared_value"
                    value={declaredValue}
                    required={declaredValueRequired}
                    disabled={!declaredValueEditable}
                    onChange={e => set('declaredValue', e.target.value)}
                    pattern={FLOAT}
                    placeholder={ot('声明价值')}
                  >
                    {!!(
                      declaredValue &&
                      useJAVAPrice &&
                      (priceSnapshot.declared_value || !priceSnapshotDelete.declared_value)
                    ) && (
                      <PriceSnapshotIcon
                        className="input-icon"
                        data={priceSnapshot.declared_value}
                        fee="declared_value"
                        type="yd"
                        bizID={odLinkId}
                        isHidden={hiddenKeys.includes('declared_value')}
                      />
                    )}
                  </Input>
                )}
              </div>
            )}

            {valueAddServiceShow && (
              <Fragment>
                {valueAddServiceEnum.map(item => (
                  <AutoWrapLabel className="fn-checkbox-label" text={item.value}>
                    <Checkbox
                      checked={(valueAddService || []).includes(item.key)}
                      disabled={!valueAddServiceEditable}
                      onChange={e => onValueAddServiceCheck(item.key, e.target.checked)}
                    />
                  </AutoWrapLabel>
                ))}
              </Fragment>
            )}

            {noticeDeliveryShow && (
              <label className="fn-checkbox-label">
                <Checkbox
                  data-path="notice_delivery"
                  checked={noticeDelivery}
                  disabled={!noticeDeliveryEditable}
                  onChange={e => set('noticeDelivery', e.target.checked)}
                />
                {ot('等通知放货')}
              </label>
            )}
            {needDispatchShow && (
              <label className="fn-checkbox-label">
                <Checkbox
                  data-path="need_dispatch"
                  checked={+needDispatch === 1}
                  disabled={!needDispatchEditable}
                  onChange={e => set('needDispatch', e.target.checked ? 1 : 2)}
                />
                {ot('需要配送安装')}
              </label>
            )}
            {hxCodeShow && (
              <label className="fn-checkbox-label">
                <Checkbox
                  data-path="hx_code"
                  checked={+hxCode === 1}
                  disabled={!hxCodeEditable}
                  onChange={e => set('hxCode', e.target.checked ? 1 : 0)}
                />
                {ot('核销码签收')}
              </label>
            )}
            {insuredShow && !isLongKun && (
              <label className="fn-checkbox-label">
                <Checkbox
                  data-path="insured"
                  checked={insured}
                  disaabled={!insuredEditable}
                  onChange={e => set('insured', e.target.checked ? 1 : 0)}
                />
                {ot('给运单投保')}
              </label>
            )}
          </div>
          <div className={`${prefixCls}__right`}>
            {receiptCatShow && (
              <div className="flex-field">
                <Select
                  data-path="receipt_cat"
                  className="receipt-cat"
                  required={receiptCatRequired}
                  value={receiptCat}
                  data={receiptCatEnum}
                  disabled={!receiptCatEditable}
                  header={normalHeader}
                  format={lockShow}
                  filter="value"
                  placeholder="回单类型"
                  onChange={val => set('receiptCat', val)}
                />
                <Input
                  data-path="receipt_n"
                  required={receiptNRequired}
                  pattern={NUMBER}
                  customValidity={receiptNRequired ? validateReceiptN : undefined}
                  value={receiptN}
                  disabled={!receiptNEditable}
                  onChange={e => set('receiptN', e.target.value)}
                >
                  <span className="input-icon unit">份</span>
                </Input>
              </div>
            )}

            {receiptNumShow && (
              <div className="flex-field">
                <label className={`fn-label${required(receiptNumRequired)}`}>{ot('回单号')}</label>
                <Input
                  data-path="receipt_num"
                  value={receiptNum}
                  required={receiptNumRequired}
                  disabled={!receiptNumEditable}
                  onChange={e => set('receiptNum', e.target.value)}
                />
              </div>
            )}

            {expectedReleaseTimeShow && (
              <div className="flex-field">
                <label className={`fn-label${required(expectedReleaseTimeRequired)}`}>{ot('预计发放')}</label>
                <DateTimePicker
                  defaultValue={expectedReleaseTime}
                  disabled={!expectedReleaseTimeEditable}
                  onChange={val => set('expectedReleaseTime', val)}
                />
              </div>
            )}

            {receiptRequireShow && (
              <div className="flex-field">
                <label className={`fn-label${required(receiptRequireRequired)}`}>{ot('回单要求')}</label>
                {receiptRequireLock && (
                  <Select
                    data-path="receipt_require"
                    multiple
                    value={receiptRequire}
                    required={receiptRequireRequired}
                    data={receiptRequireEnum}
                    disabled={!receiptRequireEditable}
                    header={normalHeader}
                    format={lockShow}
                    filter="value"
                    onChange={val => set('receiptRequire', val)}
                  />
                )}
                {!receiptRequireLock && (
                  <DataList
                    data-path="receipt_require"
                    multiple
                    value={receiptRequire}
                    required={receiptRequireRequired}
                    data={receiptRequireEnum}
                    disabled={!receiptRequireEditable}
                    header={normalHeader}
                    filter={false}
                    format="value"
                    onChange={val => set('receiptRequire', val)}
                    onSelect={data => onSelectMultiDataList('receiptRequire', data)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
}

export default renderExtra;
