exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".print-error .dialog_detail{padding:33px 5px 0 12px}.print-error .dialog_detail .notice{top:-3px}.print-error .dialog_detail .notice_list{position:relative}", ""]);

// exports
exports.locals = {
	"print-error": "print-error",
	"dialog_detail": "dialog_detail",
	"notice": "notice",
	"notice_list": "notice_list"
};