import CytUpgradeGuider from 'utils/cytUpgradeGuider';
import React from 'react';

const CDN_PATH = 'https://static.g7cdn.com/fe-cdn/cyt/commonOp';
// 订单运单引导内容 name 和 图片一致
const orderBatchBaseGuideOptions = {
  name: '订单运单字段升级',
  guideImage: 'get_deluxe.png',
};
export const tabKeyForUpgradeGuideOptions = {
  od_all: {
    ...orderBatchBaseGuideOptions,
    place: '订单管理-订单列表-全部订单',
    eventList: ['deluxe'],
  },
  od_waiting: {
    ...orderBatchBaseGuideOptions,
    place: '订单管理-订单列表-待配载',
    eventList: ['deluxe'],
  },
  od_pickuping: {
    ...orderBatchBaseGuideOptions,
    place: '订单管理-订单列表-提货中',
    eventList: ['deluxe'],
  },
  od_pickuped: {
    ...orderBatchBaseGuideOptions,
    place: '订单管理-订单列表-已提货',
    eventList: ['deluxe'],
  },
  od_deliverying: {
    ...orderBatchBaseGuideOptions,
    place: '订单管理-订单列表-送货中',
    eventList: ['deluxe'],
  },
  od_deliveryed: {
    ...orderBatchBaseGuideOptions,
    place: '订单管理-订单列表-已送货',
    eventList: ['deluxe'],
  },
  od_tr_transport: {
    ...orderBatchBaseGuideOptions,
    place: '订单管理-订单列表-干线运输中',
    eventList: ['deluxe'],
  },
  od_tr_transport_complete: {
    ...orderBatchBaseGuideOptions,
    place: '订单管理-订单列表-干线运输完成',
    eventList: ['deluxe'],
  },
  receipt_total: {
    ...orderBatchBaseGuideOptions,
    place: '订单管理-订单回单-回单总表',
    eventList: ['deluxe'],
  },
  rcp_receipt: {
    ...orderBatchBaseGuideOptions,
    place: '订单管理-订单回单-回单回收',
    eventList: ['deluxe'],
  },
  rcp_grant: {
    ...orderBatchBaseGuideOptions,
    place: '订单管理-订单回单-回单发放',
    eventList: ['deluxe'],
  },
  transport_task_batch: {
    ...orderBatchBaseGuideOptions,
    place: '运单管理-运单列表-全部',
    eventList: ['deluxe'],
  },
  transport_task_batch_done: {
    ...orderBatchBaseGuideOptions,
    place: '运单管理-运单列表-已完成',
    eventList: ['deluxe'],
  },
  transport_task_discard: {
    ...orderBatchBaseGuideOptions,
    place: '运单管理-运单列表-作废记录',
    eventList: ['deluxe'],
  },
  audit_receipt_img: {
    ...orderBatchBaseGuideOptions,
    place: '运单管理-运单回单-全部',
    eventList: ['deluxe'],
  },
  audit_receipt_img_todo: {
    ...orderBatchBaseGuideOptions,
    place: '运单管理-运单回单-待审核',
    eventList: ['deluxe'],
  },
  audit_receipt_img_done_pass: {
    ...orderBatchBaseGuideOptions,
    place: '运单管理-运单回单-已通过',
    eventList: ['deluxe'],
  },
  audit_receipt_img_done_reject: {
    ...orderBatchBaseGuideOptions,
    place: '运单管理-运单回单-已驳回',
    eventList: ['deluxe'],
  },
  batch_electronic_contract: {
    ...orderBatchBaseGuideOptions,
    place: '运单管理-电子合同',
    eventList: ['deluxe'],
  },
  waybill_trace: {
    ...orderBatchBaseGuideOptions,
    place: '在途管理-在途跟踪',
    eventList: ['deluxe'],
  },
  all_pay_mgr: {
    ...orderBatchBaseGuideOptions,
    place: '网络货运-网货运单-全部',
    eventList: ['deluxe'],
  },
  await_audit_pay_mgr: {
    ...orderBatchBaseGuideOptions,
    place: '网络货运-网货运单-待认证',
    eventList: ['deluxe'],
  },
  await_settle_pay_mgr: {
    ...orderBatchBaseGuideOptions,
    place: '网络货运-网货运单-待支付',
    eventList: ['deluxe'],
  },
  settle_pay_mgr: {
    ...orderBatchBaseGuideOptions,
    place: '网络货运-网货运单-已结清',
    eventList: ['deluxe'],
  },
  will_expire_pay_mgr: {
    ...orderBatchBaseGuideOptions,
    place: '网络货运-网货运单-认证即将过期',
    eventList: ['deluxe'],
  },
  has_expired_pay_mgr: {
    ...orderBatchBaseGuideOptions,
    place: '网络货运-网货运单-认证已过期',
    eventList: ['deluxe'],
  },
  all_invoice_mgr: {
    ...orderBatchBaseGuideOptions,
    place: '网络货运-开票管理-申请开票',
    eventList: ['deluxe'],
  },
};

export const headerFuncOpRowEvent = {
  deluxe: popInfo => {
    return {
      handleClick: () => {
        return new Promise(resolve => {
          CytUpgradeGuider({
            ...popInfo,
            type: 'popup',
            show: true,
          });
          resolve({
            // 多个标签的情况下是否阻断其他事件执行
            shouldExecuteOther: true,
            // 是否阻止默认点击事件执行【原有的公共事件】
            shouldPreventCommonClick: true,
          });
        });
      },
      renderElement: () => {
        return (
          <span
            style={{
              position: 'absolute',
              right: '0',
              color: '#CE5B14',
              backgroundColor: '#FFF5E8',
              width: '48px',
              height: '20px',
              borderRadius: '99px',
              textAlign: 'center',
              lineHeight: '20px',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            豪华版
          </span>
        );
      },
    };
  },
  freezeImg: () => {
    return {
      handleClick: () => {
        return new Promise(resolve => {
          resolve({
            // 多个标签的情况下是否阻断其他事件执行
            shouldExecuteOther: false,
            // 是否阻止默认点击事件执行【原有的公共事件】
            shouldPreventCommonClick: false,
          });
        });
      },
      renderElement: item => {
        const isRight = item?.data?.ext?.sticky_right;
        const isLeft = item?.data?.ext?.sticky;
        let imagPath = '';
        if (isRight) {
          imagPath = `${CDN_PATH}/right.svg`;
        }
        if (isLeft) {
          imagPath = `${CDN_PATH}/left.svg`;
        }
        return (
          <div
            style={{
              position: 'absolute',
              right: '22px',
              width: '16px',
              height: '16px',
              textAlign: 'center',
              lineHeight: '16px',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <img src={imagPath} alt="" />
          </div>
        );
      },
    };
  },
};
