/**
 * Created by wangnaihe on 2017/6/19.
 */
import React from 'react';
import { PureInput as Input, PureCheckbox as Checkbox, Select } from 'components';
import { FLOAT, WARN } from 'constants';
import { ot, confirm } from 'utils';
import { paidTip, normalHeader } from '../../constant';
import { required, lockShow } from '../../tool';
import { prefixCls } from './index.scss';

function renderPayTo(page) {
  return function () {
    const { store } = page;
    const state = store.getState();
    const { totalPrice, keyMap } = state;
    const { paymentMode, paymentModeShow, paymentModeRequired, paymentModeEditable, paymentModeEnum } = state;
    const { payMode, payModeRequired, payModeEditable, payModeEnum, payModeShortcuts } = state;
    const { payBilling, payBillingShow, payBillingRequired, payBillingEditable } = state;
    const { payBillingPaid, payBillingPaidShow, payBillingPaidEditable } = state;
    const { payArrival, payArrivalShow, payArrivalRequired, payArrivalEditable } = state;
    const { payCredit, payCreditShow, payCreditRequired, payCreditEditable } = state;
    const { payMonthly, payMonthlyShow, payMonthlyRequired, payMonthlyEditable } = state;
    const { payReceipt, payReceiptShow, payReceiptRequired, payReceiptEditable } = state;
    const { payOwed, payOwedShow, payOwedRequired, payOwedEditable } = state;
    const { payCoDelivery, payCoDeliveryShow, payCoDeliveryRequired, payCoDeliveryEditable } = state;

    const payMulti = payMode === 'pay_multi';

    const { set } = page;

    const autoAllocate = (key, e) => {
      page.merge('lastEffectTotalPriceKey', key);
      if (!payMulti || !+totalPrice) return;
      const { target } = e;
      const val = target.value;
      const allocated = +payModeEnum.reduce((total, item) => total + (+state[keyMap[item.key]] || 0), 0).toFixed(2);
      allocated < totalPrice && set(key, +(totalPrice + (+val || 0) - allocated).toFixed(2));
      setTimeout(() => target.select(), 10);
    };

    const setPayBillingPaid = val => {
      if (val && state.scanReceipt) {
        confirm(WARN, '此费用项勾选收银复选框后此运单将不可扫码收款，是否确定勾选？').then(result => {
          if (result) {
            set('payBillingPaid', true);
            const { cashreturn, cashreturnPaid, cashreturnPaidShow } = state;
            const autoCollect = window.company_setting.auto_collect_v2.checked;
            if (
              !(
                +payArrival ||
                +payCoDelivery ||
                ((!cashreturnPaid || (!cashreturnPaidShow && !autoCollect)) && +cashreturn)
              )
            ) {
              set('scanReceipt', false);
            }
          }
        });
      } else {
        set('payBillingPaid', val);
      }
    };

    return (
      <div className={prefixCls}>
        <h3>
          <label className="fn-label">付款方式</label>
        </h3>
        {paymentModeShow && (
          <div>
            <label className={`fn-label${required(paymentModeRequired)}`}>{ot('支付方式')}</label>
            <Select
              data-path="payment_mode"
              format={lockShow}
              map="value"
              header={normalHeader}
              value={paymentMode}
              required={paymentModeRequired}
              data={paymentModeEnum}
              disabled={!paymentModeEditable}
              filter={false}
              onChange={val => set('paymentMode', val)}
            />
          </div>
        )}
        <div>
          <label className={`fn-label${required(payModeRequired)}`}>{ot('付款方式')}</label>
          <Select
            data-path="pay_mode"
            value={payMode}
            required={payModeRequired}
            data={payModeEnum}
            disabled={!payModeEditable}
            filter={false}
            shortcuts={payModeShortcuts}
            onChange={val => set('payMode', val)}
          />
        </div>
        {payBillingShow && (
          <div>
            <label className={`fn-label${required(payBillingRequired)}`}>{ot('现付')}</label>
            <Input
              data-path="pay_billing"
              value={payBilling}
              required={payBillingRequired}
              disabled={!payMulti || !payBillingEditable}
              onChange={e => set('payBilling', e.target.value)}
              onFocus={e => autoAllocate('payBilling', e)}
              pattern={FLOAT}
            >
              {payBillingPaidShow && (
                <Checkbox
                  data-path="pay_billing_paid"
                  checked={payBillingPaid}
                  disabled={(!payMulti && payMode !== 'pay_billing') || !payBillingPaidEditable}
                  onChange={e => setPayBillingPaid(e.target.checked)}
                  {...paidTip}
                />
              )}
            </Input>
          </div>
        )}
        {payArrivalShow && (
          <div>
            <label className={`fn-label${required(payArrivalRequired)}`}>{ot('到付')}</label>
            <Input
              data-path="pay_arrival"
              value={payArrival}
              required={payArrivalRequired}
              disabled={!payMulti || !payArrivalEditable}
              onChange={e => set('payArrival', e.target.value)}
              onFocus={e => autoAllocate('payArrival', e)}
              pattern={FLOAT}
            />
          </div>
        )}
        {payCreditShow && (
          <div>
            <label className={`fn-label${required(payCreditRequired)}`}>{ot('货到打卡')}</label>
            <Input
              data-path="pay_credit"
              value={payCredit}
              required={payCreditRequired}
              disabled={!payMulti || !payCreditEditable}
              onChange={e => set('payCredit', e.target.value)}
              onFocus={e => autoAllocate('payCredit', e)}
              pattern={FLOAT}
            />
          </div>
        )}
        {payMonthlyShow && (
          <div>
            <label className={`fn-label${required(payMonthlyRequired)}`}>{ot('月结')}</label>
            <Input
              data-path="pay_monthly"
              value={payMonthly}
              required={payMonthlyRequired}
              disabled={!payMulti || !payMonthlyEditable}
              onChange={e => set('payMonthly', e.target.value)}
              onFocus={e => autoAllocate('payMonthly', e)}
              pattern={FLOAT}
            />
          </div>
        )}
        {payReceiptShow && (
          <div>
            <label className={`fn-label${required(payReceiptRequired)}`}>{ot('回付')}</label>
            <Input
              data-path="pay_receipt"
              value={payReceipt}
              required={payReceiptRequired}
              disabled={!payMulti || !payReceiptEditable}
              onChange={e => set('payReceipt', e.target.value)}
              onFocus={e => autoAllocate('payReceipt', e)}
              pattern={FLOAT}
            />
          </div>
        )}
        {payCoDeliveryShow && (
          <div>
            <label className={`fn-label${required(payCoDeliveryRequired)}`}>{ot('货款扣')}</label>
            <Input
              data-path="pay_co_delivery"
              value={payCoDelivery}
              required={payCoDeliveryRequired}
              disabled={!payMulti || !payCoDeliveryEditable}
              onChange={e => set('payCoDelivery', e.target.value)}
              onFocus={e => autoAllocate('payCoDelivery', e)}
              pattern={FLOAT}
            />
          </div>
        )}
        {payOwedShow && (
          <div>
            <label className={`fn-label${required(payOwedRequired)}`}>{ot('欠付')}</label>
            <Input
              data-path="pay_owed"
              value={payOwed}
              required={payOwedRequired}
              disabled={!payMulti || !payOwedEditable}
              onChange={e => set('payOwed', e.target.value)}
              onFocus={e => autoAllocate('payOwed', e)}
              pattern={FLOAT}
            />
          </div>
        )}
      </div>
    );
  };
}

export default renderPayTo;
