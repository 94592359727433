import React from 'react';
import { ERROR } from 'constants';
import { PopUp } from 'components';
import { showInfo, calcTransPrice, fetchApi, typeIs, ot, viewImage } from 'utils';
import { shipRouteSug } from 'utils/sug/route';
import { OUTER_TRANS, POINT_TRANS, INNER_TRANS } from '../constant';
import { calcField, weightKG } from '../tool';
import {
  costPriceKeys,
  innerPriceKeys,
  outerPriceKeys,
  transInfoPriceKeys,
  customerPriceKeys,
  outerTransPriceKeys,
  pointTransPriceKeys,
  pointTransInfoPriceKeys,
  budgetPriceKeys,
  pickupPriceKeys,
  goodsRowParams,
  newlyKeys,
} from './constant';
import TransWeightVolumeEditModal from './transWeightVolumeEditModal';

const isGoodsChange = (key, action) =>
  key === 'goods' && (!action.changes || goodsRowParams.some(k => k in action.changes));

const isCostChange = (key, action) => key === 'pointCostInfo' && costPriceKeys.some(k => k in action.changes);

const isTaxChange = (key, action) => key === 'pointCostInfo' && 'tax' in action.changes;

const isOuterTransChange = (key, action, state) => {
  if (key === 'transCorPickDist' || key === 'transCeePickDist') {
    return state.transInfo && state.transInfo.filter(item => +item.trans_type === OUTER_TRANS).length;
  }
  const { changes } = action;
  if (key !== 'transInfo' || !changes) return false;
  if ('trans_type' in changes) return false;
  const row = state.transInfo[action.index];
  const transType = row && +row.trans_type;
  return transType === OUTER_TRANS && transInfoPriceKeys.some(k => k in changes);
};

const isPointTransChange = (key, action, state) => {
  if (key === 'pointTransCorPickDist' || key === 'ceePickDist') return true;
  const { changes } = action;
  if (key !== 'transInfo' || !changes) return false;
  const row = changes.trans_type ? changes : state.transInfo[action.index];
  const transType = row && +row.trans_type;
  return transType === POINT_TRANS && pointTransInfoPriceKeys.some(k => k in changes);
};

export const needGetInnerPrice = (key, action) =>
  innerPriceKeys[key] || isGoodsChange(key, action) || isCostChange(key, action);

export const needGetOuterPrice = (key, action, state) => {
  const { sendFlag, transFlag, budgetFlag, pickupFlag, pointFlag } = state;

  if (newlyKeys[key]) return true;
  if (isGoodsChange(key, action)) return true;
  if (sendFlag && customerPriceKeys[key]) return true;
  if (pickupFlag && (pickupPriceKeys[key] || isTaxChange(key, action))) return true;
  if (transFlag && (outerTransPriceKeys[key] || isCostChange(key, action) || isOuterTransChange(key, action, state)))
    return true;
  if (pointFlag && (pointTransPriceKeys[key] || isCostChange(key, action) || isPointTransChange(key, action, state)))
    return true;
  return budgetFlag && (budgetPriceKeys[key] || isCostChange(key, action));
};

const parseGoods = state => {
  const { goods, weightUnit, keyMap } = state;
  const convertWeightToKg = weightUnit === '吨';
  return goods.map(item => {
    const newItem = goodsRowParams.reduce(
      (allSet, key) => ({ ...allSet, [key]: state[`${keyMap[key]}Show`] ? item[key] || '' : '' }),
      {},
    );
    newItem.weight = +newItem.weight;
    newItem.practical_w = +newItem.practical_w;
    convertWeightToKg && newItem.practical_w && (newItem.practical_w *= 1000);
    convertWeightToKg && newItem.weight && (newItem.weight *= 1000);
    convertWeightToKg && newItem.unit_p_unit === 'per_w' && newItem.unit_p && (newItem.unit_p /= 1000);
    newItem.num = +newItem.num || 0;
    newItem.suit = +newItem.suit || 0;
    newItem.volume = +newItem.volume || 0;
    return newItem;
  });
};

export const getPointTransCompanyID = ({ route: nodes } = {}) => {
  if (!nodes || nodes.length < 2) return [0, 0];
  const withToPointIndex = nodes.findIndex(
    (node, i) => +node.node_type_ex === 1 && nodes[i + 1] && nodes[i + 1].node_type_ex === 1,
  );
  if (withToPointIndex > -1) return [nodes[withToPointIndex].company_id, nodes[withToPointIndex + 1].company_id];
  const distributeIndex = nodes.findIndex((node, i) => i > 0 && +node.org_category === 2 && +node.node_type_ex === 2);
  if (distributeIndex > -1) return [nodes[distributeIndex - 1].company_id, nodes[distributeIndex].company_id];
  const shedIndex = nodes.findIndex(node => +node.to_transit_shed);
  if (shedIndex > 0 && nodes[shedIndex + 1]) return [nodes[shedIndex].company_id, nodes[shedIndex + 1].company_id];
  const inProvincePointIndex = nodes.findIndex(
    (node, i) =>
      +node.org_category === 1 &&
      +node.node_type_ex === 1 &&
      nodes[i + 1] &&
      +nodes[i + 1].node_type_ex === 6 &&
      +nodes[i + 1].org_category === 1,
  );
  if (inProvincePointIndex > -1)
    return [nodes[inProvincePointIndex].company_id, nodes[inProvincePointIndex + 1].company_id];
  const inProvinceDistributeIndex = nodes.findIndex(
    (node, i) =>
      +node.org_category === 1 &&
      +node.node_type_ex === 2 &&
      nodes[i + 1] &&
      [1, 2].includes(+nodes[i + 1].org_category) &&
      +nodes[i + 1].node_type_ex === 2,
  );
  if (inProvinceDistributeIndex > -1)
    return [nodes[inProvinceDistributeIndex].company_id, nodes[inProvinceDistributeIndex + 1].company_id];
  const inProvinceLineIndex = nodes.findIndex(
    (node, i) =>
      +node.org_category === 1 &&
      +node.node_type_ex === 6 &&
      nodes[i + 1] &&
      +nodes[i + 1].org_category === 1 &&
      [1, 6].includes(+nodes[i + 1].node_type_ex),
  );
  if (inProvinceLineIndex > -1)
    return [nodes[inProvinceLineIndex].company_id, nodes[inProvinceLineIndex + 1].company_id];
  return [0, 0];
};

export const getInnerPriceParams = state => {
  const req = Object.entries(innerPriceKeys).reduce((allSet, [dk, pk]) => ({ ...allSet, [pk]: state[dk] }), {});
  const pointCost = (state.pointCostInfo && state.pointCostInfo[0]) || {};
  req.company_id = state.cid;
  req.product_key = req.product_type ? req.product_type.key : 0;
  req.arr_info = req.arr_info && req.arr_info.addr ? req.arr_info.addr : req.arr_info;
  req.co_dst_type = req.arr_point ? req.arr_point.company_type : '';
  req.arr_point = req.arr_point ? req.arr_point.company_id : '';
  req.start_point = req.start_point && req.start_point.id ? req.start_point.id : req.start_point;
  req.goods = parseGoods(state);
  req.weight = calcField(req.goods, 'weight');
  req.volume = calcField(req.goods, 'volume');
  req.num = calcField(req.goods, 'num');
  req.suit = calcField(req.goods, 'suit');
  req.route_id = req.route && req.route.route_id;
  req.route = req.route && req.route.route;
  req.point_cost_info = [{}];
  req.is_through = state.isThrough;
  req.old_is_through = state.oldIsThrough;
  const pointTrans = state.transInfo && state.transInfo.find(item => +item.trans_type === POINT_TRANS);
  const [up, down] = getPointTransCompanyID(state.route);
  req.point_trans_up_com_id = up;
  req.point_trans_dn_com_id = down;
  req.insured = +req.insured;
  if (pointTrans) {
    req.point_trans_dn_com_id = pointTrans.dn_com_id ? pointTrans.dn_com_id.id : 0;
  }

  delete req.product_type;
  costPriceKeys.forEach(k => (req.point_cost_info[0][k] = pointCost[k] || 0));
  return req;
};

export const getOuterPriceParams = state => {
  const {
    odBasicId,
    transInfo,
    pointCostInfo,
    goods,
    weightUnit,
    corId,
    ceeId,
    transCorPickDist,
    transCeePickDist,
    pointTransCorPickDist,
    ceePickDist,
    isModify,
    isUpdatePre,
  } = state;
  const outerTrans = transInfo && transInfo.find(item => +item.trans_type === OUTER_TRANS);
  const pointTrans = transInfo && transInfo.find(item => +item.trans_type === POINT_TRANS);
  const order = Object.entries(outerPriceKeys).reduce((allSet, [dk, pk]) => ({ ...allSet, [pk]: state[dk] }), {});
  const pointCost = (pointCostInfo && pointCostInfo[0]) || {};

  order.business_no = odBasicId;
  order.goods = parseGoods(state);
  order.weight = calcField(order.goods, 'weight');
  order.volume = calcField(order.goods, 'volume');
  order.num = calcField(order.goods, 'num');
  order.suit = calcField(order.goods, 'suit');
  order.cor_id = +corId || 0;
  order.cee_id = +ceeId || 0;
  order.cor_cate = !order.cor_cate && order.cor_cate !== 0 ? null : order.cor_cate;
  order.cee_cate = !order.cee_cate && order.cee_cate !== 0 ? null : order.cee_cate;
  order.product_key = order.product_type ? order.product_type.key : 0;
  order.product_line = order.product_line ? [order.product_line] : '';
  order.od_link_id = isModify || isUpdatePre ? 0 : state.odLinkId || 0;
  order.arr_info = order.arr_info && order.arr_info.addr ? order.arr_info.addr : order.arr_info;
  order.co_dst_type = order.arr_point ? order.arr_point.company_type : '';
  order.arr_point = order.arr_point ? order.arr_point.company_id : '';
  order.start_point = order.start_point && order.start_point.id ? order.start_point.id : order.start_point;
  order.actual_weight = +order.actual_weight || 0;
  order.actual_volume = +order.actual_volume || 0;
  order.need_insured = +order.need_insured === 1 ? '1' : '2';
  order.insured = +order.insured;
  order.co_receipt_f = +order.co_receipt_f || 0;
  order.co_declare_f = +order.co_declare_f || 0;
  order.co_build_f = +order.co_build_f || 0;
  // eslint-disable-next-line no-self-assign
  order.route_mile = order.route_mile;
  // order.g_pkg_service = order.g_pkg_service
  costPriceKeys.forEach(k => (order[k] = pointCost[k] || 0));
  delete order.product_type;

  if (outerTrans) {
    const weight = outerTrans.trans_w_detail || [];
    const volume = outerTrans.trans_v_detail || [];
    order.trans_cor_pick_dist = transCorPickDist || 0;
    order.trans_cee_pick_dist = transCeePickDist || 0;
    order.carrier_id = outerTrans.dn_com_id ? outerTrans.dn_com_id.id : 0;
    order.trans_start_info = order.start_info;
    order.trans_pickup_addr = order.start_info;
    order.trans_arr_info = (outerTrans.address && outerTrans.address.address) || order.arr_info || '';
    order.trans_cor_pick_dist = transCorPickDist || 0;
    order.trans_cee_pick_dist = transCeePickDist || 0;
    order.trans_goods = order.goods.map((item, i) => ({
      ...item,
      weight: weightKG(weight[i], weightUnit),
      volume: volume[i],
    }));
    order.trans_n = outerTrans.trans_n;
    order.trans_w = weightKG(outerTrans.trans_w, weightUnit);
    order.trans_v = outerTrans.trans_v;
  }

  const [up, down] = getPointTransCompanyID(state.route);
  order.point_trans_cor_pick_dist = pointTransCorPickDist || 0;
  order.point_trans_cee_pick_dist = ceePickDist || 0;
  order.point_trans_fee_ave_type = 'order';
  order.point_trans_up_com_id = up;
  order.point_trans_dn_com_id = down;
  order.point_trans_start = order.start_info;
  order.point_trans_arr = order.arr_info;
  order.point_trans_cor_pick_dist = pointTransCorPickDist || 0;
  order.point_trans_cee_pick_dist = ceePickDist || 0;
  order.point_trans_goods = order.goods;
  order.point_trans_n = pointTrans ? pointTrans.trans_n : order.num;
  order.point_trans_w = pointTrans ? weightKG(pointTrans.trans_w, weightUnit) : order.weight;
  order.point_trans_v = pointTrans ? pointTrans.trans_v : order.volume;
  order.customer_id = (order.obCustomerId || {}).id;
  order.project_id = (order.obProjectId || {}).id;
  delete order.obCustomerId;
  delete order.obProjectId;
  if (pointTrans) {
    const weight = pointTrans.trans_w_detail || [];
    const volume = pointTrans.trans_v_detail || [];
    order.point_trans_goods = order.goods.map((item, i) => ({
      ...item,
      weight: weightKG(weight[i], weightUnit),
      volume: volume[i],
    }));
    order.point_trans_dn_com_id = pointTrans.dn_com_id ? pointTrans.dn_com_id.id : 0;
  }

  return order;
};

export const getInnerPriceError = (reason, allowSave = true) => {
  const tip = `以下价格无法计算，请检查价格维护是否正确。${allowSave ? '是否要继续保存当前运单？' : ''}`;
  const detail = reason.map((item, i) => <p key={i}>{item.msg}</p>);
  return (
    <div className="inner-price-error-detail">
      {tip}
      {detail}
    </div>
  );
};

export const previewImg = item => {
  if (!item) return;
  viewImage({
    type: 'sign',
    path: item.path,
    name: item.name,
  });
};

export const getEnumText = (enums, val) => (enums[val] || { display: '' }).display;

export const getFeeKeys = (state, transType) => state.transFeeKeys[transType] || {};

export const getFeeList = (state, transType) => state.transFeeList[transType] || [];

export const calcFeeTotal = (state, row) =>
  getFeeList(state, row.trans_type).reduce((total, key) => total + (+row[key] || 0), 0);

export const calcPayTotal = (row, transPayList) =>
  +transPayList.reduce((total, key) => total + (+row[key] || 0), 0).toFixed(2);

export const calcTransFreight = row => {
  const num1 = +calcTransPrice(row.billing_method, row.trans_unit_price, {
    trans_w_detail:
      window.company_setting.weight_unit.value !== 'KG'
        ? (row.trans_w_detail || []).map(w => w * 1000)
        : row.trans_w_detail,
    trans_v_detail: row.trans_v_detail,
    g_n: row.trans_n,
  });
  const mul = (numKey, unitKey) => {
    const unit = +row[unitKey] || 0;
    let num = row[numKey] || 0;
    if (!typeIs(num, 'array')) {
      num = `${num}`.split('，');
    }
    num = num.reduce((a, b) => (+a || 0) + (+b || 0), 0);
    return unit * num;
  };
  const num2 = mul('trans_v_detail', 'trans_volume_unit_p');
  const num3 = mul('trans_w_detail', 'trans_weight_unit_p');
  const num4 = mul('trans_n', 'trans_num_unit_p');
  return +Math.max(num1, num2, num3, num4).toFixed(2);
};

export const feeRelateTransPay = (state, { trans_type: transType, trans_pay_mode: payMode, trans_f: transFee }) => {
  const relate = {};
  if (+transType === OUTER_TRANS) {
    if (payMode && payMode !== 'pay_free') {
      if (payMode !== 'pay_multi') {
        if (payMode === 'pay_arrival') {
          relate[`trans_${payMode}`] = state.payArrival;
        } else {
          relate[`trans_${payMode}`] = +(transFee || 0).toFixed(2);
        }
      }
    }
  } else {
    relate.trans_pay_arrival = state.payArrival;
    if (payMode && payMode !== 'pay_free' && payMode !== 'pay_multi' && payMode !== 'pay_arrival') {
      relate[`trans_${payMode}`] = +(transFee || 0).toFixed(2);
    }
  }
  return relate;
};
export const feeRelate = (state, row, transFee) => {
  const totalPrice = +state.totalPrice || 0;
  const { transPayList } = state;
  const feeKeys = getFeeKeys(state, row.trans_type);
  const transType = +row.trans_type;
  const relate = {
    trans_f: +transFee.toFixed(2),
    trans_profit: +(totalPrice - transFee).toFixed(2),
  };
  const payMode = row.trans_pay_mode;
  const transPay = feeRelateTransPay(state, {
    trans_type: transType,
    trans_f: relate.trans_f,
    trans_pay_mode: payMode,
  });
  Object.assign(relate, transPay);
  if (state.transInputRebate && +row.trans_pay_arrival && Object.keys(feeKeys).every(fee => !+row[fee])) {
    // 如果开启中转返款计算中转费，中转费清空 中中转返款也清空
    relate.trans_settle_money = 0;
  } else if (!+state.payArrival) {
    relate.trans_settle_money = '';
  } else if (transType === OUTER_TRANS) {
    relate.trans_settle_money = +(state.payArrival - transFee).toFixed(2);
  } else {
    relate.trans_settle_money = +(calcPayTotal({ ...row, ...relate }, transPayList) - transFee).toFixed(2);
  }
  return relate;
};

export const getTransPayModeEnum = (state, row) => {
  const transType = +row.trans_type;
  const dnCompany = row.dn_com_id;
  const { isModify, modifyTransPayModeEnum } = state;
  const payArrivalTransPayModeEnum = state.payArrivalTransPayModeEnum[transType] || [];
  let transPayModeEnum = state.transPayModeEnum[transType] || [];
  if (+state.payArrival) transPayModeEnum = payArrivalTransPayModeEnum;
  if (transType === OUTER_TRANS) {
    if (dnCompany && dnCompany.payModeEnum) transPayModeEnum = dnCompany.payModeEnum;
    if (isModify && modifyTransPayModeEnum[row.b_link_id]) transPayModeEnum = modifyTransPayModeEnum[row.b_link_id];
    if (+state.payArrival)
      transPayModeEnum = transPayModeEnum.filter(v1 => payArrivalTransPayModeEnum.find(v2 => v1.key === v2.key));
  }
  return transPayModeEnum;
};

export const transPayModeRelate = (state, i, val, row, tip = true) => {
  const payArrival = +state.payArrival;
  const transType = +row.trans_type;
  const newVal = { trans_pay_mode: val };
  const { transInputRebate, transPayList, runSetting } = state; // 是否可 输入中转返款计算中转费（系统设置/运营设置/中转设置）

  // 运单付款方式非到付 中装付款方式也不能为到付
  if ((transType === INNER_TRANS || transType === POINT_TRANS) && val === 'pay_arrival' && !payArrival) {
    newVal.trans_pay_mode = '';
    tip && showInfo(ERROR, `运单不含${ot('到付')}，请选择其他${ot('中转付款方式')}！`);
  }

  // 运单付款方式到付 中转付款方式只能为到付
  if (
    (transType === INNER_TRANS || transType === POINT_TRANS) &&
    val !== 'pay_arrival' &&
    val !== 'pay_multi' &&
    payArrival
  ) {
    newVal.trans_pay_mode = '';
    tip && showInfo(ERROR, `运单含${ot('到付')}，请选择${ot('到付')}或${ot('多笔付')}${ot('中转付款方式')}！`);
  }
  if (transType === OUTER_TRANS) {
    transPayList.forEach(key => (newVal[key] = ''));
    val !== 'pay_free' && val !== 'pay_multi' && val !== '' && (newVal[`trans_${val}`] = row.trans_f || '');
  } else {
    // 到付不可编辑 和外面的到付保持同步
    transPayList.forEach(key => key !== 'trans_pay_arrival' && (newVal[key] = ''));
    val !== 'pay_free' &&
      val !== 'pay_multi' &&
      val !== 'pay_arrival' &&
      val !== '' &&
      (newVal[`trans_${val}`] = row.trans_f || '');
  }
  const transFee = +row.trans_f || 0;
  // 开通中转返款计算中转费 中转费 & 中转返款 置空(有到付时)
  if (payArrival && transInputRebate && (!row.trans_settle_money || !row.trans_freight_f)) {
    newVal.trans_settle_money = '';
    newVal.trans_freight_f = '';
    newVal.trans_f = +calcFeeTotal(state, { ...row, ...newVal }).toFixed(2);
  } else if (payArrival) {
    if (transType === OUTER_TRANS) {
      newVal.trans_settle_money = +(state.payArrival - transFee).toFixed(2);
    } else {
      const payTotal = calcPayTotal({ ...row, ...newVal }, transPayList);
      newVal.trans_settle_money = +(payTotal - transFee).toFixed(2);
    }
  }
  // 当付款方式跟新为现付时中转合计费自动勾选（如果用户未手动勾选过）
  if (
    !payArrival &&
    (transType === OUTER_TRANS || transType === POINT_TRANS) &&
    newVal.trans_pay_mode === 'pay_billing' &&
    !row.trans_f_paid_handle_change
  ) {
    let defaultCheckedFlag;
    if (transType === OUTER_TRANS) {
      defaultCheckedFlag = runSetting.show_trans_f_paid.checked && runSetting.trans_pay_billing_def.checked;
    }
    if (transType === POINT_TRANS) {
      defaultCheckedFlag = runSetting.show_point_trans_f_paid.checked && runSetting.pt_pay_billing_def.checked;
    }
    if (defaultCheckedFlag) {
      newVal.trans_f_paid = 1;
    } else {
      newVal.trans_f_paid = 0;
    }
  } else if ((transType === OUTER_TRANS || transType === POINT_TRANS) && !row.trans_f_paid_handle_change) {
    newVal.trans_f_paid = 0;
  }
  const nextRow = { ...row, ...newVal };
  const transFeeRes = feeRelate(state, { ...row, ...newVal }, nextRow.trans_f || 0);
  Object.assign(newVal, transFeeRes);
  return newVal;
};

export const calcTax = (taxRate, totalPrice, isIncludeTax, maxRate) => {
  const rate = (+taxRate || 0) / 100;
  const actualVal = +totalPrice
    ? +(isIncludeTax ? (+totalPrice * rate) / (1 + rate) : +totalPrice * rate).toFixed(2)
    : 0;
  // eslint-disable-next-line no-nested-ternary
  return maxRate ? (+actualVal > +maxRate ? maxRate : actualVal) : actualVal;
};

// 中转类型的字段是否设置为必填
export const transFieldRequired = (field, state, row) => {
  const { transDeliveryMust, transFieldSetting, deliveryMode } = state;
  const transType = row.trans_type;
  if (!transType) return false;
  if (+transType === OUTER_TRANS && !row.dn_com_id) return false;
  if (+transType === POINT_TRANS && !row.point_trans_dn_com_id) return false;
  if (+transType === INNER_TRANS && !row.dn_mgr_id) return false;
  if (field === 'trans_delivery_f' && +row.trans_type === OUTER_TRANS) {
    return transDeliveryMust.includes(deliveryMode);
  }
  // 中转备注
  let checkField = field === 'order_trans_remark' ? 'remark' : field;
  // 付款方式为免费时，中转费非必填
  if (field === 'trans_freight_f' && row.trans_pay_mode === 'pay_free') {
    return false;
  }
  // 中转费
  checkField = field === 'trans_freight_f' && +transType === POINT_TRANS ? 'point_trans_freight_f' : checkField;
  checkField = field === 'trans_freight_f' && +transType === INNER_TRANS ? 'inner_trans_freight_f' : checkField;
  // 到站
  checkField = field === 'address' ? 'arrival' : checkField;
  const fieldSetting = transFieldSetting[transType];
  return fieldSetting[checkField] && fieldSetting[checkField].required;
};

export const editTransWeightVolume = data =>
  new Promise(resolve => {
    new PopUp(TransWeightVolumeEditModal, {
      popName: 'editTransWeight',
      data,
      onConfirm: newData => resolve(newData),
      onCancel: resolve,
      onClose: resolve,
    }).show();
  });

export const dnComRelate = val => {
  const newVal = { dn_com_id: val };

  newVal.trans_dn_carrier_phone = val.telephone;
  newVal.trans_dn_carrier_phone2 = '';
  newVal.trans_dn_carrier_cs_phone = val.service_phone;
  newVal.trans_dn_carrier_addr = val.address && JSON.parse(val.address).show_val;
  newVal.trans_dn_carrier_addr_remark = val.address_remark;
  newVal.trans_dn_carrier_type = '';
  newVal.address = '';
  newVal.trans_arr_address = '';
  newVal.trans_arr_address_remark = '';
  newVal.trans_arr_site = '';
  newVal.trans_arr_phone = '';
  newVal.trans_arr_cs_phone = '';
  newVal.trans_route = '';
  return newVal;
};

export const getTransRoute = (search, row, state, fetch) => {
  const { arrPoint, arrInfo } = state;
  const arr = arrInfo.addr ? arrInfo.addr : arrInfo;
  const conf = { start_pid: row.dn_com_id.id, arr_point: arrPoint && arrPoint.company_id, arr, search, fetch };
  return shipRouteSug(conf);
};

export const getDefaultTransRoute = (route = {}, sup, state) => {
  if (route.node2 && sup.find(item => +route.node2.id === +item.id)) {
    return fetchApi('/Basic/ShipRoute/defaultSug', {
      method: 'POST',
      body: {
        req: {
          start_pid: state.startPointId,
          route_id: route.route_id,
          route_nodes: route.route,
          mile: state.mile,
          route_time: state.routeTime,
          price_mode: state.priceMode,
          old_price_mode: state.oldPriceMode,
          is_through: state.isThrough || 0,
          route_nick: route.route_nick,
          dn_com_id: route.node2.id,
        },
      },
    }).then(res => (res.res.is_join ? res.res : undefined));
  }
  return new Promise(resolve => resolve());
};

export const getInnerRemark = page => {
  const { odBasicId } = page.store.getState();
  const req = {
    category: 'Log',
    tab: 'order_record',
    sort: {},
    page_num: 1,
    page_size: null,
    fetch_mode: 'body',
    query: { od_basic_id: odBasicId, type: 901 },
    pageSize: 1000,
    pageNum: 1,
    filter: {},
  };
  fetchApi('Table/Search/logList', { method: 'POST', body: { req } }).then(res =>
    page.merge('innerRemarkInfo', res.res.data),
  );
};

export const switchWeight = (weight = '', reverse = false) => {
  const newVal = reverse ? +(weight * 1000).toFixed(3) : +(weight / 1000).toFixed(6);
  return newVal || '';
};

export const isShowSnapshotIcon = (state, stateKey) => {
  const { priceSnapshot, keyMap, feeSource } = state;
  const key = keyMap[stateKey];

  // 新增时必须有快照信息才展示
  // 编辑时没有调用或者有快照才展示
  return !!(
    state[stateKey] &&
    feeSource[key] !== 1 &&
    (state.isCreate
      ? !!priceSnapshot[key]?.[0]?.price_cal
      : !priceSnapshot[key] || !!priceSnapshot[key]?.[0]?.price_cal)
  );
};
