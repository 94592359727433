/**
 * Created by wangnaihe on 2017/6/19.
 */
import React from 'react';
import {
  Select,
  DataList,
  PureInput as Input,
  Address,
  ButtonIcon,
  PureCheckbox as Checkbox,
  CheckBox,
} from 'components';
import { fetchApi as fetch, buildTipMixin, ot } from 'utils';
import { FLOAT, NUMBER, NUMBER_NO_ZERO, FLOAT_P_3, FLOAT_P_6, REGULAR } from 'constants';
import { startPointSug as utilStartPointSug, multiColPointHeader } from 'utils/sug/org';
import { SET_SUG_DATA, SELECT_COR_SUG, SELECT_CEE_SUG, mgrHeader, normalHeader } from '../constant';
import { required, lockShow } from '../tool';
import { calcInsurance } from '../fee/tool';
import { validateDelivery } from '../fee/collect/tool';
import { calcCommission } from '../public/calcCommission';
import { getMgr } from '../extra/tool';
import { formatSugData, sugCorCee } from '../person/tool';
import { getArrInfo } from '../traffic/tool';
import { prefixCls } from './index.scss';

const manualNumTip = buildTipMixin('临时手动单号，下次开单不自增！');

function renderPre(page) {
  const startPointSug = (keyword, select) => {
    utilStartPointSug({ search: keyword, fetch: select.fetch }).then(res => page.setSug('startPoint', res));
  };

  const onChangeStartPoint = val => {
    val && page.props?.onSaveSuccess?.('changeStartPoint', val);
  };

  return function () {
    const { store } = page;
    const state = store.getState();
    const isPre = page.props.usedFor === 'pre';
    const isTags = page.props.usedFor === 'tags';
    const { preCount, count, orderNum, orderNumRange = [], mapChoose, printItems, btnType } = state;
    const { manualNum, manualNumShow } = state;
    const { goodsNum, goodsNumShow, goodsNumRequired } = state;
    const { entrustNum, entrustNumShow, entrustNumRequired } = state;
    const { startPoint, startPointRequired, startPointEditable, startPointEnum } = state;
    const { startInfo, startInfoShow, startInfoRequired, startArrNeedPoi } = state;
    const { arrInfo, arrInfoShow, arrInfoRequired, arrOnlyRoute, arrInfoEnum, arrInfoHeader, arrInfoIsDataList } =
      state;
    const { route, routeShow, routeRequired } = state;
    const { corCom, corName, corHeader, corNameEnum, corMobile, corPhone, corAddrInfo, corAddrRemark, needCorAddress } =
      state;
    const { ceeCom, ceeName, ceeHeader, ceeNameEnum, ceeMobile, ceePhone, ceeAddrInfo, ceeAddrRemark, needCeeAddress } =
      state;
    const {
      corComRequired,
      corNameRequired,
      corMobileRequired,
      corPhoneRequired,
      corAddrInfoRequired,
      corAddressLock,
    } = state;
    const {
      ceeComRequired,
      ceeNameRequired,
      ceeMobileRequired,
      ceePhoneRequired,
      ceeAddrInfoRequired,
      ceeAddressLock,
    } = state;
    const {
      corComShow,
      corNameShow,
      corMobileShow,
      corPhoneShow,
      corAddrInfoShow,
      corAddrRemarkShow,
      corAddrRemarkRequired,
    } = state;
    const {
      ceeComShow,
      ceeNameShow,
      ceeMobileShow,
      ceePhoneShow,
      ceeAddrInfoShow,
      ceeAddrRemarkShow,
      ceeAddrRemarkRequired,
    } = state;
    const {
      nameShow,
      pkgShow,
      numShow,
      weightShow,
      weightUnit,
      volumeShow,
      unitPShow,
      unitEnum,
      catShow,
      specShow,
      modelShow,
    } = state;
    const {
      nameRequired,
      pkgRequired,
      numRequired,
      weightRequired,
      volumeRequired,
      unitPRequired,
      catRequired,
      specRequired,
      modelRequired,
    } = state;
    const {
      nameEnum,
      nameLock,
      pkgEnum,
      pkgLock,
      catEnum,
      catLock,
      specialEnum,
      specialShow,
      specialLock,
      specialRequired,
    } = state;
    const goods0 = state.goods[0];
    const { coFreightF, coFreightFShow, coFreightFRequired } = state;
    const { coDeliveryF, coDeliveryFShow, coDeliveryFRequired } = state;
    const { declaredValue, declaredValueShow, declaredValueRequired } = state;
    const { coInsurance, coInsuranceShow, coInsuranceRequired, insurSet, insurCalcIcon, maxDecimal } = state;
    const { coDelivery, coDeliveryShow, coDeliveryRequired, coDeliveryRatio, commissionSetting, freeDelivery } = state;
    const { coDeliveryFee, coDeliveryFeeShow, coDeliveryFeeRequired, maxCoDeliveryFee, coDeliveryFeeCalcIcon } = state;
    const { pointCostInfo, pickupFShow, pickupFRequired } = state;
    const { payMode, payModeShow, payModeRequired, payModeEnum } = state;
    const { pickup, pickupEditable, pickupShow } = state;
    const { deliveryMode, deliveryModeShow, deliveryModeRequired, deliveryModeEnum, deliveryShortcuts } = state;
    const { rebate, rebateShow, rebateRequired, rebateEditable } = state;
    const { cashreturn, cashreturnShow, cashreturnRequired } = state;
    const { discount, discountShow, discountRequired } = state;
    const { mgrId, mgrIdShow, mgrIdRequired, mgrIdEnum } = state;
    const { receiptCat, receiptN, receiptCatShow, receiptCatRequired, receiptCatEnum } = state;
    const { remark, remarkShow, remarkEnum, remarkLock, remarkRequired } = state;
    const { shortcutsDisplay } = state;

    const { set } = page;

    const { merge } = page;

    const setGoodsNum = val => {
      set('manualGNum', true);
      set('goodsNum', val.trim ? val.trim() : '');
    };

    const checkPrintItem = (i, checked) => page.setTable('printItems', i, { checked });

    const sugCeeName = (type, keyword, select) => {
      sugCorCee(false, type, keyword, state, select.fetch).then(res => page.setSug('ceeName', formatSugData(res.res)));
    };

    const sugCorName = (type, keyword, select) => {
      sugCorCee(true, type, keyword, state, select.fetch).then(res => page.setSug('corName', formatSugData(res.res)));
    };

    const arrInfoSug = (keyword, select) => {
      if (keyword && keyword.length < 2) return;
      getArrInfo(keyword, state, select.fetch).then(res =>
        store.dispatch({ type: SET_SUG_DATA, payload: { key: 'arrInfo', data: res.res } }),
      );
    };

    const sugMgr = (keyword, select) => getMgr(keyword, select.fetch, state).then(res => page.setSug('mgrId', res.res));

    const selectSug = (key, data) => {
      if (key === 'cor') store.dispatch({ type: SELECT_COR_SUG, payload: { key: 'corName', data } });
      else store.dispatch({ type: SELECT_CEE_SUG, payload: { key: 'ceeName', data } });
    };

    const getOrderNum = () => {
      const url = '/Order/Order/genOrderNum/';
      const req = { count };
      const conf = { method: 'POST', body: { req } };
      if (!count || count > 100 || count < 1) {
        set('orderNum', '');
        merge('orderNumRange', []);
        return;
      }
      if (orderNumRange[0] && page.edited('order_num_start')) {
        const start = Number.isNaN(+orderNumRange[0])
          ? (orderNumRange[0].match(/\w*[^\d](\d+)$/) || [])[1] || ''
          : orderNumRange[0];
        const end = start ? `${+start + (count - 1)}` : '';
        const prefix = orderNumRange[0].slice(0, orderNumRange[0].lastIndexOf(start));
        const orderNumRangeNew = [orderNumRange[0], `${prefix}${end.padStart(start.length, '0')}`];
        set('orderNum', orderNumRangeNew.join('-'));
        merge('orderNumRange', orderNumRangeNew);
        !start && set('count', 1);
      } else {
        fetch(url, conf).then(res => {
          set('orderNum', res.res.order_num_range.join('-'));
          merge('orderNumRange', res.res.order_num_range);
        });
      }
    };

    const setOrderNumStart = val => set('orderNumRange', [val, orderNumRange[1]]);

    const validateArrInfo = () => {
      const needSelect = startArrNeedPoi || arrOnlyRoute;
      return needSelect && arrInfo && arrInfo.show_val && !arrInfo.addr && !arrInfo.province ? '请从下拉选择' : '';
    };

    const validateCount = () => {
      const currCount = count || preCount;
      return !+currCount || +currCount > 100 || +currCount < 1 ? '1-100的整数' : '';
    };

    const setGoods = (key, val) => page.setTable('goods', 0, { [key]: val });

    const calcVolume = e => {
      try {
        const volume = eval(e.target.value); // eslint-disable-line
        !window.isNaN(volume) && setGoods('volume', +volume.toFixed(6));
      } catch (exception) {
        console.log(exception);
      }
    };

    const maxInsurance = val => (maxDecimal && +val > +maxDecimal ? `保价费上限${maxDecimal}元！` : '');

    const calcCoInsurance = () => {
      const coInsurEle = page.getEle('co_insurance');
      delete coInsurEle.dataset.isEdited;
      const calcInsurVal = calcInsurance({ declaredValue }, insurSet, maxDecimal, state.corInsuranceRatio);
      calcInsurVal !== coInsurance && merge('coInsurance', calcInsurVal);
    };

    const maxDeliveryFee = val =>
      maxCoDeliveryFee && +val > +maxCoDeliveryFee ? `手续费上限${maxCoDeliveryFee}元！` : '';

    const calcCoDeliverFee = () => {
      const coDeliverFeeEle = page.getEle('co_delivery_fee');
      delete coDeliverFeeEle.dataset.isEdited;
      const effectValues = { coDelivery, coDeliveryRatio, route };
      const newCoDeliverFee = calcCommission(effectValues, commissionSetting, freeDelivery, maxCoDeliveryFee);
      newCoDeliverFee !== coDeliveryFee && set('coDeliveryFee', newCoDeliverFee ? +newCoDeliverFee.toFixed(2) : '');
    };

    return (
      // eslint-disable-next-line react/no-this-in-sfc
      <div className={prefixCls} ref={this.refWrap}>
        <div className="pre-content">
          <div className="pre-content-inner">
            {isPre && (
              <div className="x2">
                <label className="fn-label r">开单数量</label>
                <Input
                  data-path="count"
                  required
                  value={count || preCount}
                  placeholder="请输入1-100的整数"
                  customValidity={validateCount}
                  onChange={e => set('count', e.target.value)}
                  onBlur={getOrderNum}
                />
                <span className="tips count-tip">* 每次最多可开100单</span>
              </div>
            )}
            {isPre && (
              <div className="x2">
                <label className="fn-label r">{ot('运单号')}</label>
                <Input
                  data-path="order_num_start"
                  required
                  className="order-num-start"
                  value={orderNumRange[0]}
                  pattern={REGULAR}
                  onChange={e => setOrderNumStart(e.target.value)}
                  onBlur={getOrderNum}
                />
                <span className="order-num-">-</span>
                <Input
                  data-path="order_num_end"
                  required
                  className="order-num-end"
                  value={orderNumRange[1]}
                  disabled
                  pattern={REGULAR}
                />
                <br />
                <span className="tips order-num-tip">
                  * 如需系统自动生成运单号，请去运单设置修改运单号生成规则为规则2或规则3
                </span>
              </div>
            )}
            {!isPre && (
              <div>
                <label className="fn-label r">{ot('运单号')}</label>
                <Input
                  data-path="order_num"
                  required
                  value={orderNum}
                  pattern={REGULAR}
                  onChange={e => set('orderNum', e.target.value)}
                >
                  {manualNumShow && (
                    <Checkbox
                      data-path="manual_num"
                      checked={manualNum}
                      onChange={e => set('manualNum', e.target.checked)}
                      {...manualNumTip}
                    />
                  )}
                </Input>
              </div>
            )}
            <div>
              <label className={`fn-label${required(startPointRequired)}`}>{ot('出发网点')}</label>
              <Select
                value={startPoint}
                data={startPointEnum}
                required
                disabled={!startPointEditable}
                compare="id"
                map={false}
                header={multiColPointHeader}
                format="short_name"
                filter={startPointSug}
                onChange={onChangeStartPoint}
              />
            </div>
            {!isPre && (
              <span className="tips order-num-tip">
                * 如需系统自动生成运单号，请去运单设置修改运单号生成规则为规则2或规则3
              </span>
            )}
            {goodsNumShow && isTags && (
              <div className={entrustNumShow ? undefined : 'x2'}>
                <label className={`fn-label${required(goodsNumRequired)}`}>{ot('货号')}</label>
                <Input
                  data-path="goods_num"
                  required={goodsNumRequired}
                  className="goods-num"
                  value={goodsNum}
                  onChange={e => setGoodsNum(e.target.value)}
                />
              </div>
            )}
            {entrustNumShow && (
              <div className={goodsNumShow && isTags ? undefined : 'x2'}>
                <label className={`fn-label${required(entrustNumRequired)}`}>{ot('委托单号')}</label>
                <Input
                  data-path="entrust_num"
                  required={entrustNumRequired}
                  className="entrust-num"
                  value={entrustNum}
                  onChange={e => set('entrustNum', e.target.value)}
                />
              </div>
            )}
            {startInfoShow && (
              <div className="start-info">
                <label className={`fn-label${required(startInfoRequired)}`}>{ot('发站')}</label>
                <Address
                  g7Map
                  data-path="start"
                  required={startInfoRequired}
                  needSelect={startArrNeedPoi}
                  mode="district"
                  mapIcon={false}
                  placeholder="请填写发站地址"
                  value={startInfo}
                  onChange={val => set('startInfo', val)}
                  onSelect={val => set('startInfo', val)}
                />
              </div>
            )}
            {arrInfoShow && arrInfoIsDataList && (
              <div className="start-info">
                <label className={`fn-label${required(arrInfoRequired)}`}>{ot('到站')}</label>
                <DataList
                  data-path="arr"
                  required={arrInfoRequired}
                  customValidity={validateArrInfo}
                  value={arrInfo && arrInfo.show_val}
                  data={arrInfoEnum}
                  header={arrInfoHeader}
                  onChange={val => merge('arrInfo', { show_val: val, street: val })}
                  onSelect={val => set('arrInfo', val)}
                  filter={arrInfoSug}
                />
              </div>
            )}
            {arrInfoShow && !arrInfoIsDataList && (
              <div className="arr-info">
                <label className={`fn-label${required(arrInfoRequired)}`}>{ot('到站')}</label>
                <Address
                  g7Map
                  data-path="arr"
                  required={arrInfoRequired}
                  needSelect={startArrNeedPoi}
                  mode="district"
                  mapIcon={false}
                  value={arrInfo}
                  onChange={val => merge('arrInfo', val)}
                  onSelect={val => set('arrInfo', val)}
                />
              </div>
            )}
            {routeShow && (
              <div className="x2">
                <label className={`fn-label${required(routeRequired)}`}>{ot('路由')}</label>
                <Input disabled className="route" value={route ? route.route_nick : ''} />
              </div>
            )}
            {corComShow && (
              <div className="cor-company">
                <label className={`fn-label${required(corComRequired)}`}>{ot('发货单位')}</label>
                <Input
                  data-path="cor_com"
                  required={arrInfoRequired}
                  value={corCom}
                  onChange={e => set('corCom', e.target.value)}
                />
              </div>
            )}
            {corNameShow && (
              <div className="cor-name">
                <label className={`fn-label${required(corNameRequired)}`}>{ot('发货人')}</label>
                <DataList
                  data-path="cor_name"
                  required={corNameRequired}
                  value={corName}
                  data={corNameEnum}
                  header={corHeader}
                  onChange={val => set('corName', val)}
                  onSelect={data => selectSug('cor', data)}
                  filter={(keyword, select) => sugCorName('cor_name', keyword, select)}
                />
              </div>
            )}
            {corMobileShow && (
              <div className="cor-mobile">
                <label className={`fn-label${required(corMobileRequired)}`}>{ot('发货人手机')}</label>
                <Input
                  data-path="cor_mobile"
                  required={corMobileRequired}
                  value={corMobile}
                  maxLength="128"
                  onChange={e => set('corMobile', e.target.value)}
                />
              </div>
            )}
            {corPhoneShow && (
              <div className="cor-phone">
                <label className={`fn-label${required(corPhoneRequired)}`}>{ot('发货人电话')}</label>
                <Input
                  data-path="cor_phone"
                  required={corPhoneRequired}
                  value={corPhone}
                  maxLength="128"
                  onChange={e => set('corPhone', e.target.value)}
                />
              </div>
            )}
            {(corAddrInfoShow || needCorAddress) && (
              <div className="x2">
                <label className={`fn-label${required(corAddrInfoRequired)}`}>{ot('发货地址')}</label>
                <Address
                  g7Map
                  data-path="cor_addr_info"
                  required={corAddrInfoRequired}
                  needSelect={corAddressLock}
                  mode="address"
                  mapIcon={mapChoose}
                  placeholder="请填写发货地址"
                  value={corAddrInfo}
                  onChange={val => merge('corAddrInfo', val)}
                  onSelect={val => set('corAddrInfo', val)}
                />
                {corAddrRemarkShow && (
                  <Input
                    data-path="cor_addr_remark"
                    required={corAddrRemarkRequired}
                    className="address-remark"
                    value={corAddrRemark}
                    placeholder="发货人地址备注"
                    onChange={e => set('corAddrRemark', e.target.value)}
                  />
                )}
              </div>
            )}
            {ceeComShow && (
              <div className="cee-company">
                <label className={`fn-label${required(ceeComRequired)}`}>{ot('收货单位')}</label>
                <Input
                  data-path="cee_com"
                  required={ceeComRequired}
                  value={ceeCom}
                  onChange={e => set('ceeCom', e.target.value)}
                />
              </div>
            )}
            {ceeNameShow && (
              <div className="cee-name">
                <label className={`fn-label${required(ceeNameRequired)}`}>{ot('收货人')}</label>
                <DataList
                  data-path="cee_name"
                  required={ceeNameRequired}
                  value={ceeName}
                  data={ceeNameEnum}
                  header={ceeHeader}
                  onChange={val => set('ceeName', val)}
                  onSelect={data => selectSug('cee', data)}
                  filter={(keyword, select) => sugCeeName('cee_name', keyword, select)}
                />
              </div>
            )}
            {ceeMobileShow && (
              <div className="cee-mobile">
                <label className={`fn-label${required(ceeMobileRequired)}`}>{ot('收货人手机')}</label>
                <Input
                  data-path="cee_mobile"
                  required={ceeMobileRequired}
                  value={ceeMobile}
                  maxLength="128"
                  onChange={e => set('ceeMobile', e.target.value)}
                />
              </div>
            )}
            {ceePhoneShow && (
              <div className="cee-phone">
                <label className={`fn-label${required(ceePhoneRequired)}`}>{ot('收货人电话')}</label>
                <Input
                  data-path="cee_phone"
                  required={ceePhoneRequired}
                  value={ceePhone}
                  maxLength="128"
                  onChange={e => set('ceePhone', e.target.value)}
                />
              </div>
            )}
            {(ceeAddrInfoShow || needCeeAddress) && (
              <div className="x2">
                <label className={`fn-label${required(ceeAddrInfoRequired)}`}>{ot('收货地址')}</label>
                <Address
                  g7Map
                  data-path="cee_addr_info"
                  required={ceeAddrInfoRequired}
                  needSelect={ceeAddressLock}
                  mode="address"
                  mapIcon={mapChoose}
                  placeholder="请填写收货地址"
                  value={ceeAddrInfo}
                  onChange={val => merge('ceeAddrInfo', val)}
                  onSelect={val => set('ceeAddrInfo', val)}
                />
                {ceeAddrRemarkShow && (
                  <Input
                    data-path="cee_addr_remark"
                    required={ceeAddrRemarkRequired}
                    className="cee-address-remark"
                    value={ceeAddrRemark}
                    placeholder="收货人地址备注"
                    onChange={e => set('ceeAddrRemark', e.target.value)}
                  />
                )}
              </div>
            )}
            {nameShow && (
              <div>
                <label className={`fn-label${required(nameRequired)}`}>{ot('货物名称')}</label>
                <DataList
                  data-path="name_1"
                  required={nameRequired}
                  value={goods0.name}
                  data={nameEnum}
                  header={normalHeader}
                  readOnly={nameLock}
                  onChange={val => setGoods('name', val)}
                  onSelect={data => setGoods('name', data.value)}
                />
              </div>
            )}
            {pkgShow && (
              <div>
                <label className={`fn-label${required(pkgRequired)}`}>{ot('包装')}</label>
                <DataList
                  data-path="pkg_1"
                  required={pkgRequired}
                  value={goods0.pkg}
                  data={pkgEnum}
                  header={normalHeader}
                  readOnly={pkgLock}
                  onChange={val => setGoods('pkg', val)}
                  onSelect={data => setGoods('pkg', data.value)}
                />
              </div>
            )}
            {numShow && (
              <div>
                <label className={`fn-label${required(numRequired)}`}>{ot('件数')}</label>
                <Input
                  data-path="num_1"
                  required={numRequired}
                  value={goods0.num}
                  onChange={e => setGoods('num', e.target.value)}
                  pattern={NUMBER}
                />
              </div>
            )}
            {weightShow && (
              <div>
                <label className={`fn-label${required(weightRequired)}`}>
                  {ot('重量')}({weightUnit})
                </label>
                <Input
                  data-path="weight_1"
                  required={weightRequired}
                  value={goods0.weight}
                  onChange={e => setGoods('weight', e.target.value)}
                  pattern={weightUnit === 'kg' ? FLOAT_P_3 : FLOAT_P_6}
                />
              </div>
            )}
            {volumeShow && (
              <div>
                <label className={`fn-label${required(volumeRequired)}`}>{ot('体积')}(方)</label>
                <Input
                  data-path="volume_1"
                  required={volumeRequired}
                  placeholder="长*宽*高*件数(米)"
                  value={goods0.volume}
                  onChange={e => setGoods('volume', e.target.value)}
                  onBlur={calcVolume}
                  pattern={FLOAT_P_3}
                />
              </div>
            )}
            {unitPShow && (
              <div>
                <label className={`fn-label${required(unitPRequired)}`}>{ot('单价')}</label>
                <Input
                  data-path="unit_p_1"
                  className="price-unit"
                  required={unitPRequired}
                  value={goods0.unit_p}
                  onChange={e => setGoods('unit_p', e.target.value)}
                  pattern={FLOAT_P_6}
                />
                <Select
                  data-path="unit_p_unit_1"
                  className="unit"
                  required={unitPRequired}
                  value={goods0.unit_p_unit}
                  data={unitEnum}
                  filter={false}
                  onChange={val => setGoods('unit_p_unit', val)}
                />
              </div>
            )}
            {catShow && (
              <div>
                <label className={`fn-label${required(catRequired)}`}>{ot('货物类型')}</label>
                <DataList
                  data-path="cat_1"
                  required={catRequired}
                  value={goods0.cat}
                  data={catEnum}
                  header={normalHeader}
                  readOnly={catLock}
                  onChange={val => setGoods('cat', val)}
                  onSelect={data => setGoods('cat', data.value)}
                />
              </div>
            )}
            {specShow && (
              <div>
                <label className={`fn-label${required(specRequired)}`}>{ot('规格')}</label>
                <Input
                  data-path="spec_1"
                  required={specRequired}
                  value={goods0.spec}
                  onChange={e => setGoods('spec', e.target.value)}
                />
              </div>
            )}
            {modelShow && (
              <div>
                <label className={`fn-label${required(modelRequired)}`}>{ot('型号')}</label>
                <Input
                  data-path="model_1"
                  required={modelRequired}
                  value={goods0.model}
                  onChange={e => setGoods('model', e.target.value)}
                />
              </div>
            )}
            {specialShow && (
              <div>
                <label className={`fn-label${required(specialRequired)}`}>{ot('特殊货物')}</label>
                <DataList
                  data-path="special_1"
                  required={specialRequired}
                  value={goods0.special && goods0.special.join(',')}
                  data={specialEnum}
                  header={normalHeader}
                  readOnly={specialLock}
                  filter={false}
                  format="value"
                  multiple
                  onChange={val => setGoods('special', val && val.split(','))}
                  onSelect={data => setGoods('special', data && data.map(d => d.value))}
                />
              </div>
            )}
            {coFreightFShow && (
              <div className="price-unit">
                <label className={`fn-label${required(coFreightFRequired)}`}>{ot('开单运费', '运费')}</label>
                <Input
                  data-path="co_freight_f"
                  required={coFreightFRequired}
                  value={coFreightF}
                  onChange={e => set('coFreightF', e.target.value)}
                  pattern={FLOAT}
                />
              </div>
            )}
            {coDeliveryFShow && (
              <div>
                <label className={`fn-label${required(coDeliveryFRequired)}`}>{ot('开单送货费', '送货费')}</label>
                <Input
                  data-path="co_delivery_f"
                  value={coDeliveryF}
                  required={coDeliveryFRequired}
                  onChange={e => set('coDeliveryF', e.target.value)}
                  pattern={FLOAT}
                />
              </div>
            )}
            {declaredValueShow && (
              <div>
                <label className={`fn-label${required(declaredValueRequired)}`}>{ot('声明价值')}</label>
                <Input
                  data-path="declared_value"
                  value={declaredValue}
                  required={declaredValueRequired}
                  onChange={e => set('declaredValue', e.target.value)}
                  pattern={FLOAT}
                />
              </div>
            )}
            {coInsuranceShow && (
              <div>
                <label className={`fn-label${required(coInsuranceRequired)}`}>{ot('开单保价费', '保价费')}</label>
                <Input
                  data-path="co_insurance"
                  value={coInsurance}
                  required={coInsuranceRequired}
                  onChange={e => set('coInsurance', e.target.value)}
                  pattern={FLOAT}
                  customValidity={maxInsurance}
                >
                  {insurCalcIcon && <i className="input-icon fn-icon fn-icon-calculate" onClick={calcCoInsurance} />}
                </Input>
              </div>
            )}
            {coDeliveryShow && (
              <div>
                <label className={`fn-label${required(coDeliveryRequired)}`}>{ot('代收货款')}</label>
                <Input
                  data-path="co_delivery"
                  value={coDelivery}
                  required={coDeliveryRequired}
                  onChange={e => set('coDelivery', e.target.value)}
                  customValidity={() => validateDelivery(state)}
                  pattern={FLOAT}
                />
              </div>
            )}
            {coDeliveryFeeShow && (
              <div>
                <label className={`fn-label${required(coDeliveryFeeRequired)}`}>{ot('货款手续费')}</label>
                <Input
                  data-path="co_delivery_fee"
                  value={coDeliveryFee}
                  required={coDeliveryFeeRequired}
                  disabled={!(+coDelivery > 0)}
                  onChange={e => set('coDeliveryFee', e.target.value)}
                  pattern={FLOAT}
                  customValidity={maxDeliveryFee}
                >
                  {coDeliveryFeeCalcIcon && (
                    <i className="fn-icon fn-icon-calculate input-icon" onClick={calcCoDeliverFee} />
                  )}
                </Input>
              </div>
            )}
            {pickupFShow && (
              <div>
                <label className={`fn-label${required(pickupFRequired)}`}>{ot('单票提货费')}</label>
                <Input
                  data-path="point_cost_info_co_delivery_0"
                  value={pointCostInfo && pointCostInfo[0] && pointCostInfo[0].pickup_f}
                  required={pickupFRequired}
                  onChange={e => page.setTable('pointCostInfo', 0, { pickup_f: e.target.value })}
                  pattern={FLOAT}
                />
              </div>
            )}
            {mgrIdShow && (
              <div>
                <label className={`fn-label${required(mgrIdRequired)}`}>{ot('经办人')}</label>
                <Select
                  data-path="mgr_id"
                  required={mgrIdRequired}
                  compare="id"
                  value={mgrId}
                  data={mgrIdEnum}
                  header={mgrHeader}
                  filter={sugMgr}
                  map={false}
                  onChange={val => set('mgrId', val)}
                />
              </div>
            )}
            {payModeShow && (
              <div>
                <label className={`fn-label${required(payModeRequired)}`}>{ot('付款方式')}</label>
                <Select
                  data-path="pay_mode"
                  required={payModeRequired}
                  value={payMode}
                  data={payModeEnum}
                  filter={false}
                  onChange={val => merge('payMode', val)}
                />
              </div>
            )}
            {pickupShow && (
              <div>
                <label className="fn-label" />
                <label className="fn-checkbox-label">
                  <Checkbox
                    data-path="pickup"
                    checked={pickup}
                    disabled={!pickupEditable}
                    onChange={e => merge('pickup', e.target.checked)}
                  />
                  上门提货
                </label>
              </div>
            )}
            {deliveryModeShow && (
              <div>
                <label className={`fn-label${required(deliveryModeRequired)}`}>{ot('送货方式')}</label>
                <Select
                  data-path="delivery_mode"
                  required={deliveryModeRequired}
                  value={deliveryMode}
                  data={deliveryModeEnum}
                  filter="name"
                  onChange={val => merge('deliveryMode', val)}
                  shortcuts={deliveryShortcuts}
                />
              </div>
            )}
            {rebateShow && (
              <div>
                <label className={`fn-label${required(rebateRequired)}`}>{ot('回扣')}</label>
                <Input
                  data-path="rebate"
                  value={rebate}
                  required={rebateRequired}
                  disabled={!rebateEditable}
                  onChange={e => merge('rebate', e.target.value)}
                  pattern={FLOAT}
                />
              </div>
            )}
            {cashreturnShow && (
              <div>
                <label className={`fn-label${required(cashreturnRequired)}`}>{ot('现返')}</label>
                <Input
                  data-path="cashreturn"
                  value={cashreturn}
                  required={cashreturnRequired}
                  onChange={e => merge('cashreturn', e.target.value)}
                  pattern={FLOAT}
                />
              </div>
            )}
            {discountShow && (
              <div>
                <label className={`fn-label${required(discountRequired)}`}>{ot('欠返')}</label>
                <Input
                  data-path="discount"
                  value={discount}
                  required={discountRequired}
                  onChange={e => merge('discount', e.target.value)}
                  pattern={FLOAT}
                />
              </div>
            )}
            {receiptCatShow && (
              <div>
                <label className={`fn-label${required(receiptCatRequired)}`}>{ot('回单类型')}</label>
                <Select
                  data-path="receipt_cat"
                  value={receiptCat}
                  required={receiptCatRequired}
                  data={receiptCatEnum}
                  header={normalHeader}
                  format={lockShow}
                  filter="value"
                  placeholder="回单类型"
                  onChange={val => set('receiptCat', val)}
                />
              </div>
            )}
            {receiptCatShow && (
              <div>
                <label className={`fn-label${required(receiptCatRequired)}`}>{ot('回单数')}</label>
                <Input
                  data-path="receipt_n"
                  pattern={receiptCatRequired ? NUMBER_NO_ZERO : NUMBER}
                  value={receiptN}
                  required={receiptCatRequired}
                  onChange={e => set('receiptN', e.target.value)}
                />
              </div>
            )}
            {remarkShow && (
              <div className="x2">
                <label className={`fn-label${required(remarkRequired)}`}>{ot('备注')}</label>
                <DataList
                  data-path="remark"
                  className="remark"
                  required={remarkRequired}
                  value={remark}
                  data={remarkEnum}
                  readOnly={remarkLock}
                  header={normalHeader}
                  onChange={val => set('remark', val)}
                  onSelect={data => set('remark', data.value)}
                />
              </div>
            )}
          </div>
        </div>
        <footer>
          <div className="print-checkbox">
            {printItems.map((item, i) => (
              <div key={i}>
                <CheckBox
                  label={item.name}
                  direction="right"
                  tips={item.name}
                  checked={item.checked}
                  onClick={val => checkPrintItem(i, val)}
                />
              </div>
            ))}
          </div>
          {isPre && (
            <div className="in-bk">
              <ButtonIcon
                iconType="icon-print-out"
                data-path="add_new_print"
                loading={state.preSaving && btnType === 'pre_new_print'}
                onClick={() => page.handleSaveBtnClick('pre_new_print')}
                tips={!page.LodopObj ? '请安装打印插件' : undefined}
                disabled={(state.preSaving && btnType !== 'pre_new_print') || !page.LodopObj}
              >
                新增并打印({shortcutsDisplay.add_new_print})
              </ButtonIcon>
              <ButtonIcon
                iconType="icon-print-out"
                data-path="co_save_print"
                loading={state.preSaving && btnType === 'pre_save_print'}
                onClick={() => page.handleSaveBtnClick('pre_save_print')}
                tips={!page.LodopObj ? '请安装打印插件' : undefined}
                disabled={(state.preSaving && btnType !== 'pre_save_print') || !page.LodopObj}
              >
                保存并打印({shortcutsDisplay.co_save_print})
              </ButtonIcon>
              <ButtonIcon
                iconType="icon-save"
                data-path="co_save"
                loading={state.preSaving && btnType === 'pre'}
                onClick={() => page.handleSaveBtnClick('pre')}
              >
                保存({shortcutsDisplay.co_save})
              </ButtonIcon>
            </div>
          )}
          {!isPre && (
            <div className="in-bk">
              <ButtonIcon
                iconType="icon-print-out"
                data-path="add_new_print"
                loading={state.tagsSaving && btnType === 'tags_new_print'}
                onClick={() => page.handleSaveBtnClick('tags_new_print')}
                tips={!page.LodopObj ? '请安装打印插件' : undefined}
                disabled={(state.tagsSaving && btnType !== 'tags_new_print') || !page.LodopObj}
              >
                新增并打印({shortcutsDisplay.add_new_print})
              </ButtonIcon>
              <ButtonIcon
                iconType="icon-print-out"
                data-path="co_save_print"
                loading={state.tagsSaving && btnType === 'tags_save_print'}
                onClick={() => page.handleSaveBtnClick('tags_save_print')}
                tips={!page.LodopObj ? '请安装打印插件' : undefined}
                disabled={(state.tagsSaving && btnType !== 'tags_save_print') || !page.LodopObj}
              >
                保存并打印({shortcutsDisplay.co_save_print})
              </ButtonIcon>
              <ButtonIcon
                iconType="icon-save"
                data-path="co_save"
                loading={state.tagsSaving && btnType === 'tags'}
                onClick={() => page.handleSaveBtnClick('tags')}
              >
                保存({shortcutsDisplay.co_save})
              </ButtonIcon>
            </div>
          )}
        </footer>
      </div>
    );
  };
}

export default renderPre;
