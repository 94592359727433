exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".order-editor-pay{flex:1 37%;margin-left:4px;border-left:1px solid #78aadd}.order-editor-pay>div{width:33.33%;float:left}", ""]);

// exports
exports.locals = {
	"prefixCls": "order-editor-pay",
	"order-editor-pay": "order-editor-pay"
};