/**
 * Created by wangnaihe on 2017/12/1.
 */
export const btnSetDef = {
  co_table_tab_btn: {
    // 开关  开单页表格tab显示隐藏
    show: ['budget_info', 'point_cost_info', 'std_cost', 'cost_info', 'trans_info'],
    hidden: [],
  },
  oinfo_table_tab_btn: {
    // 开关 详情页表格tab显示隐藏
    show: [
      'point_cost_info',
      'std_cost',
      'cost_info',
      'budget_info',
      'tr_info',
      'shuttle_info',
      'sign_info',
      'trans_info',
      'delivery_info',
      'pickup_info',
      'inner_remark',
    ],
    hidden: [],
  },
};

export const orderTableTabs = {
  point_cost_info: { title: '支出费用', type: 'Text', display: 'show', key: 'point_cost_info' },
  cost_info: { title: '成本信息', type: 'Text', display: 'show', key: 'cost_info' },
  std_cost: { title: '标准费用', type: 'Text', display: 'show', key: 'std_cost' },
  tr_info: { title: '配载信息', type: 'Text', display: 'show', key: 'tr_info' },
  shuttle_info: { title: '短驳信息', type: 'Text', display: 'show', key: 'shuttle_info' },
  sign_info: { title: '签收信息', type: 'Text', display: 'show', key: 'sign_info' },
  trans_info: { title: '中转信息', type: 'Text', display: 'show', key: 'trans_info' },
  delivery_info: { title: '送货信息', type: 'Text', display: 'show', key: 'delivery_info' },
  pickup_info: { title: '提货信息', type: 'Text', display: 'show', key: 'pickup_info' },
  budget_info: { title: '预算费用', type: 'Text', display: 'show', key: 'budget_info' },
  inner_remark: { title: '内部备注', type: 'Text', display: 'show', key: 'inner_remark' },
};

export const shutleBoxDict = {
  co_table_tab_btn: orderTableTabs,
  oinfo_table_tab_btn: orderTableTabs,
};

// Blur  check
export const checkConfig = {
  upInter: ['timeout_days'], // 正整数
  integer: ['mile_ratio'], // 正数
  numAn: ['u_price', 'co_make_f'], // 数字, 且最多保留两位小数
};
