/**
 * Created by wangnaihe on 2017/10/9.
 */

import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import { fetchApi as fetch, genRandomString } from 'utils';
import { ModalDialog, Button, Icon, Tips } from 'components';
import adaptor from '../adaptor';
import { getErrorMsg } from '../tool';
import saveOrder from '../public/saveOrder';
import calcCeeDist from '../public/calcCeeDist';
import calcCorDist from '../public/calcCorDist';
import { prefixCls } from './index.scss';

const DOING = 1;
const SUCCESS = 2;
const FAIL = 3;

const iconMap = {
  [DOING]: 'icon-info-o',
  [SUCCESS]: 'icon-check-o',
  [FAIL]: 'icon-error-o',
};

const statusMap = {
  [DOING]: '正在处理中…',
  [SUCCESS]: '操作成功',
  [FAIL]: '操作失败',
};

class SplitReservationModal extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    close: PropTypes.func,
  };

  constructor(prop) {
    super(prop);
    const data = this.props.data.map(item => ({ r: item }));
    this.state = { data };
    this.split_code = genRandomString(8);
  }

  async componentDidMount() {
    const { data } = this.state;
    for (let i = 0; i < data.length; i++) !this.cancel && (await this.process(data[i], i));
  }

  getGoods = async item => {
    const url = 'Reservation/Reservation/edit';
    const res = await fetch(url, { method: 'POST', body: { req: { id: item.r.id, view: true, sys_version: '1' } } });
    return res.res.reservation_data.goods;
  };

  fetchOrder = async (item, goods, splitField, splitMode) => {
    const url = '/Order/Order/co?raw=1';
    const req = {
      reservation_num: item.r.reservation_num,
      co_mode: 0,
      split_mode: splitMode,
      split_field: splitField,
      split_goods: goods,
    };
    const res = await fetch(url, { method: 'POST', body: { req } });
    return res.errno === 0 ? { order: adaptor({ data: res, usedFor: 'create' }) } : { error: getErrorMsg(res) };
  };

  saveOrder = async (order, goods, splitField, splitMode) => {
    const req = { split_mode: splitMode, split_field: splitField, split_goods: goods, split_code: this.split_code };
    const res = await saveOrder(order, 'split', req, true);
    return res.errno === 0 ? { orderNum: res.res.order_data.order_num } : { error: getErrorMsg(res) };
  };

  updateItem = (item, index) => {
    const data = [...this.state.data];
    data[index] = { ...data[index], ...item };
    this.setState({ data });
  };

  process = async (item, index) => {
    const keys = ['num', 'weight', 'volume'];
    this.setState({ cur: index });
    if (keys.every(k => !item.r[`g_${k}`] || item.r[`g_${k}`].every(v => !+v))) {
      this.updateItem(
        { status: FAIL, error: '订单的件数、重量、体积都为0，无法拆单！如需拆单，请先修改订单。' },
        index,
      );
      return false;
    }
    this.updateItem({ status: DOING }, index);
    const goodsOrig = await this.getGoods(item);
    const splitField = (window.company_setting.split_fee_mode && window.company_setting.split_fee_mode.value) || 'num';
    const errors = {};
    for (let i = 0; i < goodsOrig.length; i++) {
      const delta = keys.map(k => +goodsOrig[i][k] - +goodsOrig[i][`${k}_split`] || 0);
      if (delta.every(k => !k)) continue; //eslint-disable-line
      const goods = goodsOrig.map((g, j) =>
        j === i ? { num: delta[0], weight: delta[1], volume: delta[2] } : { num: 0, weight: 0, volume: 0 },
      );
      const splitMode = index === this.state.data.length - 1 && i === goodsOrig.length - 1 ? 3 : 2;
      const { order, error } = await this.fetchOrder(item, goods, splitField, splitMode);
      if (error) {
        errors[i] = error;
      } else {
        const [corPickDist, ceePickDist] = await Promise.all([calcCorDist(order), calcCeeDist(order)]);
        const { error: saveError } = await this.saveOrder(
          { ...order, corPickDist, ceePickDist },
          goods,
          splitField,
          splitMode,
        );
        if (saveError) {
          errors[i] = saveError;
        }
      }
    }

    if (Object.keys(errors).length) {
      this.updateItem(
        {
          status: FAIL,
          error: Object.keys(errors).map(i => (
            <div>
              货物{+i + 1}:{errors[i]}
            </div>
          )),
        },
        index,
      );
    } else {
      this.updateItem({ status: SUCCESS }, index);
    }
  };

  close = done => {
    this.cancel = !done;
    this.modal.handleHide();
  };

  render() {
    const { data, cur } = this.state;
    const total = data.length;
    const done = total === cur + 1 && (data[cur].status === SUCCESS || data[cur].status === FAIL);
    const failed = done && data.filter(({ status }) => status === FAIL).length;
    const succeeded = done && total - failed;
    const content = (
      <div className={prefixCls}>
        <div className="process">
          <Icon iconType={done ? 'icon-check-o' : 'icon-info-o'} />
          {!done && (
            <span>
              拆单进度（第<span className="fs22 text-orange">{cur}</span>个/共{total}个），
              <span className="text-red">操作进行中，请勿关闭！</span>
            </span>
          )}
          {done && (
            <span>
              拆单已完成，<span className="fs22 text-green">{succeeded}</span>个操作成功，
              <span className="fs22 text-red">{failed}</span>个操作失败！
            </span>
          )}
        </div>
        <div className="fn-table-wrap">
          <table className="fn-table-a">
            <thead>
              <tr>
                <th width={30}>序号</th>
                <th>订单号</th>
                <th>拆单数</th>
                <th width={100}>操作状态</th>
              </tr>
            </thead>
            <tbody>
              {data.map(({ r, status, error }, index) => {
                const statusText = (
                  <Fragment>
                    <Icon iconType={iconMap[status]} />
                    <span>{statusMap[status]}</span>
                  </Fragment>
                );
                return (
                  <tr key={r.id}>
                    <td>{index + 1}</td>
                    <td>{r.reservation_num}</td>
                    <td>{r.g_num ? r.g_num.length : 0}</td>
                    <td>
                      {status &&
                        (error ? (
                          <Tips className="status-bar" title={error}>
                            {statusText}
                          </Tips>
                        ) : (
                          <span className="status-bar">{statusText}</span>
                        ))}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );

    const bottom = (
      <Button type="primary" onClick={() => this.close(done)}>
        {done ? '关闭' : '取消'}
      </Button>
    );

    return (
      <ModalDialog
        ref={r => (this.modal = r)}
        content={content}
        bottom={bottom}
        isShow
        isModal
        close={this.props.close}
        title="拆单进度"
        contentStyle={{ width: '568px' }}
      />
    );
  }
}

export default SplitReservationModal;
