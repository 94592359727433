/**
 * Created by wangnaihe on 2017/12/1.
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { fromJS, typeIs, getReturnValue, showInfo } from 'utils';
import { setComVal } from 'utils/dataStripping';
import { CardForm, Button, ModalDialog, PopTip, PopUp, PopupTypeset } from 'components';
import actions from 'actions';
import { INFO, ERROR } from 'constants';
import { checkConfig, shutleBoxDict, btnSetDef } from './constant';
import { prefixCls } from './index.scss';

/* current code */

const btnSet = {
  co_table_tab: '开单页页底部页签',
  oinfo_table_tab: '详情页底部页签',
};
class personSettingModal extends PureComponent {
  static propTypes = {
    close: PropTypes.func,
    onSaveSuccess: PropTypes.func,
  };

  static childContextTypes = {
    setData: PropTypes.func,
    getPath: PropTypes.func,
  };
  getChildContext() {
    return { setData: this.setData, getPath: this.getPath };
  }

  constructor(prop) {
    super(prop);
    this.state = {
      psnData: prop.psnData,
    };
    this.events = {
      onClick: this.handleEvents,
      onChange: this.handleEvents,
      handleSelected: this.handleEvents,
    };
    this.ssp = []; // setState path
  }

  UNSAFE_componentWillMount() {
    actions.settingServer.getOrderSet('psn').then(res => {
      if (res.type !== 'API_SUCCESS') {
        return null;
      }
      this.setState({
        sumData: res.data.sumData,
        psnData: res.data.resData ? res.data.resData.pageData : '',
      });
    });
  }

  UNSAFE_componentWillReceiveProps() {}

  resetDef = () => {
    this.psnSetCpntDag.handleHide();
    actions.settingServer.resetPsn('psn', 'co_def_val').then(res => {
      if (res.type === 'API_SUCCESS') {
        new PopUp(PopTip, {
          classname: 'pop_tip',
          type: INFO,
          isShow: true,
          content: '保存成功',
          autoDestroy: true,
        }).show();
        // location.reload(true)
        this.props.onSaveSuccess && this.props.onSaveSuccess('co_person_set');
      } else {
        console.log(res);
      }
    });
  };

  savePsnDt = () => {
    console.log('here is savePsnDt');
    this.psnSetCpntDag.handleHide();
    actions.settingServer.setOrderSet(this.state.diffForm, false, '', 'psn').then(res => {
      if (res.type === 'API_SUCCESS') {
        new PopUp(PopTip, {
          classname: 'pop_tip',
          type: INFO,
          isShow: true,
          content: '保存成功',
          autoDestroy: true,
        }).show();
        // location.reload(true)
        this.props.onSaveSuccess && this.props.onSaveSuccess('co_person_set');
      } else {
        console.log(res);
      }
    });
  };

  numAnCheck = (key, _value, tplValue, eventName) => {
    let retVal = _value;
    let retValTp = tplValue;
    if (checkConfig.numAn.includes(key) && eventName === 'onBlur' && _value) {
      if (!+_value) {
        showInfo(ERROR, '只能填写大于0的数字！');
        retVal = retValTp = '';
      } else {
        retVal = retValTp = +(+_value).toFixed(2);
      }
    }
    return { retVal, retValTp };
  };

  getPath = paths => (this.ssp = paths);

  handleEvents = (key, value, ...rest) => {
    const type = rest[rest.length - 2] || '';
    if (type !== 'SwitchSet') return null;
    // console.log(type, '++++', rest)
  };

  typeSetReset = (getvModalFunc, btnkey, keyPath, pureDtKeyPath, _keyData, _sumData) => {
    const _left = btnSetDef[btnkey].hidden || [];
    const _right = btnSetDef[btnkey].show || [];
    const defKeyData = _keyData.setIn(['__meta', 'hidden'], fromJS(_left)).setIn(['__meta', 'show'], fromJS(_right));
    const saveSumData = _sumData.setIn(pureDtKeyPath, defKeyData);
    const _setKey = keyPath[keyPath.length - 1];
    const diffForm = { ...this.state.diffForm, [_setKey]: defKeyData.toJS() };
    this.setState({
      diffForm,
      sumData: saveSumData.toJS(), // not necessary
    });
    getvModalFunc().vModalDialog.handleHide();
  };

  typeSetOk = (getvModalFunc, getvTypeset, keyPath, pureDtKeyPath, _keyData, _sumData) => {
    let okKeyData = null;
    const vTypeSet = getvTypeset();
    const _left = vTypeSet.state.leftList;
    const _right = vTypeSet.state.rightList;
    const _show = []; // 显示字段  如开单页显示设置中的支出费用、标准费用
    const _hidden = [];
    _left.forEach(item => {
      item && delete item.baseOpationCheckColumn; // eslint-disable-line
      item && item.key && _hidden.push(item.key);
    });
    _right.forEach(item => {
      item && delete item.baseOpationCheckColumn; // eslint-disable-line
      item && item.key && _show.push(item.key);
    });
    okKeyData = _keyData.setIn(['__meta', 'hidden'], fromJS(_hidden)).setIn(['__meta', 'show'], fromJS(_show));

    const saveSumData = _sumData.setIn(pureDtKeyPath, okKeyData);
    const _setKey = keyPath[keyPath.length - 1];
    const diffForm = { ...this.state.diffForm, [_setKey]: okKeyData.toJS() };
    // 改变的顶部Tab
    this.setState({
      diffForm,
      sumData: saveSumData.toJS(), // not necessary
    });
    getvModalFunc().vModalDialog.handleHide();
  };
  // 穿梭框类的对话框
  shuttleBox = (keyName, keyPath) => {
    const leftList = [];
    const rightList = [];
    let vTypset = null;
    let vModalDialog = null;
    const getvTypet = () => vTypset;
    const getvModalDialog = () => vModalDialog;
    const _sumData = fromJS(this.state.sumData);
    const _keyPath = keyPath.filter(item => item !== 'data' && item !== 'pageData' && item !== 'co_def_val');
    const _keyData = _sumData.getIn(_keyPath);
    const lListAry = _keyData.getIn(['__meta', 'hidden']).toJS();
    const rListAry = _keyData.getIn(['__meta', 'show']).toJS();
    const shutBD = shutleBoxDict[keyName];
    lListAry.forEach(item => {
      leftList.push(shutBD[item]);
    });
    rListAry.forEach(item => {
      rightList.push(shutBD[item]);
    });
    const titleKey = keyName.slice(0, -4);
    const dialog = new PopUp(PopupTypeset, {
      isShow: true,
      title: btnSet[titleKey],
      autoDestroy: true,
      ref: r => (vModalDialog = r),
      handleSubmit: () => this.typeSetOk(getvModalDialog, getvTypet, keyPath, _keyPath, _keyData, _sumData),
      // handleCancel: () => this.typeSetCancel(getvModalDialog, getvTypet),
      handleReset: () => this.typeSetReset(getvModalDialog, keyName, keyPath, _keyPath, _keyData, _sumData),
      typesetProps: {
        ref: r => (vTypset = r),
        leftListProps: {
          showHeader: false,
          tableHeader: {
            title: 'title',
          },
        },
        rightListProps: {
          showHeader: false,
          tableHeader: {
            title: 'title',
          },
        },
        uniqueKey: 'key',
        leftList,
        rightList,
      },
    });
    dialog.show();
    // console.log(keyPath, dialog)
  };

  setData = (key, value, paths, eventName, type) => {
    const data = fromJS(this.state.psnData);
    const sumData = fromJS(this.state.sumData);

    let _value = value; // diff form
    let tplValue = value; // tpl

    const _setKey = paths[paths.length - 1];
    let _vPaths = paths.filter(item => item !== 'data' && item !== 'pageData' && item !== 'supplement');
    const subPath = ['otherProps', 'others', key];
    const notePath = ['__meta', key];

    // 判断类型 start
    if (type === 'Input') {
      _value = value.target.value;
      tplValue = value.target.value;
    } else if (type === 'SelectDrop' && value) {
      if (typeIs(value, 'object')) {
        _value = _setKey === 'dest_point' || _setKey === 'rcv_stn' ? value : value.key;
      } else {
        _value = value.map(item => item.key);
      }
      tplValue = getReturnValue(value, type);
    }
    // 判断类型 end

    // 类型check  start
    const checkRst = this.numAnCheck(paths[paths.length - 1], _value, tplValue, eventName);
    tplValue = checkRst.retValTp;
    _value = checkRst.retVal;
    // 类型check  end

    if (type === 'Shortcuts') {
      _vPaths = [_setKey];
    }

    if (type === 'Button') {
      this.shuttleBox(key, _vPaths);
    }
    // if (_setKey === 'transport_type') _value = value.value

    const tplPath = paths.concat(subPath);
    const noteData = sumData.getIn(_vPaths);

    if (!noteData) {
      // showInfo(INFO, '请先恢复默认设置，再继续使用!')
      console.log('需要恢复默认设置', _setKey);
      return false;
    }

    if (_setKey === 'print_copies' && _value && (!/^\d+$/.test(_value) || +_value <= 0)) {
      showInfo(ERROR, '[运单打印数]只能填写大于0的正整数，不允许填0！');
      _value = tplValue = '';
    }

    if (_setKey === 'goods_count' && _value && (!/^\d+$/.test(_value) || +_value <= 0 || +_value > 80)) {
      showInfo(ERROR, '[货物行数]只能填写1~80之间的整数');
      _value = tplValue = '';
    }

    // let ABC = setComVal(data.getIn(tplPath), tplValue)
    // console.log(ABC)
    let _psnDataHere = data.setIn(tplPath, setComVal(data.getIn(tplPath), tplValue));

    const _noteData = noteData.setIn(notePath, _value).toJS();

    const diffForm = { ...this.state.diffForm, [_setKey]: _noteData };
    let _sumDataHere = sumData.setIn(_vPaths, _noteData);

    // 服务类型关联送货方式
    if (_setKey === 'service_type') {
      let deliveryWay = fromJS(_sumDataHere.getIn(['co_def_val', 'delivery_way']));
      let deliveryValue = deliveryWay.getIn(['__meta', 'value']);
      let deliveryVal = [];
      if (_value === 'site_door' && (deliveryValue === 'self_pick' || !deliveryValue)) {
        deliveryValue = 'pure_delivery';
        deliveryVal = [{ key: 'pure_delivery', name: '送货' }];
      } else if (_value === 'site_site' && deliveryValue !== 'self_pick') {
        deliveryValue = 'self_pick';
        deliveryVal = [{ key: 'self_pick', name: '自提' }];
      } else if (!_value) {
        deliveryVal = [];
        deliveryValue = null;
      } else {
        deliveryValue = '';
      }

      if (deliveryValue !== '') {
        const deliveryWayEnum = data
          .getIn(['co_def_val', 'data', 'delivery_way', 'otherProps', 'others', 'value', 'otherProps', 'data'])
          .toJS();
        const deliverWayList = deliveryWayEnum.map(v => v.key);
        if (!deliverWayList.find(v => v === deliveryValue)) {
          deliveryVal = [];
          deliveryValue = null;
        }
      }
      if (deliveryValue !== '') {
        // 页面数据设置
        const subJSPathArr = ['otherProps', 'others', 'value', 'otherProps', 'defaultSelected'];
        const unFromJSDtPath = ['co_def_val', 'data', 'delivery_way'];
        let contentMain = fromJS(_psnDataHere.getIn(unFromJSDtPath));
        contentMain = contentMain.setIn(subJSPathArr, deliveryVal);
        _psnDataHere = _psnDataHere.setIn(unFromJSDtPath, contentMain);
        // 设置数据设置
        deliveryWay = deliveryWay.setIn(['__meta', 'value'], deliveryValue);
        _sumDataHere = _sumDataHere.setIn(['co_def_val', 'delivery_way'], deliveryWay);
        diffForm.delivery_way = _sumDataHere.getIn(['co_def_val', 'delivery_way']);
      }
    }

    // 送货方式关联服务类型
    if (_setKey === 'delivery_way') {
      let serviceType = fromJS(_sumDataHere.getIn(['co_def_val', 'service_type']));
      let serviceTypeValue = serviceType.getIn(['__meta', 'value']);
      let serviceTypeVal = [];
      if (_value === 'self_pick' && serviceTypeValue !== 'site_site') {
        serviceTypeValue = 'site_site';
        serviceTypeVal = [{ key: 'site_site', name: '站到站' }];
      } else if (_value && _value !== 'self_pick' && serviceTypeValue !== 'site_door') {
        serviceTypeValue = 'site_door';
        serviceTypeVal = [{ key: 'site_door', name: '站到门' }];
      } else if (!_value) {
        serviceTypeVal = [];
        serviceTypeValue = null;
      } else {
        serviceTypeValue = '';
      }

      if (serviceTypeValue !== '') {
        // 页面数据设置
        const subJSPathArr = ['otherProps', 'others', 'value', 'otherProps', 'defaultSelected'];
        const unFromJSDtPath = ['co_def_val', 'data', 'service_type'];
        let contentMain = fromJS(_psnDataHere.getIn(unFromJSDtPath));
        contentMain = contentMain.setIn(subJSPathArr, serviceTypeVal);
        _psnDataHere = _psnDataHere.setIn(unFromJSDtPath, contentMain);
        // 设置数据设置
        serviceType = serviceType.setIn(['__meta', 'value'], serviceTypeValue);
        _sumDataHere = _sumDataHere.setIn(['co_def_val', 'service_type'], serviceType);
        diffForm.service_type = _sumDataHere.getIn(['co_def_val', 'service_type']);
      }

      if (_value === 'direct_complete' || _value === 'direct_truck') {
        let arrPoint = fromJS(_sumDataHere.getIn(['co_def_val', 'dest_point']));
        const subJSPathArr = ['otherProps', 'others', 'value', 'otherProps', 'defaultSelected'];
        const unFromJSDtPath = ['co_def_val', 'data', 'dest_point'];
        let contentMain = fromJS(_psnDataHere.getIn(unFromJSDtPath));
        const { id, company_name: name, address } = window.company_info;
        const arrPointVal = { ...window.company_info, key: id, name, address: JSON.parse(address) };
        contentMain = contentMain.setIn(subJSPathArr, [arrPointVal]);
        _psnDataHere = _psnDataHere.setIn(unFromJSDtPath, contentMain);
        // 设置数据设置
        arrPoint = arrPoint.setIn(['__meta', 'value'], arrPointVal);
        _sumDataHere = _sumDataHere.setIn(['co_def_val', 'dest_point'], arrPoint);
        diffForm.dest_point = _sumDataHere.getIn(['co_def_val', 'dest_point']);
      }
    }

    // 目的网点 关联
    if (_setKey === 'dest_point') {
      if (_value && +_value.id !== +window.company_id) {
        let deliveryWay = fromJS(_sumDataHere.getIn(['co_def_val', 'delivery_way']));
        const deliveryValue = deliveryWay.getIn(['__meta', 'value']);
        if (deliveryValue === 'direct_complete' || deliveryValue === 'direct_truck') {
          const subJSPathArr = ['otherProps', 'others', 'value', 'otherProps', 'defaultSelected'];
          const unFromJSDtPath = ['co_def_val', 'data', 'delivery_way'];
          let contentMain = fromJS(_psnDataHere.getIn(unFromJSDtPath));
          contentMain = contentMain.setIn(subJSPathArr, []);
          _psnDataHere = _psnDataHere.setIn(unFromJSDtPath, contentMain);
          // 设置数据设置
          deliveryWay = deliveryWay.setIn(['__meta', 'value'], '');
          _sumDataHere = _sumDataHere.setIn(['co_def_val', 'delivery_way'], deliveryWay);
          diffForm.delivery_way = _sumDataHere.getIn(['co_def_val', 'delivery_way']);
        }
      }
    }

    this.setState({
      psnData: _psnDataHere.toJS(),
      diffForm,
      sumData: _sumDataHere.toJS(),
    });
    // console.log(noteData, diffForm)
  };

  renderDom = psnData =>
    // console.log(psnData)
    Object.entries(psnData).map(([key, item]) => {
      let elements = null;
      elements = (
        <CardForm
          key={key}
          ref={r => (this[`${key}`] = r)}
          header={item.header}
          classname={item.classname}
          getPaths={(...rest) => [key, 'data', ...rest]}
          reactKey={key}
          data={item.data}
          handleEvents={this.events}
        />
      );
      return elements;
    });

  render = () => {
    const { psnData } = this.state;
    const btnClas = 'fn-btn fn-btn--default';
    const _button = [
      <Button key="1" submit type="primary" className={btnClas} onClick={() => this.resetDef()}>
        恢复默认
      </Button>,
      <Button key="2" submit type="primary" className={btnClas} onClick={() => this.savePsnDt()}>
        确定
      </Button>,
      <Button key="3" submit className={btnClas} onClick={() => this.psnSetCpntDag.handleHide()}>
        取消
      </Button>,
    ];

    if (!psnData) {
      return null;
    }

    return (
      <ModalDialog
        classname={prefixCls}
        content={this.renderDom(psnData)}
        isShow
        isModal
        autoDestroy
        title="个人设置"
        ref={r => (this.psnSetCpntDag = r)}
        close={this.props.close}
        popName="psnSetCpnt"
        bottom={_button}
        contentStyle={{ width: '960px', height: '500px', overflow: 'auto' }}
      />
    );
  };
}

export default personSettingModal;
