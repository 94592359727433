import moment from 'moment';
import { postponeDate } from '../tool';
import { SET_ORDER_DATA } from '../constant';

const headerMiddleware = page => store => {
  const onChange = (key, val) => {
    const state = store.getState();
    switch (key) {
      case 'billingDate': {
        const stamp = val.slice(0, 10);
        page.merge('isToday', 'not-today');
        localStorage.billDate = stamp;
        break;
      }
      case 'prevDate': {
        localStorage.prevDate = val;
        localStorage.billDate = state.billingDate.slice(0, 10);
        break;
      }
      case 'productLine': {
        if (!state.prevDate && state.checkOverCutOffTime) {
          const { defaultBillingDate, checkSaturday, checkSunday, postponeBillingDateTo } = state;
          if (val && val.cut_off_time && moment().format('HH:mm:ss') > val.cut_off_time) {
            const newDate = postponeDate(defaultBillingDate, checkSaturday, checkSunday, postponeBillingDateTo, true);
            store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'billingDate', val: newDate } });
          } else {
            store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'billingDate', val: defaultBillingDate } });
          }
        }
        break;
      }
      default:
        break;
    }
  };

  return next => action => {
    switch (action.type) {
      case SET_ORDER_DATA:
        onChange(action.payload.key, action.payload.val, action.src, action);
        break;
      default:
        break;
    }
    next(action);
  };
};

export default headerMiddleware;
