// 车辆管理 - 配置文件

const submitCertHeader = {
  tr_num: { title: '车牌号', display: 'show', align: 'center' },
  tr_color_text: { title: '车牌颜色', display: 'show', align: 'center' },
};

// 批量操作的配置
export const batchOpConfig = {
  // 提交认证
  truck_platform_cert: {
    title: '车辆认证进度',
    url: '/cmm-basic/truck/commitAttestationOfPlatform',
    tips: '车辆认证',
    header: submitCertHeader,
  },
};
