/**
 * Created by wangnaihe on 2017/6/19.
 */
import React from 'react';
import {
  DataList,
  PureInput as Input,
  Address,
  Icon,
  Tips,
  RangeTimePicker,
  DateTimePicker,
  UploadFile,
} from 'components';
import { readIDFun, ot, buildTipMixin } from 'utils';
import { MOBILE } from 'constants';
import { SELECT_CEE_SUG } from '../../constant';
import { required, AutoWrapLabel } from '../../tool';
import { formatSugData, sugCorCee, showHistory, renderMsgIcon, destination } from '../tool';
import { prefixCls } from './index.scss';
import { dataAnalyticTrack } from '@/utils/dataAnalytic';

const projectCeeTip = buildTipMixin(
  <div>
    提示：
    <br />
    项目客户的收货单位在开单时不允许修改！如需修改，请到客户管理里修改客户信息。！
  </div>,
  { type: 'info' },
);

function renderConsigneeTo(page) {
  const disabledDate = current => {
    const state = page.store.getState();
    return current && state.shudGTEXpcd && state.xpcdArrDate && new Date(state.xpcdArrDate) > current.valueOf();
  };

  return function () {
    const { store } = page;
    const state = store.getState();
    const { origin, isDetail, ceeId, ceeHeader, ceeSalesmen, ceeIsProject } = state;
    const { ceeIsMonth, ceeIsMonthShow } = state;
    const { ceeCustomerNo, ceeCustomerNoShow, ceeCustomerNoRequired, ceeCustomerNoEditable, ceeCustomerNoEnum } = state;
    const { ceeIndustry, ceeIndustryShow, ceeIndustryRequired, ceeIndustryEditable, ceeIndustryEnum } = state;
    const { ceeCom, ceeComShow, ceeComRequired, ceeComEditable, ceeComEnum } = state;
    const { ceeName, ceeNameShow, ceeNameRequired, ceeNameEditable, ceeNameEnum, ceeHistoryShow } = state;
    const { ceeMobile, ceeMobileShow, ceeMobileRequired, ceeMobileEditable, ceeMobileEnum } = state;
    const { ceePhone, ceePhoneShow, ceePhoneRequired, ceePhoneEditable, ceePhoneEnum, validateCeeMobile } = state;
    const { ceeIdNum, ceeIdNumShow, ceeIdNumRequired, ceeIdNumEditable, ceeIdNumEnum, readIDCard } = state;
    const { ceeCate, ceeCateStr, ceeCateShow, ceeCateRequired, ceeCateEditable } = state;
    const {
      ceeAddrInfo,
      ceeAddrInfoShow,
      ceeAddrInfoRequired,
      ceeAddrInfoEditable,
      mapChoose,
      ceeAddressType,
      ceeAddressLock,
    } = state;
    const { ceeAddrRemark, ceeAddrRemarkShow, ceeAddrRemarkEditable, ceeAddrRemarkRequired } = state;
    const { ceePickDist, ceePickDistShow } = state;
    const { shudArrDate, shudArrDateShow, shudArrDateRequired, shudArrDateEditable } = state;
    const { obPlanArrT, obPlanArrTShow, obPlanArrTRequired, obPlanArrTEditable } = state;
    const { ceeRemark, ceeRemarkShow, ceeRemarkEditable, ceeRemarkRequired } = state;
    const { autoMatchGdAddr } = state;

    // 收货人附件
    const { ceeAttachment, ceeAttachmentShow, ceeAttachmentEditable, ceeAttachmentRequired, matchedCeeAttachment } =
      state;

    const { set } = page;

    const { merge } = page;

    const sug = (key, type, keyword, select) => {
      sugCorCee(false, type, keyword, state, select.fetch).then(res => page.setSug(key, formatSugData(res.res)));
    };

    const clearHiddenFields = (name, mobile) => {
      const ceeMaintainCalc = state.basicSetting.cee_maintain_calc || {};
      ceeId && set('ceeId', '');
      ceeCate && set('ceeCate', '');
      merge('ceeCateStr', '');
      ceeSalesmen && set('ceeSalesmen', '');
      set('ceeMaintain', name || mobile ? ceeMaintainCalc.maintain_fee : []);
      set('ceeMaintainByFormula', +ceeMaintainCalc.maintain_rule === 2);
      set('ceeMaintainRatio', name || mobile ? +ceeMaintainCalc.maintain_ratio || 0 : 0);
      ceeIsProject && set('ceeIsProject', false);
      !ceeIsMonthShow && ceeIsMonth && set('ceeIsMonth', false);
      !ceeNameShow && ceeName && set('ceeName', '');
      !ceeMobileShow && ceeMobile && set('ceeMobile', '');
      !ceeAddrInfoShow && ceeAddrInfo && set('ceeAddrInfo', '');
      !ceeComShow && ceeCom && set('ceeCom', '');
      !ceePhoneShow && ceePhone && set('ceePhone', '');
      !ceeIdNumShow && ceeIdNum && set('ceeIdNum', '');
      !ceeCustomerNoShow && ceeCustomerNo && set('ceeCustomerNo', '');
      !ceeIndustryShow && ceeIndustry && set('ceeIndustry', '');
      !ceeAddrRemarkShow && ceeAddrRemark && set('ceeAddrRemark', '');
      !ceeRemarkShow && ceeRemark && set('ceeRemark', '');
    };

    const handleDestination = poi => {
      console.log(state.addressNetworkPoint, 'ssss');
      if (!state.addressNetworkPoint) {
        const arrInfo = state.arrInfo || {};
        destination({ poi }).then(res => {
          if (res.res.list && res.res.list[0]) {
            page.set('fromGis', true);
            page.set('arrInfo', { ...arrInfo, ...res.res.list[0], ...{ address: true } });
          }
        });
      }
    };
    const selectSug = data => {
      if (data.address && data.address.poi) {
        handleDestination(data.address.poi);
      }
      store.dispatch({ type: SELECT_CEE_SUG, payload: { data } });
    };

    const clearAttachment = () => {
      // 手动改收货人清空附件
      if (ceeAttachmentShow) {
        set('ceeAttachment', []);
        set('matchedCeeAttachment', false);
      }
    };

    const setID = () => {
      const cardInfo = readIDFun();
      if (!cardInfo) return;
      set('ceeIdNum', cardInfo.id);
      ceeNameShow && set('ceeName', cardInfo.name);
      clearAttachment();
    };

    const setCeeName = val => {
      clearHiddenFields(val, ceeMobile);
      set('ceeName', val);
      clearAttachment();
    };

    const setCeeMobile = val => {
      clearHiddenFields(ceeName, val);
      set('ceeMobile', val);
    };

    const addressBlur = () => {
      if (ceeAddrInfo && !ceeAddrInfo.poi) {
        set('ceeAddrInfo', ceeAddrInfo);
      }
    };
    const setAddress = (type, val) => {
      handleDestination(val.poi);
      // set('addressNetworkPoint', false)
      set(type, val);
    };

    const onFileChange = files => {
      set('ceeAttachment', files);
      dataAnalyticTrack(['常发货物要求', '订单', '上传收货人附件']);
    };

    return (
      <div className={prefixCls}>
        <div>
          {ceeIsMonthShow && !!ceeIsMonth && <Icon iconType="icon-monthly-pass" tips="月结客户" />}
          <div>
            <Icon iconType="icon-record" />
            <p>收货方</p>
          </div>
        </div>
        <div>
          <div>
            {ceeCustomerNoShow && (
              <div>
                <label className={`fn-label${required(ceeCustomerNoRequired)}`}>{ot('收货客户编号', '客户编号')}</label>
                <DataList
                  data-path="cee_customer_no"
                  maxLength="30"
                  required={ceeCustomerNoRequired}
                  value={ceeCustomerNo}
                  data={ceeCustomerNoEnum}
                  disabled={!ceeCustomerNoEditable}
                  highlight="customer_no"
                  header={ceeHeader}
                  onChange={val => set('ceeCustomerNo', val)}
                  onSelect={selectSug}
                  filter={(keyword, select) => sug('ceeCustomerNo', 'cee_customer_no', keyword, select)}
                />
              </div>
            )}
          </div>
          <div>
            {ceeComShow && (
              <div>
                <label className={`fn-label${required(ceeComRequired)}`}>{ot('收货单位')}</label>
                <DataList
                  data-path="cee_com"
                  maxLength="30"
                  required={ceeComRequired}
                  value={ceeCom}
                  data={ceeComEnum}
                  disabled={!ceeComEditable || ceeIsProject}
                  highlight="customer_cname"
                  header={ceeHeader}
                  onChange={val => set('ceeCom', val)}
                  onSelect={selectSug}
                  filter={(keyword, select) => sug('ceeCom', 'cee_com', keyword, select)}
                  {...(ceeComEditable && ceeIsProject ? projectCeeTip : undefined)}
                />
              </div>
            )}
            {ceeNameShow && (
              <div>
                <label className={`fn-label${required(ceeNameRequired)}`}>{ot('收货人')}</label>
                <DataList
                  data-path="cee_name"
                  maxLength="20"
                  required={ceeNameRequired}
                  value={ceeName}
                  data={ceeNameEnum}
                  disabled={!ceeNameEditable}
                  highlight="name"
                  header={ceeHeader}
                  onChange={val => setCeeName(val)}
                  onSelect={selectSug}
                  filter={(keyword, select) => sug('ceeName', 'cee_name', keyword, select)}
                >
                  {ceeHistoryShow && (
                    <Tips className="input-tip" title="最近10次收货记录查询">
                      <i
                        className="fn-icon fn-icon-search"
                        data-path="cee_name_log"
                        onClick={() => showHistory('cee_name', store)}
                      />
                    </Tips>
                  )}
                </DataList>
              </div>
            )}
          </div>
          <div>
            {ceeMobileShow && (
              <div className="cee-mobile">
                <label className={`fn-label${required(ceeMobileRequired)}`}>{ot('收货人手机', '手机号码')}</label>
                {isDetail && ceeMobile && renderMsgIcon(origin, { key: 'cee_msg_info' })}
                <DataList
                  data-path="cee_mobile"
                  required={ceeMobileRequired}
                  maxLength="128"
                  pattern={validateCeeMobile ? MOBILE : undefined}
                  value={ceeMobile}
                  data={ceeMobileEnum}
                  disabled={!ceeMobileEditable}
                  highlight="telephone"
                  header={ceeHeader}
                  onChange={setCeeMobile}
                  onSelect={selectSug}
                  filter={(keyword, select) => sug('ceeMobile', 'cee_mobile', keyword, select)}
                />
              </div>
            )}
            {ceePhoneShow && (
              <div className="cee-phone">
                <label className={`fn-label${required(ceePhoneRequired)}`}>{ot('收货电话', '电话号码')}</label>
                {isDetail && !ceeMobile && ceePhone && renderMsgIcon(origin, { key: 'cee_msg_info' })}
                <DataList
                  data-path="cee_phone"
                  required={ceePhoneRequired}
                  maxLength="128"
                  value={ceePhone}
                  data={ceePhoneEnum}
                  disabled={!ceePhoneEditable}
                  highlight="phone"
                  compare="id"
                  header={ceeHeader}
                  format="phone"
                  map={false}
                  onChange={val => set('ceePhone', val)}
                  onSelect={selectSug}
                  filter={(keyword, select) => sug('ceePhone', 'cee_phone', keyword, select)}
                />
              </div>
            )}
          </div>
          <div>
            {ceeCateShow && (
              <div className="cee_cate">
                <label className={`fn-label${required(ceeCateRequired)}`}>{ot('收货客户类型', '客户类型')}</label>
                <Input data-path="cee_cate" required={ceeCateRequired} value={ceeCateStr} disabled={!ceeCateEditable} />
              </div>
            )}
            {ceeIndustryShow && (
              <div>
                <label className={`fn-label${required(ceeIndustryRequired)}`}>{ot('收货客户行业', '客户行业')}</label>
                <DataList
                  data-path="cee_industry"
                  maxLength="30"
                  required={ceeIndustryRequired}
                  value={ceeIndustry}
                  data={ceeIndustryEnum}
                  disabled={!ceeIndustryEditable}
                  highlight="industry"
                  header={ceeHeader}
                  onChange={val => set('ceeIndustry', val)}
                  onSelect={selectSug}
                  filter={(keyword, select) => sug('ceeIndustry', 'cee_industry', keyword, select)}
                />
              </div>
            )}
          </div>
          {ceeIdNumShow && (
            <div>
              <label className={`fn-label${required(ceeIdNumRequired)}`}>{ot('收货人身份证', '身份证号')}</label>
              <DataList
                data-path="cee_id_num"
                maxLength="18"
                required={ceeIdNumRequired}
                value={ceeIdNum}
                data={ceeIdNumEnum}
                disabled={!ceeIdNumEditable}
                header={ceeHeader}
                onChange={val => set('ceeIdNum', val)}
                onSelect={selectSug}
                filter={(keyword, select) => sug('ceeIdNum', 'cee_id_num', keyword, select)}
              >
                {ceeIdNumEditable && readIDCard && (
                  <Tips className="input-tip" title="点击读取身份证号">
                    <i className="fn-icon fn-icon-usid" onClick={setID} />
                  </Tips>
                )}
              </DataList>
            </div>
          )}

          {(ceeAddrInfoShow || ceeAddrRemarkShow || ceePickDistShow) && (
            <div>
              {!ceeAddrInfoShow && ceeAddrRemarkShow && !ceePickDistShow ? (
                <label className={`fn-label${required(ceeAddrRemarkRequired)}`}>{ot('收货地址备注', '地址备注')}</label>
              ) : (
                <label className={`fn-label${required(ceeAddrInfoRequired)}`}>{ot('收货地址')}</label>
              )}
              {(ceeAddrInfoShow || ceePickDistShow) &&
                (autoMatchGdAddr ? (
                  <Input
                    value={ceeAddrInfo?.show_val}
                    placeholder="请输入地址"
                    maxLength="30"
                    required={ceeAddrInfoRequired}
                    disabled={!ceeAddrInfoEditable}
                    data-path="cee_addr_info"
                    onChange={e => set('ceeAddrInfo', { show_val: e.target.value, poi: '1,1' })}
                  />
                ) : (
                  <Address
                    g7Map
                    data-path="cee_addr_info"
                    showTitleTips
                    required={ceeAddrInfoRequired}
                    needSelect={ceeAddressLock}
                    mode={ceeAddressType}
                    mapIcon={mapChoose}
                    fullName
                    value={ceeAddrInfo}
                    disabled={!ceeAddrInfoEditable}
                    onBlur={addressBlur}
                    onChange={val => merge('ceeAddrInfo', val)}
                    onSelect={val => setAddress('ceeAddrInfo', val)}
                  />
                ))}
              {ceeAddrRemarkShow && (
                <Input
                  data-path="cee_addr_remark"
                  showTitleTips
                  maxLength="30"
                  required={ceeAddrRemarkRequired}
                  className="address-remark"
                  placeholder={ot('收货地址备注', '地址备注')}
                  value={ceeAddrRemark || ''}
                  disabled={!ceeAddrRemarkEditable}
                  onChange={e => set('ceeAddrRemark', e.target.value)}
                />
              )}
              {ceePickDistShow && (
                <Input
                  data-path="cee_pick_dist"
                  className="pick-dist"
                  value={ceePickDist || ''}
                  disabled
                  onChange={e => set('ceePickDist', e.target.value)}
                />
              )}
            </div>
          )}
          {shudArrDateShow && (
            <div key="shudArrDate" className="x2">
              <label className={`fn-label${required(shudArrDateRequired)}`}>{ot('指定到货日期', '到货时间')}</label>
              <RangeTimePicker
                defaultValue={shudArrDate}
                disabled={!shudArrDateEditable}
                disabledDate={disabledDate}
                onChange={val => set('shudArrDate', val)}
                format="YY-MM-DD HH:mm:ss"
                showTime
                showExtFoot={false}
              />
            </div>
          )}
          {obPlanArrTShow && (
            <div>
              <AutoWrapLabel className={`fn-label${required(obPlanArrTRequired)}`} text={ot('预计到达时间')} />
              <DateTimePicker
                defaultValue={obPlanArrT}
                data-path="ob_plan_arr_t"
                showTime
                multiLine
                disabled={!obPlanArrTEditable}
                onChange={val => page.set('obPlanArrT', val)}
              />
            </div>
          )}
          {ceeAttachmentShow && (
            <div>
              <label className={`fn-label${required(ceeAttachmentRequired)}`}>{ot('收货人附件')}</label>
              <UploadFile
                className="cor-attachment"
                type="customer_goods"
                showType="link"
                fileList={ceeAttachment}
                disabled={!ceeAttachmentEditable || matchedCeeAttachment}
                onChange={fileList => onFileChange(fileList)}
                isOss
                displayName="hover"
                maxNum={10}
                sizeLimit={10}
              />
              <Icon
                iconType="icon-help"
                isHtmlTips
                tips="如果联系人维护了常发货物附件则选择货物后自动匹配带入附件 <br /> 如果联系人未维护常发货物附件信息，则支持人工上传"
              />
            </div>
          )}
          {ceeRemarkShow && (
            <div>
              <label className={`fn-label${required(ceeRemarkRequired)}`}>{ot('收货备注')}</label>
              <Input
                data-path="cee_remark"
                showTitleTips
                required={ceeRemarkRequired}
                title={ceeRemark}
                value={ceeRemark || ''}
                disabled={!ceeRemarkEditable}
                maxLength={500}
                onChange={e => set('ceeRemark', e.target.value)}
              />
            </div>
          )}
        </div>
      </div>
    );
  };
}

export default renderConsigneeTo;
