import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { Select, ModalDialog, Button, DataList, Label } from 'components';
import { validateFieldsOf, ot } from 'utils';
import { sugCorCee, formatSugData } from '../../person/tool';
import { SELECT_COR_SUG } from '../../constant';
import { formatVipSug } from './tool';

const style = { width: '560px', overflow: 'auto' };

class DistributeModal extends PureComponent {
  static propTypes = {
    close: PropTypes.func,
    page: PropTypes.object,
  };

  constructor(prop) {
    super(prop);
    const { page } = this.props;
    const { memberCode = {} } = page.store.getState();
    this.state = { data: this.dataInit(memberCode) };
    this.initMemberCode = memberCode.member_code;
  }
  dataInit = data => {
    const { bank_card_num, card_holder, co_delivery_mode, contact_phone, member_code, open_bank } = data;
    return {
      member_code,
      payment_mode: co_delivery_mode,
      bank_name: open_bank,
      account_holder: card_holder,
      bank_card: bank_card_num,
      account_holder_phone: contact_phone,
    };
  };
  set = (val, key) => {
    const { data } = this.state;
    !key && this.setState({ data: val });
    key && this.setState({ data: { ...data, [key]: val } });
  };

  setCoDeliveryMode = val => {
    this.setState({
      data: {
        payment_mode: val,
      },
    });
  };

  sug = (key, type, keyword, select) => {
    const { page } = this.props;
    const { store } = page;
    const state = store.getState();
    sugCorCee(true, type, keyword, state, select.fetch).then(res =>
      this.setState({ [`${key}Enum`]: formatSugData(res.res) }),
    );
  };

  sugVip = (key, type, keyword, select) => {
    const { isCMBCOrLSMember, basicSetting } = this.props.page.store.getState();
    if (!isCMBCOrLSMember) return this.sug(key, type, keyword, select);
    if (keyword.length < 6) return;
    const url = '/Table/Suggest/memberSug';
    const req = { type: basicSetting.member_code.checked === 'ls' ? 2 : 1, query: keyword };
    const conf = { method: 'POST', body: { req } };
    select
      .fetch(url, conf, 'json', { focus: `dg_${type}` })
      .then(res => this.setState({ memberCodeEnum: formatVipSug(res.res) }));
  };

  sugPerson = (keyword, select) => this.sug('cardHolder', 'card_holder', keyword, select);

  sugPhone = (keyword, select) => this.sug('contactPhone', 'contact_phone', keyword, select);

  sugID = (keyword, select) => this.sug('corIdNum', 'cor_id_num', keyword, select);

  sugBank = (keyword, select) => this.sug('bankCardNum', 'bank_card_num', keyword, select);
  header = [
    { key: 'member_code', title: ot('会员号') },
    { key: 'payment_mode', title: ot('发放方式') },
    { key: 'bank_name', title: ot('开户行') },
    { key: 'bank_card', title: ot('银行卡号') },
    { key: 'account_holder', title: ot('开户人') },
    { key: 'name', title: ot('发货人') },
    { key: 'telephone', title: ot('发货手机'), style: { minWidth: '130px', maxWidth: '130px' } },
    // { key: 'id_num', title: '身份证号' },
  ];

  confirm = async () => {
    const { page } = this.props;
    const { data } = this.state;
    const input = page.getEle('co_delivery');
    if (!(await validateFieldsOf(this.wrap))) return false;
    // 新选择的会员
    if (data.member_code && data.member_code !== this.initMemberCode) {
      page.store.dispatch({ type: SELECT_COR_SUG, payload: { data, key: 'memberCode' } });
    } else {
      // 手动输入的
      const { member_code, payment_mode, bank_name, account_holder, bank_card, account_holder_phone } = data;
      const memberCode = {
        member_code,
        co_delivery_mode: payment_mode,
        open_bank: bank_name,
        card_holder: account_holder,
        bank_card_num: bank_card,
        contact_phone: account_holder_phone,
      };
      page.set('memberCode', memberCode);
    }
    this.modal.handleHide();
    page.path && page.path.next(input, 39);
  };

  renderContent = () => {
    const { page } = this.props;
    const orderState = page.store.getState();
    const {
      memberCodeType,
      coDeliveryModeEnum,
      openBankEnum,
      coDelivery,
      setting,
      isCMBCOrLSMember,
      corHeaders,
      memberCodeEditable,
      coDeliveryModeEditable,
      cardHolderEditable,
      contactPhoneEditable,
      openBankEditable,
      bankCardNumEditable,
    } = orderState;
    const { data, memberCodeEnum, cardHolderEnum, contactPhoneEnum, corIdNumEnum, bankCardNumEnum } = this.state;
    const head = this.header;
    const disabled = !!data[memberCodeType];
    const required = key => +coDelivery > 0 && setting.co_delivery_required.value.includes(key);

    return (
      <div className="distribute-modal" ref={r => (this.wrap = r)}>
        <div>
          <Label>{ot('会员编号')}</Label>
          <Select
            value={data}
            data={memberCodeEnum}
            compare={memberCodeType}
            map={false}
            clear
            header={isCMBCOrLSMember ? corHeaders.member_code : head}
            format={memberCodeType}
            filter={(keyword, select) => this.sugVip('memberCode', 'member_code', keyword, select)}
            onChange={this.set}
            disabled={!memberCodeEditable}
          />
        </div>
        <div>
          <Label isRequired>{ot('发放方式')}</Label>
          <Select
            data={coDeliveryModeEnum}
            required
            onChange={this.setCoDeliveryMode}
            value={data.payment_mode}
            filter={false}
            disabled={!coDeliveryModeEditable || disabled}
          />
        </div>
        {data.co_delivery_mode === '现金' && (
          <Fragment>
            <div>
              <Label>{ot('联系人')}</Label>
              <DataList
                highlight="account_holder"
                value={data.account_holder}
                data={cardHolderEnum}
                header={head}
                filter={this.sugPerson}
                onChange={val => this.set(val, 'card_holder')}
                onSelect={this.set}
                disabled={!cardHolderEditable || disabled}
              />
            </div>
            <div>
              <Label>{ot('联系电话')}</Label>
              <DataList
                highlight="account_holder_phone"
                value={data.contact_phone}
                data={contactPhoneEnum}
                header={head}
                filter={this.sugPhone}
                onChange={val => this.set(val, 'contact_phone')}
                onSelect={this.set}
                disabled={disabled}
              />
            </div>
            <div>
              <Label>{ot('身份证')}</Label>
              <DataList
                highlight="id_num"
                value={data.id_num}
                data={corIdNumEnum}
                header={head}
                filter={this.sugID}
                onChange={val => this.set(val, 'id_num')}
                onSelect={this.set}
                disabled={disabled}
              />
            </div>
            {/* <div>
            <Label>{ot('会员电话')}</Label>
            <DataList highlight="shipper_phone" value={data.shipper_phone} data={sug} header={head} filter={this.sugPhone} onChange={val => this.set(val, 'shipper_phone')} onSelect={this.set} disabled={disabled} />
          </div> */}
          </Fragment>
        )}
        {data.co_delivery_mode !== '现金' && (
          <Fragment>
            <div>
              <Label isRequired={required('card_holder')}>{ot('开户人')}</Label>
              <DataList
                highlight="account_holder"
                value={data.account_holder}
                required={required('card_holder')}
                data={cardHolderEnum}
                header={head}
                filter={this.sugPerson}
                onChange={val => this.set(val, 'account_holder')}
                onSelect={this.set}
                disabled={!cardHolderEditable || disabled}
              />
            </div>
            <div>
              <Label isRequired={required('bank_card_num')}>{ot('银行卡号')}</Label>
              <DataList
                highlight="bank_card"
                value={data.bank_card}
                required={required('bank_card_num')}
                data={bankCardNumEnum}
                header={head}
                filter={this.sugBank}
                onChange={val => this.set(val, 'bank_card')}
                onSelect={this.set}
                disabled={!bankCardNumEditable || disabled}
              />
            </div>
            <div>
              <Label isRequired={required('open_bank')}>{ot('开户行')}</Label>
              <Select
                value={data.bank_name}
                required={required('open_bank')}
                data={openBankEnum}
                onChange={val => this.set(val, 'bank_name')}
                disabled={!openBankEditable || disabled}
              />
            </div>
            <div>
              <Label isRequired={required('contact_phone')}>{ot('开户电话')}</Label>
              <DataList
                highlight="account_holder_phone"
                value={data.account_holder_phone}
                required={required('contact_phone')}
                data={contactPhoneEnum}
                header={head}
                filter={this.sugPhone}
                onChange={val => this.set(val, 'account_holder_phone')}
                onSelect={this.set}
                disabled={!contactPhoneEditable || disabled}
              />
            </div>
            {/* <div>
            <Label>{ot('会员电话')}</Label>
            <DataList highlight="shipper_phone" value={data.shipper_phone} data={sug} header={head} filter={this.sugPhone} onChange={val => this.set(val, 'shipper_phone')} onSelect={this.set} disabled={disabled} />
          </div> */}
          </Fragment>
        )}
      </div>
    );
  };

  bottom = (
    <Fragment>
      <Button key="1" type="primary" onClick={this.confirm}>
        确定
      </Button>
      <Button key="2" onClick={() => this.modal.handleHide()}>
        取消
      </Button>
    </Fragment>
  );

  render() {
    return (
      <ModalDialog
        ref={r => (this.modal = r)}
        content={this.renderContent()}
        bottom={this.bottom}
        isShow
        isModal
        close={this.props.close}
        title="代收发放"
        popName="distributeModal"
        contentStyle={style}
      />
    );
  }
}

export default DistributeModal;
