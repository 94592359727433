exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".split-reservation-manual{padding:10px}.split-reservation-manual .split-tip{font-size:14px;color:#f37100;margin-bottom:10px;margin-left:9px}.split-reservation-manual .fn-radio__w{display:inline}.split-reservation-manual .fn-radio{font-size:14px;margin-right:20px}.split-reservation-manual .fn-label{font-size:14px;margin-left:9px}.split-reservation-manual .fn-table-a{margin-top:14px}.split-reservation-manual .fn-table-a td{padding:1px}.split-reservation-manual .fn-input-pure-wrap{width:100%;height:25px}.split-reservation-manual .fn-input-pure{text-align:center;background:#ffefcb;border:1px solid #ffb32b}.split-reservation-manual .fn-input-pure:disabled{background:#f2f9ff;border:0}", ""]);

// exports
exports.locals = {
	"prefixCls": "split-reservation-manual",
	"split-reservation-manual": "split-reservation-manual",
	"split-tip": "split-tip",
	"fn-radio__w": "fn-radio__w",
	"fn-radio": "fn-radio",
	"fn-label": "fn-label",
	"fn-table-a": "fn-table-a",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"fn-input-pure": "fn-input-pure"
};