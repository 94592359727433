/**
 * Created by wangnaihe on 2017/6/19.
 */
import React from 'react';
import { ot } from 'utils';
import { getProfitText } from './tool';
import { moveKeys } from '../constant';
import { prefixCls } from './index.scss';

const renderProfitRateDenominator = (totalPrice, ext) => (
  <span>
    {ot('合计运费')}
    <i> ¥ {totalPrice}</i>
    {Object.entries(moveKeys)
      .filter(([k]) => ext.profit[k])
      .map(([k, t]) => (
        <span key={k}>
          + {t}
          <i> ¥ {ext.profit[k]}</i>
        </span>
      ))}
  </span>
);

function renderProfit(page) {
  return function () {
    const { store } = page;
    const state = store.getState();
    const { totalPrice, profit, profitShow, profitRate, ext } = state;
    if (page.props.usedFor !== 'detail' || !profitShow) return null;

    return (
      <table className={prefixCls}>
        <tbody>
          <tr>
            <td>
              {ot('单票毛利')}
              <i>¥ {profit}</i>
            </td>
            <td>=&nbsp;{getProfitText(state)}</td>
          </tr>
          <tr>
            <td>
              {ot('毛利率')}:<i>{profitRate}%</i>
            </td>
            <td>
              =&nbsp;({ot('单票毛利')}
              <i> ¥ {profit}</i>) / ({renderProfitRateDenominator(totalPrice, ext)}) x 100%
            </td>
          </tr>
        </tbody>
      </table>
    );
  };
}

export default renderProfit;
