// import emitter from 'utils/emitter'

import React from 'react';
import _ from 'lodash';
import { typeIs, fetchApi, showInfo, alert } from 'utils';
import { PopUp, PopTip } from 'components';
import browserHistory from 'routes/history';
import { INFO, ERROR } from 'constants';
import { AsyncOrder } from 'pages/OrderController/coInfo/dynamicImport';
import { openOrderDetail } from 'utils/business/order/direct';
import { directToTaskDetail } from 'utils/business/task/direct';
import { batchView } from './truckBatchOp';

const checkSelected = function (selected, tips = '请选择操作项', one) {
  // one 只能操作一条记录的 设为true
  if (!selected || selected.length <= 0) {
    /*
    showDialogTips(INFO, tips)
    */
    new PopUp(PopTip, {
      classname: 'pop_tip',
      type: INFO,
      isShow: true,
      content: tips,
      autoDestroy: true,
    }).show();
    return false;
  } else if (one && selected.length > 1) {
    /*
    showDialogTips(INFO, tips) // '只能操作一个项'
    */
    new PopUp(PopTip, {
      classname: 'pop_tip',
      type: INFO,
      isShow: true,
      content: tips,
      autoDestroy: true,
    }).show();
    return false;
  }
  return true;
};
/**
 * 网点中转处理
 * @param that
 * @param odLinkId
 * @param odBasicId
 * @param transInBatchId
 * @returns {Promise.<void>}
 */
const pointTrans = function (that, odLinkId, odBasicId, transInBatchId) {
  const url = '/Order/Trans/pointTrans/';
  const req = { od_link_id: odLinkId };
  const conf = { method: 'POST', body: { req, from: 'pc' } };
  const checkPointTrans = fetchApi(url, conf);
  checkPointTrans.then(res => {
    if (res.errno !== 0) {
      showInfo(ERROR, res.errmsg);
    } else {
      new PopUp(AsyncOrder, {
        popName: 'pointTrans',
        isWhere: 'pointTransInfo',
        showType: 'Slider',
        oinfo_id: odLinkId,
        od_basic_id: odBasicId,
        b_inner_trans_in_batch_id: transInBatchId,
        refreshTab: () => setTimeout(that.handleRefreshData, 2000),
      }).show();
    }
  });
};

// 查看运单详情
export const showOinfo = function (that, rowIndex, column, dataArr, obj, isDown = false, cb, activeTab) {
  const { category, tab } = that.tableInfo || {};
  // 批次详情，运单列可能有打包单的数量,跳转到打包单详情
  const packTypeArr = ['30', '31'];
  if (dataArr.b_link_id && dataArr.type && packTypeArr.includes(dataArr.type)) {
    batchView(that, rowIndex, column, dataArr, obj, false, cb, activeTab);
    return;
  }
  if (category === 'Reservation' && column === 'order_num' && dataArr.business_type === '整车订单') {
    directToTaskDetail({
      from: 'ReservationList',
      batchID: dataArr.od_basic_id[0],
      carBatch: dataArr.order_num[0],
      comID: _.get(dataArr, 'od_link_id[0]'),
    });
    return;
  }
  // 系统日志页 不可操作运单
  if (dataArr && dataArr.num_msg) {
    showInfo(ERROR, dataArr.num_msg);
    return;
  }
  const dataList = that ? that.getTableList([rowIndex]) : dataArr;
  if (dataList.length === 0) return false;
  let odLinkId;
  let odBasicId;
  let transInBatchId;

  if (dataList[0].extra && dataList[0].extra.od_link_id) {
    const extradata = dataList[0].extra;
    odLinkId = extradata.od_link_id;
    odBasicId = extradata.od_basic_id;
    transInBatchId = extradata.b_inner_trans_in_batch_id;
  } else {
    odLinkId = dataList[0].od_link_id || dataList[0]['Order|od_link_id'];
    odBasicId = dataList[0].od_basic_id || dataList[0]['Order|od_basic_id'];
    transInBatchId = dataList[0].b_inner_trans_in_batch_id || dataList[0]['Order|b_inner_trans_in_batch_id'];
    // 是下游的情况下，打开详情页需要用next_od_link_id
    isDown && +dataList[0].next_od_link_id !== 0 && (odLinkId = dataList[0].next_od_link_id);
  }
  // 运单信息
  let orderNum = dataList[0].order_num || dataList[0]['Order|order_num'];
  // 详情页打开方式(系统设置中设置， 侧拉打开Or新的Tab页面打开)
  // 网点中转待处理运单(未接收的运单的展示和正常详情页不一致)
  if (that.tableInfo && that.tableInfo.category === 'Order' && that.tableInfo.tab === 'trans_inner_to_deal') {
    const orderFrom = dataList[0].order_from_ext;
    if (orderFrom === '1') {
      pointTrans(that, odLinkId, odBasicId, transInBatchId);
      return;
    }
  }
  // 只有装车清单需要此参数
  const { company_id: comId } = (isDown && that.batchInfo) || {};
  let from = `${category}_${tab}`;
  if (window.LTAB) {
    from = `${category}_${window.LTAB}`;
  }
  // 为了兼容 linkcell 内是个数组的情况（订单列表关联的运单号）
  if (typeIs(odLinkId, 'array')) {
    const index = orderNum.findIndex(item => item === obj);
    if (index < 0) {
      return;
    }
    orderNum = orderNum[index];
    odLinkId = odLinkId[index];
    odBasicId = odBasicId[index];
  }
  openOrderDetail({ from, orderNum, odBasicId, odLinkId, transInBatchId, activeTab, comId });
};

// 交易记录 详情 分别跳转运单详情、批次详情
export const showOinfo_ = function (that, rowIndex, column, dataArr, obj, isDown = false, tableInfoObj) {
  const newDataArr = Object.assign(dataArr, dataArr.conn_info);
  if (newDataArr.od_link_id) {
    showOinfo(that, rowIndex, column, newDataArr, obj, isDown);
  } else if (newDataArr.b_link_id) {
    batchView(that, rowIndex, column, newDataArr, undefined, tableInfoObj);
  }
};
// 创建运单
export const createOrder = function (that) {
  browserHistory.pushWithQuery('/Order/coInfo');
  console.log('createOrder', that);
};
// 补单
export const supplementOrder = function (that, trIndex, ...rest) {
  const odLinkIdList = that.getSelectesKey('od_link_id');
  if (!checkSelected(odLinkIdList, '请选择一条待补录运单', true)) return;
  const orderNumPre = that.getSelectesKey('order_num')[0];
  browserHistory.pushWithQuery({
    pathname: '/Order/coInfo',
    query: {
      isWhere: 'updatePre',
      tabName: '补录运单',
      orderNumPre,
      orderLinkIdPre: odLinkIdList[0],
    },
  });
  console.log(rest, trIndex, 'supplementOrder', that);
};
// 改单
export const orderModify = function (that, rowIndex) {
  let rowData = {};
  let modifyOrderNum = [];
  let odBasicId = ''; // CYTRD-3799 需要传入运单 id
  const onlyModifySelfOrder =
    window.company_setting.only_modify_oneself_order && window.company_setting.only_modify_oneself_order.checked;
  // 事件触发来自表格页
  if (typeIs(rowIndex, 'number')) {
    rowData = that.getData()[rowIndex] || {};
    modifyOrderNum = [rowData.order_num];
    odBasicId = rowData?.od_basic_id || '';
  } else {
    // 事件触发来自非表格页
    modifyOrderNum = that.getSelectesKey('order_num') || [];
    odBasicId = that.getSelectesKey('od_basic_id')?.[0];
  }
  if (!checkSelected([modifyOrderNum], true)) return;
  if (onlyModifySelfOrder && (+rowData.order_creator !== +window.user_id || +rowData.order_up !== 1)) {
    const tips = (
      <p>
        <h3 style={{ paddingBottom: '10px' }}>这不是您本人开的运单，您无权修改。</h3>
        <small className="text-gray">如需改单，请联系制单员修改运单。</small>
      </p>
    );
    alert(INFO, tips);
    return;
  }
  browserHistory.pushWithQuery({
    pathname: '/Order/coInfo',
    query: {
      isWhere: 'modify',
      modifyOrderNum: encodeURIComponent(modifyOrderNum[0]),
      tabName: '申请改单',
      modifyFrom: 'list',
      refer: that.isDetailProxyListPage ? 'orderDetail' : 'orderList',
      odBasicId,
    },
  });
};

// 复制
export const orderCopy = function (that, rowIndex) {
  let rowData = {};
  let copyOrderNum = [];
  let copyFrom = 'list';
  if (typeIs(rowIndex, 'number')) {
    // 事件触发来自表格页
    rowData = that.getData()[rowIndex] || {};
    copyOrderNum = [rowData.order_num];
  } else {
    // 事件触发来自非表格页
    copyOrderNum = that.getSelectesKey('order_num') || [];
    copyFrom = 'oinfo';
  }
  // 跳转
  browserHistory.pushWithQuery({
    pathname: '/Order/coInfo',
    query: {
      isWhere: 'copy',
      copyOrderNum: encodeURIComponent(copyOrderNum[0]),
      tabName: '复制订单',
      copyFrom,
      refer: that.isDetailProxyListPage ? 'orderDetail' : 'orderList',
    },
  });
};

// 修改预开单
export const modifyPre = async (listPage, btnKey, rowIndex, column, selectedBtn) => {
  // console.log('modifyPre', listPage, btnKey, rowIndex, column, selectedBtn)

  const rowData = listPage.getData()[rowIndex] || {};
  const od_link_id = rowData.od_link_id || '';
  const modifyOrderNum = rowData.order_num || '';

  // 修改前校验
  const url = '/Order/OrderModify/modifyPre';
  const req = { od_link_id, is_check: true };
  const conf = { method: 'POST', body: { req, from: 'pc' } };
  const fetchRes = await fetchApi(url, conf); // infoRes
  if (+fetchRes.errno !== 0) {
    alert(ERROR, fetchRes.errmsg);
    return false;
  }

  const modifyFrom = listPage.initTable ? 'list' : 'detail';
  // listPage.closeTab && listPage.closeTab()
  modifyFrom === 'detail' && listPage.refreshTab && listPage.refreshTab();

  // console.log('modifyPre rowData', rowData)
  const coPreType = +rowData.co_pre_type; // 1-->空白单 2-->预打标签
  browserHistory.pushWithQuery({
    pathname: '/Order/coInfo',
    query: {
      isWhere: 'pre',
      tabName: '预开单',
      modifyFrom,
      modifyOrderNum,
      orderLinkIdPre: od_link_id,
      activeTab: coPreType === 2 ? 'pre_order_tags' : 'pre_order_empty',
    },
  });
};

// CMTX-8953 订单列表显示运单号、运单列表显示订单号 -徐文斌
export const waybillOrderView = (that, index, buttonKey, data, linkItem) => {
  const { category, tab } = that.tableInfo || {};
  // 根据原linkItem值查询到索引，然后对应服务端参数数组取值
  const bPickupBatchList = data?.relation_order_num || [];
  const linkIndex = bPickupBatchList.indexOf(linkItem);

  const linkData = data?.relation_order_num_para?.[linkIndex] || {};
  const { od_link_id: odLinkId } = linkData;
  const orderNum = bPickupBatchList[linkIndex];
  const from = `${category}_${tab}`;
  openOrderDetail({ from, orderNum, odLinkId });
};

export function init(emt) {
  // 创建运单
  emt.on('createOrder', createOrder);
  // 补单
  emt.on('supplementOrder', supplementOrder);
  // 运单列表行内按钮改单
  emt.on('orderOrderModify', orderModify);
  // 改单
  emt.on('orderModify', orderModify);
  // 复制
  emt.on('orderOrderCopy', orderCopy); // 列表内事件
  emt.on('orderCopy', orderCopy); // 详情页事件
  // 修改预开单
  emt.on('modifyPre', modifyPre);
  // 查看运单详情[列操作]
  emt.on('orderOrderNum', showOinfo);
  emt.on('orderSubOrderNum', showOinfo);
  emt.on('orderBPackBatch', (that, index, buttonKey, newBatchData, linkItem, tableInfoObj) => {
    batchView(that, index, buttonKey, newBatchData, linkItem, false);
  });
  emt.on('orderOrderSt', (that, rowIndex, column, dataArr, obj) =>
    showOinfo(that, rowIndex, column, dataArr, obj, false, undefined, '2'),
  );
  emt.on('settleOrder|orderNum', showOinfo); // 资金流水明细里运单号
  // 查看运单详情
  emt.on('logOrderNum', showOinfo);
  emt.on('accountToolOrderNum', showOinfo);
  // 查看运单详情
  emt.on('messageOrderNum', showOinfo);
  // 查看运单详情
  emt.on('reservationOrderNum', showOinfo);
  emt.on('settleRecordOrderNum', (that, trIndex, ...rest) => {
    const tableData = (that.getData ? that.getData()[trIndex] : that.getTableData().data[trIndex]) || {};
    if (!tableData.od_link_id) {
      return false;
    }
    showOinfo(that, trIndex, ...rest);
  });
  emt.on('settleOrderNum', (that, trIndex, ...rest) => {
    const tableData = that.getData()[trIndex] || {};
    if (!tableData.od_link_id) {
      return false;
    }
    showOinfo(that, trIndex, ...rest);
  });
  emt.on('orderModifyOrderNum', showOinfo);
  emt.on('acApplyOrderOrder|orderNum', showOinfo);
  emt.on('acApplyDailySettle|orderNum', showOinfo);
  // 保险 我的保单 查看运单详情
  emt.on('insuranceOrderNum', showOinfo);
  // 交易记录详情跳运单详情
  emt.on('tradeRecordTrDetail', showOinfo_);
  emt.on('lossOrder|orderNum', showOinfo);
  // 1.0运单跳转到订单详情
  emt.on('batchRelationOrderNum', waybillOrderView);
  // 在途费用明细跳转订单详情
  emt.on('batchFeeApprovalRelationOrderNum', waybillOrderView);
}
