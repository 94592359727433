exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".check-msg-tpl .fn-show_info__text_con_notice{padding-left:67px;padding-top:10px;color:#888;max-width:462px;max-height:100px;overflow-y:auto}.msg_tpl_stop .notice_title{margin-left:43px;position:relative;bottom:10px}.msg_tpl_stop .notice_title span{color:#333!important}.msg_tpl_stop .detail-content .notice_list{margin-left:10px}", ""]);

// exports
exports.locals = {
	"check-msg-tpl": "check-msg-tpl",
	"fn-show_info__text_con_notice": "fn-show_info__text_con_notice",
	"msg_tpl_stop": "msg_tpl_stop",
	"notice_title": "notice_title",
	"detail-content": "detail-content",
	"notice_list": "notice_list"
};