import React, { Component } from 'react';
import moment from 'moment';
import { PopUp, ShowInfo, PopTip, Label, Input, CheckBox, TextArea, ModalDialog, Button, Icon } from 'components';
import { INFO, ERROR, CHECK, WARN } from 'constants';
import {
  postData as gpostData,
  createTip,
  getUuid,
  opTips,
  showInfo,
  viewImage,
  confirm,
  copyConfirm,
  fetchApi as fetch,
  fetchJAVA,
} from 'utils';
import viewProject from 'utils/business/project/viewProject';
import addCustomer, { addPlateform } from 'utils/business/customerPf/addCustomer';
import actions from 'actions';
import CustomerOp from 'components/commoncomponents/CustomerPfOp';
import Project from 'pages/ProjectController/Entity';
import CustomerOperat from 'operation/listOperation/CustomerOperat';
import ProjDialog from 'pages/ProjectController/dialog';
import ProjRecordOp from 'pages/ProjectController/projRecord';
// import ProjectCustomerOp from 'operation/listOperation/ProjectCustomerOp'
import typeSet from 'operation/listOperation/CustomerOperat/typeSet.js';
import RelatePoint from 'operation/listOperation/ceeRelatePoint';

import './customerPfOp.scss';

import { errmsgTip } from './newTips';

const addCorCee = function (that, type) {
  let _tab = 'cor';
  if (type === 'add_cee') {
    _tab = 'cee';
  }
  const pageKey = getUuid();
  const _req = {
    pageKey,
    customer_info: {
      _id: -1,
    },
    data: {
      customer_id: -1,
    },
    op_type: 'add',
    tab: _tab,
  };
  console.log('addCorCee =', type, _tab);
  const titleName = type === 'add_cor' ? '新增发货人' : '新增收货人';
  actions.companyServer.postCustomerMangerInfo(_req).then(res => {
    if (res.type === 'API_SUCCESS') {
      if (res.data.errno === 0) {
        new PopUp(CustomerOperat, {
          pageKey,
          parm: {
            titleName,
            opType: 'add',
            dragerContainer: that,
            customer_id: -1,
            tab: _tab,
            that,
          },
          popName: `operatSlideDrag_${pageKey}`,
        }).show();
      } else {
        errmsgTip(res.data.errmsg);
      }
    } else {
      createTip('网络错误！', ERROR).show();
    }
  });
};
const updateCorCee = function (that, type, isBatch) {
  let _tab = 'cor';
  if (type === 'update_cee') {
    _tab = 'cee';
  }
  const opType = isBatch ? 'update_batch' : 'update';
  // eslint-disable-next-line no-nested-ternary
  const titleName = isBatch
    ? _tab === 'cor'
      ? '批量修改发货人'
      : '批量修改收货人'
    : _tab === 'cor'
    ? '修改发货人'
    : '修改收货人';
  const tip = isBatch && '以下数据只有编辑过的，才会更新客户信息；未编辑过的字段，不会更新原客户信息。';
  const _comIds = that.vTable.getStateSelectes();
  if (_comIds.length < 1) {
    errmsgTip('请选择要修改的客户！');
    return false;
  } else if (_comIds.length > 1 && !isBatch) {
    errmsgTip('只能选择一条信息！');
    return false;
  }
  const table = that.getData();
  const drComId = isBatch ? _comIds.map(item => table[item].id) : table[_comIds[0]].id;
  const pageKey = getUuid();
  const _req = {
    pageKey,
    customer_info: {
      customer_id: drComId,
    },
    data: {
      customer_id: drComId,
    },
    customer_id: drComId,
    op_type: opType,
    tab: _tab,
  };
  actions.companyServer.postCustomerMangerInfo(_req).then(res => {
    if (res.type === 'API_SUCCESS') {
      if (res.data.errno === 0) {
        const showPopUp = (ids = drComId) => {
          new PopUp(CustomerOperat, {
            pageKey,
            parm: {
              isShow: true,
              pageType: 'customerMangerList',
              opType,
              titleName,
              dragerContainer: that,
              // customer_id: isBatch ? _comIds.map(item => table[item].id) : drComId,
              customer_id: ids,
              tab: _tab,
              that,
              tip,
            },
            popName: `operatSlideDrag_${pageKey}`,
          }).show();
        };
        if (isBatch) {
          return opTips({
            resData: res.data,
            opType: '修改',
            orderType: '客户',
            showSucTips: false,
            sucCallback: () => {
              showPopUp(drComId);
            },
            continueCallback: (sucId, cb) => {
              cb();
              showPopUp(sucId);
            },
          });
        }
        showPopUp(drComId);
      } else {
        errmsgTip(res.data.errmsg);
      }
    } else {
      errmsgTip(res.data.errmsg);
      return false;
    }
  });
};
export const customerName = function (that, type) {
  const _tab = that.getReq().tab || 'cor';
  const table = that.getData();
  const drComId = table[type].id;
  const pageKey = getUuid();
  const _req = {
    pageKey,
    customer_info: {
      customer_id: drComId,
    },
    data: {
      customer_id: drComId,
    },
    customer_id: drComId,
    op_type: 'view',
    tab: _tab,
  };
  const titleName = _tab === 'cor' ? '查看发货人' : '查看收货人';
  actions.companyServer.postCustomerMangerInfo(_req).then(res => {
    if (res.type === 'API_SUCCESS') {
      if (res.data.errno === 0) {
        new PopUp(CustomerOperat, {
          pageKey,
          parm: {
            isShow: true,
            pageType: 'customerMangerList',
            opType: 'view',
            titleName,
            dragerContainer: that,
            customer_id: drComId,
            tab: _tab,
            that,
          },
          popName: `operatSlideDrag_${pageKey}`,
        }).show();
      } else {
        errmsgTip(res.data.errmsg);
      }
    } else {
      createTip('网络错误！', ERROR).show();
    }
  });
};
const deleteCorCee = function (that, type) {
  const _that = that;
  let _tab = 'cor';
  if (type === 'delete_cee') {
    _tab = 'cee';
  }
  const _comIds = that.vTable.getStateSelectes();
  if (_comIds.length < 1) {
    createTip('请选择要删除的客户！', ERROR).show();
    return false;
  }
  const table = that.getData();
  const _drComIds = [];
  for (const val of _comIds) {
    _drComIds.push(table[val].id);
  }
  let _req = {
    data: {
      customer_id: _drComIds,
    },
    opType: 'delete',
    customer_id: _drComIds,
    tab: _tab,
    is_check: 1,
  };
  const popup = new PopUp(ShowInfo, {
    classname: 'del-customer',
    isShow: true,
    type: WARN,
    content: '您确定删除吗？',
    autoDestroy: true,
    title: '删除',
    simpleMode: {
      confirm: thisDialog => {
        thisDialog.changeBtnLoadingStatus('confirm', true);
        actions.companyServer
          .postCustomerMangerAM(_req)
          .then(res => {
            opTips({
              resData: res.data,
              opType: '删除',
              tips: '删除',
              orderType: '客户',
              sucCallback() {
                thisDialog.handleHide();
                setTimeout(() => {
                  _that.handleRefreshData();
                }, 1500);
              },
              ext: {
                failedCallback() {
                  thisDialog.handleHide();
                },
              },
              continueCallback(sucId, cb) {
                cb();
                _req = {
                  data: {
                    customer_id: sucId,
                  },
                  opType: 'delete',
                  customer_id: sucId,
                  tab: _tab,
                };
                actions.companyServer.postCustomerMangerAM(_req).then(res2 => {
                  thisDialog.handleHide();
                  if (res2.type === 'API_SUCCESS' && res2.data.errno === 0) {
                    createTip('删除成功！', INFO).show();
                    setTimeout(() => {
                      _that.handleRefreshData();
                    }, 1500);
                  } else {
                    createTip(res2.data.errmsg, ERROR).show();
                    thisDialog.changeBtnLoadingStatus('confirm', false);
                  }
                });
              },
            });
          })
          .catch(err => {
            thisDialog.changeBtnLoadingStatus('confirm', false);
            console.log(err);
          });
      },
      cancel: thisDialog => thisDialog.handleHide(),
    },
    closable: true,
    maskClosable: true,
  });
  popup.show();
};
const setting = function (that, type) {
  let _tab = 'cor';
  if (type === 'setting_cee') {
    _tab = 'cee';
  }
  const _req = {
    type: _tab,
  };
  that.disableMenuButton(type);
  actions.companyServer.customerTypeList(_req).then(res => {
    that.enableMenuButton(type);
    if (res.type === 'API_SUCCESS') {
      if (res.data.errno === 0) {
        const parm = {
          that,
          isShow: true,
          type: _tab,
          res: res.data.res,
          popName: 'customerSetPopUp',
        };
        const dialog = new PopUp(typeSet, parm);
        dialog.show();
      } else {
        errmsgTip(res.data.errmsg);
      }
    } else {
      createTip('请重新请求类型设置', ERROR).show();
    }
  });
};

function showPopTip(type, content, callback) {
  new PopUp(PopTip, {
    type,
    content,
    onClose: () => {
      if (typeof callback === 'function') callback();
    },
  }).show();
}

function checkIds(ids, tips) {
  if (ids.length <= 0) {
    showPopTip(ERROR, tips);
    return false;
  }
  return true;
}

function showUnQueyerPop(that, ids, cunt) {
  const _that = that;
  const _this = {};
  const _sameCount = cunt;
  const customerIds = ids;
  const title = '确认删除解绑查单人？解绑后，查单人将不允许查看发货人的运单';
  let noticeHtml = '';
  if (Number(_sameCount) > 0) {
    const tips = (
      <span>
        还有<em className="red">{_sameCount}名同名发货客户</em>，是同时否解绑？
      </span>
    );
    noticeHtml = (
      <div className="queryer-content">
        <p className="checkLabel">
          <CheckBox label={tips} direction="right" ref={r => (_this.sameCheck = r)} checked />
        </p>
      </div>
    );
  }
  const btnS = {
    confirm: thisDialog => {
      thisDialog.changeBtnLoadingStatus('confirm', true);
      const _sanCheck = _this.sameCheck && _this.sameCheck.state.checked ? 2 : 1; // 不添加 1 2：添加
      const parm = {
        customer_ids: customerIds,
        del_same_customer: _sanCheck,
      };
      const url = 'Basic/Customer/delQueryer';
      gpostData(url, parm, res => {
        if (res.data && res.data.errno === 0) {
          createTip('成功！', INFO).show();
          thisDialog.handleHide();
          _that.handleRefreshData();
        } else {
          thisDialog.changeBtnLoadingStatus('confirm', false);
          createTip(res.data.errmsg, ERROR).show();
        }
      });
    },
    cancel: thisDialog => thisDialog.handleHide(),
  };
  const popup = new PopUp(ShowInfo, {
    classname: 'queryer-op1',
    isShow: true,
    type: WARN,
    content: title,
    contentNotice: noticeHtml,
    autoDestroy: true,
    title: '解绑查单人',
    simpleMode: btnS,
    closable: true,
    maskClosable: true,
  });
  popup.show();
}

const delQueryer = function (that, type) {
  const _that = that;
  const customerIds = that.getSelectesKey('customer_id');
  if (!checkIds(customerIds, '请选择要解绑的客户！')) {
    return;
  }
  const parm = {
    customer_ids: customerIds,
    isCheck: 1,
  };
  that.disableMenuButton(type);
  gpostData('Basic/Customer/delQueryer', parm, res => {
    that.enableMenuButton(type);
    if (res.data.errno === 0) {
      // 正常
      showUnQueyerPop(_that, customerIds, 0);
    } else if (res.data.errno === -1600) {
      // 需要提示
      const _data = res.data.res;
      showUnQueyerPop(_that, customerIds, _data.sameCount || -1);
    } else {
      showInfo(ERROR, res.data.errmsg, true, undefined, false);
    }
  });
};

function showQueyerPop(that, ids, cunt) {
  const _that = that;
  const _this = {};
  let _check = '';
  if (cunt > 0) {
    const _tips = (
      <span>
        还有<em className="red">{cunt}名同名发货客户</em>，是否绑定相同查单人？
      </span>
    );
    _check = (
      <div className="checkLabel">
        <CheckBox label={_tips} direction="right" ref={r => (_this.sameCheck = r)} checked />
      </div>
    );
  }
  const title = '绑定后，查单人可在微信公众号查看该发货人在本公司发货运单及运单跟踪情况';
  const noticeHtml = (
    <div className="queryer-content">
      <div className="item phone">
        <Label isRequired showSpark>
          <span>查单人手机号</span>
        </Label>
        <Input ref={r => (_this.phone = r)} />
      </div>
      <div className="item name">
        <Label>
          <span>查单人姓名</span>
        </Label>
        <Input ref={r => (_this.named = r)} />
      </div>
      {_check}
    </div>
  );
  const popup = new PopUp(ShowInfo, {
    classname: 'queryer-op1',
    isShow: true,
    type: WARN,
    content: title,
    contentNotice: noticeHtml,
    autoDestroy: true,
    title: '绑定查单人',
    simpleMode: {
      confirm: thisDialog => {
        const _phone = _this.phone.getValue();
        const _named = _this.named.getValue();
        const _sameCostomer = _this.sameCheck && _this.sameCheck.state.checked ? 2 : 1;
        if (!_phone) {
          createTip('请输入查单人手机号！', ERROR).show();
          return false;
        }
        const parm = {
          customer_ids: ids, // 发货客户id
          queryer_name: _named, // 查单人姓名
          queryer_phone: _phone, // 查单人手机号
          add_same_customer: _sameCostomer, // 是否绑定其他同客户 0：默认值  1：不添加 2：添加
        };
        thisDialog.changeBtnLoadingStatus('confirm', true);
        gpostData('Basic/Customer/addQueryer', parm, res => {
          if (res.data && res.data.errno === 0) {
            createTip('绑定成功！', CHECK).show();
            thisDialog.handleHide();
            _that.handleRefreshData();
          } else {
            thisDialog.changeBtnLoadingStatus('confirm', false);
            createTip(res.data.errmsg, ERROR).show();
          }
          console.log(thisDialog);
        });
      },
      cancel: thisDialog => thisDialog.handleHide(),
    },
    closable: true,
    maskClosable: true,
  });
  popup.show();
}

const addQueryerFn = function (that, type) {
  const _that = that;
  const customerIds = that.getSelectesKey('customer_id');
  if (!checkIds(customerIds, '请选择要绑定的客户！')) {
    return;
  }
  const parm = {
    customer_ids: customerIds,
    isCheck: 1,
  };
  // const allLen = customerIds.length
  that.disableMenuButton(type);
  gpostData('Basic/Customer/addQueryer', parm, res => {
    that.enableMenuButton(type);
    const _data = res.data.res;
    const _sameCount = _data.sameCount || -1;
    if (res.data.success_id && res.data.success_id.length) {
      res.data.errno = 0;
    }
    _data &&
      (res.data.res.ext = {
        noSplice: true,
      });
    opTips({
      resData: res.data,
      opType: '绑定查单人',
      orderType: '客户',
      continueCallback(sucId, cb) {
        cb();
        showQueyerPop(_that, sucId, _sameCount);
      },
      sucCallback() {
        showQueyerPop(_that, customerIds, _sameCount);
      },
      showSucTips: false,
    });
  });
};

const relatePoint = function (that, type) {
  console.log(type);
  const data = that.getSelectesKey(['id', 'telephone', 'name', 'company_id', 're_arr_point']);
  if (data.length === 1) {
    data[0].company_name = (that.getEnumItem('company_id', data[0].company_id)[0] || {}).short_name;
    data[0].re_arr_point_name = (
      that.getEnumItem('re_arr_point', data[0].re_arr_point, false, { uniqueKey: 'id' })[0] || {}
    ).short_name;
  }
  new PopUp(RelatePoint, { data, refresh: that.handleRefreshData }).show();
};

// const addProjCustomer = function (that, type) {
//   const pageKey = getUuid()
//   const _req = {
//     pageKey,
//     customer_info: {
//       _id: -1,
//     },
//     data: {
//       customer_id: -1,
//     }
//   }
//   actions.companyServer.postProjCustomerMangerInfo(_req).then(res => {
//     console.log(res)
//     if (res.type === 'API_SUCCESS') {
//       if (res.data.errno === 0) {
//         new PopUp(ProjectCustomerOp, {
//           pageKey,
//           parm: {
//             titleName: '新增项目客户',
//             dragerContainer: that,
//             customer_id: -1,
//             opType: 'add',
//             that,
//             refreshTab: () => setTimeout(that.handleRefreshData, 2000)
//           },
//           popName: `operatSlideDrag_${pageKey}`,
//         }).show()
//       } else {
//         errmsgTip(res.data.errmsg)
//       }
//     } else {
//       createTip('网络错误！', ERROR).show()
//     }
//   })
// }

// const updateProjCustomer = function (that, type) {
//   const opType = 'update'
//   const titleName = '修改客户'
//   const tip = ''
//   const _comIds = that.vTable.getStateSelectes()
//   if (_comIds.length < 1) {
//     errmsgTip('请选择要修改的客户！')
//     return false
//   } else if (_comIds.length > 1) {
//     errmsgTip('只能修改一个客户！')
//     return false
//   }
//   const table = that.getData()
//   const drComId = table[_comIds[0]].id
//   console.log(_comIds)
//   const pageKey = getUuid()
//   const _req = {
//     pageKey,
//     data: {
//       customer_id: drComId,
//       op_type: opType
//     }
//   }
//   actions.companyServer.postProjCustomerMangerInfo(_req).then(res => {
//     console.log(res)
//     if (res.type === 'API_SUCCESS') {
//       if (res.data.errno === 0) {
//         new PopUp(ProjectCustomerOp, {
//           pageKey,
//           parm: {
//             titleName,
//             dragerContainer: that,
//             customer_id: _req.data.customer_id,
//             opType,
//             that,
//             tip,
//             refreshTab: () => setTimeout(that.handleRefreshData, 2000)
//           },
//           popName: `operatSlideDrag_${pageKey}`,
//         }).show()
//       } else {
//         errmsgTip(res.data.errmsg)
//       }
//     } else {
//       createTip('网络错误！', ERROR).show()
//     }
//   })
// }

// const deleteProjCustomer = function (that, type) {
//   const _that = that
//   const _comIds = that.vTable.getStateSelectes()
//   if (_comIds.length < 1) {
//     createTip('请选择要删除的客户！', ERROR).show()
//     return false
//   }
//   const table = that.getData()
//   const _drComIds = []
//   for (const val of _comIds) {
//     _drComIds.push(table[val].id)
//   }
//   let _req = {
//     data: {
//       customer_id: _drComIds,
//     },
//     opType: 'delete',
//     customer_id: _drComIds,
//     is_check: 1,
//   }
//   const popup = new PopUp(ShowInfo, {
//     classname: 'del-customer',
//     isShow: true,
//     type: WARN,
//     content: '您确定删除吗？',
//     autoDestroy: true,
//     title: '删除',
//     simpleMode: {
//       confirm: (thisDialog) => {
//         thisDialog.changeBtnLoadingStatus('confirm', true)
//         actions.companyServer.postCustomerProjectManager(_req).then(res => {
//           opTips({
//             resData: res.data,
//             opType: '删除',
//             tips: '删除',
//             orderType: '客户',
//             sucCallback() {
//               thisDialog.handleHide()
//               setTimeout(() => {
//                 _that.handleRefreshData()
//               }, 1500)
//             },
//             ext: {
//               failedCallback() {
//                 thisDialog.handleHide()
//               }
//             },
//             continueCallback(sucId, cb) {
//               cb()
//               _req = {
//                 data: {
//                   customer_id: sucId,
//                 },
//                 opType: 'delete',
//                 customer_id: sucId,
//               }
//               actions.companyServer.postCustomerProjectManager(_req).then(res2 => {
//                 thisDialog.handleHide()
//                 if (res2.type === 'API_SUCCESS' && res2.data.errno === 0) {
//                   createTip('删除成功！', INFO).show()
//                   setTimeout(() => {
//                     _that.handleRefreshData()
//                   }, 1500)
//                 } else {
//                   createTip(res2.data.errmsg, ERROR).show()
//                   thisDialog.changeBtnLoadingStatus('confirm', false)
//                 }
//               })
//             }
//           })
//         }).catch(err => {
//           thisDialog.changeBtnLoadingStatus('confirm', false)
//           console.log(err)
//         })
//       },
//       cancel: (thisDialog) => thisDialog.handleHide(),
//     },
//     closable: true,
//     maskClosable: true,
//   })
//   popup.show()
// }

// const viewProjCustomer = function (that, ele, rowIndex, column, data) {
//   const opType = 'view'
//   const titleName = '查看项目客户'
//   const pageKey = getUuid()
//   const _req = {
//     pageKey,
//     data: {
//       customer_id: column.id,
//       op_type: opType
//     }
//   }
//   actions.companyServer.postProjCustomerMangerInfo(_req).then(res => {
//     console.log(res)
//     if (res.type === 'API_SUCCESS') {
//       if (res.data.errno === 0) {
//         new PopUp(ProjectCustomerOp, {
//           pageKey,
//           parm: {
//             titleName,
//             dragerContainer: that,
//             customer_id: _req.data.customer_id,
//             opType,
//             that,
//             refreshTab: () => setTimeout(that.handleRefreshData, 2000)
//           },
//           popName: `operatSlideDrag_${pageKey}`,
//         }).show()
//       } else {
//         errmsgTip(res.data.errmsg)
//       }
//     } else {
//       createTip('网络错误！', ERROR).show()
//     }
//   })
// }

// const settingProjCustomer = function (that, type) {
//   alert('项目客户设置')
// }

export const viewImg = function (that, ele, rowIndex, column, data) {
  console.log(that, ele, rowIndex, column);
  const { category } = that.getReq();
  let type = 'customer_proj';
  if (['ProjectRecord', 'CustomerProject'].includes(category)) {
    // 意向项目、合作项目 & 项目跟进记录
    type = 'customer_project';
  }
  const props = {
    type,
    path: data.path,
    name: data.name,
  };
  viewImage(props);
};

// ================================== 客户管理 - 客户tab ==================================
// 添加客户
export const addCustomerWrap = async that => {
  const url = '/cmm-basic/japi/company/isOpenWh';
  const req = { company_id: window.user_info.company_id };
  const conf = { method: 'GET', body: req };
  const infoRes = await fetchJAVA(url, conf);
  if (+infoRes.errno !== 0) {
    infoRes.errmsg && showInfo(ERROR, infoRes.errmsg);
    return;
  }
  if (!infoRes.res.isOpenWh) {
    showInfo(WARN, '请先开通网货服务！');
    return;
  }
  addCustomer().then(res =>
    addPlateform(res.id).then(() => {
      showInfo(CHECK, '添加成功');
      setTimeout(that.handleRefreshData, 2000);
    }),
  );
};
// 更新客户
export const updateCustomer = async that => {
  const ids = that.getSelectesKey('id');
  if (ids.length < 1) {
    showInfo(WARN, '请选择要操作的数据！');
    return false;
  } else if (ids.length !== 1) {
    showInfo(WARN, '只能操作一条数据！');
    return false;
  }
  const op_type = 3;
  const id = ids[0];
  const url = '/cmm-basic/japi/platformCust/init';
  const req = { customer_id: id };
  const conf = { method: 'GET', body: req };
  const infoRes = await fetchJAVA(url, conf);
  if (+infoRes.errno !== 0) {
    infoRes.errmsg && showInfo(ERROR, infoRes.errmsg);
    return;
  }
  const { res } = infoRes;
  const param = {
    id,
    resData: res,
    opType: op_type,
    onConfirm: () => {
      showInfo(CHECK, '修改成功');
      setTimeout(that.handleRefreshData, 2000);
    },
    title: '修改客户',
  };
  const dialog = new PopUp(CustomerOp, param);
  dialog.show();
};
// 查看客户
export const viewCustomerWrap = async (that, rowIndex, columnKey, column, data) => {
  const op_type = 4;
  const { id } = column;
  const url = '/cmm-basic/japi/platformCust/init';
  const req = { customer_id: id };
  const conf = { method: 'GET', body: req };
  const infoRes = await fetchJAVA(url, conf);
  if (+infoRes.errno !== 0) {
    infoRes.errmsg && showInfo(ERROR, infoRes.errmsg);
    return;
  }
  const { res } = infoRes;
  const param = {
    id,
    resData: res,
    opType: op_type,
    onConfirm: () => setTimeout(that.handleRefreshData, 2000),
    title: '修改客户',
  };
  const dialog = new PopUp(CustomerOp, param);
  dialog.show();
};
// 删除客户
export const deleteCustomer = async that => {
  const ids = that.getSelectesKey('id');
  if (ids.length < 1) {
    showInfo(WARN, '请选择要操作的数据！');
    return false;
  }
  const req = {
    id: ids,
    is_check: 1,
    op_type: 'delete',
  };
  const content = (
    <div className="customer-dialog-content customer-delete">
      <p className="big">确定要删除选中的客户吗？</p>
      <p className="red">删除后无法恢复，请谨慎操作！</p>
    </div>
  );
  if (!(await confirm(WARN, content, {}, '删除客户'))) return false;
  const url = '/Basic/CustomerProj/opProj';
  const conf = { method: 'POST', body: { req } };
  const res = await fetch(url, conf);
  if (+res.errno !== 0) {
    res.errmsg && showInfo(ERROR, res.errmsg);
    return false;
  }
  res.errmsg && showInfo(CHECK, res.errmsg);

  setTimeout(that.handleRefreshData, 2000);
};
// 取消认证
export const platformImportCancel = async that => {
  const ids = that.getSelectesKey('id');
  if (ids.length < 1) {
    showInfo(WARN, '请选择要操作的数据！');
    return false;
  }
  const content = (
    <div className="customer-dialog-content platform_cancle">
      <p className="big">确定要对选中的客户取消认证吗？</p>
      <p className="red">取消后将无法开展网货业务，请谨慎操作！</p>
    </div>
  );
  if (!(await confirm(WARN, content, {}, '操作确认', null, null, 'platform_cancle'))) return false;

  async function certCustomer(idArr) {
    const req = {
      ids: idArr,
    };
    const url = '/cmm-basic/japi/platformCust/cancelPlatformCustomerCertification';
    const conf = { method: 'POST', body: { req } };
    return await fetchJAVA(url, conf);
  }
  const res = await certCustomer(ids);
  opTips({
    resData: res,
    opType: '操作确认',
    orderType: '客户',
    continueBtnName: '继续',
    cancelBtnName: '取消',
    showSucTips: false,
    sucCallback: () => {
      showInfo(CHECK, '取消认证成功！');
      setTimeout(that.handleRefreshData, 2000);
    },
    continueCallback: (sucId, cb) => {
      certCustomer(sucId).then(res2 => {
        cb();
        if (+res2.errno !== 0) {
          res2.errmsg && showInfo(ERROR, res2.errmsg);
          return false;
        }
        res2.errmsg && showInfo(CHECK, res2.errmsg);
        showInfo(CHECK, '取消认证成功！');
        setTimeout(that.handleRefreshData, 2000);
      });
    },
    ext: {
      // failedCallback: () => this.closeCallback()
    },
  });

  // if (+res.errno !== 0) {
  //   res.errmsg && showInfo(ERROR, res.errmsg)
  //   return false
  // }
  // res.errmsg && showInfo(CHECK, res.errmsg)

  // setTimeout(that.handleRefreshData, 2000)
};
// 认证
export const platformImport = async that => {
  const ids = that.getSelectesKey('id');
  if (ids.length < 1) {
    showInfo(WARN, '请选择要操作的数据！');
    return false;
  }
  let isPass = 0;
  const content = (
    <div className="customer-dialog-content customer-platform">
      <Label style={{ float: 'left', marginRight: '10px' }}>认证备注</Label>
      <span style={{ float: 'left' }}>
        <TextArea
          style={{ width: '300px' }}
          placeholder="拒绝时必填"
          value={that.state.platgormRemark}
          onChange={e => that.setState({ platgormRemark: e.target.value })}
        />
      </span>
    </div>
  );
  const operateRes = await copyConfirm(
    '',
    content,
    { confirm: '通过', cancel: '拒绝' },
    '客户认证',
    null,
    null,
    'platform_remark',
  );
  if (operateRes === 'no') {
    if (!that.state.platgormRemark) {
      showInfo(ERROR, '备注必填');
      return false;
    }
    isPass = 2;
  } else if (operateRes === 'yes') {
    isPass = 0;
  } else {
    return;
  }
  async function certCustomer(idArr, type) {
    const req = {
      ids: idArr,
      cert_remark: that.state.platgormRemark,
      is_pass: type,
    };
    const url = '/cmm-basic/japi/platformCust/submitPlatformCustomerCertification';
    const conf = { method: 'POST', body: { req } };
    const cbRes = await fetchJAVA(url, conf);
    return cbRes;
  }
  const res = await certCustomer(ids, isPass);
  opTips({
    resData: res,
    opType: '操作确认',
    orderType: '客户',
    continueBtnName: '继续',
    cancelBtnName: '取消',
    showSucTips: false,
    sucCallback: () => {
      showInfo(CHECK, '认证成功！');
      setTimeout(that.handleRefreshData, 2000);
    },
    continueCallback: (sucId, cb) => {
      certCustomer(sucId, 1).then(res2 => {
        cb();
        if (+res2.errno !== 0) {
          res2.errmsg && showInfo(ERROR, res2.errmsg);
          return false;
        }
        res2.errmsg && showInfo(CHECK, res2.errmsg);

        showInfo(CHECK, '认证成功！');

        setTimeout(that.handleRefreshData, 2000);
      });
    },
    ext: {
      // failedCallback: () => this.closeCallback()
    },
  });
};

class InviteRate extends Component {
  constructor(props) {
    super(props);
    this.that = props.that;
    this.noInviteNum = props.noInvite.length;
    this.state = {
      noInvite: props.noInvite,
      invited: 0,
      isShow: true,
    };
  }

  componentDidMount() {
    this.sendInvite();
  }

  sendInvite = async () => {
    const noInvite = [...this.state.noInvite];
    let { invited } = this.state;
    const url = '/Basic/Customer/sendInviteMsg';
    for (const i in noInvite) {
      if (this.isCancle) {
        break;
      }
      const req = {
        business_type: 'platform_customer',
        business_id: noInvite[i].id,
        telphone: noInvite[i].contractor_telephone,
        // telphone: '18539279350',
        // content: '',
      };

      const conf = { method: 'POST', body: { req } };
      const res = await fetch(url, conf);
      const operateMsg = {};
      if (+res.errno !== 0 || res.errmsg) {
        operateMsg.status = 0;
        operateMsg.msg = res.errmsg;
      } else {
        operateMsg.status = 1;
        operateMsg.msg = '操作成功';
      }
      invited++;
      this.noInviteNum--;
      noInvite[i].operateMsg = operateMsg;
      this.setState({
        noInvite,
        invited,
      });
    }
  };

  cancle = () => {
    this.setState({
      isShow: false,
    });
    this.isCancle = true;
    setTimeout(this.that.handleRefreshData, 2000);
    this.props.close();
    this.props.onConfirm(null);
  };
  renderOperteStatus = operateMsg => {
    const msg = '正在处理中...';
    let result1 = <span className="operate-status-2">{msg}</span>;

    if (operateMsg) {
      result1 = (
        <span>
          <Icon iconType={operateMsg.status ? 'icon-check-o' : 'icon-error-o'} />
          <span className={`operate-status-${operateMsg.status}`}>{operateMsg.msg}</span>
        </span>
      );
    }

    return result1;
  };
  renderContent = () => (
    <div className="invite_rate_box">
      <div className="title">
        <span className="iconBox">
          <Icon iconType="icon-info-o" />
        </span>
        <span className="text">
          批量发送进度（第 {this.state.invited} 个/共 {this.state.noInvite.length} 个），操作进行中，请勿关闭！
        </span>
      </div>
      <div className="table-box">
        <table className="invite_rate_table">
          <thead>
            <tr>
              <th>序号</th>
              <th>网点ID</th>
              <th>客户名称</th>
              <th>联系人</th>
              <th>联系人手机</th>
              <th>操作状态</th>
            </tr>
          </thead>

          <tbody>
            {this.state.noInvite.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.company_id}</td>
                <td>{item.proj_name}</td>
                <td>{item.name}</td>
                <td>{item.contractor_telephone}</td>
                <td>{this.renderOperteStatus(item.operateMsg)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
  renderBottom = () => (
    <div>
      <Button type="primary" onClick={this.cancle}>
        {this.noInviteNum ? '取消' : '关闭'}
      </Button>
    </div>
  );
  render() {
    return (
      <div>
        <ModalDialog
          isShow={this.state.isShow}
          title="批量发送邀请短信"
          content={this.renderContent()}
          bottom={this.renderBottom()}
        />
      </div>
    );
  }
}
export const msgInvite = async that => {
  const ids = that.getSelectesKey('id');
  if (ids.length < 1) {
    showInfo(WARN, '请选择要操作的数据！');
    return false;
  }
  const selectData = that.getStateSelecteRows();
  const yesInvite = [];
  const noInvite = [];
  for (const i in selectData) {
    if (selectData[i].invite_status === '1') {
      noInvite.push(selectData[i]);
    }

    if (selectData[i].invite_status === '2') {
      yesInvite.push(selectData[i].proj_name);
    }
  }
  let content;
  let isOk = false;
  if (yesInvite.length > 0 && noInvite.length > 0) {
    content = (
      <div className="customer-dialog-content platform_cancle">
        <p className="big">以下客户已通过邀请，是否继续邀请其他客户？</p>
        <p className="red">{yesInvite.join('、')}</p>
      </div>
    );

    if (!(await confirm(INFO, content, { confirm: '继续', cancel: '取消' }, '操作确认', null, null, 'platform_cancle')))
      return false;
    isOk = true;
  } else if (yesInvite.length === ids.length) {
    showInfo(ERROR, '选中客户已通过短信邀请！');
    return false;
  } else {
    content = (
      <div className="customer-dialog-content platform_cancle">
        <p className="big">确定要再次发送邀请短信吗？</p>
      </div>
    );

    if (!(await confirm(WARN, content, { confirm: '确定', cancel: '取消' }, '操作确认', null, null, 'platform_cancle')))
      return false;
    isOk = true;
  }

  if (isOk) {
    return new Promise(resolve => {
      new PopUp(InviteRate, { noInvite, that, onConfirm: res1 => resolve(res1) }, res1 => resolve(res1)).show();
    });
    // await copyAlert(CHECK, invite_content, { confirm: '确定' }, null, null, '批量发送邀请短信')
    // setTimeout(() => {
    //   that.handleRefreshData
    // }, 2000)

    // const url = '/Basic/Customer/sendInviteMsg'
    // for (let i in noInvite) {
    //   let req = {

    //   }

    //   const conf = { method: 'POST', body: { req } }
    //   const res = await fetch(url, conf)
    //   if (+res.errno !== 0) {
    //     res.errmsg && showInfo(ERROR, res.errmsg)
    //     return false
    //   }
    //   res.errmsg && showInfo(CHECK, res.errmsg)

    //   setTimeout(that.handleRefreshData, 2000)
    // }
  }
};
// 启用&停用 操作
const enableDisableOp = async req => {
  const url = '/Basic/CustomerProj/opProjState';
  const conf = { method: 'POST', body: { req } };
  const res = await fetch(url, conf);
  return res;
};
// 启用&停用
export const enableDisableCustomer = async (that, btnKey) => {
  const ids = that.getSelectesKey('id');
  if (ids.length < 1) {
    showInfo(WARN, '请选择要操作的数据！');
    return false;
  }
  let op = '停用';
  let op_type = 0;
  if (btnKey === 'enable_proj') {
    op = '启用';
    op_type = 1;
  }
  const content = (
    <div className="customer-dialog-content">
      <p className="big">您确定要{op}选中客户吗？</p>
      {btnKey !== 'enable_proj' && <p className="gray">{op}后，该客户将无法再创建任务，请谨慎操作！</p>}
    </div>
  );
  if (!(await confirm(WARN, content, {}, `${op}客户`))) return false;

  const req = { ids, op_type, is_check: 1, need_tpl: 0 };
  const resData = await enableDisableOp(req);

  if (+resData.errno === 5501 || +resData.errno === 5500) {
    // 选中客户已停用（启用），请勿重复操作！
    resData.errmsg && showInfo(ERROR, resData.errmsg);
    return false;
  }

  opTips({
    resData,
    opType: op,
    tips: op,
    orderType: '客户',
    showSucTips: false,
    sucCallback: async () => {
      const succRes = await enableDisableOp({ ids, op_type, is_check: 0, need_tpl: 0 });
      if (succRes.errno !== 0) {
        succRes.errmsg && showInfo(ERROR, succRes.errmsg);
        return;
      }
      succRes.errmsg && showInfo(CHECK, succRes.errmsg);
      setTimeout(() => that.handleRefreshData(), 2000);
    },
    continueCallback: async (sucId, cb) => {
      cb();
      const continueRes = await enableDisableOp({ ids: sucId, op_type, is_check: 0, need_tpl: 0 });
      if (continueRes.errno !== 0) {
        continueRes.errmsg && showInfo(ERROR, continueRes.errmsg);
        return;
      }
      continueRes.errmsg && showInfo(CHECK, continueRes.errmsg);
      setTimeout(() => that.handleRefreshData(), 2000);
    },
  });
};
// ================================== 客户管理 - 项目tab ⬇ ==================================
// 添加项目

const addProject = (that, buttonKey) => {
  const tableReq = that.getReq();
  const { tab } = tableReq;
  Project.show({
    opType: 'add',
    type: tab.includes('intention') ? 1 : 2,
    onSuccess: () => setTimeout(that.handleRefreshData, 2000),
  });
};
const editProject = (that, buttonKey) => {
  const ids = that.getSelectesKey('id');
  if (ids.length < 1) {
    showInfo(WARN, '请选择要操作的数据！');
    return false;
  } else if (ids.length !== 1) {
    showInfo(WARN, '只能操作一条数据！');
    return false;
  }
  const id = ids[0];
  Project.show({ opType: 'edit', id, onSuccess: () => setTimeout(that.handleRefreshData, 2000) });
};
const batchEditProject = (that, buttonKey) => {
  const list = that.getSelectesKey(['id', 'name', 'number']);
  if (list.length < 1) {
    showInfo(WARN, '请选择要操作的数据！');
    return false;
  }
  const tableReq = that.getReq();
  const { tab } = tableReq;
  Project.show({
    opType: 'batch-edit',
    list,
    type: tab.includes('intention') ? 1 : 2,
    onSuccess: () => setTimeout(that.handleRefreshData, 2000),
  });
};

// 查看项目
export const viewProjectWrap = async (that, rowIndex, columnKey, column, data) => {
  const tableReq = that.getReq();
  const { category } = tableReq;
  const { id } = column;
  let project_id = id;
  let activeTab = '1';
  if (category === 'ProjectRecord' && columnKey === 'project_id') {
    // 项目跟进记录 - 项目名称
    project_id = column.project_id;
    activeTab = 'records';
  }
  viewProject(project_id, { activeTab });
};
// 删除项目
export const deleteProject = async that => {
  const ids = that.getSelectesKey('id');
  if (ids.length < 1) {
    showInfo(WARN, '请选择要操作的数据！');
    return false;
  }
  const req = {
    ids,
    type: 'delete',
  };
  const content = (
    <div className="proj-dialog-content proj-delete">
      <p className="big">确定要删除选中的项目吗？</p>
      <p className="red">删除后无法恢复，请谨慎操作！</p>
    </div>
  );
  if (!(await confirm(WARN, content, {}, '删除项目'))) return false;
  const url = '/cmm-basic/japi/customerproj/updatestatus';
  const conf = { method: 'POST', body: { req } };
  const res = await fetchJAVA(url, conf);
  if (+res.errno !== 0) {
    res.errmsg && showInfo(ERROR, res.errmsg);
    return false;
  }
  res.errmsg && showInfo(CHECK, res.errmsg);

  setTimeout(that.handleRefreshData, 2000);
};
// 跟进项目
export const followProject = async that => {
  const ids = that.getSelectesKey('id');
  if (ids.length < 1) {
    showInfo(WARN, '请选择要操作的数据！');
    return false;
  } else if (ids.length !== 1) {
    showInfo(WARN, '只能操作一条数据！');
    return false;
  }
  const selectData = that.getStateSelecteRows()[0];
  const { id, name } = selectData;
  const selectInfo = {
    project_id: id,
  };
  const selectEnum = {
    project_id: [{ key: id, name }],
  };
  // const id = ids[0] // 项目id
  // const url = '/cmm-basic/japi/projectrecord/showprojectrecord'
  // const req = {}
  // const conf = { method: 'POST', body: { req } }
  // const infoRes = await fetchJAVA(url, conf)
  // if (+infoRes.errno !== 0) {
  //   infoRes.errmsg && showInfo(ERROR, infoRes.errmsg)
  //   return
  // }
  // const res = infoRes.res
  const params = {
    resData: {
      enums: {
        ...selectEnum,
      },
      info: {
        ...selectInfo,
      },
    },
    opType: 'add',
    that,
    title: '新增项目跟进记录',
    succText: '跟进成功',
    popName: 'project_record_pop',
  };
  const dialog = new PopUp(ProjRecordOp, params);
  dialog.show();
};
// 合作项目
export const cooperatProject = async that => {
  const ids = that.getSelectesKey('id');
  if (ids.length < 1) {
    showInfo(WARN, '请选择要操作的数据！');
    return false;
  }
  const info = {
    time: moment().format('YYYY-MM-DD HH:mm:ss'),
  };
  const params = {
    ids,
    info,
    popName: 'project_dialog_pop',
    className: 'cooperat-dailog',
    type: WARN,
    mainTitle: '您确定要将选中项目转为合作状态吗？',
    subTitle: '如为批量操作，所有项目的合作时间都以此为准',
    confirmCallback: (res, req) => {
      opTips({
        resData: res,
        opType: '合作',
        tips: '合作',
        orderType: '项目',
        showSucTips: false,
        sucCallback: () => {
          showInfo(CHECK, '转合作项目成功！');
          setTimeout(() => that.handleRefreshData(), 2000);
        },
        continueCallback: async (sucId, cb) => {
          cb();
          const url = '/cmm-basic/japi/customerproj/cooper';
          const continueReq = { ...req, ids: sucId };
          const conf = { method: 'POST', body: { req: continueReq } };
          const continueRes = await fetchJAVA(url, conf);
          if (continueRes.errno !== 0) {
            continueRes.errmsg && showInfo(ERROR, continueRes.errmsg);
            return;
          }
          continueRes.errmsg && showInfo(CHECK, continueRes.errmsg);
          setTimeout(() => that.handleRefreshData(), 2000);
        },
      });
    },
  };
  const dialog = new PopUp(ProjDialog, params);
  dialog.show();
};
// 启用&停用
export const enableDisableProject = async (that, btnKey) => {
  const ids = that.getSelectesKey('id');
  if (ids.length < 1) {
    showInfo(WARN, '请选择要操作的数据！');
    return false;
  }
  let op = '停用';
  let op_type = 'disable';
  if (['intention_project_enable', 'cooperative_project_enable'].includes(btnKey)) {
    op = '启用';
    op_type = 'enable';
  }
  const content = (
    <div className="customer-dialog-content">
      <p className="big">您确定要{op}选中项目吗？</p>
      {btnKey !== 'intention_project_enable' && <p className="gray">{op}后，该项目将无法再创建任务，请谨慎操作！</p>}
    </div>
  );
  if (!(await confirm(WARN, content, {}, `${op}项目`))) return false;

  const url = '/cmm-basic/japi/customerproj/updatestatus';
  const req = { ids, type: op_type, is_check: 1 };
  const conf = { method: 'POST', body: { req } };
  const resData = await fetchJAVA(url, conf);

  if (+resData.errno === 1) {
    // 选中客户已停用（启用），请勿重复操作！
    resData.errmsg && showInfo(ERROR, resData.errmsg);
    return false;
  }

  opTips({
    resData,
    opType: op,
    tips: op,
    orderType: '项目',
    showSucTips: false,
    sucCallback: () => {
      showInfo(CHECK, `${op}成功！`);
      setTimeout(() => that.handleRefreshData(), 2000);
    },
    continueCallback: async (sucId, cb) => {
      cb();
      conf.body.req = { ids: sucId, type: op_type, is_check: 0 };
      const continueRes = await fetchJAVA(url, conf);
      if (continueRes.errno !== 0) {
        continueRes.errmsg && showInfo(ERROR, continueRes.errmsg);
        return;
      }
      continueRes.errmsg && showInfo(CHECK, continueRes.errmsg);
      setTimeout(() => that.handleRefreshData(), 2000);
    },
  });
};
// 项目跟进记录 - 新增
export const addProjectRecord = async that => {
  const param = {
    opType: 'add',
    that,
    title: '新增项目跟进记录',
    popName: 'project_record_pop',
  };
  const dialog = new PopUp(ProjRecordOp, param);
  dialog.show();
};
// 项目跟进记录 - 修改
export const updateProjectRecord = async that => {
  const ids = that.getSelectesKey('id');
  if (ids.length < 1) {
    showInfo(WARN, '请选择要操作的数据！');
    return false;
  } else if (ids.length !== 1) {
    showInfo(WARN, '只能操作一条数据！');
    return false;
  }
  const id = ids[0];
  const url = '/cmm-basic/japi/projectrecord/showprojectrecord';
  const req = { id };
  const conf = { method: 'POST', body: { req } };
  const infoRes = await fetchJAVA(url, conf);
  if (+infoRes.errno !== 0) {
    infoRes.errmsg && showInfo(ERROR, infoRes.errmsg);
    return;
  }
  const { res } = infoRes;
  const param = {
    id,
    resData: res,
    opType: 'update',
    that,
    title: '修改项目跟进记录',
    popName: 'project_record_pop',
  };
  const dialog = new PopUp(ProjRecordOp, param);
  dialog.show();
};
// 项目跟进记录 - 查看
export const viewProjectRecord = async (that, ele, rowIndex, column, data) => {
  console.log('view', ele, rowIndex, column, data);
  const { id } = column;
  const url = '/cmm-basic/japi/projectrecord/showprojectrecord';
  const req = { id };
  const conf = { method: 'POST', body: { req } };
  const infoRes = await fetchJAVA(url, conf);
  if (+infoRes.errno !== 0) {
    infoRes.errmsg && showInfo(ERROR, infoRes.errmsg);
    return;
  }
  const { res } = infoRes;
  const param = {
    id,
    resData: res,
    opType: 'view',
    that,
    title: '查看项目跟进记录',
    popName: 'project_record_pop',
  };
  const dialog = new PopUp(ProjRecordOp, param);
  dialog.show();
};
// 项目跟进记录 - 删除
export const deleteProjectRecord = async that => {
  const ids = that.getSelectesKey('id');
  if (ids.length < 1) {
    showInfo(WARN, '请选择要操作的数据！');
    return false;
  }
  const req = {
    ids,
    // type: 'delete',
  };
  const content = (
    <div className="proj-dialog-content proj-record-delete">
      <p className="big">确定要删除选中的跟进记录吗？</p>
      <p className="red">删除后无法恢复，请谨慎操作！</p>
    </div>
  );
  if (!(await confirm(WARN, content, {}, '删除项目跟进记录'))) return false;
  const url = '/cmm-basic/japi/projectrecord/delete';
  const conf = { method: 'POST', body: { req } };
  const res = await fetchJAVA(url, conf);
  if (+res.errno !== 0) {
    res.errmsg && showInfo(ERROR, res.errmsg);
    return false;
  }
  res.errmsg && showInfo(CHECK, res.errmsg);

  setTimeout(that.handleRefreshData, 2000);
};
// ================================== 客户管理 - 项目tab ⬆ ==================================

export const addCor = addCorCee;
export const updateCor = updateCorCee;
export const deleteCor = deleteCorCee;
export const addCee = addCorCee;
export const updateCee = updateCorCee;
export const deleteCee = deleteCorCee;
export const settingCor = setting;
export const settingCee = setting;
const updateBatchCor = updateCorCee;
const updateBatchCee = updateCorCee;
// const configProj = settingProjCustomer

export function init(emt) {
  emt.on('addCor', addCor);
  emt.on('updateCor', updateCor);
  emt.on('updateBatchCor', (that, type) => updateBatchCor(that, type, true));
  emt.on('deleteCor', deleteCor);
  emt.on('addCee', addCee);
  emt.on('updateCee', updateCee);
  emt.on('updateBatchCee', that => updateBatchCee(that, 'update_cee', true));
  emt.on('deleteCee', deleteCee);
  emt.on('settingCor', settingCor);
  emt.on('settingCee', settingCee);
  emt.on('customerName', customerName);
  emt.on('delQueryer', delQueryer); // 解除绑定
  emt.on('addQueryer', addQueryerFn); // 绑定
  emt.on('relateApCee', relatePoint); // 绑定
  // ==== 客户管理 - 客户tab ====
  emt.on('addPlatformProj', addCustomerWrap); // 添加平台客户
  emt.on('updatePlatformProj', updateCustomer); // 修改客户
  emt.on('platformImport', platformImport); // 认证
  emt.on('platformImportCancel', platformImportCancel); // 取消认证
  emt.on('msgInvite', msgInvite); // 短信邀请
  emt.on('deleteProj', deleteCustomer); // 删除客户
  emt.on('platformProjProjName', viewCustomerWrap); // 查看客户
  emt.on('enableProj', enableDisableCustomer); // 启用
  emt.on('disableProj', enableDisableCustomer); // 停用
  // emt.on('config', configProj)
  emt.on('customerProjImgs', viewImg);
  emt.on('customerProjBusinessLicense', viewImg);
  // ============== 客户管理 - 项目tab ⬇ ==============
  emt.on('intentionProjectAdd', addProject); // 意向项目 - 新增
  emt.on('projectBatchUpdate', batchEditProject); // 意向项目 - 新增
  emt.on('intentionProjectEdit', editProject); // 意向项目 - 修改
  emt.on('intentionProjectFollowUp', followProject); // 意向项目 - 跟进
  emt.on('intentionProjectCooperative', cooperatProject); // 意向项目 - 合作
  emt.on('intentionProjectEnable', enableDisableProject); // 意向项目 - 启用
  emt.on('intentionProjectDisable', enableDisableProject); // 意向项目 - 停用
  emt.on('intentionProjectDel', deleteProject); // 意向项目 - 删除
  emt.on('cooperativeProjectAdd', addProject); // 合作项目 - 新增
  emt.on('cooperativeProjectEdit', editProject); // 合作项目 - 修改
  emt.on('cooperativeProjectFollowUp', followProject); // 合作项目 - 跟进
  emt.on('cooperativeProjectEnable', enableDisableProject); // 合作项目 - 启用
  emt.on('cooperativeProjectDisable', enableDisableProject); // 合作项目 - 停用
  emt.on('cooperativeProjectDel', deleteProject); // 合作项目 - 删除
  emt.on('customerProjectName', viewProjectWrap); // 意向项目、合作项目 - 查看项目
  emt.on('customerProjectImgs', viewImg); // 意向项目、合作项目 - 查看图片
  emt.on('projectRecordProjectId', viewProjectWrap); // 跟进记录 - 查看项目
  emt.on('customerProjectCustomerProjId', viewCustomerWrap); // 意向项目、合作项目 - 查看客户
  emt.on('projectRecordAdd', addProjectRecord); // 跟进记录 - 新增
  emt.on('projectRecordEdit', updateProjectRecord); // 跟进记录 - 修改
  emt.on('projectRecordName', viewProjectRecord); // 跟进记录 - 查看
  emt.on('projectRecordDel', deleteProjectRecord); // 跟进记录 - 删除
  emt.on('projectRecordImgs', viewImg); // 跟进记录 - 查看图片
  // ============== 客户管理 - 项目tab ⬆ ==============
}
