exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".order-editor-consignee{margin-left:4px;border-left:1px solid #78aadd}", ""]);

// exports
exports.locals = {
	"prefixCls": "order-editor-consignee",
	"order-editor-consignee": "order-editor-consignee"
};