import React, { Fragment } from 'react';
import actions from 'actions';
import {
  callApi as _callApi,
  typeIs,
  opVerify,
  showInfo,
  getOptRowInfo,
  getPageKey,
  handleResponse,
  isset,
  createTip,
  hasPermission,
  opTips,
  formatCamelCase,
  initDate,
  confirmWithReason,
  alert,
  emitListLinkOp,
  flatV1,
} from 'utils';
import {
  PopUp,
  SlideDrager,
  PopTip,
  ShowInfo,
  shouldCompUpdate,
  Dialog,
  ModalDialog,
  Button,
  DialogDetail,
  TextArea,
} from 'components';
import { ERROR, CHECK, WARN, INFO, RANGETIMEFILTERPROPS } from 'constants';
import fetchApi from 'utils/fetchApi';
import { dataAnalyticTrack } from '@/utils/dataAnalytic';
import { tipSelect, getPageTableInfo, getPageTableRowId } from 'pages/FinanceController/utils/getPageInfo';
import { manipulate } from 'pages/FinanceController/utils/manipulate';
import manipulateParam from 'pages/FinanceController/utils/manipulateConfig';
import operateFnConfig from 'pages/FinanceController/utils/operateFnConfig';
import getBatchDetail from 'pages/FinanceController/BatchDetail';
import getBillDetail from 'pages/FinanceController/BillDetatil';
import getSliderDetail from 'pages/FinanceController/SliderDetail';
import TicketPaidBuilding from 'pages/FinanceController/ticketPaidBuilding';
import RigReamrk from 'pages/FinanceController/RigReamrk';
import AuditModal from 'pages/FinanceController/AuditModal';
import FeeOperate from 'pages/FinanceController/FeeOperate';
import dailyFinanceSlideDrager from 'pages/FinanceController/IncomeExpensesDetail';
import recharge from 'pages/FinanceController/RechargeWithdrawal/recharge';
import withdrawal from 'pages/FinanceController/RechargeWithdrawal/withdrawal';
import DailySettle from 'pages/FinanceController/IncomeExpensesDetail/DailySettle';
import getUuid from 'utils/uuid';
import AuditTrail from 'pages/ApprovalController/AuditTrail';
import { AsyncWalletWithdraw } from 'pages/AccountController/walletWithdraw/dynamicImport';
import ListPageConf from 'pages/ListPage';
import './orderModifyOp.scss';
import BatchOp from '../BatchOp';
import { docListNo as showVoucher } from './voucherListOp';
import invoiceRegistEx from 'operation/listOperation/Finance/invoiceRegistEx';
import { handleUnbindInvoice } from 'operation/listOperation/Finance/invoiceRegistEx/common.js';
// eslint-disable-next-line import/no-cycle
import {
  AuditWithModal,
  cancelAuditMass,
  applyOperation,
  cancelApply,
  cancelApplyMass,
  cancelAuditUniversal,
} from './approvalOp';
// import { popSideListPage } from './reportsOp'
import { pageSkipConfig } from '../../../pages/FinanceController/utils';
import { isStatusVerify, statusVerify } from 'utils/business/finance/auditStatus';
import { showCapitalFlow, capitalFlow, capitalFlowView, opAudit } from 'operation/listOperation/loanOp';
import _ from 'lodash';
import Carmaintenance from 'pages/CarMaintenanceController/Entity';
import PayModeTransferAccounts from '../payModetransferAccounts';

const isFromPickClip = listPage => listPage && listPage.getReq().tab === 'pick_clip'; // 是否是挑单夹事件
const permMap = {
  account: { permission: 'review_employee_ac_detail', tip: '运单交账明细' },
  account_batch_pickup: { permission: 'review_employee_ac_detail_batch_pickup', tip: '提货批次交账明细' },
  account_batch_shuttle: { permission: 'review_employee_ac_detail_batch_shuttle', tip: '短驳批次交账明细' },
  account_batch_start: { permission: 'review_employee_ac_detail_batch_start', tip: '发车批次交账明细' },
  account_batch_to: { permission: 'review_employee_ac_detail_batch_to', tip: '到车批次交账明细' },
  account_batch_delivery: { permission: 'review_employee_ac_detail_batch_delivery', tip: '送货批次交账明细' },
};

const BACK_SERVICE_MAP = {
  1: 'wx',
  2: 'by',
};

// 请求接口
function postPack(options) {
  // eslint-disable-line
  const attr = options || {};
  const { url } = attr;
  const { pack } = attr;
  const { callFn } = attr;
  const saveAs = 'truckOperate';
  const callApi = {
    path: url,
    dataFormat: 'raw',
    saveAs,
    method: 'POST',
    body: {
      req: pack,
    },
  };
  _callApi(callApi).then(res => {
    if (undefined !== callFn && typeof callFn === 'function') {
      callFn(res);
    }
  });
}

// 显示一个提示, 确认之后再操作
function showConfirm(titleTip, contentTip, callback, cancelCallBack) {
  new PopUp(ShowInfo, {
    type: INFO,
    content: contentTip,
    title: titleTip,
    maskClosable: false,
    simpleMode: {
      confirm: thisDialog => {
        thisDialog.changeBtnLoadingStatus('confirm', true);
        callback &&
          callback(() => {
            thisDialog.changeBtnLoadingStatus('confirm', false);
            thisDialog.handleHide();
          });
      },
      cancel: thisDialog => {
        thisDialog.handleHide();
        cancelCallBack && cancelCallBack();
      },
    },
  }).show();
}

const getBillType = function (expenseType) {
  const url = '/Finance/Finance/sugBillList';
  const option = {
    method: 'POST',
    body: {
      req: {
        expense: [expenseType],
      },
    },
  };
  return fetchApi(url, option);
};
const getExpenseType = function (billType) {
  const url = '/Finance/Finance/sugExpenseList';
  const option = {
    method: 'POST',
    body: {
      req: {
        bill_type: [billType],
      },
    },
  };
  return fetchApi(url, option);
};
/**
 * 结算管理：结算
 * 客户对账单列表：结算
 */
export const settle = function (that) {
  console.log('结算管理：结算', that);
  const curReq = that.getReq();
  const _category = curReq.category;
  let _tab = curReq.tab;
  // 判断是否选中
  if (
    _tab === 'account' ||
    _tab === 'customer' ||
    _tab === 'receipt_payment' ||
    _tab === 'balance' ||
    _tab === 'point'
  ) {
    // 获取支付方式数据并弹框
    if (!tipSelect(that)) return false;
  }
  let genId = 'od_link_id';
  if (_category === 'Batch') {
    genId = 'b_link_id';
  }
  if (
    _tab === 'customer' ||
    _tab === 'account' ||
    _tab === 'receipt_payment' ||
    _tab === 'balance' ||
    _tab === 'point'
  ) {
    genId = 'bill_id';
  }
  let comIdArr = getPageTableRowId({
    pageInfo: that,
    idKey: 'com_id',
  });
  comIdArr = Array.from(new Set(comIdArr)); // 去重
  if (comIdArr.length > 1) {
    const popup = new PopUp(PopTip, {
      type: CHECK,
      content: '只支持同一个网点进行操作',
    });
    popup.show();
    return false;
  }
  // 获得 Table 中想要的信息
  const _res = getPageTableRowId({
    pageInfo: that,
    idKey: genId,
  });
  const pack = {};
  pack.com_id = comIdArr[0];
  pack.link_ids = _res;
  // pack.expense = curReq.expense || '-1'
  pack.category = _category;
  pack.type = curReq.type;
  pack.tab = curReq.tab;
  if (
    _tab === 'account' ||
    _tab === 'customer' ||
    _tab === 'receipt_payment' ||
    _tab === 'balance' ||
    _tab === 'point'
  ) {
    // 获取支付方式数据并弹框
    _tab = `${_tab}_settle`;
    manipulate({
      // opSymbol: 'settleLogCancel',
      // isCheck: true,
      // isTip: true,
      getPayMode: true,
      req: {
        data: _res,
        type: _tab,
      },
    });
  } else {
    const urlData = {
      controller: 'Finance',
      action: 'pickOrder',
    };
    urlData.query = {};
    urlData.query.tabName = manipulateParam.tabName[_tab];
    console.log(_tab, 'urlData', urlData);
    actions.api.direct(urlData, 'prevPagePack', pack);
  }
};
/**
 * 网点对账明细：创建对账单 按运单
 */
export const createPoint = function (that) {
  console.log('网点对账明细：创建对账单，运单', that);
  // 判断是否选中
  // if (!tipSelect(that)) return false
  const curReq = that.getReq();
  const genId = 'od_link_id';
  const fitlerQuery = that.getAllFitlerQuery();
  // if (!fitlerQuery.com_id) {
  // new PopUp(PopTip, {
  // type: WARN,
  // content: '请选择网点'
  // }).show()
  // return false
  // }
  if (!fitlerQuery.account_id || (typeIs(fitlerQuery.account_id, 'array') && fitlerQuery.account_id.length === 0)) {
    // if (!fitlerQuery.account_id) {
    new PopUp(PopTip, {
      type: WARN,
      content: '请选择对账网点',
    }).show();
    return false;
  }
  if (
    !fitlerQuery.trsp_direction ||
    (typeIs(fitlerQuery.trsp_direction, 'array') && fitlerQuery.trsp_direction.length === 0)
  ) {
    // if (!fitlerQuery.trsp_direction) {
    new PopUp(PopTip, {
      type: WARN,
      content: '请选择运输方向',
    }).show();
    return false;
  }
  // 获得 Table 中想要的信息
  const _res = getPageTableRowId({
    pageInfo: that,
    idKey: genId,
  });
  let comIdArr = getPageTableRowId({
    pageInfo: that,
    idKey: 'com_id',
  });
  comIdArr = Array.from(new Set(comIdArr)); // 去重
  if (comIdArr.length > 1) {
    const popup = new PopUp(PopTip, {
      type: CHECK,
      content: '只支持同一个网点进行操作',
    });
    popup.show();
    return false;
  }
  let accountId = String(fitlerQuery.account_id);
  if (typeIs(accountId, 'array')) {
    accountId = String(accountId[0]);
  }
  let trspDirection = fitlerQuery.trsp_direction;
  if (typeIs(trspDirection, 'array')) {
    trspDirection = trspDirection[0];
  }
  const tableEnum = that.getTableEnum();
  let accountName;
  let trspDirectionName;
  tableEnum.account_id.map(item => {
    if (item.key === accountId) {
      accountName = item.name;
    }
    return item.key;
  });
  tableEnum.trsp_direction.map(item => {
    if (item.key === trspDirection) {
      trspDirectionName = item.name;
    }
    return item.key;
  });
  const pack = {};
  pack.com_id = comIdArr[0];
  pack.link_ids = _res;
  // pack.expense = curReq.expense || '-1'
  pack.category = curReq.category;
  pack.type = curReq.type || curReq.tab;
  pack.tab = curReq.tab;
  pack.opposite_id = accountId; // 对账网点
  pack.trsp_direction = trspDirection; // 运输方向
  pack.accountName = accountName;
  pack.trspDirectionName = trspDirectionName;
  const urlData = {
    controller: 'Finance',
    action: 'pickOrder',
    query: {
      tabName: manipulateParam.tabName[`createPoint_${curReq.tab}`],
    },
  };
  console.log(`createPoint_${curReq.tab}`, urlData);
  actions.api.direct(urlData, 'prevPagePack', pack);
};
/**
 * 网点对账明细：创建对账单 按批次
 */
export const createPointBill = function (that) {
  console.log('网点对账明细：创建对账单，批次', that);
  // 判断是否选中
  // if (!tipSelect(that)) return false
  const curReq = that.getReq();
  const genId = 'b_tr_basic_id';
  // 获得 Table 中想要的信息
  const _res = getPageTableRowId({
    pageInfo: that,
    idKey: genId,
  });
  const pack = {};
  pack.link_ids = _res;
  // pack.expense = curReq.expense || '-1'
  pack.category = curReq.category;
  pack.type = curReq.type;
  pack.tab = curReq.tab;
  pack.opposite_id = '23'; // 对账网点
  const urlData = {
    controller: 'Finance',
    action: 'pickOrder',
  };
  actions.api.direct(urlData, 'prevPagePack', pack);
};
/**
 * 员工交账账明细：创建交账单
 */
export const createEmAccount = function (that) {
  console.log('员工交账账明细：创建交账单', that);
  // 判断是否选中
  // if (!tipSelect(that)) return false
  const curReq = that.getReq();
  const _tab = 'account';
  const _type = _tab;
  let userArr = [];
  let comIdArr = [];
  const optRowLen = that.getSelectesKey();
  if (optRowLen.length > 0) {
    userArr = that.getSelectesKey('mgr_id');
    userArr = Array.from(new Set(userArr));
    comIdArr = that.getSelectesKey('com_id');
    comIdArr = Array.from(new Set(comIdArr));
    if (userArr.length > 1 || comIdArr.length > 1) {
      new PopUp(PopTip, {
        type: WARN,
        content: '请您针对一个网点的一名员工进行交账！',
      }).show();
      return false;
    }
  }
  const genId = 'od_link_id';
  // 获得 Table 中想要的信息
  const _res = getPageTableRowId({
    pageInfo: that,
    idKey: genId,
    expense: 'expense',
  });
  const pack = {};
  pack.com_id = comIdArr[0];
  pack.link_info = _res;
  // pack.expense = curReq.expense || '-1'
  pack.category = curReq.category;
  pack.tab = _tab;
  pack.type = _type;
  pack.tabType = curReq.tab;
  pack.opposite_id = userArr.join(',');
  const urlData = {
    controller: 'Finance',
    action: 'pickOrder',
    query: {
      tabName: manipulateParam.tabName[`createEmAccount_${_tab}`],
    },
  };
  console.log(`createEmAccount_${_tab}`, urlData);
  actions.api.direct(urlData, 'prevPagePack', pack);
};
// 收付款管理：收款
export const receiptApply = function (that) {
  console.log('收付款管理：收款', that);
  // 判断是否选中
  // if (!tipSelect(that)) return false
  // const butKey = 'receiptApply'
  const curReq = that.getReq();
  const _category = curReq.category;
  let _type = curReq.type;
  let _tab = curReq.tab;
  let genId = 'od_link_id';
  if (_category === 'Batch') {
    genId = 'b_link_id';
  }
  if (_type === 'receipt_payment') {
    _type = 'receipt';
    _tab = 'receipt';
  }
  // 货款回收里的收款申请操作
  if (_type === 'co_delivery_receipt') {
    _type = 'co_delivery_receipt';
    _tab = 'co_delivery_receipt';
  }
  // 获得 Table 中想要的信息
  const _res = getPageTableRowId({
    pageInfo: that,
    idKey: genId,
  });
  const pack = {
    originReqFromSource: that.getOrignReq && that.getOrignReq(), // 向下传递原始Req(包含tab等数据)
  };
  pack.link_ids = _res;
  // pack.expense = curReq.expense || '-1'
  pack.category = _category;
  pack.type = _type;
  pack.tab = _tab;
  const urlData = {
    controller: 'Finance',
    action: 'pickOrder',
    query: {
      tabName: manipulateParam.tabName[`receiptApply_${_tab}`],
    },
  };
  console.log(`receiptApply_${_tab}`, 'urlData', urlData);
  actions.api.direct(urlData, 'prevPagePack', pack);
};
// 发车批次收付款：付款申请
export const paymentApply = function (that) {
  console.log('发车批次收付款：付款申请', that);
  // 判断是否选中
  // if (!tipSelect(that)) return false
  const curReq = that.getReq();
  const _category = curReq.category;
  let _type = curReq.type;
  let _tab = curReq.tab;
  let genId = 'od_link_id';
  if (_category === 'Batch') {
    genId = 'b_link_id';
  }
  if (_type === 'receipt_payment') {
    _type = 'payment';
    _tab = 'payment';
  }
  // 货款发放里的付款申请操作
  if (_type === 'co_delivery_paid') {
    _type = 'payment_co_delivery';
    _tab = 'payment_co_delivery';
  }
  // 获得 Table 中想要的信息
  const _res = getPageTableRowId({
    pageInfo: that,
    idKey: genId,
  });
  const pack = {};
  pack.refreshTab = that.refreshTab;
  pack.link_ids = _res;
  // pack.expense = curReq.expense || '-1'
  pack.category = _category;
  pack.type = _type;
  pack.tab = _tab;
  const urlData = {
    controller: 'Finance',
    action: 'pickOrder',
    query: {
      tabName: manipulateParam.tabName[`paymentApply_${_tab}`],
    },
  };
  console.log(`paymentApply_${_tab}`, urlData);
  actions.api.direct(urlData, 'prevPagePack', pack);
};
// 奖金流水汇总：生成凭证
export const settleLogGenCert = function (that) {
  console.log('奖金流水汇总：生成凭证');
  // 判断是否选中
  if (!tipSelect(that)) return false;
  const curReq = that.getReq();
  let _tab = curReq.tab;
  _tab === 'detail' ? (_tab = 'settleLogDetail') : (_tab = 'settleLogList');
  // 获得 Table 中想要的信息
  const _res = getPageTableInfo({
    pageInfo: that,
    req: {
      bill_id: 'bill_id',
    },
  });
  manipulate({
    btnKey: 'settleLogGenCert',
    refreshTab: that.refreshTab,
    opSymbol: `${_tab}_genCert`,
    isCheck: true,
    isTip: true,
    req: {
      data: _res,
      is_check: 1,
      type: `${_tab}_genCert`,
    },
  });
};
// 奖金流水汇总：取消
export const settleLogCancelOld = function (that) {
  console.log('奖金流水汇总：取消', that);
  const curReq = that.getReq();
  let _tab = curReq.tab;
  if (_tab === 'detail') _tab = 'settleLogDetail';
  if (_tab === 'list') _tab = 'settleLogList';
  // 判断是否选中
  if (!tipSelect(that)) return false;
  // 获得 Table 中想要的信息
  const _req = {};
  // _tab === 'detail' ? _req.order_id = 'order_id' : _req.bill_id = 'bill_id' // 奖金流水汇总列表取bill_id，明细取order_id
  _req.bill_id = 'bill_id';
  if (_tab === 'settleLogDetail') {
    _req.expense_id = 'expense_id';
    _req.amount = 'settle_amount';
    _req.expense = 'expense';
  }
  const _res = getPageTableInfo({
    pageInfo: that,
    req: _req,
  });
  manipulate({
    refreshTab: that.refreshTab,
    opSymbol: `${_tab}_cancel`,
    isCheck: true,
    isTip: true,
    req: {
      data: _res,
      is_check: 1,
      type: `${_tab}_cancel`,
    },
  });
};
// 员工交账：审核通过
export const review = function (that) {
  console.log('员工交账：审核通过', that);
  const curReq = that.getReq();
  const _tab = curReq.tab;
  // 判断是否选中
  if (!tipSelect(that)) return false;
  // 获得 Table 中想要的信息
  const _req = {};
  _req.bill_id = 'bill_id';
  const _res = getPageTableInfo({
    pageInfo: that,
    req: _req,
  });
  const para = {};
  para.data = _res;
  para.type = `${_tab}_review`;
  const _obj = {};
  _obj.req = para;
  _obj.refreshTab = that.refreshTab;
  new PopUp(RigReamrk, _obj).show();
  // manipulate({
  // opSymbol: `${_tab}_review`,
  // // isCheck: true,
  // isTip: true,
  // req: {
  // data: _res,
  // type: `${_tab}_review`
  // }
  // })
};
// 员工交账：审核拒绝
export const reject = function (that) {
  console.log('员工交账：审核拒绝', that);
  const curReq = that.getReq();
  const _tab = curReq.tab;
  // 判断是否选中
  if (!tipSelect(that)) return false;
  // 获得 Table 中想要的信息
  const _req = {};
  // _tab === 'detail' ? _req.order_id = 'order_id' : _req.bill_id = 'bill_id' // 奖金流水汇总列表取bill_id，明细取order_id
  _req.bill_id = 'bill_id';
  const _res = getPageTableInfo({
    pageInfo: that,
    req: _req,
  });
  const para = {};
  para.data = _res;
  para.type = `${_tab}_reject`;
  const _obj = {};
  _obj.req = para;
  _obj.refreshTab = that.refreshTab;
  new PopUp(RigReamrk, _obj).show();
  // manipulate({
  // opSymbol: `${_tab}_reject`,
  // // isCheck: true,
  // isTip: true,
  // req: {
  // data: _res,
  // type: `${_tab}_reject`
  // }
  // })
};
// 财务挂账：挂账
export const ledger = function (that) {
  console.log('财务挂账：挂账', that);
  const curReq = that.getReq();
  const _tab = curReq.tab;
  let genId = 'od_link_id';
  if (_tab !== 'ledger') {
    // 判断是否选中
    if (!tipSelect(that)) return false;
    genId = 'b_link_id';
  }
  // 获得 Table 中想要的信息
  const _res = getPageTableRowId({
    pageInfo: that,
    idKey: genId,
  });
  const pack = {};
  pack.link_ids = _res;
  const urlData = {
    controller: 'Finance',
    action: 'pickOrder',
    query: {
      tabName: manipulateParam.tabName[`createPoint_${_tab}`],
    },
  };
  console.log(`createPoint_${_tab}`, urlData);
  if (_tab === 'ledger') {
    // pack.expense = '-1'
    pack.category = curReq.category;
    pack.type = _tab;
    actions.api.direct(urlData, 'prevPagePack', pack);
  } else {
    manipulate({
      pageTab: _tab,
      // isCheck: true,
      // isTip: true,
      getPCAmount: true,
      req: {
        link_ids: _res,
        type: 'ledger',
      },
    });
  }
};
// 财务挂账：取消挂账
// export const cancelLedger = function (that) {
//   console.log('财务挂账：取消挂账')
//   let curReq = that.getReq()
//   let _tab = curReq.tab
//   if (_tab !== 'ledger') {
//     // 判断是否选中
//     if (!tipSelect(that)) return false
//   }
//   // 获得 Table 中想要的信息
//   let _res = getPageTableRowId({
//     pageInfo: that,
//     idKey: 'od_link_id'
//   })
//   let pack = {}
//   pack.link_ids = _res
//   // pack.expense = '-1'
//   pack.type = `${_tab}_cancel`
//   let urlData = {
//     controller: 'Finance',
//     action: 'pickOrder'
//   }
//   actions.api.direct(urlData, 'prevPagePack', pack)
// }
/*
 * 财务挂账：列表上的详情操作
 */
export const batchLedger = function (that, index) {
  console.log('财务挂账：列表上的详情操作');
  const pageKey = getPageKey().pageConfig.key;
  const curReq = that.getReq();
  // let _category = curReq.category
  const _tab = curReq.tab;
  // let _type = curReq.type
  const genID = 'b_link_id'; // 发车批次挂账
  const data = that.getData();
  const curID = data[index][genID];
  const _batchNo = data[index].car_batch;
  const _req = {};
  _req.b_link_id = curID;
  _req.com_id = data[index].company_id;
  _req.pageTab = _tab;
  _req.type = 'ledger';
  new PopUp(getBatchDetail(), {
    refreshTab: that.refreshTab,
    // popName: `BalanceBuildingSlider${_tab}`,
    popName: `finance_${pageKey}`,
    batchNo: _batchNo,
    pageType: 'ledger',
    listPage: that,
    req: _req,
  }).show();
};
/*
 * 业务确认：列表上的详情操作
 */
export const batchConfirm = function (that, index) {
  console.log('业务确认：列表上的详情操作');
  const pageKey = getPageKey().pageConfig.key;
  const curReq = that.getReq();
  // let _category = curReq.category
  const _tab = curReq.tab;
  // let _type = curReq.type
  const genID = 'b_link_id';
  const data = that.getData();
  const curID = data[index][genID];
  const _batchNo = data[index].car_batch;
  const _req = {};
  _req.b_link_id = curID;
  _req.com_id = data[index].company_id;
  _req.pageTab = _tab;
  _req.type = 'confirm';
  new PopUp(getBatchDetail(), {
    // popName: `BatchDetailSlider${_tab}`,
    popName: `finance_${pageKey}`,
    batchNo: _batchNo,
    pageType: 'confirm',
    listPage: that,
    req: _req,
  }).show();
};
// 业务确认：取消确认
export const cancelConfirm = function (that) {
  console.log('业务确认：取消确认', that);
  // 判断是否选中
  if (!tipSelect(that)) return false;
  const curReq = that.getReq();
  const _category = curReq.category;
  const _tab = curReq.tab;
  let genId = 'od_link_id';
  if (_category === 'Batch') {
    genId = 'b_link_id';
  }
  // 获得 Table 中想要的信息
  const _res = getPageTableRowId({
    pageInfo: that,
    idKey: genId,
  });
  const pack = {};
  pack.link_ids = _res;
  // pack.expense = curReq.expense || '-1'
  pack.type = `${curReq.type}_cancel`;
  pack.tab = `${curReq.tab}_cancel`;
  const urlData = {
    controller: 'Finance',
    action: 'pickOrder',
    query: {
      tabName: manipulateParam.tabName[`cancelConfirm_${_tab}`],
    },
  };
  console.log(`cancelConfirm_${_tab}`, urlData);
  if (_category === 'Batch') {
    manipulate({
      refreshTab: that.refreshTab,
      pageTab: `${_tab}_cancel`,
      // isCheck: true,
      // isTip: true,
      getPCAmount: true,
      req: {
        link_ids: _res,
        type: 'confirm_cancel',
      },
    });
  } else {
    actions.api.direct(urlData, 'prevPagePack', pack);
  }
};
// 业务确认：确认
export const confirm = function (that) {
  console.log('业务确认：确认', that);
  const curReq = that.getReq();
  const _category = curReq.category;
  const _tab = curReq.tab;
  let genId = 'od_link_id';
  if (_category === 'Batch') {
    genId = 'b_link_id';
  }
  if (_category === 'Batch') {
    // 判断是否选中
    if (!tipSelect(that)) return false;
  }
  // 获得 Table 中想要的信息
  const _res = getPageTableRowId({
    pageInfo: that,
    idKey: genId,
  });
  const pack = {};
  pack.link_ids = _res;
  // pack.expense = curReq.expense || '-1'
  pack.type = curReq.type;
  pack.tab = curReq.tab;
  const urlData = {
    controller: 'Finance',
    action: 'pickOrder',
    query: {
      tabName: manipulateParam.tabName[`confirm_${_tab}`],
    },
  };
  console.log(`confirm_${_tab}`, urlData);
  if (_category === 'Batch') {
    manipulate({
      refreshTab: that.refreshTab,
      pageTab: _tab,
      // isCheck: true,
      // isTip: true,
      getPCAmount: true,
      req: {
        link_ids: _res,
        type: 'confirm',
      },
    });
  } else {
    actions.api.direct(urlData, 'prevPagePack', pack);
  }
};
// 收银管理：收银
export const cash = function (that) {
  console.log('收银管理：收银', that);
  // 判断是否选中
  // if (!tipSelect(that)) return false
  const curReq = that.getReq();
  const _category = curReq.category;
  const _tab = curReq.tab;
  let genId = 'od_link_id';
  if (_category === 'Batch') {
    genId = 'b_link_id';
  }
  // 获得 Table 中想要的信息
  const _res = getPageTableRowId({
    pageInfo: that,
    idKey: genId,
  });
  const pack = {};
  pack.link_ids = _res;
  // pack.expense = curReq.expense || '-1'
  pack.category = curReq.category;
  pack.type = curReq.type;
  pack.tab = curReq.tab;
  const urlData = {
    controller: 'Finance',
    action: 'pickOrder',
    query: {
      tabName: manipulateParam.tabName[`cash_${_tab}`],
    },
  };
  console.log(`cash_${_tab}`, 'urlData', urlData);
  actions.api.direct(urlData, 'prevPagePack', pack);
};
// 收银管理：取消收银
export const cancelCash = function (that) {
  console.log('收银管理：取消收银', that);
  // 判断是否选中
  // if (!tipSelect(that)) return false
  const curReq = that.getReq();
  const _category = curReq.category;
  const _tab = curReq.tab;
  let genId = 'od_link_id';
  if (_category === 'Batch') {
    genId = 'b_link_id';
  }
  // 获得 Table 中想要的信息
  const _res = getPageTableRowId({
    pageInfo: that,
    idKey: genId,
  });
  const pack = {};
  pack.link_ids = _res;
  // pack.expense = curReq.expense || '-1'
  pack.category = curReq.category;
  pack.type = `${curReq.type}_cancel`;
  pack.tab = `${curReq.tab}_cancel`;
  const urlData = {
    controller: 'Finance',
    action: 'pickOrder',
    query: {
      tabName: manipulateParam.tabName[`cancelCash_${_tab}`],
    },
  };
  console.log(`cancelCash_${_tab}`, 'urlData', urlData);
  actions.api.direct(urlData, 'prevPagePack', pack);
};
// 收付款单：账单详情
// 2017-09-20 11:54:12 quanzhizhong 移至新文件 financialOp 里
export const settlePaReNoBak = function (that, index, column) {
  console.log('收付款单：账单详情', index, column, that);
  const pageKey = getPageKey().pageConfig.key;
  const curReq = that.getReq();
  const _category = curReq.category;
  const _expense = curReq.expense;
  const _type = curReq.type;
  const _tab = curReq.tab;
  const data = that.getData();
  const _billId = data[index].bill_id;
  const _settleNo = data[index].pa_re_no;
  const _sliderTitle = `单号：${_settleNo}`;
  const _req = {};
  _req.bill_id = _billId;
  _req.category = _category;
  _req.expense = _expense;
  _req.type = _type;
  _req.tab = _tab;
  _req.bill_type = data[index].bill_type;
  _req.bill_no = data[index].settle_no;
  _req.opposite_name = data[index].opposite_name;
  _req.opposite_phone = data[index].opposite_phone;
  _req.time = data[index].ac_time;
  _req.remark = data[index].remark;
  new PopUp(getSliderDetail(), {
    // popName: 'settlePaReNoSlider',
    popName: `finance_${pageKey}`,
    pageThat: that,
    refreshTab: that.refreshTab,
    sliderTitle: _sliderTitle,
    req: _req,
  }).show();
};
// 员工交账单列表：账单详情
export const settleEmAcNoBak = function (that, index, column) {
  console.log('员工交账单列表：账单详情', index, column, that);
  const pageKey = getPageKey().pageConfig.key;
  const curReq = that.getReq();
  const _category = curReq.category;
  const _expense = curReq.expense;
  const _type = curReq.type;
  const _tab = curReq.tab;
  const data = that.getData();
  const _settleNo = data[index].em_ac_no;
  let _billId = data[index].em_ac_no_id;
  if (_tab === 'account' || _tab === 'document_center') {
    _billId = data[index].bill_id;
  }
  const _sliderTitle = `账单号：${_settleNo}`;
  const _req = {};
  _req.bill_id = _billId;
  _req.bill_no = _settleNo;
  _req.category = _category;
  _req.expense = _expense;
  _req.type = _type;
  _req.tab = 'account';
  _req.bill_type = data[index].bill_type;
  _req.opposite_name = data[index].opposite_name;
  _req.time = data[index].em_account_time;
  _req.remark = data[index].remark;
  new PopUp(getSliderDetail(), {
    // popName: 'settleEmAcNoSlider',
    popName: `finance_${pageKey}`,
    pageThat: that,
    refreshTab: that.refreshTab,
    sliderTitle: _sliderTitle,
    req: _req,
  }).show();
};
// 对账单列表：对账单详情
export const settleAcNoBak = function (that, index, column) {
  console.log('对账单列表：对账单详情', index, column, that);
  const pageKey = getPageKey().pageConfig.key;
  const curReq = that.getReq();
  const _category = curReq.category;
  const _expense = curReq.expense;
  const _type = curReq.type;
  const _tab = curReq.tab;
  const data = that.getData();
  const _settleNo = data[index][column];
  let _billId = data[index].em_ac_no_id;
  if (_tab === 'customer' || _tab === 'document_center') {
    _billId = data[index].bill_id;
  }
  const _sliderTitle = `对账单号：${_settleNo}`;
  const _req = {};
  _req.bill_id = _billId;
  _req.bill_no = _settleNo;
  _req.category = _category;
  _req.expense = _expense;
  _req.type = _type;
  _req.tab = _tab;
  _req.bill_type = data[index].bill_type;
  _req.opposite_name = data[index].ac_name;
  _req.time = data[index].ac_time;
  _req.remark = data[index].remark;
  if (['customer_consignor', 'customer_consignee', 'customer_partner'].includes(data[index].customer_type)) {
    _req.tab = data[index].customer_type;
    _req.type = 'customer';
  }
  new PopUp(getSliderDetail(), {
    // popName: 'settleAcNoSlider',
    popName: `finance_${pageKey}`,
    pageThat: that,
    refreshTab: that.refreshTab,
    sliderTitle: _sliderTitle,
    req: _req,
  }).show();
};
// 收款单：详情
export const settleReNoBak = function (that, index, column) {
  console.log('收款单：详情', index, column, that);
  const pageKey = getPageKey().pageConfig.key;
  const curReq = that.getReq();
  const _category = curReq.category;
  const _expense = curReq.expense;
  // let _type = curReq.type
  const _tab = curReq.tab;
  const data = that.getData();
  const _billId = data[index].re_no_id;
  const _settleNo = data[index][column];
  const _sliderTitle = `收款单号：${_settleNo}`;
  const _req = {};
  _req.bill_id = _billId;
  _req.bill_no = _settleNo;
  _req.category = _category;
  _req.expense = _expense;
  _req.type = 'receipt_payment';
  _req.tab = _tab;
  _req.bill_type = data[index].bill_type;
  _req.opposite_name = data[index].ac_name;
  _req.time = data[index].ac_time;
  _req.remark = data[index].remark;
  new PopUp(getSliderDetail(), {
    popName: `finance_${pageKey}`,
    pageThat: that,
    refreshTab: that.refreshTab,
    sliderTitle: _sliderTitle,
    req: _req,
  }).show();
};
// 付款单：详情
export const settlePaNoBak = function (that, index, column) {
  console.log('付款单：详情', index, column, that);
  const pageKey = getPageKey().pageConfig.key;
  const curReq = that.getReq();
  const _category = curReq.category;
  const _expense = curReq.expense;
  // let _type = curReq.type
  const _tab = curReq.tab;
  const data = that.getData();
  const _billId = data[index].pa_no_id;
  const _settleNo = data[index][column];
  const _sliderTitle = `付款单号：${_settleNo}`;
  const _req = {};
  _req.bill_id = _billId;
  _req.bill_no = _settleNo;
  _req.category = _category;
  _req.expense = _expense;
  _req.type = 'receipt_payment';
  _req.tab = _tab;
  _req.bill_type = data[index].bill_type;
  _req.opposite_name = data[index].ac_name;
  _req.time = data[index].ac_time;
  _req.remark = data[index].remark;
  new PopUp(getSliderDetail(), {
    popName: `finance_${pageKey}`,
    pageThat: that,
    refreshTab: that.refreshTab,
    sliderTitle: _sliderTitle,
    req: _req,
  }).show();
};
// 客户对账单：详情
export const settleCusAcNoBak = function (that, index, column) {
  console.log('客户对账单：详情', index, column, that);
  const pageKey = getPageKey().pageConfig.key;
  const curReq = that.getReq();
  const _category = curReq.category;
  const _expense = curReq.expense;
  // let _type = curReq.type
  const _tab = curReq.tab;
  const data = that.getData();
  const _billId = data[index].cus_ac_no_id;
  const _settleNo = data[index][column];
  const _sliderTitle = `对账单号：${_settleNo}`;
  const _req = {};
  _req.bill_id = _billId;
  _req.bill_no = _settleNo;
  _req.category = _category;
  _req.expense = _expense;
  _req.type = 'customer';
  _req.tab = _tab;
  _req.bill_type = data[index].bill_type;
  _req.opposite_name = data[index].ac_name;
  _req.time = data[index].ac_time;
  _req.remark = data[index].remark;
  new PopUp(getSliderDetail(), {
    popName: `finance_${pageKey}`,
    pageThat: that,
    refreshTab: that.refreshTab,
    sliderTitle: _sliderTitle,
    req: _req,
  }).show();
};
// 网点对账单：详情
export const settlePoAcNoBak = function (that, index, column) {
  console.log('网点对账单：详情', index, column, that);
  const pageKey = getPageKey().pageConfig.key;
  const curReq = that.getReq();
  const _category = curReq.category;
  const _expense = curReq.expense;
  // let _type = curReq.type
  const _tab = curReq.tab;
  const data = that.getData();
  const _settleNo = data[index][column];
  let _billId = data[index].po_ac_no_id;
  if (_tab === 'point' || _tab === 'document_center') {
    _billId = data[index].bill_id;
  }
  const _sliderTitle = `对账单号：${_settleNo}`;
  const _req = {};
  _req.bill_id = _billId;
  _req.bill_no = _settleNo;
  _req.category = _category;
  _req.expense = _expense;
  _req.type = 'point';
  _req.tab = _tab;
  _req.bill_type = data[index].bill_type;
  _req.opposite_name = data[index].ac_name;
  _req.time = data[index].ac_time;
  _req.remark = data[index].remark;
  new PopUp(getSliderDetail(), {
    // popName: 'settlePoAcNoSlider',
    popName: `finance_${pageKey}`,
    pageThat: that,
    refreshTab: that.refreshTab,
    sliderTitle: _sliderTitle,
    req: _req,
  }).show();
};
// 汇总页明细跳转
export const orderDetail = function (that, index) {
  console.log('汇总页明细跳转', index, that);
  const { entries } = Object;
  const data = that.getData();
  const curRowData = data[index];
  const curReq = that.getReq();
  const _tab = curReq.tab;
  const _type = curReq.type;
  // 获得筛选条件
  const filterQuery = that.getAllFitlerQuery();
  const comID = data[index].com_id || filterQuery.com_id;
  const curComId = parseFloat(comID);
  const _designateParam = {};
  _designateParam.com_id = [];
  _designateParam.com_id.push(curComId);
  _designateParam.billing_date = filterQuery.billing_date;
  // 客户对账
  if (_type === 'customerAC') {
    if (_tab === 'partner') {
      // 承运商
      _designateParam.trans_dn_carrier_id = curRowData.trans_dn_carrier_id;
    } else if (_tab === 'transpoint') {
      // 承运网点
      _designateParam.point_trans_dn_com_id = curRowData.point_trans_dn_com_id;
      // _designateParam.point_trans_dn_com_telephone = curRowData.point_trans_dn_com_telephone
    } else if (_tab === 'consignee') {
      // 收货客户
      _designateParam.cee_com = curRowData.cee_com || filterQuery.cee_com; // 货单位
      _designateParam.cee_name = curRowData.cee_name || filterQuery.cee_name; // 发货人
      _designateParam.cee_mobile = curRowData.cee_mobile || filterQuery.cee_mobile; // 发货人电话
    } else if (_tab === 'consignor') {
      // 发货客户
      _designateParam.cor_com = curRowData.cor_com || filterQuery.cor_com; // 发货单位
      _designateParam.cor_name = curRowData.cor_name || filterQuery.cor_name; // 发货人
      _designateParam.cor_mobile = curRowData.cor_mobile || filterQuery.cor_mobile; // 发货人电话
    }
  }
  // 网点对账
  if (_type === 'pointOrder' && _tab === 'pointOrder') {
    _designateParam.account_company = data[index].account_company || filterQuery.account_company;
  }
  const __designateParam = {};
  for (const [key, value] of entries(_designateParam)) {
    __designateParam[key] = value || '';
    // 所有条件都重新传值， 才能更新之前的值
    // if (value) {
    //   __designateParam[key] = value
    // }
  }
  let _action;
  // 客户对账
  if (_type === 'customerAC') {
    _action = 'cliRelDet';
  }
  // 网点对账
  if (_type === 'pointOrder') {
    _action = 'branchRecDet';
  }
  const urlData = {
    controller: 'Finance',
    action: _action,
  };
  const pack = {
    designateParam: { ignore_default: true },
    designateFilter: __designateParam,
    initFilterHeader: that.getHeader(),
  };
  urlData.injectProps = pack;
  window.$app.direct(urlData);
};

/**
 * 对账汇总跳明细 tab，哪些查询参数带过去
 *
 * @param {string} tab - The tab value used to determine which parameters to filter
 * @param {Object} that - The object containing the parameters to be filtered
 * @returns {Object} - An object containing only the parameters that match the specified tab and are included in the shouldPickParam array
 */
function pickFetchParam(tab, that) {
  // 客户运单对账，司机对账，车辆对账，承运商对账，项目运单对账
  const shouldPickTabs = ['client_batch', 'driver_batch', 'car_batch', 'team_batch', 'project'];
  // 创建时间，始发发车时间，终点到达时间，亏吨重量（上游），承运商，预计靠台时间，预计发车时间
  const shouldPickParam = [
    'create_time',
    'head_truck_t',
    'end_arr_t',
    'income_shortfall_weight',
    'b_tr_team_id',
    'plan_platform_time',
    'plan_truck_t',
  ];
  // 需要取已经提交查询的筛选条件
  const fetchParam = that.getAllFitlerQuery?.(false, true) ?? {};

  if (!shouldPickTabs.includes(tab)) {
    return {};
  }

  const params = _.pick(fetchParam, shouldPickParam);
  // create_time 不传值的话，接口会有一个默认值
  if (!params.create_time) {
    params.create_time = '';
  }

  return params;
}

// 网点对账汇总列表 - 明细 -运单维度明细跳转
// 网点对账汇总、发货客户汇总 、收货客户汇总、员工交账明细（不用去除默认时间）
// 添加designateParam: { ignore_default: true }, 跳到的页面 不使用默认筛选值
export const financeSumDetail = async function (that, index) {
  console.log('网点对账汇总列表-明细-明细跳转', index, that);
  const { entries } = Object;
  const data = that.getData();
  // const data = that.getEnumItemData(),
  const rowData = that.formatRowEnumItem(data[index]);
  const currHeader = that.getHeader() || {}; // 跳转用header信息 有字段名转变时， 或新加筛选值时， header里要相应增加配置值
  const curReq = that.getReq();
  const _type = curReq.type;
  const _category = curReq.category;
  let _tab = curReq.tab;
  // let curComId = rowData.com_id
  // 获得筛选条件
  const filterQuery = that.getAllFitlerQuery(); // 只有枚举id的筛选
  const filterQueryWidthEnum = that.getAllFitlerQuery(true); // 带有完整枚举的筛选
  let _designateParam = {};
  if (_type !== 'account' && _type !== 'pointOrder') {
    for (const [key, value] of entries(filterQueryWidthEnum)) {
      // 所有条件都重新传值， 才能更新之前的值
      _designateParam[key] = rowData[key] || value || '';
    }
    _designateParam.employee_ac_p = rowData.mgr_id;
    currHeader.employee_ac_p = currHeader.mgr_id; // 跳转用header信息 有字段名转变时， 或新加筛选值时， header里要相应增加配置值
    // _designateParam.employee_ac_p = rowData.user_name
  }
  if (_type === 'pointOrder') {
    // 财务报表，应收应付表，往来费用，点击费用跳转到网点对账汇总，到点明细跳时，会联带出筛选面板的网点ID
    filterQueryWidthEnum.com_id = '';

    // 网点对账汇总 - 运单维度的跳转，  批次维度再financialOp里了
    // 跳转只带入以下筛选： 网点、对账网点、开单时间、发车时间、签收时间， 运输方向
    // com_id,account_company,billing_date，b_tr_truck_t,sign_t，  trsp_direction
    _designateParam.com_id = rowData.com_id;
    _designateParam.account_id = rowData.account_company;
    currHeader.account_id = currHeader.account_company; // 跳转用header信息 有字段名转变时， 或新加筛选值时， header里要相应增加配置值
    _designateParam.trsp_direction = 'up';
    if (data[index].account_company === data[index].prev_com_id) {
      // 比较值是保持 data[index] 原值比较
      _designateParam.trsp_direction = 'down';
    }
    !currHeader.trsp_direction &&
      (currHeader.trsp_direction = { key: 'trsp_direction', refEnum: 'trsp_direction', filterable: true }); // 保证转为下拉
    const skipConfig = pageSkipConfig[`${_category}_financeSumDetail`] || {};
    const skipParam = skipConfig.opParam || [];
    const primaryFilter = skipConfig.primaryFilter || [];
    skipParam.forEach(item => {
      if (filterQueryWidthEnum[item.inKey]) _designateParam[item.outKey] = filterQueryWidthEnum[item.inKey];
      if (!currHeader[item.outKey]) currHeader[item.outKey] = item.header;
    });
    !(_category === 'FinanceSum' && _tab === 'point_order_up' && _designateParam.b_tr_truck_t) &&
      primaryFilter.forEach(key => {
        if (!_designateParam[key]) {
          _designateParam[key] = isset(currHeader, `${key}.defaultValue`, initDate());
        }
      });
    // _designateParam.opposite_id = _designateParam.account_id
    // delete _designateParam.account_company
    // if (!_designateParam.b_tr_truck_t) delete _designateParam.b_tr_truck_t
    // if (!_designateParam.sign_t) delete _designateParam.sign_t
  }
  // receipt_payment 客户订单对账 汇总跳转明细
  if (_type === 'customerAC' || _type === 'receipt_payment') {
    _designateParam = {};
    _designateParam.com_id = rowData.com_id;

    if (_type === 'receipt_payment') {
      for (const [key, value] of entries(filterQueryWidthEnum)) {
        // 所有条件都重新传值， 才能更新之前的值
        _designateParam[key] = rowData[key] || value || '';
      }
    }

    _designateParam.billing_date = filterQuery.billing_date || initDate();
    if (!currHeader.billing_date) {
      currHeader.billing_date = RANGETIMEFILTERPROPS();
    }
    if (_tab === 'consignor') {
      _designateParam['cor_com._exact_'] = rowData.cor_com;
      _designateParam['cor_name._exact_'] = rowData.cor_name;
      _designateParam['cor_mobile._exact_'] = rowData.cor_mobile;

      if (!rowData.cor_mobile) {
        _designateParam['0'] = {
          0: {
            0: { id: '_EXIST_FIELD_', cor_mobile: '_EXIST_FIELD_' },
            _logic: 'not',
          },
          cor_mobile: ['', '_NULL_VALUE_'],
          _logic: 'or',
        };
      }
      // 如果发货人姓名，手机号同时为空
      if (!rowData.cor_mobile && !rowData.cor_name) {
        _designateParam['1'] = {
          0: {
            0: { id: '_EXIST_FIELD_', cor_name: '_EXIST_FIELD_' },
            _logic: 'not',
          },
          cor_name: ['', '_NULL_VALUE_'],
          _logic: 'or',
        };
      }
    } else if (_tab === 'consignee') {
      _designateParam['cee_com._exact_'] = rowData.cee_com;
      _designateParam['cee_name._exact_'] = rowData.cee_name;
      _designateParam['cee_mobile._exact_'] = rowData.cee_mobile;

      if (!rowData.cee_mobile) {
        _designateParam['0'] = {
          0: {
            0: { id: '_EXIST_FIELD_', cee_mobile: '_EXIST_FIELD_' },
            _logic: 'not',
          },
          cee_mobile: ['', '_NULL_VALUE_'],
          _logic: 'or',
        };
      }
      // 如果发货人姓名，手机号同时为空
      if (!rowData.cee_mobile && !rowData.cee_name) {
        _designateParam['1'] = {
          0: {
            0: { id: '_EXIST_FIELD_', cee_name: '_EXIST_FIELD_' },
            _logic: 'not',
          },
          cee_name: ['', '_NULL_VALUE_'],
          _logic: 'or',
        };
      }
    } else if (_tab === 'transpoint') {
      _designateParam.point_trans_dn_com_id = rowData.point_trans_dn_com_id;
      // _designateParam.point_trans_dn_com_telephone = rowData.point_trans_dn_com_telephone
    } else if (
      [
        'project',
        'team',
        'team_batch', // v1 版本的承运商对账
        'driver',
        'driver_batch',
        'car',
        'car_batch',
        'dispatch',
        'dispatch_batch',
        'client_batch',
        'customer_reservation',
        'project_reservation',
        'receipt_payment_sum', // 客户订单汇总
      ].includes(_tab)
    ) {
      let _len = 0;
      rowData &&
        rowData.unique_id &&
        Object.keys(rowData.unique_id).forEach(x => {
          if (rowData.unique_id[x] && rowData.unique_id[x] !== '_NULL_VALUE_') {
            const _val = rowData.unique_id[x];
            const _header = that.getHeader()[x];
            if (_header && _header.fetchApi) {
              const _enum = that.getTableEnum()[_header.refEnum];
              const _obj = _enum.filter(v => +v[_header.uniqueKey] === +_val);
              _designateParam[x] = _obj;
            } else {
              _designateParam[x] = _val;
            }
          } else {
            const _des = {
              0: { 0: { id: '_EXIST_FIELD_' }, _logic: 'not' },
              _logic: 'or',
            };
            _des[0][0][x] = '_EXIST_FIELD_';
            if (
              (_tab === 'team' && x === 'b_tr_team_id') ||
              (_tab === 'client_batch' && x === 'customer_id') ||
              (_tab === 'customer_reservation' && x === 'customer_id') ||
              (_tab === 'project_reservation' && x === 'customer_id') ||
              (_tab === 'modify_settle' && x === 'customer_id')
              // (_tab === 'receipt_payment_sum' && x === 'ob_customer_id')
            ) {
              _des[x] = [0];
            } else {
              _des[x] = ['', '_NULL_VALUE_'];
            }
            _designateParam[_len] = _des;
            _len++;
          }
        });
      // 承运商批次对账，司机批次对账，项目客户对账，车辆批次对账，派单网点批次对账都是按照create_time来筛选
      if (
        [
          'team',
          'team_batch',
          'project',
          'project_v1',
          'driver_batch',
          'car_batch',
          'dispatch_batch',
          'client_batch',
          'customer_reservation',
          'project_reservation',
          'modify_settle',
        ].includes(_tab)
      ) {
        delete _designateParam.billing_date;
        _designateParam.create_time = filterQuery.create_time || initDate();
        if (!currHeader.create_time) {
          currHeader.create_time = RANGETIMEFILTERPROPS();
        }
      }
    } else {
      _designateParam['chain_trans_dn_carrier_name._exact_'] = rowData.chain_trans_dn_carrier_name;
      // _designateParam.trans_dn_carrier_phone = rowData.trans_dn_carrier_phone
    }

    _designateParam = {
      ..._designateParam,
      ...pickFetchParam(_tab, that),
    };

    const filter = {};
    for (const k of Object.keys(_designateParam)) {
      if (_designateParam[k]) {
        if (k.indexOf('._exact_') > 0) {
          filter[k.replace('._exact_', '')] = _designateParam[k];
        } else if (_designateParam[k]) {
          filter[k] = _designateParam[k];
        }
      }
    }

    // v1版本 的情况导致，详情的数据也需要添加tab信息
    _tab = _.get(that.getTableInfo(), 'pageConfig.tableInfo.tab');

    // 车队对账，项目客户汇总，项目对账明细
    if (['team', 'project', 'project_v1'].includes(_tab)) _tab = `${_tab}Batch`;

    _tab = `${_tab}Detail`;

    if (_type === 'receipt_payment') _tab = `receipt_payment_v1`; // 客户订单对账 汇总跳转明细，目前只有 1.0 版本有

    that.handleChangeTabs(_tab, {
      designateFilter: _designateParam,
      defaultFilter: filter,
      designateParam: { ignore_default: true },
      designateHeader: currHeader,
      shouldRefresh: true,
    });
    return;
  }
  let _action = 'branchRecDet';
  let _activeTab;
  // 员工交账
  if (_type === 'account') {
    _activeTab = 'account';
    _action = 'handAccountDetail';
    const expenseType = isset(filterQuery, 'expense_type', []) || [];
    const billType = isset(filterQuery, 'bill_type', []) || [];
    let _resultBillType = [];
    let _resultExpenseType = [];
    // 如果单据类型为空 存在费用类别 则利用费用类别反推
    if (expenseType.length && !billType.length) {
      _resultBillType = isset(await getBillType(expenseType[0]), 'res', []);
    }
    // 取首个单据类型
    if (billType.length || _resultBillType.length) {
      _activeTab = (filterQuery.bill_type && filterQuery.bill_type[0]) || (_resultBillType && _resultBillType[0]);
      _activeTab === '_NULL_' && (_activeTab = 'account');
      _resultExpenseType = isset(await getExpenseType(_activeTab), 'res', []);
      _designateParam.expense_text = expenseType.filter(item =>
        Object.values(_resultExpenseType)
          .map(innerItem => innerItem.key)
          .includes(item),
      );
    }
    if (!hasPermission(permMap[_activeTab].permission)) {
      createTip(`您需要${permMap[_activeTab].tip}权限才可查看!`, ERROR).show();
      return;
    }
    if (_tab !== 'employee_ac_total') {
      delete currHeader.billing_date;
    }
    _activeTab = _tab.replace('employee_ac_total', 'account');
  }
  // if (_type === 'pointOrder') {
  //   delete _designateParam.company_id
  //   delete _designateParam.create_time
  // }
  if (_type !== 'pointOrder') {
    delete _designateParam.company_id;
    delete _designateParam.create_time;
    const _filterType = ['_NULL_', 'account'].includes(_activeTab) ? 'settle' : 'batch';
    const _filterList = [
      { srcKey: 'com_id', settleKey: 'com_id', batchKey: 'company_id' },
      { srcKey: 'mgr_id', settleKey: 'employee_ac_p', batchKey: 'employee_ac_p' },
      { srcKey: 'billing_date', settleKey: 'billing_date', batchKey: 'create_time' },
    ];
    _filterList.forEach(item => {
      if (rowData[item.srcKey] || filterQuery[item.srcKey]) {
        const _dpKey = item[`${_filterType}Key`];
        _designateParam[_dpKey] = rowData[item.srcKey] || filterQueryWidthEnum[item.srcKey];
        if (_dpKey !== item.srcKey && currHeader[item.srcKey]) {
          // 跳转用header信息 有字段名转变时， 或新加筛选值时， header里要相应增加配置值
          currHeader[_dpKey] = currHeader[item.srcKey];
        }
      }
    });
    // bug 31736 start
    const billCreat = _filterType === 'settle' ? 'billing_date' : 'create_time';
    if (!_designateParam[billCreat]) {
      currHeader[billCreat] = RANGETIMEFILTERPROPS();
      _designateParam[billCreat] = initDate();
    }
    // bug 31736 end
  }

  // 有日期等 范围筛选的 需要传入 initFilterHeader
  const urlData = {
    controller: 'Finance',
    action: _action,
    injectProps: {
      activeTab: _activeTab,
      designateParam: { ignore_default: true },
      designateFilter: _designateParam,
      initFilterHeader: { ...currHeader, create_time: RANGETIMEFILTERPROPS() },
    },
  };
  window.$app.direct(urlData);
};
/**
 * 下面的代码是重构后的，后期代码都要写在重构里
 * /

 /*
 * 获得页面参数:
 *  tab
 *  type,
 *  category
 */
const getPageParam = function (options) {
  const attr = options || {};
  const butKey = attr.buttonKey;
  const pageContext = attr.pageInfo;
  const pageReq = pageContext.getReq();
  let _tab = pageReq.tab;
  let _type = pageReq.type;
  const _category = pageReq.category;
  const _refreshTab = pageContext.refreshTab;
  if (butKey === 'moneyFlowDetailCancel' && _tab === 'detail') {
    _tab = 'settleLogDetail_cancel';
    _type = 'settleLogDetail_cancel';
  }
  if (
    butKey === 'deliveryCancelConfirm' ||
    butKey === 'shuttleCancelConfirm' ||
    butKey === 'toCancelConfirm' ||
    butKey === 'employeeCancel' ||
    butKey === 'receiptPaymentCancel' ||
    butKey === 'pointCancel' ||
    butKey === 'customerCancel'
  ) {
    _tab = `${_tab}_cancel`;
    _type = _tab;
  }
  if (
    // butKey === '' ||
    // butKey === '' ||
    butKey === 'pointSettle' ||
    butKey === 'balanceSettle' ||
    butKey === 'employeeSettle' ||
    butKey === 'receiptPaymentSettle' ||
    butKey === 'customerSettle'
  ) {
    _tab = `${_tab}_settle`;
    _type = _tab;
  }
  if (
    // butKey === '' ||
    // butKey === '' ||
    butKey === 'receiptPaymentReject'
  ) {
    _tab = `${_tab}_reject`;
    _type = _tab;
  }
  if (
    // butKey === '' ||
    // butKey === '' ||
    butKey === 'receiptPaymentReview'
  ) {
    _tab = `${_tab}_review`;
    _type = _tab;
  }
  if (butKey === 'moneyAudit') {
    _tab = 'moneyAudit';
    _type = _tab;
  }
  if (butKey === 'moneyCancelAudit') {
    _tab = 'moneyCancelAudit';
    _type = _tab;
  }
  if (
    // butKey === '' ||
    // butKey === '' ||
    butKey === 'customerSubmit'
  ) {
    _tab = `${_tab}_submit`;
    _type = _tab;
  }
  if (
    // butKey === '' ||
    // butKey === '' ||
    butKey === 'cusReview' ||
    butKey === 'cusReject'
  ) {
    _tab = `${_tab}_audit`;
    _type = _tab;
  }
  if (
    // butKey === '' ||
    // butKey === '' ||
    butKey === 'customerCancelAudit'
  ) {
    _tab = `${_tab}_cancel_audit`;
    _type = _tab;
  }
  if (butKey === 'celCoDeliveryRmt' || butKey === 'CelCoDeliveryAvl' || butKey === 'coDeliveryAvlBatch') {
    _type = _tab;
  }
  if (butKey === 'employeeCancelReview') {
    _tab = 'employee_cancel_review';
  }
  const param = {};
  param.tab = _tab;
  param.type = _type;
  param.category = _category;
  param.buttonKey = butKey;
  param.refreshTab = _refreshTab;
  param.handleRefreshData = pageContext.handleRefreshData;
  return param;
};
/*
 * 获得列表里选中行的要操作信息
 * 要操作的ID、费用项、费用
 * 费用项明细
 * 费用项合计
 */
const getSelectInfo = function (options) {
  const attr = options || {};
  const butKey = attr.buttonKey;
  const pageContext = attr.pageInfo;
  const pageParam = getPageParam({
    buttonKey: butKey,
    pageInfo: pageContext,
  });
  // 获得 Table 中想要的信息
  let rowInfo;
  if (
    pageParam.tab === 'point_cancel' ||
    pageParam.tab === 'balance_cancel' ||
    pageParam.tab === 'account_cancel' ||
    pageParam.tab === 'customer_cancel' ||
    pageParam.tab === 'customer_submit' ||
    pageParam.tab === 'customer_audit' ||
    // pageParam.tab === 'cusReject' ||
    pageParam.tab === 'customer_cancel_audit' ||
    pageParam.tab === 'employee_cancel_review' ||
    pageParam.tab === 'receipt_payment_cancel' ||
    pageParam.tab === 'settleLogDetail_cancel' ||
    pageParam.tab === 'receipt_payment_reject' ||
    pageParam.tab === 'receipt_payment_review'
  ) {
    const _req = {};
    _req.bill_id = 'bill_id';
    if (pageParam.tab === 'settleLogDetail_cancel') {
      _req.expense_id = 'expense_id';
      _req.amount = 'settle_amount';
      _req.expense = 'expense';
      _req.expense_disp = 'expense_disp';
    }
    rowInfo = getPageTableInfo({
      pageInfo: pageContext,
      req: _req,
    });
  } else {
    let genId = 'od_link_id';
    if (pageParam.category === 'Batch') {
      genId = 'b_link_id';
    }
    if (
      // pageParam.tab === '' ||
      // pageParam.tab === '' ||
      pageParam.tab === 'moneyAudit' ||
      pageParam.tab === 'moneyCancelAudit' ||
      pageParam.tab === 'point_settle' ||
      pageParam.tab === 'balance_settle' ||
      pageParam.tab === 'account_settle' ||
      pageParam.tab === 'receipt_payment_settle' ||
      pageParam.tab === 'customer_settle'
    ) {
      genId = 'bill_id';
    }
    if (pageParam.tab === 'remit_batch' || pageParam.tab === 'arrival_batch') {
      genId = 'id';
    }
    if (pageParam.tab === 'remit_batch_list') {
      genId = 'rmt_id';
    }
    if (pageParam.tab === 'arrival_batch_list') {
      genId = 'dn_rmt_id';
    }
    if (
      butKey === 'receiptPaymentSettle' ||
      butKey === 'employeeSettle' ||
      butKey === 'customerSettle' ||
      butKey === 'pointSettle'
    ) {
      genId = ['bill_id'];
    }
    rowInfo = getPageTableRowId({
      pageInfo: pageContext,
      idKey: genId,
    });
  }
  return rowInfo;
};
/*
 * 获得要进行操作的相关参数
 */
const getRigParam = function (options) {
  const attr = options || {};
  const butKey = attr.buttonKey;
  const pageContext = attr.pageInfo;
  const selectRowInfo = getSelectInfo({
    buttonKey: butKey,
    pageInfo: pageContext,
  });
  const pageParam = getPageParam({
    buttonKey: butKey,
    pageInfo: pageContext,
  });
  const rigParam = {};
  if (manipulateParam.param.reqIsCheck.includes(pageParam.tab)) rigParam.is_check = 1;
  if (manipulateParam.param.reqIsType.includes(pageParam.tab)) rigParam.type = pageParam.type;
  if (manipulateParam.param.reqIsTab.includes(pageParam.tab)) rigParam.tab = pageParam.tab;
  if (pageParam.tab === 'receipt_payment_cancel') rigParam.is_check = 2;
  if (
    pageParam.tab === 'confirm_batch_to' ||
    pageParam.tab === 'confirm_batch_shuttle' ||
    pageParam.tab === 'confirm_batch_delivery'
  ) {
    rigParam.type = 'confirm';
  }
  if (
    pageParam.tab === 'confirm_batch_delivery_cancel' ||
    pageParam.tab === 'confirm_batch_shuttle_cancel' ||
    pageParam.tab === 'confirm_batch_to_cancel'
  ) {
    rigParam.type = 'confirm_cancel';
  }
  if (
    pageParam.tab === 'ledger_batch_delivery' ||
    pageParam.tab === 'ledger_batch_shuttle' ||
    pageParam.tab === 'ledger_batch_to'
  ) {
    rigParam.type = 'ledger';
  }
  if (pageParam.tab === 'customer_audit') {
    if (pageParam.buttonKey === 'cusReview') {
      rigParam.type = 'customer_review';
    } else if (pageParam.buttonKey === 'cusReject') {
      rigParam.type = 'customer_reject';
    }
  }
  if (
    // pageParam.tab === '' ||
    // pageParam.tab === '' ||
    // pageParam.tab === '' ||
    // pageParam.tab === '' ||
    pageParam.tab === 'moneyAudit' ||
    pageParam.tab === 'moneyCancelAudit' ||
    pageParam.tab === 'settleLogDetail_cancel' ||
    pageParam.tab === 'point_settle' ||
    pageParam.tab === 'balance_settle' ||
    pageParam.tab === 'account_settle' ||
    pageParam.tab === 'receipt_payment_settle' ||
    pageParam.tab === 'receipt_payment_cancel' ||
    pageParam.tab === 'balance_cancel' ||
    pageParam.tab === 'account_cancel' ||
    pageParam.tab === 'point_cancel' ||
    pageParam.tab === 'customer_settle' ||
    pageParam.tab === 'customer_cancel' ||
    pageParam.tab === 'customer_submit' ||
    pageParam.tab === 'customer_audit' ||
    pageParam.tab === 'customer_cancel_audit' ||
    pageParam.tab === 'employee_cancel_review' ||
    pageParam.tab === 'receipt_payment_reject' ||
    pageParam.tab === 'receipt_payment_review'
  ) {
    rigParam.data = selectRowInfo;
  } else if (pageParam.tab === 'remit_batch' || pageParam.tab === 'arrival_batch') {
    rigParam.rmt_id = selectRowInfo;
  } else if (pageParam.tab === 'remit_batch_list' || pageParam.tab === 'arrival_batch_list') {
    rigParam.rmt_id = selectRowInfo;
  } else {
    rigParam.link_ids = selectRowInfo;
  }
  return rigParam;
};
/*
 * 获得要跳转页面的URL
 * 主要针对左右挑单的相关操作
 */
const getUrl = function (options) {
  const attr = options || {}; // eslint-disable-line
  // const butKey = attr.buttonKey
  const _controller = 'Finance';
  const _action = 'pickOrder';
  const urlData = {
    controller: _controller,
    action: _action,
  };
  return urlData;
};
// 操作处理
const operationHandle = function (options) {
  const attr = options || {};
  const butKey = attr.buttonKey;
  const pageContext = attr.pageInfo;
  const formPickClip = isFromPickClip(pageContext);
  if (manipulateParam.param.isSelectRow.includes(butKey)) {
    // 判断是否有选中行
    if (formPickClip) {
      if (!pageContext.getData().legnth) return false;
    } else if (!tipSelect(pageContext)) return false;
  }
  // 页面上下文参数
  const pageParam = getPageParam({
    buttonKey: butKey,
    pageInfo: pageContext,
  });
  // console.log('页面上下文参数', pageParam)
  // 选中行中要操作参数
  const rigParam = getRigParam({
    pageInfo: pageContext,
    buttonKey: butKey,
  });
  // 获得 Table 中想要的信息
  const _keys = manipulateParam.param.selectRowInfoKeys[butKey];
  const listSelectRowInfo = getOptRowInfo({
    pageInfo: pageContext,
    keys: _keys,
  });
  console.log('列表选中行信息', listSelectRowInfo);
  // if (attr) {
  // console.log('调试操作', pageParam, rigParam)
  // return false
  // }
  // 判断是否直接弹出操作框
  if (manipulateParam.param.isRig.includes(pageParam.tab)) {
    // 判断是否有选中行
    if (!tipSelect(pageContext)) return false;
    const manipulateConfig = {};
    manipulateConfig.btnKey = butKey;
    manipulateConfig.buttonKey = butKey;
    manipulateConfig.req = rigParam;
    if (manipulateParam.param.isCheck.includes(pageParam.tab)) {
      manipulateConfig.isCheck = true;
    }
    if (manipulateParam.param.isTip.includes(pageParam.tab)) {
      manipulateConfig.isTip = true;
    }
    if (manipulateParam.param.getPCAmount.includes(pageParam.tab)) {
      manipulateConfig.getPCAmount = true;
    }
    if (manipulateParam.param.getPayMode.includes(pageParam.tab)) {
      // manipulateConfig.getPayMode = true
      // 账单列表的结算，只有勾选一条的时候才弹出收支方式dialog
      if (rigParam.data.length > 1) {
        manipulateConfig.isCheck = true;
        manipulateConfig.opSymbol = pageParam.tab;
        manipulateConfig.refreshTab = pageParam.refreshTab;
      } else {
        manipulateConfig.getPayMode = true;
        manipulateConfig.req = { bill_id: manipulateConfig.req.data[0].bill_id };
        console.log('manipulateConfig', manipulateConfig);
      }
    }
    if (manipulateParam.param.refreshTab.includes(pageParam.tab)) {
      manipulateConfig.refreshTab = pageParam.refreshTab;
      manipulateConfig.handleRefreshData = pageParam.handleRefreshData;
    }
    if (manipulateParam.param.pageTab.includes(pageParam.tab)) {
      manipulateConfig.pageTab = pageParam.tab;
    }
    if (manipulateParam.param.opSymbol.includes(pageParam.tab)) {
      manipulateConfig.opSymbol = pageParam.tab;
    }
    const pickOrderCheckKey = `${pageParam.tab}_${butKey}`;
    const optRowLen = rigParam.data ? rigParam.data.length : [];
    if (manipulateParam.param.pickOrderCheck.includes(pickOrderCheckKey) && optRowLen > 0) {
      const checkUrl = `${pageParam.tab}_check`;
      rigParam.is_check = 1;
      opVerify({
        buttonKey: butKey,
        opUrl: manipulateParam.apiUrl[checkUrl],
        param: rigParam,
        errText: '以下运单',
        callFn: resParam => {
          // let packObj = {}
          // packObj.link_ids = resParam.success_id
          // packObj.type = butKey
          rigParam.link_ids = resParam.success_id;
          if (resParam.isContinue) {
            if (manipulateParam.param.isReamk.includes(pageParam.tab)) {
              new PopUp(RigReamrk, manipulateConfig).show();
            } else {
              manipulateConfig.req.is_check = 0;
              manipulate(manipulateConfig);
            }
          }
        },
      });
      return false;
    }
    if (manipulateParam.param.isReamk.includes(pageParam.tab)) {
      new PopUp(RigReamrk, manipulateConfig).show();
      return false;
    }
    manipulate(manipulateConfig);
    return false;
  }
  // 类似汇款到账的操作
  if (manipulateParam.param.feeOp.includes(butKey)) {
    const feeOpParam = {};
    feeOpParam.pageParam = pageParam;
    feeOpParam.req = rigParam;
    if (butKey === 'celCoDeliveryRmt' || butKey === 'coDeliveryAvlBatch' || butKey === 'CelCoDeliveryAvl') {
      feeOpParam.req.od_link_ids = listSelectRowInfo;
    }
    feeOpParam.req.buttonKey = butKey;
    feeOpParam.buttonKey = butKey;
    feeOpParam.refreshTab = pageParam.refreshTab;
    feeOpParam.handleRefreshData = pageParam.handleRefreshData;
    const checkParam = rigParam;
    if (butKey === 'celCoDeliveryRmt' || butKey === 'coDeliveryAvlBatch' || butKey === 'CelCoDeliveryAvl') {
      checkParam.od_link_ids = listSelectRowInfo;
    }
    checkParam.is_check = 1;
    opVerify({
      buttonKey: butKey,
      opUrl: operateFnConfig.apiUrl[butKey],
      param: checkParam,
      errText: '以下运单',
      callFn: resParam => {
        console.log(resParam);
        new PopUp(FeeOperate, feeOpParam).show();
      },
    });
    return false;
  }
  // 跳转到左右挑单Tab
  const urlData = getUrl({
    buttonKey: butKey,
  });
  urlData.query = {};
  urlData.query.tabName = manipulateParam.tabName[`${butKey}_${pageParam.tab}`];
  console.log(`${butKey}_${pageParam.tab}`, 'urlData', urlData);
  const pickOrderCheckKey = `${pageParam.tab}_${butKey}`;
  const optRowLen = rigParam.link_ids.length;
  if (manipulateParam.param.pickOrderCheck.includes(pickOrderCheckKey) && optRowLen > 0) {
    const checkUrl = `${pageParam.tab}_check`;
    opVerify({
      opUrl: manipulateParam.apiUrl[checkUrl],
      param: rigParam,
      errText: '以下运单',
      callFn: resParam => {
        // let packObj = {}
        // packObj.link_ids = resParam.success_id
        // packObj.type = butKey
        rigParam.link_ids = resParam.success_id;
        if (resParam.isContinue) {
          actions.api.direct(urlData, 'prevPagePack', rigParam);
        }
      },
    });
  } else {
    actions.api.direct(urlData, 'prevPagePack', rigParam);
  }
};
// 货款发放
export const coDeliveryPaid = function (that) {
  console.log('货款发放', that);
  operationHandle({
    pageInfo: that,
    buttonKey: 'coDeliveryPaid',
  });
  // let curReq = that.getReq()
  // let _category = curReq.category
  // // 获得 Table 中想要的信息
  // let _res = getPageTableRowId({
  // pageInfo: that,
  // idKey: 'od_link_id'
  // })
  // let pack = {}
  // pack.link_ids = _res
  // // pack.expense = curReq.expense || '-1'
  // pack.category = _category
  // pack.type = curReq.type
  // pack.tab = curReq.tab
  // let urlData = {
  // controller: 'Finance',
  // action: 'pickOrder'
  // }
  // actions.api.direct(urlData, 'prevPagePack', pack)
};
// 汇款接收
export const coDeliveryAvl = function (that) {
  console.log('货款接收', that);
  operationHandle({
    pageInfo: that,
    buttonKey: 'coDeliveryAvl',
  });
  // let curReq = that.getReq()
  // let _category = curReq.category
  // // 获得 Table 中想要的信息
  // let _res = getPageTableRowId({
  // pageInfo: that,
  // idKey: 'od_link_id'
  // })
  // let pack = {}
  // pack.link_ids = _res
  // pack.category = _category
  // pack.type = curReq.type
  // pack.tab = curReq.tab
  // let urlData = {
  // controller: 'Finance',
  // action: 'pickOrder'
  // }
  // actions.api.direct(urlData, 'prevPagePack', pack)
};
// 货款汇款
export const coDeliveryRmt = function (that) {
  console.log('货款汇款', that);
  operationHandle({
    pageInfo: that,
    buttonKey: 'coDeliveryRmt',
  });
  // let curReq = that.getReq()
  // let _category = curReq.category
  // // 获得 Table 中想要的信息
  // let _res = getPageTableRowId({
  // pageInfo: that,
  // idKey: 'od_link_id'
  // })
  // let pack = {}
  // pack.link_ids = _res
  // // pack.expense = curReq.expense || '-1'
  // pack.category = _category
  // pack.type = curReq.type
  // pack.tab = curReq.tab
  // let urlData = {
  // controller: 'Finance',
  // action: 'pickOrder'
  // }
  // actions.api.direct(urlData, 'prevPagePack', pack)
};
// 货款回收
export const coDeliveryRcp = function (that) {
  console.log('货款回收', that);
  operationHandle({
    pageInfo: that,
    buttonKey: 'coDeliveryRcp',
  });
  // let curReq = that.getReq()
  // let _category = curReq.category
  // // 获得 Table 中想要的信息
  // let _res = getPageTableRowId({
  // pageInfo: that,
  // idKey: 'od_link_id'
  // })
  // let pack = {}
  // pack.link_ids = _res
  // // pack.expense = curReq.expense || '-1'
  // pack.category = _category
  // pack.type = curReq.type
  // pack.tab = curReq.tab
  // let urlData = {
  // controller: 'Finance',
  // action: 'pickOrder'
  // }
  // actions.api.direct(urlData, 'prevPagePack', pack)
};
// 资金流水明细取消
export const moneyFlowDetailCancelBak = function (that) {
  console.log('资金流水明细取消');
  operationHandle({
    pageInfo: that,
    buttonKey: 'moneyFlowDetailCancel',
  });
};
// 客户对账单结算
// export const customerSettle = function (that) {
// console.log('客户对账单结算')
// operationHandle({
// pageInfo: that,
// buttonKey: 'customerSettle'
// })
// }
// 客户对账单取消
export const customerCancelBak = function (that) {
  console.log('客户对账单取消');
  operationHandle({
    pageInfo: that,
    buttonKey: 'customerCancel',
  });
};
// 收付款单结算
// export const receiptPaymentSettle = function (that) {
// console.log('收付款单结算')
// operationHandle({
// pageInfo: that,
// buttonKey: 'receiptPaymentSettle'
// })
// }
// 收付款单取消
export const receiptPaymentCancelBak = function (that) {
  console.log('收付款单取消');
  operationHandle({
    pageInfo: that,
    buttonKey: 'receiptPaymentCancel',
  });
};
// 员工交账单结算
// export const employeeSettle = function (that) {
// console.log('员工交账单结算')
// operationHandle({
// pageInfo: that,
// buttonKey: 'employeeSettle'
// })
// }
// 员工交账单取消
export const employeeCancelBack = function (that) {
  console.log('员工交账单取消');
  operationHandle({
    pageInfo: that,
    buttonKey: 'employeeCancel',
  });
};
// 网点对账单结算
// export const pointSettle = function (that) {
// console.log('网点对账单结算')
// operationHandle({
// pageInfo: that,
// buttonKey: 'pointSettle'
// })
// }
// 网点对账单取消
export const pointCancelBak = function (that) {
  console.log('网点对账单取消');
  operationHandle({
    pageInfo: that,
    buttonKey: 'pointCancel',
  });
};
// 财务挂账：到车批次挂账
export const toLedger = function (that) {
  console.log('财务挂账：到车批次挂账');
  operationHandle({
    pageInfo: that,
    buttonKey: 'toLedger',
  });
};
// 财务挂账：到车批次取消挂账
export const toCancelLedger = function (that) {
  console.log('财务挂账：到车批次取消挂账');
  operationHandle({
    pageInfo: that,
    buttonKey: 'toCancelLedger',
  });
};
// 财务挂账：短驳批次挂账
export const shuttleLedger = function (that) {
  console.log('财务挂账：短驳批次挂账');
  operationHandle({
    pageInfo: that,
    buttonKey: 'shuttleLedger',
  });
};
// 财务挂账：短驳批次取消挂账
export const shuttleCancelLedger = function (that) {
  console.log('财务挂账：短驳批次取消挂账');
  operationHandle({
    pageInfo: that,
    buttonKey: 'shuttleCancelLedger',
  });
};
// 财务挂账：送货批次挂账
export const deliveryLedger = function (that) {
  console.log('财务挂账：送货批次挂账');
  operationHandle({
    pageInfo: that,
    buttonKey: 'deliveryLedger',
  });
};
// 财务挂账：送货批次取消挂账
export const deliveryCancelLedger = function (that) {
  console.log('财务挂账：送货批次取消挂账');
  operationHandle({
    pageInfo: that,
    buttonKey: 'deliveryCancelLedger',
  });
};
// 财务挂账：提货批次挂账
export const pickupLedger = function (that) {
  console.log('财务挂账：提货批次挂账');
  operationHandle({
    pageInfo: that,
    buttonKey: 'pickupLedger',
  });
};
// 财务挂账：送货批次取消挂账
export const pickupCancelLedger = function (that) {
  console.log('财务挂账：提货批次取消挂账');
  operationHandle({
    pageInfo: that,
    buttonKey: 'pickupCancelLedger',
  });
};
// 财务挂账：在途费用挂账
export const onwayLedger = that => {
  console.log('财务挂账：在途费用挂账');
  operationHandle({
    pageInfo: that,
    buttonKey: 'onwayLedger',
  });
};
// 财务挂账：在途费用取消挂账
export const onwayCancelLedger = that => {
  console.log('财务挂账：在途费用取消挂账');
  operationHandle({
    pageInfo: that,
    buttonKey: 'onwayCancelLedger',
  });
};
// 业务确认：到车批次确认
export const toConfirm = function (that) {
  console.log('业务确认：到车批次确认');
  operationHandle({
    pageInfo: that,
    buttonKey: 'toConfirm',
  });
};
// 业务确认：到车批次取消确认
export const toCancelConfirm = function (that) {
  console.log('业务确认：到车批次取消确认');
  operationHandle({
    pageInfo: that,
    buttonKey: 'toCancelConfirm',
  });
};
// 业务确认：短驳批次确认
export const shuttleConfirm = function (that) {
  console.log('业务确认：短驳批次确认');
  operationHandle({
    pageInfo: that,
    buttonKey: 'shuttleConfirm',
  });
};
// 业务确认：短驳批次取消确认
export const shuttleCancelConfirm = function (that) {
  console.log('业务确认：短驳批次取消确认');
  operationHandle({
    pageInfo: that,
    buttonKey: 'shuttleCancelConfirm',
  });
};
// 业务确认：送货批次确认
export const deliveryConfirm = function (that) {
  console.log('业务确认：送货批次确认');
  operationHandle({
    pageInfo: that,
    buttonKey: 'deliveryConfirm',
  });
};
// 业务确认：送货批次取消确认
export const deliveryCancelConfirm = function (that) {
  console.log('业务确认：送货批次取消确认');
  operationHandle({
    pageInfo: that,
    buttonKey: 'deliveryCancelConfirm',
  });
};
// 业务确认：提货批次确认
export const pickupConfirm = function (that) {
  operationHandle({
    pageInfo: that,
    buttonKey: 'pickupConfirm',
  });
};
// 业务确认：提货批次取消确认
export const pickupCancelConfirm = function (that) {
  operationHandle({
    pageInfo: that,
    buttonKey: 'pickupCancelConfirm',
  });
};
// 收银管理：短途批次收银
export const shortCash = function (that) {
  console.log('收银管理：短途批次收银');
  operationHandle({
    pageInfo: that,
    buttonKey: 'shortCash',
  });
};
// 收银管理：短途批次取消收银
export const shortCancelCash = function (that) {
  console.log('收银管理：短途批次取消收银');
  operationHandle({
    pageInfo: that,
    buttonKey: 'shortCancelCash',
  });
};
// 收付款单：审批通过
export const receiptPaymentReview = function (that) {
  console.log('收付款单：审批通过');
  // if (!tipSelect(that)) return false
  operationHandle({
    pageInfo: that,
    buttonKey: 'receiptPaymentReview',
  });
};
// 收付款单：审批拒绝
export const receiptPaymentReject = function (that) {
  console.log('收付款单：审批拒绝');
  // if (!tipSelect(that)) return false
  operationHandle({
    pageInfo: that,
    buttonKey: 'receiptPaymentReject',
  });
};
// // 资金流水汇总：审核
// export const moneyAudit = function (that) {
//   console.log('资金流水汇总：审核')
//   operationHandle({
//     pageInfo: that,
//     buttonKey: 'moneyAudit'
//   })
// }
const moenyAuditMass = async (that, btnKey) => {
  const data = that.getSelectesKey('bill_id');
  if (!data.length) {
    return showInfo(ERROR, '请选择要操作数据');
  }
  const pageInfo = that.getReq();
  const url = 'Finance/FlowAudit/flowAudit';
  const tips1 = '请确认是否审核通过，确认后审核通过的资金流水明细数据所属的资金流水汇总将会一起审核！';
  const tips2 = '请确认是否审核通过，确认后审核通过的资金流水汇总数据中的资金流水明细将会全部审核！';
  const tips = pageInfo.tab === 'list' ? tips2 : tips1;
  const companyIDs = that.getSelectesKey('company_id');
  const openModal = ids =>
    new PopUp(AuditModal, {
      submitUrl: url,
      getReq: remark => ({
        audit_info: { remark },
        data: ids,
        type: 'moneyAudit',
      }),
      isMultiPoint: Array.from(new Set(companyIDs)).length > 1,
      tips,
      callBack: () => setTimeout(that.handleRefreshData, 2000),
    }).show();
  that.disableMenuButton(btnKey);
  const checkRes = await fetchApi(url, {
    method: 'POST',
    body: {
      req: {
        data,
        type: 'moneyAudit',
        is_check: 1,
      },
    },
  });
  that.enableMenuButton(btnKey);
  opTips({
    resData: checkRes,
    opType: 'moneyAudit',
    sucCallback: () => openModal(data),
    continueCallback: (sucIds, cb) => {
      cb();
      openModal(sucIds);
    },
    showSucTips: false,
    orderType: '数据',
  });
};
// 资金流水汇总：取消审核
export const moneyCancelAudit = (that, btnKey) => {
  const data = that.getSelectesKey('bill_id');
  if (!data.length) {
    return showInfo(ERROR, '请选择要操作数据');
  }
  const pageInfo = that.getReq();
  const url = 'Finance/FlowAudit/flowCancelAudit';
  const tips1 = '取消成功的资金流水明细数据所属的资金流水汇总将会一起取消审核。';
  const tips2 = '取消成功的资金流水汇总数据中的资金流水明细将会全部取消审核。';
  const reason = pageInfo.tab === 'list' ? tips2 : tips1;
  const post = async (ids, check = 1) => {
    that.disableMenuButton(btnKey);
    const checkRes = await fetchApi(url, {
      method: 'POST',
      body: {
        req: {
          data: ids,
          type: 'moneyCancelAudit',
          is_check: check,
        },
      },
    });
    that.enableMenuButton(btnKey);
    opTips({
      resData: checkRes,
      opType: 'moneyCancelAudit',
      continueCallback: (sucIds, cb) => {
        cb();
        post(sucIds, 0);
      },
      sucCallback: that.handleRefreshData,
      orderType: '数据',
    });
  };
  confirmWithReason({
    tips: '您确认要取消审核吗？',
    reason: (
      <Fragment>
        <em>●</em>
        {reason}
      </Fragment>
    ),
    noticeTitle: '取消后：',
    onConfirm: cb => {
      cb();
      post(data, 1);
    },
  });
};
// 日常收支--新增收据
export const createReceipt = function (that) {
  console.log('createReceipt');
  const pageKey = getPageKey().pageConfig.key;
  const popUpParam = {
    // popName: 'openRecExpPanel',
    popName: `finance_${pageKey}`,
    pageType: 'receipt',
    action: 'add',
    titleName: '新增收据',
    popContainer: that,
    opCallback: that.handleRefreshData,
    that,
  };
  const dialog = new PopUp(dailyFinanceSlideDrager, popUpParam);
  dialog.show();

  dataAnalyticTrack(['收支单汇总', '创建收据', '【创建收据】点击数']);
};
// 日常收支--新增报销单
export const createExpense = function (that) {
  console.log('createExpense');
  const pageKey = getPageKey().pageConfig.key;
  const popUpParam = {
    // popName: 'openRecExpPanel',
    popName: `finance_${pageKey}`,
    pageType: 'expense',
    action: 'add',
    titleName: '新增报销单',
    popContainer: that,
    opCallback: that.handleRefreshData,
    that,
  };
  const dialog = new PopUp(dailyFinanceSlideDrager, popUpParam);
  dialog.show();

  dataAnalyticTrack(['收支单汇总', '创建报销单', '【创建报销单】点击数']);
};
export const auditExpense = ({ that, auditType, appoint, data, selectedList, operations, rebut_direct }) => {
  console.log('auditExpense =', auditType, appoint);
  const pageKey = getPageKey().pageConfig.key;
  const billId = data[0].apply_id;
  const pageType = selectedList[0].bill_sort === '1' ? 'expense' : 'receipt';
  actions.financeServer
    .getIncomeExpenseData({
      bill_id: billId,
      bill_sort: pageType,
      action: 'view_check',
    })
    .then(res => {
      if (+res.data.errno !== 0) {
        showInfo(ERROR, res.message || res.data.errmsg || '网络错误');
        return;
      }
      const popUpParam = {
        popName: `finance_${pageKey}`,
        billId,
        pageType,
        action: 'view_check',
        titleName: '日常收支审核',
        popContainer: that,
        opCallback: that.handleRefreshData,
        that,
        appoint,
        auditType,
        auditData: data,
        data: res.data.res,
        operations,
        rebut_direct,
      };
      const dialog = new PopUp(dailyFinanceSlideDrager, popUpParam);
      dialog.show();
    });
};
// 处理服务器返回正确的情况
function handleSuccess(res, callSuccess, callFail) {
  const resData = res.data || {};
  if (res.type === 'API_SUCCESS' && +resData.errno === 0) {
    callSuccess && callSuccess(resData.res);
  } else {
    const list = [];
    const errorTypeMap = resData.res && resData.res.fail_detail;
    if (errorTypeMap) {
      const errorTypes = Object.keys(errorTypeMap);
      for (let i = 0, len = errorTypes.length; i < len; i++) {
        const errorMsg = errorTypes[i];
        list.push(`当前单据${errorMsg}，无法操作！`);
      }
    }
    callFail && callFail(resData.res);
    showInfo(ERROR, list.join('') || res.message || resData.errmsg || '网络错误');
  }
}

/*
 * 日常收支：编辑
 */
export const modifyBalance = () =>
  function (that, btnKey) {
    console.log('modifyBalance');
    const pageKey = getPageKey().pageConfig.key;
    let billId = that.getSelectesKey('bill_id');
    if (billId.length === 0) {
      showInfo(INFO, '请选择收支记录！');
      return false;
    } else if (billId.length > 1) {
      showInfo(INFO, '请选中一条收支记录！');
      return false;
    }

    // 获取选中Row的after_service_id key组成的数组
    // const relatedBsArr = that.getSelectesKey('after_service_id');
    // if (relatedBsArr && relatedBsArr.length) {
    //   // 当存在关联的后服务单，不允许修改
    //   const relatedBsId = relatedBsArr[0];
    //   if (relatedBsId && relatedBsId !== '0') {
    //     showInfo(INFO, '当前报销单通过后服中心关联创建，请在后服中心修改关联的后服单号！');
    //     return;
    //   }
    // }

    const { operate = [] } = that.getSelectesKey('otherProps')[0] || {};
    const isApproval = operate.find(os => os.key === 'audit_log');

    const customerType = that.getSelectesKey('customer_type')[0];
    if (['income_pay'].includes(customerType)) {
      return capitalFlow(that, 'modify_income_pay');
    }

    that.disableMenuButton(btnKey);
    billId = billId[0];
    actions.financeServer
      .getIncomeExpenseData({
        bill_id: billId,
        // bill_sort: pageType,
        action: 'update',
      })
      .then(res => {
        that.enableMenuButton(btnKey);
        if (+res.data.errno !== 0) {
          showInfo(ERROR, res.message || res.data.errmsg || '网络错误');
          return;
        }
        const popUpParam = {
          popName: `finance_${pageKey}`,
          billId,
          action: 'update',
          titleName: '日常收支修改',
          popContainer: that,
          opCallback: that.handleRefreshData,
          that,
          data: res.data.res,
          pageType: res.data.res.load_info.bill_sort === 'expense_num' ? 'expense' : 'receipt',
          isApproval,
        };
        new PopUp(dailyFinanceSlideDrager, popUpParam).show();
      });
  };

// 日常收支单条结算
function singleBalanceSettle(that) {
  const selectedList = that.getSelectesKey('bill_id');
  const { tab } = that.getReq();
  const buttonKey = tab === 'document_center' ? 'documentSettle' : 'balanceSettle';
  that.disableMenuButton('balance_settle');
  let supportSection;
  let sectionIds;
  if (tab === 'balance_detail') {
    sectionIds = that.getSelectesKey('id');
    supportSection = true;
  }
  // test
  fetchApi('Finance/ReceiptExpense/getBillPayInfo', {
    method: 'POST',
    body: {
      req: {
        bill_id: selectedList[0],
        id: sectionIds,
      },
    },
  }).then(res => {
    that.enableMenuButton('balance_settle');
    if (res.errno !== 0) {
      return showInfo(ERROR, res.errmsg);
    }
    const auditStatus = res.res.audit_status;
    const payModeInfo = res.res.payModeTable.enumerations.pay_mode || [];
    const payModeList = Object.values(res.res.enum);
    let errmsg;
    switch (+auditStatus) {
      case 15:
        errmsg = '该单据已驳回，无法结算';
        break;
      case 10:
      case 20:
        errmsg = '该单据待审核，无法结算';
        break;
      case 25:
        errmsg = '该单据审核中，无法结算';
        break;
      case 30:
        errmsg = '该单据已拒绝，无法结算';
        break;
      case 50:
        errmsg = '该单据已结算，无法再次结算';
        break;
      default:
        break;
    }
    if (errmsg) {
      showInfo(ERROR, errmsg);
      return;
    }
    let payMode = [];
    if (res.res.payModeTable && res.res.payModeTable.data.length > 0) {
      payMode = res.res.payModeTable.data;
    } else if (payModeInfo.length > 0) {
      payMode = [payModeInfo[0]];
    }
    // 获得选中行的网点ID，去重后的。
    const comIdArr = [...new Set(that.getSelectesKey('company_id'))];
    const feeHeader = res.res.payModeTable.header || {};
    const payModeEditable = typeof res.res.pay_mode_editable === 'undefined' ? true : res.res.pay_mode_editable;
    if (!payModeEditable) {
      delete feeHeader.substract;
      payMode = payMode.map(x => ({ ...x, otherProps: { disable: ['pay_mode'] } }));
    }
    const operator = that.getSelectesKey('operator');
    const postData = {
      re_info: res.res.re_info,
      create_by: operator[0],
      com_id: comIdArr[0],
    };
    new PopUp(DailySettle, {
      that,
      selectedList,
      payModeTableHeader: feeHeader,
      payModeTableEnum: { pay_mode: payModeInfo },
      payModeList: payModeList || [],
      payMode,
      buttonKey,
      isExpense: res.res.bill_sort === 'expense_num',
      hasCertAuth: res.res.has_cert_auth,
      assistCombine: res.res.assist_combine,
      settleTime: res.res.settle_time,
      comIds: comIdArr,
      amount: Math.abs(res.res.amount),
      sectionAmount: res.res.re_info_amount,
      settled_flag: res.res.settled_flag,
      supportSection,
      sectionIds,
      postData,
      callBack: () => {
        setTimeout(() => {
          that.handleRefreshData();
        }, 1000);
      },
    }).show();
  });
}

// 日常收支多条结算
function multiBalanceSettle(that) {
  that.disableMenuButton('balance_settle');
  const billId = that.getSelectesKey('bill_id');
  const selectedList = [...new Set(billId)];
  const sendParam = {
    body: {
      req: {
        bill_id: selectedList,
        batch_op_flag: true,
        is_check: 1,
      },
    },
    path: 'Finance/ReceiptExpense/settleRecExp',
    method: 'POST',
    dataFormat: 'raw',
    saveAs: 'settleRecExp',
  };
  _callApi(sendParam).then(res => {
    that.enableMenuButton('balance_settle');
    handleResponse({ operation: '结算', isCheck: 1 }, res, (succDt, closeResTip) => {
      closeResTip && closeResTip();
      const resData = res.data.res;
      let settleDgObj;
      // 获得选中行的网点ID，去重后的。
      const comIdArr = [...new Set(that.getSelectesKey('company_id'))];
      if (resData.header) {
        let payMode = [];
        if (resData.pay_mode_info.length > 0 && resData.is_force_paymode) {
          payMode = [resData.pay_mode_info[0]];
        } else {
          payMode = [
            {
              pay_mode: '',
            },
          ];
        }
        settleDgObj = {
          that,
          is_force_paymode: resData.is_force_paymode,
          isMultiSettle: true,
          selectedList: succDt,
          hasCertAuth: resData.has_cert_auth,
          payModeTableHeader: resData.header || {},
          payModeTableEnum: resData.enum || {},
          payModeList: resData.pay_mode_info || [],
          payMode,
          comIds: comIdArr,
          buttonKey: 'balanceSettle',
          settleTime: resData.settle_time,
          resData,
          batch_op_flag: true,
          callBack: () => {
            setTimeout(() => {
              that.handleRefreshData();
            }, 2000);
          },
        };
      } else {
        settleDgObj = {
          that,
          isMultiSettle: true,
          selectedList: succDt,
          comIds: comIdArr,
          buttonKey: 'balanceSettle',
          hasCertAuth: resData.has_cert_auth,
          callBack: () => {
            setTimeout(() => {
              that.handleRefreshData();
            }, 1000);
          },
        };
      }
      new PopUp(DailySettle, settleDgObj).show();
    });
  });
}

// 日常收支结算
export const balanceSettle = function (that) {
  console.log('balanceSettle');
  const selectedList = that.getSelectesKey('bill_id');
  if (!selectedList.length) {
    showInfo(ERROR, '请选择日常收支');
    return;
  }
  const billIds = new Set(selectedList);
  if (billIds.size === 1) {
    singleBalanceSettle(that);
  } else {
    const payModeNoEdit = window.company_setting.pay_mode_no_edit;
    if (payModeNoEdit && payModeNoEdit.checked === 'settle_edit')
      return showInfo(ERROR, '收支方式启用了结算时录入，不允许批量结算操作！');
    multiBalanceSettle(that);
  }
};
// 日常收支列表：收支详情
export const settleBalanceNo = function (that, index) {
  const pageKey = getPageKey().pageConfig.key;
  const data = that.getData();
  const billId = data[index].ba_no_id || data[index].bill_id || data[index]['Settle|bill_id'];
  if (!billId) {
    return false;
  }

  const customerType = data[index].customer_type;
  if (['income_pay'].includes(customerType)) {
    return capitalFlowView(that, index);
  }
  console.log('settleBalanceNo =', index, customerType);
  const popUpParam = {
    // popName: 'settleBalanceNoPanel',
    popName: `finance_${pageKey}`,
    billId,
    action: 'view',
    titleName: '日常收支查看',
    pageType: 'receiptOrReceiptCheck',
    popContainer: that,
    that,
  };
  const dialog = new PopUp(dailyFinanceSlideDrager, popUpParam);
  dialog.show();
};
// 客户对账单列表 点结算单号,跳转
export const settleSettleNoBak = function (that, index) {
  console.log('点结算单号跳转');
  const data = that.getData();
  const settleNo = data[index].settle_no;
  // 没有日期等 范围筛选 则可不传initFilterHeader
  // const filterQuery = that.getAllFitlerQuery()
  const filterQuery = that.getAllFitlerQuery(true);
  if (filterQuery && typeof filterQuery === 'object') {
    // TODO 去掉 对账时间
    if (Object.keys(filterQuery).includes('em_account_time')) {
      filterQuery.create_time = filterQuery.em_account_time;
      delete filterQuery.em_account_time;
    }
    // for (let k of Object.keys(filterQuery)) {
    //   // if (filterQuery[k] === '' ||
    //   // filterQuery[k] === undefined ||
    //   // filterQuery[k] === null ||
    //   // filterQuery[k] === [] ||
    //   // k !== 'company_id' ||
    //   // k !== 'settle_time' ||
    //   // k !== 'expense_disp' ||
    //   // k !== 'category' ||
    //   // k !== 'settle_category' ||
    //   // k !== 'direction' ||
    //   // k !== 'settle_no'
    //   // ) {
    //   // delete filterQuery[k]
    //   // }
    //   if (k === 'em_account_time') {
    //     filterQuery.create_time = filterQuery[k]
    //     delete filterQuery[k]
    //   }
    // }
  }
  const _designateParam = Object.assign({}, filterQuery);
  _designateParam.settle_no = settleNo;
  const pack = {
    // designateParam: {},
    // TODO 是否忽略默认筛选值
    designateParam: { ignore_default: true },
    // designateFilter: { settle_no: settleNo },
    designateFilter: _designateParam,
    initFilterHeader: that.getHeader(),
  };
  const action = {
    controller: 'Finance',
    action: 'settleLogDetail',
    injectProps: pack,
  };
  window.$app.direct(action);
};
export const settleBalanceDetail = function (that, index) {
  const data = that.getData();
  const currHeader = that.getHeader() || {};
  const rowData = that.formatRowEnumItem(data[index]);
  const comId = rowData.company_id;
  const balanceNo = rowData.balance_no;
  const pack = {
    designateParam: { ignore_default: true },
    designateFilter: { balance_no: balanceNo, company_id: comId },
    initFilterHeader: { ...currHeader },
  };
  const action = {
    controller: 'Finance',
    action: 'dailyIODetail',
    injectProps: pack,
  };
  window.$app.direct(action);
};

// 日常收支审核方法调用
function auditBalance(operate, reqData, callback) {
  const sendParam = {
    body: {
      req: reqData,
    },
    path: 'Finance/ReceiptExpense/balanceCheck',
    method: 'POST',
    dataFormat: 'raw',
    saveAs: 'checkReview',
  };
  if (operate === 'pass') {
    sendParam.path = 'Finance/ReceiptExpense/checkReview';
  } else if (operate === 'reject') {
    sendParam.path = 'Finance/ReceiptExpense/checkReject';
  }
  _callApi(sendParam).then(res => {
    callback && callback(res);
  });
}

// 日常收支审核按钮点击
export const checkBalanceApproval = function (that, buttonKey) {
  const billCat = that.getSelectesKey('bill_cat')[0];
  if (billCat === 'income_pay') {
    opAudit(that, buttonKey);
    return false;
  }
  AuditWithModal(that, 'expense_or_receipt', data => (data.bill_sort === '1' ? 'create_expense' : 'create_receipt'));
};
// 日常收支审核按钮点击
export const checkBalance = function (that, billId = that.getSelectesKey('bill_id'), datas) {
  console.log('checkBalance');
  const selectedList = [...new Set(billId)];
  if (!selectedList.length) {
    showInfo(ERROR, '请选择日常收支');
    return;
  }
  that.disableMenuButton('check_balance');
  let reqData = {
    bill_id: selectedList,
  };
  auditBalance('', reqData, res => {
    that.enableMenuButton('check_balance');
    // 有不能进行审核的数据，进行提示
    handleResponse({ operation: '审核', isCheck: 1 }, res, (succDt, closeResTip) => {
      closeResTip && closeResTip();
      console.log('handleResponse =', succDt, selectedList, datas);
      if (succDt.length === 1) {
        const newSelectedList = selectedList.map(id => {
          // bill_sort: 1  为报销单
          const bill_sort = datas.find(item => item.apply_id === id)?.bill_sort || '1';
          return {
            bill_id: id,
            bill_sort,
          };
        });
        // auditExpense 方法中，根据 bill_sort 来判断是 报销还是 收支
        auditExpense({ that, data: datas, selectedList: newSelectedList });
        return false;
      }
      // 点击继续后弹出审核意见框
      const auditTypeData = [
        {
          label: '通过',
          value: 'pass',
          name: 'auditRigTypeData',
          direction: 'right',
        },
        {
          label: '拒绝',
          value: 'reject',
          name: 'auditRigTypeData',
          direction: 'right',
        },
      ];
      const popup = new PopUp(Dialog, {
        classname: 'balance_audit',
        loadBtnList: ['balanceAuditConfirm'],
        dialogInfo: {
          title: '日常收支审批',
          contentHeader: null,
          button: [
            {
              key: 'balanceAuditConfirm',
              children: '确定',
            },
            {
              key: 'cancel',
              children: '取消',
            },
          ],
          actionList: {
            balanceAuditConfirm: (key, data, validateInfo) => {
              if (!validateInfo.isPass) {
                showInfo(ERROR, validateInfo.error.labelProps.children + validateInfo.errorInfo);
                return false;
              }
              reqData = {
                bill_id: succDt,
                remark: data.reason,
              };
              auditBalance(data.auditType, reqData, dores => {
                popup.close();
                handleResponse({ operation: '审核' }, dores, () => {
                  setTimeout(() => {
                    that.handleRefreshData();
                  }, 1000);
                });
              });
            },
            cancel: () => {
              popup.close();
            },
          },
        },
        data: {
          auditType: {
            type: 'Radio',
            eventNames: ['onChange'],
            validate: ['require'],
            labelProps: {
              children: '审批结果：',
              isRequired: true,
            },
            otherProps: {
              classname: 'two_radio',
              data: auditTypeData,
              value: 'pass',
            },
          },
          reason: {
            type: 'TextArea',
            eventNames: ['onChange'],
            otherProps: {
              classname: 'audit_reason',
              disabled: false,
              defaultValue: '',
              placeholder: '',
            },
          },
        },
        isShow: true,
      }).show();
    });
  });
};
// 日常收支取消审核
export const cancelCheckBalanceApproval = function (that) {
  cancelAuditMass(that, 'expense_or_receipt', data => (data.bill_sort === '1' ? 'create_expense' : 'create_receipt'));
};
// 日常收支取消审核
export const cancelCheckBalance = function (that, billId = that.getSelectesKey('bill_id')) {
  const selectedList = [...new Set(billId)];
  if (!selectedList.length) {
    showInfo(ERROR, '请选择日常收支');
    return;
  }
  showConfirm('取消审核', '您确定要取消审核所选单据吗?', closeConfirm => {
    const reqData = {
      bill_id: selectedList,
    };
    const sendParam = {
      body: {
        req: reqData,
      },
      path: 'Finance/ReceiptExpense/cancelCheckRecExp',
      method: 'POST',
      dataFormat: 'raw',
      saveAs: 'checkReview',
    };
    _callApi(sendParam).then(res => {
      closeConfirm && closeConfirm();
      handleResponse({ operation: '取消审核', isCheck: 1 }, res, (succDt, closeRsTip) => {
        if (!succDt || succDt.length === 0 || selectedList.length === succDt.length || selectedList.length === 1) {
          showInfo(CHECK, '取消审核成功');
          setTimeout(() => {
            that.handleRefreshData();
          }, 1000);
          return;
        }
        sendParam.body.req.bill_id = succDt;
        _callApi(sendParam).then(dores => {
          closeRsTip && closeRsTip();
          handleSuccess(dores, () => {
            showInfo(CHECK, '取消审核成功');
            setTimeout(() => {
              that.handleRefreshData();
            }, 1000);
          });
        });
      });
    });
  });
};
// 日常收支删除
export const deleteBalance = function (that) {
  const selectedList = that.getSelectesKey('bill_id');
  if (!selectedList.length) {
    showInfo(ERROR, '请选择日常收支');
    return;
  }
  showConfirm('删除单据', '您确定要删除所选单据吗?', closeConfirm => {
    const reqData = {
      bill_id: selectedList,
    };
    const sendParam = {
      body: {
        req: reqData,
      },
      path: 'Finance/ReceiptExpense/deleteRecExp',
      method: 'POST',
      dataFormat: 'raw',
      saveAs: 'checkReview',
    };
    _callApi(sendParam).then(res => {
      closeConfirm && closeConfirm();
      handleResponse({ operation: '删除', isCheck: 1 }, res, (succDt, closeRsTip) => {
        if (!succDt || succDt.length === 0 || selectedList.length === succDt.length || selectedList.length === 1) {
          closeRsTip && closeRsTip();
          setTimeout(() => {
            that.handleRefreshData();
          }, 1000);
          return;
        }
        sendParam.body.req.bill_id = succDt;
        _callApi(sendParam).then(dores => {
          closeRsTip && closeRsTip();
          handleResponse({ operation: '删除' }, dores, () => {
            setTimeout(() => {
              that.handleRefreshData();
            }, 1000);
          });
        });
      });
    });
  });
};
export const cancelCreateExpense = function (that) {
  const selectedList = that.getSelectesKey(['bill_id', 'bill_sort', 'otherProps']);
  if (!selectedList.length) {
    showInfo(ERROR, '请选择日常收支');
    return;
  }
  if (selectedList.length > 1) {
    showInfo(ERROR, '一次只能取消一条日常收支');
    return;
  }
  const op = selectedList[0].otherProps.operate;
  if (!op.length || !op.find(i => i.key === 'audit_log')) {
    // 产品要求：非审批流的单据，取消就跟删除是一样的操作
    deleteBalance(that);
    return;
  }
  const operation = selectedList[0].bill_sort === '1' ? 'create_expense' : 'create_receipt';
  cancelApply(that, operation);
};
/**
 * 获取 doc 详情
 * @param docId
 */
const getDoc = function (docId) {
  // eslint-disable-line
  const url = '/Accounts/Document/getDoc';
  const option = {
    method: 'POST',
    body: {
      req: {
        doc_id: docId,
      },
    },
  };
  return fetchApi(url, option);
};
const getSugOpenPeriod = function (ledgerPid) {
  // eslint-disable-line
  let url = '/Accounts/Period/sugOpenPeriod';
  if (ledgerPid) {
    url += `?ledger_pid=${ledgerPid}`;
  }
  return fetchApi(url);
};
const getDocConfig = function () {
  // eslint-disable-line
  const url = '/Accounts/Document/getDocDetailConf';
  const option = {
    method: 'POST',
    body: {
      req: {
        key: 'ac_doc_detail',
      },
    },
  };
  return fetchApi(url, option);
};

// 非审批流
const notApproval = (that, bill_id, document_type, isCheck) => {
  let auditRemark;
  let req;
  let popUp;

  const handleSubmit = async (actionType, applyCheck) => {
    const remark = (auditRemark && auditRemark._textarea && auditRemark._textarea.value) || '';
    req = {
      bill_id: [bill_id],
    };
    applyCheck
      ? (req.is_check = 1)
      : (req.audit_info = { remark: remark || '', type: actionType === 1 ? 'pass' : 'reject' });
    const checkRes = await fetchApi('/Finance/Unify/audit', {
      method: 'POST',
      body: {
        req,
      },
    });
    if (checkRes.errno !== 0) {
      popUp.close();
      if (checkRes.res && checkRes.res.failed_detail && checkRes.res.failed_detail.length) {
        opTips({
          resData: checkRes,
          opType: '审核',
          orderType: '数据',
          tips: '审核',
        });
      } else {
        alert(WARN, checkRes.errmsg || `您没有${document_type}审核权限，如需审核请联系系统管理员分配权限！`);
      }
    } else if (checkRes.errno === 0) {
      if (applyCheck) {
        popUp.show();
      } else {
        showInfo(CHECK, actionType === 1 ? '已同意' : '已拒绝');
        setTimeout(() => {
          that.handleRefreshData();
        }, 1000);
        popUp.close();
      }
    }
  };
  const content = (
    <div className="remark-textArea">
      <TextArea classname="remark" ref={r => (auditRemark = r)} style={{ height: '95px' }} />
    </div>
  );
  const bottom = [
    <Button
      key={1}
      type="primary"
      onClick={() => {
        handleSubmit(1, false);
      }}
    >
      确定
    </Button>,
    <Button
      key={2}
      onClick={() => {
        handleSubmit(3, false);
      }}
    >
      拒绝
    </Button>,
  ];
  popUp = new PopUp(ModalDialog, {
    content,
    bottom,
    isShow: true,
    isModal: true,
    title: '审核',
  });
  if (isCheck) {
    handleSubmit(undefined, true);
  }
};
const settleAudit = async (that, index) => {
  const data = that.getData();
  const clickData = data[index];
  const { enumerations } = that.state;
  const { category, tab } = that.tableInfo || {};
  const { op_filter, bill_no, bill_id } = clickData;
  const { bill_category } = enumerations;
  const document_type = Array.isArray(bill_category) && bill_category.filter(i => clickData.bill_cat === i.key);
  console.log(document_type, 'document_type');

  const billCat = clickData.bill_cat;
  const Loan_Audit_Operation_Map = {
    loan_driver: 'loan_driver_apply',
    repay_driver: 'repayment_driver_apply',
    loan_car: 'loan_car_apply',
    repay_car: 'repayment_car_apply',
    loan_employee: 'loan_user_apply',
    repay_employee: 'repayment_user_apply',
    income_pay: 'income_pay',
  };
  if (
    ['loan_car', 'repay_car', 'loan_driver', 'repay_driver', 'loan_employee', 'repay_employee', 'income_pay'].includes(
      billCat,
    )
  ) {
    return showCapitalFlow({
      that,
      action: 'audit',
      type: billCat,
      billId: clickData.bill_id,
      auditOperation: Loan_Audit_Operation_Map[billCat],
      rows: [clickData],
      operation: 'loan_audit',
    });
  }

  let operation;
  if (tab === 'balance') {
    operation = clickData.bill_sort === '1' ? 'create_expense' : 'create_receipt';
  } else {
    operation = 'document_center';
  }
  if (op_filter && op_filter.indexOf('audit_log') > 0 && billCat !== 'acquittance' && billCat !== 'expense_account') {
    AuditWithModal(that, operation, false, [clickData], { buttonKey: 'settleAudit' });
  } else if (tab === 'balance' || billCat === 'acquittance' || billCat === 'expense_account') {
    AuditWithModal(
      that,
      'expense_or_receipt',
      colData => (colData.bill_sort === '1' ? 'create_expense' : 'create_receipt'),
      [clickData],
      { buttonKey: 'settleAudit' },
    );
  } else if (isStatusVerify(category, tab)) {
    const _list = await statusVerify('audit', [clickData]);
    if (_list.length > 0) {
      notApproval(that, _list[0].bill_id, document_type[0].display, true);
    }
  }
};
const settleAuditLog = (that, index, col, row) => {
  const selected = that.getData()[index];
  const { tab } = that.getReq();
  if (tab.includes('invoice')) {
    emitListLinkOp({
      listPage: that,
      index,
      col,
      row,
      key: 'Invoice_audit_log',
    });
    return;
  }
  let operation = '';
  let table;
  if (tab === 'receipt_payment' || tab === 'customer' || tab === 'point') {
    table = 'bill';
  } else if (tab === 'carrier') {
    operation = 'oms_carrier_ca_apply';
  } else {
    operation = selected.bill_sort === '1' ? 'create_expense' : 'create_receipt';
  }
  const id = selected.bill_id;
  AuditTrail(id, { operation, table });
};
// 凭证号
// const defaultAssistData = {
//   accounts_id: {},
//   consignor_id: {},
//   consignee_id: {},
//   carrier_id: {},
//   route_id: {},
//   department_id: {},
//   user_id: {},
//   company_id: {}
// }
export const settleCertNo = function (that, index, columnKey, rowData, keyNo, NN) {
  console.log('columnKey', columnKey);
  console.log('rowData', rowData);
  console.log('keyNo', keyNo);
  console.log('NN', NN);
  // const pageKey = getPageKey().pageConfig.key
  const pageReq = that.getReq();
  const _tab = pageReq.tab;
  const _category = pageReq.category;
  let rowDataMap = {};
  const dataObjArr = that.getData();
  if (!(dataObjArr[index].cert_no || dataObjArr[index]['Settle|cert_no'])) {
    return false;
  }
  if (_tab === 'ac_apply_daily' && _category === 'AcApplyDaily') {
    rowDataMap = {
      noId: 'cert_no_id',
      ledgerId: 'Settle|ledger_id',
    };
  }
  // 多次结算生成多凭证号
  let columnInex;
  let certNoId;
  const columnVal = rowData[columnKey];
  if (typeIs(columnVal, 'array')) {
    columnInex = columnVal.indexOf(keyNo);
    console.log('columnVal', columnVal, columnInex);
    const certNoIdArr = rowData.cert_no_id;
    certNoId = certNoIdArr[columnInex];
    console.log('certNoId', certNoId);
    if (columnVal.length > 1) {
      rowDataMap.curCertNoId = [certNoId];
    }
  }
  // showVoucher(that, index, rowDataMap, certNoId)
  showVoucher(that, index, rowDataMap);
};
// 客户对账列表：提交
export const customerSubmit = function (that) {
  console.log('客户对账列表：提交');
  operationHandle({
    pageInfo: that,
    buttonKey: 'customerSubmit',
  });
};
// 客户对账列表：审核通过
export const cusReview = function (that) {
  console.log('客户对账列表：审核通过');
  operationHandle({
    pageInfo: that,
    buttonKey: 'cusReview',
  });
};
// 客户对账列表：审核拒绝
export const cusReject = function (that) {
  console.log('客户对账列表：审核拒绝');
  operationHandle({
    pageInfo: that,
    buttonKey: 'cusReject',
  });
};
// 客户对账列表：取消审核
export const customerCancelAudit = async function (that, biliIds = that.getSelectesKey('bill_id'), { buttonKey } = {}) {
  console.log('客户对账列表：取消审核');
  if (!biliIds.length) {
    showInfo(ERROR, '请选择对账单');
    return;
  }

  let opList = biliIds;
  const _list = await statusVerify('cancelAudit', that.vTable.getStateSelecteRows());
  if (_list.length === 0) return false;
  opList = _list.map(v => v.bill_id);

  const postData = (theData, check) => {
    buttonKey && that?.disableMenuButton?.(buttonKey);
    const data = theData.map(v => ({ bill_id: v }));
    const url = '/Finance/CustomerAccount/customerCancelAudit';
    // const url = '/cmm-finance/japi/finance/cancelAudit'
    // fetchJAVA(url, {
    fetchApi(url, {
      method: 'POST',
      body: {
        req: {
          is_check: check,
          type: 'customer_cancel_audit',
          data,
        },
      },
    })
      .then(res => {
        const resData = res;
        if (!check) {
          const msg = resData.errno === 0 ? '取消审核成功' : resData.errmsg;
          const type = resData.errno === 0 ? CHECK : ERROR;
          return showInfo(type, msg);
        }
        if (resData.success_id && resData.success_id.length) {
          resData.errno = 0;
        }
        opTips({
          resData,
          opType: '取消审核',
          orderType: '数据',
          tips: '取消审核',
          sucCallback() {
            setTimeout(that.handleRefreshData, 2000);
          },
          continueCallback(succIds, cb) {
            cb();
            postData(succIds, 0);
          },
        });
      })
      ?.finally?.(() => buttonKey && that?.enableMenuButton?.(buttonKey));
  };
  const handle = {
    confirm: () => postData(opList, 1),
  };
  showInfo(INFO, '您确定要取消审核吗？', true, handle);
};
export const employeeCancelReview = function (that) {
  console.log('员工交账列表：取消审核');
  operationHandle({
    pageInfo: that,
    buttonKey: 'employeeCancelReview',
  });
};
// 汇款批次、到账批次：取消汇款
export const cancelCdRmt = function (that) {
  console.log('汇款批次、到账批次：取消汇款');
  operationHandle({
    pageInfo: that,
    buttonKey: 'cancelCdRmt',
  });
};
// 汇款批次详情：取消汇款
export const celCoDeliveryRmt = function (that) {
  console.log('汇款批次详情：取消汇款');
  operationHandle({
    pageInfo: that,
    buttonKey: 'celCoDeliveryRmt',
  });
};
// 到账批次：取消到账
export const cancelCdAvl = function (that) {
  console.log('到账批次：取消到账');
  operationHandle({
    pageInfo: that,
    buttonKey: 'cancelCdAvl',
  });
};
// 到账批次：到账
export const cdAvl = function (that) {
  console.log('到账批次：到账');
  operationHandle({
    pageInfo: that,
    buttonKey: 'cdAvl',
  });
};
// 到账批次详情：取消到账
export const celCoDeliveryAvl = function (that) {
  console.log('到账批次详情：取消到账');
  operationHandle({
    pageInfo: that,
    buttonKey: 'CelCoDeliveryAvl',
  });
};
// 到账批次详情：到账
export const coDeliveryAvlBatch = function (that) {
  console.log('到账批次详情：到账');
  operationHandle({
    pageInfo: that,
    buttonKey: 'coDeliveryAvlBatch',
  });
};
// 汇款批次列表，批次号
export const coDyBatchBatchNum = function (that, index) {
  const pageKey = getPageKey().pageConfig.key;
  console.log('汇款批次列表，批次号', that, index);
  const pageReq = that.getReq();
  const _tab = pageReq.tab;
  console.log('_tab', _tab);
  // let reqID = 'rmt_id'
  // if (_tab === 'arrival_batch') reqID = 'dn_rmt_id'
  const data = that.getData();
  const curID = data[index].id;
  if (!curID) {
    return false;
  }
  const searchFilter = {};
  if (_tab === 'arrival_batch') {
    searchFilter.dn_rmt_id = curID;
  } else {
    searchFilter.rmt_id = curID;
  }
  // const report = 1
  const sideTitle = data[index].batch_num;
  const pageConfig = {
    listPage: true,
    url: 'Table/Search/orderList/',
    tableInfo: {
      category: 'Order',
      tab: `${_tab}_list`,
      body: {
        fetch_mode: 'all',
      },
    },
    key: getUuid(),
  };
  const enumObj = that.getTableEnum();
  console.log('enum', enumObj);
  // 汇款网点
  let startComText;
  const startComId = data[index].start_com_id;
  for (const val of enumObj.company_id) {
    if (val.key === startComId) startComText = val.name;
  }
  console.log('汇款网点', startComText);
  // 到账网点
  let toComText;
  const toComId = data[index].to_com_id;
  for (const val of enumObj.company_id) {
    if (val.key === toComId) toComText = val.name;
  }
  console.log('到账网点', toComText);
  // 汇款人
  let startUserText;
  const startUserId = data[index].start_user_id;
  for (const val of enumObj.user_id) {
    if (val.key === startUserId) startUserText = val.name;
  }
  console.log('汇款人', startUserText);
  // 汇款备注
  const startRemark = data[index].start_remark;
  console.log('startRemark', startRemark);
  // 汇款时间
  const startTime = data[index].start_time;
  console.log('startTime', startTime);
  // 到账时间
  const toTime = data[index].to_time;
  console.log('toTime', toTime);
  const designateParam = {};
  const getTableInfo = () => ({ ...that.getTableInfo(), title: sideTitle });
  const configObj = { pageConfig, key: pageConfig.key, parentPageKey: that.getPageKey() };
  const ListPage = shouldCompUpdate(configObj.parentPageKey)(ListPageConf(configObj));
  const dConfig = {};
  dConfig.height = '100%';
  const attrEl = (
    <ul className="detailAttr">
      <li>
        <span>汇款网点：</span>
        {startComText}
      </li>
      <li>
        <span>到账网点：</span>
        {toComText}
      </li>
      <li>
        <span>汇款人：</span>
        {startUserText}
      </li>
      <li>
        <span>汇款时间：</span>
        {startTime}
      </li>
      <li>
        <span>备注：</span>
        {startRemark}
      </li>
    </ul>
  );
  // searchFilter 包含范围筛选时 需要传入header
  const contentEl = (
    <div style={dConfig}>
      {attrEl}
      <ListPage
        isRedirect
        designateParam={designateParam}
        designateFilter={searchFilter}
        initFilterHeader={undefined}
        getPageInfo={getTableInfo}
      />
    </div>
  );
  const param = {
    isShow: true,
    slideWidth: 960,
    HeaderTitle: sideTitle,
    contentDiv: contentEl,
    popName: `finance_${pageKey}`,
  };
  new PopUp(SlideDrager, param).show();
};
export const settleBillNo = function (that, index) {
  const billCat = that.getData()[index].bill_cat;
  // if (['customer_consignor', 'customer_consignee', 'customer_partner'].includes(billCat)) { //客户对账
  //   // settleAcNo(that, index, 'ac_no')
  //   return
  // } else if (billCat === 'account') { // 员工交账
  //   // settleEmAcNo(that, index, 'em_ac_no')
  //   return
  // } else if (billCat === 'point') { // 网点对账
  //   // settlePoAcNo(that, index, 'po_ac_no')
  //   return
  // } else if (['receipt', 'payment'].includes(billCat)) { //收付款
  //   // settlePaReNoBak(that, index, 'pa_re_no')
  //   return
  if (['expense_account', 'acquittance'].includes(billCat)) {
    // 报销单 收据
    settleBalanceNo(that, index);
  }
};
const auditModal = ({ buttonText, handleSubmit }) => {
  let elReamrk;
  let btn1;
  let btn2;
  let dialogRef;
  // 防止按钮重复点击 处理
  const handleBtn = () => {
    btn1 &&
      btn1.setState({
        // 操作按钮loading
        loading: true,
      });
    btn2 &&
      btn2.setState({
        // 取消按钮 disabled
        disabled: true,
      });
  };
  const popUp = new PopUp(ModalDialog, {
    content: (
      <textarea
        style={{ width: '100%', height: '80px', padding: '5px', borderColor: '#ccc' }}
        ref={r => (elReamrk = r)}
      />
    ),
    bottom: (
      <div>
        <Button
          type="primary"
          ref={ref => (btn1 = ref)}
          onClick={() => {
            handleBtn();
            handleSubmit(true, elReamrk.value, dialogRef.handleHide);
          }}
        >
          {buttonText.left}
        </Button>
        {buttonText.right && (
          <Button
            type="default"
            ref={ref => (btn2 = ref)}
            onClick={() => {
              handleBtn();
              handleSubmit(false, elReamrk.value, dialogRef.handleHide);
            }}
          >
            {buttonText.right}
          </Button>
        )}
      </div>
    ),
    isShow: true,
    ref: r => (dialogRef = r),
    isModal: true,
    title: '审核',
  });
  popUp.show();
};
// 收付款单审批 (未开通审批流)
export const receiptPaymentAudit = (that, selectedList) => {
  const desc = '收付款';
  if (!selectedList.length) {
    showInfo(ERROR, `请选择${desc}`);
    return;
  }
  const buttonText = {
    left: '通过',
    right: '拒绝',
  };
  const handleSubmit = (result, remark, handleHide) => {
    const url = `Finance/PaymentOption/${result ? 'paReview' : 'paReject'}`;
    const type = `receipt_payment_${result ? 'review' : 'reject'}`;
    const data = selectedList.map(v => {
      let comId = v.company_id;
      let createBy = v.create_by;
      try {
        createBy = that.getEnumItem('create_by', createBy);
      } catch (error) {
        createBy = '';
      }

      if (typeof comId === 'string') {
        try {
          comId = that.getEnumItem('company_id', comId);
        } catch (error) {
          comId = '';
        }
      }
      comId = _.get(comId, [0, 'display']) || _.get(comId, 'display') || '';
      createBy = _.get(createBy, [0, 'display']) || _.get(createBy, 'display') || '';
      return {
        bill_id: v.bill_id,
        bill_no: v.bill_no,
        company_id: comId,
        create_by: createBy,
      };
    });
    if (data.length > 1) {
      const header = {
        bill_no: { title: '单据号', display: 'show', dispay: 'show' },
        company_id: { title: '申请组织', display: 'show', dispay: 'show' },
        create_by: { title: '申请人', display: 'show', dispay: 'show' },
      };
      handleHide();
      new PopUp(BatchOp, {
        title: '批量审核',
        tips: '审核',
        header,
        data,
        requestUrl: url,
        prefixalTips: '以下单据',
        getPara: dataList => {
          const theData = dataList.map(item => ({ bill_id: item.bill_id }));
          return {
            audit_info: { remark },
            is_check: 0,
            data: theData,
          };
        },
        finishCallback: () => that.handleRefreshPage(),
        escBind: false,
      }).show();
    } else {
      fetchApi(url, {
        method: 'POST',
        body: {
          req: {
            audit_info: { remark },
            data: [{ bill_id: selectedList[0].bill_id }],
          },
        },
        type,
      }).then(res => {
        handleHide();
        opTips({
          resData: res,
          opType: formatCamelCase(type),
          orderType: '数据',
          sucCallback: () => {
            that.handleRefreshData();
          },
        });
      });
    }
  };
  auditModal({
    buttonText,
    handleSubmit,
  });
};
// 对账单审批(未开通审批流)
export const cusAudit = async (that, selectedList) => {
  const desc = '对账单';
  // 是选中且只选中一行
  if (!selectedList.length) {
    showInfo(ERROR, `请选择${desc}`);
    return;
  }
  const buttonText = {
    left: '通过',
    right: '拒绝',
  };

  const opList = await statusVerify('audit', selectedList);
  if (opList.length === 0) return false;
  console.log('cusAudit =', selectedList, opList);

  const handleSubmit = (result, remark, handleHide) => {
    const url = 'Finance/CustomerAccount/customerAudit';
    // const url = '/cmm-finance/japi/finance/audit'
    const type = result ? 'pass' : 'reject';
    const type2 = `customer_${result ? 'review' : 'reject'}`;
    const data = opList.map(v => {
      let comId = v.company_id;
      let createBy = v.create_by;
      try {
        createBy = that.getEnumItem('create_by', createBy);
      } catch (error) {
        createBy = '';
      }

      if (typeof comId === 'string') {
        try {
          comId = that.getEnumItem('company_id', comId);
        } catch (error) {
          comId = '';
        }
      }
      comId = _.get(comId, [0, 'display']) || _.get(comId, 'display') || '';
      createBy = _.get(createBy, [0, 'display']) || _.get(createBy, 'display') || '';
      return {
        bill_id: v.bill_id,
        bill_no: v.bill_no,
        company_id: comId,
        create_by: createBy,
      };
    });
    if (data.length > 1) {
      const header = {
        bill_no: { title: '单据号', display: 'show', dispay: 'show' },
        company_id: { title: '申请组织', display: 'show', dispay: 'show' },
        create_by: { title: '申请人', display: 'show', dispay: 'show' },
      };
      handleHide();
      new PopUp(BatchOp, {
        title: '批量审核',
        tips: '审核',
        header,
        data,
        requestUrl: url,
        // isJAVA: true,
        prefixalTips: '以下单据',
        getPara: dataList => {
          const theData = dataList.map(item => ({ bill_id: item.bill_id }));
          return {
            audit_info: { remark, type },
            type: type2,
            is_check: 0,
            data: theData,
          };
        },
        finishCallback: () => that.handleRefreshPage(),
        escBind: false,
      }).show();
    } else {
      // fetchJAVA(url, {
      fetchApi(url, {
        method: 'POST',
        body: {
          req: {
            audit_info: { remark, type },
            data: [{ bill_id: opList[0].bill_id }],
            type: type2,
            is_check: 0,
          },
        },
      }).then(res => {
        handleHide();
        opTips({
          resData: res,
          opType: formatCamelCase(type2),
          orderType: '数据',
          sucCallback: () => {
            that.handleRefreshData();
          },
        });
      });
    }
  };
  auditModal({
    buttonText,
    handleSubmit,
  });
};
/**
 * 收付款单审核
 * @param {*} that
 */
const auditSettleReceiptPayment = (that, buttonKey) => {
  AuditWithModal(that, 'receipt_payment', null, null, { buttonKey });
};
/**
 * 收付款单取消审核
 * @param {*} that
 */
export const cancelAuditSettleReceiptPayment = that => {
  cancelAuditMass(that, 'receipt_payment');
};
export const cancelSettleReceiptPayment = (that, buttonKey) => {
  cancelApply(that, 'receipt_payment', { buttonKey });
};
export const cancelSettleCustomer = (that, buttonKey) => {
  cancelApply(that, 'create_account', { buttonKey });
};
// cancelReservation
export const cancelReservation = that => {
  cancelApply(that, 'create_account');
};
// 网点对账
const cancelSettlePoint = that => {
  cancelApply(that, 'create_point');
};
const auditSettlePoint = that => {
  AuditWithModal(that, 'create_point');
};
const cancelAuditSettlePoint = that => {
  cancelAuditMass(that, 'create_point');
};
// 员工交账
const auditSettleAccount = that => AuditWithModal(that, applyOperation.createEmAccount);
const cancelAuditSettleAccount = that => cancelAuditMass(that, applyOperation.createEmAccount);
// 客户对账
const auditSettleCustomer = that => AuditWithModal(that, 'create_account');

// 付款单审核
const paymentAudit = (that, buttonKey) => {
  AuditWithModal(that, 'create_account', null, null, { buttonKey });
};

const cancelAuditSettleCustomer = (that, buttonKey) => {
  cancelAuditMass(that, 'create_account', null, { buttonKey });
};
const auditSettleCarrier = that => {
  AuditWithModal(that, 'oms_carrier_ca_apply');
};
const cancelAuditSettleCarrier = that => {
  cancelAuditMass(that, 'oms_carrier_ca_apply');
};
const cancelSettleCarrier = that => {
  cancelApplyMass(that, 'oms_carrier_ca_apply');
};
const auditSettleListReviewBill = that => {
  AuditWithModal(that, 'money_flow_apply');
};
const cancelAuditSettleListReviewBill = that => {
  cancelAuditMass(that, 'money_flow_apply');
};
const cancelSettleListReviewBill = that => {
  cancelApplyMass(that, 'money_flow_apply');
};
// 网点对账列表：取消审核
export const pointAcCancelAudit = function (that, biliIds = that.getSelectesKey('bill_id')) {
  console.log('网点对账列表：取消审核');
  if (!biliIds.length) {
    showInfo(ERROR, '请选择要操作的数据');
    return;
  }
  const postData = (theData, check) => {
    // that.disableMenuButton()
    const data = theData.map(v => ({ bill_id: v }));
    fetchApi('Finance/Point/pointAuditCancel', {
      method: 'POST',
      body: {
        req: {
          is_check: check,
          type: 'point_ac_audit_cancel',
          data,
        },
      },
    }).then(res => {
      const resData = res;
      if (!check) {
        const msg = resData.errno === 0 ? '取消审核成功' : resData.errmsg;
        const type = resData.errno === 0 ? INFO : ERROR;
        return showInfo(type, msg);
      }
      if (resData.success_id && resData.success_id.length) {
        resData.errno = 0;
      }
      opTips({
        resData,
        opType: '取消审核',
        orderType: '数据',
        tips: '取消审核',
        sucCallback() {
          setTimeout(that.handleRefreshData, 2000);
        },
        continueCallback(succIds, cb) {
          cb();
          postData(succIds, 0);
        },
      });
    });
  };
  const handle = {
    confirm: () => postData(biliIds, 1),
  };
  showInfo(INFO, '您确定要取消审核吗？', true, handle);
};
// 网点单审批(未开通审批流)
export const pointAudit = (that, billIds = that.getSelectesKey('bill_id')) => {
  const desc = '对账单';
  // 是选中且只选中一行
  if (!billIds.length) {
    showInfo(ERROR, '请选择要操作的数据');
    return;
  }
  const buttonText = {
    left: '通过',
    right: '拒绝',
  };
  const data = billIds.map(v => ({ bill_id: v }));
  const handleSubmit = (result, remark, cb) => {
    const type = result ? 'pass' : 'reject';
    const url = type === 'pass' ? 'Finance/Point/pointReview' : 'Finance/Point/pointReject';
    fetchApi(url, {
      method: 'POST',
      body: {
        req: {
          audit_info: { remark },
          data,
          is_check: 0,
        },
      },
    }).then(res => {
      cb();
      opTips({
        resData: res,
        opType: formatCamelCase(type),
        orderType: '数据',
        sucCallback: () => {
          that.handleRefreshData();
        },
      });
    });
  };
  auditModal({
    buttonText,
    handleSubmit,
  });
};
// 网点对账
const pointAcAudit = that => AuditWithModal(that, 'point_ac_audit');
const pointAcAuditCancel = that => cancelAuditMass(that, 'point_ac_audit_cancel');

// todo 提出去抽成挂账公共方法
const ledgerMass = (that, btnKey) => {
  const dataAll = that.getSelectesKey(['bill_id', 'company_id', 'balance_amount', 'build_time', 'bill_no']);
  const companyIds = that.getSelectesKey('company_id');
  const ids = that.getSelectesKey('bill_id');
  // test code
  const tips = btnKey === 'balance_ledger' ? '挂账' : '取消挂账';
  const type = btnKey === 'balance_ledger' ? undefined : 'cancel';
  const opType = btnKey === 'balance_ledger' ? 'ledger' : 'ledgerCancel';
  // let dataAll = new Array(100).fill(1).map((v, i) => ({ id: i, company_id: 123, amount: 9527, build_time: '2020/1/1 0:00' }))
  // test code end
  const dataDictionary = {}; // 数据字典
  dataAll.forEach(v => {
    dataDictionary[v.bill_id] = v;
  });
  // let companyIds = [123]
  const amount = dataAll.map(v => v.balance_amount);
  const requestUrl = 'Finance/ReceiptExpense/balanceLedger'; // 挂账和取消挂账地址
  if (!dataAll.length) {
    return showInfo(ERROR, '请您选择要操作的数据！');
  }
  if (new Set(companyIds).size > 1) {
    return showInfo(ERROR, '一次只能操作一个网点的数据！');
  }
  that.disableMenuButton(btnKey);
  const amountObj = {};
  dataAll.forEach((v, i) => {
    amountObj[v.bill_id] = amount[i];
  });
  const handleLedger = async ({ data, isCheck, isConfirm }) => {
    that.enableMenuButton(btnKey);
    let amountTotal = 0;
    const hasCertAuth = true;
    const isCert =
      (btnKey === 'balance_ledger'
        ? window.company_setting.check_voucher.checked
        : window.company_setting.auto_gen_credent_cancel_ledger.checked) && hasCertAuth;
    data.forEach(v => (amountTotal = +(amountTotal + parseFloat(v.balance_amount, 10)).toFixed(2)));
    if (isConfirm) {
      // 确认操作
      return showInfo(WARN, `请您确认对所选日常收支进行挂账操作，挂账总金额：${amountTotal.toFixed(2)}元？`, true, {
        confirm: () => handleLedger({ data }),
      });
    } else if (!isCheck) {
      // 不需要验证
      return new PopUp(BatchOp, {
        title: '批量操作',
        tips,
        data,
        header: {
          bill_no: { title: '收支编号', type: 'Text', display: 'show', width: 100, align: 'left' },
          build_time: { title: '发生时间', type: 'Text', display: 'show', width: 140, align: 'left' },
          balance_amount: { title: '金额', type: 'Text', display: 'show', width: 80, align: 'left' },
        },
        requestUrl,
        getPara: dataList => {
          const theData = dataList[0];
          return { ids: [theData.bill_id], type, is_cert: isCert };
        },
        finishCallback: that.handleRefreshData,
        escBind: false,
      }).show();
    }
    const req = {
      is_check: isCheck,
      is_cert: isCert,
      ids,
      type,
    };
    const resData = await fetchApi(requestUrl, {
      method: 'POST',
      body: { req },
    });
    const successids = isset(resData, 'res.success_id', []);
    if (successids.length === data.length) {
      // 全部可操作
      return handleLedger({ data: dataAll, isConfirm: true });
    }
    const successData = successids.map(v => dataDictionary[v]);
    opTips({
      // 信息格式化
      opType, // 操作类型， 对应提示文本
      resData, // 请求接口后， 后台返回的数据
      continueCallback: (IDS, cb) => {
        // 部分可操作
        cb();
        handleLedger({ data: successData, isConfirm: true });
      }, // 继续 btn 对应的回调
      orderType: '日常收支', // 是 ‘运单’还是 ‘批次’ 信息 或者其他
    });
  };
  handleLedger({ data: dataAll, isCheck: true });
};
const cCoDeliveryTicket = (that, btnKey) => {
  const ticketIds = that.getSelectesKey('id');
  if (!ticketIds.length) {
    return showInfo(ERROR, '请您选择要操作的数据！');
  }
  if (ticketIds.length > 1) {
    return showInfo(ERROR, '一次只能操作一条的数据！');
  }
  const paidState = that.getSelectesKey('paid_state');
  if (+paidState[0] === 10) {
    return showInfo(ERROR, '已发放的换票批次不能修改！');
  }
  const fetchAction = () =>
    fetchApi('Finance/CoDelivery/cancelCoDyTicket', {
      method: 'POST',
      body: {
        req: {
          ticket_id: ticketIds[0],
        },
      },
    }).then(res => {
      that.enableMenuButton(btnKey);
      if (res.errno === 0) {
        showInfo(CHECK, '取消换票批次成功！');
      } else {
        opTips({
          resData: res,
          opType: '取消换票',
          orderType: '运单',
        });
        // showInfo(ERROR, res.errmsg || '取消换票批次失败！')
      }
      setTimeout(that.handleRefreshData, 1500);
    });
  const content = (
    <DialogDetail
      ext={{
        specialTips: '您确认要取消当前换票批次吗？',
        noticeTitle: '取消后',
        reason: '当前换票批次将被删除，批次中运单的换票状态将变为：未换票。确认取消后操作将无法退回。',
      }}
    />
  );
  const btns = [
    {
      key: 'confirm',
      name: '确定',
      btnType: 'primary',
      cb: dialog => {
        dialog.handleHide();
        that.disableMenuButton(btnKey);
        fetchAction();
      },
    },
    {
      key: 'cancel',
      name: '取消',
      btnType: 'default',
      cb: dialog => dialog.handleHide(),
    },
  ];
  new PopUp(ShowInfo, {
    classname: 'detail_info',
    isShow: true,
    type: INFO,
    // title: (!!opType && OP_CH_TIPS[opType]) ? `${OP_CH_TIPS[opType]}确认` : title,
    title: '操作确认',
    buttons: btns,
    content,
  }).show();
};
const ticketlPaidSettle =
  (isCancel = false) =>
  (that, btnKey) => {
    const ticketIds = that.getSelectesKey('id');
    if (!ticketIds.length) {
      return showInfo(ERROR, '请您选择要操作的数据！');
    }
    if (ticketIds.length > 1) {
      return showInfo(ERROR, '一次只能操作一条的数据！');
    }
    const paidState = that.getSelectesKey('paid_state');
    if (+paidState[0] === 10 && !isCancel) {
      return showInfo(ERROR, '已发放的换票批次不能发放！');
    }
    if (+paidState[0] === 0 && isCancel) {
      return showInfo(ERROR, '所选换票批次还未发放，无需取消发放！');
    }
    const submitUrl = `Finance/CoDelivery/ticket${isCancel ? 'Cancel' : ''}PaidSettle`;
    const type = isCancel ? '取消发放' : '发放';
    that.disableMenuButton(btnKey);
    fetchApi('Finance/CoDelivery/ticketPaidBuilding', {
      method: 'POST',
      body: {
        req: {
          ticket_id: ticketIds[0],
          type: isCancel ? 'cancel' : undefined,
        },
      },
    }).then(res => {
      that.enableMenuButton(btnKey);
      if (res.errno !== 0) {
        return showInfo(ERROR, res.errmsg);
      }
      const resData = res.res;
      const { co_delivery_info: fees = {} } = resData;
      const payInfo = resData.pay_info || {};
      const enums = payInfo.enum || {};
      const payModeInfo = payInfo.pay_mode_info;
      let defaultPayMode;
      if (enums.pay_mode) {
        enums.pay_mode = Object.keys(enums.pay_mode).map(key => {
          if (payModeInfo[key] && payModeInfo[key].default) {
            defaultPayMode = [
              {
                ...enums.pay_mode[key],
                name: key,
                key,
              },
            ];
          }
          return {
            ...enums.pay_mode[key],
            name: key,
            key,
          };
        });
      }
      const submitCheck = info => {
        const req = {
          ticket_id: ticketIds[0],
          pay_info: info,
        };
        return {
          req,
          url: submitUrl,
        };
      };
      new PopUp(TicketPaidBuilding, {
        payHead: payInfo.header,
        payEnums: payInfo.enum,
        payModeDepot: payInfo.pay_mode_info,
        payTotal: fees.all_co_dy_paid_sat,
        payData: [{ amount: fees.all_co_dy_paid_sat, pay_mode: defaultPayMode }],
        submitCallBack: () => setTimeout(that.handleRefreshData, 1500),
        submitCheck,
        type,
        feeData: [
          {
            order: 1,
            expense: '代收货款',
            value: fees.co_dy_paid_sat || 0,
          },
          {
            order: 2,
            expense: '货款手续费',
            value: fees.co_dy_fee_sat || 0,
          },
          {
            order: 3,
            expense: '货款扣',
            value: fees.co_dy_pay_sat || 0,
          },
        ],
        subPrompt: ` 同时请您选择此次${type}当前网点的收支方式：`,
        prompt: (
          <span>
            请您确认对所选批次进行{type}操作，{type}共计：
            <span style={{ color: 'red', fontSize: '22px' }}>{fees.all_co_dy_paid_sat || 0}</span>元？
          </span>
        ),
      }).show();
    });
  };
const mdCoDeliveryTicket = (type, buttonKey) => async (that, r, c, d) => {
  const data = type === 'modify' ? that.getSelectesKey(['id', 'paid_state', 'batch_num']) : [d];
  if (type !== 'detail') {
    if (!data.length) {
      return showInfo(ERROR, '请您选择要操作的数据！');
    }
    if (data.length > 1) {
      return showInfo(ERROR, '一次只能操作一条的数据！');
    }
    if (+data[0].paid_state === 10) {
      return showInfo(ERROR, '已发放的换票批次不能修改！');
    }
  }

  let _pickOrderInfo;
  const req = {
    ticket_id: data[0].id,
    batch_num: data[0].batch_num,
    type,
  };
  if (type === 'detail') {
    req.billType = 'show';
    req.is_detail = 1;
  }
  const billInfo = await fetchApi('Finance/CoDelivery/ticketOnBuilding', { method: 'POST', body: { req } });
  if (billInfo.errno === 0) {
    _pickOrderInfo = billInfo.res;
  } else {
    showInfo(ERROR, billInfo.errmsg);
    return false;
  }

  new PopUp(getBillDetail(), {
    refreshTab: that.refreshTab,
    // popName: `BalanceBuildingSlider${_tab}`,
    popName: 'Finance/CoDelivery/ticketOnBuilding',
    billNo: data[0].batch_num,
    pageType: 'ticketOnBuilding',
    listPage: that,
    sendReq: false,
    pickOrderInfo: _pickOrderInfo,
    closeCallback: () => setTimeout(that.handleRefreshData, 1500),
    type: type === 'modify' ? 'add' : 'show',
    buttonKey,
    reqParam: {
      ticket_id: data[0].id,
      batch_num: data[0].batch_num,
      type,
    },
  }).show();
};
const updatePayStatus = type => (that, btnKey) => {
  const ids = that.getSelectesKey('od_link_id');
  if (!ids.length) {
    return showInfo(ERROR, '请您选择要操作的数据！');
  }
  const opType = type === 'pay' ? '打款' : '取消打款';
  const postData = (data, check) => {
    that.disableMenuButton(btnKey);
    fetchApi('/Order/Order/updatePayStatus', {
      method: 'POST',
      body: {
        req: {
          op_type: type,
          od_link_ids: data,
          is_check: check,
        },
      },
    }).then(res => {
      that.enableMenuButton(btnKey);
      opTips({
        resData: res,
        opType,
        tips: opType,
        showSucTips: false,
        continueCallback(sucId, cb) {
          cb && cb();
          postData(sucId, 0);
        },
        sucCallback() {
          setTimeout(that.handleRefreshData, 2000);
          let content;
          if (type === 'pay') {
            content = `您确定对所选运单（合计${res.res.total}元）打款到公路港吗？点击确定会进入打款侧拉，请选择提现卡号为公路港的银行卡号`;
          } else {
            content = `您确定对所选运单（合计${res.res.total}元）取消打款吗？ 点击确定系统会将打款状态变为未打款，请注意系统不会自动将手续费金额增加到您的钱包余额中`;
          }
          showInfo(INFO, content, true, {
            confirm() {
              if (type === 'pay') {
                new PopUp(AsyncWalletWithdraw, {
                  showType: 'slider',
                  onSaveSuccess: () => {},
                }).show();
              }
            },
          });
        },
      });
    });
  };
  postData(ids, 1);
};
// 充值
const rechargeModal = that => {
  let titleName = '车辆账本充值';
  let userType = '7';
  const { category } = that.tableInfo;
  const tab = flatV1(_.get(that, 'tableInfo.tab'));
  if (
    ['TradeRecord', 'TradeBalance'].includes(category) &&
    ['driver_account_book_manage', 'driver_trade_record'].includes(tab)
  ) {
    titleName = '司机账本充值';
    userType = '8';
  } else if (
    ['TradeRecord', 'TradeBalance'].includes(category) &&
    ['consignor_account_book_manage', 'consignor_trade_record'].includes(tab)
  ) {
    titleName = '发货人账本充值';
    userType = '9';
  } else if (
    ['TradeRecord', 'TradeBalance'].includes(category) &&
    ['employee_account_book_manage', 'employee_trade_record'].includes(tab)
  ) {
    titleName = '员工账本充值';
    userType = '2';
  } else if (
    ['TradeRecord', 'TradeBalance'].includes(category) &&
    ['customer_account_book_manage', 'customer_trade_record'].includes(tab)
  ) {
    titleName = '客户账本充值';
    userType = '12';
  }
  const data = that.getStateSelecteRows();
  const pageKey = getPageKey().pageConfig.key;
  const popup = new PopUp(recharge, {
    popName: `recharge_${pageKey}`,
    userType,
    titleName,
    data,
    that,
    close: () => {
      popup.close();
    },
  }).show();
};
// 提现
const withdrawModal = that => {
  console.log('withdrawModal');
  let titleName = '车辆账本提现';
  let userType = '7';
  const { category } = that.tableInfo;
  const tab = flatV1((that.tableInfo || {}).tab);

  if (
    ['TradeRecord', 'TradeBalance'].includes(category) &&
    ['driver_account_book_manage', 'driver_trade_record'].includes(tab)
  ) {
    titleName = '司机账本提现';
    userType = '8';
  } else if (
    ['TradeRecord', 'TradeBalance'].includes(category) &&
    ['consignor_account_book_manage', 'consignor_trade_record'].includes(tab)
  ) {
    titleName = '发货人账本提现';
    userType = '9';
  } else if (
    ['TradeRecord', 'TradeBalance'].includes(category) &&
    ['employee_account_book_manage', 'employee_trade_record'].includes(tab)
  ) {
    titleName = '员工账本提现';
    userType = '2';
  } else if (
    ['TradeRecord', 'TradeBalance'].includes(category) &&
    ['customer_account_book_manage', 'customer_trade_record'].includes(tab)
  ) {
    titleName = '客户账本提现';
    userType = '12';
  }
  const data = that.getStateSelecteRows();
  const pageKey = getPageKey().pageConfig.key;
  const popup = new PopUp(withdrawal, {
    popName: `withdrawal_${pageKey}`,
    userType,
    titleName,
    data,
    that,
    close: () => {
      popup.close();
    },
  }).show();
};

// 后服务单详情
const viewBsDetail = (that, index, col, row) => {
  const { after_service_id, after_service_type, tr_status } = row;
  const hasViewCarmaintenancePerm = hasPermission('viewBsCarmaintenance');
  const hasViewCarRepairPerm = hasPermission('viewBsCarRepair');
  const type = BACK_SERVICE_MAP[after_service_type];
  if (!after_service_id || !type) {
    showInfo(ERROR, '参数错误！');
    return;
  }
  if (!hasViewCarRepairPerm && type === 'wx') {
    showInfo(ERROR, '您尚未开通“车辆维修”权限，无法查看！');
    return;
  }
  if (!hasViewCarmaintenancePerm && type === 'by') {
    showInfo(ERROR, '您尚未开通“车辆保养”权限，无法查看！');
    return;
  }
  if (+tr_status === 0) {
    showInfo(ERROR, '车辆已删除！');
    return;
  }
  Carmaintenance.show({
    opType: 'view',
    type,
    id: after_service_id,
  });
};
const afterServiceNo = (that, index, col, row) => {
  const { balance_no, after_service_type } = row;
  const afterServiceTypeConfig = {
    // 维修
    1: {
      controller: 'Back',
      action: 'BsCarmaintenance-repair',
      permission: ['viewBsCarRepairV1', 'viewBsCarRepair'],
      name: '维修记录',
      injectProps: {
        designateFilter: {
          bill_no: balance_no,
        },
        designateParam: {
          ignore_default: true,
        },
      },
    },
    // 保养
    2: {
      controller: 'Back',
      action: 'BsCarmaintenance-carmaintenance',
      permission: ['viewBsCarmaintenanceV1', 'viewBsCarmaintenance'],
      name: '保养记录',
      injectProps: {
        designateFilter: {
          bill_no: balance_no,
        },
        designateParam: {
          ignore_default: true,
        },
      },
    },
    3: {
      // 保险
      controller: 'Back',
      action: 'BsInsurance',
      permission: ['viewBsInsuranceV1', 'viewBsInsurance'],
      name: '保险',
      injectProps: {
        designateFilter: {
          balance_bill_no: balance_no,
        },
        designateParam: {
          ignore_default: true,
        },
      },
    },
    4: {
      // 物料入库
      controller: 'Material',
      action: 'in_stock',
      permission: ['materialInStockViewV1', 'materialInStockView'],
      name: '物料入库单',
      injectProps: {
        designateFilter: {
          bill_no: balance_no,
        },
        designateParam: {
          ignore_default: true,
        },
      },
    },
  };
  const directConfig = afterServiceTypeConfig[after_service_type];
  if (!directConfig) return showInfo(ERROR, '无法查看对应记录！');
  const { permission, name } = directConfig;
  if (!hasPermission(permission)) return showInfo(ERROR, `无权限查看${name}！`);
  delete directConfig.permission;
  delete directConfig.name;
  window.$app.direct(directConfig);
};
const handleCleanData = (file, type) => {
  if (file?.name) {
    return file;
  }
  const { original: name, title: path } = file;
  return {
    path,
    type,
    name,
  };
};
// 收支方式转账
const payModeTransferAccounts = that => {
  const selectData = that.getStateSelecteRows();
  let selectPaymode = '';
  if (selectData.length > 1) {
    showInfo(ERROR, '请选择一个收支方式');
    return;
  } else if (selectData.length === 1) {
    const { pay_mode = '' } = selectData[0].pay_mode_dict || {};
    selectPaymode = pay_mode;
    if (pay_mode.includes('账本')) {
      return showInfo(ERROR, '暂不支持对账本转账');
    }
  }
  new PopUp(PayModeTransferAccounts, {
    selectPaymode,
    onSaveSuccess: () => {
      setTimeout(() => {
        that.handleRefreshData();
      }, 1000);
    },
  }).show();
};

// 单据中心-取消审核
const documentCenterCancelAudit = (that, buttonKey) => {
  cancelAuditUniversal(that, 'create_account');
};
const showInvoiceRegist = (that, type, selectedRows, btnKey = '') => {
  const config = {
    op_from: 'incomeSummary',
    currentTableUrl: 'Table/Search/settleList',
    currentTableParams: {
      category: 'Settle',
      tab: 'balance_for_data_select_v1',
    },
    dataKey: 'balance_no',
    header: {
      biz_data_no: {
        title: '收支编号',
        width: 260,
        columnKey: 'balance_no',
        display: 'show',
        columnType: 'keyword',
        format: [],
        allowClear: true,
        analyzer: 'like',
        isMultiple: true,
        linkValue: '',
        flexGrow: 0,
        align: 'left',
        filterType: 'TextArea',
        type: 'Link',
        checked: false,
        checkable: false,
        filterable: 'true',
        sticky: true,
        sortable: 'true',
      },
    },
    tableTitle: '关联发票的收支编号',
    modalTableTitle: '收支单',
    filterKey: 'bill_id',
    isEdit: type === 'edit',
    btnKey,
    selectedRows,
  };
  invoiceRegistEx(that, config);
};
export function init(emt) {
  // emt.on('settleLogGenCert', settleLogGenCert)
  // emt.on('settleLogCancel', settleLogCancel)
  emt.on('coDeliveryPaid', coDeliveryPaid);
  emt.on('coDeliveryAvl', coDeliveryAvl);
  emt.on('coDeliveryRmt', coDeliveryRmt);
  emt.on('coDeliveryRcp', coDeliveryRcp);
  emt.on('cancelSettleReceiptPayment', cancelSettleReceiptPayment);
  // 收付款单列表 - 我创建的tab - 取消 - 开通审批流
  emt.on('cancelSettleReceiptPaymentSelfCreate', cancelSettleReceiptPayment);
  emt.on('cancelSettleCustomer', cancelSettleCustomer);

  // emt.on('receiptPaymentSettle', receiptPaymentSettle)
  // emt.on('pointSettle', pointSettle)
  // emt.on('pointCancel', pointCancel)
  // emt.on('employeeSettle', employeeSettle)
  // emt.on('employeeCancel', employeeCancel)
  // emt.on('customerSettle', customerSettle)
  // emt.on('customerCancel', customerCancel)
  // emt.on('moneyFlowDetailCancel', moneyFlowDetailCancel)
  emt.on('balanceSettle', balanceSettle);
  emt.on('ledger', ledger);
  emt.on('batchLedger', batchLedger);
  // emt.on('cancelLedger', cancelLedger)
  emt.on('deliveryLedger', deliveryLedger);
  emt.on('deliveryCancelLedger', deliveryCancelLedger);
  emt.on('shuttleLedger', shuttleLedger);
  emt.on('shuttleCancelLedger', shuttleCancelLedger);
  emt.on('pickupLedger', pickupLedger);
  emt.on('pickupCancelLedger', pickupCancelLedger);
  emt.on('onwayLedger', onwayLedger);
  emt.on('onwayCancelLedger', onwayCancelLedger);
  emt.on('toLedger', toLedger);
  emt.on('toCancelLedger', toCancelLedger);
  emt.on('confirm', confirm);
  emt.on('batchConfirm', batchConfirm);
  emt.on('cancelConfirm', cancelConfirm);
  emt.on('deliveryConfirm', deliveryConfirm);
  emt.on('deliveryCancelConfirm', deliveryCancelConfirm);
  emt.on('shuttleConfirm', shuttleConfirm);
  emt.on('shuttleCancelConfirm', shuttleCancelConfirm);
  emt.on('pickupConfirm', pickupConfirm);
  emt.on('pickupCancelConfirm', pickupCancelConfirm);
  emt.on('toConfirm', toConfirm);
  emt.on('toCancelConfirm', toCancelConfirm);
  emt.on('cash', cash);
  emt.on('shortCash', shortCash);
  // emt.on('review', review)
  // emt.on('reject', reject)
  emt.on('cancelCash', cancelCash);
  emt.on('shortCancelCash', shortCancelCash);
  emt.on('receiptApply', receiptApply);
  emt.on('paymentApply', paymentApply);
  emt.on('createEmAccount', createEmAccount);
  emt.on('createPoint', createPoint);
  emt.on('createPointBill', createPointBill);
  // 取消网点对账单
  emt.on('cancelSettlePoint', cancelSettlePoint);
  emt.on('auditSettlePoint', auditSettlePoint);
  emt.on('cancelAuditSettlePoint', cancelAuditSettlePoint);
  emt.on('updateBalance', modifyBalance(true));
  // emt.on('settleSettleNo', settleSettleNo) // 结算单号
  // emt.on('settlePaReNo', settlePaReNo)
  // emt.on('settleEmAcNo', settleEmAcNo)
  // emt.on('settleAcNo', settleAcNo)
  // emt.on('settlePoAcNo', settlePoAcNo)
  // emt.on('settleCusAcNo', settleCusAcNo)
  // emt.on('settleReNo', settleReNo)
  // emt.on('settlePaNo', settlePaNo)
  emt.on('orderDetail', orderDetail);
  emt.on('financeSumDetail', financeSumDetail);
  emt.on('settleBalanceNo', settleBalanceNo); // 收据号
  emt.on('settleBaNo', settleBalanceNo); // 收据号
  // emt.on('settleAfterServiceNo', viewBsDetail); // 后服务单详情
  emt.on('settleAfterServiceNo', afterServiceNo);
  emt.on('acApplyDailySettle|balanceNo', settleBalanceNo); // 科目汇总表侧拉日常收支tab收据号
  // emt.on('receiptPaymentReview', receiptPaymentReview)
  // emt.on('receiptPaymentReject', receiptPaymentReject)
  emt.on('moneyAudit', moenyAuditMass);
  emt.on('moneyCancelAudit', moneyCancelAudit);
  emt.on('createExpense', createExpense);
  emt.on('cancelSettleBalance', cancelCreateExpense);
  emt.on('cancelSettleBalanceDetail', cancelCreateExpense);
  emt.on('cancelSettleBalanceSelfCreate', cancelCreateExpense);
  emt.on('modifySettleBalance', modifyBalance(true));
  emt.on('modifySettleBalanceDetail', modifyBalance(true));
  emt.on('modifySettleBalanceSelfCreate', modifyBalance(false));
  emt.on('createReceipt', createReceipt);
  emt.on('checkBalance', checkBalanceApproval);
  emt.on('auditSettleBalance', checkBalanceApproval);
  emt.on('auditSettleBalanceDetail', checkBalanceApproval);
  emt.on('auditSettleBalanceAuditT', checkBalanceApproval);
  emt.on('cancelAuditSettleBalance', cancelCheckBalanceApproval);
  emt.on('cancelAuditSettleBalanceAuditT', cancelCheckBalanceApproval);
  emt.on('cancelCheckBalance', cancelCheckBalanceApproval);
  emt.on('cancelAuditSettleBalanceDetail', cancelCheckBalanceApproval);
  emt.on('deleteBalance', deleteBalance);
  emt.on('customerSubmit', customerSubmit);
  // emt.on('cusReview', cusReview)
  // emt.on('cusReject', cusReject)
  emt.on('cancelCdRmt', cancelCdRmt);
  emt.on('cancelCdAvl', cancelCdAvl);
  emt.on('cdAvl', cdAvl);
  emt.on('celCoDeliveryRmt', celCoDeliveryRmt);
  emt.on('coDeliveryAvlBatch', coDeliveryAvlBatch);
  emt.on('celCoDeliveryAvl', celCoDeliveryAvl);
  emt.on('coDyBatchBatchNum', coDyBatchBatchNum);
  emt.on('settleCertNo', settleCertNo); // 凭证号
  emt.on('acApplyDailySettle|certNo', settleCertNo); // 凭证号
  emt.on('settleBalanceDetail', settleBalanceDetail); // 明细
  // emt.on('settleBillNo', settleBillNo) // 单据号-单据中心
  emt.on('settleAuditLog', settleAuditLog);
  emt.on('settleAudit', settleAudit);
  emt.on('auditSettleReceiptPayment', auditSettleReceiptPayment); // 收付款
  // 收付款单列表 - 待审核tab - 审核 - 开通审批流
  emt.on('auditSettleReceiptPaymentAuditT', auditSettleReceiptPayment);
  emt.on('receiptPaymentAudit', auditSettleReceiptPayment); // 收付款
  emt.on('cancelAuditSettleReceiptPayment', cancelAuditSettleReceiptPayment);
  // 收付款单列表 - 待审核tab - 取消审核 - 开通审批流
  emt.on('cancelAuditSettleReceiptPaymentAuditT', cancelAuditSettleReceiptPayment);
  emt.on('cancelAuditSettleAccount', cancelAuditSettleAccount); // 员工交账
  emt.on('auditSettleAccount', auditSettleAccount);
  emt.on('auditSettleCustomer', auditSettleCustomer); // 客户对账
  emt.on('cusAudit', auditSettleCustomer); // 客户对账
  // 客户对账列表 - 待审核tab - 审核 - 开通审批流
  emt.on('auditSettleCustomerAuditT', auditSettleCustomer);
  emt.on('auditSettleCustomerAuditTTask', paymentAudit);
  emt.on('cancelAuditSettleCustomer', cancelAuditSettleCustomer);
  emt.on('customerCancelAudit', cancelAuditSettleCustomer);
  // 客户对账列表 - 待审核tab - 取消审核 - 开通审批流
  emt.on('cancelAuditSettleCustomerAuditT', cancelAuditSettleCustomer);
  emt.on('cancelAuditSettleCustomerAuditTTask', cancelAuditSettleCustomer);

  emt.on('auditSettleCustomerTask', paymentAudit);
  emt.on('cancelSettleCustomerTask', cancelSettleCustomer);
  emt.on('payModeTransfer', payModeTransferAccounts);
  emt.on('cancelAuditSettleCustomerTask', cancelAuditSettleCustomer);

  // emt.on('employeeCancelReview', employeeCancelReview)
  // 网点对账
  emt.on('pointAcAudit', auditSettlePoint);
  emt.on('pointAcAuditCancel', cancelAuditSettlePoint);
  // emt.on('employeeCancelReview',employeeCancelReview)
  emt.on('balanceLedger', ledgerMass);
  emt.on('balanceLedgerCancel', ledgerMass);
  emt.on('cCoDeliveryTicket', cCoDeliveryTicket);
  emt.on('coDyTicketBatchNum', mdCoDeliveryTicket('detail', 'coDyTicketBatchNum'));
  emt.on('cdTicketPaid', ticketlPaidSettle());
  emt.on('cdTicketCPaid', ticketlPaidSettle(true));
  emt.on('mCoDeliveryTicket', mdCoDeliveryTicket('modify', 'mCoDeliveryTicket'));

  // 物流承运单对账
  emt.on('auditSettleCarrier', auditSettleCarrier);
  emt.on('cancelAuditSettleCarrier', cancelAuditSettleCarrier);
  emt.on('cancelSettleCarrier', cancelSettleCarrier);
  emt.on('dfPay', updatePayStatus('pay'));
  emt.on('cancelDfPay', updatePayStatus('cancel'));

  // 资金流水审核单

  // // 审核审核单
  // 'audit_Settle_list_review_bill',
  // // 取消审核审核单
  // 'cancel_audit_Settle_list_review_bill',
  // // 取消审核单
  // 'cancel_Settle_list_review_bill',
  emt.on('auditSettleListReviewBill', auditSettleListReviewBill);
  emt.on('cancelAuditSettleListReviewBill', cancelAuditSettleListReviewBill);
  emt.on('cancelSettleListReviewBill', cancelSettleListReviewBill);
  emt.on('accountBookRecharge', rechargeModal);
  emt.on('accountBookWithdrawDeposit', withdrawModal);
  emt.on('cancelReservation', cancelReservation);
  // emt.on('modifySettleCustomerTask', cancelAuditSettleCustomer)
  // modify_reservation
  // auditSettleCustomerTask
  // audit_reservation
  // cancelAuditSettleCustomerTask
  emt.on('auditReservation', auditSettleCustomer);
  emt.on('auditCancelReservation', cancelAuditSettleCustomer);
  // 结算对账
  emt.on('cancelSettle', cancelSettleCustomer);
  emt.on('auditSettle', auditSettleCustomer);
  emt.on('auditCancelSettle', cancelAuditSettleCustomer);
  emt.on('documentCenterCancelAudit', documentCenterCancelAudit);
  // 发票登记
  emt.on('incomeSummaryInvoiceRegister', (that, btnKey) => {
    const selectedRows = that?.getStateSelecteRows?.();
    showInvoiceRegist(that, 'edit', selectedRows, btnKey);
  });
  // 查看发票
  emt.on('incomeSummaryQuery', (that, btnKey) => {
    const selectedRows = that?.getStateSelecteRows?.();
    showInvoiceRegist(that, 'preview', selectedRows, btnKey);
  });
  // 解绑发票
  emt.on('settleUnbindInvoice', (that, ...arg) => {
    const rowData = arg[2];
    if (rowData?.bill_id) {
      const invoiceQueryParams = {
        biz_data_ids: [rowData.bill_id],
        op_from: 'incomeSummary',
      };
      handleUnbindInvoice(that, invoiceQueryParams, () => {
        showInvoiceRegist(that, 'edit', [rowData]);
      });
    }
  });
}
