import headerMiddleware from './header/middleware';
import trafficMiddleware from './traffic/middleware';
import personMiddleware from './person/middleware';
import goodsMiddleware from './goods/middleware';
import feeMiddleware from './fee/middleware';
import extraMiddleware from './extra/middleware';
import infoMiddleware from './info/middleware';
import priceMiddleware from './info/priceMiddleware';
import profitMiddleware from './footer/middleware';

const middleware = [
  headerMiddleware,
  trafficMiddleware,
  ...personMiddleware,
  goodsMiddleware,
  ...feeMiddleware,
  extraMiddleware,
  infoMiddleware,
  priceMiddleware,
  profitMiddleware,
];

export default middleware;
