import React from 'react';
import { ERROR, INFO, CHECK, WARN } from 'constants';
import {
  emitListBtnOp,
  getUrlControllerAction,
  hasPermission,
  emitListLinkOp,
  showInfo,
  syncExecQueueFetchData,
  createTip,
  sleep,
  postJAVA,
  confirm,
  opTips,
  confirmWithReasonSync,
  viewImage,
  downloadUploadedFile,
  g7Done,
  doCheck,
} from '@/utils';
import actions from 'actions';
import { operationHandle, isOverOneCompany, opConfig } from 'pages/FinanceController/utils/';
import moveLedger from 'pages/OrderController/abnormalManage/moveLedger';
// eslint-disable-next-line import/no-cycle
import { cancelSettleReceiptPayment, cancelAuditSettleReceiptPayment, cancelSettleCustomer } from './financeOp';
import { capitalFlowView } from 'operation/listOperation/loanOp';
import { PopUp, ShowInfo } from '@/components';
import WageDetail from 'components/commoncomponents/WageDetail';
import getJAVAPriceSettingParams from 'utils/business/price/getJAVAPriceSettingParams';
import _ from 'lodash';
import attendance from 'pages/FinanceController/attendance';
import makeOfferDetail from 'pages/FinanceController/makeOfferDetail';
import salaryAdjustDetail from 'pages/FinanceController/salaryAdjustDetail';
import invoiceRegist from '../../../operation/listOperation/Finance/invoiceRegist';
// import { tableLink } from '../../../pages/TruckController/components/financeLog/action';
// import g7BatchSettlePayCheck from 'pages/CarryController/listOp/g7BatchSettlePayCheck';
import { startG7BathSettlePay } from 'pages/CarryController/listOp/utils/index.js';
import { statementofAccountDetailBtnkeyMap, statementofAccountDetailKeys } from 'pages/FinanceController/constants';
import {
  addOrderToCollection,
  getCollectionData,
  checkAndApplyInit,
  setCollectionData,
  getCollectionUniqData,
  arrayToMap,
  mapToArray,
} from 'components/commoncomponents/pickorder/PaymentCollection/utils.js';
import { COLLECTION_PAGE_CONFIG } from 'components/commoncomponents/pickorder/PaymentCollection/config';
import { handleToPicker } from 'operation/listOperation/financeRelOp.js';

const attendanceOp = (that, opType, rowdata) => {
  const row = that.getStateSelecteRows();
  if (opType === 'edit') {
    if (row.length === 0) {
      showInfo(WARN, '请选择要修改的数据');
      return;
    }
    if (row.length > 1) {
      showInfo(WARN, '请选择一条数据');
      return;
    }
  }
  let info = row && row[0];
  if (opType === 'view') {
    info = rowdata;
  }
  return attendance.show({
    info,
    opType,
    onSuccess: () => setTimeout(that.handleRefreshData, 2000),
  });
};
const makeOffer = (that, opType) => {
  const id = that.getSelectesKey('id');
  if (id.length < 1) {
    showInfo(WARN, '请选择要修改的数据');
    return;
  }
  return makeOfferDetail.show({
    id,
    opType,
    onSuccess: () => setTimeout(that.handleRefreshData, 2000),
  });
};
const salaryAdjustmentBatch = that => {
  const rows = that.getStateSelecteRows();
  if (rows.length < 1) {
    showInfo(WARN, '请选择要修改的数据');
    return;
  }
  return salaryAdjustDetail.show({
    rows,
    onSuccess: () => setTimeout(that.handleRefreshData, 2000),
  });
};

export const initEmt = function (rigType, that, extraPara, tab) {
  const extra = extraPara || {};
  if (['receiptPaymentSettle', 'customerSettle', 'employeeSettle', 'pointSettle', 'documentSettle'].includes(rigType)) {
    const payModeNoEdit = window.company_setting.pay_mode_no_edit;
    const billIds = that.getSelectesKey('bill_id');
    if (payModeNoEdit && payModeNoEdit.checked === 'settle_edit' && billIds.length > 1)
      return showInfo(ERROR, '收支方式启用了结算时录入，不允许批量结算操作！');
  }
  // 单据中心借支相关操作时做一次过滤【借支管理不支持多条结算】
  if (['documentSettle'].includes(rigType)) {
    const chooseDataTypeGroup = that.getSelectesKey('bill_cat');
    const filterType = ['loan_car', 'repay_car', 'repay_driver', 'loan_driver', 'loan_employee', 'repay_employee'];
    let count = 0;
    for (let i = 0; i <= chooseDataTypeGroup.length; i++) {
      if (filterType.includes(chooseDataTypeGroup[i])) {
        count += 1;
      }
      if (count >= 2) {
        return showInfo(ERROR, '只能操作一条数据');
      }
    }
  }
  if (rigType === 'settleBillNo') {
    const billCat = that.getData()[extraPara.rowIndex].bill_cat;
    if (
      [
        'loan_car',
        'repay_car',
        'loan_driver',
        'repay_driver',
        'loan_employee',
        'repay_employee',
        'income_pay',
      ].includes(billCat)
    ) {
      return capitalFlowView(that, extraPara.rowIndex);
    }
  }
  if (rigType === 'updateDriverWage') {
    const rowInfo = that.getSelectesKey('id');
    const rowInfoState = that.getSelectesKey('state');
    if (rowInfo.length <= 0) {
      showInfo(WARN, '请选择要操作的数据！');
      return;
    } else if (rowInfo.length >= 2) {
      showInfo(WARN, '请选择一条数据! ');
      return;
    } else if (+rowInfoState[0] !== 1) {
      showInfo(WARN, '只能修改未发放的工资单! ');
      return;
    }
  }
  const otherProps = that.getSelectesKey('otherProps');
  const settleTab = tab;
  let { isApproval } = extra;
  if (!isApproval && otherProps.length === 1) {
    const { operate = [] } = otherProps[0] || {};
    isApproval = operate.find(os => os.key === 'audit_log');
  }
  let originParam = {
    btnKey: rigType,
    pageInfo: that,
    rowIndex: extra.rowIndex,
    rowColumn: extra.colKey,
    approvalType: extra.approvalType,
    isApproval,
    settleTab,
    from: extraPara && extraPara.from ? extraPara.from : '',
    originReqFromSource: that.getOrignReq && that.getOrignReq(), // 向下传递原始Req(包含tab等数据)
    extraPara,
  };
  if (!_.isNil(extraPara?.indexNO)) {
    originParam = {
      ...originParam,
      indexNO: extraPara.indexNO,
    };
  }

  operationHandle(originParam);
};

// 收款单、付款单结算前置校验
export const beforSettleVerify = (that, btnKey) => {
  // 收款单、付款单批量结算时，必须选择费用方向相同的单据
  // 费用方向根据实际对账金额的正负判断
  const getAmountKey = btnKey === 'customerSettle' ? 'ac_amount' : 'receipt_payment_amount';
  const amountList = that.getSelectesKey(getAmountKey);
  if (amountList.length > 0) {
    // 是否包含 >= 0 的数
    const findPositiveNum = amountList.find(item => +item >= 0);
    // 是否有 < 0 的数
    const findMinusNum = amountList.find(item => +item < 0);
    if (findPositiveNum && findMinusNum) {
      return showInfo(ERROR, '勾选的对账单费用方向不一致，无法批量结算；');
    }
  }
  if (btnKey === 'receiptPaymentSettle') {
    initEmt(btnKey, that, { approvalType: 'receipt_payment' });
    return;
  }
  initEmt(btnKey, that);
};

// 发送客户对账单
const sendCustomerAccountList = async that => {
  const billIds = that.getSelectesKey('bill_id');
  if (!billIds.length) return createTip('没有操作项，至少选择一行！').show();
  await syncExecQueueFetchData({
    url: '/cmm-finance/finance/sendBill',
    data: {
      bill_ids: billIds,
    },
    getRequest: options => ({
      url: options.url,
      query: {
        bill_ids: _.get(options.response, 'res.success_id'),
      },
    }),
    success: async () => {
      createTip('您勾选的对账单发送成功', CHECK).show();
      // es入库较慢
      await sleep(500);
      typeof that.handleRefreshPage === 'function' && that.handleRefreshPage();
    },
  });
};

// 确认对账单
const confirmCustomerAccountList = async that => {
  const billIds = that.getSelectesKey('bill_id');
  if (!billIds.length) return createTip('没有操作项，至少选择一行！').show();
  await syncExecQueueFetchData({
    url: '/cmm-finance/finance/confirmBill',
    data: {
      bill_ids: billIds,
    },
    getRequest: options => ({
      url: options.url,
      query: {
        bill_ids: _.get(options.response, 'res.success_id'),
      },
    }),
    success: async () => {
      createTip('操作成功', CHECK).show();
      // es入库较慢
      await sleep(500);
      typeof that.handleRefreshPage === 'function' && that.handleRefreshPage();
    },
  });
};

// 平台业务 创建对账单
const createPlat = (that, rowIndex, column, row) => {
  console.log('createPlat =', that, rowIndex, column, row);
  const pickOrderPack = {};
  pickOrderPack.com_id = window.company_id;
  pickOrderPack.tab = 'business_detail';
  pickOrderPack.type = 'customer_business';
  pickOrderPack.category = 'Batch';
  pickOrderPack.buttonKey = 'createPlatAccount';

  const sourcePage = getUrlControllerAction();
  sourcePage.query = {};
  sourcePage.query.tabName = that.props.config && that.props.config.tabName;

  const urlData = {
    controller: 'Finance',
    action: 'pickOrder',
    query: {
      tabName: '创建对账单',
      sourcePage: JSON.stringify(sourcePage || {}),
    },
    injectProps: {
      designateFilter: {
        sr_settle_status: 1,
      },
    },
  };
  // window.$app.direct(urlData, 'prevPagePack', pickOrderPack)
  actions.api.direct(urlData, 'prevPagePack', pickOrderPack);
};
// 平台业务操作
const recEmt = function (rigType, that) {
  operationHandle({
    btnKey: rigType,
    pageInfo: that,
  });
};

// 校验是否是审批流单据，暂时不可用于行内按钮 ，不可用户创建单据。
const checkAppprovalWrap = (rigType, that, extraPara) => {
  const extra = extraPara || {};
  const otherProps = that.getSelectesKey('otherProps');
  let isApproval;
  if (otherProps.length === 1) {
    const { operate = [] } = otherProps[0] || {};
    isApproval = operate.find(os => os.key === 'audit_log');
    if (!isApproval) {
      extra.approvalType = undefined;
    }
  }
  initEmt(rigType, that, extra);
};
const switchPack = (that, isPack) => {
  const { tab } = that.tableInfo;
  const copyThat = that;
  const packApiJson = { tab, category: 'Batch', url: 'Table/Search/batchList', query: [] };
  const orderApiJson = { tab, category: 'Order', url: 'Table/Search/orderList', query: [] };
  const ApiJson = isPack ? packApiJson : orderApiJson;
  // const tableInfo = Object.assign(copyThat.tableInfo, ApiJson)
  // const _filter = Object.assign(copyThat._filter, { billing_date: '' })
  // const newThat = Object.assign(copyThat, { tableInfo })
  console.log(copyThat, 'copyThat');
  copyThat.handleRefreshPage && copyThat.handleChangeTabs(tab, { shouldRefresh: true }, ApiJson);
};
const settleLogGenCert = (that, index, column) => {
  const selected = that.getSelectesKey();
  if (selected.length === 0 && hasPermission('ac_doc_add')) {
    emitListBtnOp({
      listPage: {
        ...that,
        handleRefreshData: () => {},
      },
      space: that.getPageKey(),
      emitter: that.emitter,
      cb: () => {},
      key: 'doc_create',
      index,
      col: column,
    });
  } else {
    initEmt('settleLogGenCert', that, { rowIndex: index, colKey: column });
  }
};
const payDriverWage = async that => {
  const ids = that.getSelectesKey('id');
  const rowInfoState = that.getSelectesKey('state');
  const state = rowInfoState.filter(item => +item !== 1);
  if (ids.length === 0) {
    showInfo(WARN, '请选择要操作的数据! ');
    return;
  }
  if (state.length && ids.length <= 1) {
    showInfo(WARN, '只能发放未发放的工资单! ');
    return;
  }
  const params = {
    ids,
    isShow: true,
    autoDestroy: true,
    type: WARN,
    content: `您确定要发放工资吗？`,
    simpleMode: {
      confirm: thisDialog => {
        thisDialog.handleHide();
        const req = {
          ids,
          is_continue: 1,
        };
        // eslint-disable-next-line no-shadow
        const getdata = params => {
          postJAVA('/cmm-basic/driverWage/pay-wage', params).then(async res => {
            if (res && +res.errno === 0) {
              showInfo(CHECK, res.res);
              await sleep(1500);
              that.handleRefreshPage();
            } else {
              opTips({
                resData: res,
                opType: '工资',
                tips: '工资',
                orderType: '工资',
                sucCallback: async () => {
                  await sleep(1500);
                  that.handleRefreshPage();
                },
                continueCallback: async (sucId, cb) => {
                  const sucIds = sucId.map(item => item.id);
                  // eslint-disable-next-line no-shadow
                  const req = {
                    ids: sucIds,
                  };
                  cb && cb();
                  getdata(req);
                },
              });
            }
          });
        };
        getdata(req);
      },
      cancel: thisDialog => {
        thisDialog.handleHide();
      },
    },
  };
  const dialog = new PopUp(ShowInfo, params);
  dialog.show();
};
const driverWageWageName = async (that, index) => {
  const { id } = that.getData()[index];
  const { wage_month } = that.getData()[index];
  const wage_state = that.getData()[index].state;
  const { wage_name } = that.getData()[index];
  const { wage_no } = that.getData()[index];
  const params = {
    category: 'DriverWageDetail',
    tab: 'driver_wage_detail',
    wage_id: id,
    wage_month,
    wage_state,
    title: wage_name,
    wage_no,
  };
  new PopUp(WageDetail, params).show();
};
const resetDriverWageDetail = async (that, index) => {
  const ids = that.getSelectesKey('id');
  const rowInfoState = that.getSelectesKey('state');
  if (ids.length !== 1) {
    showInfo(WARN, '请选择一条数据！ ');
    return;
  }
  if (+rowInfoState[0] !== 1 && +rowInfoState[0] !== 3) {
    // 1 未发放 3 计算失败
    showInfo(WARN, '只能重算未发放的工资单! ');
    return;
  }
  const contentDiv = (
    <div>
      <p style={{ fontSize: '15px' }}>确定要重新计算工资吗？</p>
      <p style={{ fontSize: '12px', color: '#D9001B' }}>
        提交后，系统将会在后台重新计算工资，同时系统计算的结果会覆盖原有数据，请谨慎操作。
      </p>
    </div>
  );
  const contentSuccess = (
    <div>
      <p style={{ fontSize: '15px' }}>工资单重算申请提交成功！</p>
      <p style={{ fontSize: '12px', color: '#D9001B' }}>
        系统可能需要一段时间，在后台进行工资数据计算。计算工资期间，不会影响您的操作。计算完成后，会在消息中通知计算结果。
      </p>
    </div>
  );
  confirm(INFO, contentDiv, { confirm: '确定', cancel: '取消' }, '操作提示').then(async result => {
    if (result) {
      const req = {
        wage_id: ids[0],
        settings: getJAVAPriceSettingParams([
          'driver_base_wage_pt',
          'driver_subsidy_pt',
          'driver_business_commission_pt',
          'driver_deduction_pt',
        ]),
      };
      const res = await postJAVA('/cmm-basic/driverWage/re-calc', req);
      if (+res.errno === 0) {
        // eslint-disable-next-line no-shadow
        const confirm = await confirmWithReasonSync({
          title: '操作提醒',
          noticeTitle: '',
          iconType: WARN,
          tips: '工资单重算申请提交成功！',
          reason:
            '系统可能需要一段时间，在后台进行工资数据计算。计算工资期间，不会影响您的操作。计算完成后，会在消息中通知计算结果。',
          btnText: {
            confirm: '确定',
          },
          btns: { confirm: 1, cancel: 0 },
        });
        if (confirm) {
          that.handleRefreshPage();
        }
      }
    }
  });
};
const handleCleanData = (file, type) => {
  if (file?.name) {
    return file;
  }
  const { original: name, title: path } = file;
  return {
    path,
    type,
    name,
  };
};
const SettleSumPayMode = function (that, index) {
  const data = that.getData();
  const currHeader = that.getHeader() || {};
  // 收支方式汇总链接到明细tab，支持1.0、2.0。
  let tab = that.tableInfo?.tab;
  tab = tab.replace('_summary', '');
  const queryFilter = that.getApiSearchFilter();
  const rowData = that.formatRowEnumItem(data[index]);

  const filter = {};
  const create_time = queryFilter?.filter?.create_time?.length
    ? `${queryFilter.filter.create_time?.[0]?.[1]},${queryFilter.filter.create_time?.[1]?.[1]}`
    : '';
  const settle_time = queryFilter?.filter?.settle_time?.length
    ? `${queryFilter.filter.settle_time?.[0]?.[1]},${queryFilter.filter.settle_time?.[1]?.[1]}`
    : '';
  filter.create_time = create_time || '';
  filter.settle_time = settle_time || '';
  filter.settle_audit = queryFilter?.query?.settle_audit || '';
  const { pay_mode } = rowData.pay_mode_dict;
  // 跳转需要构建以下格式的查询数据，跳转到收支明细tab时查询条件和列表筛选头中的收支方式才能回显
  // 查询条件中要触发回显，需要把 filter.['pay_mode_dict.pay_mode'].otherProps.initFetchApi 设置为 ture，可以主动触发sug接口
  filter['pay_mode_dict.pay_mode'] = [{ all_py: '', display: pay_mode, key: pay_mode, name: pay_mode }];

  that.handleChangeTabs(tab, {
    designateFilter: filter,
    // defaultFilter: filter,
    designateParam: { ignore_default: true },
    designateHeader: currHeader,
    shouldRefresh: true,
  });
};

const paymentAcTaskPay = async (listPage, btnKey) => {
  // 校验是否开通了服务
  if (!g7Done()) return doCheck();
  // console.log(listPage);
  const { disableMenuButton, enableMenuButton, handleRefreshPage } = listPage;
  disableMenuButton?.(btnKey);
  const selecteRows = listPage.getStateSelecteRows();
  if (!(selecteRows && selecteRows.length)) {
    enableMenuButton?.(btnKey);
    return showInfo(ERROR, '请您选择一条数据！');
  }
  // 能网货支付的单据状态（审核通过 || 部分结算 ）
  const canPayAuditStates = ['1', '7'];
  const nonpaymentBill = selecteRows.reduce((pre, cur) => {
    const { pa_re_audit, bill_no } = cur;
    return canPayAuditStates.includes(pa_re_audit) ? [...pre] : [...pre, bill_no];
  }, []);
  if (nonpaymentBill.length) {
    enableMenuButton?.(btnKey);
    return showInfo(ERROR, `对账单${nonpaymentBill.join('，')}不可支付，请重新选择单据！`);
  }

  // 开始网货支付
  startG7BathSettlePay({
    listPage,
    btnKey,
    type: 'settle_custom',
  });
};

// 弹窗打开对账单详情
const openDialogStatementofAccountDetail = options => {
  const { that, rowIndex, column, data, keyNo, operation } = options;
  const billNoKey = statementofAccountDetailBtnkeyMap[operation];

  const otherProps = that.getData?.()?.[rowIndex]?.otherProps ?? {};
  const { operate = [] } = otherProps;
  const isApproval = operate?.find?.(os => os.key === 'audit_log');
  const from = location?.pathname === '/taskFinance/cliCustomerConfirm' ? 'CustomerConfirm' : '';
  const indexNO = data?.[billNoKey]?.findIndex?.(item => item === keyNo); // 多个对账单号，具体点击的单号下标
  const req = that?.getReq?.() ?? {};
  // 运单的type返回在data?.bill_jump_para_v1里面

  // 网货运单 -- 待支付 会默认菜单 that ?.getReq 里会带一个type，导致其他类型的对账单 如司机对账单展示不出来，因为没有从bill_jump_para_v1里面取。
  // 网货运单 -- 全部、待认证等其他tab 的各种对账单则不会有这个bug。故这里强制修订一下它正确的type
  if (!req.type || req.tab.indexOf('await_settle_pay_mgr') > -1) {
    req.type = data?.bill_jump_para_v1?.find(item => item.bill_no === keyNo)?.type;
  }
  _.set(data, 'customer_type', req.type); // 从当前页面获取customer_type，并手动填入列表行数据，后面在billDetail里有使用

  initEmt(operation, that, { rowIndex, colKey: column, isApproval, from, indexNO });
};

// 通过挑单页发起的对账或者使用挑单页数据发起的对账
const useCollectionDataHandle = async ({ that, originBtnKey, buttonKey, billType }) => {
  const context = that;
  const saveData = await getCollectionData({ billType, dataType: 'view' });
  const { overloadNum, pickOrderConfig } = COLLECTION_PAGE_CONFIG[billType];
  const { approvalType } = pickOrderConfig || {};
  if (saveData.size === 0) {
    return showInfo(ERROR, '待对账列表为空，请先选择数据');
  }

  if (saveData.size <= overloadNum) {
    // 先把创建对账单数据存一下，operationHandle 和 toPickOrder 中link_ids取值来源于此
    await setCollectionData({
      val: saveData,
      billType,
      dataType: 'add',
    });
    // comIdArr 从外部传入，在operationHandle中使用
    const comIdArr = [...new Set(mapToArray(saveData).map(item => item.com_id))];
    // 使用创建订单对账btnkey，走原来逻辑，在 operationHandle 和 toPickOrder 中，根据 originBtnKey 去判断做兼容
    return initEmt(buttonKey, context, { approvalType, originBtnKey, comIdArr });
  }
  const od_link_id = [...saveData.keys()];
  const checkedRes = await checkAndApplyInit({
    acount: saveData.size,
    req: { od_link_id },
    billType,
  });
  if (!checkedRes) return;
  const { company_id_list, failed_detail, fee_relation_and_fee, success_id, total_amount } = checkedRes.res;
  const allIds = success_id.map(item => item.id);
  const ids = [...new Set(allIds)];
  const comIdArr = [...new Set((company_id_list || []).map(item => item.id))];
  // 能创建对账单条数少于1000，走原来逻辑
  if (ids.length <= overloadNum) {
    const uniqData = getCollectionUniqData(success_id);
    const collectData = uniqData.map(item => ({
      od_link_id: item.id,
      com_id: company_id_list?.[0]?.id,
      order_num: item.number,
    }));
    const mapData = arrayToMap(collectData, 'od_link_id');
    // perationHandle和toPickOrder中link_ids取值来源于此
    await setCollectionData({
      val: mapData,
      billType,
      dataType: 'add',
    });
    // comIdArr 从外部传入，在operationHandle中使用
    // 使用创建订单对账btnkey，走原来逻辑，在 operationHandle和toPickOrder中，根据 originBtnKey 去判断做兼容
    return initEmt(buttonKey, context, { approvalType, originBtnKey, comIdArr });
  }
  // 不能跨网点对账
  if (opConfig.isOverOneCompany.includes(buttonKey) && !isOverOneCompany({ comIds: comIdArr })) {
    return false;
  }
  const overload = {
    success_id: [
      {
        uuid: null,
        id: null,
        expense_id: null,
        number: '',
        expense: null,
        direction: 0,
        amount: null,
        company_id: null,
        fee_direct: null,
        fee_type: null,
        fee_id: null,
      },
    ],
    operate_data: success_id,
    failed_detail,
    fee_info: fee_relation_and_fee,
    company_info: company_id_list,
    account_book: [],
    total_amount,
  };
  const { pickerReqCategory, pickerReqType, pickerReqTab } =
    COLLECTION_PAGE_CONFIG[billType]?.handleToPickerConfig || {};
  handleToPicker(context, {
    buttonKey,
    pickerPack: { approvalType },
    overload,
    isNewOverload: true,
    pickerReq: { od_link_id: [...ids], category: pickerReqCategory, type: pickerReqType, tab: pickerReqTab },
    originBtnKey,
  });
};

const addOrderToCollectionHandle = async that => {
  // 只有未对账：10，部分对账：15 能加入调单夹
  const canAddCollectionStatus = ['10', '15'];

  // 如配置了订单支持0费用对账，则无需对账也能加入
  const customer_zero_apply = _.get(window.company_setting, 'customer_zero_apply.value', []);
  const zeroApply = customer_zero_apply.includes('receipt_payment');
  if (zeroApply) {
    canAddCollectionStatus.push('0');
  }
  const selectData = that.getStateSelecteRows();
  const successIds = [];
  const failedDetail = [];
  const canAddData = [];
  const faileReason = `已对账${zeroApply ? '' : '或无需对账'}`;
  selectData.forEach(item => {
    // 能对账
    if (canAddCollectionStatus.includes(item.collection_status)) {
      successIds.push(item.od_link_id);
      canAddData.push(item);
    } else {
      failedDetail.push({
        msg: faileReason,
        number: item.order_num,
        old_msg: null,
      });
    }
  });
  if (failedDetail.length) {
    // 模拟后端返回数据
    const res = {
      errno: 0,
      errmsg: 'null',
      res: {
        success_id: [...successIds],
        failed_detail: [...failedDetail],
      },
      global_data: {},
      success: true,
      error: false,
    };
    opTips({
      resData: res,
      opType: '对账',
      tips: '订单',
      orderType: '订单',
      continueCallback: (sucId, cb) => {
        cb && cb();
        addOrderToCollection({
          newData: [...canAddData],
          billType: 'receipt',
          dataType: 'view',
        });
        that.vTable?.handleSelectAll?.(false);
      },
    });
    return;
  }
  addOrderToCollection({
    newData: [...selectData],
    billType: 'receipt',
    dataType: 'view',
  });
  that.vTable?.handleSelectAll?.(false);
};

export function init(emt) {
  // 结算管理
  // emt.on('settle', settle)
  // 结算凭证列表
  emt.on('settleSettleFile', (that, index, column, rowData, obj) => {
    const fileObj = handleCleanData(obj, 'bill');
    downloadUploadedFile(fileObj);
  });
  emt.on('settleSettleImg', (that, index, column, rowData, obj) => {
    viewImage(obj, rowData?.[column] || []);
  });
  emt.on('settle', that => initEmt('settle', that));
  emt.on('settlePack', that => initEmt('settle', that));
  emt.on('packSettlePayArrival', that => initEmt('settle', that, null, 'settle_batch_arrival_pack'));
  emt.on('packSettleCrease', that => initEmt('settle', that, null, 'settle_batch_crease_pack'));
  emt.on('showPack', that => switchPack(that, true));
  emt.on('showOrder', that => switchPack(that, false));

  // 货款管理
  emt.on('coDeliveryRcp', that => initEmt('coDeliveryRcp', that));
  emt.on('coDeliveryRmt', that => initEmt('coDeliveryRmt', that));
  emt.on('coDeliveryAvl', that => initEmt('coDeliveryAvl', that));
  emt.on('coDeliveryPaid', that => initEmt('coDeliveryPaid', that));
  emt.on('chPayOnline', that => initEmt('chPayOnline', that));

  // 收付款管理
  // emt.on('receiptApply', (that) => initEmt('receiptApply', that, { approvalType: 'order_receipt_apply' }))
  // emt.on('paymentApply', (that) => initEmt('paymentApply', that, { approvalType: 'order_receipt_apply' }))
  emt.on('receiptApply', that => initEmt('receiptApply', that));
  emt.on('paymentApply', that => initEmt('paymentApply', that));
  // 运单收收款
  emt.on('orderReceiptApply', that => initEmt('receiptApply', that, { approvalType: 'order_receipt_apply' }));
  // 加入待对账挑单夹
  emt.on('addOrderToCollection', addOrderToCollectionHandle);
  // 全部数据，挑单夹
  emt.on('allOrderToCollection', async that => {
    useCollectionDataHandle({
      that,
      originBtnKey: 'allOrderToCollection',
      buttonKey: 'receiptApply',
      billType: 'receipt',
    });
  });

  // 挑单夹——创建对账单
  emt.on('createOrderFromCollection', async (that, key, index, col, selected, buttonData, name) => {
    useCollectionDataHandle({
      that,
      originBtnKey: 'createOrderFromCollection',
      buttonKey: 'receiptApply',
      billType: 'receipt',
    });
  });

  // 发票登记
  emt.on('paymentInvoiceRegister', invoiceRegist);
  emt.on('receiptInvoiceRegister', invoiceRegist);
  // 付款单网货支付
  emt.on('paymentAcTaskPay', paymentAcTaskPay);
  emt.on('taskStatementInvoiceRegister', invoiceRegist);
  emt.on('settleInvoicePics', (that, index, buttonKey, data, img) => {
    viewImage(img, data.invoice_pics || []);
  });
  emt.on('orderPaymentApply', that => initEmt('paymentApply', that, { approvalType: 'order_payment_apply' }));
  // 发车批次收付款申请
  emt.on('trStartReceiptApply', that => initEmt('receiptApply', that, { approvalType: 'tr_start_receipt_apply' }));
  emt.on('trStartPaymentApply', that => initEmt('paymentApply', that, { approvalType: 'tr_start_payment_apply' }));
  // 短驳批次付款申请
  emt.on('shuttleReceiptApply', that => initEmt('receiptApply', that, { approvalType: 'shuttle_Receipt_apply' }));
  emt.on('shuttlePaymentApply', that => initEmt('paymentApply', that, { approvalType: 'shuttle_payment_apply' }));
  // 在途费用收款申请
  emt.on('onwayReceiptApply', that => initEmt('receiptApply', that, { approvalType: 'onway_receipt_apply' }));
  // 在途费用付款申请
  emt.on('onwayPaymentApply', that => initEmt('paymentApply', that, { approvalType: 'onway_payment_apply' }));
  //
  emt.on('settlePaReNo', (that, index, column) => {
    const { otherProps } = that.getData()[index];
    const { operate = [] } = otherProps || {};
    const isApproval = operate.find(os => os.key === 'audit_log');
    initEmt('settlePaReNo', that, { rowIndex: index, colKey: column, isApproval });
  });
  emt.on('orderExpenseReceiptBillNo', (that, index, column, data, keyNo) => {
    const { otherProps } = that.getData()[index];
    const { operate = [] } = otherProps || {};
    const isApproval = operate.find(os => os.key === 'audit_log');
    const indexNO = data?.expense_receipt_bill_no?.findIndex?.(item => item === keyNo); // 多个对账单号，具体点击的单号下标
    initEmt('orderExpenseReceiptBillNo', that, {
      rowIndex: index,
      colKey: column,
      isApproval,
      indexNO,
    });
  });

  emt.on('batchPaymentNo', (that, index, column, data, batchNo) => {
    const { otherProps } = that.getData()[index];
    const { operate = [] } = otherProps || {};
    const indexNO = data.payment_no.findIndex(item => item === batchNo);
    const isApproval = operate.find(os => os.key === 'audit_log');
    initEmt('batchPaymentNo', that, { rowIndex: index, colKey: column, isApproval, indexNO });
  });
  emt.on('batchFeeApprovalBillNo', (that, index, column) => {
    const { otherProps } = (that.getData() || [])[index];
    const { operate = [] } = otherProps || {};
    const isApproval = operate.find(os => os.key === 'audit_log');
    initEmt('batchFeeApprovalBillNo', that, { rowIndex: index, colKey: column, isApproval });
  });

  // 修改收付款单申请
  // 1.0 司机报销单 全部、我创建的（修改按钮)
  emt.on('receiptPaymentModifyV1', that =>
    initEmt('receiptPaymentModifyV1', that, { approvalType: 'receipt_payment' }),
  );

  // 2.0 收付款单列表 全部（修改按钮)
  emt.on('modifySettleReceiptPayment', that =>
    initEmt('receiptPaymentModify', that, { approvalType: 'receipt_payment' }),
  );

  emt.on('modifySettle', that => initEmt('modifySettle', that, { approvalType: 'batch_settle_ac' }));

  emt.on('receiptPaymentSettle', that => beforSettleVerify(that, 'receiptPaymentSettle'));
  emt.on('rcptPytCancelAudit', that => cancelAuditSettleReceiptPayment(that)); // 收付款单取消审核
  emt.on('receiptPaymentCancel', that => cancelSettleReceiptPayment(that));
  emt.on('receiptPaymentReview', that => initEmt('receiptPaymentReview', that));
  emt.on('receiptPaymentReject', that => initEmt('receiptPaymentReject', that));

  // 收银管理
  emt.on('cash', that => initEmt('cash', that));
  emt.on('cancelCash', that => initEmt('cancelCash', that));
  emt.on('shortCash', that => initEmt('shortCash', that));
  emt.on('shortCancelCash', that => initEmt('shortCancelCash', that));

  // 创建员工交账单
  emt.on('createEmAccount', that => initEmt('createEmAccount', that));
  emt.on('employeeCancelReview', that => initEmt('employeeCancelReview', that));
  emt.on('employeeSettle', that => initEmt('employeeSettle', that));
  emt.on('settleEmAcNo', (that, index, column) => initEmt('settleEmAcNo', that, { rowIndex: index, colKey: column }));
  emt.on('emAccountModify', that => initEmt('emAccountModify', that));
  emt.on('employeeCancel', that => initEmt('employeeCancel', that));
  emt.on('review', that => initEmt('review', that));
  emt.on('reject', that => initEmt('reject', that));

  // 创建客户对账账单
  emt.on('createAccount', that => initEmt('createAccount', that));
  emt.on('corCaApply', that => initEmt('createAccount', that, { approvalType: 'cor_ca_apply' })); // 发货客户
  emt.on('ceeCaApply', that => initEmt('createAccount', that, { approvalType: 'cee_ca_apply' })); // 收货客户

  emt.on('carrierCaApply', that => initEmt('createAccount', that, { approvalType: 'carrier_ca_apply' })); // 承运商客户
  emt.on('omsCarrierCaApply', that => initEmt('createAccount', that, { approvalType: 'oms_carrier_ca_apply' })); // 承运商客户
  emt.on('modifySettleCarrier', that => initEmt('modifySettleCarrier', that, { approvalType: 'oms_carrier_ca_apply' }));
  emt.on('createDrAccount', that => initEmt('createDrAccount', that, { approvalType: 'carrier_ca_apply' })); // 承运商对账 -> 司机对账
  emt.on('transPointCaApply', that => initEmt('createAccount', that, { approvalType: 'trans_point_ca_apply' })); // 承运网点
  emt.on('customerAccountModify', that => initEmt('customerAccountModify', that));
  // 客户对账单列表 - 全部 - 修改
  emt.on('modifySettleCustomer', that =>
    initEmt('customerAccountModify', that, { approvalType: 'create_account', per: true }),
  );
  emt.on('modifySettleCustomerTask', that =>
    initEmt('customerAccountModify', that, { approvalType: 'create_account', per: true }),
  );
  // 客户对账单列表 - 我创建的 - 修改 - 审批流
  emt.on('modifySettleCustomerSelfCreate', that =>
    initEmt('customerAccountModify', that, { approvalType: 'create_account', per: true }),
  );
  emt.on('modifySettleCustomerSelfCreateTask', that =>
    initEmt('customerAccountModify', that, { approvalType: 'create_account', per: true }),
  );
  // 客户对账单列表 - 全部 - 推送师傅
  emt.on('pushDriver', that => initEmt('pushDriver', that, { approvalType: 'push_driver', per: true }));
  // 客户对账单列表 - 全部 - 取消推送
  emt.on('cancelPushDriver', that =>
    initEmt('cancelPushDriver', that, { approvalType: 'cancel_push_driver', per: true }),
  );
  emt.on('settleAcNo', (that, index, column) => {
    const otherProps = that.getData()[index].otherProps || {};
    const { operate = [] } = otherProps;
    const isApproval = operate.find(os => os.key === 'audit_log');
    const from = location.pathname === '/taskFinance/cliCustomerConfirm' ? 'CustomerConfirm' : '';
    initEmt('settleAcNo', that, { rowIndex: index, colKey: column, isApproval, from });
  });
  /**
   * 在对账列表显示对账单号，可查看对账单详情
   *  上游对账-客户运单对账-客户明细
      上游对账-项目运单对账-项目明细
      下游对账-司机明细/车辆明细/承运商明细
      还有2.0任务对账下的:
      司机/车辆/承运商/客户/项目/派单网点
      运单列表
   */
  statementofAccountDetailKeys.forEach(key => {
    emt.on(key, (that, rowIndex, column, data, keyNo) => {
      // 这种方式也可以打开对账单详情，但是显示的内容不完整 ↓
      // const newData = _.cloneDeep(data);
      // _.set(newData, 'finance_bill_no', keyNo);
      // _.set(newData, 'ori_finance_type', 'ft_driver_ac');
      // tableLink({ rowIndex, column, data: newData, keyNo });
      // 这种方式也可以打开对账单详情，但是显示的内容不完整↑
      openDialogStatementofAccountDetail({
        that,
        rowIndex,
        column,
        data,
        keyNo,
        operation: key,
      });
    });
  });

  emt.on('settleCusAcNo', (that, index, column) => initEmt('settleCusAcNo', that, { rowIndex: index, colKey: column }));
  emt.on('customerSettle', that => beforSettleVerify(that, 'customerSettle'));
  // 发送对账单
  emt.on('customerAcConfirmSend', that => sendCustomerAccountList(that));
  // 确认对账单
  emt.on('customerAcConfirmOk', that => confirmCustomerAccountList(that));

  // 客户对账单列表 - 全部 - 取消 - 审批流
  emt.on('customerCancel', that => cancelSettleCustomer(that));
  // 客户对账单列表 - 我创建的 - 取消 - 审批流
  emt.on('cancelSettleCustomerSelfCreate', that => cancelSettleCustomer(that));
  emt.on('cancelSettleCustomerSelfCreateTask', that => cancelSettleCustomer(that));
  emt.on('cusReview', that => initEmt('cusReview', that));
  emt.on('cusReject', that => initEmt('cusReject', that));
  // 创建网点对账单
  emt.on('createPoint', that => initEmt('createPoint', that, { approvalType: 'create_point' }));
  emt.on('pointSettle', that => initEmt('pointSettle', that));
  emt.on('settlePoAcNo', (that, index, column) => initEmt('settlePoAcNo', that, { rowIndex: index, colKey: column }));
  emt.on('batchAccountDetail', (that, index, column) =>
    initEmt('batchAccountDetail', that, { rowIndex: index, colKey: column }),
  );
  emt.on('pointCancel', that => initEmt('pointCancel', that));
  emt.on('pointAccountModify', that =>
    checkAppprovalWrap('pointAccountModify', that, { approvalType: 'create_point' }),
  );
  emt.on('modifySettlePoint', that => checkAppprovalWrap('pointAccountModify', that, { approvalType: 'create_point' }));

  // 挂账
  emt.on('ledger', that => initEmt('ledger', that));
  emt.on('toLedger', that => initEmt('toLedger', that));
  emt.on('shuttleLedger', that => initEmt('shuttleLedger', that));
  emt.on('deliveryLedger', that => initEmt('deliveryLedger', that));
  emt.on('pickupLedger', that => initEmt('pickupLedger', that));
  emt.on('onwayLedger', that => initEmt('onwayLedger', that));
  emt.on('ledgerSr', that => initEmt('ledgerSr', that));
  // 取消挂账
  emt.on('cancelLedger', that => initEmt('cancelLedger', that));
  emt.on('toCancelLedger', that => initEmt('toCancelLedger', that));
  emt.on('shuttleCancelLedger', that => initEmt('shuttleCancelLedger', that));
  emt.on('deliveryCancelLedger', that => initEmt('deliveryCancelLedger', that));
  emt.on('pickupCancelLedger', that => initEmt('pickupCancelLedger', that));
  emt.on('onwayCancelLedger', that => initEmt('onwayCancelLedger', that));
  emt.on('cancelLedgerSr', that => initEmt('cancelLedgerSr', that));

  // 业务确认
  emt.on('confirm', that => initEmt('confirm', that));
  emt.on('cancelConfirm', that => initEmt('cancelConfirm', that));
  emt.on('toConfirm', that => initEmt('toConfirm', that));
  emt.on('toCancelConfirm', that => initEmt('toCancelConfirm', that));
  emt.on('shuttleConfirm', that => initEmt('shuttleConfirm', that));
  emt.on('shuttleCancelConfirm', that => initEmt('shuttleCancelConfirm', that));
  emt.on('deliveryConfirm', that => initEmt('deliveryConfirm', that));
  emt.on('deliveryCancelConfirm', that => initEmt('deliveryCancelConfirm', that));
  emt.on('pickupConfirm', that => initEmt('pickupConfirm', that));
  emt.on('pickupCancelConfirm', that => initEmt('pickupCancelConfirm', that));

  // 资金流水
  emt.on('settleLogCancel', that => initEmt('settleLogCancel', that));
  emt.on('moneyFlowDetailCancel', that => initEmt('moneyFlowDetailCancel', that));
  emt.on('settleReNo', (that, index, column) => initEmt('settleReNo', that, { rowIndex: index, colKey: column }));
  emt.on('settlePaNo', (that, index, column) => initEmt('settlePaNo', that, { rowIndex: index, colKey: column }));
  emt.on('settleLogGenCert', settleLogGenCert);
  emt.on('moneyFlowApply', that =>
    initEmt('moneyFlowApply', that, { approvalType: 'money_flow_apply', isApproval: true }),
  );
  emt.on('modifySettleListReviewBill', that =>
    initEmt('modifySettleListReviewBill', that, { approvalType: 'money_flow_apply', isApproval: true }),
  );
  // 资金流水账
  emt.on('settleSettleNo', (that, index) => initEmt('settleSettleNo', that, { rowIndex: index }));
  emt.on('settleOriSettleNo', (that, index) => initEmt('settleOriSettleNo', that, { rowIndex: index }));
  emt.on('acApplyDailySettle|settleNo', (that, index) => initEmt('settleSettleNo', that, { rowIndex: index }));
  // 收支方式余额表
  emt.on('settleSumPayModeDict.payMode', SettleSumPayMode);

  // 资金流水审核单
  emt.on('SettleReviewNo', (that, index) => initEmt('SettleReviewNo', that, { rowIndex: index, colKey: 'bill_no' }));
  // 单据中心结算
  emt.on('documentSettle', that => initEmt('documentSettle', that));
  emt.on('settleBillNo', (that, index, col, row) => {
    if (that.tableInfo.tab.includes('invoice')) {
      emitListLinkOp({
        listPage: that,
        index,
        col,
        row,
        key: 'Settle_invoice_no',
      });
    } else {
      initEmt('settleBillNo', that, { rowIndex: index });
    }
  });
  // 日常收支
  // emt.on('balanceSettle', (that) => initEmt('balanceSettle', that))
  emt.on('moveLedger', that => moveLedger(that));
  emt.on('moveCancelLedger', that => moveLedger(that, true));
  // 代收换票
  emt.on('coDeliveryPaidTicket', that => initEmt('coDeliveryPaidTicket', that));
  emt.on('coDeliveryTicket', that => initEmt('coDeliveryPaidTicket', that));
  emt.on('customerPlatModify', that => recEmt('customerPlatModify', that));
  emt.on('customerPlatCancel', that => recEmt('customerPlatCancel', that));
  emt.on('createPlatAccount', createPlat);
  emt.on('applyAccount', that => recEmt('applyAccount', that));
  emt.on('moneyFlowNum', that => recEmt('moneyFlowNum', that));
  emt.on('modifyReservation', that =>
    initEmt('modifyReservation', that, { approvalType: 'create_account', per: true }),
  );
  emt.on('settleReservation', that => initEmt('customerSettle', that));
  // 工资管理
  // 工资管理-创建司机
  // 工资管理-创建-司机
  emt.on('addDriverWageDriver', that =>
    initEmt('addDriverWageDriver', that, { approvalType: 'add_driver_wage_driver' }),
  );
  // 工资管理-创建-组织
  emt.on('addDriverWageCompany', that =>
    initEmt('addDriverWageCompany', that, { approvalType: 'addDriverWageCompany' }),
  );
  // 工资管理-修改工资单
  emt.on('updateDriverWage', that => initEmt('updateDriverWage', that, { approvalType: 'updateDriverWage' }));
  // 工资管理-发放工资
  emt.on('payDriverWage', that => payDriverWage(that));
  // 工资管理-详情
  emt.on('driverWageWageName', driverWageWageName);
  // 工资单详情-重算工资
  emt.on('resetDriverWage', resetDriverWageDetail);
  // 工资单详情修改
  emt.on('addDriverAttendance', that => attendanceOp(that, 'add')); // 考勤管理新增
  emt.on('updateDriverAttendance', that => attendanceOp(that, 'edit')); // 考勤管理修改
  emt.on('driverAttendanceReportName', (that, index, key, rowData) => attendanceOp(that, 'view', rowData)); // 考勤管理查看
  emt.on('makeOffer', makeOffer); // 定薪
  emt.on('salaryAdjustmentBatch', salaryAdjustmentBatch); // 批量调薪

  emt.on('updateDriverWageDetail', that =>
    initEmt('updateDriverWageDetail', that, { approvalType: 'updateDriverWageDetail' }),
  );
  // DriverWage_wage_name
  emt.on('driverWageWageNo', driverWageWageName);
}
