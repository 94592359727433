const calcMaxFreight = goods =>
  goods.reduce((total, item) => {
    const weightFreight = (+item.weight || 0) * (+item.weight_unit_price || 0);
    const volumeFreight = (+item.volume || 0) * (+item.volume_unit_price || 0);
    const numFreight = (+item.num || 0) * (+item.num_unit_price || 0);
    const suitUnitPrice = (+item.suit || 0) * (+item.suit_unit_price || 0);
    const subtotalFreight = +item.subtotal_price || 0;
    return total + Math.max(weightFreight, volumeFreight, numFreight, suitUnitPrice, subtotalFreight);
  }, 0);

export default calcMaxFreight;
