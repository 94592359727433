exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".order-editor-fee{display:flex;align-items:stretch;border-bottom:1px solid #78aadd}.order-editor-fee>div{border-right:1px solid #78aadd}.order-editor-fee>div h3{height:32px;line-height:32px;font-size:14px;box-shadow:inset 0 0 0 0 transparent;background:#cae0f6;border-bottom:1px solid #78aadd}.order-editor-fee>div h3:before{content:\"\";padding:0 1px;background:#ff8100;margin:0 6px}.order-editor-fee>div>div{display:flex;align-items:center;position:relative;height:34px;padding:0 3px;margin-bottom:1px}.order-editor-fee>div>div .fn-label{position:relative;flex:0 0 72px;text-align:justify;-moz-text-align-last:justify;text-align-last:justify;padding-right:12px}.order-editor-fee>div>div .fn-label:after{position:absolute}.order-editor-fee>div>div .fn-input-pure{text-align:center}.order-editor-fee>div>div .fn-checkbox-pure{right:0;top:14px}.order-editor-fee>div>div .fn-checkbox-pure+.input-tip{right:15px}", ""]);

// exports
exports.locals = {
	"prefixCls": "order-editor-fee",
	"order-editor-fee": "order-editor-fee",
	"fn-label": "fn-label",
	"fn-input-pure": "fn-input-pure",
	"fn-checkbox-pure": "fn-checkbox-pure",
	"input-tip": "input-tip"
};