import React from 'react';
import { PopUp, PopTip, ShowInfo, BatchOp } from 'components';
import { postData as gpostData, opTips, remDub, throttle, fetchApi, showInfo } from 'utils';
import { ERROR, INFO, CHECK, OP_CH_TIPS, WARN } from 'constants';
import _ from 'lodash';
import actions from 'actions';
import { preArrivalCheck } from './orderOp';
import { taskStateOp as task_taskStateOp } from '../../../operation/listOperation/taskOp';
import { batchTaskAdd } from './truckOp/batchTaskAdd';
import { i18n } from '@/utils/i18n/index';
import { POPUP_CONFIRM_WINDOW } from '@/utils/i18n/constants';

const isFromPickClip = listPage => listPage && listPage.getReq().tab === 'pick_clip'; // 是否是挑单夹事件
/**
 * 此方法是用来兼容挑单夹取数据的方法
 * 注：挑单夹的源数为全部数据
 * @param {*} keys key
 * @param {*} that listpage 引用
 */
// const getData = (keys, that) => {
//   let obj = {}
//   keys.forEach(v => obj[v] = [])
//   if (isFromPickClip(that)) {
//     let data = that.getData()
//     data.forEach((item, index) => {
//       keys.forEach((key) => {
//         if (!obj[key]) {
//           obj[key] = []
//         }
//         obj[key][index] = item[key]
//       })
//     })
//   } else {
//     keys.forEach(v => obj[v] = that.getSelectesKey(v))
//   }
//   return obj
// }
function showPopTip(type, content, callback) {
  new PopUp(PopTip, {
    type,
    content,
    onClose: () => {
      if (typeof callback === 'function') callback();
    },
  }).show();
}
function showShowInfo(type, content, title, callback) {
  const popup = new PopUp(ShowInfo, {
    classname: 'del-customer',
    isShow: true,
    type: INFO,
    content,
    autoDestroy: true,
    title: title || '确定',
    simpleMode: {
      cancel: thisDialog => {
        thisDialog.handleHide();
        if (typeof callback === 'function') callback();
      },
    },
    closable: true,
    maskClosable: true,
  });
  popup.show();
}
function checkOp(idIndex) {
  if (idIndex.length <= 0) {
    showPopTip(ERROR, '请选择操作批次');
    return false;
  }
  if (idIndex.length > 1) {
    showPopTip(ERROR, '不能操作多个批次');
    return false;
  }
  return true;
}
function checkOp2(idIndex, type, canBatch) {
  if (idIndex.length <= 0) {
    showPopTip(ERROR, `请选择操作${type}`);
    return false;
  }
  if (idIndex.length > 1 && canBatch !== 1) {
    showPopTip(ERROR, `不能操作多个${type}`);
    return false;
  }
  return true;
}
function checkComId(comId, tips, popupType, title) {
  const _tips = tips || '一次只能操作一个网点的数据';
  if (popupType && comId.length > 1) {
    showShowInfo(ERROR, _tips, title);
    return false;
  }
  if (comId.length > 1) {
    showPopTip(ERROR, _tips);
    return false;
  }
  return true;
}
const isAllTask = list => {
  const task = [];
  const batch = [];
  list.forEach(t => {
    if (+t.blk_status === 2 && t.transtask_flag !== 0) {
      task.push(t);
    } else {
      batch.push(t);
    }
  });
  console.log(task);
  if (batch.length === list.length && !task.length) {
    return 'batch';
  } else if (task.length === list.length && !batch.length) {
    return 'task';
  }
  showInfo(WARN, '不能同时操作批次和任务');
  return 'both';
};
// 判断顶部筛选中选中的companyId, 业务操作只能选择一个网点进行操作
export const checkFilterCompanyId = that => {
  const comIds =
    (that.getAllFitlerQuery && that.getAllFitlerQuery().com_id) ||
    (that.getAllFitlerQuery && that.getAllFitlerQuery().company_id) ||
    [];
  const _comIds = remDub(comIds); // 去重
  if (_comIds.length > 1 || (_comIds.length === 1 && _comIds[0] === '_NULL_')) {
    showPopTip(ERROR, '请在网点筛选中选择一个网点进行操作！');
    return false;
  }
  return _comIds;
};
export const cancelLoad = function (bLinkInfo, opType, url, props, callback, ext) {
  let pack = {
    op_type: 'b_list',
  };
  const showInfoContentType = ext && ext.showInfoContentType;
  const showInfoContent = showInfoContentType === 'order' && `您确定对所选运单${OP_CH_TIPS[opType]}吗？`;
  pack = Object.assign(pack, bLinkInfo, props);
  new PopUp(ShowInfo, {
    type: INFO,
    content: showInfoContent || `您确定整车${OP_CH_TIPS[opType]}吗`,
    title: '取消装车',
    simpleMode: {
      confirm: (thisDialog, btnKey) => {
        ext && ext.that && ext.btnKey && ext.that.disableMenuButton && ext.that.disableMenuButton(ext.btnKey);
        thisDialog && btnKey && thisDialog.changeBtnLoadingStatus(btnKey, true);
        gpostData(url, pack, res => {
          ext && ext.that && ext.btnKey && ext.that.enableMenuButton && ext.that.enableMenuButton(ext.btnKey);
          thisDialog && btnKey && thisDialog.changeBtnLoadingStatus(btnKey, false);
          if (res.data && res.data.errno) {
            // 有错误直接报
            showPopTip(ERROR, res.data.errmsg || '取消失败');
            return; // 在promise后面有return,promise还会执行吗?
          }
          if (res.data && res.data.errno === 0) {
            opTips({
              opType,
              resData: res.data,
              sucCallback: () => {
                typeof callback === 'function' && callback();
              },
              continueCallback: (sucId, cb) => {
                pack.isCheck = 0;
                gpostData(url, pack, () => {
                  typeof cb === 'function' && cb();
                  typeof callback === 'function' && callback();
                });
              },
            });
          }
        });
        thisDialog.handleHide();
      },
      cancel: thisDialog => thisDialog.handleHide(),
    },
  }).show();
};
// 取消装车-运单-二期优化
export const cancelLoad2 = function (param) {
  const tips = param.tips || '';
  const msg = `您确定${tips}吗？`;
  const { opType } = param;
  const { bLinkInfo } = param;
  const { callback } = param;
  const { url } = param;
  const { tipsType } = param;
  const { ext } = param;
  const ids = [];
  ids.push(bLinkInfo[0].od_link_id);
  const pack = {
    op_type: opType,
    company_id: bLinkInfo[0].com_id,
    od_link_ids: ids,
  };
  new PopUp(ShowInfo, {
    type: INFO,
    content: msg,
    title: tips,
    simpleMode: {
      confirm: thisDialog => {
        ext && ext.that && ext.btnKey && ext.that.disableMenuButton && ext.that.disableMenuButton(ext.btnKey);
        gpostData(url, pack, res => {
          ext && ext.that && ext.btnKey && ext.that.enableMenuButton && ext.that.enableMenuButton(ext.btnKey);
          if (res.data !== undefined && res.data.errno === 0) {
            opTips({
              opType: tipsType,
              resData: res.data,
              sucCallback: () => {
                // successCallback
                typeof callback === 'function' && callback();
              },
              continueCallback: (sucId, cb) => {
                pack.isCheck = 0;
                gpostData(url, pack, () => {
                  typeof cb === 'function' && cb();
                  typeof callback === 'function' && callback();
                });
              },
            });
          } else {
            showPopTip(ERROR, res.data.errmsg || '取消失败');
          }
        });
        thisDialog.handleHide();
      },
      cancel: thisDialog => thisDialog.handleHide(),
    },
  }).show();
};
export const cancelAcceptLoad = function (bLinkInfo, msg, url, props, callback, ext) {
  let pack = {
    op_type: 'b_list',
    isCheck: 1, // TODO 这个isCheck还是is_check还是没有这个字段???
  };
  const tips = '取消卸车';
  pack = Object.assign(pack, bLinkInfo);
  pack = Object.assign(pack, props);
  console.log(ext);
  new PopUp(ShowInfo, {
    type: INFO,
    content: msg,
    title: tips,
    simpleMode: {
      confirm: (thisDialog, btnKey) => {
        thisDialog && btnKey && thisDialog.changeBtnLoadingStatus(btnKey, true);
        gpostData(url, pack, res => {
          thisDialog && btnKey && thisDialog.changeBtnLoadingStatus(btnKey, false);
          if (res.data !== undefined && res.data.errno === 0 && res.data.res) {
            if (res.data.res.failed_detail.length === 0) {
              showPopTip(CHECK, `${tips}成功`, () => {
                typeof callback === 'function' && callback();
              });
            } else {
              opTips({
                opType: 'cancelUnload',
                resData: res.data,
                sucCallback: () => {
                  showPopTip(CHECK, `${tips}成功`, () => {
                    typeof callback === 'function' && callback();
                  });
                },
                continueCallback: (sucId, cb) => {
                  // continueCallback
                  typeof cb === 'function' && cb(); // "继续"操作完成后的回调
                  pack.isCheck = 0;
                  gpostData(url, pack, secRes => {
                    if (secRes.data !== undefined && secRes.data.errno === 0) {
                      showPopTip(CHECK, `${tips}成功`, () => {
                        typeof callback === 'function' && callback();
                      });
                    } else {
                      showPopTip(ERROR, secRes.data.errmsg || `${tips}失败`);
                    }
                  });
                },
              });
            }
          } else {
            showPopTip(ERROR, res.data.errmsg || '取消失败');
          }
        });
        thisDialog.handleHide();
      },
      cancel: thisDialog => {
        thisDialog.handleHide();
      },
    },
  }).show();
};
// 运单跳转到新的页面所做的校验
const checkOrderComId = function (that, btnKey) {
  let comIds = checkFilterCompanyId(that);
  if (comIds === false) return false;
  if (comIds.length !== 0) return comIds;
  // 顶部筛选没有筛选, 判断运单中是否有不同网点的运单
  // const ids = that.getSelectesKey('od_link_id')
  comIds = remDub(that.getSelectesKey('com_id', isFromPickClip(that)));
  if (!checkComId(comIds)) return false;
  comIds = comIds.length === 0 ? [window.company_id] : comIds;
  return comIds;
};
// 批次跳转到新增页面的校验
const checkBatchComId = function (that) {
  let comIds = checkFilterCompanyId(that);
  if (comIds === false) return false;
  comIds = comIds.length === 0 ? [window.company_id] : comIds;
  return comIds;
};
// 设置并且保存下游的费用
export const modifyArrFee = function (pack, callback) {
  const url = '/Truck/Truck/modifyArrFee';
  gpostData(url, pack, res => {
    if (res.data.errno !== 0) {
      showPopTip(ERROR, res.data.errmsg);
      return;
    }
    showPopTip(CHECK, '保存成功！');
    if (typeof callback === 'function') callback();
  });
};
// 运单卸车  (到车管理--卸车)
export const odUnloadFunc = (pack, callback, ext) => {
  ext && ext.that && ext.btnKey && ext.that.disableMenuButton && ext.that.disableMenuButton(ext.btnKey);
  const url = '/Truck/Truck/onUnload';
  const tips = '卸车';
  gpostData(url, pack, res => {
    ext && ext.that && ext.btnKey && ext.that.enableMenuButton && ext.that.enableMenuButton(ext.btnKey);
    if (res.data.errno === -155) {
      const msg = res.data.errmsg.split('#');
      if (msg.length === 3) {
        msg[1] = <span style={{ color: 'red' }}>{msg[1]}</span>;
      }
      return new PopUp(ShowInfo, {
        type: ERROR,
        content: msg,
        simpleMode: {
          confirm: t => {
            t.handleHide();
            ext.that.handleRefreshData();
          },
        },
        simpleText: {
          confirm: '刷新',
        },
      }).show();
    }
    opTips({
      opType: 'unLoad',
      resData: res.data,
      sucCallback: () => {
        if (typeof callback === 'function') callback();
      },
      continueCallback: (sucId, checkCallback) => {
        const newPack = pack;
        if (pack.type !== 'single') {
          newPack.od_link_ids = sucId;
        }
        // newPack.isCheck = 0
        newPack.is_check = 0;
        gpostData(url, pack, secRes => {
          if (secRes.data !== undefined && secRes.data.errno === 0) {
            showPopTip(CHECK, `${tips}成功`, () => {
              if (typeof callback === 'function') callback();
              if (typeof checkCallback === 'function') checkCallback();
            });
          } else {
            showPopTip(ERROR, secRes.data.errmsg || `${tips}失败`);
          }
        });
      },
      tips: `${tips}成功`,
      title: `${tips}确认`,
      ext: {
        failedCallback: ext.failedCallback,
        noSplice: (res.data && res.data.res && res.data.res.is_hint) || false,
      },
    });
  });
};

export const batchCheckCompanyId = that => {
  // companyIds 运单的company_id
  const comIds = that.getAllFitlerQuery().com_id || that.getAllFitlerQuery().company_id;
  const curId = window.company_id;
  const curType = window.company_info.type;
  const _comIds = remDub(comIds); // 去重
  if (+curType === 1 || +curType === 2) {
    // 当前登陆的是职能组织
    if (_comIds.length !== 1 || (_comIds.length === 1 && _comIds[0] === '_NULL_')) {
      showPopTip(ERROR, '请在网点筛选中选择一个网点进行操作！');
      return false;
    }
    return _comIds;
  }
  if (_comIds.length > 1 || (_comIds.length === 1 && _comIds[0] === '_NULL_')) {
    showPopTip(ERROR, '请在网点筛选中选择一个网点进行操作！');
    return false;
  } else if (_comIds.length < 1) {
    return [curId];
  }
  return _comIds;
};

const customValidity = val => {
  if (val && val.black_set === '1') {
    // showInfo(WARN, '当前司机已被拉黑')
    return { type: 'warn', msg: '当前司机已被拉黑' };
  } else if (val && val.black_set === '2') {
    return '当前司机已被拉黑';
  }
  return '';
};

export const directNewPageInnerFunc = (ids, comId, extData, btnKey, that, idArr) => {
  const ext = extData;
  const tabArr = ['settle_pay_arrival', 'pack_default', 'settle_crease'];
  const showPackKye = ['cargo', 'delivery'];
  const showPack =
    (that &&
      that.state &&
      that.state.req &&
      tabArr.indexOf(that.state.req.tab) > -1 &&
      showPackKye.indexOf(btnKey) > -1) ||
    false;
  const fromPickClip = isFromPickClip(that) || false;
  const urlData = {
    controller: ext.controller,
    action: ext.tab || 'load',
    query: {
      fromPickClip, // 用来区分是否是挑单夹页面跳转过来的
      tabName: ext.tabName,
      showPack,
      isOrderPicker: true,
    },
  };
  if (btnKey === 'reservation_pickup') {
    urlData.query.reservation = true;
  }
  let directInfo = {
    comId: comId && comId[0],
    ...ext.reqPara,
  };
  const pack = {
    type: 'add',
    op_type: 'create',
    company_id: comId && comId[0],
    loading_od_link_ids: ids || [],
    id: idArr || [],
    // 其他的参数
    ...ext.reqPara,
  };
  btnKey && that.disableMenuButton && that.disableMenuButton(btnKey);
  console.log(ext.opTipsPara, 'extextextextextextextextextextext');
  gpostData(ext.checkUrl, pack, res => {
    btnKey && that.enableMenuButton && that.enableMenuButton(btnKey);
    if (ext.showErrWithOptips && res.data.res.failed_detail.length) {
      opTips({
        resData: res.data,
        ...ext.opTipsPara,
        continueCallback: (sucId, cb) => {
          cb && cb();
          const key = (ext.opTipsPara && ext.opTipsPara.successIdKey) || 'od_link_ids';
          ext.reqPara[key] = sucId;
          directNewPageInnerFunc(sucId, comId, ext, btnKey, that);
        },
      });
      return;
    } else if (res.data.errno !== 0) {
      const popup = new PopUp(ShowInfo, {
        classname: 'del-customer',
        isShow: true,
        type: ERROR,
        content: res.data.errmsg,
        autoDestroy: true,
        title: ext.checkTips,
        simpleMode: {
          cancel: thisDialog => thisDialog.handleHide(),
        },
        closable: true,
        maskClosable: true,
      });
      popup.show();
      return;
    }
    directInfo = { ...directInfo, pickOrderInfo: res.data.res, sendReq: false, operationBtn: btnKey };
    if (ext.direct) {
      ext.direct(directInfo);
    } else {
      const dr_name = _.get(directInfo.pickOrderInfo, 'batchTitleInfo.cardInfo.dr_name');
      const dr_phone = _.get(directInfo.pickOrderInfo, 'batchTitleInfo.cardInfo.dr_phone');
      const dr_assistant_name = _.get(directInfo.pickOrderInfo, 'batchTitleInfo.cardInfo.dr_assistant_name');
      const dr_assistant_phone = _.get(directInfo.pickOrderInfo, 'batchTitleInfo.cardInfo.dr_assistant_phone');
      if (dr_name) {
        directInfo.pickOrderInfo.batchTitleInfo.cardInfo.dr_name.customValidity = customValidity;
      }
      if (dr_phone) {
        directInfo.pickOrderInfo.batchTitleInfo.cardInfo.dr_phone.customValidity = customValidity;
      }
      if (dr_assistant_name) {
        directInfo.pickOrderInfo.batchTitleInfo.cardInfo.dr_assistant_name.customValidity = customValidity;
      }
      if (dr_assistant_phone) {
        directInfo.pickOrderInfo.batchTitleInfo.cardInfo.dr_assistant_phone.customValidity = customValidity;
      }
      actions.api.direct(urlData, 'directInfo', directInfo);
    }
  });
};
// 新增送货/新增提货/新增短驳跳转到操作页面
const direactNewPage = async (that, btnKey, ext) => {
  // 是否为批次页面操作
  const isBatch = ext.isBatch === undefined ? true : ext.isBatch;
  // eslint-disable-next-line no-nested-ternary
  const comId = ext.comId ? ext.comId : isBatch ? checkBatchComId(that) : checkOrderComId(that, btnKey);
  const getKeyList = ['od_link_id', 'com_id', 'b_link_id', 'order_num', 'id', 'company_id', 'car_batch'];
  let odLinkIds;
  let bLinkIds;
  let orderNum;
  let IDS;
  let carBatch;
  try {
    ({
      od_link_id: odLinkIds,
      b_link_id: bLinkIds,
      order_num: orderNum,
      id: IDS,
      car_batch: carBatch,
    } = await preArrivalCheck(that, ext.checkTips, getKeyList));
  } catch (error) {
    return;
  }
  console.log('***********************');
  // if (btnKey === 'reservation_pickup') {
  //   comId = companyId
  // }
  if (comId === false) return;
  const ids = isBatch ? [] : remDub(btnKey === 'reservation_pickup' ? IDS : odLinkIds);
  if (ids === false) return;
  if (ext && ext.paraAddBLinkId) {
    Object.assign(ext, { reqPara: { ...ext.reqPara, b_link_id: bLinkIds[0] } });
  }
  if (ext && ext.hasBLinkId) {
    Object.assign(ext, { reqPara: { ...ext.reqPara, b_link_ids: bLinkIds } });
  }
  if (ext && ext.hasOdLinkId) {
    odLinkIds = odLinkIds.filter(i => i);
    orderNum = orderNum.filter(i => i);
    Object.assign(ext, { reqPara: { ...ext.reqPara, od_link_ids: odLinkIds } });
  }
  if (ext && !ext.hasBLinkId && bLinkIds) {
    // tab为数组中的，选中后进行操作会挑单
    const tabArr = ['pack_default', 'settle_pay_arrival', 'settle_crease'];
    const b_pack_link_ids =
      tabArr.indexOf(that.tableInfo.tab) > -1 ? { b_pack_link_ids: bLinkIds, pack_batch: carBatch } : {};
    Object.assign(ext, { reqPara: { ...ext.reqPara, ...b_pack_link_ids } });
  }
  Object.assign(ext, { reqPara: { ...ext.reqPara, orderNum } });
  directNewPageInnerFunc(ids, comId, ext, btnKey, that, IDS);
};
// 新增配载
export const cargo = function (that, btnKey, isBatch = true) {
  // 是否为预装车
  const isPreLoad = _.get(window, 'company_setting.task_auto_loading.checked') === 'yes'; // 是自动装车
  direactNewPage(that, btnKey, {
    checkTips: '新增配载',
    controller: 'Truck',
    checkUrl: isPreLoad ? '/Truck/Truck/onLoading' : 'Truck/TruckPre/onLoading',
    isBatch,
  });
};
// 新增打包
export const addPack = function (that, btnKey, isBatch) {
  console.log('新增打包', btnKey, isBatch);
  direactNewPage(that, btnKey, {
    checkTips: '新增打包',
    controller: 'Operate',
    checkUrl: 'Truck/Pack/onLoading',
    isBatch,
  });
};
export const editPack = function (that, btnKey, isBatch) {
  direactNewPage(that, btnKey, {
    checkTips: '修改打包',
    controller: 'Operate',
    checkUrl: 'Truck/Pack/onLoading',
    isBatch,
  });
};
export const preCargoFunc = function (that, btnKey, isBatch) {
  direactNewPage(that, btnKey, {
    checkTips: '预装车',
    controller: 'Truck',
    tab: 'preload',
    checkUrl: '/Truck/TruckPre/onLoading',
    reqPara: {
      isPreLoad: true,
    },
    isBatch,
  });
};
// 新增配载
export const preCargo = function (that, btnKey, isBatch = true) {
  preCargoFunc(that, btnKey, isBatch);
};
// 新增配载
export const odPreCargo = function (that, btnKey, isBatch = false) {
  preCargoFunc(that, btnKey, isBatch);
};
export const loading = function (that, btnKey) {
  const idIndex = that.getSelectesKey();
  if (!checkOp(idIndex)) {
    return;
  }
  direactNewPage(that, btnKey, {
    checkTips: '新增配载',
    controller: 'Truck',
    checkUrl: '/Truck/Truck/onLoading',
    paraAddBLinkId: true,
    isBatch: true,
  });
};
// 新增提货
export const pickup = async function (that, btnKey, isBatch = true) {
  // 以下 comId 取值逻辑 目的是 为了兼容指派运单
  // order_status == -10 即指派运单，此时comId 取pickup_tr_team_id
  let comId;
  if (isBatch) {
    comId = checkBatchComId(that);
  } else {
    comId = checkFilterCompanyId(that);
    if (comId === false) return false;
    // 顶部筛选没有筛选, 判断运单中是否有不同网点的运单
    // const ids = that.getSelectesKey('od_link_id')
    const data = that.getSelectesKey(['com_id', 'pickup_tr_team_id', 'order_status'], isFromPickClip(that));

    comId = remDub(
      data.map(item => {
        if (+item.order_status === -10) {
          return item.pickup_tr_team_id;
        }
        return item.com_id;
      }),
    );
    if (!checkComId(comId)) return false;
    comId = comId.length === 0 ? [window.company_id] : comId;
  }
  const { get } = await i18n(POPUP_CONFIRM_WINDOW);
  direactNewPage(that, btnKey, {
    checkTips: '新增提货',
    controller: 'Pickup',
    checkUrl: '/Truck/Pickup/onLoading',
    showErrWithOptips: true,
    opTipsPara: {
      orderType: get('order', '运单'),
      opType: '新增提货',
    },
    isBatch,
    comId,
  });
};
export const reservationPickup = function (that, btnKey, isBatch) {
  direactNewPage(that, btnKey, {
    checkTips: '新增提货',
    controller: 'Pickup',
    checkUrl: '/Reservation/Pickup/onLoading',
    isBatch: isBatch || (that.tableInfo || {}).category === 'Batch',
    tab: 'load-reservation',
  });
};
// 新增送货
export const delivery = function (that, btnKey, isBatch = true) {
  // 以下 comId 取值逻辑 目的是 为了兼容指派运单
  // order_status == -10 即指派运单，此时comId delivery_tr_team_id
  let comId;
  if (isBatch) {
    comId = checkBatchComId(that);
  } else {
    comId = checkFilterCompanyId(that);
    if (comId === false) return false;
    // 顶部筛选没有筛选, 判断运单中是否有不同网点的运单
    // const ids = that.getSelectesKey('od_link_id')
    const data = that.getSelectesKey(['com_id', 'delivery_tr_team_id', 'order_status'], isFromPickClip(that));

    comId = remDub(
      data.map(item => {
        if (+item.order_status === -10) {
          return item.delivery_tr_team_id;
        }
        return item.com_id;
      }),
    );
    if (!checkComId(comId)) return false;
    comId = comId.length === 0 ? [window.company_id] : comId;
  }
  direactNewPage(that, btnKey, {
    checkTips: '新增送货',
    controller: 'Delivery',
    checkUrl: '/Truck/Delivery/onLoading',
    isBatch,
    comId,
  });
};
// 新增短驳
export const shuttle = function (that, btnKey, isBatch = true) {
  direactNewPage(that, btnKey, {
    checkTips: '新增短驳',
    controller: 'Shuttle',
    checkUrl: '/Truck/Shuttle/onLoading',
    isBatch,
  });
};
// 运单送货
export const odPickup = function (that, btnKey) {
  pickup(that, btnKey, false);
};

// 运单短驳
export const odShuttle = function (that, btnKey) {
  shuttle(that, btnKey, false);
};
// 运单送货
export const odDelivery = function (that, btnKey) {
  delivery(that, btnKey, false);
};
const bindDevice = function (that, btnKey) {
  direactNewPage(that, btnKey, {
    checkTips: '绑定设备',
    controller: 'Order',
    tab: 'bindDevice',
    checkUrl: '/Order/Order/onBindDevice',
    isBatch: false,
    tabName: '绑定设备',
  });
};
// 运单装车 运单列表-新增配载
export const odTruck = function (that, btnKey) {
  cargo(that, btnKey, false);
};
// 卸车
export const unload = function (that, buttonKey) {
  const idIndex = that.getSelectesKey(['b_link_id', 'company_id', 'blk_next_id', 'next_company_id']);
  if (!checkOp(idIndex)) return;
  const pack = {
    b_link_id: idIndex[0].b_link_id,
    company_id: idIndex[0].company_id,
    op_type: 'b_list',
  };
  const { tab, category } = that.getReq();
  const BTNS = {
    unload: 1,
  };
  const B_LINK_ID_KEY = {
    Batch_tr_up: BTNS[buttonKey] && 'blk_next_id',
    Batch_tr_pre_loading: BTNS[buttonKey] && 'blk_next_id',
    Batch_tr_up_loading: BTNS[buttonKey] && 'blk_next_id',
    Batch_tr_up_depart: BTNS[buttonKey] && 'blk_next_id',
    Batch_tr_up_arrival: BTNS[buttonKey] && 'blk_next_id',
  };
  const COMPANY_ID_KEY = {
    Batch_tr_up: BTNS[buttonKey] && 'next_company_id',
    Batch_tr_pre_loading: BTNS[buttonKey] && 'next_company_id',
    Batch_tr_up_loading: BTNS[buttonKey] && 'next_company_id',
    Batch_tr_up_depart: BTNS[buttonKey] && 'next_company_id',
    Batch_tr_up_arrival: BTNS[buttonKey] && 'next_company_id',
  };
  const uniqueKey = `${category}_${tab}`;
  if (BTNS[buttonKey] && B_LINK_ID_KEY[uniqueKey]) {
    pack.tr_up_op = 1;
    pack.b_link_id = idIndex[0][B_LINK_ID_KEY[uniqueKey]];
    pack.company_id = idIndex[0][COMPANY_ID_KEY[uniqueKey]];
  }
  odUnloadFunc(pack, throttle(that.handleRefreshData, 1000), { that, buttonKey });
};
// 取消卸车
export const cancelUnload = function (that, btnKey) {
  const idIndex = that.getSelectesKey(['b_link_id', 'company_id']);
  if (!checkOp(idIndex)) return;
  cancelAcceptLoad(idIndex[0], '您确定整车取消卸车吗？', '/Truck/Truck/onCancelUnload', {}, that.handleRefreshData, {
    that,
    btnKey,
  });
};
// 取消装车--运单列表
export const odCancelLoad = async (that, btnKey) => {
  const idIndex = [];
  let odLinkIds;
  let comID;
  try {
    ({ od_link_id: odLinkIds, com_id: comID } = await preArrivalCheck(that, '取消装车', ['od_link_id', 'com_id']));
  } catch (error) {
    return;
  }
  odLinkIds.forEach((v, i) => idIndex.push({ od_link_id: odLinkIds[i], com_id: comID[i] }));
  if (!checkOp2(idIndex, '运单')) return;
  cancelLoad2({
    tips: '取消装车',
    opType: 'od_list',
    bLinkInfo: idIndex,
    url: '/Truck/Truck/onCancel',
    callback: that.handleRefreshData,
    tipsType: 'od_cancel_load',
    ext: { that, btnKey },
  });
};
// 取消预装车--运单列表
export const odCancelPreLoad = async (that, btnKey) => {
  // const idIndex = that.getSelectesKey(['od_link_id', 'com_id'])
  const idIndex = [];
  let odLinkIds;
  let comID;
  try {
    ({ od_link_id: odLinkIds, com_id: comID } = await preArrivalCheck(that, '取消预装车', ['od_link_id', 'com_id']));
  } catch (error) {
    return;
  }
  odLinkIds.forEach((v, i) => idIndex.push({ od_link_id: odLinkIds[i], com_id: comID[i] }));
  if (!checkOp2(idIndex, '运单')) return;
  cancelLoad2({
    tips: '取消预装车',
    opType: 'od_list',
    bLinkInfo: idIndex,
    url: '/Truck/TruckPre/onCancel',
    callback: that.handleRefreshData,
    tipsType: 'od_cancel_pre_load',
    ext: { that, btnKey },
  });
};
// 取消短驳--运单列表
export const odCancelShuttle = async (that, btnKey) => {
  // const idIndex = that.getSelectesKey(['od_link_id', 'com_id'])
  const idIndex = [];
  let odLinkIds;
  let comID;
  try {
    ({ od_link_id: odLinkIds, com_id: comID } = await preArrivalCheck(that, '取消短驳', ['od_link_id', 'com_id']));
  } catch (error) {
    return;
  }
  odLinkIds.forEach((v, i) => idIndex.push({ od_link_id: odLinkIds[i], com_id: comID[i] }));
  if (!checkOp2(idIndex, '运单')) return;
  cancelLoad2({
    tips: '取消短驳',
    opType: 'od_list',
    bLinkInfo: idIndex,
    url: '/Truck/Shuttle/onCancel',
    callback: that.handleRefreshData,
    tipsType: 'od_cancel_shuttle',
    ext: { that, btnKey },
  });
};
// 取消送货--运单列表
export const canceodCancelDeliverylLoad = async (that, btnKey) => {
  // const idIndex = that.getSelectesKey(['od_link_id', 'com_id'])
  const idIndex = [];
  let odLinkIds;
  let comID;
  try {
    ({ od_link_id: odLinkIds, com_id: comID } = await preArrivalCheck(that, '取消送货', ['od_link_id', 'com_id']));
  } catch (error) {
    return;
  }
  odLinkIds.forEach((v, i) => idIndex.push({ od_link_id: odLinkIds[i], com_id: comID[i] }));
  if (!checkOp2(idIndex, '运单')) return;
  cancelLoad2({
    tips: '取消送货',
    opType: 'od_list',
    bLinkInfo: idIndex,
    url: '/Truck/Delivery/onCancel',
    callback: that.handleRefreshData,
    tipsType: 'od_cancel_delivery',
    ext: { that, btnKey },
  });
};
// 取消提货--运单列表
export const odCancelPickup = async (that, btnKey) => {
  // const idIndex = that.getSelectesKey(['od_link_id', 'com_id'])
  const idIndex = [];
  let odLinkIds;
  let comID;
  try {
    ({ od_link_id: odLinkIds, com_id: comID } = await preArrivalCheck(that, '取消提货', ['od_link_id', 'com_id']));
  } catch (error) {
    return;
  }
  odLinkIds.forEach((v, i) => idIndex.push({ od_link_id: odLinkIds[i], com_id: comID[i] }));
  if (!checkOp2(idIndex, '运单')) return;
  cancelLoad2({
    tips: '取消提货',
    opType: 'od_list',
    bLinkInfo: idIndex,
    url: '/Truck/Pickup/onCancel',
    callback: that.handleRefreshData,
    tipsType: 'od_cancel_pickup',
    ext: { that, btnKey },
  });
};
export const cancelTr = function (that, btnKey) {
  const idIndex = that.getSelectesKey(['b_link_id', 'company_id']);
  if (!checkOp(idIndex)) return;
  cancelLoad(idIndex[0], 'calcelTruck', '/Truck/Truck/onCancel', {}, that.handleRefreshData, { that, btnKey });
};
export const cancelTrPre = function (that, btnKey) {
  const idIndex = that.getSelectesKey(['b_link_id', 'company_id']);
  if (!checkOp(idIndex)) return;
  cancelLoad(idIndex[0], 'calcelPreTruck', '/Truck/TruckPre/onCancel', {}, that.handleRefreshData, { that, btnKey });
};
// 取消送货
export const cancelDelivery = function (that, btnKey, rowIndex) {
  const rows = that.getStateSelecteRows();
  const idIndex = that.getSelectesKey(['b_link_id', 'company_id']);
  if (!checkOp(idIndex)) return;
  if (isAllTask(rows) === 'both') return;
  if (isAllTask(rows) === 'task') {
    return task_taskStateOp('delete')(that, btnKey, rowIndex);
  }
  if (!checkOp(idIndex)) return;
  cancelLoad(idIndex[0], 'cancelDelivery', '/Truck/Delivery/onCancel', {}, that.handleRefreshData, { that, btnKey });
};
// 取消提货
export const cancelPickup = function (that, btnKey, rowIndex) {
  const rows = that.getStateSelecteRows();
  const idIndex = that.getSelectesKey(['b_link_id', 'company_id']);
  if (!checkOp(idIndex)) return;
  if (isAllTask(rows) === 'both') return;
  if (isAllTask(rows) === 'task') {
    return task_taskStateOp('delete')(that, btnKey, rowIndex);
  }
  const req = that.getReq();
  let url = '/Truck/Pickup/onCancel';
  if (req.tab === 'pickup_reservation_batch') {
    // 订单提货 取消提货
    url = '/Reservation/Pickup/onCancel';
  }
  cancelLoad(idIndex[0], 'cancelPickup', url, {}, that.handleRefreshData, { that, btnKey });
};
// 取消短驳
export const cancelShuttle = function (that, btnKey, rowIndex) {
  const rows = that.getStateSelecteRows();
  const idIndex = that.getSelectesKey(['b_link_id', 'company_id']);
  if (!checkOp(idIndex)) return;
  if (isAllTask(rows) === 'both') return;
  if (isAllTask(rows) === 'task') {
    return task_taskStateOp('delete')(that, btnKey, rowIndex);
  }
  cancelLoad(idIndex[0], 'cancelShuttle', '/Truck/Shuttle/onCancel', {}, that.handleRefreshData, { that, btnKey });
};
export const preToLoading = function (that, btnKey) {
  const ids = that.getSelectesKey(['b_link_id']);
  if (!checkOp(ids)) return;
  const url = '/Truck/TruckPre/toLoading';
  const callback = that.handleRefreshData;
  const tipsType = 'truck';
  const pack = ids[0] || {};
  new PopUp(ShowInfo, {
    type: INFO,
    title: '提示',
    content: '您确定对选中的预装车批次操作装车吗？',
    simpleMode: {
      confirm: thisDialog => {
        that && btnKey && that.disableMenuButton && that.disableMenuButton(btnKey);
        gpostData(url, pack, res => {
          that && btnKey && that.enableMenuButton && that.enableMenuButton(btnKey);
          if (res.data !== undefined && res.data.errno === 0) {
            opTips({
              opType: tipsType,
              resData: res.data,
              sucCallback: () => {
                // successCallback
                typeof callback === 'function' && callback();
              },
              continueCallback: (sucId, cb) => {
                pack.isCheck = 0;
                gpostData(url, pack, () => {
                  typeof cb === 'function' && cb();
                  typeof callback === 'function' && callback();
                });
              },
            });
          } else {
            showPopTip(ERROR, res.data.errmsg || '取消失败');
          }
        });
        thisDialog.handleHide();
      },
      cancel: thisDialog => thisDialog.handleHide(),
    },
  }).show();
};
const deliveryBatchDispatch = dispatchType =>
  function (that, btnKey, isBatch = true) {
    const tabName = dispatchType === 'delivery' ? '送货批次派单' : '提货批次派单';
    const checkUrl =
      dispatchType === 'delivery' ? '/Truck/Delivery/batchDispatchInfo' : '/Truck/Pickup/batchDispatchInfo';
    direactNewPage(that, btnKey, {
      checkTips: tabName,
      controller: 'Schedule',
      checkUrl,
      isBatch,
      tab: 'batchLoad',
      tabName,
      reqPara: {
        op_type: 'b_dispatch',
        dispatchType,
      },
      hasBLinkId: true,
      showErrWithOptips: true,
      opTipsPara: {
        opType: tabName,
        orderType: '批次',
        successIdKey: 'b_link_ids',
      },
    });
  };
const transtaskDispatchDriver = type =>
  function (that, btnKey, isBatch = true) {
    const tabName = '指派司机';
    const checkUrl = '/Truck/TransportTask/batchDispatchInfo';
    direactNewPage(that, btnKey, {
      checkTips: tabName,
      controller: 'Schedule',
      checkUrl,
      isBatch,
      tab: 'dispatchDriver',
      tabName,
      reqPara: {
        type,
        op_type: 'b_dispatch',
        dispatchType: 'driver',
      },
      hasBLinkId: true,
      showErrWithOptips: true,
      opTipsPara: {
        opType: tabName,
        orderType: '批次',
        successIdKey: 'b_link_ids',
      },
    });
  };
const cancelDeliveryBatchDispatch = type => (that, btnKey) => {
  const batchKey = type === 'delivery' ? 'delivery_batch' : 'pickup_batch';
  const keyword = type === 'delivery' ? '送' : '提';
  const opType = type === 'delivery' ? 'cancelDeliveryBatchDispatch' : 'cancelPickupBatchDispatch';
  let data = that.getSelectesKey(['b_link_id', batchKey, 'b_tr_num', 'b_dr_name', 'b_dr_phone']);

  if (!data.length) {
    showInfo(ERROR, '请选择要操作的数据！');
    return;
  }
  const url = type === 'delivery' ? 'Truck/Delivery/cancelBatchDispatch' : 'Truck/Pickup/cancelBatchDispatch';
  const ext = {};
  const showInfoContent = '取消派单将清空送货司机，并从司机的任务列表中删除该批次。您确定要取消派单吗？';

  new PopUp(ShowInfo, {
    type: WARN,
    content: showInfoContent,
    title: '操作确认',
    simpleMode: {
      confirm: (thisDialog, btn) => {
        thisDialog.handleHide();
        that.disableMenuButton(btnKey);
        const handleBatch = d => {
          const tips = `取消${keyword}货派单`;
          const header = {
            [batchKey]: { title: `${keyword}货批次`, display: 'show', width: 110 },
            b_tr_num: { title: '车牌号', display: 'show' },
            b_dr_name: { title: '司机', display: 'show' },
            b_dr_phone: { title: '司机手机号', display: 'show' },
          };
          new PopUp(BatchOp, {
            title: '批量取消派单',
            tips,
            header,
            data: d,
            requestUrl: url,
            getPara: dataList => ({ b_link_ids: [dataList[0].b_link_id] }),
            finishCallback: () => {
              setTimeout(that.handleRefreshData, 500);
            },
            escBind: false,
          }).show();
          thisDialog.handleHide();
        };
        const postData = () => {
          const pack = {
            b_link_ids: data.map(item => item.b_link_id),
            is_check: 1,
          };
          gpostData(url, pack, res => {
            that.enableMenuButton(btnKey);
            opTips({
              opType,
              resData: res.data,
              orderType: '批次',
              sucCallback: () => {
                handleBatch(data);
              },
              showSucTips: false,
              continueCallback: (sucId, cb) => {
                cb();
                const idStr = sucId.join(',');
                data = data.filter(item => idStr.includes(item.b_link_id));
                handleBatch(data);
              },
            });
          });
        };
        postData();
      },
      cancel: thisDialog => thisDialog.handleHide(),
    },
  }).show();
};
const startDeliveryBatch = type => (that, btnKey) => {
  const batchKey = type === 'delivery' ? 'delivery_batch' : 'pickup_batch';
  const keyword = type === 'delivery' ? '送' : '提';
  const opType = type === 'delivery' ? 'startDeliveryBatch' : 'startPickupBatch';
  let data = that.getSelectesKey(['b_link_id', [batchKey], 'b_tr_num', 'b_dr_name', 'b_dr_phone']);

  if (!data.length) {
    showInfo(ERROR, '请选择要操作的数据！');
    return;
  }
  const url = type === 'delivery' ? 'Truck/Delivery/startDispatch' : 'truck/Pickup/startDispatch';
  const handleBatch = d => {
    const tips = `${keyword}货`;
    const header = {
      [batchKey]: { title: `${keyword}货批次`, display: 'show', width: 110 },
      b_tr_num: { title: '车牌号', display: 'show' },
      b_dr_name: { title: '司机', display: 'show' },
      b_dr_phone: { title: '司机手机号', display: 'show' },
    };
    new PopUp(BatchOp, {
      title: `批量开始${keyword}货`,
      tips,
      header,
      data: d,
      requestUrl: url,
      getPara: dataList => ({ b_link_ids: [dataList[0].b_link_id] }),
      finishCallback: () => {
        setTimeout(that.handleRefreshData, 500);
      },
      escBind: false,
    }).show();
  };
  const postData = () => {
    const pack = {
      b_link_ids: data.map(item => item.b_link_id),
      is_check: 1,
    };
    gpostData(url, pack, res => {
      that.enableMenuButton(btnKey);
      opTips({
        opType,
        resData: res.data,
        orderType: '批次',
        sucCallback: () => {
          handleBatch(data);
        },
        showSucTips: false,
        continueCallback: (sucId, cb) => {
          cb();
          const idStr = sucId.join(',');
          data = data.filter(item => idStr.includes(item.b_link_id));
          handleBatch(data);
        },
      });
    });
  };
  postData();
};

// 移库
const moveStock = async (that, btnKey) => {
  const data = that.getSelectesKey(['com_id', 'od_link_id']);
  if (!data.length) {
    showInfo(ERROR, '请选择要操作的运单！');
    return false;
  }

  const comIds = remDub(data.map(o => o.com_id));
  if (!checkComId(comIds)) return false;

  // 进入左右挑单页
  that.disableMenuButton(btnKey);
  const res = await fetchApi('/Truck/WhOperate/onLoading', {
    method: 'POST',
    body: {
      req: {
        od_link_ids: data.map(o => o.od_link_id),
        op_type: '移库',
        company_id: window.company_id,
      },
    },
  });

  that.enableMenuButton(btnKey);
  const open = (sucIds, cb) => {
    cb && cb();
    const urlData = {
      controller: 'Truck',
      action: 'moveload',
      query: {
        fromPickClip: isFromPickClip(that),
        tabName: '运单移库',
      },
    };

    const directInfo = { pickOrderInfo: res.res, sendReq: false, comId: window.company_id };
    actions.api.direct(urlData, 'directInfo', directInfo);
  };

  opTips({
    resData: res,
    sucCallback: open,
    showSucTips: false,
    opType: 'moveStock',
  });
};
export const cancelMoveStock = (that, btnKey) => {
  const data = that.getSelectesKey(['od_link_id']);
  if (!data.length) {
    showInfo(ERROR, '请选择要操作的运单！');
    return false;
  }
  const cancelOp = async () => {
    that.disableMenuButton(btnKey);
    const res = await fetchApi('/Truck/WhOperate/onCancelDirectInWh', {
      method: 'POST',
      body: {
        req: { od_link_ids: data.map(o => o.od_link_id) },
      },
    });
    that.enableMenuButton(btnKey);

    opTips({
      opType: 'cancelMoveStock',
      resData: res,
      orderType: '移库',
      sucCallback: () => {
        showPopTip(CHECK, '取消移库成功', () => {
          setTimeout(() => {
            that.handleRefreshData();
          }, 2000);
        });
      },
      showSucTips: false,
      continueCallback: (sucId, cb) => {
        cb();
        cancelOp();
      },
    });
  };
  showInfo(WARN, '请您确认对所选运单执行取消移库操作', true, { confirm: () => cancelOp() }, true, '操作确认');
};

export function init(emt) {
  // 新增配载
  emt.on('cargo', cargo);
  // 预装车
  emt.on('preLoading', preCargo);
  // 运单预装车
  emt.on('odPreLoading', odPreCargo);
  // 新增送货
  emt.on('delivery', delivery);
  // 新增短驳
  emt.on('shuttle', shuttle);
  // 新增提货
  emt.on('pickup', pickup);
  // 运单短驳
  emt.on('odShuttle', odShuttle);
  // 运单送货
  emt.on('odDelivery', odDelivery);
  // 运单提货
  emt.on('odPickup', odPickup);
  // 运单提货配载（极速版）
  emt.on('odPickupTask', (that, btnKey) =>
    batchTaskAdd({ that, loadType: 'pickup', title: '提货配载', controller: 'Pickup', btnKey }),
  );
  // 运单干线配载 (极速版)
  emt.on('loadOrderTask', (that, btnKey) =>
    batchTaskAdd({ that, loadType: 'truck', title: '干线配载', controller: 'Truck', btnKey }),
  );
  // 运单送货配载 (极速版)
  emt.on('odDeliveryTask', (that, btnKey) =>
    batchTaskAdd({ that, loadType: 'delivery', title: '送货配载', controller: 'Delivery', btnKey }),
  );
  // 订单提货
  emt.on('reservationPickup', reservationPickup);
  // 运单装车
  emt.on('loadOrder', odTruck);
  // 到站装车
  emt.on('loading', loading);
  // 取消短驳
  emt.on('cancelShuttle', cancelShuttle);
  // 卸车
  emt.on('unload', unload);
  // 取消卸车
  emt.on('cancelUnload', cancelUnload);
  // 运单取消装车
  emt.on('odCancelLoad', odCancelLoad);
  // 运单取消预装车
  emt.on('odCancelPreLoad', odCancelPreLoad);
  // 运单取消短驳
  emt.on('odCancelShuttle', odCancelShuttle);
  // 运单取消送货
  emt.on('odCancelDelivery', canceodCancelDeliverylLoad);
  // 运单取消提货
  emt.on('odCancelPickup', odCancelPickup);
  // 运单取消提货
  emt.on('preToLoading', preToLoading);
  // emt.on('deliveryBatchDispatch', deliveryBatchDispatch('delivery'))
  // emt.on('cancelDeliveryBatchDispatch', cancelDeliveryBatchDispatch('delivery'))
  emt.on('startDeliveryBatch', startDeliveryBatch('delivery'));
  // emt.on('pickupBatchDispatch', deliveryBatchDispatch('pickup'))
  // emt.on('cancelPickupBatchDispatch', cancelDeliveryBatchDispatch('pickup'))
  emt.on('startPickupBatch', startDeliveryBatch('pickup'));
  // 绑定设备
  emt.on('bindDevice', bindDevice);

  // 新增打包
  emt.on('addPack', addPack);
  // 修改打包
  // emt.on('editPack', editPack)
  // 移库
  emt.on('ykInWh', moveStock);
  emt.on('cancelYkInWh', cancelMoveStock);

  // emt.on('transtaskDispatchDriver', transtaskDispatchDriver('add'))
  // emt.on('transtaskModifyDispatchDriver', transtaskDispatchDriver('modify'))
}
