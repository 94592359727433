exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".order-editor-send-msg-modal .fn-checkbox input{top:10px}.order-editor-send-msg-modal .msg-table{border:0}.order-editor-send-msg-modal .msg-table .td1{width:100px}.order-editor-send-msg-modal .msg-table .td2{width:90px}.order-editor-send-msg-modal .msg-table tbody{width:510px;max-height:60vh;display:inline-block}", ""]);

// exports
exports.locals = {
	"order-editor-send-msg-modal": "order-editor-send-msg-modal",
	"fn-checkbox": "fn-checkbox",
	"msg-table": "msg-table",
	"td1": "td1",
	"td2": "td2"
};