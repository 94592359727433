/**
 * Created by wangnaihe on 2017/6/19.
 */
import React from 'react';
import { Select, DataList, DateTimePicker, PureInput as Input, PureCheckbox as Checkbox, PopUp } from 'components';
import { NUMBER, FLOAT, FLOAT_P_6, FLOAT_3, FLOAT_6 } from 'constants';
import { buildTipMixin, ot } from 'utils';
import UserSlider from 'pages/CompanyController/companyGroup/components/SiderDrager';
import { mgrHeader, paidTip, returnedTip, noticePickName } from '../constant';
import { required, lockShow, validateReceiptN } from '../tool';
import { getMgr } from './tool';
import { prefixCls } from './index.scss';

const normalHeader = ['value'];

const noticeDeliveryTip = buildTipMixin('等通知放货');

const pickupTip = buildTipMixin('上门提货');

const needDispatchTip = buildTipMixin('需要配送安装');

const hxCodeTip = buildTipMixin('签收需要核销码');

const insuredTip = buildTipMixin('给运单投保');

function renderExtra(page) {
  let addMgrSlider;
  const addMgr = name => {
    addMgrSlider && addMgrSlider.handleHide();
    new PopUp(UserSlider, {
      popName: 'addMgr',
      type: 'User',
      opType: 'add',
      inforeq: { User_id: '0', type: 'User', readonly: false, name, src: 'co' },
      opreq: { User_id: '', type: 'User' },
      title: '添加经办人',
      currId: window.company_id,
      ref: r => (addMgrSlider = r),
      onConfirm: (res, req) => {
        const user = req.org_info;
        const mgrIdEnum = page.store.getState().mgrIdEnum || [];
        const mgrId = { id: res.res[1], name: user.name, telephone: user.telephone };
        page.setSug('mgrId', [...mgrIdEnum, mgrId]);
        page.set('mgrId', mgrId);
      },
    }).show();
  };

  const mgrBlur = (e, showAddMgr) => {
    if (!showAddMgr) return;
    const { dataset } = e.target;
    if (dataset.userInput && !dataset.isSelect) addMgr(dataset.userInput);
  };

  const sugMgr = (keyword, select) =>
    getMgr(keyword, select.fetch, page.store.getState()).then(res => page.setSug('mgrId', res.res));

  const onSelectMultiDataList = (key, value) => page.set(key, DataList.formatMultipleValue(value, 'value'));

  return function () {
    const { store } = page;
    const state = store.getState();
    const { valueAddedServiceCardShow, weightUnit } = state;
    const { mgrId, mgrIdShow, mgrIdRequired, mgrIdEditable, mgrIdEnum, showAddMgr } = state;
    const { rebate, rebateShow, rebateRequired, rebateEditable, cashOwnBrokerBottom } = state;
    const { rebatePaid, rebatePaidShow, rebatePaidEditable } = state;
    const { cashreturn, cashreturnShow, cashreturnRequired, cashreturnEditable } = state;
    const { cashreturnPaid, cashreturnPaidShow, cashreturnPaidEditable } = state;
    const { discount, discountShow, discountRequired, discountEditable } = state;
    const {
      deliveryMode,
      deliveryModeBottom,
      deliveryModeRequired,
      deliveryModeEditable,
      deliveryModeEnum,
      deliveryShortcuts,
    } = state;
    const { noticeDelivery, noticeDeliveryEditable, noticeDeliveryShow } = state;
    const { pickup, pickupEditable, pickupShow, noticePickShow, noticePickShowMode } = state;
    const { needDispatch, needDispatchShow, needDispatchEditable } = state;
    const { hxCode, hxCodeShow, hxCodeEditable } = state;
    const { insured, insuredShow, insuredEditable, isLongKun } = state;
    const { urgency, urgencyShow, urgencyRequired, urgencyEditable, urgencyEnum } = state;
    const { receiptCat, receiptCatShow, receiptCatRequired, receiptCatEditable, receiptCatEnum } = state;
    const { receiptN, receiptNRequired, receiptNEditable } = state;
    const { receiptNum, receiptNumShow, receiptNumRequired, receiptNumEditable } = state;
    const { expectedReleaseTime, expectedReleaseTimeShow, expectedReleaseTimeRequired, expectedReleaseTimeEditable } =
      state;
    const {
      receiptRequire,
      receiptRequireShow,
      receiptRequireLock,
      receiptRequireRequired,
      receiptRequireEditable,
      receiptRequireEnum,
    } = state;
    const { trspMode, trspModeShow, trspModeRequired, trspModeEditable, trspModeEnum } = state;
    const { odCurrency0001, odCurrency0001Show, odCurrency0001Required, odCurrency0001Editable } = state;
    const { odCurrency0002, odCurrency0002Show, odCurrency0002Required, odCurrency0002Editable } = state;
    const { odCurrency0003, odCurrency0003Show, odCurrency0003Required, odCurrency0003Editable } = state;
    const { odCurrency0004, odCurrency0004Show, odCurrency0004Required, odCurrency0004Editable } = state;

    const { shortfallWeight, shortfallWeightShow, shortfallWeightRequired } = state;
    const { shortfallVolume, shortfallVolumeShow, shortfallVolumeRequired } = state;

    const { remark, remarkShow, remarkLock, remarkRequired, remarkEditable, remarkEnum } = state;
    const { innerRemark, innerRemarkShow, innerRemarkRequired, innerRemarkEditable } = state;
    const { set } = page;

    const items = [];

    let afterIndex;
    let remarkIndex;

    mgrIdShow &&
      items.push(
        <div key="mgrId">
          <label className={`fn-label${required(mgrIdRequired)}`}>{ot('经办人')}</label>
          <Select
            data-path="mgr_id"
            value={mgrId}
            required={mgrIdRequired}
            compare="id"
            data={mgrIdEnum}
            disabled={!mgrIdEditable}
            header={mgrHeader}
            map={false}
            showIcon={!showAddMgr}
            filter={sugMgr}
            onChange={val => set('mgrId', val)}
            onBlur={val => mgrBlur(val, showAddMgr)}
          >
            {showAddMgr && <i className="fn-icon fn-icon-add-rad input-icon" onClick={() => addMgr()} />}
          </Select>
        </div>,
      );

    cashOwnBrokerBottom &&
      (rebateShow || rebatePaidShow) &&
      items.push(
        <div key="rebate">
          <label className={`fn-label${required(rebateRequired)}`}>{ot('回扣')}</label>
          <Input
            data-path="rebate"
            value={rebate}
            required={rebateRequired}
            disabled={!rebateEditable}
            pattern={FLOAT}
            onChange={e => set('rebate', e.target.value)}
          >
            {rebatePaidShow && (
              <Checkbox
                data-path="rebate_paid"
                checked={rebatePaid}
                disabled={!rebatePaidEditable}
                onChange={e => set('rebatePaid', e.target.checked)}
                {...paidTip}
              />
            )}
          </Input>
        </div>,
      );

    cashOwnBrokerBottom &&
      (cashreturnShow || cashreturnPaidShow) &&
      items.push(
        <div key="cashreturn">
          <label className={`fn-label${required(cashreturnRequired)}`}>{ot('现返')}</label>
          <Input
            data-path="cashreturn"
            value={cashreturn}
            required={cashreturnRequired}
            disabled={!cashreturnEditable}
            pattern={FLOAT}
            onChange={e => set('cashreturn', e.target.value)}
          >
            {cashreturnPaidShow && (
              <Checkbox
                data-path="cashreturn_paid"
                checked={cashreturnPaid}
                disabled={!cashreturnPaidEditable}
                onChange={e => set('cashreturnPaid', e.target.checked)}
                {...returnedTip}
              />
            )}
          </Input>
        </div>,
      );

    discountShow &&
      cashOwnBrokerBottom &&
      items.push(
        <div key="discount">
          <label className={`fn-label${required(discountRequired)}`}>{ot('欠返')}</label>
          <Input
            data-path="discount"
            value={discount}
            required={discountRequired}
            disabled={!discountEditable}
            pattern={FLOAT}
            onChange={e => set('discount', e.target.value)}
          />
        </div>,
      );

    deliveryModeBottom &&
      items.push(
        <div key="deliveryMode">
          <label className={`fn-label${required(deliveryModeRequired)}`}>{ot('送货方式')}</label>
          <Select
            data-path="delivery_mode"
            value={deliveryMode}
            required={deliveryModeRequired}
            data={deliveryModeEnum}
            disabled={!deliveryModeEditable}
            filter="value"
            onChange={val => set('deliveryMode', val)}
            shortcuts={deliveryShortcuts}
          />
        </div>,
      );
    noticePickShow &&
      items.push(
        <div key="noticeDelivery">
          {noticeDeliveryShow && (
            <label className="fn-checkbox-label" {...noticeDeliveryTip}>
              <Checkbox
                data-path="notice_delivery"
                checked={noticeDelivery}
                disabled={!noticeDeliveryEditable}
                onChange={e => set('noticeDelivery', e.target.checked)}
              />
              {noticePickName.notice_delivery[noticePickShowMode]}
            </label>
          )}
          {pickupShow && (
            <label className="fn-checkbox-label" {...pickupTip}>
              <Checkbox
                data-path="pickup"
                checked={pickup}
                disabled={!pickupEditable}
                onChange={e => set('pickup', e.target.checked)}
              />
              {noticePickName.pickup[noticePickShowMode]}
            </label>
          )}
          {needDispatchShow && (
            <label className="fn-checkbox-label" {...needDispatchTip}>
              <Checkbox
                data-path="need_dispatch"
                checked={+needDispatch === 1}
                disabled={!needDispatchEditable}
                onChange={e => set('needDispatch', e.target.checked ? 1 : 2)}
              />
              {noticePickName.need_dispatch[noticePickShowMode]}
            </label>
          )}
          {hxCodeShow && (
            <label className="fn-checkbox-label" {...hxCodeTip}>
              <Checkbox
                data-path="hx_code"
                checked={+hxCode === 1}
                disabled={!hxCodeEditable}
                onChange={e => set('hxCode', e.target.checked ? 1 : 0)}
              />
              {noticePickName.hx_code[noticePickShowMode]}
            </label>
          )}
          {insuredShow && !isLongKun && (
            <label className="fn-checkbox-label" {...insuredTip}>
              <Checkbox
                data-path="insured"
                checked={insured}
                disaabled={!insuredEditable}
                onChange={e => set('insured', e.target.checked ? 1 : 0)}
              />
              {noticePickName.insured[noticePickShowMode]}
            </label>
          )}
        </div>,
      );

    !valueAddedServiceCardShow &&
      urgencyShow &&
      items.push(
        <div key="urgency">
          <label className={`fn-label${required(urgencyRequired)}`}>{ot('紧急度')}</label>
          <Select
            data-path="urgency"
            value={urgency}
            required={urgencyRequired}
            data={urgencyEnum}
            disabled={!urgencyEditable}
            onChange={val => set('urgency', val)}
          />
        </div>,
      );

    !valueAddedServiceCardShow &&
      receiptCatShow &&
      items.push(
        <div key="receiptN">
          <Select
            data-path="receipt_cat"
            required={receiptCatRequired}
            value={receiptCat}
            data={receiptCatEnum}
            disabled={!receiptCatEditable}
            header={normalHeader}
            format={lockShow}
            filter="value"
            placeholder="回单类型"
            onChange={val => set('receiptCat', val)}
          />
          <Input
            data-path="receipt_n"
            required={receiptNRequired}
            pattern={NUMBER}
            customValidity={receiptNRequired ? validateReceiptN : undefined}
            value={receiptN}
            disabled={!receiptNEditable}
            onChange={e => set('receiptN', e.target.value)}
          />
          份
        </div>,
      );

    !valueAddedServiceCardShow &&
      receiptNumShow &&
      items.push(
        <div key="receiptNum" className="receipt-num">
          <label className={`fn-label${required(receiptNumRequired)}`}>{ot('回单号')}</label>
          <Input
            data-path="receipt_num"
            value={receiptNum}
            required={receiptNumRequired}
            disabled={!receiptNumEditable}
            onChange={e => set('receiptNum', e.target.value)}
          />
        </div>,
      );

    !valueAddedServiceCardShow &&
      expectedReleaseTimeShow &&
      items.push(
        <div key="expectedReleaseTime">
          <label className={`fn-label${required(expectedReleaseTimeRequired)}`}>{ot('预计发放时间')}</label>
          <DateTimePicker
            defaultValue={expectedReleaseTime}
            disabled={!expectedReleaseTimeEditable}
            onChange={val => set('expectedReleaseTime', val)}
          />
        </div>,
      );

    !valueAddedServiceCardShow &&
      receiptRequireShow &&
      items.push(
        <div key="receiptRequire">
          <label className={`fn-label${required(receiptRequireRequired)}`}>{ot('回单要求')}</label>
          {receiptRequireLock && (
            <Select
              data-path="receipt_require"
              multiple
              value={receiptRequire}
              required={receiptRequireRequired}
              data={receiptRequireEnum}
              disabled={!receiptRequireEditable}
              header={normalHeader}
              format={lockShow}
              filter="value"
              onChange={val => set('receiptRequire', val)}
            />
          )}
          {!receiptRequireLock && (
            <DataList
              data-path="receipt_require"
              multiple
              value={receiptRequire}
              required={receiptRequireRequired}
              data={receiptRequireEnum}
              disabled={!receiptRequireEditable}
              header={normalHeader}
              filter={false}
              format="value"
              onChange={val => set('receiptRequire', val)}
              onSelect={data => onSelectMultiDataList('receiptRequire', data)}
            />
          )}
        </div>,
      );

    trspModeShow &&
      items.push(
        <div key="trspMode">
          <label className={`fn-label${required(trspModeRequired)}`}>{ot('运输方式')}</label>
          <Select
            data-path="trsp_mode"
            value={trspMode}
            required={trspModeRequired}
            data={trspModeEnum}
            header={normalHeader}
            format={lockShow}
            filter="value"
            disabled={!trspModeEditable}
            onChange={val => set('trspMode', val)}
          />
        </div>,
      );

    odCurrency0001Show &&
      items.push(
        <div key="odCurrency0001">
          <label className={`fn-label${required(odCurrency0001Required)}`}>{ot('自定义数字1')}</label>
          <Input
            value={odCurrency0001}
            required={odCurrency0001Required}
            data-path="od_currency_0001"
            pattern={FLOAT_P_6}
            disabled={!odCurrency0001Editable}
            onChange={e => set('odCurrency0001', e.target.value)}
          />
        </div>,
      );

    odCurrency0002Show &&
      items.push(
        <div key="odCurrency0002">
          <label className={`fn-label${required(odCurrency0002Required)}`}>{ot('自定义数字2')}</label>
          <Input
            value={odCurrency0002}
            required={odCurrency0002Required}
            data-path="od_currency_0002"
            pattern={FLOAT_P_6}
            disabled={!odCurrency0002Editable}
            onChange={e => set('odCurrency0002', e.target.value)}
          />
        </div>,
      );
    odCurrency0003Show &&
      items.push(
        <div key="odCurrency0003">
          <label className={`fn-label${required(odCurrency0003Required)}`}>{ot('自定义数字3')}</label>
          <Input
            value={odCurrency0003}
            required={odCurrency0003Required}
            data-path="od_currency_0003"
            pattern={FLOAT_P_6}
            disabled={!odCurrency0003Editable}
            onChange={e => set('odCurrency0003', e.target.value)}
          />
        </div>,
      );
    odCurrency0004Show &&
      items.push(
        <div key="odCurrency0004">
          <label className={`fn-label${required(odCurrency0004Required)}`}>{ot('自定义数字4')}</label>
          <Input
            value={odCurrency0004}
            required={odCurrency0004Required}
            data-path="od_currency_0004"
            pattern={FLOAT_P_6}
            disabled={!odCurrency0004Editable}
            onChange={e => set('odCurrency0004', e.target.value)}
          />
        </div>,
      );

    shortfallWeightShow &&
      items.push(
        <div key="shortfallWeight">
          <label className={`fn-label${required(shortfallWeightRequired)}`}>
            {ot('亏吨重量')}({weightUnit})
          </label>
          <Input
            value={shortfallWeight}
            required={shortfallWeightRequired}
            disabled
            data-path="shortfall_weight"
            pattern={weightUnit === 'kg' ? FLOAT_3 : FLOAT_6}
            onChange={e => set('shortfallWeight', e.target.value)}
          />
        </div>,
      );

    shortfallVolumeShow &&
      items.push(
        <div key="shortfallVolume">
          <label className={`fn-label${required(shortfallVolumeRequired)}`}>{ot('亏方体积')}(方)</label>
          <Input
            value={shortfallVolume}
            required={shortfallVolumeRequired}
            disabled
            data-path="shortfall_volume"
            pattern={FLOAT_P_6}
            onChange={e => set('shortfallVolume', e.target.value)}
          />
        </div>,
      );

    remarkShow &&
      items.push(
        <div key="remark" className="x2 order-remark">
          <label className={`fn-label${required(remarkRequired)}`}>{ot('运单备注', '备注')}</label>
          {!remarkLock && (
            <DataList
              data-path="remark"
              maxLength="1000"
              multiple
              showTitleTips
              value={remark}
              required={remarkRequired}
              data={remarkEnum}
              disabled={!remarkEditable}
              header={normalHeader}
              filter={false}
              format="value"
              onChange={val => set('remark', val)}
              onSelect={data => onSelectMultiDataList('remark', data)}
            />
          )}
          {remarkLock && (
            <Select
              data-path="remark"
              multiple
              showInputTitleTips
              collapseAll={false}
              value={remark}
              required={remarkRequired}
              data={remarkEnum}
              disabled={!remarkEditable}
              header={normalHeader}
              format={lockShow}
              filter="value"
              onChange={val => set('remark', val)}
            />
          )}
        </div>,
        true,
      );

    innerRemarkShow &&
      items.push(
        <div key="inner_remark" className="x2 order-innerRemark">
          <label className={`fn-label${required(remarkRequired)}`}>{ot('内部备注')}</label>
          <Input
            data-path="inner_remark"
            showTitleTips
            value={innerRemark}
            maxLength={500}
            required={innerRemarkRequired}
            disabled={!innerRemarkEditable}
            onChange={e => set('innerRemark', e.target.value)}
          />
        </div>,
        true,
      );

    items.push(
      <div key="b1" />,
      <div key="b2" />,
      <div key="b3" />,
      <div key="b4" />,
      <div key="b5" />,
      <div key="b6" />,
      <div key="b7" />,
      <div key="b8" />,
      <div key="b9" />,
    );

    return (
      <div className={prefixCls}>
        {items.map((item, index) => {
          // 此处为了hack布局
          if (item === true && index > 3) {
            remarkIndex = index;
            afterIndex = 2;
            return null;
          }
          if (remarkIndex && item) afterIndex++;
          if (remarkIndex > 4 && remarkIndex === afterIndex) {
            if (index + 10 > items.length) return <div key={index} className="x2" />;
            const next = items[index + 1];
            afterIndex = 2;
            items[index + 1] = null;
            return (
              <div key={index} className="x2 combine">
                {item}
                {next}
              </div>
            );
          }
          return item;
        })}
      </div>
    );
  };
}

export default renderExtra;
