import React from 'react';
import { Icon, PopUp, Tooltip } from 'components';
import { fetchJAVA, showInfo } from 'utils';
import { ERROR } from 'constants';
// eslint-disable-next-line import/no-cycle
import HistoryModal from './history';
import { findMatchingAttachments } from 'pages/ScheduleController/task/components/taskEditor/line/utils.js';

export function formatSugData(data) {
  return data.map(item => ({
    ...item,
    addressShow: item.address && item.address.show_val,
    is_month: +item.is_month === 1 ? '是' : '否',
  }));
}

export const sugCorCee = (isCor, type, keyword, state, fetch, fetchURL) => {
  const coCorSug = {};
  const coCeeSug = {};
  state.arrInfoShow && (coCorSug.arr = state.arrInfo ? state.arrInfo.show_val : '');
  state.memberCodeShow && (coCorSug.member_code = state.memberCode ? state.memberCode.memberCode : '');
  state.corCustomerNoShow && (coCorSug.cor_customer_no = state.corCustomerNo || '');
  state.corIndustryShow && (coCorSug.cor_industry = state.corIndustry || '');
  state.corComShow && (coCorSug.cor_com = state.corCom || '');
  state.corNameShow && (coCorSug.cor_name = state.corName || '');
  state.corMobileShow && (coCorSug.cor_mobile = state.corMobile || '');
  state.corPhoneShow && (coCorSug.cor_phone = state.corPhone);
  state.corIdNumShow && (coCorSug.cor_id_num = state.corIdNum);

  state.arrInfoShow && (coCeeSug.arr = state.arrInfo ? state.arrInfo.show_val : '');
  state.ceeCustomerNoShow && (coCeeSug.cee_customer_no = state.ceeCustomerNo || '');
  state.ceeIndustryShow && (coCeeSug.cee_industry = state.ceeIndustry || '');
  state.ceeComShow && (coCeeSug.cee_com = state.ceeCom || '');
  state.ceeNameShow && (coCeeSug.cee_name = state.ceeName || '');
  state.ceeMobileShow && (coCeeSug.cee_mobile = state.ceeMobile || '');
  state.ceePhoneShow && (coCeeSug.cee_phone = state.ceePhone);
  state.ceeIdNumShow && (coCeeSug.cee_id_num = state.ceeIdNum);
  isCor ? (coCorSug[type] = keyword) : (coCeeSug[type] = keyword);
  const url = fetchURL ?? (isCor ? '/Basic/Nsug/corSug/' : '/Basic/Nsug/ceeSug/');
  const req = {
    group_id: window.group_id,
    company_id: state.cid,
    user_id: window.user_id,
    department_id: window.user_info.department_id,
    property: window.company_info.property,
    // #co_cor_sug代表托运人；co_cee_sug代表收货人
    type: isCor ? 'co_cor_sug' : 'co_cee_sug',
    co_cor_sug: coCorSug,
    co_cee_sug: coCeeSug,
    // #当前正在检索的词，为空则检索order表，有值则检索customer表
    search: keyword,
    // #当前正在检索的字段，现在没有用处，只是标识一下
    field: type,
    cor_relate_f: state.setting.cor_relate_f.cor_relate_f_val,
    cee_sug_relate: state.setting.cee_sug_relate.relate_val,
  };
  const conf = { method: 'POST', body: { req } };
  return fetch(url, conf);
};

export const destination = (state, fetch = fetchJAVA) => {
  const { poi } = state;
  const url = '/cmm-basic/gisZone/match/point/destination';
  // const req = {
  //   poi,
  // }
  const conf = { method: 'GET', body: { poi } };
  return fetch(url, conf);
};

export const showHistory = (type, store) => {
  const outerState = store.getState();
  const typeTxt = type === 'cor_name' ? '发' : '收';
  const name = type === 'cor_name' ? outerState.corName : outerState.ceeName;
  if (!name) {
    showInfo(ERROR, `请选择${typeTxt}货人!`);
    return false;
  }
  new PopUp(HistoryModal, { type, store, cid: outerState.cid, popName: `${type}_history` }).show();
};

export const renderMsgIcon = (oriData, param) => {
  const { key } = param;
  const orderData = oriData.order_data || {};
  const bodyData = orderData[key] || [];
  const msgNum = bodyData.length;
  if (!msgNum) return;
  const tipsContent = (
    <table className="msg-icon-table">
      <thead>
        <tr>
          <td className="th th1">短信模板名称</td>
          <td className="th th2">短信内容</td>
          <td className="th th3">手机号码</td>
        </tr>
      </thead>
      <tbody>
        {bodyData.map((item, index) => (
          <tr className={`tb-tr-${index}`}>
            <td className="tb tb1">{item.tpl_name}</td>
            <td className="tb tb2">{item.msg}</td>
            <td className="tb tb3">{item.telephone}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
  return (
    <Tooltip className="msg-icon-tips" trigger="click" background="#fff" border="#fff !important" content={tipsContent}>
      <Icon iconType="icon-message" badgeClass="badge-icon" isBadge badgeCount={msgNum} />
    </Tooltip>
  );
};

// 设置收货人/发货人附件
export const setAttachments = ({ page, type = 'consignee' }) => {
  const { store } = page;
  const state = store.getState();
  const { goods = [] } = state;

  const setKey = {
    // 收货人
    consignee: {
      attachmentKey: 'ceeAttachment',
      allAttachmentKey: 'ceeAllAttachment',
      disAbledAttachmentKey: 'matchedCeeAttachment',
    },
    // 发货人
    consignor: {
      attachmentKey: 'corAttachment',
      allAttachmentKey: 'corAllAttachment',
      disAbledAttachmentKey: 'matchedCorAttachment',
    },
  };
  const { attachmentKey, allAttachmentKey, disAbledAttachmentKey } = setKey[type];
  const { goods_claims = [] } = state[allAttachmentKey] || {};
  // 没有配置收货人/发货人附件，则不设置附件相关
  if (!state[`${attachmentKey}Show`]) return;
  // 订单只管联系人的常发货物去匹配
  const { isMatchedGoodClaims, matchingAttachments } = findMatchingAttachments(goods || [], goods_claims || [], []);
  page.set(attachmentKey, matchingAttachments);
  page.set(disAbledAttachmentKey, isMatchedGoodClaims);
};
