import React from 'react';
import {
  isEmptyObj,
  inArray,
  createTip,
  checkCookie,
  getLogId,
  showInfo,
  INFO,
  getComVal,
  getLabelProps,
  getGId,
  fetchApi,
  downloadOSSFile,
  confirm,
} from 'utils';
import { ERROR, WARN, CHECK } from 'constants';
import { PopUp, ShowInfo } from 'components';
import _ from 'lodash';
import './export.scss';
import { errmsgTip } from './newTips';
import dynamicImportExportJsonExcel from './AsyncExportJsonExcel';
import { exportSafeManagerNotice } from './ExportJsonExcel';
/* ###################### 所有的导出数据的处理 全部走此处 ############################### */
export const DATATYPE = {
  long: 'Number',
  integer: 'Number',
  short: 'Number',
  double: 'Number',
  float: 'Number',
  currency: 'Number',
  keyword: 'Text',
  text: 'Text',
  date: 'DateText',
  date_ymd: 'DateYmdText',
  date_ym: 'DateYmText',
};

export function returnWorkbookOption(ext, sheets) {
  const option = {};
  const datas =
    sheets.map?.(sheet => {
      const { dataList, title, batchInfoLen, columnWidths, datasTypeFront, ...other } = sheet;
      const sheetOption = {
        sheetData: dataList,
        sheetName: title ?? ext.pageTitle,
        batchInfoLen,
        columnWidths,
        excelDataType: datasTypeFront,
        // sheetFilter: ['two', 'one'],
        // sheetHeader: ext.pageTitle
      };
      return Object.assign(sheetOption, other);
    }) ?? [];
  option.datas = datas;
  option.fileName = ext.pageTitle;
  return option;
}

/*
 * 格式化cardForm数据-使之扁平化
 * 参数-cardForm
 * 参数-需要提取的变量数组
 */
export function formatCardForm(cardForm, pickArray) {
  const strArray = [];
  pickArray.forEach(item => {
    if (!cardForm[item]) return;
    const label = getLabelProps(cardForm[item]);
    // let val = cardForm[item].otherProps.defaultValue
    let val = getComVal(cardForm[item]);
    if (cardForm[item].type === 'Ccombiner') {
      const data = cardForm[item].otherProps.data || {};
      let combVal = '';
      Object.keys(data).forEach(key => {
        if (!pickArray.includes(key)) return;
        let itemVal = getComVal(data[key]) || '';
        if (data[key].type === 'SelectDrop') {
          const itemOtherProps = data[key].otherProps || {};
          const seleVal = itemOtherProps.defaultSelected; // 默认selected的值
          itemVal =
            typeof seleVal === 'object'
              ? (seleVal[0] && seleVal[0][itemOtherProps.showKey || 'name']) || ''
              : (itemOtherProps.data.filter(x => x.key === seleVal)[0] || {}).name || seleVal;
        }
        combVal = `${combVal} ${itemVal}`;
      });
      val = combVal;
    }
    let otherProps = {};
    let selected = '';
    if (cardForm[item].type === 'SelectDrop') {
      otherProps = { ...cardForm[item].otherProps };
      selected = cardForm[item].otherProps.defaultSelected; // 默认selected的值
      val = typeof selected === 'object' ? (selected[0] && selected[0][otherProps.showKey]) || '' : selected;
      val = val || ''; // 防止 undefined 出现
    }
    if (cardForm[item].type === 'Route') {
      selected = cardForm[item].otherProps.value; // 默认selected的值
      if (typeof selected === 'object') {
        selected.forEach((item2, index) => {
          index === 0 && (val = item2.company_name);
          index !== 0 && (val += `->${item2.company_name}`);
        });
      } else {
        val = selected;
      }
    }
    strArray.push(label ? `${label}: ${val}` : `${val}`);
  });
  return strArray;
}

/*
 * 格式化批次类型
 */
export const formatBatchType = {
  tr_up_loading_list: {
    title: '装车清单',
    getKey: ['car_batch', 'truck_t', 'route', 'tr_num', 'tr_num_ext', 'dr_name', 'dr_phone', 'fee_ave_type', 'remark'],
  },
  tr_down_loading_list: {
    title: '到车清单',
    getKey: ['car_batch', 'truck_t', 'route', 'tr_num', 'tr_num_ext', 'dr_name', 'dr_phone', 'fee_ave_type', 'remark'],
  },
  shuttle_up_list: {
    title: '短驳清单',
    getKey: [
      'car_batch',
      'tr_num',
      'dr_name',
      'dr_phone',
      'b_shuttle_f',
      'fee_ave_type',
      'truck_t',
      'remark',
      'station',
    ],
  },
  delivery_list: {
    title: '送货清单',
    getKey: [
      'car_batch',
      'tr_num',
      'dr_name',
      'dr_phone',
      'b_delivery_f',
      'fee_ave_type',
      'truck_t',
      'mgr_id',
      'remark',
    ],
  },
  pickup_list: {
    title: '提货清单',
    getKey: [
      'car_batch',
      'tr_num',
      'dr_name',
      'dr_phone',
      'b_pickup_f',
      'fee_ave_type_txt',
      'fee_ave_type',
      'truck_t',
      'mgr_id',
      'remark',
    ],
  },
};

// 批次详情 价格信息
export function formatFeeInfo(title, list) {
  const arr = [];
  Object.keys(title).forEach(item => {
    list.forEach((dataItem, index) => {
      arr[index] = arr[index] || [];
      arr[index].push(`${title[item]}:${dataItem[item] || ''}`);
    });
  });
  return arr;
}

export function downLoadFile(idata, url) {
  // remark: 'export'
  if (!checkCookie()) return;
  const form = document.createElement('form');
  form.setAttribute('style', 'display:none');
  form.setAttribute('target', '');
  form.setAttribute('method', 'post');
  // form.setAttribute('action', `/api/${url}`)
  form.setAttribute('action', `/api/${url}?logid=${getLogId()}&gid=${getGId()}`);
  const data = { ...idata };
  data.remark = 'export';
  data.logid = `${getLogId()}`;
  data.gid = `${getGId()}`;
  Object.keys(data).forEach(key => {
    const ipt = document.createElement('input');
    ipt.setAttribute('type', 'hidden');
    ipt.setAttribute('name', key);
    ipt.setAttribute('value', data[key]);
    form.appendChild(ipt);
  });
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

export const exportCsvAll = function (that, req, url) {
  const queryFilter = req || that.getCurrFetchParam();
  queryFilter.fetch_mode && delete queryFilter.fetch_mode;
  if (!queryFilter) return;
  const data = JSON.stringify({
    ...queryFilter,
    format: 'csv',
  });
  downLoadFile({ req: data }, url || that.tableInfo.url);
};

/*
 *  转换为excel输出结构
 *  return ext{ dataList, datasTypeFront, columnWidths }
 */
export function dataToExcel(ext) {
  const { header = {}, data = [], headerArry = [], isInsertAvg = false } = ext;
  const excelHeader = [];
  const excelData = [];
  const datasTypeFront = [];
  const columnWidths = [];
  let dataList = [];
  headerArry.unshift('front_sn'); // 手动增加字段
  header.front_sn = {
    title: '序号',
    type: 'Number',
    width: 40,
  };
  headerArry.forEach(item => {
    const headerItem = header[item];
    const { type, columnType, filterType, width } = headerItem;
    if (type === 'img') return;
    excelHeader.push(headerItem.title);
    if (
      type === 'Text' &&
      filterType !== 'SelectDrop' &&
      inArray(['long', 'short', 'double', 'float', 'currency', 'integer', 'currency'], columnType)
    ) {
      datasTypeFront.push('Number');
    } else if (
      type === 'Input' &&
      inArray(['long', 'short', 'double', 'float', 'currency', 'integer', 'currency'], columnType)
    ) {
      datasTypeFront.push('Number');
    } else if (
      type === 'Link' &&
      inArray(['long', 'short', 'double', 'float', 'currency', 'integer', 'currency'], columnType)
    ) {
      datasTypeFront.push('Number');
    } else if (type === 'Link' || type === 'Icon') {
      datasTypeFront.push('Text');
    } else {
      datasTypeFront.push(headerItem.type);
    }
    columnWidths.push(width);
    data.forEach((dataItem, index) => {
      excelData[index] = excelData[index] || [];
      if (item === 'front_sn') {
        if (index === data.length - 1) {
          excelData[index].push('合计');
        } else if (index === data.length - 2 && isInsertAvg) {
          excelData[index].push('平均值');
        } else {
          excelData[index].push(`${index + 1}`);
        }
      } else {
        excelData[index].push(_.get(dataItem, item));
      }
    });
  });
  dataList = [excelHeader, ...excelData];
  return { dataList, datasTypeFront, columnWidths };
}

// 格式化批次头部信息
export function fnFormatTitle(data, type) {
  const arr = [];
  if (type === 1) {
    data.forEach((item, index) => {
      arr[index] = [];
      arr[index].push(item.join('      '));
    });
  } else {
    arr[0] = [];
    arr[0].push(data.join('      '));
  }
  return arr;
}

export const handleImg = (tableInfo, data, rowIndex, key, val) => {
  const { category, tab } = tableInfo;

  const imgBeNull = category === 'Driver' && tab === 'cert' && ['id_front_img', 'id_back_img', 'd_l_img'].includes(key);
  const value = data[rowIndex][key];

  const showFileName = ['attachments', 'g_attachments', 'tt_g_attachments'].includes(key);
  if (showFileName) {
    data[rowIndex][key] = value?.map?.(item => `【${item.showName || item.name}】`).join('') ?? value;
  } else if (imgBeNull) {
    data[rowIndex][key] = val && val.length ? '有' : '无'; // eslint-disable-line
  } else if (Array.isArray(value) && Object.values(value).some(k => typeof k === 'object')) {
    data[rowIndex][key] = ''; // eslint-disable-line
  } else if (typeof value === 'object' && !Array.isArray(value)) {
    data[rowIndex][key] = ''; // eslint-disable-line
  }
};

/**
 * 处理一下用户自定义导出数据
 * 原逻辑是从选中的数据中导出，但有些异步的数据无法获取，所以需要从用户传入的自定义数据中二次组合
 * @param {*} selectData 当前选中的列表数据
 * @param {*} userData 用户指定导出的数据，同表格数据格式
 * @param {*} fullPage 是否导出当前页面数据
 * @param {*} selects 当前页面选中的行
 * @returns
 */
function getUserExportData({ selectData, userData, fullPage, selects, getExportItem }) {
  // 默认为整个页面数据
  let matchData = userData;
  // 从当前选中的数据中找到对应的行，把额外的定位数据写进去
  // 导出当前页数据
  if (!fullPage) {
    // 从选择的下标行中写入数据
    matchData = userData.filter((item, index) => selects.includes(index));
  }
  return _.cloneDeep(selectData).map((item, index) => {
    // @罗中 todo 业务逻辑从公共部分抽出
    item.last_time = matchData?.[index]?.last_time;
    if (matchData?.[index]?.lnglat !== ',') {
      item.lnglat = matchData?.[index]?.lnglat;
    } else {
      item.lnglat = '';
    }
    item.car_loc = matchData?.[index]?.car_loc;
    if (typeof getExportItem === 'function') {
      item = getExportItem(item);
    }
    return item;
  });
}

/**
 * listpage-导出选中
 * @param {*} that
 * @param {*} fullPage fasle 是否是导出整个页面的数据
 * @param {*} userConfig {} 用户自定义配置
 * @param {*} userConfig.data [] 用户指定导出的数据，同表格数据格式
 * @param {*} userConfig.title '' 用户自定义导出文件的名称
 * @returns
 */
export function fnExportSelect(that, fullPage, userConfig) {
  const selects = that.vTable.getStateSelectes(); // 要导出的序列号
  // 需要选择数据，或者有特殊导出的数据
  if (!fullPage && selects.length < 1) {
    errmsgTip('请选择要导出的数据！');
    return false;
  }
  let data = [];
  const selectData = [...that.getShowingData(fullPage ? undefined : selects)]; // 格式化后的字符串数据
  const totalList = fullPage ? that.getShowingTotalRow(['footerTotal']) : that.getSelectedTotalRow(); // 选中合计data
  const avgObj = fullPage ? that.getShowingAvgRow(['footerCalculate']) : that.getSelectedTotalRow('avg'); // 选中合计data
  const isInsertAvg = !isEmptyObj(avgObj) && !_.every(avgObj, _.isUndefined);
  // 如果有用户自定义导出，则特殊处理一下数据s
  // 是否有用户自定义导出数据
  const hasUserData = userConfig && Array.isArray(userConfig?.data);
  if (hasUserData) {
    data = getUserExportData({
      selectData,
      userData: userConfig?.data,
      fullPage,
      selects,
      getExportItem: userConfig?.getExportItem,
    });
    // 用户自定义数据因为是非全量，所以需要修改合计的数据
    totalList.car_batch = `${data.length} 批次`;
    // 计算里程
    const totalMile = data.reduce(function (sum, cur) {
      const curNo = +cur.line_mile || 0;
      return sum + curNo;
    }, 0);
    totalList.line_mile = totalMile || 0;
  } else {
    data = selectData;
  }
  const header = (that?.getHeader && that?.getHeader()) || {};
  const hKeys = Object.keys(header);
  // 获取多级表头
  const { isMultiTable, multiHeaders, flattenColumns = [] } = getMultiHeaders(that);
  // console.log('merges', merges);
  const pageInfo = that.props.getPageInfo();
  const pageTitle = userConfig?.title || pageInfo.menuName;
  isInsertAvg && data.push(avgObj);
  let headerArry = [];
  data.push(totalList);
  const tableInfo = that.tableInfo || {};

  // 处理图片
  data.forEach((originRow, rowIndex) => {
    const rowItem = { ...originRow };
    data[rowIndex] = rowItem;
    // 结算单号、凭证号的数据有的是数组，需处理拼接位字符串
    if (Array.isArray(originRow.settle_no)) {
      data[rowIndex].settle_no = originRow.settle_no.join(' ');
    }
    if (Array.isArray(originRow.cert_no)) {
      data[rowIndex].cert_no = originRow.cert_no.join(' ');
    }
    Object.keys(rowItem).forEach(fieldKey => handleImg(tableInfo, data, rowIndex, fieldKey, rowItem[fieldKey]));
  });

  if (isMultiTable) {
    headerArry = flattenColumns.map(el => el.key);
  } else {
    hKeys.map(key => {
      if (header[key].display === 'show') {
        headerArry.push(key);
      }
      return headerArry;
    });
  }
  const excelDataList = dataToExcel({ header, headerArry, data, isInsertAvg });
  const { dataList, datasTypeFront, columnWidths } = excelDataList;
  const { category, tab } = tableInfo;
  const { action, controller } = that?.props?.config || getPathInfo();
  const noticeParams = {
    identify: `${controller}_${action}`,
    category,
    tab,
    module: pageTitle,
    export_num: selects?.length,
  };
  const workbookOption = returnWorkbookOption({ pageTitle }, [
    { columnWidths, datasTypeFront, dataList, multiHeaders, isMultiTable, isInsertAvg },
  ]);
  dynamicImportExportJsonExcel().then(module =>
    module.default({
      ...workbookOption,
      noticeParams,
    }),
  );
}
// 平铺所有子集
function getFlattenColumns(columnsGroup, childrenColumnName) {
  const rows = [];
  function travel(columns) {
    if (columns && columns.length > 0) {
      columns.forEach(column => {
        if (!column[childrenColumnName]) {
          rows.push({ ...column, key: column.key || column.id });
        } else {
          travel(column[childrenColumnName]);
        }
      });
    }
  }
  travel(columnsGroup);
  return rows;
}
/**
 * 根据基准数组获取标数组的排序
 *
 * @param {array} baseArray 基准数组
 * @param {array} compareArray 需要调整排序的目标数组
 * @returns {array} 排序映射数组
 */
function restoreArrayOrder({ baseArray, compareArray }) {
  if (baseArray.length !== compareArray.length) {
    return [];
  }
  const diffIndexes = [];
  baseArray.forEach((baseItem, index) => {
    if (compareArray[index]?.key !== baseItem?.key) {
      const newIndex = compareArray.findIndex(compareItem => compareItem?.key === baseItem?.key);
      if (newIndex !== -1) {
        diffIndexes.push({
          current: index,
          new: newIndex,
        });
      }
    }
  });
  return diffIndexes;
}
function getMultiHeaders(that) {
  const header = (that?.getHeader && that?.getHeader()) || {};
  const isMultiTable = that?.getIsMultiTable && that?.getIsMultiTable();
  let headerRelationship = [];
  let multiHeaders = [];
  let flattenColumns = [];
  if (isMultiTable) {
    header.front_sn = {
      title: '序号',
      type: 'Number',
      width: 40,
    };
    headerRelationship = (that?.getTableHeaderRelationShip && that?.getTableHeaderRelationShip()) || {};
    flattenColumns = getFlattenColumns(headerRelationship, 'children').filter(
      el => !['reference', 'checkbox', 'operate', 'blank_cell'].includes(el?.key),
    );
    multiHeaders = buildHeader(
      // 增加序号字段
      [
        {
          id: 'front_sn',
        },
        ...headerRelationship,
      ],
      header,
    );
  }
  return {
    isMultiTable,
    multiHeaders,
    flattenColumns,
  };
}
// 非listpage-导出选中
export function fnExportSelectSpecial(ext) {
  let _dataList = [];
  const { data, total, header, pageTitle } = ext;
  const batchInfoLen = ext.batchInfoLen || 0;
  const HeaderData = ext.HeaderData || [];
  const hKeys = Object.keys(header);
  const headerArry = [];
  data.push(total);
  hKeys.map(key => {
    if (header[key].display === 'show') {
      headerArry.push(key);
    }
    return headerArry;
  });
  const excelDataList = dataToExcel({ header, headerArry, data });
  const { dataList, datasTypeFront, columnWidths } = excelDataList;
  _dataList = [...HeaderData, ...dataList];
  const workbookOption = returnWorkbookOption({ pageTitle }, [
    { columnWidths, datasTypeFront, dataList: _dataList, batchInfoLen },
  ]);
  const { action, controller } = getPathInfo();
  const noticeParams = {
    identify: `${controller}_${action}`,
    category: '',
    tab: '',
    module: pageTitle,
    export_num: total,
  };
  dynamicImportExportJsonExcel().then(module =>
    module.default({
      ...workbookOption,
      noticeParams,
    }),
  );
}

// listpage-导出全部
export function fnWorkBookOption(data, ext) {
  let dataList = [];
  let columnWidths = [];
  let datasTypePhp = [];
  let workbookOption = {};
  const datasTypeFront = [];
  const { pageTitle, noticeParams, that } = ext;
  const avgObj = that?.getShowingAvgRow?.() ?? {};
  const isInsertAvg = !isEmptyObj(avgObj);
  const header = (that?.getHeader && that?.getHeader()) || {};
  let diffIndexes = [];
  const { isMultiTable, multiHeaders, flattenColumns } = getMultiHeaders(that);
  if (isMultiTable) {
    const compareArray = Object.keys(header)
      .filter(
        key => header[key].display === 'show' && !['reference', 'checkbox', 'operate', 'blank_cell'].includes(key),
      )
      .map(key => ({
        key,
      }));
    diffIndexes = restoreArrayOrder({
      compareArray,
      baseArray: flattenColumns,
    });
  }
  const batchInfoLen = ext.batchInfoLen || 0;
  const HeaderData = ext.HeaderData || [];
  let bodyData = [];
  let sumData = [];
  let total = 0;
  const dateIndex = {};
  Object.keys(data).forEach(index => {
    const item = data[index];
    if (item.type === 'header') {
      dataList = [...dataList, ...item.data];
      datasTypePhp = [...datasTypePhp, ...item.datasType];
      columnWidths = [...columnWidths, ...item.columnWidth];
      datasTypePhp.forEach((t, i) => t === 'date' && (dateIndex[i] = 1));
    } else if (item.type !== 'footer') {
      let concatData = [];
      if (isMultiTable) {
        item.data.forEach(row => {
          const newRow = [...row];
          diffIndexes.forEach(location => {
            // 由于返回的数据里有序号，所以index需要增加1
            const currentIndex = location.current + 1;
            const newIndex = location.new + 1;
            const newData = row[newIndex];
            newRow.splice(currentIndex, 1, newData);
          });
          concatData.push(newRow);
        });
      } else {
        concatData = item.data;
      }
      bodyData = bodyData.concat(concatData);
      dataList = [...dataList, ...concatData.map(row => row.map((col, i) => (dateIndex[i] && col === 0 ? null : col)))];
    } else {
      sumData = item.data && item.data[0];
    }
    if (item.type === 'data') {
      total += item.data?.length || 0;
    }
  });
  if (sumData) {
    if (pageTitle !== '员工交账明细') {
      sumData = sumData.map((v, i) => {
        const unit = (v && `${v}`.replace(/(-|\+)?\d+(\.\d+)?/, '')) || '';
        let sum = v;
        if (unit && v !== '合计') {
          sum = `${bodyData.length}${unit}`;
        } else if (
          v !== '合计' &&
          v !== null &&
          ['integer', 'double', 'currency', 'long', 'short', 'float'].includes(datasTypePhp[i])
        ) {
          sum = +bodyData.reduce((a, b) => (+a || 0) + (+b[i] || 0) || '', 0) || '';
        }
        return sum;
      });
    }
    dataList.push(sumData);
  }
  dataList = [...HeaderData, ...dataList];
  datasTypePhp.forEach(item => {
    datasTypeFront.push(DATATYPE[item] || 'Text');
  });
  noticeParams.export_num = total;
  workbookOption = returnWorkbookOption({ pageTitle }, [
    { columnWidths, datasTypeFront, dataList, batchInfoLen, isInsertAvg, isMultiTable, multiHeaders },
  ]);
  dynamicImportExportJsonExcel().then(module =>
    module.default({
      ...workbookOption,
      noticeParams,
    }),
  );
}

// 将数组2 拷贝到 数字1，支持各类迭代方法
const copyToArr = (arr1, arr2, { each, map = el => el } = {}) => {
  arr2?.forEach((e, i) => {
    each?.(e, i);
    arr1.push(map(e));
  });
};

export function fnWorkBookProcess(file, ext = {}) {
  if (!file) return;
  const { sheets, url, fileName } = file;
  const { noticeParams } = ext;
  // 返回了 OSS 地址，直接下载
  if (url) {
    downloadOSSFile(url);
    // 即使客户没有真的保存文件，仍然进行消息提示
    exportSafeManagerNotice({
      ...noticeParams,
    });
    return;
  }
  // 否则使用前端导出

  const _sheets =
    sheets?.map(sheet => {
      const { data, config } = sheet;
      const dataList = [];
      const datasTypeFront = [];
      const columnWidths = [];

      Object.keys(data).forEach(index => {
        const item = data[index];
        if (item.type === 'header') {
          copyToArr(dataList, item.data);
          copyToArr(datasTypeFront, item.datasType, { map: el => DATATYPE[el] || 'Text' });
          copyToArr(columnWidths, item.columnWidth);
        } else if (item.type !== 'footer') {
          copyToArr(dataList, item.data);
        } else {
          // 添加页脚
          copyToArr(dataList, item.data);
        }
      });

      return {
        dataList,
        datasTypeFront,
        columnWidths,
        ...config,
      };
    }) ?? [];

  const workbookOption = returnWorkbookOption({ pageTitle: fileName }, _sheets);
  dynamicImportExportJsonExcel().then(module =>
    module.default({
      ...workbookOption,
      noticeParams,
    }),
  );
}

// 表头信息处理
export function fnBatchHeader(batchInfo) {
  const { cardForm, feeInfoTitle, feeInfoData, cardFormHeader, tab = '' } = batchInfo;
  if (tab === '' || (!formatBatchType[tab] && cardForm)) return { batchInfoLen: 0, HeaderData: [] };
  if (!formatBatchType[tab] && cardForm) {
    return { batchInfoLen: 0, allData: [] };
  }
  const batchFormat = {};
  const cardFormAll = { ...{}, ...cardForm, ...cardFormHeader };

  if (cardForm) {
    // 不显示的不导出
    Object.keys(cardFormAll).forEach(
      x => x !== 'car_batch' && x !== 'route' && !cardFormAll[x].display && delete cardFormAll[x],
    );
    batchFormat.title = [...[], `${window.company_info.company_name}${formatBatchType[tab].title}`];
    batchFormat.listInfo = formatCardForm(cardFormAll, formatBatchType[tab].getKey);
  } else {
    // wms
    batchFormat.title = [...[], `${window.company_info.company_name}${batchInfo.title}`];
    batchFormat.listInfo = [...batchInfo.listInfo];
  }

  batchFormat.listFee = (feeInfoTitle && formatFeeInfo(feeInfoTitle, feeInfoData)) || [];

  const batchInfoLen = batchFormat.listFee.length + 3;
  let HeaderData = [];
  HeaderData = [...HeaderData, ...fnFormatTitle(batchFormat.title)];
  HeaderData = [...HeaderData, ...fnFormatTitle(batchFormat.listInfo)];
  batchFormat.listFee.length > 0 && (HeaderData = [...HeaderData, ...fnFormatTitle(batchFormat.listFee, 1)]);
  HeaderData = [...HeaderData, ...['']];
  return { batchInfoLen, HeaderData };
}

// 批次详情-导出选中excel
export function fnBatchExportSelect(ext) {
  const { listData, total, header, title, batchInfo = {} } = ext;
  const { tab = '' } = batchInfo;
  const headerArry = [];
  const data = [...listData, total];
  Object.keys(header).forEach(key => {
    if (header[key].display === 'show') {
      headerArry.push(key);
    }
  });
  const batchHeaderData = fnBatchHeader(batchInfo);
  const { batchInfoLen, HeaderData = [] } = batchHeaderData;
  const excelDataList = dataToExcel({ header, headerArry, data });
  const { dataList, datasTypeFront, columnWidths } = excelDataList;
  let allData = [];
  allData = [...HeaderData, ...dataList];
  const pageTitle = ((formatBatchType[tab] && formatBatchType[tab].title) || title).replace(/:|\s|：|/g, '');
  const workbookOption = returnWorkbookOption(
    {
      pageTitle,
      title,
    },
    [{ columnWidths, datasTypeFront, dataList: allData, batchInfoLen }],
  );
  const { action, controller } = getPathInfo();
  const noticeParams = {
    identify: `${controller}_${action}`,
    category: '',
    tab: '',
    module: pageTitle,
    export_num: total,
  };
  dynamicImportExportJsonExcel().then(module =>
    module.default({
      ...workbookOption,
      noticeParams,
    }),
  );
}
/**
 * 自定义导出
 * 可以自定义 顶部信息
 * @param {*} param0
 */
export function batchCustomExport({ title, listData, total, header, info = [], feeInfo = [] }) {
  const headerCols = [];
  const headerData = [...fnFormatTitle([title]), ...fnFormatTitle([info]), ...fnFormatTitle(feeInfo, 1), ''];
  const data = [...listData, total];
  Object.keys(header).forEach(key => {
    if (header[key].display === 'show') {
      headerCols.push(key);
    }
  });
  const { dataList, datasTypeFront, columnWidths } = dataToExcel({ header, headerArry: headerCols, data });
  const workbookOption = returnWorkbookOption(
    {
      pageTitle: title,
      title,
    },
    [
      {
        columnWidths,
        datasTypeFront,
        dataList: [...headerData, ...dataList],
        batchInfoLen: headerData.length,
      },
    ],
  );
  const { action, controller } = getPathInfo();
  const noticeParams = {
    identify: `${controller}_${action}`,
    category: '',
    tab: '',
    module: title,
    export_num: total,
  };
  dynamicImportExportJsonExcel().then(module =>
    module.default({
      ...workbookOption,
      noticeParams,
    }),
  );
}
export const fnExportAllPost = function (ext) {
  const { url, pageTitle, req, batchHeaderData = {}, export_ways, ids, noticeParams, that } = ext;
  // const parm = {
  //   method: 'POST',
  //   body: `req=${JSON.stringify(req)}`,
  //   headers: {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/x-www-form-urlencoded'
  //   },
  //   credentials: 'include',
  // }
  // fetch(url, parm).then(async (res) => {
  //   if (res.status !== 200) {
  //     const err = await res.json()
  //     throw new Error(`Server failed with error: ${JSON.stringify(err)}`)
  //   } else {
  //     return res.json()
  //   }
  // })
  const parm = { method: 'POST', body: { req } };
  // eslint-disable-next-line no-unused-expressions
  export_ways ? (parm.body.req.export_ways = export_ways) : '';
  if (ids) {
    // eslint-disable-next-line no-unused-expressions
    ids.length !== 0 ? (parm.body.req.ids = ids) : '';
  }
  fetchApi(url, parm)
    .then(data => {
      if (data && +data.errno === 999999) {
        showInfo(ERROR, data.errmsg || '导出失败，请减少查询时间范围后，再重试一下。');
        return false;
      }
      fnWorkBookOption(data, { ...batchHeaderData, pageTitle, noticeParams, that });
    })
    .catch(() => {
      createTip('导出条数太大下载失败 ，请减少条数分批导出', ERROR).show();
    });
};
function getPathInfo() {
  const route = location.pathname.split('/').filter(Boolean);
  return {
    controller: route[0],
    action: route[1],
  };
}
export const fnExportAll = function (ext) {
  let { count } = ext;
  const { req, pageTitle, url, batchHeaderData = {}, that, export_ways, ids } = ext;
  const { category, tab } = req || {};
  const { action, controller } = that?.props?.config || getPathInfo();

  const noticeParams = {
    identify: `${controller}_${action}`,
    category,
    tab,
    module: pageTitle,
    export_num: count,
  };

  count = count || 1001; // 兼容员工交账汇总 类似的汇总表
  if (count <= 1000) {
    fnExportAllPost({ url, req, pageTitle, batchHeaderData, export_ways, ids, noticeParams, that });
  } else if (count > 1000 && count <= 10000) {
    showInfo(INFO, '导出过程可能比较耗时，开始导出后请耐心等待，现在导出？', true, {
      confirm: () => {
        window.$app.showBottomTips({
          type: 'exportTip',
          data: '文件导出中，请勿重复操作导出！',
        });
        fnExportAllPost({ url, req, pageTitle, batchHeaderData, export_ways, ids, noticeParams, that });
      },
    });
  } else if (count > 10000 && count <= 65000) {
    const content = (
      <div className="fn-show_info__text">
        <div className="dialog_detail">
          <p className="notice">当前导出的数据量过大，无法按xls格式导出！您确定要继续导出csv格式文件吗？</p>
          <div className="checkinfo-detail">
            <p className="notice_title">操作提示：</p>
            <div className="detail-content">
              <p className="notice_list">
                <em>●</em>点击[继续]，将按csv格式导出，无法保证数据格式和表格样式。
              </p>
              <p className="notice_list">
                <em>●</em>点击[取消]，可返回重新设置筛选条件，控制导出数据量在10000条以内。
              </p>
            </div>
          </div>
        </div>
      </div>
    );
    const buttons = [];
    buttons.push({
      name: '继续',
      btnType: 'primary',
      cb: thisDialog => {
        thisDialog.handleHide();
        !ext.exportAllCallback && exportCsvAll(that, req, url);
        ext.exportAllCallback && ext.exportAllCallback();
        exportSafeManagerNotice({
          ...noticeParams,
        });
      },
    });
    buttons.push({
      name: '取消',
      btnType: 'default',
      cb: thisDialog2 => {
        thisDialog2.handleHide();
      },
    });
    const popup = new PopUp(ShowInfo, {
      classname: 'print-error detail_info',
      isShow: true,
      type: WARN,
      autoDestroy: true,
      title: '提示',
      content,
      buttons,
      closable: true,
      maskClosable: false,
    });
    popup.show();
  } else {
    showInfo(INFO, '导出数据量已超过系统限额<65000条>，请重新选择数据范围。', true, undefined, false);
  }
};

// 批次详情-导出excel
export function fnBatchExportAll(ext) {
  const { req, exportUrl, count, batchInfo = {}, pageTitle, ids, export_ways } = ext;
  const { tab = '' } = batchInfo;
  const batchHeaderData = fnBatchHeader(batchInfo);
  const pageTitleFormt = (pageTitle || '').replace(/:|\s|：|/g, '');
  fnExportAll({
    count,
    req,
    ids,
    export_ways,
    batchHeaderData,
    url: exportUrl,
    pageTitle: pageTitleFormt || (formatBatchType[tab] && `${formatBatchType[tab].title}`),
    // pageTitle: `${window.company_info.company_name}${formatBatchType[tab].title}`,
  });
}

/**
 * 构建excel表头
 * @param revealList 列表页面展示的表头
 * @returns {[]} excel表格展示的表头
 */
function buildHeader(revealList, header) {
  const excelHeader = [];
  // 构建生成excel表头需要的数据结构
  getHeader(revealList, excelHeader, 0, 0, header);
  // 多行表头长短不一，短的向长的看齐，不够的补上行合并占位符
  const max = Math.max(...excelHeader.map(a => a.length));
  excelHeader.filter(e => e.length < max).forEach(e => pushRowSpanPlaceHolder(e, max - e.length));
  return excelHeader;
}

/**
 * 填充行合并占位符
 * */
function pushRowSpanPlaceHolder(arr, count) {
  for (let i = 0; i < count; i++) {
    arr.push('!$ROW_SPAN_PLACEHOLDER');
  }
}
// 填充列合并占位符
function pushColSpanPlaceHolder(arr, count) {
  for (let i = 0; i < count; i++) {
    arr.push('!$COL_SPAN_PLACEHOLDER');
  }
}
/**
 * 生成头部
 * @param headers 展示的头部
 * @param excelHeader excel头部
 * @param deep 深度
 * @param perOffset 前置偏移量
 * @returns {number}  后置偏移量
 */
function getHeader(headers, excelHeader, deep, perOffset, header) {
  let offset = 0;
  let cur = excelHeader[deep];
  if (!cur) {
    cur = excelHeader[deep] = [];
  }
  // 填充行合并占位符
  pushRowSpanPlaceHolder(cur, perOffset - cur.length);
  for (let i = 0; i < headers.length; i++) {
    const head = headers[i];
    if (['reference', 'checkbox', 'operate', 'blank_cell'].includes(head.id)) {
      continue;
    }
    const title = header[head?.id]?.title || head?.id;
    cur.push(title);
    if (
      Object.prototype.hasOwnProperty.call(head, 'children') &&
      Array.isArray(head.children) &&
      head.children.length > 0
    ) {
      const childOffset = getHeader(head.children, excelHeader, deep + 1, cur.length - 1, header);
      // 填充列合并占位符
      pushColSpanPlaceHolder(cur, childOffset - 1);
      offset += childOffset;
    } else {
      offset++;
    }
  }
  return offset;
}

/**
 * @description 并发请求，返回所有结果
 * @param {Object} options - 并发请求配置项
 * @param {number} options.limit - 最大并发数
 * @param {Function[]} options.requestList - 异步函数列
 * @returns {Promise<*>} 所有结果
 */
export const asyncPool = async ({ limit = 3, requestList }) => {
  const pool = new Set();
  const results = [];
  for (const request of requestList) {
    const promise = request();
    results.push(promise);
    pool.add(promise);
    const clean = () => pool.delete(promise);
    promise.then(clean).catch(error => {
      clean();
      throw error;
    });
    if (pool.size >= limit) {
      await Promise.race(pool);
    }
  }
  return Promise.all(results);
};

export const queryAllByLocalExport = ({
  exportUrl,
  pageTitle,
  header,
  dataPath = 'res.right.data',
  errnoPath = 'errno',
  errmsgPath = 'errmsg',
  createParams,
  totalAcount,
  pageSize = 500,
  extra = {},
}) => {
  // 请求数据方法
  const fetchRequest = async param => {
    return fetchApi(exportUrl, {
      method: 'POST',
      body: { req: { ...param } },
    })
      .then(res => {
        const errno = _.get(res, errnoPath, 0);
        const errmsg = _.get(res, errmsgPath, '');
        if (errno !== 0) {
          return Promise.reject(errmsg || '导出失败，请稍后再试');
        }
        const data = _.get(res, dataPath, []);
        return Promise.resolve(data);
      })
      .catch(err => {
        return Promise.reject(err || err?.message || '导出失败，请稍后再试');
      });
  };

  // 构建请求队列
  const createfetchList = () => {
    const fetchList = [];
    const times = Math.ceil(totalAcount / pageSize);
    for (let i = 0; i < times; i++) {
      const pageNum = i + 1;
      const parama = createParams(pageNum);
      fetchList.push(() => fetchRequest(parama));
    }
    return fetchList;
  };

  // 获取所有数据并导出
  const fetchDataToExport = async () => {
    try {
      const fetchRequestList = createfetchList();
      const res = await asyncPool({ requestList: fetchRequestList });
      const data = res.reduce((pre, cur) => [...pre, ...cur], []);
      const { that } = extra;
      const total = that?.biPickOrder?.rightTable?.getTotalRow?.() || {};
      // 利用表格引擎的方法，构建数据，主要是为了拿到格式化的数据
      const listStore = that?.biPickOrder?.rightTable?.createStore?.(data);
      const listData = listStore?.getAllShow?.(true) || data;
      fnBatchExportSelect({ total, listData, header, title: pageTitle });
    } catch (error) {
      // 导出失败，关闭提示
      window.$app.showBottomTips({
        type: 'hideExportTip',
      });
      showInfo(ERROR, error || error?.message || '导出失败，请稍后再试');
    }
  };

  confirm(
    CHECK,
    '导出过程可能比较耗时，开始导出后请耐心等待，现在导出？',
    {
      confirm: '确定',
      cancel: '取消',
    },
    '确认导出',
  ).then(() => {
    window.$app.showBottomTips({
      type: 'exportTip',
      data: '文件导出中，请勿重复操作导出！',
    });
    fetchDataToExport();
  });
};
