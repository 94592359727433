import { getInnerPriceParams, switchWeight } from '../info/tool';

export default (state, createOrderData, modifyDiff) => {
  const { origin, ext, modifyFrom, odLinkId, calcInnerPrice, priceMode, weightUnit, feeSource } = state;
  const { tables, orderData } = modifyDiff;
  const req = { modify_reason: '', od_link_id: odLinkId, modify_from: modifyFrom, order_data: orderData, ...tables };
  orderData.route_time = state.routeTime;
  orderData.trans_hour = state.transHour;
  orderData.is_through = state.isThrough;
  orderData.fee_source = feeSource.getRealSource(state);

  if (orderData.actual_weight && weightUnit === '吨') {
    orderData.actual_weight = switchWeight(orderData.actual_weight, true);
  }
  if (orderData.shortfall_weight && weightUnit === '吨') {
    orderData.shortfall_weight = switchWeight(orderData.shortfall_weight, true);
  }
  if (!state.hasCashier) {
    if (!state.payBillingPaidShow && 'pay_billing' in orderData) {
      orderData.pay_billing_paid = !!orderData.pay_billing;
    }
    if (!state.cashreturnPaidShow && 'cashreturn' in orderData) {
      orderData.cashreturn_paid = !!orderData.cashreturn;
    }

    if (!state.rebatePaidShow && 'rebate' in orderData) {
      orderData.rebate_paid = !!orderData.rebate;
    }

    if (!state.coPayAdvPaidShow && 'co_pay_adv' in orderData) {
      orderData.co_pay_adv_paid = !!orderData.co_pay_adv;
    }

    if (!state.pickupFPaidShow && req.point_cost_info && req.point_cost_info.length) {
      req.point_cost_info.forEach((row, index) => {
        req.point_cost_info[index] &&
          'pickup_f' in req.point_cost_info[index] &&
          (req.point_cost_info[index].pickup_f_paid = !!req.point_cost_info[index].pickup_f);
      });
    }
  }

  if (req.trans_info) {
    const oldTable = (origin.trans_info && origin.trans_info.data) || [];
    const newTable = createOrderData.trans_info;
    const transPayList = state.transPayList;
    newTable.forEach((newRow, index) => {
      const oldRow = oldTable[index] || {};
      const oldTransFee = +oldRow.trans_f || 0;
      const newTransFee = +newRow.trans_f || 0;
      if (oldTransFee !== newTransFee) {
        req.trans_info[index] = req.trans_info[index] || {};
        req.trans_info[index].trans_f = newTransFee;
        transPayList.forEach(k => (req.trans_info[index][k] = newRow[k]));
      }
    });
  }

  // merge内部价格参数
  if (calcInnerPrice) {
    if (priceMode > 2) {
      const innerPriceParams = getInnerPriceParams(state);
      const paramsKeys = ['cor_id', 'cee_id', 'tax', 'pickup_f'];
      paramsKeys.forEach(key => (req[key] = innerPriceParams[key]));
    }

    if (!priceMode || priceMode > 2) {
      orderData.price_mode = state.priceMode || 0;
    }
  }

  // merge外部价格参数
  // const outerPriceParams = getOuterPriceParams(state)
  // const paramsKeys = ['trans_cee_pick_dist']
  // paramsKeys.forEach(key => orderData[key] = outerPriceParams[key])
  req.cor_pt = state.corPt;

  if (state.transCeePickDist !== ext.trans_cee_pick_dist) {
    orderData.trans_cee_pick_dist = state.transCeePickDist || 0;
  }
  state.pointPriceResult && Object.assign(orderData, state.pointPriceResult);
  req.is_split_check = 1;
  return req;
};
