import { showInfo, ot, throttle } from 'utils';
import { ERROR, WARN } from 'constants';
import _ from 'lodash';
import { SET_ORDER_DATA, SELECT_COR_SUG, FETCH_ORDER_DATA, SELECT_GOODS_SUG, MERGE_DATA } from '../../constant';
import { calcFreight, isLongKunBlackCor } from '../../tool';
import { calcInsurance } from '../tool';
import { calcSubTotal } from '@/pages/ScheduleController/task/components/taskEditor/goods/tool';

const relateGoodsSugKeys = ['co_delivery_f', 'co_pickup_f', 'co_pkg_f', 'declared_value', 'co_insurance'];

const effectActualPriceKeys = { totalPrice: 1, cashreturn: 1, discount: 1, rebate: 1 };

const needCalcCoDeliveryFGroup = { 61009: 1, 349: 1, 226: 1 };

const calcTotalPrice = (data, keys) => Object.keys(keys).reduce((total, key) => total + (+data[key] || 0), 0);

const needFill = (setting, key) => setting[key] && setting[key].required;

const freightMiddleware = page => store => {
  const checkLongKunBlackCor = throttle(() => {
    const state = store.getState();
    if (isLongKunBlackCor(state.longKunBlack, state.corName, state.corMobile)) {
      if (state.insured) {
        page.set('insured', 0);
        page.merge('needAutoInsure', true);
      }
      if (state.insuredShow) {
        page.merge('insuredShow', false);
      }
    } else if (state.needAutoInsure) {
      page.set('insured', 1);
      page.merge('insuredShow', true);
    } else if (state.originInsuredShow) {
      page.merge('insuredShow', true);
    }
  }, 500);

  // const calcActualPrice = throttle(() => {
  //   const state = store.getState()
  //   const { effectActualPriceKeys, keyMap, effectActualPriceKeyMap } = state
  //   const actualPrice = +Object.keys(effectActualPriceKeys).reduce((total, formulaKey) => {
  //     const ratio = effectActualPriceKeys[formulaKey]
  //     let value = +state[formulaKey] || 0
  //     if (!keyMap[formulaKey]) {
  //       const newKey = effectActualPriceKeyMap[formulaKey]
  //       tableKeys.forEach(tableKey => {
  //         const tableData = state[tableKey]
  //         tableData && tableData.forEach(row => {
  //           if (row[newKey]) value = +row[newKey] || 0
  //         })
  //       })
  //     }
  //     return total + (ratio * value)
  //   }, 0).toFixed(2)
  //   actualPrice !== (+state.actualPrice || 0) && page.set('actualPrice', actualPrice)
  // }, 200)

  const onChange = (next, key, val, action) => {
    const state = store.getState();
    const { setting } = state;

    // 影响合计运费计算的key 由后端给出 具体见adaptor
    const { effectTotalPriceKeys } = state;

    // 重新计算合计运费
    if (effectTotalPriceKeys[key]) {
      const tempData = { ...state, [key]: val };
      const totalPrice = +calcTotalPrice(tempData, effectTotalPriceKeys).toFixed(2);
      page.merge('totalPrice', totalPrice);
      if (state.isModify && (+state[key] || 0) !== (+val || 0)) {
        page.merge('lastEffectTotalPriceKey', key);
      }
    }

    // 重新计算实际运费
    // if (effectActualPriceKeys[key] || tableKeys.includes(key)) {
    //   calcActualPrice()
    // }
    // 重新计算实际运费
    if (effectActualPriceKeys[key]) {
      const { totalPrice, cashreturn, discount, rebate } = { ...state, [key]: val };
      const actualPrice = +(+totalPrice - (+cashreturn || 0) - (+discount || 0) - (+rebate || 0)).toFixed(2);
      page.merge('actualPrice', actualPrice);
    }

    if (
      needCalcCoDeliveryFGroup[state.groupId] &&
      window.orderDict['开单安装费'] &&
      window.orderDict['开单装卸费'] &&
      (key === 'coHandlingF' || key === 'coInstallF')
    ) {
      const { coHandlingF, coInstallF } = { ...state, [key]: val };
      !page.edited('co_delivery_f') && page.set('coDeliveryF', +(+coHandlingF + +coInstallF || 0).toFixed(2));
    }

    switch (key) {
      case 'goods': {
        // 改单时是否计算运费关联设置
        /*
         220824 CMTX-7967：重新计算订单价格
         经产品 周贺龙 唐嗣元 确认。只要修改过 吨/元 方/元 件/元 套/元，只做前端计算，不调用「价格计算」接口进行计算
         sendFlag：isCreate && 「开单时计算发货客户价格」||（isModify && 「改单时计算发货客户价格」&& !「修改货物信息不关联修改运费」);
         sendFlag简义：开单或改单时，计算客户发货价格
        */
        if (!state.notCalcFreightWhenModify || !state.isModify) {
          // CYTRD-4821 价格计算优先级
          if (state.sendFlag) {
            return;
          }

          if (state.preFreight) {
            page.merge('freightChange', true);
            break;
          }
          const changes = action?.changes || {};
          // 前端计算运费
          const newGoods = val?.map(item => ({
            ...item,
            ...(_.hasIn(changes, 'subtotal_price')
              ? {}
              : {
                  ...calcSubTotal(item, 'num'),
                  ...calcSubTotal(item, 'weight'),
                  ...calcSubTotal(item, 'volume'),
                  ...calcSubTotal(item, 'suit'),
                }),
          }));
          const totalPrice = calcFreight(newGoods, state.sendFlag, state.freightRoundType);

          setTimeout(() => {
            page.merge('goods', newGoods, 'price');
          });
          page.merge('coFreightF', totalPrice === 0 ? '' : totalPrice, 'price');
          page.merge('lastFreightSrc', 'goods');
        }
        break;
      }
      case 'declaredValue': {
        const insuranceSetting = setting.decimal_price;
        const maxDeclaredValue = setting.declare_no_over.money;

        if ((state.corInsuranceRatio || insuranceSetting.checked !== 'manual') && !page.edited('co_insurance')) {
          // 重新计算保价费
          const insurance = calcInsurance(
            { declaredValue: val },
            insuranceSetting,
            state.maxDecimal,
            state.corInsuranceRatio,
          );
          page.set('coInsurance', insurance);
        }

        if (maxDeclaredValue && +val > +maxDeclaredValue) {
          showInfo(ERROR, `${ot('声明价值')}超过了最大值，请重新输入。`);
        }
        break;
      }
      case 'corInsuranceRatio': {
        if ((val || state.corInsuranceRatio) && !page.edited('co_insurance')) {
          const insuranceSetting = setting.decimal_price;
          // 重新计算保价费
          const insurance = calcInsurance(state, insuranceSetting, state.maxDecimal, val);
          page.set('coInsurance', insurance);
        }
        break;
      }
      case 'deliveryMode': {
        // 送货时必填送货费
        !state.coDeliveryFSettingRequired &&
          state.needDeliveryFee &&
          page.merge('coDeliveryFRequired', val !== 'self_pick');
        break;
      }
      case 'pickup': {
        // 提货时必填提货费
        !state.coPickupFSettingRequired && state.needPickupFee && page.merge('coPickupFRequired', !!val);
        break;
      }
      case 'payBillingPaid':
      case 'cashreturnPaid':
      case 'scanReceipt': {
        const { payBillingPaid, cashreturnPaid, scanReceipt } = state;
        const paids = { payBillingPaid, cashreturnPaid, scanReceipt, [key]: val };
        if (Object.values(paids).every(item => item)) {
          showInfo(WARN, '不可同时勾选现付/现饭与扫码收款复选框');
          page.set('scanReceipt', false);
        }
        break;
      }
      case 'corName': {
        if (state.isLongKun && state.insuredServerEditable) {
          checkLongKunBlackCor();
        }
        break;
      }
      case 'corMobile': {
        if (state.isLongKun && state.insuredServerEditable) {
          checkLongKunBlackCor();
        }
        break;
      }
      default:
        break;
    }
  };
  const onMergePrice = (next, key, val, action) => {
    const state = store.getState();

    // 影响合计运费计算的key 由后端给出 具体见adaptor
    const { effectTotalPriceKeys } = state;

    // 重新计算合计运费
    if (effectTotalPriceKeys[key]) {
      const tempData = { ...state, [key]: val };
      const totalPrice = +calcTotalPrice(tempData, effectTotalPriceKeys).toFixed(2);
      page.merge('totalPrice', totalPrice);
      if (state.isModify && (+state[key] || 0) !== (+val || 0)) {
        page.merge('lastEffectTotalPriceKey', key);
      }
    }

    // 重新计算实际运费
    // if (effectActualPriceKeys[key] || tableKeys.includes(key)) {
    //   calcActualPrice()
    // }
    // 重新计算实际运费
    if (effectActualPriceKeys[key]) {
      const { totalPrice, cashreturn, discount, rebate } = { ...state, [key]: val };
      const actualPrice = +(+totalPrice - (+cashreturn || 0) - (+discount || 0) - (+rebate || 0)).toFixed(2);
      page.merge('actualPrice', actualPrice);
    }
  };

  return next => action => {
    // const state = store.getState()
    switch (action.type) {
      case SET_ORDER_DATA:
        onChange(next, action.payload.key, action.payload.val, action);
        break;
      case MERGE_DATA: {
        if (action.src === 'price') {
          onMergePrice(next, action.payload.key, action.payload.val, action);
        }
        break;
      }
      case SELECT_COR_SUG: {
        // 选择发货方sug时关联是否含税
        const { data } = action.payload;
        const taxType = data.tax_type;
        if (taxType !== undefined && taxType !== null) {
          page.set('taxInc', +taxType === 2);
        }
        break;
      }
      case SELECT_GOODS_SUG: {
        // 货物sug带出费用
        const state = store.getState();
        const { keyMap, goodsSugSetting } = state;
        const data = action.payload;
        relateGoodsSugKeys.forEach(
          k => state[`${keyMap[k]}Show`] && needFill(goodsSugSetting, k) && page.set(keyMap[k], +data[k] || 0),
        );
        break;
      }
      case FETCH_ORDER_DATA: {
        const { usedFor } = page.props;
        usedFor === 'updatePre' &&
          setTimeout(() => {
            const { totalPrice } = store.getState();
            page.set('totalPrice', totalPrice);
          });

        if (usedFor === 'updatePre' || usedFor === 'create') {
          setTimeout(() => {
            const state = store.getState();
            const { declaredValue, coInsurance, isLongKun, totalPrice, effectTotalPriceKeys } = state;
            declaredValue && !coInsurance && page.set('declaredValue', declaredValue);
            if (isLongKun) {
              checkLongKunBlackCor();
            }
            if (!totalPrice) {
              Object.keys(effectTotalPriceKeys).forEach(key => {
                if (state[key]) {
                  page.set(key, state[key]);
                }
              });
            }
          });
        }
        if (usedFor === 'modify') {
          setTimeout(() => {
            const { insured, isLongKun } = store.getState();
            if (isLongKun && !insured) {
              checkLongKunBlackCor();
            }
          });
        }
        break;
      }
      default:
        break;
    }
    next(action);
  };
};

export default freightMiddleware;
