import moment from 'moment';
import { typeIs } from 'utils';
import { alwaysSaveKeys, distributeKeys, goodsKeys, needTrimKeys, payModeKeys, OUTER_TRANS } from '../constant';
import { convertWeight, postString, switchWeight } from '../tool';
import { getInnerPriceParams, getOuterPriceParams } from '../info/tool';
import { dangerGoodsFields } from 'constants/setting/rosSetting';

export default order => {
  const {
    weightUnit,
    reservationNum,
    camelKey,
    keyMap,
    getMap,
    manualGNum,
    transSetting,
    isPre,
    isModify,
    isCreate,
    isScan,
    costInfo,
    budgetInfo,
    goodSn = [],
    calcInnerPrice,
    priceMode,
    modifyFrom,
    feeSource,
  } = order;
  console.log(order, 'lllllll');
  // 订单转运单时, 拆单, 合单 不过滤，否则会干掉开单页未显示的字段
  const keys =
    isCreate && reservationNum ? Object.keys(camelKey) : Object.keys(camelKey).filter(key => order[`${key}Show`]);
  const showGoodsKeys = goodsKeys.filter(key => order[`${keyMap[key]}Show`]);
  // 货物信息仓库 库区 货位字段
  ['wh_id', 'wh_area_id', 'wh_location_id'].map(key => {
    if (order[`${keyMap[key]}Show`]) {
      !showGoodsKeys.includes(`${key}`) && showGoodsKeys.push(`${key}`);
      !showGoodsKeys.includes(`${key.replace('id', 'name')}`) && showGoodsKeys.push(`${key.replace('id', 'name')}`);
    }
  });
  // 危险货物字段，始终带出
  showGoodsKeys.push(...Object.keys(dangerGoodsFields), 'attachments');

  const normalKeys = keys.filter(key => !goodsKeys.includes(keyMap[key]));
  const goods = isScan
    ? [{ num: goodSn.length }]
    : weightUnit === '吨'
    ? convertWeight(order.goods, true)
    : order.goods;
  const req = { manualGNum };
  const addrKeys = { start_info: 1, arr_info: 1, cor_addr_info: 1, cee_addr_info: 1 };
  normalKeys.forEach(key => {
    if (key === 'goods') {
      req.goods = [];
      goods
        .filter(
          (row, i) =>
            i === 0 ||
            row.id ||
            row.name ||
            row.num ||
            row.volume ||
            row.weight ||
            row.wh_id ||
            row.wh_area_id ||
            row.wh_location_id ||
            row.in_bill_num ||
            row.out_bill_num,
        )
        .forEach((item, index) =>
          showGoodsKeys.forEach(k => {
            const val = item[k];
            const isEmpty = val === '' || val === null || val === undefined;
            req.goods[index] = req.goods[index] || {};
            req.goods[index][k] = isEmpty ? '' : postString(val, needTrimKeys[k]);
            req.goods[index].subtotal_price = item.subtotal_price;
            req.goods[index].id = req.goods[index].name ? item.id : '';
          }),
        );
      return;
    }
    const val = order[key];
    const isEmpty = val === '' || val === null || val === undefined;
    const reqKey = keyMap[key];
    req[reqKey] = isEmpty ? '' : val;
    getMap[key] && typeIs(val, 'object') && getMap[key] in val && (req[reqKey] = val[getMap[key]]);
    req[reqKey] = postString(req[reqKey], needTrimKeys[reqKey]);
    if (addrKeys[reqKey] && req[reqKey] && req[reqKey].show_val && !req[reqKey].province) {
      const showVal = postString(req[reqKey].show_val);
      req[reqKey] = { ...req[reqKey], show_val: showVal, street: showVal };
    }
  });

  req.sys_order_num = order.originOrderNum;
  req.company_id = req.start_point;
  req.order_tp_id = order.tpl;

  // 收发货人是否匹配
  req.matched_cor_attachment = order.matchedCorAttachment;
  req.matched_cee_attachment = order.matchedCeeAttachment;

  alwaysSaveKeys.forEach(key => !req[key] && (req[key] = order[keyMap[key]]));
  Object.keys(distributeKeys).forEach(key => delete req[key]);

  if (normalKeys.includes('route') && order.route) Object.assign(req, order.route);
  if (order.memberCode) Object.assign(req, order.memberCode);

  if (req.product_type) {
    req.product_key = req.product_type.key;
    req.product_type = req.product_type.name;
  }

  if (req.product_line) {
    req.product_line = [req.product_line];
  }

  if (req.remark && typeIs(req.remark, 'array')) {
    req.remark = req.remark.join(',');
  }

  if (req.rcv_stn && typeof req.rcv_stn === 'object') {
    req.rcv_stn_name = req.rcv_stn.short_name;
    req.rcv_stn = req.rcv_stn.id;
  }

  if (req.receipt_require && typeIs(req.receipt_require, 'array')) {
    req.receipt_require = req.receipt_require.join(',');
  }
  if (!modifyFrom && req.actual_weight && weightUnit === '吨') {
    req.actual_weight = switchWeight(req.actual_weight, true);
  }
  if (!modifyFrom && req.shortfall_weight && weightUnit === '吨') {
    req.shortfall_weight = switchWeight(req.shortfall_weight, true);
  }
  req.route_time = order.routeTime;
  req.trans_hour = order.transHour;
  req.is_through = order.isThrough;
  req.old_is_through = order.oldIsThrough;
  req.fee_source = feeSource.getRealSource(order);

  if (isPre) {
    return req;
  }

  const transFilter = item => item.dn_mgr_id || item.dn_com_id || item.trans_dn_mgr_id;

  const transInfo =
    order.transInfo &&
    order.transInfo.filter(transFilter).map(item => ({
      ...item,
      address: item.address && item.address.name ? [item.address] : item.address,
      dn_com_id: item.dn_com_id ? item.dn_com_id.id : '',
      dn_mgr_id: item.dn_mgr_id ? item.dn_mgr_id.id : '',
      order_trans_remark: item.order_trans_remark ? item.order_trans_remark.trim() : '',
      trans_w: weightUnit === '吨' ? switchWeight(item.trans_w, true) : item.trans_w || '',
      trans_w_detail:
        weightUnit === '吨' && item.trans_w_detail
          ? item.trans_w_detail.map(w => switchWeight(w, true))
          : item.trans_w_detail,
    }));
  const transVolumeRatio = transSetting && +transSetting.trans_v_ratio.value;
  const notTransFilter = item =>
    transVolumeRatio || (+item.trans_type === OUTER_TRANS && (item.order_trans_remark || item.trans_order_num));
  const transInfoNoTrans =
    transInfo &&
    order.transInfo
      .filter(item => !transFilter(item) && notTransFilter(item))
      .map(item => ({
        trans_type: item.trans_type,
        trans_v: transVolumeRatio ? item.trans_v : undefined,
        order_trans_remark: item.order_trans_remark,
        trans_order_num: item.trans_order_num,
      }));
  const stdCost =
    order.stdCost &&
    order.stdCost.map(item => ({
      ...item,
      std_mgr_id: typeIs(item.std_mgr_id, 'object') ? item.std_mgr_id.id : item.std_mgr_id,
      std_mgr_name: typeIs(item.std_mgr_id, 'object') ? item.std_mgr_id.name : item.std_mgr_id ? item.std_mgr_name : '',
      std_cee_mgr_id: typeIs(item.std_cee_mgr_id, 'object') ? item.std_cee_mgr_id.id : item.std_cee_mgr_id,
      std_cee_mgr_name: typeIs(item.std_cee_mgr_id, 'object') ? item.std_cee_mgr_id.name : item.std_cee_mgr_name,
    }));

  const pointCostInfo =
    order.pointCostInfo &&
    order.pointCostInfo.map(item => {
      const row = { ...item };
      Object.keys(row).forEach(key => row[key] && (row[key] = postString(row[key], needTrimKeys[key])));
      if (+row.dispatch_st === 20 && isCreate) {
        row.dispatch_date = moment().format('YYYY-MM-DD HH:mm:ss');
      }
      return row;
    });
  const { dispatch_st: dispatchSt } = (pointCostInfo && pointCostInfo[0]) || {};

  pointCostInfo && (req.point_cost_info = pointCostInfo);
  costInfo && (req.cost_info = costInfo);
  stdCost && (req.std_cost = stdCost);
  transInfo && (req.trans_info = [...transInfo, ...transInfoNoTrans]);
  budgetInfo && (req.budget_info = budgetInfo);
  dispatchSt && (req.dispatch_st = dispatchSt);

  if (isCreate) {
    req.ext_trans_pt = order.ext_trans_pt;
  }
  if (isModify) {
    payModeKeys.forEach(key => (req[key] = order[keyMap[key]]));
    return req;
  }

  if (!order.hasCashier) {
    if (!order.payBillingPaidShow && req.pay_billing) {
      req.pay_billing_paid = true;
    }
    if (!order.cashreturnPaidShow && req.cashreturn) {
      req.cashreturn_paid = true;
    }

    if (!order.rebatePaidShow && req.rebate) {
      req.rebate_paid = true;
    }

    if (!order.coPayAdvPaidShow && req.co_pay_adv) {
      req.co_pay_adv_paid = true;
    }
    if (!order.pickupFPaidShow && req.point_cost_info && req.point_cost_info.length) {
      req.point_cost_info.forEach((row, index) => {
        req.point_cost_info[index] &&
          req.point_cost_info[index].pickup_f &&
          (req.point_cost_info[index].pickup_f_paid = true);
      });
    }
  }

  // merge内部价格参数
  if (calcInnerPrice && priceMode > 2) {
    const innerPriceParams = getInnerPriceParams(order);
    Object.entries(innerPriceParams).forEach(([k, v]) => !req[k] && k !== 'goods' && (req[k] = v));
    req.inner_price_list = order.innerPriceList;
  }
  // merge外部价格参数
  const outerPriceParams = getOuterPriceParams(order);
  Object.entries(outerPriceParams).forEach(([k, v]) => !req[k] && k !== 'goods' && (req[k] = v));
  req.cor_pt = order.corPt;
  // 删掉手续费不需要手续费参与计算，但是需要展示
  if (req.cor_pt && req.cor_pt.co_delivery_fee) {
    delete req.cor_pt.co_delivery_fee;
  }
  order.pointPriceResult && Object.assign(req, order.pointPriceResult);
  return req;
};
