/**
 * Created by wangnaihe on 2017/10/9.
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { fetchApi as fetch, validateFieldsOf, showInfo, buildTipMixin, ot } from 'utils';
import { ModalDialog, Button, Icon, Load, PureInput as Input, PureCheckbox as Checkbox } from 'components';
import { ERROR, FLOAT_P_2 } from 'constants';
import { paidTip } from '../../constant';
import { prefixCls } from './index.scss';

const fields = {
  od_loc_tr_f: ot('发站单票装车费'),
  od_rmt_unload_f: ot('到站单票卸车费'),
  od_loc_misc_f: ot('发站单票其他费'),
  od_rmt_msc_f: ot('到站单票其他费'),
  pickup_f: ot('单票提货费'),
  in_wh_f: ot('到站单票进仓费'),
  od_delivery_f: ot('单票送货费'),
  pci_uld_f: ot('卸货费'),
  pci_uld_grp: ot('卸货小组'),
};

const hasPaid = { pickup_f: 1, od_delivery_f: 1 };

const textFields = { pci_uld_grp: 1 };

class PointCostEditModal extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    data: PropTypes.object,
    close: PropTypes.func,
    onSaveSuccess: PropTypes.func,
  };

  constructor(prop) {
    super(prop);
    this.state = { title: `编辑网点支出【${prop.data.comName}】`, loading: true, disabled: {}, origin: {}, tips: {} };
  }

  async UNSAFE_componentWillMount() {
    const url = 'Order/Order/getComPointCostInfo';
    const { od_link_id: oid, com_id: cid } = this.props.data;
    const res = await fetch(url, { method: 'POST', body: { req: { od_link_id: oid, com_id: cid } } });
    const state = { loading: false };
    Object.entries(res.res.point_cost_info).forEach(([key, field]) => {
      this.state.origin[key] = state[key] = field.value;
      this.state.disabled[key] = !field.editable;
      this.state.tips[key] = field.tips ? buildTipMixin(field.tips) : undefined;
    });
    this.setState(state);
  }

  set = (key, val) => this.setState({ [key]: val });

  save = async () => {
    if (!(await validateFieldsOf(this.wrap))) return false;
    this.setState({ saving: true });
    const { origin } = this.state;
    const changed = Object.keys(origin).filter(k => origin[k] !== this.state[k]);
    const pointCostInfo = changed.reduce((pre, k) => ({ ...pre, [k]: this.state[k] }), {});
    const url = 'Order/Order/saveComPointCostInfo';
    const { od_link_id: oid, com_id: cid, comName } = this.props.data;
    const req = { od_link_id: oid, com_id: cid, com_short_name: comName, point_cost_info: pointCostInfo };
    const res = await fetch(url, { method: 'POST', body: { req } });
    if (res.errno === 0) {
      this.modal.handleHide();
      this.props?.onSaveSuccess?.('refresh');
    } else {
      showInfo(ERROR, res.errmsg);
      this.setState({ saving: false });
    }
  };

  render() {
    const { state } = this;
    const { title, loading, saving, disabled, tips } = state;
    const content = (
      <Load spinning={loading} className={prefixCls}>
        <div className="tip">
          <Icon iconType="icon-info-o" />
          <p>以下费用项只影响本网点支出，不影响批次费用。编辑后，无需通过改单审核直接生效，请确认无误后再输入！</p>
        </div>
        <div className="fields" ref={r => (this.wrap = r)}>
          {Object.keys(fields).map(key => (
            <div>
              <label>{fields[key]}</label>
              <Input
                value={state[key]}
                pattern={textFields[key] ? undefined : FLOAT_P_2}
                disabled={disabled[key]}
                onChange={e => this.set(key, e.target.value)}
                {...tips[key]}
              >
                {hasPaid[key] && (
                  <Checkbox
                    checked={state[`${key}_paid`]}
                    disabled={disabled[`${key}_paid`]}
                    onChange={e => this.set(`${key}_paid`, e.target.checked)}
                    {...paidTip}
                  />
                )}
              </Input>
            </div>
          ))}
        </div>
      </Load>
    );

    const bottom = [
      <Button key="1" type="primary" loading={saving} onClick={this.save}>
        确定
      </Button>,
      <Button key="2" type="default" disabled={saving} onClick={() => this.modal.handleHide()}>
        取消
      </Button>,
    ];

    return (
      <ModalDialog
        ref={r => (this.modal = r)}
        content={content}
        bottom={bottom}
        isShow
        isModal
        close={this.props.close}
        title={title}
        contentStyle={{ width: '520px' }}
      />
    );
  }
}

export default PointCostEditModal;
