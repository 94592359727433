/**
 * Created by wangnaihe on 2017/6/19.
 */
import React from 'react';
import { ButtonIcon, Button, CheckBox, Tooltip, Tips } from 'components';
import { WARN } from 'constants';
import { ot, hasPermission } from 'utils';
import OrderButtons from 'components/commoncomponents/OrderButtons';
import { isCollectPrint } from '../tool';
import { getProfitText } from '../profit/tool';
import commonErrorTip from '../public/commonErrorTip';
import { prefixCls } from './index.scss';
import browserHistory from 'routes/history';

const disablePrintTip = '您未选择左侧的打印模板，无法打印！';
const menuBtnHeader = { title: '' };

function renderFooter(page) {
  const getButtons = state =>
    Object.entries(state.buttons || {})
      .filter(item => item[1])
      .reduce(
        (all, [k, v]) => ({
          ...all,
          [k]: {
            ...v,
            children: v.title,
            display: true,
            loading: state[`${k}Saving`],
            eventNames: ['onClick'],
            type: 'ButtonMenu',
            uniqueKey: 'key',
            showKey: 'title',
            sublist: v.children ? Object.values(v.children).map(item => ({ ...item, name: item.title })) : [],
          },
        }),
        {},
      );
  const renderProfitTip = () => (
    <span className="profit-tip">
      {ot('单票毛利')}的设置公式可到系统设置-财务设置里调整！
      <br />
      {ot('单票毛利')}={getProfitText(page.store.getState())}
    </span>
  );

  const setScanReceipt = val => {
    if (val) {
      const { store } = page;
      const state = store.getState();
      const {
        payBilling,
        payBillingPaid,
        payBillingPaidShow,
        payArrival,
        payCoDelivery,
        cashreturn,
        cashreturnPaid,
        cashreturnPaidShow,
      } = state;
      const autoCollect = window.company_setting.auto_collect_v2.checked;
      if (
        (cashreturnPaid && (cashreturnPaidShow || autoCollect)) ||
        (payBillingPaid && (payBillingPaidShow || autoCollect))
      ) {
        const fee = [
          { val: payBillingPaid, name: ot('现付') },
          { val: cashreturnPaid, name: ot('现返') },
        ]
          .filter(item => item.val)
          .map(item => item.name)
          .join('，');
        const title = `${fee}勾选了“收银复选框”，不可扫码收款`;
        if ((!payBillingPaid && +payBilling) || +payArrival || +payCoDelivery || (!cashreturnPaid && +cashreturn)) {
          page
            .continueTip(
              {
                errmsg: title,
                res: {
                  failed_detail: [
                    '点击取消：在开单页将需要扫码收款的费用项后面的复选框取消勾选',
                    '点击继续：运单生成后仅对未收银的费用项操作扫码收款',
                  ],
                },
              },
              '您可以：',
            )
            .then(result => {
              result && page.set('scanReceipt', val);
            });
        } else {
          commonErrorTip({
            type: WARN,
            title,
            caption: '您可以：',
            detail: ['点击关闭：关闭此弹窗后在开单页将需要扫码收款的费用项后面的复选框取消勾选，然后再勾选“扫码收款”'],
          });
        }
        return;
      }
    }
    page.set('scanReceipt', val);
  };

  // 打开光标路径弹窗
  const handleShowCoPath = () => {
    page.setting({ key: 'coPath' });
  };

  const handlePushSetting = () => {
    // 跳转到订单配置
    browserHistory.pushWithQuery({
      pathname: '/SystemManagement/systemConfiguration',
      query: {
        activeTab: 'orderSetting',
      },
    });
  };
  const showOrderSettingButton = hasPermission('orderConfigurationV1');
  return function () {
    const { store } = page;
    const state = store.getState();
    const { showPreview, showAddPrint, showSavePrint, showSaveAdd, showSave, showNoticeMessage, btnType } = state;
    const { profit, profitShow, profitRate, totalPrice, shortcutsDisplay, msgTypeList } = state;
    const { printItems, isReservation, collectPrintTypes, isBack, scanReceipt } = state;
    const { showScanPay } = state;
    const { usedFor } = page.props;
    const collectPrint = isCollectPrint(state);

    const checkPrintItem = (i, checked) => page.setTable('printItems', i, { checked });
    const renderProfit = () =>
      profitShow && (
        <div className="profit">
          <div>
            <span>
              {ot('单票毛利')}
              <Tooltip className="profit-text" renderContent={renderProfitTip}>
                <i className="fn-icon fn-icon-help" />
              </Tooltip>
            </span>
            <i>¥ {profit}</i>
          </div>
          <div className="profit-rate">
            <span>
              {ot('毛利率')}
              <Tips
                className="profit-text"
                title={`${ot('毛利率')}=(${ot('单票毛利')}¥${profit})/(${ot('合计运费')}¥${totalPrice})`}
              >
                <i className="fn-icon fn-icon-help" />
              </Tips>
            </span>
            <i>{profitRate} %</i>
          </div>
        </div>
      );
    let disablePrint = collectPrint && collectPrintTypes.order;
    const renderPrintCheckbox = () =>
      printItems.map((item, i) => {
        const disabled = collectPrintTypes[item.type] && collectPrint;
        const checked = item.checked && !disabled;
        checked && (disablePrint = false);
        return (
          <div key={i}>
            <CheckBox
              disabled={disabled}
              label={item.name}
              direction="right"
              tips={disabled ? '提交收银后才可打印！' : item.name}
              checked={checked}
              onClick={val => checkPrintItem(i, val)}
            />
          </div>
        );
      });

    if (usedFor === 'create' || usedFor === 'copy') {
      return (
        <footer className={`${prefixCls}`}>
          {renderProfit()}
          {/* 暂时注释右下角齿轮设置 -20230420 */}
          {/* <MenuBtn
            classname="setting"
            direction="right_up"
            showKey="title"
            tableHeader={menuBtnHeader}
            subList={settingSubList}
            sublistStyle={{ width: 100 }}
            uniqueKey="key"
            display
            onClick={menuItem => page.setting(menuItem)}
            buttonProps={{ children: '', iconType: 'icon-setting' }}
          /> */}
          <div className="create-btn-group">
            <div className="print-checkbox">{renderPrintCheckbox()}</div>
            <div className="in-bk">
              <ButtonIcon data-path="co-path" iconType="icon-polygon" onClick={() => handleShowCoPath()}>
                光标路径
              </ButtonIcon>
              {showOrderSettingButton && (
                <ButtonIcon data-path="co-setting" iconType="icon-shezhi1" onClick={() => handlePushSetting()}>
                  订单配置
                </ButtonIcon>
              )}
              {showScanPay && (
                <ButtonIcon
                  iconType="icon-saomazhifu"
                  data-path="scan-receipt"
                  buttonClass={scanReceipt ? 'scan-receipt checked' : 'scan-receipt'}
                  clickLimit={false}
                  onClick={() => setScanReceipt(!scanReceipt)}
                  disabled={state.createSaving}
                >
                  扫码收款
                </ButtonIcon>
              )}
              {showNoticeMessage && (
                <ButtonIcon
                  buttonClass={msgTypeList && msgTypeList.length ? 'adm checked' : 'adm'}
                  iconType="icon-message"
                  data-path="notice_message"
                  onClick={() => page.msgModel('BUS_TRACK_SEND,BUS_TRACK_RECEIVE', 1)}
                  disabled={state.createSaving}
                >
                  短信通知
                </ButtonIcon>
              )}
              {!isReservation && showAddPrint && (
                <ButtonIcon
                  iconType="icon-print-out"
                  data-path="add_new_print"
                  disabled={disablePrint || (state.createSaving && btnType !== 'co_new_print')}
                  tips={disablePrint ? disablePrintTip : undefined}
                  onClick={() => page.handleSaveBtnClick('co_new_print')}
                  loading={state.createSaving && btnType === 'co_new_print'}
                >
                  新增并打印({shortcutsDisplay.add_new_print})
                </ButtonIcon>
              )}
              {showPreview && (
                <ButtonIcon iconType="icon-dayinyulan" data-path="co_preview" onClick={page.preview}>
                  预览{shortcutsDisplay.co_preview && `(${shortcutsDisplay.co_preview})`}
                </ButtonIcon>
              )}
              {showSavePrint && (
                <ButtonIcon
                  iconType="icon-print-out"
                  data-path="co_save_print"
                  disabled={disablePrint || (state.createSaving && btnType !== 'co_save_print')}
                  tips={disablePrint ? disablePrintTip : undefined}
                  onClick={() => page.handleSaveBtnClick('co_save_print')}
                  loading={state.createSaving && btnType === 'co_save_print'}
                >
                  保存并打印({shortcutsDisplay.co_save_print})
                </ButtonIcon>
              )}
              {!isReservation && showSaveAdd && (
                <ButtonIcon
                  iconType="icon-file-add"
                  data-path="add_save"
                  disabled={state.createSaving && btnType !== 'co_new'}
                  onClick={() => page.handleSaveBtnClick('co_new')}
                  loading={state.createSaving && btnType === 'co_new'}
                >
                  保存并新增({shortcutsDisplay.add_save})
                </ButtonIcon>
              )}
              {showSave && (
                <ButtonIcon
                  iconType="icon-save"
                  data-path="co_save"
                  disabled={state.createSaving && btnType !== 'co_save'}
                  onClick={() => page.handleSaveBtnClick('co_save')}
                  loading={state.createSaving && btnType === 'co_save'}
                >
                  保存({shortcutsDisplay.co_save})
                </ButtonIcon>
              )}
            </div>
          </div>
        </footer>
      );
    }

    if (usedFor === 'modify') {
      return (
        <footer className={`${prefixCls}`}>
          {renderProfit()}
          {!isBack ? (
            <ButtonIcon
              iconType="icon-file-add"
              data-path="add_save"
              disabled={state.modifySaving && btnType !== 'co_update_new'}
              onClick={() => page.handleSaveBtnClick('co_update_new')}
              loading={state.modifySaving && btnType === 'co_update_new'}
            >
              保存并新增({shortcutsDisplay.add_save})
            </ButtonIcon>
          ) : null}
          <ButtonIcon
            iconType="icon-save"
            data-path="co_save"
            disabled={state.modifySaving && btnType !== 'co_update'}
            onClick={() => page.handleSaveBtnClick('co_update')}
            loading={state.modifySaving && btnType === 'co_update'}
          >
            保存({shortcutsDisplay.co_save})
          </ButtonIcon>
        </footer>
      );
    }

    if (usedFor === 'updatePre') {
      return (
        <footer className={`${prefixCls}`}>
          {renderProfit()}
          <div className="create-btn-group">
            <div className="print-checkbox">{renderPrintCheckbox()}</div>
            <div className="in-bk">
              <ButtonIcon
                buttonClass={msgTypeList && msgTypeList.length ? 'adm checked' : 'adm'}
                iconType="icon-message"
                data-path="notice_message"
                onClick={() => page.msgModel('BUS_TRACK_SEND,BUS_TRACK_RECEIVE', 1)}
                disabled={state.updatePreSaving}
              >
                短信通知
              </ButtonIcon>
              <ButtonIcon iconType="icon-dayinyulan" data-path="co_preview" onClick={page.preview}>
                预览{shortcutsDisplay.co_preview && `(${shortcutsDisplay.co_preview})`}
              </ButtonIcon>
              <ButtonIcon
                iconType="icon-print-out"
                data-path="co_save_print"
                disabled={disablePrint || (state.updatePreSaving && btnType !== 'update_pre_save_print')}
                tips={disablePrint ? disablePrintTip : undefined}
                onClick={() => page.handleSaveBtnClick('update_pre_save_print')}
                loading={state.updatePreSaving && btnType === 'update_pre_save_print'}
              >
                保存并打印({shortcutsDisplay.co_save_print})
              </ButtonIcon>
              {showSave && (
                <ButtonIcon
                  iconType="icon-save"
                  data-path="co_save"
                  disabled={state.updatePreSaving && btnType !== 'update_pre_save'}
                  onClick={() => page.handleSaveBtnClick('update_pre_save')}
                  loading={state.updatePreSaving && btnType === 'update_pre_save'}
                >
                  保存({shortcutsDisplay.co_save})
                </ButtonIcon>
              )}
            </div>
          </div>
        </footer>
      );
    }

    if (usedFor === 'pointTrans') {
      return (
        <footer className={`${prefixCls}`}>
          <div className="create-btn-group">
            <div className="print-checkbox">{renderPrintCheckbox()}</div>
            <div className="in-bk">
              <Button
                type="primary"
                onClick={() => page.handleSaveBtnClick('transAccept')}
                loading={state.pointTransSaving && btnType === 'transAccept'}
                disabled={state.pointTransSaving && btnType !== 'transAccept'}
              >
                接收
              </Button>
              <Button
                type="primary"
                onClick={() => page.handleSaveBtnClick('transReject')}
                loading={state.pointTransSaving && btnType === 'transReject'}
                disabled={state.pointTransSaving && btnType !== 'transReject'}
              >
                拒绝
              </Button>
              <Button type="default" onClick={() => page.cancel('cancel')} disabled={state.pointTransSaving}>
                取消
              </Button>
            </div>
          </div>
        </footer>
      );
    }

    if (usedFor === 'outerTransDeal') {
      return (
        <footer className={`${prefixCls}`}>
          <div className="create-btn-group">
            <div className="in-bk">
              <Button
                type="primary"
                onClick={() => page.handleSaveBtnClick('transAccept')}
                loading={state.outerTransDealSaving && btnType === 'transAccept'}
                disabled={state.outerTransDealSaving && btnType !== 'transAccept'}
              >
                接收
              </Button>
              <Button
                type="primary"
                onClick={() => page.handleSaveBtnClick('transReject')}
                loading={state.outerTransDealSaving && btnType === 'transReject'}
                disabled={state.outerTransDealSaving && btnType !== 'transReject'}
              >
                拒绝
              </Button>
              <Button type="default" onClick={() => page.cancel('cancel')} disabled={state.outerTransDealSaving}>
                取消
              </Button>
            </div>
          </div>
        </footer>
      );
    }

    if (usedFor === 'detail') {
      return (
        <footer className={`${prefixCls}`}>
          <OrderButtons
            data={getButtons(state)}
            handleClick={page.detailOp}
            isShowPage={false}
            flat={false}
            hasStaticBtn={false}
          />
        </footer>
      );
    }
  };
}

export default renderFooter;
