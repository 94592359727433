import moment from 'moment';
import { WARN } from 'constants';
import { fetchApi as fetch, throttle, confirm, showInfo, ot } from 'utils';
import { getRoute, getProductLine, getPoint } from './tool';
import {
  SET_ORDER_DATA,
  CEE_ARR_MATCHED,
  CO_DELIVERY_FEE_BLUR,
  FETCH_ORDER_DATA,
  SELECT_CEE_SUG,
  directDelivery,
  withoutArr,
} from '../constant';
import { postponeDate, getCeeRelateArrPoint, calcField } from '../tool';
import { i18n } from '@/utils/i18n/index';
import { POPUP_CONFIRM_WINDOW } from '@/utils/i18n/constants';

const effectDeliveryModeKeys = {
  coDeliveryF: 'delivery_0_delivery',
  coUpstairsF: 'delivery_0_upstairs',
  coHandlingF: 'delivery_0_load',
  coInstallF: 'delivery_0_install',
};

const feeToDelivery = {
  coDeliveryF: 'pure_delivery',
  coUpstairsF: 'delivery_floor_no',
  coHandlingF: 'delivery_handle',
  coInstallF: 'delivery_set',
};

const trafficMiddleware = page => store => {
  const getGoodsNum = throttle(cb => {
    const state = store.getState();
    const { ext, goods = [], rcvStn, startPoint, arrPoint } = state;
    const num = calcField(goods, 'num');
    const req = {
      co_point_id: startPoint || '',
      arr_point_id: (arrPoint && arrPoint.company_id) || '',
      order_num: state.orderNum,
      rcv_stn: rcvStn ? rcvStn.id || rcvStn : '',
      goods_seq_num: ext.goods_seq_num || '',
      od_link_id: ext.od_link_id || '',
      num,
    };
    const url = 'Order/Order/getGoodsNum';
    const conf = { method: 'POST', body: { req } };
    fetch(url, conf).then(res => cb(res.res));
  }, 500);

  const getServiceType = (pickup, deliveryMode) => {
    if (pickup && deliveryMode === 'self_pick') {
      return 'door_site';
    } else if (deliveryMode === 'self_pick') {
      return 'site_site';
    } else if (pickup) {
      return 'door_door';
    }
    return 'site_door';
  };

  const calcXpcdArrDate = (timeData, billingDateStr, state) => {
    const key = state.xpcdArrDateConsistDelta;
    if (state.xpcdArrDateShow && timeData && timeData[key]) {
      const billingDate = moment(billingDateStr || undefined);
      const begin =
        state.xpcdArrDateConsistBegin === 'next_billing_date' ? billingDate.add(1, 'days').startOf('day') : billingDate;
      const delta = timeData[key];
      const xpcdArrDate = begin.add(delta, key === 'route_time' ? 'hours' : 'day');
      const {
        postponeXpcdArrDateTo,
        xpcdArrDateCheckSaturday,
        xpcdArrDateCheckSunday,
        checkOverDeliveryTime,
        productLine,
      } = state;
      const deliveryTime =
        'delivery_time' in timeData ? timeData.delivery_time : productLine && productLine.delivery_time;
      const overDeliveryTime = checkOverDeliveryTime && deliveryTime && xpcdArrDate.format('HH:mm:ss') > deliveryTime;
      const xpcdArrDateStr = postponeDate(
        xpcdArrDate,
        xpcdArrDateCheckSaturday,
        xpcdArrDateCheckSunday,
        postponeXpcdArrDateTo,
        overDeliveryTime,
      );
      store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'xpcdArrDate', val: xpcdArrDateStr } });
    }
  };

  const setProduct = (res, src) => {
    const state = store.getState();
    if (res.productLine || state.productLine) page.set('productLine', res.productLine);
    page.merge('productTypeEnum', res.productTypeEnum);
    res.productType && page.set('productType', res.productType, src);
  };

  const mergeRouteInfo = (val, state) => {
    // eslint-disable-next-line no-nested-ternary
    const priceMode = val && 'price_mode' in val ? (+val.price_mode < 2 ? state.defaultPriceMode : val.price_mode) : '';
    // 设置中转方式
    val && val.trans_mode && page.set('transMode', val.trans_mode);
    // 设置中转时效
    page.set('transHour', (val && val.trans_hour) || '');
    // 设置路由时效
    page.set('routeTime', (val && val.route_time) || '');
    page.set('isThrough', (val && val.is_through) || '');
    page.set('oldIsThrough', (val && val.old_is_through) || '');
    page.set('mile', (val && val.mile) || '');
    // 到站变化时更新价格模式
    page.set('oldPriceMode', (val && val.old_price_mode) || '');
    page.set('priceMode', priceMode);
  };

  // 移库中，构造下拉数据的相关逻辑
  const handleStockPointData = routeVal => {
    const state = store.getState();
    const { isOpenPayment, stockPoint, startPoint, startPointId, startPointEnum } = state;

    if (!isOpenPayment) return;

    // 入库网点可选下拉数据：开单网点，当前节点在计划路由的下一站；
    const route = Array.isArray(routeVal) ? routeVal : [];
    let stockPointEnum = [...startPointEnum];

    const curRouteIdx = route.findIndex((v, idx) => +v.company_id === +startPointId && route[idx + 1]);
    const nextRouteIdx = curRouteIdx > -1 && curRouteIdx + 1;

    if (nextRouteIdx) {
      stockPointEnum = [
        ...startPointEnum,
        { id: route[nextRouteIdx].company_id, short_name: route[nextRouteIdx].node_name },
      ];
    }

    // eslint-disable-next-line no-nested-ternary
    const newStockPoint = !route.length
      ? startPoint
      : stockPointEnum.find(v => +v.id === +stockPoint)
      ? stockPoint
      : '';

    page.set('stockPointEnum', stockPointEnum);
    // 不选路由时，入库网点不可选
    page.set('stockPointEditable', route.length > 0);
    page.set('stockPoint', newStockPoint);
  };

  const onChange = async (key, val, src, action) => {
    console.log(key, val, '32223', src);
    const state = store.getState();
    const { setting } = state;
    const { goodsNumCustom } = state;
    const { effectGoodsNumKeys } = state;
    const i18nDict = await i18n(POPUP_CONFIRM_WINDOW);
    const orderDict = i18nDict.get('order', '运单');

    switch (key) {
      case 'billingDate': {
        const timeData = state.xpcdArrDateConsistDelta === 'route_time' ? state.arrInfo : state.productLine;
        calcXpcdArrDate(timeData, val, state);
        break;
      }
      case 'startInfo': {
        // 设置线路
        state.productLineShow &&
          getProductLine(val, state.arrInfo, state.productType, state.cid, res => setProduct(res, 'startInfo'));
        break;
      }
      case 'arrInfo': {
        // 设置线路
        state.productLineShow &&
          getProductLine(state.startInfo, val, state.productType, state.cid, res => setProduct(res, 'arrInfo'));
        if (src === 'arrPoint') break;
        console.log(state.addressNetworkPoint, !state.addressNetworkPoint, state.arrPoint);
        if (
          state.addressNetworkPoint !== undefined &&
          !state.addressNetworkPoint &&
          state.arrPoint &&
          state.arrPoint.short_name
        )
          break;
        // 读取时效 设置标准到货日期
        calcXpcdArrDate(val, state.billingDate, state);
        if (val && (val.show_val || val.company_id > 0 || val.route_id > 0 || val.id)) {
          // 设置路由信息

          state.routeShow && store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'route', val: getRoute(val) } });
          if ((val.company_id || val.id) && !state.ceeRelateArrPoint) {
            // 设置目的网点
            const point = {
              company_id: val.company_id || val.id,
              name: val.name,
              short_name: val.short_name,
              addr: val.arr_point_addr,
              company_type: val.company_type,
              company_no_service: val.company_no_service,
            };
            page.set('arrPoint', point, 'arrInfo');
          }
          console.log(store.getState(), 'nnnnnnnn');
          if ((val.company_id || val.id) && !state.ceeRelateArrPoint && val.short_name && store.getState().fromGis) {
            console.log('888888');
            getPoint(val.short_name, state, fetch).then(res => {
              if (res && res[0] && res[0].route_nodes) {
                store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'route', val: getRoute(res[0]) } });
              }
              page.setSug('arrPoint', res);
              page.set('arrInfo', {});
              page.set('fromGis', false);
            });
          }
          mergeRouteInfo(val, state);
        }
        if (val.mile) {
          page.set('routeMile', val.mile);
        }
        break;
      }
      case 'productType': {
        // 设置线路
        !src &&
          state.productLineShow &&
          getProductLine(state.startInfo, state.arrInfo, val, state.cid, res => setProduct(res, 'productType'));
        break;
      }
      case 'arrPoint': {
        // 修改目的网点 如果目的网点和出发点不同 送货方式为直送则改为空
        if (val && val.company_id && directDelivery[state.deliveryMode] && val.company_id !== state.startPointId) {
          page.set('deliveryMode', '');
        }
        if (src === 'arrInfo' || src === 'cee') break;
        // 读取时效 设置标准到货日期
        calcXpcdArrDate(val, state.billingDate, state);

        if (state.routeShow) {
          // 设置路由
          if (val.route_nodes) {
            store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'route', val: getRoute(val) } });
          } else {
            const startPointName = state.startPoint ? state.startPoint.short_name : '';
            const arrPointName = val ? val.short_name : '';
            const route =
              startPointName && arrPointName
                ? getRoute({ route_nick: `${startPointName}->${arrPointName}` })
                : getRoute({});
            store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'route', val: route } });
          }
        }
        mergeRouteInfo(val, state);
        if (val.mile) {
          page.set('routeMile', val.mile);
        }
        break;
      }
      case 'route': {
        if (val && val.route_id && directDelivery[state.deliveryMode]) {
          page.set('deliveryMode', '');
        }
        // 入库网点 监测路由
        val && val.route && handleStockPointData(val.route);

        break;
      }
      case 'transMode': {
        if (val && val !== 3 && (directDelivery[state.deliveryMode] || withoutArr[state.deliveryMode])) {
          page.set('deliveryMode', '');
        }
        break;
      }
      case 'productLine': {
        const timeData = state.xpcdArrDateConsistDelta === 'route_time' ? state.arrInfo : val;
        calcXpcdArrDate(timeData, state.billingDate, state);
        break;
      }
      case 'serviceType': {
        // 修改送货方式 !!!服务类型与送货方式相互影响 警惕死循环!!!
        if (src === 'deliveryMode' || src === 'pickup') break;
        const deliveryMode = val === 'door_door' || val === 'site_door' ? 'pure_delivery' : 'self_pick';
        const hasChange =
          deliveryMode === 'self_pick' ||
          state.deliveryMode === 'self_pick' ||
          state.deliveryMode === '' ||
          state.deliveryMode === null;
        hasChange &&
          store.dispatch({
            type: SET_ORDER_DATA,
            payload: { key: 'deliveryMode', val: deliveryMode },
            src: 'serviceType',
          });
        break;
      }
      case 'ceeAddrInfo': {
        if (setting.cee_addr_delivery.checked && val && val.show_val && state.deliveryMode === 'self_pick') {
          confirm(WARN, `当前${orderDict}填写了${ot('收货地址')}，是否将${ot('送货方式')}由自提改为送货？`).then(
            result => {
              result &&
                store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'deliveryMode', val: 'pure_delivery' } });
            },
          );
        }
        break;
      }
      case 'deliveryMode': {
        !state.truckTypeSettingRequired &&
          state.needTruck &&
          page.merge('truckTypeRequired', val === 'direct_complete');
        !state.truckLengthSettingRequired &&
          state.needTruck &&
          page.merge('truckLengthRequired', val === 'direct_complete');

        // 直送时 修改目的网点为出发网点 请空路由
        if (val !== state.deliveryMode && directDelivery[val]) {
          state.arrPoint && page.set('arrPoint', state.companyInfo);
          state.routeShow && page.set('route', getRoute({}));
          state.transMode && page.set('transMode', 3);
          page.merge('routeRequired', false);
        } else {
          state.routeSettingRequired && page.merge('routeRequired', true);
        }

        if (withoutArr[val] && +state.transMode !== 3) {
          state.arrPoint && page.set('arrPoint', '');
          state.routeShow && page.set('route', getRoute({}));
          state.transMode && page.set('transMode', 3);
        }

        // 送货方式变化时提醒
        if (state.warnAllowCheck && +state.coDeliveryF && val === 'self_pick') {
          confirm(WARN, `当前${orderDict}有${ot('送货费')}，是否将${ot('送货方式')}由自提改为送货？`).then(result => {
            result && store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'deliveryMode', val: 'pure_delivery' } });
          });
        } else {
          // 更改服务类型  !!!服务类型与送货方式相互影响 警惕死循环!!!
          if (src === 'serviceType') break;
          const serviceType = getServiceType(state.pickup, val);
          const hasChange = serviceType !== state.serviceType;
          hasChange &&
            store.dispatch({
              type: SET_ORDER_DATA,
              payload: { key: 'serviceType', val: serviceType },
              src: 'deliveryMode',
            });
        }
        break;
      }
      case 'pickup': {
        if (src === 'serviceType') break;
        const serviceType = getServiceType(val, state.deliveryMode);
        const hasChange = serviceType !== state.serviceType;
        hasChange &&
          store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'serviceType', val: serviceType }, src: 'pickup' });
        break;
      }
      case 'goods': {
        goodsNumCustom &&
          effectGoodsNumKeys.num &&
          action.changes &&
          action.changes.num &&
          getGoodsNum(res => {
            res && page.set('goodsNum', res.goods_num && res.goods_num.trim ? res.goods_num.trim() : res.goods_num);
          });
        break;
      }
      case 'stockPoint': {
        if (val && !state.isOpenPayment) {
          const routeVal = state.route;
          if (routeVal && routeVal.route && routeVal.route.length) {
            !routeVal.route.find(v => +v.company_id === +val) && showInfo(WARN, '入库网点不在计划路由内！');
          }
        }
        break;
      }
      case 'obProjectId': {
        // 选择项目带出客户
        if (val && val.customer_proj_id) {
          const customerProject = {
            id: val.customer_proj_id,
            name: val.customer_proj_name,
            remark: val.customer_proj_remark,
            state: val.customer_proj_state,
          };
          page.set('obCustomerId', customerProject);
        }
        break;
      }
      default:
        break;
    }

    // 重新请求货号
    goodsNumCustom &&
      effectGoodsNumKeys[key] &&
      getGoodsNum(res => {
        res && page.set('goodsNum', res.goods_num && res.goods_num.trim ? res.goods_num.trim() : res.goods_num);
      });

    // 费用项的值变化时影响送货方式 根据设置 XXX非零送货方式为xxx
    if (effectDeliveryModeKeys[key] && setting[effectDeliveryModeKeys[key]].checked && +val) {
      store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'deliveryMode', val: feeToDelivery[key] } });
    }
  };

  return next => action => {
    switch (action.type) {
      case SET_ORDER_DATA:
        onChange(action.payload.key, action.payload.val, action.src, action);
        break;
      case CEE_ARR_MATCHED: {
        // 目的网点匹配时
        const result = action.payload;
        if (Array.isArray(result)) {
          // 匹配多个路由 打开目的网点下拉
          const arrPointInput = page.wrap.querySelector('.traffic-arr-point input');
          arrPointInput && arrPointInput.focus();
        } else if (result.company_id) {
          // 匹配单个路由 改变目的网点
          store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'arrPoint', val: result } });
        }
        break;
      }
      case SELECT_CEE_SUG: {
        const state = store.getState();
        const { data } = action.payload;
        // 选择收货人带出目的网点
        if (state.ceeRelateArrPoint) {
          const point = getCeeRelateArrPoint(data);
          page.set('arrPoint', point, 'cee');
        }
        break;
      }
      case CO_DELIVERY_FEE_BLUR: {
        // 修改送货费时提示送货方式
        const state = store.getState();
        const coDeliveryF = action.payload;
        if (state.warnAllowCheck && +coDeliveryF && state.deliveryMode === 'self_pick') {
          confirm(WARN, `当前运单有${ot('送货费')}，是否将${ot('送货方式')}由自提改为送货？`).then(result => {
            result && store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'deliveryMode', val: 'pure_delivery' } });
            const input = page.wrap.querySelector('[data-path=co_delivery_f]');
            page.path && page.path.next(input, 39);
          });
        }
        break;
      }
      case FETCH_ORDER_DATA: {
        setTimeout(() => {
          const state = store.getState();
          if (
            page.props.usedFor === 'create' &&
            state.reservationNum &&
            state.startInfo &&
            state.startInfo.show_val &&
            state.arrInfo &&
            state.arrInfo.show_val
          ) {
            // 设置线路
            getProductLine(state.startInfo, state.arrInfo, state.productType, state.cid, res => {
              (state.productLine || res.productLine) &&
                store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'productLine', val: res.productLine } });
              store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'productTypeEnum', val: res.productTypeEnum } });
              res.productType &&
                store.dispatch({
                  type: SET_ORDER_DATA,
                  payload: { key: 'productType', val: res.productType },
                  src: 'startInfo',
                });
            });
          }
        });
        break;
      }
      default:
        break;
    }
    next(action);
  };
};

export default trafficMiddleware;
