exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".split-reservation .process{line-height:26px;font-size:14px}.split-reservation .process .fn-icon{font-size:18px;width:auto;height:auto;line-height:1;margin-right:8px;margin-left:10px;vertical-align:-2px}.split-reservation .fn-icon-info-o{color:#69b1f1}.split-reservation .fn-icon-check-o{color:#31c481}.split-reservation .fn-icon-error-o{color:#ff6f6b}.split-reservation .fn-table-wrap{max-height:calc(100vh - 240px);overflow-y:auto;margin-top:20px}.split-reservation table{table-layout:auto}.split-reservation table th{color:#222}.split-reservation .status-bar .fn-icon-info-o+span{color:#008fff}.split-reservation .status-bar .fn-icon-error-o+span{color:#f12222}", ""]);

// exports
exports.locals = {
	"prefixCls": "split-reservation",
	"split-reservation": "split-reservation",
	"process": "process",
	"fn-icon": "fn-icon",
	"fn-icon-info-o": "fn-icon-info-o",
	"fn-icon-check-o": "fn-icon-check-o",
	"fn-icon-error-o": "fn-icon-error-o",
	"fn-table-wrap": "fn-table-wrap",
	"status-bar": "status-bar"
};