exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".order-editor-header{margin-bottom:2px}.order-editor-header>h1{width:216px;margin:auto;font-size:24px;color:#333;line-height:30px;letter-spacing:16px;text-indent:16px;white-space:nowrap;font-weight:400;text-align:center}.order-editor-header>h1:after{content:\"\";display:block;height:4px;margin-top:6px;border-top:1px solid #333;border-bottom:1px solid #333}.order-editor-header>h1~div{margin-top:-22px}.order-editor-header .bill-date,.order-editor-header .co-num,.order-editor-header .creator{height:28px}.order-editor-header .bill-date label,.order-editor-header .co-num label,.order-editor-header .creator label{vertical-align:middle}.order-editor-header .bill-date .fn-label:after,.order-editor-header .creator .fn-label:after{content:\"\"}.order-editor-header .bill-date i,.order-editor-header .creator i{width:auto;font-size:15px;height:18px;line-height:1;color:#537287;padding-left:0;padding-right:2px;margin-top:5px}.order-editor-header .creator span{display:inline-block;vertical-align:middle;font-size:14px}.order-editor-header .bill-date .not-today .ant-calendar-picker-input{color:blue}.order-editor-header .co-num .fn-input-pure-wrap{width:160px}.order-editor-header .co-num .fn-input-pure{color:red;font-size:16px}.order-editor-header .back-journey{color:#333;margin-left:2px}.order-editor-header .bill-date .fn-input-pure-wrap{width:100px}.order-editor-header .bill-date .ant-calendar-picker{width:140px!important}.order-editor-header .bill-date{margin-left:10px}", ""]);

// exports
exports.locals = {
	"prefixCls": "order-editor-header",
	"order-editor-header": "order-editor-header",
	"co-num": "co-num",
	"creator": "creator",
	"bill-date": "bill-date",
	"fn-label": "fn-label",
	"not-today": "not-today",
	"ant-calendar-picker-input": "ant-calendar-picker-input",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"fn-input-pure": "fn-input-pure",
	"back-journey": "back-journey",
	"ant-calendar-picker": "ant-calendar-picker"
};