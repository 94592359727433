exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".point-cost-info-edit-modal .tip{display:flex;align-items:center}.point-cost-info-edit-modal .tip .fn-icon{flex:0 0;color:#69b1f1;font-size:18px;width:auto;height:auto}.point-cost-info-edit-modal .tip p{flex:1 1;margin-left:10px;line-height:22px;font-size:14px}.point-cost-info-edit-modal .fields{margin-top:20px}.point-cost-info-edit-modal .fields>div{width:50%;display:inline-flex;align-items:center;margin-bottom:10px}.point-cost-info-edit-modal .fields>div>label{flex:0 0 100px;text-align:right;padding-right:5px;color:#333}.point-cost-info-edit-modal .fields>div .fn-input-pure-wrap{flex:1 1 100%;height:28px}.point-cost-info-edit-modal .fields>div .input-tip-paid{margin-top:-14px;right:3px}", ""]);

// exports
exports.locals = {
	"prefixCls": "point-cost-info-edit-modal",
	"point-cost-info-edit-modal": "point-cost-info-edit-modal",
	"tip": "tip",
	"fn-icon": "fn-icon",
	"fields": "fields",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"input-tip-paid": "input-tip-paid"
};