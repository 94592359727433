/**
 * Created by wangnaihe on 2017/8/25.
 */

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { ModalDialog, Button, PureCheckbox as Checkbox } from 'components';
import { prefixCls } from './index.scss';

export default class MessageTplModal extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    selected: PropTypes.array,
    close: PropTypes.func,
    onConfirm: PropTypes.func,
  };

  state = {
    selected: this.props.selected,
    checkAll: this.props.data.length === this.props.selected.length,
  };

  checkAll = e => {
    const { checked } = e.target;
    const data = this.props.data || [];
    if (checked) {
      this.setState({ selected: data, checkAll: checked });
    } else {
      this.setState({ selected: [], checkAll: checked });
    }
  };

  check = row => {
    const selected = [...this.state.selected];
    const index = selected.indexOf(row);
    const dataLength = this.props.data.length;
    if (index > -1) {
      const setStateData = {};
      if (dataLength === selected.length) setStateData.checkAll = false;
      selected.splice(index, 1);
      setStateData.selected = selected;
      this.setState(setStateData);
    } else {
      const setStateData = {};
      selected.push(row);
      if (dataLength === selected.length) setStateData.checkAll = true;
      setStateData.selected = selected;
      this.setState(setStateData);
    }
  };

  confirm = () => {
    this.props.onConfirm && this.props.onConfirm(this.state.selected, this.msgModelDialog);
  };

  render() {
    const data = this.props.data || [];
    const { selected } = this.state;
    const { checkAll } = this.state;
    const content = (
      <table className="fn-table-a fn-table-a__wrap msg-table">
        <thead>
          <tr>
            <th width={30} onClick={this.checkAll}>
              <Checkbox checked={checkAll} onChange={this.checkAll} />
            </th>
            <th width={100}>短信类型</th>
            <th width={90}>接收方</th>
            <th>短信模版</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td onClick={() => this.check(row)}>
                <Checkbox checked={selected.some(item => item === row)} onChange={() => this.check(row)} />
              </td>
              <td className="al td1">{row.typeDesc}</td>
              <td className="al td2">{row.receiver}</td>
              <td className="al">{row.template}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
    const bottom = [
      <Button key={1} type="primary" onClick={this.confirm}>
        确定
      </Button>,
      <Button key={2} onClick={() => this.msgModelDialog.handleHide()}>
        取消
      </Button>,
    ];
    return (
      <ModalDialog
        ref={r => (this.msgModelDialog = r)}
        isShow
        closable
        maskClosable
        close={this.props.close}
        content={content}
        bottom={bottom}
        contentStyle={{ width: '550px' }}
        title="短信通知"
        classname={prefixCls}
      />
    );
  }
}
