exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".order-editor-profit{width:100%;table-layout:fixed;font-size:14px;margin-top:6px;border:0}.order-editor-profit tr:last-child td{border-bottom:0}.order-editor-profit td{line-height:17px;padding:0 4px;height:60px;border:1px solid #78aadd}.order-editor-profit td i{color:red;font-weight:700}.order-editor-profit td:first-child{width:140px;background:#f2f9ff;border-right:1px solid #b9ccd7;border-left:0;text-align:center}", ""]);

// exports
exports.locals = {
	"prefixCls": "order-editor-profit",
	"order-editor-profit": "order-editor-profit"
};