/**
 * Created by wangnaihe on 2017/6/19.
 */
import React from 'react';
import { Tooltip } from 'components';
import { prefixCls } from './index.scss';

// 回单详细信息
const renderReceiptInfo = (orderData, flag) => {
  const data = orderData[`${flag}_receipt_data`];
  const userTitle = flag === 'cor' ? '发放' : '回收';
  const key = flag === 'cor' ? 'paid' : 'recp';
  return (
    <div className="state-stamp-tip">
      <dl>
        <dt>{userTitle}人 : </dt>
        <dd>
          <p>
            <span className="text-blue">{data[`${key}_user`]}</span>
          </p>
        </dd>
      </dl>
      <dl>
        <dt>{userTitle}时间 : </dt>
        <dd>
          <p>
            <span className="text-blue">{data[`${key}_time`]}</span>
          </p>
        </dd>
      </dl>
      <dl>
        <dt>{userTitle}数量 : </dt>
        <dd>
          <p>
            <span className="text-blue">{data[`${key}_num`]}</span>
          </p>
        </dd>
      </dl>
    </div>
  );
};

// 结算详细信息
const renderSettleInfo = (orderData, flag, enums) => {
  const transFee = orderData[`${flag}_pay_settle_info`];
  const transFeePayed = Object.entries(transFee.pay_ed || {}).filter(item => item[1] !== 0);
  const transFeeToPay = Object.entries(transFee.pay_to || {}).filter(item => item[1] !== 0);
  const payed = transFeePayed.map(([key, value]) => (
    <span className="text-blue" key={key}>
      {enums[key].display || ''}
      <span>{value}</span>元；
    </span>
  ));
  const unpay = transFeeToPay.map(([key, value]) => (
    <span className="text-red" key={key}>
      {enums[key].display || ''}
      <span>{value}</span>元；
    </span>
  ));
  return (
    <div className="state-stamp-tip">
      <dl>
        <dt>结算人 : </dt>
        <dd>
          <p>
            <span className="text-blue">{transFee.settle_user}</span>
          </p>
        </dd>
      </dl>
      <dl>
        <dt>结算时间 : </dt>
        <dd>
          <p>
            <span className="text-blue">{transFee.settle_time}</span>
          </p>
        </dd>
      </dl>
      {payed.length > 0 && (
        <dl>
          <dt>已结金额 : </dt>
          <dd>
            <p>{payed}</p>
          </dd>
        </dl>
      )}
      {unpay.length > 0 && (
        <dl>
          <dt>未结金额 : </dt>
          <dd>
            <p>{unpay}</p>
          </dd>
        </dl>
      )}
    </div>
  );
};

// 货款提示信息
const renderDeliveryInfo = (orderData, flag) => {
  const deliveryFee = orderData[`${flag}_delivery_fee`];
  const userTitle = flag === 'cor' ? '发放' : '回收';
  const title = flag === 'cor' ? '发' : '收';
  const key = flag === 'cor' ? 'co_delivery_paid' : 'co_delivery_receipt';
  return (
    <div className="state-stamp-tip">
      <dl>
        <dt>{userTitle}人: </dt>
        <dd>
          <p>
            <span className="text-blue">{deliveryFee.settle_user}</span>
          </p>
        </dd>
      </dl>
      <dl>
        <dt>{userTitle}时间 : </dt>
        <dd>
          <p>
            <span className="text-blue">{deliveryFee.settle_time}</span>
          </p>
        </dd>
      </dl>
      {deliveryFee.pay_ed && deliveryFee.pay_ed[key] !== 0 && (
        <dl>
          <dt>已{title}金额 : </dt>
          <dd>
            <p>
              <span className="text-blue">{deliveryFee.pay_ed[key]}元</span>
            </p>
          </dd>
        </dl>
      )}
      {deliveryFee.pay_to && deliveryFee.pay_to[key] !== 0 && (
        <dl>
          <dt>未{title}金额 : </dt>
          <dd>
            <p>
              <span className="text-red">{deliveryFee.pay_to[key]}元</span>
            </p>
          </dd>
        </dl>
      )}
    </div>
  );
};
const renderDeliveryInfoTicket = extData => {
  const ticket = extData.ticket || {};
  const { bank = '', account_num: account = '', payee = '' } = ticket;
  const userTitle = '收款';
  return (
    <div className="state-stamp-tip">
      <dl>
        <dt>{userTitle}人: </dt>
        <dd>
          <p>
            <span className="text-blue">{payee}</span>
          </p>
        </dd>
      </dl>
      <dl>
        <dt>{userTitle}账号 : </dt>
        <dd>
          <p>
            <span className="text-blue">{account}</span>
          </p>
        </dd>
      </dl>
      <dl>
        <dt>开户行 : </dt>
        <dd>
          <p>
            <span className="text-blue">{bank}</span>
          </p>
        </dd>
      </dl>
    </div>
  );
};
const renderToolTips = (content, tipsContent) => (
  <Tooltip type="info" content={tipsContent}>
    {content}
  </Tooltip>
);

function renderStateStamp(page) {
  return function () {
    const state = page.store.getState();
    const orderFlags = state.origin.order_flags || {};
    const orderData = state.origin.order_data;
    const payModeEnum = state.enums.pay_mode;
    const { ext } = state.origin;
    // 财运通1.0不展示水印,1.0 status_stamp_show返回false, 2.0 status_stamp_show返回true
    if (page.props.usedFor !== 'detail' || !orderFlags.status_stamp_show) return null;
    return (
      <div className={prefixCls}>
        <div className="cor-state">
          {+orderFlags.cor_total_price_settle_flag === 20 &&
            renderToolTips(<i className="settle-stamp complete" />, renderSettleInfo(orderData, 'cor', payModeEnum))}
          {+orderFlags.cor_total_price_settle_flag === 15 &&
            renderToolTips(<i className="settle-stamp part" />, renderSettleInfo(orderData, 'cor', payModeEnum))}
          {+orderFlags.cor_total_price_settle_flag === 10 && renderToolTips(<i className="settle-stamp" />)}
          {orderFlags.cor_receipt_op_flag === 20 &&
            renderToolTips(<i className="receipt-stamp complete" />, renderReceiptInfo(orderData, 'cor'))}
          {orderFlags.cor_receipt_op_flag === 10 && renderToolTips(<i className="receipt-stamp" />)}
          {orderFlags.cor_co_delivery_settle_flag === 20 &&
            renderToolTips(<i className="delivery-stamp complete" />, renderDeliveryInfo(orderData, 'cor'))}
          {orderFlags.cor_co_delivery_settle_flag === 10 && renderToolTips(<i className="delivery-stamp" />)}
          {orderFlags.is_ticket === 1 &&
            renderToolTips(<i className="is-ticket" />, renderDeliveryInfoTicket(ext, 'cor'))}
        </div>
        <div className="cee-state">
          {orderFlags.cee_total_price_settle_flag === 20 &&
            renderToolTips(<i className="settle-stamp complete" />, renderSettleInfo(orderData, 'cee', payModeEnum))}
          {orderFlags.cee_total_price_settle_flag === 15 &&
            renderToolTips(<i className="settle-stamp part" />, renderSettleInfo(orderData, 'cee', payModeEnum))}
          {orderFlags.cee_total_price_settle_flag === 10 && renderToolTips(<i className="settle-stamp" />)}
          {orderFlags.cee_receipt_op_flag === 20 &&
            renderToolTips(<i className="cee-receipt-stamp complete" />, renderReceiptInfo(orderData, 'cee'))}
          {orderFlags.cee_receipt_op_flag === 10 && renderToolTips(<i className="cee-receipt-stamp" />)}
          {orderFlags.cee_co_delivery_settle_flag === 20 &&
            renderToolTips(<i className="cee-delivery-stamp complete" />, renderDeliveryInfo(orderData, 'cee'))}
          {orderFlags.cee_co_delivery_settle_flag === 10 && renderToolTips(<i className="cee-delivery-stamp" />)}
        </div>
      </div>
    );
  };
}
export default renderStateStamp;
