import { PopUp, ShowInfo } from 'components';
import { ERROR, INFO, WARN } from 'constants';
import actions from 'actions';
import { fetchJAVA, getPageKey, createTip, throttle, opTips } from 'utils';
import CarriersOperat from 'operation/listOperation/CarriersOperat';
import CommentDialog from 'components/commoncomponents/commentDialog';
// import emitter from 'utils/emitter'
// import { getPageKey } from 'utils/pageInfo'
const titleNameJons = { 1: '平台承运商', 2: '外部承运商', 3: '内部承运商' };
export const addCarriers = function (that, callback) {
  const carriersType = { add_carriers_group: '1', add_carriers: '2', add_inner_carriers: '3' };
  const pageKey = getPageKey().pageConfig.key;
  const _req = {
    carrier_info: {
      carrier_id: -1,
    },
    data: {
      carrier_id: -1,
    },
    op_type: 'add',
    trans_type: carriersType[callback],
  };
  // 没有callback， 默认刷新列表
  let sucCallback = callback;
  if (!sucCallback || typeof sucCallback !== 'function') sucCallback = throttle(() => that.handleRefreshData(), 1000);
  actions.companyServer.postCarrierInfo(_req).then(res => {
    if (res.type === 'API_SUCCESS') {
      if (res.data.errno === 0) {
        const { carrier_info = {} } = res.data.res;
        const _parm = {
          isShow: true,
          pageType: 'carriersMangerList',
          opType: 'add',
          titleName: `新增${titleNameJons[carrier_info.trans_type]}` || '新增承运商',
          dragerContainer: that,
          dataAM: res,
          callback: sucCallback,
          that,
          transType: _req.trans_type,
        };
        const parm = {
          parm: _parm,
          popName: `operatSlideDrag_${pageKey}`,
        };
        const dialog = new PopUp(CarriersOperat, parm);
        dialog.show();
      } else {
        createTip(res.data.errmsg, ERROR).show();
      }
    } else {
      createTip('网络错误！', ERROR).show();
    }
  });
};
const addCarriersGms = (that, callback) => {
  const pageKey = getPageKey().pageConfig.key;
  const _req = {
    carrier_info: {
      carrier_id: -1,
    },
    data: {
      carrier_id: -1,
    },
    op_type: 'add',
  };
  // 没有callback， 默认刷新列表
  let sucCallback = callback;
  if (!sucCallback || typeof sucCallback !== 'function') sucCallback = throttle(() => that.handleRefreshData(), 1000);
  actions.companyServer.postCarrierInfo(_req).then(res => {
    if (res.type === 'API_SUCCESS') {
      if (res.data.errno === 0) {
        const { carrier_info = {} } = res.data.res;
        const _parm = {
          isShow: true,
          pageType: 'carriersMangerList',
          opType: 'add',
          titleName: `新增${titleNameJons[carrier_info.trans_type]}` || '新增承运商',
          dragerContainer: that,
          dataAM: res,
          callback: sucCallback,
          that,
          transType: _req.trans_type,
        };
        const parm = {
          parm: _parm,
          popName: `operatSlideDrag_${pageKey}`,
        };
        const dialog = new PopUp(CarriersOperat, parm);
        dialog.show();
      } else {
        createTip(res.data.errmsg, ERROR).show();
      }
    } else {
      createTip('网络错误！', ERROR).show();
    }
  });
};
export const updateCarriers = function (that, callback) {
  const pageKey = getPageKey().pageConfig.key;
  const _comIds = that.vTable.getStateSelectes();

  if (_comIds.length < 1) {
    createTip('请选择承运商！', ERROR).show();
    return false;
  }

  const table = that.getData();
  const drComId = table[_comIds[0]].carrier_id;
  const _req = {
    carrier_info: {
      carrier_id: drComId,
    },
    data: {
      carrier_id: drComId,
    },
    op_type: 'update',
  };
  let sucCallback = callback;
  if (!sucCallback || typeof sucCallback !== 'function') sucCallback = throttle(() => that.handleRefreshData(), 1000);
  actions.companyServer.postCarrierInfo(_req).then(res => {
    if (res.type === 'API_SUCCESS') {
      if (res.data.errno === 0) {
        const { carrier_info = {} } = res.data.res;
        const _parm = {
          isShow: true,
          pageType: 'carriersMangerList',
          opType: 'update',
          titleName: `修改${titleNameJons[carrier_info.trans_type]}` || '修改承运商',
          dragerContainer: that,
          comId: drComId,
          callback: sucCallback,
          that,
        };
        const parm = {
          parm: _parm,
          popName: `operatSlideDrag_${pageKey}`,
        };
        const dialog = new PopUp(CarriersOperat, parm);
        dialog.show();
      } else {
        createTip(res.data.errmsg, ERROR).show();
      }
    } else {
      createTip('网络错误！', ERROR).show();
    }
  });
};

export const columnCarrierName = function (that, ele) {
  const pageKey = getPageKey().pageConfig.key;
  const table = that.getData();
  console.log(table, 'table');
  const drComId = table[ele].carrier_id;
  const time = table[ele].appraise_t;
  console.log(time);
  const _req = {
    carrier_info: {
      carrier_id: drComId,
    },
    data: {
      carrier_id: drComId,
    },
    op_type: 'view',
  };
  actions.companyServer.postCarrierInfo(_req).then(res => {
    if (res.type === 'API_SUCCESS' && res.data.errno === 0) {
      if (res.data.errno === 0) {
        const { carrier_info = {} } = res.data.res;
        const _parm = {
          isShow: true,
          pageType: 'carriersMangerList',
          opType: 'view',
          titleName: `查看${titleNameJons[carrier_info.trans_type]}` || '查看承运商',
          dragerContainer: that,
          comId: drComId,
          that,
        };
        const parm = {
          parm: _parm,
          popName: `operatSlideDrag_${pageKey}`,
        };
        const dialog = new PopUp(CarriersOperat, parm);
        dialog.show();
      } else {
        createTip(res.data.errmsg, ERROR).show();
      }
    } else {
      createTip('网络错误！', ERROR).show();
    }
  });
};

export const deleteCarriers = function (that) {
  const _that = that;
  const _comIds = that.vTable.getStateSelectes();

  if (_comIds.length < 1) {
    createTip('请选择要删除的承运商！', ERROR).show();
    return false;
  }

  const table = that.vTable.getStateCache();
  const _drComIds = [];
  for (const val of _comIds) {
    _drComIds.push(table[val].carrier_id);
  }

  let _req = {
    carrier_info: {
      comId: _drComIds,
    },
    data: {
      carrier_ids: _drComIds,
    },
    opType: 'delete',
    is_check: 1,
  };
  const popup = new PopUp(ShowInfo, {
    classname: 'del-customer',
    isShow: true,
    type: WARN,
    content: '您确定删除吗？',
    autoDestroy: true,
    title: '删除',
    simpleMode: {
      confirm: thisDialog => {
        thisDialog.changeBtnLoadingStatus('confirm', true);
        actions.companyServer.postCarriersMangerAM(_req).then(res => {
          opTips({
            resData: res.data,
            opType: '删除',
            tips: '删除',
            orderType: '承运商',
            sucCallback() {
              thisDialog.handleHide();
              setTimeout(() => {
                _that.handleRefreshData();
              }, 1500);
            },
            ext: {
              failedCallback() {
                thisDialog.handleHide();
              },
            },
            continueCallback(sucId, cb) {
              cb();
              _req = {
                carrier_info: {
                  comId: sucId,
                },
                data: {
                  carrier_ids: sucId,
                },
                opType: 'delete',
              };
              actions.companyServer.postCarriersMangerAM(_req).then(res2 => {
                thisDialog.handleHide();
                if (res.type === 'API_SUCCESS') {
                  if (res.data.errno === 0) {
                    createTip('删除成功！', INFO).show();
                    // _that.handleRefreshData()
                    throttle(() => _that.handleRefreshData(), 1500)();
                    thisDialog.handleHide();
                  } else {
                    createTip(res.data.errmsg, ERROR).show();
                    thisDialog.changeBtnLoadingStatus('confirm', false);
                  }
                } else {
                  createTip('删除失败，请重新删除！', ERROR).show();
                  thisDialog.changeBtnLoadingStatus('confirm', false);
                }
              });
            },
          });
        });
      },
      cancel: thisDialog => thisDialog.handleHide(),
    },
    closable: true,
    maskClosable: true,
  });
  popup.show();
};
export const commentAppraise = function (that) {
  const _comIds = that.vTable.getStateSelectes();
  const selectData = [];
  const needData = [];
  _comIds.map(item => selectData.push(that.getData()[item]));
  selectData.map(item =>
    needData.push({
      car_batch: item.carrier_no,
      id: item.id,
    }),
  );
  const ids = that.getSelectesKey('id');
  if (ids.length < 1) {
    createTip('请选择要一条评价！', ERROR).show();
    return false;
  }
  const req = {
    appraise_type: 'carrier',
    id: ids[0],
  };
  console.log('res,mee');
  fetchJAVA('/cmm-batch/appraise/pre', {
    method: 'POST',
    body: { req },
  }).then(res => {
    if (res.errno === 0) {
      const popup = new PopUp(CommentDialog, {
        isShow: true,
        title: '评价承运商',
        subjective: false,
        data: res.res.subjective_appraise,
        id: ids[0],
        appraise_id: res.res.appraise_id,
        appraise_type: 'carrier',
        needData,
        dialogTitle: '承运商编号',
        batch: needData.length !== 1,
        tips: '评价承运商',
        finishCallback: (...rest) => {
          setTimeout(() => {
            that.handleRefreshData();
          }, 2000);
        },
        escBind: false,
      });
      popup.show();
    } else {
      new PopUp(ShowInfo, {
        type: WARN,
        title: '提示', // default 提示
        content: res.errmsg, // isrequire
        simpleMode: {
          confirm: async t => {
            t.handleHide();
          },
          cancel: t => t.handleHide(),
        },
      }).show();
    }
  });
};

export const columnAppraiseTtCount = function (that, ele) {
  const table = that.getData();
  const { id } = table[ele];
  console.log(ele, id, table[ele]);
  const urlData = {
    controller: 'Schedule',
    action: 'taskList',
  };
  const pack = {
    designateParam: { ignore_default: true },
    designateFilter: {
      b_tr_team_id: id,
      show_batch_flags: ['appraised_carrier'],
    },
    initFilterHeader: '',
  };
  urlData.injectProps = pack;
  window.$app.direct(urlData);
};
export function init(emt) {
  emt.on('addCarriers', addCarriers);
  emt.on('addCarriersGroup', addCarriers);
  emt.on('addInnerCarriers', addCarriers);
  emt.on('addCarriersGms', addCarriersGms);
  emt.on('updateCarriers', updateCarriers);
  emt.on('deleteCarriers', deleteCarriers);
  emt.on('carrierAppraise', commentAppraise);
  emt.on('carrierCarrierName', columnCarrierName);
  emt.on('carrierCarrierAppraiseTtCount', columnAppraiseTtCount);
}
