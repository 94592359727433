exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".order-editor-collect{flex:1 13%;margin-left:4px;border-left:1px solid #78aadd}.order-editor-collect .fn-icon-pay-way{float:right;width:auto;font-size:20px;margin-right:6px;margin-top:10px;color:#0097cf}.distribute-modal>div{display:inline-block;margin:5px 10px 5px 5px}.distribute-modal>div .fn-label{width:64px;text-align:right;display:inline-block;padding-right:4px;vertical-align:middle}.distribute-modal>div .fn-input-pure-wrap{width:160px}", ""]);

// exports
exports.locals = {
	"prefixCls": "order-editor-collect",
	"order-editor-collect": "order-editor-collect",
	"fn-icon-pay-way": "fn-icon-pay-way",
	"distribute-modal": "distribute-modal",
	"fn-label": "fn-label",
	"fn-input-pure-wrap": "fn-input-pure-wrap"
};