import { round } from '../tool';

// 计算保价费
export const calcInsurance = (effectValue, insuranceSetting, maxDecimal, corInsuranceRatio) => {
  const type = insuranceSetting.checked;
  const defaultCalcRule = insuranceSetting.calc_rule;
  const { declaredValue } = effectValue;
  let min = 0;
  let rate = +corInsuranceRatio || 0;
  const rule = defaultCalcRule;
  if (!+declaredValue > 0) return 0;
  if (type === 'rate') {
    min = +insuranceSetting.min || 0;
    rate = (corInsuranceRatio ? +corInsuranceRatio : +insuranceSetting.rate) || 0;
  }
  // 为增加其他计算方式预留
  const actualVal = +Math.max(min, round((rate * +declaredValue) / 1000, rule)).toFixed(2);
  // eslint-disable-next-line no-nested-ternary
  return maxDecimal ? (+actualVal > +maxDecimal ? maxDecimal : actualVal) : actualVal;
};
