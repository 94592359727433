import { ot } from 'utils';

export const pathMap = {
  order_num: ot('运单号'),
  goods_num: ot('货号'),
  entrust_num: ot('委托单号'),
  device_id: ot('GPS编号'),
  start_point: ot('出发网点'),
  billing_date: ot('开单日期'),
  arr_point: ot(ot('目的网点')),
  trans_mode: ot(ot('中转方式')),
  service_type: ot('服务类型'),
  start: ot('发站'),
  rcv_stn: ot('收货点'),
  arr: ot('到站'),
  product_line: ot('线路'),
  thermosphere: ot('温层'),
  product_type: ot('产品类型'),
  xpcd_arr_date: ot('预到日期'),
  shud_arr_date: ot('应到日期'),
  delivery_mode: ot('送货方式'),
  truck_type: ot('需求车型'),
  truck_length: ot('需求车长'),
  co_delivery_mode: ot('发放方式'),
  card_holder: ot('开户人'),
  contact_phone: ot('开户电话'),
  open_bank: ot('开户行'),
  bank_card_num: ot('银行卡号'),
  shipper_phone: ot('会员电话'),
  cor_customer_no: ot('发货人客户编号'),
  cor_cate: ot('发货人客户类型'),
  cor_industry: ot('发货人客户行业'),
  cor_com: ot('发货单位'),
  cor_name: ot('发货人姓名'),
  cor_mobile: ot('发货人手机'),
  cor_phone: ot('发货人电话'),
  cor_id_num: ot('发货人身份证号'),
  cor_addr_info: ot('发货人地址'),
  cor_addr_remark: ot('发货人地址备注'),
  cor_pick_dist: ot('提货里程'),
  cor_remark: ot('发货客户备注'),
  cee_customer_no: ot('收货人客户编号'),
  cee_cate: ot('发货人客户类型'),
  cee_industry: ot('收货人客户行业'),
  cee_com: ot('收货单位'),
  cee_name: ot('收货人姓名'),
  cee_mobile: ot('收货人手机'),
  cee_phone: ot('收货人电话'),
  cee_id_num: ot('收货人身份证号'),
  cee_addr_info: ot('收货人地址'),
  cee_addr_remark: ot('收货人地址备注'),
  cee_pick_dist: ot('送货里程'),
  cee_remark: ot('收货客户备注'),
  name_1: `货物1${ot('名称')}`,
  code_1: `货物1${ot('代码')}`,
  pkg_1: `货物1${ot('包装方式')}`,
  pkg_service_1: `货物1${ot('包装服务')}`,
  suit_1: `货物1${ot('套数')}`,
  suit_unit_price_1: `货物1${ot('套数单价')}`,
  num_1: `货物1${ot('件数')}`,
  num_unit_price_1: `货物1${ot('件数单价')}`,
  num_per_tray_1: `货物1${ot('单托件数')}`,
  tray_count_1: `货物1${ot('托数')}`,
  weight_per_num_1: `货物1${ot('单件重量')}`,
  weight_1: `货物1${ot('重量')}`,
  practical_w_1: `货物1${ot('货物实际重量')}`,
  weight_unit_price_1: `货物1${ot('重量单价')}`,
  length_1: `货物1${ot('长')}`,
  width_1: `货物1${ot('宽')}`,
  high_1: `货物1${ot('高')}`,
  volume_per_num_1: `货物1${ot('单件体积')}`,
  volume_1: `货物1${ot('体积')}`,
  practical_v_1: `货物1${ot('货物实际体积')}`,
  volume_unit_price_1: `货物1${ot('体积单价')}`,
  unit_p_1: `货物1${ot('单价')}`,
  unit_p_unit_1: `货物1${ot('单价单位')}`,
  subtotal_price_1: `货物1${ot('运费小计')}`,
  count_per_num_1: `货物1${ot('单件数量')}`,
  count_1: `货物1${ot('数量')}`,
  unit_1: `货物1${ot('单位')}`,
  cat_1: `货物1${ot('货物类型')}`,
  spec_1: `货物1${ot('规格')}`,
  model_1: `货物1${ot('型号')}`,
  special_1: `货物1${ot('特殊货物')}`,
  need_insured: ot('需要保价'),
  value_add_service: ot('增值服务项'),
  tax_inc: ot('合计运费含税'),
  co_freight_f: ot('运费'),
  rebate: ot('回扣'),
  rebate_paid: ot('回扣已付'),
  rebate_name: ot('回扣人'),
  rebate_phone: ot('回扣手机'),
  co_delivery_f: ot('开单送货费'),
  co_pickup_f: ot('开单提货费'),
  co_receipt_f: ot('开单回单费'),
  co_handling_f: ot('开单装卸费'),
  co_upstairs_f: ot('开单上楼费'),
  declared_value: ot('声明价值'),
  co_insurance: ot('开单保价费'),
  co_trans_f: ot('开单中转费'),
  co_pkg_f: ot('开单包装费'),
  co_in_wh_f: ot('开单进仓费'),
  co_pay_adv: ot('开单垫付费'),
  co_delivery_adv: ot('开单垫付货款'),
  co_storage_f: ot('开单保管费'),
  co_install_f: ot('开单安装费'),
  co_make_f: ot('开单制单费'),
  co_declare_f: ot('开单报关费'),
  co_build_f: ot('开单港建费'),
  co_arr_service_f: ot('到付服务费'),
  co_misc_f: ot('开单其他费'),
  payment_mode: ot('运费支付方式'),
  pay_mode: ot('付款方式'),
  pay_billing: ot('现付'),
  pay_billing_paid: ot('现付已付'),
  pay_arrival: ot('到付'),
  pay_monthly: ot('月结'),
  pay_credit: ot('货到打卡'),
  pay_receipt: ot('回付'),
  pay_owed: ot('欠付'),
  pay_co_delivery: ot('货款扣'),
  co_delivery: ot('代收货款'),
  co_delivery_fee: ot('手续费'),
  co_delivery_release_days: ot('发放天数'),
  co_delivery_freight: ot('代收运费'),
  member_code: ot('会员号'),
  cashreturn: ot('现返'),
  cashreturn_paid: ot('现返已返'),
  cashreturn_name: ot('现返人'),
  cashreturn_phone: ot('现返手机'),
  discount: ot('欠返'),
  discount_name: ot('欠返人'),
  discount_phone: ot('欠返手机'),
  notice_delivery: ot('控货'),
  pickup: ot('上门提货'),
  invoice: ot('开具发票'),
  receipt_cat: ot('回单类型'),
  co_pickup_date: ot('预约提货时间'),
  urgency: ot('紧急度'),
  receipt_n: ot('回单数量'),
  receipt_num: ot('回单号'),
  receipt_require: ot('回单要求'),
  expected_release_time: ot('预计发放时间'),
  trsp_mode: ot('运输方式'),
  mgr_id: ot('经办人'),
  remark: ot('备注'),
  point_cost_info_b_billing_f_0: ot('现付运输费'),
  point_cost_info_b_fuel_card_f_0: ot('现付油卡费'),
  point_cost_info_b_receipt_f_0: ot('回付运输费'),
  point_cost_info_b_dr_name_0: ot('干线司机'),
  point_cost_info_b_dr_phone_0: ot('干线手机号'),
  point_cost_info_b_tr_num_0: ot('干线车牌号'),
  point_cost_info_pickup_f_0: ot('单票提货费'),
  point_cost_info_pickup_dr_name_0: ot('提货司机'),
  point_cost_info_pickup_dr_phone_0: ot('提货手机号'),
  point_cost_info_pickup_tr_num_0: ot('提货车牌号'),
  point_cost_info_b_loc_tr_f_0: ot('装车费'),
  point_cost_info_loc_tr_name_0: ot('装车联系人'),
  point_cost_info_loc_tr_phone_0: ot('装车手机号'),
  point_cost_info_tax_0: ot('税费'),
  point_cost_info_tax_r_0: ot('税率'),
  point_cost_info_tax_inc_0: ot('含税'),
  point_cost_info_b_insur_f_0: ot('保险费'),
  point_cost_info_b_loc_misc_f_0: ot('其他费'),
  point_cost_info_b_loc_name_0: ot('其他联系人'),
  point_cost_info_b_loc_phone_0: ot('其他手机号'),
  point_cost_info_b_arr_f_0: ot('到付运输费'),
  point_cost_info_od_loc_tr_f_0: ot('发站单票装车费'),
  point_cost_info_od_loc_misc_f_0: ot('发站单票其他费'),
  point_cost_info_od_rmt_unload_f_0: ot('到站单票卸车费'),
  point_cost_info_od_rmt_msc_f_0: ot('到站单票其他费'),
  point_cost_info_b_info_f_0: ot('信息费'),
  point_cost_info_b_info_name_0: ot('信息费联系人'),
  point_cost_info_b_info_phone_0: ot('信息费手机号'),
  point_cost_info_dispatch_dr_name_0: ot('配安司机'),
  point_cost_info_dispatch_dr_phone_0: ot('配安手机号'),
  point_cost_info_dispatch_f_0: ot('配安费'),
  point_cost_info_dispatch_remark_0: ot('配安备注'),
  point_cost_info_dispatch_st_0: ot('派单勾选框'),
  point_cost_info_pci_uld_f_0: ot('卸货费'),
  point_cost_info_pci_uld_grp_0: ot('卸货小组'),
  std_cost_std_mgr_id_0: ot('发货业务员'),
  std_cost_std_mn_f_0: ot('发货客户维护费'),
  std_cost_std_cee_mgr_id_0: ot('收货业务员'),
  std_cost_std_cee_mn_f_0: ot('收货客户维护费'),
  budget_info_budget_trans_f_0: ot('预算中转费'),
  budget_info_budget_delivery_f_0: ot('预算送货费'),
  budget_info_budget_handling_f_0: ot('预算装卸费'),
  budget_info_budget_upstairs_f_0: ot('预算上楼费'),
  budget_info_budget_misc_f_0: ot('预算其他费'),
  trans_info_trans_type_0: ot('中转类型'),
  trans_info_dn_com_id_0: ot('承运商'),
  trans_info_trans_route_0: ot('承运路由'),
  trans_info_address_0: ot('中转到站'),
  trans_info_dn_mgr_id_0: ot('承运经办人'),
  trans_info_trans_order_num_0: ot('中转单号'),
  trans_info_trans_num_unit_p_0: ot('中转件数单价'),
  trans_info_trans_weight_unit_p_0: ot('中转重量单价'),
  trans_info_trans_volume_unit_p_0: ot('中转体积单价'),
  trans_info_trans_freight_f_0: ot('中转费'),
  trans_info_trans_pay_mode_0: ot('中转付款方式'),
  trans_info_trans_pickup_f_0: ot('中转提货费'),
  trans_info_trans_in_wh_f_0: ot('中转进仓费'),
  trans_info_trans_delivery_f_0: ot('中转送货费'),
  trans_info_trans_handling_f_0: ot('中转装卸费'),
  trans_info_trans_upstairs_f_0: ot('中转上楼费'),
  trans_info_trans_pkg_f_0: ot('中转包装费'),
  trans_info_trans_storage_f_0: ot('中转保管费'),
  trans_info_trans_insurance_0: ot('中转保价费'),
  trans_info_trans_misc_f_0: ot('中转其他费'),
  trans_info_trans_f_0: ot('中转费合计'),
  trans_info_trans_pay_billing_0: ot('中转现付'),
  trans_info_trans_pay_arrival_0: ot('中转到付'),
  trans_info_trans_pay_receipt_0: ot('中转回付'),
  trans_info_trans_pay_monthly_0: ot('中转月结'),
  trans_info_trans_pay_owed_0: ot('中转欠付'),
  trans_info_trans_pay_co_delivery_0: ot('中转货款扣'),
  trans_info_trans_pay_credit_0: ot('中转货到打卡'),
  trans_info_remark_0: ot('中转备注'),
  trans_info_trans_t_0: ot('中转时间'),
  collect_print: ot('提交收银'),
  notice_message: ot('短信通知'),
  add_new_print: ot('新增并打印'),
  co_save_print: ot('保存并打印'),
  add_save: ot('保存并新增'),
  co_save: ot('保存'),
  trans_info_trans_n_0: ot('中转件数'),
  trans_info_trans_w_0: ot('运单中转重量'),
  trans_info_trans_v_0: ot('运单中转体积'),
  trans_info_trans_pickup_addr_remark_0: ot('中转提货地址备注'),
  trans_info_trans_pickup_addr_0: ot('中转提货地址'),
  trans_info_point_trans_dn_com_id_0: ot('承运网点'),
  trans_info_trans_pay_adv_0: ot('中转垫付费'),
  trans_info_trans_pick_type_0: ot('交接方式'),
  trans_info_trans_settle_money_0: ot('中转返款'),
  need_dispatch: ot('需要配安'),
  inner_remark: ot('内部备注'),
  insured: ot('投保'),
  hx_code: ot('需核销码'),
  stock_point: ot('入库网点'),
  route_mile: ot('路由里程'),
  back_journey: ot('返程'),
  ob_project_id: ot('项目名称'),
  ob_customer_id: ot('客户名称'),
  ob_plan_load_t: ot('预计装货时间'),
  ob_plan_truck_t: ot('预计发车时间'),
  ob_plan_arr_t: ot('预计到达时间'),
};
