import React from 'react';
import {
  typeIs,
  getPageKey,
  postData as gpostData,
  fromJS,
  filterArrayPropByKey,
  opTips,
  showInfo,
  sleep,
  checkSelected,
} from 'utils';
import fetchApi, { postJAVA } from 'utils/fetchApi';
import { WARN, CHECK, ERROR } from 'constants';
import { showDialog, createTip } from 'utils/checkInfo';
import { PopUp, ModalDialog, CardForm, Button, PopTip, SelectDrop, ShowInfo, TextArea, DialogDetail } from 'components';
import getBatchDetail from 'components/commoncomponents/BatchDetail';
import BatchOp from 'components/commoncomponents/BatchOp';
import { i18n } from '@/utils/i18n/index.js';
import { POPUP_CONFIRM_WINDOW } from '@/utils/i18n/constants.js';
import './orderCheckOp.scss';
import { dataAnalyticTrack } from '@/utils/dataAnalytic';

const batchOpConfig = require('operation/listOperation/batchOpConfig');

const mockData = require('./../BatchDetail/mockData');

const isFromPickClip = listPage => listPage && listPage.getReq().tab === 'pick_clip'; // 是否是挑单夹事件
const getData = (keys, that) => {
  const obj = {};
  keys.forEach(v => (obj[v] = []));
  if (isFromPickClip(that)) {
    const data = that.getData();
    data.forEach((item, index) => {
      keys.forEach(key => {
        if (!obj[key]) {
          obj[key] = [];
        }
        obj[key][index] = item[key];
      });
    });
  } else {
    keys.forEach(v => (obj[v] = that.getSelectesKey(v)));
  }
  return obj;
};
let detailDialog; // 详情页侧拉
function showPopTip(type, content, callback) {
  new PopUp(PopTip, {
    type,
    content,
    onClose: () => {
      if (typeof callback === 'function') callback();
    },
  }).show();
}
const createDialog = function ({ tab, type, isLine, fromEvents, rechargeDialogSave, tipTitle }) {
  const dialog = {};
  const rechargeContent = () => {
    // 创建表单元素
    const cardFromData = {
      account_name: {
        type: 'SelectDrop',
        labelProps: {
          isRequired: true,
          children: '审核意见：',
        },
        eventNames: ['handleSelected'],
        otherProps: {
          data: [
            { id: 1, name: '正常' },
            { id: 2, name: '错误' },
            { id: 3, name: '异常' },
          ],
          blurCheck: true,
          shouldFilter: false,
          tableHeader: { name: '' },
          showHeader: false,
          showKey: 'name',
          uniqueKey: 'id',
          isMultiple: false,
          selectOnly: true,
          readOnly: true,
          defaultSelected: [{ id: 1, name: '正常' }],
        },
      },
      amount: {
        type: 'Input',
        eventNames: ['onChange'],
        labelProps: { isRequired: true, children: '审核人：' },
        width: '450px',
        otherProps: { disabled: true, type: 'text', defaultValue: window.user_info.name, style: { width: '300px' } },
      },
      remark: {
        type: 'TextArea',
        eventNames: ['onChange'],
        labelProps: { children: '审核备注：', style: { verticalAlign: 'top' } },
        otherProps: { disabled: false, type: 'text', defaultValue: '', style: { height: '78px' } },
      },
      isCheckOrder: {
        type: 'CheckBox',
        eventNames: ['onClick'],
        otherProps: {
          disabled: false,
          type: 'checkbox',
          defaultValue: 1,
          checked: true,
          display: true,
          label: '同时审核该批次的运单',
          direction: 'right',
        },
      },
    };
    let events;
    if (tab === 'order') {
      // 运单
      cardFromData.isCheckOrder = '';
      events = { handleSelected: fromEvents.cardSelectEvents, onChange: fromEvents.cardTextAreaEvents };
      if (type === 'cancel_check') {
        cardFromData.account_name = '';
        events = { onChange: fromEvents.cardTextAreaEvents };
      }
    } else {
      // 批次
      if (isLine) {
        // 批次详情
        cardFromData.isCheckOrder = '';
        events = { handleSelected: fromEvents.cardSelectEvents, onChange: fromEvents.cardTextAreaEvents };
      } else {
        events = {
          handleSelected: fromEvents.cardSelectEvents,
          onChange: fromEvents.cardTextAreaEvents,
          onClick: fromEvents.cardTextCheckEvents,
        };
      }
      if (type === 'cancel_check') {
        cardFromData.account_name = '';
        events = { onChange: fromEvents.cardTextAreaEvents, onClick: fromEvents.cardTextCheckEvents };
        if (!isLine) {
          cardFromData.isCheckOrder.otherProps.label = '同时取消审核该批次的运单';
        }
      }
    }
    return <CardForm ref={r => (dialog._cardForm = r)} data={cardFromData} isHeader={false} handleEvents={events} />;
  };
  const rechargeDialogCancle = () => {
    // 取消审核
    dialog && dialog.orderDialog && dialog.orderDialog.close();
  };
  const getRechargeFooter = () => (
    // 创建按钮
    <div className="slide__drager__footer__content">
      <Button onClick={rechargeDialogSave} type="primary" ref={r => (dialog.hasLoadingBtn = r)}>
        确定
      </Button>
      <Button onClick={rechargeDialogCancle} ref={r => (dialog.hasDisabledBtn = r)}>
        取消
      </Button>
    </div>
  );
  dialog.orderDialog = new PopUp(ModalDialog, {
    // 审核、取消审核弹窗
    // ref: (r) => rechargeDialog = r,
    title: tipTitle,
    content: rechargeContent(),
    bottom: getRechargeFooter(),
    isModal: false,
    popName: 'Recharge',
    isShow: true,
    style: { width: '443px' },
  });
  return dialog;
};
const doAction = async function (opts, that, isLine) {
  // 页面所有操作的行为
  const { tab } = opts;
  const { type } = opts;
  const tipTitle = opts.title;
  let urlCheck;
  let urlOrder;
  let linkIds;
  const i18nDict = await i18n(POPUP_CONFIRM_WINDOW);
  const batchDict = i18nDict.get('batch', '任务');
  const orderDict = i18nDict.get('order', '运单');
  if (isLine) {
    // 详情页审核
    urlCheck = '/Order/Order/orderBatchCheck'; // 校验是否存在已审核的批次
    urlOrder = '/Order/Order/orderBatchCheck'; // 批次审核接口
    linkIds = that.getSelectesKey('b_link_id'); // 批次id
  } else {
    if (tab === 'order') {
      // 运单审核
      urlCheck = '/Order/Order/orderChkOpCheck'; // 校验是否存在已审核的运单
      urlOrder = '/Order/Order/orderCheck'; // 运单审核接口
      // linkIds = that.getSelectesKey('od_link_id') // 获取选中项的od_link_id值（数组）
      const { od_link_id: idIndex } = getData(['od_link_id'], that);
      linkIds = idIndex;
    } else {
      // 批次审核
      urlCheck = '/Driver/Batch/batchCheckChk'; // 校验是否存在已审核的批次
      urlOrder = '/Driver/Batch/batchCheck'; // 批次审核接口
      linkIds = that.getSelectesKey('b_link_id'); // 获取选中项的od_link_id值（数组）
    }
    // const selectedIndex = that.vTable.getStateSelectes() // 选中的条数
    if (linkIds.length < 1) {
      createTip('至少选中一行', WARN).show();
      return;
    }
  }
  let _cardForm;
  let rechargeDialog;
  let continueDialog;
  let checkView;
  let checkRemark;
  let isCheckOrder = 1;
  console.log(_cardForm); // 不加log报未使用错误
  const rechargeDialogSave = () => {
    dataAnalyticTrack(['订单审核', `订单${type === 'check' ? '审核' : '取消审核'}确定`]);
    // 确定审核保存
    rechargeDialog.hasLoadingBtn.loadingOn();
    rechargeDialog.hasDisabledBtn.disabledOn();
    let option;
    if (isLine) {
      option = {
        method: 'POST',
        body: {
          req: {
            od_link_id: isLine.odLinkIds,
            b_link_id: isLine.bLinkId,
            check_view: checkView, // 审核意见
            check_remark: checkRemark, // 审核备注
            is_batch: isLine.isChecked, // 是否同步运单
            op_type: type, // 操作类型 check 审核 cancel_check 取消审核
          },
        },
      };
    } else if (tab === 'order') {
      option = {
        method: 'POST',
        body: {
          req: {
            od_link_id: linkIds,
            check_view: checkView, // 审核意见
            check_remark: checkRemark, // 审核备注
            op_type: type, // 操作类型 check 审核 cancel_check 取消审核
          },
        },
      };
    } else {
      option = {
        method: 'POST',
        body: {
          req: {
            b_link_id: linkIds,
            check_view: checkView, // 审核意见
            check_remark: checkRemark, // 审核备注
            check_order: isCheckOrder, // 是否同步运单
            op_type: type, // 操作类型 check 审核 cancel_check 取消审核
          },
        },
      };
    }
    const req = fetchApi(urlOrder, option);
    req
      .then(res => {
        rechargeDialog.hasLoadingBtn.loadingOff();
        rechargeDialog.hasDisabledBtn.disabledOff();
        if (res.errno === 0) {
          createTip(`${tipTitle}成功`, CHECK).show();
          rechargeDialog.orderDialog.close();
          if (isLine) {
            detailDialog.close();
          }
          setTimeout(() => {
            that.handleRefreshData();
          }, 2000);
        } else {
          showDialog({
            type: WARN,
            title: '提示',
            content: res.errmsg,
          }).show();
        }
        rechargeDialog.orderDialog.close();
      })
      .catch(err => {
        rechargeDialog.hasLoadingBtn.loadingOff();
        rechargeDialog.hasDisabledBtn.disabledOff();
        console.log(err);
      });
  };
  const cardSelectEvents = (key, event, rest) => {
    // 获取下拉框的值
    if (type === 'cancel_check') {
      checkView = '';
      return;
    }
    if (key === 'account_name' && typeIs(event, 'array')) {
      checkView = event[0].name;
    } else {
      console.log(rest);
    }
  };
  const cardTextAreaEvents = (key, event, rest) => {
    // 获取textarea的值
    if (key === 'remark') {
      checkRemark = event;
    } else {
      console.log(rest);
    }
  };
  const cardTextCheckEvents = (key, event, rest) => {
    // 获取checkbox的值
    if (key === 'isCheckOrder') {
      if (event === true) {
        isCheckOrder = 1;
      } else {
        isCheckOrder = 0;
      }
    } else {
      console.log(rest);
    }
  };
  rechargeDialog = createDialog({
    tab,
    type,
    isLine,
    fromEvents: { cardSelectEvents, cardTextAreaEvents, cardTextCheckEvents },
    rechargeDialogSave,
    tipTitle,
  });
  const doActionCheck = () => {
    // 校验是否存在已审核的运单、批次
    let option;
    if (isLine) {
      // 详情页的审核、取消审核校验
      option = {
        method: 'POST',
        body: {
          req: {
            type: opts.type, // 操作类型 check 审核 cancel_check 取消审核
            is_check: 1,
            is_batch: isLine.isChecked, // 是否是校验
            b_link_id: isLine.bLinkId,
            od_link_id: isLine.odLinkIds,
            op_type: opts.type,
          },
        },
      };
      if (type === 'check') {
        // 防止按钮多次点击
        isLine.setRightButtonLoadingStatus('order_batch_check', true);
      } else {
        isLine.setRightButtonLoadingStatus('cancel_order_batch_check', true);
      }
    } else {
      // 列表页的审核、取消审核校验
      option = {
        method: 'POST',
        body: {
          req: {
            type: opts.type, // 操作类型 check 审核 cancel_check 取消审核
            link_ids: linkIds,
          },
        },
      };
      if (type === 'check') {
        // 防止按钮多次点击
        that.disableMenuButton('order_check');
        that.disableMenuButton('batch_check');
      } else {
        that.disableMenuButton('cancel_order_check');
        that.disableMenuButton('cancel_batch_check');
      }
    }
    const req = fetchApi(urlCheck, option);
    req
      .then(res => {
        if (isLine) {
          if (type === 'check') {
            // 防止按钮多次点击
            isLine.setRightButtonLoadingStatus('order_batch_check', false);
          } else {
            isLine.setRightButtonLoadingStatus('cancel_order_batch_check', false);
          }
        }
        that.enableMenuButton('order_check');
        that.enableMenuButton('batch_check');
        that.enableMenuButton('cancel_order_check');
        that.enableMenuButton('cancel_batch_check');
        if (res.errno === 0) {
          const faileDetail = res.res.failed_detail;
          if (((faileDetail && faileDetail.length < 1) || faileDetail === undefined) && !res.res.errmsg) {
            // 检查通过直接显示审核弹窗
            rechargeDialog.orderDialog.show();
          } else {
            // 检查不通过显示继续弹窗
            const opType = opts.title || '审核';
            const tips = '';

            opTips({
              opType,
              resData: res,
              continueCallback: (sucId, checkCallback) => {
                // 继续 btn 对应的回调
                console.log('继续 btn');
                checkCallback();
                rechargeDialog.orderDialog.show();
              },
              orderType: opts.tab === 'order' ? orderDict : batchDict,
              tips: `${tips}成功`,
              title: `${tips}确认`,
              showSucTips: true,
              ext: {
                noSplice: true,
              },
            });

            // res.res.success_id.length > 0
            // const sucId = res.res.success_id
            // let _html
            // const errMsg = res.res.errmsg || ''
            // let content = []
            // let continueOrder = ''
            // let continueCancelOrder = ''
            // let msgOrder,
            // msgNet,
            // orderArr = [],
            // netArr = []
            // faileDetail && faileDetail.forEach((item, index) => {
            // content.push(item.number)
            // if (index === 0) {
            // msgOrder = item.msg
            // }
            // if (msgOrder === item.msg) {
            // orderArr.push(item.number)
            // } else {
            // msgNet = item.msg
            // netArr.push(item.number)
            // }
            // })
            // content = content.join(',')
            // orderArr = orderArr.join(',')
            // netArr = netArr.join(',')
            // const dialogContinue = () => {
            // continueDialog.handleHide()
            // rechargeDialog.orderDialog.show()
            // }
            // const dialogCancle = () => {
            // continueDialog.handleHide()
            // }
            // let btns = (
            // <div className="slide__drager__footer__content">
            // <Button onClick={dialogCancle} type="primary">取消</Button>
            // </div>
            // )
            // if (isLine) {
            // if (sucId && sucId.length !== 0) {
            // continueOrder = (<p>是否继续将可审核的数据操作审核？</p>)
            // continueCancelOrder = (<p>是否继续将可取消审核的数据操作取消？</p>)
            // if (isLine.isChecked !== 1) {
            // continueOrder = (<p>是否继续将可审核的运单操作审核？</p>)
            // continueCancelOrder = (<p>是否继续将可取消审核的运单操作取消？</p>)
            // }
            // btns = (
            // <div className="slide__drager__footer__content">
            // <Button onClick={dialogContinue} type="primary">继续</Button>
            // <Button onClick={dialogCancle} type="primary">取消</Button>
            // </div>
            // )
            // }
            // if (type === 'check') { // 审核
            // _html = (
            // <div>
            // <p>{errMsg}</p>
            // <p>{msgOrder}</p>
            // <p>{orderArr}</p>
            // <p>{msgNet}</p>
            // <p>{netArr}</p>
            // {continueOrder}
            // </div>
            // )
            // } else {
            // _html = (
            // <div>
            // <p>{errMsg}</p>
            // <p>{msgOrder}</p>
            // <p>{orderArr}</p>
            // <p>{msgNet}</p>
            // <p>{netArr}</p>
            // {continueCancelOrder}
            // </div>
            // )
            // }
            // } else {
            // if (sucId && sucId.length !== 0) {
            // continueOrder = (<p>是否继续将可审核的{tab === 'order' ? '运单' : '批次'}操作审核？</p>)
            // continueCancelOrder = (<p>是否继续将可取消审核的{tab === 'order' ? '运单' : '批次'}操作取消？</p>)
            // btns = (
            // <div className="slide__drager__footer__content">
            // <Button onClick={dialogContinue} type="primary">继续</Button>
            // <Button onClick={dialogCancle} type="primary">取消</Button>
            // </div>
            // )
            // }
            // if (type === 'check' && !faileDetail) { // 审核
            // _html = (
            // <div>
            // <p>{errMsg}</p>
            // </div>
            // )
            // } else if (type === 'check' && faileDetail) {
            // _html = (
            // <div>
            // <p>{errMsg}</p>
            // <p>以下{tab === 'order' ? '运单' : '批次'}已审核，无需审核</p>
            // <p>{content}</p>
            // {continueOrder}
            // </div>
            // )
            // } else if (type === 'cancel_check' && !faileDetail) {
            // _html = (
            // <div>
            // <p>{errMsg}</p>
            // </div>
            // )
            // } else {
            // _html = (
            // <div>
            // <p>{res.res.errmsg}</p>
            // <p>以下{tab === 'order' ? '运单' : '批次'}未审核，无法取消审核</p>
            // <p>{content}</p>
            // {continueCancelOrder}
            // </div>
            // )
            // }
            // }
            // const popup = new PopUp(ModalDialog, {
            // ref: (r) => continueDialog = r,
            // title: tipTitle,
            // content: _html,
            // bottom: btns,
            // isModal: false,
            // popName: 'Recharge2',
            // isShow: true,
            // style: { width: '500px', wordBreak: 'break-word' }
            // })
            // popup.show()
          }
        } else {
          showDialog({
            type: WARN,
            title: '提示',
            content: res.errmsg,
          }).show();
        }
      })
      .catch(err => {
        console.log(err);
        that.enableMenuButton('order_check');
        that.enableMenuButton('batch_check');
        that.enableMenuButton('cancel_order_check');
        that.enableMenuButton('cancel_batch_check');
        if (isLine) {
          if (type === 'check') {
            // 防止按钮多次点击
            isLine.setRightButtonLoadingStatus('order_batch_check', false);
          } else {
            isLine.setRightButtonLoadingStatus('cancel_order_batch_check', false);
          }
        }
      });
  };
  doActionCheck(opts);
};
const orderCheck = function (that) {
  // 运单审核按钮点击
  const option = {
    tab: 'order',
    type: 'check',
    title: '审核',
  };
  doAction(option, that);
};
const cancelOrderCheck = function (that) {
  // 运单取消审核按钮点击
  const option = {
    tab: 'order',
    type: 'cancel_check',
    title: '取消审核',
  };
  doAction(option, that);
};
// 批量操作
const showBatchDialog = (data, pack, btnKey, that) => {
  const tips = batchOpConfig[btnKey].title;
  const { url } = batchOpConfig[btnKey];
  const enumList = batchOpConfig[btnKey].enum || [];
  const { header } = batchOpConfig[btnKey];
  const enumerations = {};
  enumList.forEach(key => {
    header[key].refEnum = key;
    enumerations[key] = that.getTableEnum(key);
  });
  new PopUp(BatchOp, {
    title: `批量${tips}`,
    tips,
    header,
    data,
    enumerations,
    requestUrl: url,
    // pace: 10,
    getPara: dataList => {
      const bLinkIds = filterArrayPropByKey(dataList, 'b_link_id');
      return { ...pack, b_link_id: bLinkIds };
    },
    finishCallback: () => {
      that.handleRefreshData();
    },
    escBind: false,
  }).show();
};
const doBatchOp = (opts, btnKey, that) => {
  const linkIds = that.getSelectesKey('b_link_id');
  if (linkIds.length < 1) {
    createTip('至少选中一行', WARN).show();
    return;
  }
  // 十条以下不做批量处理
  if (linkIds.length <= 1) {
    doAction(opts, that);
    return;
  }
  const rechargeDialog = createDialog({
    tab: opts.tab,
    type: opts.type,
    isLine: false,
    fromEvents: {},
    rechargeDialogSave: () => {
      if (rechargeDialog._cardForm) {
        const resData = rechargeDialog._cardForm.getStateDataForm();
        const newPack = {
          b_link_id: linkIds,
          // check_view: checkView, // 审核意见
          check_remark: resData.remark, // 审核备注
          check_order: resData.isCheckOrder ? 1 : 0, // 是否同步运单
          op_type: opts.type, // 操作类型 check 审核 cancel_check 取消审核
        };
        if (opts.type === 'check') {
          const checkView =
            (rechargeDialog._cardForm.getStateData().account_name || {}).otherProps.defaultSelected[0].name || '正常';
          newPack.check_view = checkView;
        }
        const data = that.getStateSelecteRows();
        showBatchDialog(JSON.parse(JSON.stringify(data)), newPack, btnKey, that);
        rechargeDialog.orderDialog.close();
      }
    },
    tipTitle: opts.title,
  });
  rechargeDialog.orderDialog && rechargeDialog.orderDialog.show();
};
const batchCheck = function (that) {
  // 批次审核按钮点击
  const option = {
    tab: 'batch',
    type: 'check',
    title: '审核',
  };
  doBatchOp(option, 'batch_check', that);
};
const cancelBatchCheck = function (that) {
  // 批次取消审核按钮点击
  const option = {
    tab: 'batch',
    type: 'cancel_check',
    title: '取消审核',
  };
  doBatchOp(option, 'cancel_batch_check', that);
};
const inlineCheck = function (that, index, opts) {
  const pageKey = getPageKey().pageConfig.key;
  const dataList = that.getTableList([index]);
  if (dataList.length === 0) return false;
  const batchId = dataList[0].b_link_id;
  const comId = dataList[0].company_id;
  const pack = {
    type: opts.btnKey,
    b_link_id: batchId,
    company_id: comId,
  };
  const { tableInfo } = that.getTableInfo().pageConfig;
  const pageUrl = (mockData[tableInfo.tab] || {}).pageUrl || '/Truck/Truck/onLoading';
  gpostData(pageUrl || '/Truck/Truck/onLoading', pack, res => {
    if (res.data.errno !== 0) {
      showPopTip(ERROR, res.data.errmsg);
      return;
    }
    detailDialog = new PopUp(getBatchDetail(), {
      popName: `BatchDetailSlider_${pageKey}`,
      batchId,
      comId,
      batchNo: opts.name,
      type: opts.btnKey,
      hasTab: false,
      isEdit: false,
      listPage: that,
      pickOrderInfo: fromJS(res.data.res),
    });
    detailDialog.show();
  });
};
const batchLineCheck = function (that, index) {
  // 行内批次审核按钮点击
  const opts = {
    name: '审核',
    btnKey: 'tr_up_loading_list_check',
  };
  inlineCheck(that, index, opts);
};
const batchLineCancelCheck = function (that, index) {
  // 行内批次取消审核按钮点击
  const opts = {
    name: '取消审核',
    btnKey: 'tr_up_loading_list_cancel_check',
  };
  inlineCheck(that, index, opts);
};
export const batchCheckLine = function (that, obj) {
  // 给详情批次审核按钮点击
  const option = {
    tab: 'batch',
    type: 'check',
    title: '审核',
  };
  const isLine = obj;
  doAction(option, that, isLine);
};
export const cancelBatchCheckLine = function (that, obj) {
  // 给详情批次取消审核按钮点击
  const option = {
    tab: 'batch',
    type: 'cancel_check',
    title: '取消审核',
  };
  const isLine = obj;
  doAction(option, that, isLine);
};

// 任务审核 ⬇
const doTaskFetch = async ({ listPage, reqObj = [], btnKey, url, opType, sucIds, showInfoRef }) => {
  const rawInfos = [...(reqObj.infos || [])];
  const newInfos = sucIds
    ? rawInfos.reduce((ary, x) => (sucIds.includes(+x.b_basic_id) ? [...ary, x] : ary), [])
    : rawInfos;
  const fetchReq = { ...reqObj, infos: newInfos };
  const i18nDict = await i18n(POPUP_CONFIRM_WINDOW);
  const batchDict = i18nDict.get('batch', '任务');
  const fetchRes = await postJAVA(url, fetchReq);
  console.log(' === doFetch === ', reqObj, btnKey, fetchRes);

  const { errno, errmsg, res = {} } = fetchRes;
  const { ext } = res;

  if (+errno !== 0) {
    errmsg && showInfo(WARN, errmsg);
    // showInfoRef && showInfoRef.handleHide && showInfoRef.handleHide()
    listPage.enableMenuButton && listPage.enableMenuButton(btnKey);
    return false;
  }

  opTips({
    opType,
    resData: fetchRes,
    continueCallback: (sucId, checkCallback) => {
      // 继续 btn 对应的回调
      doTaskFetch({ listPage, reqObj, btnKey, sucIds: sucId, url, opType, showInfoRef });
      checkCallback();
    },
    sucCallback: () => {
      setTimeout(() => {
        // showInfoRef && showInfoRef.handleHide && showInfoRef.handleHide()
        listPage.enableMenuButton && listPage.enableMenuButton(btnKey);
        listPage.handleRefreshData && listPage.handleRefreshData();
      }, 2000);
    },
    orderType: batchDict,
    tips: opType,
    title: '操作确认',
    showSucTips: true,
    ext,
  });
};
const taskPop = ({ listPage, contentElem, reqObj, btnKey, url, opType }) => {
  new PopUp(ShowInfo, {
    classname: 'task-check-pop-wrap',
    content: contentElem,
    title: opType,
    showIcon: false,
    simpleMode: {
      confirm: showInfoRef => {
        showInfoRef.handleHide();
        doTaskFetch({ listPage, reqObj, btnKey, url, opType });
      },
      cancel: showInfoRef => {
        showInfoRef.handleHide();
        listPage.enableMenuButton && listPage.enableMenuButton(btnKey);
      },
    },
    onClose: () => listPage.enableMenuButton && listPage.enableMenuButton(btnKey),
  }).show();
};

// 运单审核列表-审核按钮
export const taskCheck = async (that, rowIndex) => {
  const selects = that.vTable.getStateSelectes() || [];
  const data = that.getData();
  const enumerations = that.getTableEnum();
  const selectsData = selects.map(index => data[index]);
  let batchRef = null;
  let batchAuditPopup = null;
  let opinionVal = '';
  let selectVal = '正常';
  const opinionChange = (e, val) => (opinionVal = val);
  const handleSelected = val => (selectVal = val.key);

  if (!checkSelected(selectsData)) return;

  // 批量操作弹窗
  const showBatchOP = (originData, audit) => {
    const param = {
      title: '批量操作',
      requestUrl: '/cmm-batch/batch/oneTaskAudit',
      isJAVA: true,
      tips: '审核',
      dealHeaderWidth: 150,
      enumerations,
      header: {
        car_batch: { title: '运单号', display: 'show', width: 120 },
        create_time: { title: '创建时间', display: 'show', width: 100 },
      },
      data: originData,
      escBind: false,
      getBatchOpRef: ref => {
        batchRef = ref;
      },
      getPara: dataList => {
        const { b_basic_id, company_id } = dataList[0] || {};
        return {
          check_view: selectVal,
          check_remark: opinionVal,
          infos: [{ b_basic_id, cur_company_id: company_id }],
        };
      },
      paceCallback: callbackRes => {
        if (+callbackRes.errno !== 0) {
          callbackRes.errmsg && showInfo(ERROR, callbackRes.errmsg);
          return false;
        }
      },
      finishCallback: () => {
        that.handleRefreshData();
      },
    };
    batchAuditPopup = new PopUp(BatchOp, param);
    batchAuditPopup.show();
  };

  // 二次确认弹窗
  new PopUp(ShowInfo, {
    classname: 'detail_info',
    isShow: true,
    type: WARN,
    title: '操作确认',
    buttons: [
      {
        key: 'confirm',
        name: '确认',
        btnType: 'primary',
        cb: dialog => {
          dialog.handleHide();
          dataAnalyticTrack(['运单审核', '审核确认']);
          showBatchOP(selectsData);
        },
      },
      {
        key: 'cancel',
        name: '取消',
        btnType: 'default',
        cb: dialog => {
          dialog.handleHide();
          dataAnalyticTrack(['运单审核', '取消审核确认']);
        },
      },
    ],
    content: (
      <DialogDetail
        noticeTitle={<span />}
        ext={{
          specialTips: '确认批量审核吗？',
          reason: (
            <div className="task-check-content">
              <div className="check-views task-check-item">
                <label className="item-txt red"> 审核意见： </label>
                <SelectDrop
                  alignWidth
                  selectOnly
                  data={[
                    { key: '正常', name: '正常' },
                    { key: '错误', name: '错误' },
                    { key: '异常', name: '异常' },
                  ]}
                  defaultSelected={selectVal}
                  handleSelected={handleSelected}
                />
              </div>
              <div className="check-remark task-check-item">
                <label className="item-txt"> 审核备注： </label>
                <TextArea style={{ width: '300px', marginTop: '5px', height: '80px' }} onChange={opinionChange} />
              </div>
            </div>
          ),
        }}
      />
    ),
  }).show();
};
const taskCheckCancel = async (listPage = {}, btnKey) => {
  const selectedRows = listPage.getStateSelecteRows();
  console.log('=== taskCheckCancel ===', selectedRows);

  if (!selectedRows.length) {
    return showInfo(WARN, '请选择要操作的数据！');
  }
  listPage.disableMenuButton && listPage.disableMenuButton(btnKey);
  await sleep(0);

  const infosAry = selectedRows.map(x => ({ b_basic_id: x.b_basic_id, cur_company_id: x.company_id }));
  const reqObj = { cancel_check_remark: '', infos: infosAry };

  const contentElem = (
    <div className="task-check-content">
      <div className="check-remark task-check-item">
        <label className="item-txt"> 取消原因： </label>
        <TextArea onChange={e => (reqObj.cancel_check_remark = e.target.value)} />
      </div>
    </div>
  );

  taskPop({ listPage, contentElem, reqObj, btnKey, url: '/cmm-batch/batch/cancelTaskAudit', opType: '取消审核' });
};
// 任务审核 ⬆

export function init(emt) {
  emt.on('orderCheck', orderCheck);
  emt.on('cancelOrderCheck', cancelOrderCheck);
  emt.on('batchCheck', batchCheck);
  emt.on('cancelBatchCheck', cancelBatchCheck);
  emt.on('batchLineCheck', batchLineCheck);
  emt.on('batchLineCancelCheck', batchLineCancelCheck);

  emt.on('taskCheck', taskCheck);
  emt.on('taskCheckCancel', taskCheckCancel);
}
