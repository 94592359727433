exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".order-editor-img{border-bottom:0!important;margin-top:5px}.order-editor-img .tip{color:#fd3c3c;padding-left:22px;margin-bottom:5px;display:inline-block}.order-editor-img .transform-fileupload-wrap{padding-left:5px}.order-editor-img .file-wrap{padding-left:10px;padding-top:10px}", ""]);

// exports
exports.locals = {
	"prefixCls": "order-editor-img",
	"order-editor-img": "order-editor-img",
	"tip": "tip",
	"transform-fileupload-wrap": "transform-fileupload-wrap",
	"file-wrap": "file-wrap"
};