import { ot, showInfo, throttle } from 'utils';
import { ERROR } from 'constants';
import { SET_ORDER_DATA, CEE_ARR_MATCHED, MERGE_DATA, FETCH_ORDER_DATA, SELECT_CEE_SUG } from '../../constant';
import { getDistance, getDistanceByKeyword } from '../../tool';
import { setAttachments } from '../tool';

const consigneeMiddleware = page => store => {
  const calcCeePickDist = throttle(() => {
    const state = store.getState();
    const { arrPoint, transMode, ceeAddrInfo, arrInfo } = state;
    if (!state.arrPointShow && !state.arrInfoShow) {
      const p1 = state.companyInfo && state.companyInfo.address && state.companyInfo.address.poi;
      const p2 = ceeAddrInfo.poi;
      const { mileRatio } = state;
      getDistance(p1, p2, mileRatio).then(
        distance => (state.ceePickDist || distance) && page.set('ceePickDist', distance),
      );
    } else if (arrPoint && (!transMode || transMode === 3)) {
      const p1 = ceeAddrInfo.poi;
      const p2 = arrPoint.addr && arrPoint.addr.poi;
      const { mileRatio } = state;
      getDistance(p1, p2, mileRatio).then(
        distance => (state.ceePickDist || distance) && page.set('ceePickDist', distance),
      );
    } else {
      const endPoi = ceeAddrInfo && ceeAddrInfo.poi ? ceeAddrInfo.show_val : '';
      const endCity = ceeAddrInfo && ceeAddrInfo.poi ? ceeAddrInfo.city || ceeAddrInfo.province : '';
      const arrInfoAddress = arrInfo && arrInfo.addr ? arrInfo.addr : arrInfo;
      const startPoi =
        arrInfoAddress && arrInfoAddress.poi ? `${arrInfoAddress.district || arrInfoAddress.city}人民政府` : '';
      const startCity = arrInfoAddress && arrInfoAddress.poi ? arrInfoAddress.city || arrInfoAddress.province : '';
      const { mileRatio } = state;
      getDistanceByKeyword(startPoi, endPoi, [startCity, endCity], mileRatio).then(distance => {
        if (state.transCeePickDist || distance) page.set('transCeePickDist', distance);
        if (state.ceePickDist || distance) page.set('ceePickDist', distance);
      });
    }
  }, 10);

  function onChange(next, key, val) {
    const state = store.getState();
    switch (key) {
      case 'ceeAddrInfo': {
        const { address_filling } = window.company_setting;
        calcCeePickDist();
        // 如果系统设置勾选并且到站没有编辑过，将收货地址的值带入到到站中
        if (state.arrInfoShow && !page.edited('arr') && address_filling?.values?.includes('arr')) {
          page.set('arrInfo', val);
        }
        break;
      }
      case 'arrPoint': {
        calcCeePickDist();
        break;
      }
      case 'transMode': {
        calcCeePickDist();
        break;
      }
      case 'arrInfo': {
        calcCeePickDist();
        break;
      }
      case 'deliveryMode': {
        // 送货必填收货地址
        !state.ceeAddrInfoSettingRequired &&
          state.needCeeAddress &&
          store.dispatch({ type: MERGE_DATA, payload: { ceeAddrInfoRequired: val !== 'self_pick' } });
        break;
      }
      case 'shudArrDate': {
        if (val) {
          const shudArrDate = val.split(',');
          const time1 = new Date(shudArrDate[0]);
          const time2 = new Date(shudArrDate[1]);
          const clearVal = () => setTimeout(() => page.set('shudArrDate', ''));
          if (time1 > time2) {
            showInfo(ERROR, '指定到货开始时间不能大于结束时间');
            clearVal();
          } else if (time1.getDay() !== time2.getDay()) {
            showInfo(ERROR, `${ot('指定到货时间')}不能跨天`);
            clearVal();
          }
        }
        break;
      }
      default:
        break;
    }
  }
  return next => action => {
    // const state = store.getState()
    switch (action.type) {
      case SET_ORDER_DATA:
        onChange(next, action.payload.key, action.payload.val, action.src);
        break;
      case CEE_ARR_MATCHED: {
        if (action.payload === 'CAN_NOT_MATCH') {
          // 匹配不到任何网点 清空收货地址
          store.dispatch({ type: SET_ORDER_DATA, payload: { key: 'ceeAddrInfo', val: '' } });
        }
        break;
      }
      case SELECT_CEE_SUG: {
        const { data } = action.payload;
        const { set } = page;
        const { merge } = page;
        const state = store.getState();
        const frequentGoods = data.ext && JSON.parse(data.ext);
        const ceeMaintainCalc = state.basicSetting.cee_maintain_calc || {};
        const maintain = data.maintain_fee;
        // eslint-disable-next-line no-nested-ternary
        const parsedMaintain = Array.isArray(maintain) ? maintain : maintain ? JSON.parse(maintain) || [] : [];
        const hasMaintainRule =
          data.maintain_rule &&
          ((+data.maintain_rule === 2 && data.maintain_ratio) ||
            (+data.maintain_rule === 1 && parsedMaintain.some(Boolean)));
        set('ceeId', data.id);
        set('ceeCate', data.type);
        merge('ceeCateStr', data.type_name);
        set('ceeIsMonth', data.is_month === '是');
        set('ceeName', data.name || '');
        set('ceeMobile', data.telephone || '');
        set('ceeAddrInfo', data.address || '');
        set('ceeCom', data.customer_cname || '');
        set('ceePhone', data.phone || '');
        set('ceeIdNum', data.payee_id_card || '');
        set('ceeCustomerNo', data.customer_no || '');
        set('ceeIndustry', data.industry || '');
        set('ceeAddrRemark', data.address_remark || '');
        set('ceeRemark', data.remark || '');
        merge('ceeSalesmen', data.salesmen);
        set('ceeMaintainByFormula', +(hasMaintainRule ? data.maintain_rule : ceeMaintainCalc.maintain_rule) === 2);
        // 使用 单价 计算时跟新 单价 触发维护费
        set('ceeMaintain', hasMaintainRule ? parsedMaintain : ceeMaintainCalc.maintain_fee);
        // 维护费系数
        set('ceeMaintainRatio', +(hasMaintainRule ? data.maintain_ratio : ceeMaintainCalc.maintain_ratio) || 0);
        merge('ceeIsProject', +data.is_proj === 1);
        frequentGoods && set('frequentGoods', frequentGoods);

        // 下拉收货人sug 都会获取新的收货人信息
        if (state.ceeAttachmentShow) {
          // 联系人上的所有附件信息：货物发生变更后，需要根据联系人常发货物和附件计算附件
          set('ceeAllAttachment', {
            goods_claims: data.goods_claims || [], // 联系人常发货物
            attachment: data.attachment || [], // 联系人附件
          });
          // 计算并赋值
          setAttachments({
            page,
            type: 'consignee',
          });
        }

        break;
      }
      case FETCH_ORDER_DATA: {
        setTimeout(() => {
          const state = store.getState();
          if (state.isCreate && state.ceeAddrInfo && state.ceeAddrInfo.poi) {
            page.set('ceeAddrInfo', state.ceeAddrInfo);
          }
          if (state.isCreate && state.ceeMaintain) {
            page.set('ceeMaintain', state.ceeMaintain);
          }
        });
        break;
      }
      default:
        break;
    }
    next(action);
  };
};

export default consigneeMiddleware;
