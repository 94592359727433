/**
 * Created by wangnaihe on 2017/6/19.
 */
import React from 'react';
import renderFreightTo from './freight';
import renderCollectTo from './collect';
import renderPayTo from './pay';
import { prefixCls } from './index.scss';

function renderFee(page) {
  const renderFreight = renderFreightTo(page);
  const renderPay = renderPayTo(page);
  const renderCollect = renderCollectTo(page);

  return () => (
    <div className={prefixCls}>
      {renderFreight()}
      {renderPay()}
      {renderCollect()}
    </div>
  );
}

export default renderFee;
