/**
 * Created by wangnaihe on 2017/12/20.
 */
export const getMgr = (keyword, fetch, state) => {
  const from = +window.company_id !== +state.cid ? 'co_for_other' : undefined;
  const url = 'Basic/Org/userSug';
  const req = { company_id: state.cid, search: keyword, slice_num: 10, from };
  const conf = { method: 'POST', body: { req } };
  return fetch(url, conf);
};
