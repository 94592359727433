/**
 * Created by wangnaihe on 2017/6/27.
 */
import React, { Fragment } from 'react';
import { ot } from 'utils';
import { profitFormulaEnum } from 'constants/setting/orderSetting';
import { tableKeys, OUTER_TRANS } from '../constant';

export const getProfitText = state => {
  const { profitFormula, keyMap, ext } = state;
  const extProfit = ext.profit || {};
  return profitFormula.map((f, i) => {
    const ratio = +f.ratio || 0;
    const operator = f.operator === 'minus' ? ' - ' : ' + ';
    if (!f.itemList.length) return null;
    return (
      <Fragment key={i}>
        {i !== 0 && operator}(
        {f.itemList.length &&
          f.itemList.map((key, index) => {
            let value = 0;
            if (extProfit[key]) value = extProfit[key] || 0;
            else if (state[keyMap[key]]) value = state[keyMap[key]] || 0;
            else {
              tableKeys.forEach(tableKey => {
                const tableData = state[tableKey];
                tableData &&
                  tableData.forEach(row => {
                    const outerTransNotStartPoint =
                      row.od_link_id &&
                      (+row.od_link_id < +ext.ipt_head_od_link_id || +row.od_link_id > +ext.ipt_tail_od_link_id);
                    if (tableKey === 'transInfo' && (+row.trans_type !== OUTER_TRANS || outerTransNotStartPoint))
                      return;
                    if (row[key]) value += +row[key] || 0;
                  });
              });
            }
            return (
              <Fragment key={index}>
                {index !== 0 && ' + '}
                {ot(profitFormulaEnum[key])}
                <i> ¥ {value}</i>
              </Fragment>
            );
          })}
        ) x {ratio}
      </Fragment>
    );
  });
};
